/*
	Замена прав УКД по задаче PC-4357

	SIGN_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR_CONSUMER // -> SIGN_EDI_FNS_UKD_CONSUMER, в Core не использовалось
	SIGN_UKD_KSCHF_DIS_SIMPLE_CONSUMER                   // -> SIGN_EDI_FNS_UKD_CONSUMER
	SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER                  // -> SIGN_EDI_FNS_UKD_CONSUMER
	SIGN_EDI_FNS_UKD_KSCHF_SUPPLIER                      // -> SIGN_EDI_FNS_UKD_CONSUMER
	SIGN_EDI_FNS_UKD_DIS_CONSUMER                        // -> SIGN_EDI_FNS_UKD_CONSUMER
	SIGN_EDI_FNS_UKD_KSCHF_CONSUMER                      //  Право SIGN_EDI_FNS_UKD_KSCHF_CONSUMER - отсутствовало на беке, отключено

	SIGN_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR_SUPPLIER // -> SIGN_EDI_FNS_UKD_SUPPLIER, в Core не использовалось
	SIGN_EDI_FNS_UKD_KSCHF_WITHOUT_OPERATOR              // -> SIGN_EDI_FNS_UKD_SUPPLIER, в Core не использовалось
	SIGN_EDI_FNS_UKD_KSCHF_DIS_SUPPLIER                  // -> SIGN_EDI_FNS_UKD_SUPPLIER
	SIGN_EDI_FNS_UKD_DIS_SUPPLIER                        // -> SIGN_EDI_FNS_UKD_SUPPLIER
	SIGN_EDI_FNS_UKD_KSCHF                               // -> SIGN_EDI_FNS_UKD_SUPPLIER
	SIGN_UKD_KSCHF_DIS_SIMPLE                            // -> SIGN_EDI_FNS_UKD_SUPPLIER

	DELETE_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR        // -> DELETE_EDI_FNS_UKD, в Core не использовалось
	DELETE_EDI_FNS_UKD_KSCHF_WITHOUT_OPERATOR            // -> DELETE_EDI_FNS_UKD, в Core не использовалось
	DELETE_UKD_KSCHF_DIS_SIMPLE                          // -> DELETE_EDI_FNS_UKD, в Core не использовалось
	DELETE_EDI_FNS_UKD_DIS                               // -> DELETE_EDI_FNS_UKD
	DELETE_EDI_FNS_UKD_KSCHF_DIS                         // -> DELETE_EDI_FNS_UKD
	DELETE_EDI_FNS_UKD_KSCHF                             // -> DELETE_EDI_FNS_UKD

	EDIT_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR_P2       // -> EDIT_EDI_FNS_UKD, в Core не использовалось
	EDIT_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR          // -> EDIT_EDI_FNS_UKD, в Core не использовалось
	EDIT_EDI_FNS_UKD_KSCHF_WITHOUT_OPERATOR              // -> EDIT_EDI_FNS_UKD, в Core не использовалось
	EDIT_P2_UKD_KSCHF_DIS_SIMPLE                         // -> EDIT_EDI_FNS_UKD, в Core не использовалось
	EDIT_UKD_KSCHF_DIS_SIMPLE                            // -> EDIT_EDI_FNS_UKD, в Core не использовалось
	EDIT_EDI_FNS_UKD_KSCHF                               // -> EDIT_EDI_FNS_UKD, в Core не использовалось
	EDIT_EDI_FNS_UKD_DIS                                 // -> EDIT_EDI_FNS_UKD, в Core не использовалось

	COMPLETE_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR      // -> COMPLETE_EDI_FNS_UKD, в Core не использовалось
	COMPLETE_EDI_FNS_UKD_KSCHF_WITHOUT_OPERATOR          // -> COMPLETE_EDI_FNS_UKD, в Core не использовалось
	COMPLETE_UKD_KSCHF_DIS_SIMPLE                        // -> COMPLETE_EDI_FNS_UKD, в Core не использовалось
	COMPLETE_EDI_FNS_UKD_KSCHF_DIS                       // -> COMPLETE_EDI_FNS_UKD
	COMPLETE_EDI_FNS_UKD_KSCHF                           // -> COMPLETE_EDI_FNS_UKD
	COMPLETE_EDI_FNS_UKD_DIS                             // -> COMPLETE_EDI_FNS_UKD

	CREATE_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR_P2     // -> CREATE_EDI_FNS_UKD, в Core не использовалось
	CREATE_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR        // -> CREATE_EDI_FNS_UKD, в Core не использовалось
	CREATE_EDI_FNS_UKD_KSCHF_WITHOUT_OPERATOR            // -> CREATE_EDI_FNS_UKD, в Core не использовалось
	CREATE_P2_UKD_KSCHF_DIS_SIMPLE                       // -> CREATE_EDI_FNS_UKD, в Core не использовалось
	CREATE_UKD_KSCHF_DIS_SIMPLE                          // -> CREATE_EDI_FNS_UKD, в Core не использовалось
	CREATE_EDI_FNS_UKD_KSCHF_DIS_P2                      // -> CREATE_EDI_FNS_UKD
	CREATE_EDI_FNS_UKD_DIS_P2                            // -> CREATE_EDI_FNS_UKD
	CREATE_EDI_FNS_UKD_P2                                // Право CREATE_EDI_FNS_UKD_P2 - отсутствовало на беке, отключено
*/

import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';

const actionRulesForUkdModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.UTOCH
			]
		},
		permissionChanging: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				change: function (actionPermission, checkOptions) {
					var suffix =
						checkOptions.direction === edi.constants.DIRECTIONS.INCOMING ? '_CONSUMER' : '_SUPPLIER';
					//var rawBpName = edi.utils.getAttributeByName(checkOptions.attributes, "bpName");
					//var bpName = '_' + edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[rawBpName] || 'unknown';
					//return 'SIGN_' + checkOptions.docType + bpName + suffix;

					// Больше не проверяем bpName для УКД. Раньше было 3 варианта bpName в Core: DIS, KSCHF, KSCHF_DIS
					return 'SIGN_' + checkOptions.docType + suffix;
				}
			}
		],
		conditions: [
			{
				customMethod: documentPackageMethods.isDocActionBlocked,
				allow: false
			},
			{
				directions: [edi.constants.DIRECTIONS.OUTGOING],
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				allow: function (documentData) {
					const isBlocked = edi.utils.getAttributeByName(
						documentData.attributes,
						'isBlocked',
						undefined,
						true
					);
					return !isBlocked;
				}
			},
			{
				directions: [edi.constants.DIRECTIONS.INCOMING],
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				allow: function (documentData) {
					const bpName = edi.utils.getAttributeByName(documentData.attributes, 'bpName');
					const bpNamesWithoutSignAction = [
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHF_ROAMING_IN,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHF_ROAMING_OUT,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_IN,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_OUT,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_IN_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14
					];
					const isBpWithoutSign = bpNamesWithoutSignAction.find((bpWithoutSign) => bpName === bpWithoutSign);
					const isBlocked = edi.utils.getAttributeByName(
						documentData.attributes,
						'isBlocked',
						undefined,
						true
					);
					return !isBpWithoutSign && !isBlocked;
				}
			}
		]
	},
	REJECT: {
		permissionChanging: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				change() {
					return null;
				}
			}
		],
		conditions: [
			{
				customMethod: documentPackageMethods.isDocActionBlocked,
				allow: false
			}
		]
	},
	ROUTE_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction === edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction === edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	ROUTE_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction === edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction === edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	CLARIFY: {
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.AMEND
			]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived');
				}
			},
			{
				businessStates: [edi.constants.STATE.UTOCH, edi.constants.STATE.AMEND],
				allow: function (checkOptions) {
					const docInClosedPackage =
						checkOptions.record.data.packageId &&
						edi.utils.getAttributeByName(checkOptions.attributes, 'packageEnclosed') === 'true';
					return docInClosedPackage && checkOptions.actionFromPackageModule === true;
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		LOOP: {
			SINGLE_APPLY: [],
			BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [],
			BUSINESS_STATE: []
		},
		permissionChanging: [],
		conditions: []
	},
	EDIT: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				allow(documentData) {
					let version = documentData?.record?.get('versionId');
					return version === '5.01-N';
				}
			}
		]
	},
	COMPLETE: {
		INCOMING: {
			SINGLE_APPLY: [],
			BUSINESS_STATE: [
				edi.constants.STATE.READ,
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		OUTGOING: {
			SINGLE_APPLY: [],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				customMethod: function (checkOptions) {
					return !checkOptions.hasPart2 && checkOptions.annulNotActive;
				},
				allow: false
			}
		]
	},
	READ: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.SENT, edi.constants.STATE.UTOCH]
		},
		permissionChanging: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				directions: [edi.constants.DIRECTIONS.INCOMING],
				change: function () {
					return 'SIGN_EDI_FNS_UKD_CONSUMER';
				}
			}
		],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				allow: function (checkOptions) {
					let attrs = checkOptions.attributes;
					let bpName = edi.utils.getAttributeByName(attrs, 'bpName');
					let bpNamesWithoutReadAction = [
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHF_ROAMING_IN,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHF_ROAMING_OUT,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_IN,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_OUT,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_IN_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14,
						edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14
					];
					const hasReadAction = !bpNamesWithoutReadAction.find(
						(bpNameWithoutRead) => bpNameWithoutRead === bpName
					);
					const isBlocked = edi.utils.getAttributeByName(
						checkOptions.attributes,
						'isBlocked',
						undefined,
						true
					);

					return !isBlocked && hasReadAction;
				}
			}
		]
	},
	SEND: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {
			SINGLE_APPLY: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENDER_REVIEW],
				allow: false
			}
		]
	},
	CREATE_FROM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED, edi.constants.STATE.SENT]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENT],
				allow: false
			}
		]
	},
	CONVERT_TO_DRAFT: {
		OUTGOING: {
			BUSINESS_STATE: [],
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	COPY: {
		OUTGOING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
				allow(documentData) {
					let version = documentData?.record?.get('versionId');
					return version === '5.01-N';
				}
			}
		]
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						documentPackageMethods.isPackagingAllowed(checkOptions.docType) &&
						checkOptions.state === edi.constants.STATE.DRAFT
					);
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH_COMPLETED,
				edi.constants.STATE.RENOUNCEMENT,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH_COMPLETED,
				edi.constants.STATE.RENOUNCEMENT,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		conditions: [
			{
				customMethod: documentPackageMethods.isDocActionBlocked,
				allow: false
			},
			{
				allow: function (checkOptions) {
					var annual = true;
					if (checkOptions.annulStatus) {
						annual = [
							edi.constants.STATE.CREATED,
							edi.constants.STATE.DELETED,
							edi.constants.STATE.DRAFT
						].some((it) => it === checkOptions.annulStatus);
					}

					const isBlocked = edi.utils.getAttributeByName(
						checkOptions.attributes,
						'isBlocked',
						undefined,
						true
					);

					return (
						!isBlocked &&
						(!checkOptions.record.get('archived') && checkOptions.annulNotActive ? annual : false)
					);
				}
			}
		],
		permissionChanging: []
	},
	CREATE_CUD: {
		OUTGOING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER,
				edi.constants.STATE.UTOCH_COMPLETED,
				edi.constants.STATE.ON_ANNUL
			]
		},
		permissionChanging: [],
		conditions: []
	}
};

const actionRulesForUkdStatuses = {
	SIGN: {
		OUTGOING: {
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_10_DP_IZVPOL,
					STATE: [edi.constants.STATE.WAIT_SIGNATURE_STATUS10]
				}
			]
		},
		permissionChanging: [
			{
				documents: [
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_IOP_DP_PDOTPR_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_P1_DP_IZVPOL
				],
				change: function (actionPermission, checkOptions) {
					return 'SIGN_EDI_FNS_UKD_CONSUMER';
				}
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_11_P2_DP_IZVPOL],
				change: function (actionPermission, checkOptions) {
					return 'SIGN_EDI_FNS_UKD_SUPPLIER';
				}
			}
		],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PDPOL, edi.constants.NOTICE_DOC_TYPES]
			},
			{
				states: [
					edi.constants.STATE.DEBTOR_REVIEW,
					edi.constants.STATE.RECEIVER_REVIEW,
					edi.constants.STATE.READ
				],
				allow: function (documentData) {
					return documentData.needSignatures;
				}
			}
		]
	}
};
Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD]: actionRulesForUkdModule,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_IZVPOL]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_DP_IZVPOL]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_DP_PDOTPR]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_DP_IZVPOL]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_UVUTOCH]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_10_DP_IZVPOL]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_IOP_DP_PDOTPR_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_DP_PDOTPR_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_UVUTOCH_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_UOU_DP_PDPOL_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_UOU_DP_PDOTPR_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_UOU_DP_PDOTPR_DECREE_14]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_11_P2_DP_IZVPOL]: actionRulesForUkdStatuses,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_P1_DP_IZVPOL]: actionRulesForUkdStatuses
});

export { actionRulesForUkdModule, actionRulesForUkdStatuses };
