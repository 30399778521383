import { checkBeforeSignRules } from './methods';
import { createProxyConfig } from '@Components/storeComponents';
import { createModulePanel, createPanel } from '@Components/panels';
import { createCombo, createFieldContainer, createSearchTypeSelectionFieldForGrid } from '@Components/fields';
import { createContainer } from '@Components/miscComponents';
import { createGrid, createGridActionBar, createGridCheckboxSelectionModel } from '@Components/grid';
import { createDocumentTypeFilterSelectForGrid } from '@Edi/specialComponents/documentTypeFilterSelect';
import { dpContainerSign } from '@Edi/modules/documents/AGREEMENT_PACKAGES/methods';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createAutocomplete, createDateRangeField } from '@UIkit/components/fields';
import { filterMethods } from './filter';
import { createCertificateHandler } from '@App/js/signature/handlers/createCertificateHandler';
import { createPoaHandler } from '@App/js/signature/handlers/createPoaHandler';

Ext.namespace('edi.modules');
/**
 * Class for grid documents statuses
 * @author Vsevolod Liapin
 */
edi.modules['document.statuses'] = function () {
	var moduleData,
		grid,
		filterObject,
		fireSearch,
		allActiveCerts = {},
		signProperties = {},
		useActiveCerts = false,
		filterForm,
		docTypeCombo,
		docStatusCombo,
		attributesSearchCombo,
		notHaveActiveCertType = [],
		aperakTypes = edi.constants.MODULES.APERAK_DOCUMENT_TYPES,
		includedTypes = edi.constants.MODULES.DOCUMENTS.INCLUDED_TYPES,
		includedStates = edi.constants.MODULES.DOCUMENTS.INCLUDED_STATES;

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', gridDataChangedHandler);
		edi.events.documents.on('delete', gridDataChangedHandler);
	};
	/**
	 * Creates filter form
	 * @returns {Object}
	 */
	var createFilterForm = function () {
		fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(
					edi.rest.services.DOCUMENTS.GET,
					filterForm,
					grid,
					filterMethods.createArgsCommon,
					{
						gridName: 'statuses'
					}
				);
			}
			filterObject.filter();
		};

		var docTypes = [],
			docStates = [],
			docDateRange,
			modifyDateRange,
			creationDateRange,
			refDocTypes = [],
			refDocCodes = [],
			bpNamesUPD = Array.isArray(edi.constants.UPD_BP_NAMES_FOR_FILTER)
				? edi.constants.UPD_BP_NAMES_FOR_FILTER
				: [],
			bpNamesUKD = Array.isArray(edi.constants.UKD_BP_NAMES_FOR_FILTER)
				? edi.constants.UKD_BP_NAMES_FOR_FILTER
				: [];

		Object.values(edi.constants.DOCUMENT_TYPES).forEach(function (value) {
			if (includedTypes.some((it) => it === value)) {
				docTypes.push({
					id: value,
					name: edi.i18n.getMessage('documents.doctype.' + value)
				});

				if (value === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD && bpNamesUPD.length) {
					//Add all UPD BP names as separate options
					docTypes = docTypes.concat(
						bpNamesUPD.map(function (bpName) {
							return {
								id: bpName,
								name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UPD.' + bpName)
							};
						})
					);
				} else if (value === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD && bpNamesUKD.length) {
					//Add all UKD BP names as separate options
					docTypes = docTypes.concat(
						bpNamesUKD.map(function (bpName) {
							return {
								id: bpName,
								name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UKD.' + bpName)
							};
						})
					);
				}
			}
		});

		Object.values(aperakTypes).forEach(function (value) {
			refDocTypes.push({
				id: value,
				name: edi.i18n.getMessage('documents.doctype.' + edi.constants.APPERAK_DOCUMENT_TYPES_MAP[value])
			});
		});

		Object.values(edi.constants.STATE).forEach(function (value) {
			if (
				includedStates.some((it) => it === value) &&
				!edi.constants.MODULES.DOCUMENTS.FILTER_REMOVED_STATES.some((it) => it === value)
			) {
				var searchMap = ['BUSINESS_STATE', value];
				if (
					value === edi.constants.STATE.SENT ||
					value === edi.constants.STATE.WAIT_RECEIVER_DECISION ||
					value === edi.constants.STATE.READ ||
					value === edi.constants.STATE.SIGNED_BY_CONSUMER
				) {
					searchMap.push('DOCUMENTS_FILTER');
				}
				docStates.push({
					id: value,
					name: edi.i18n.getTr(searchMap)
				});
			}
		});

		docStates.push(
			{
				id: 'ACCEPTED',
				name: edi.i18n.getMessage('documents.status.APERAK_CODE_29')
			},
			{
				id: 'ACCEPTED_WITH_COMMENT_13',
				name: edi.i18n.getMessage('documents.status.APERAK_CODE_13')
			}
		);

		var setAllowBlankForDateRange = function (dateRangeFields, allowBlank) {
			if (dateRangeFields && dateRangeFields.dateFrom && dateRangeFields.dateTo) {
				dateRangeFields.dateFrom.allowBlank = allowBlank;
				dateRangeFields.dateTo.allowBlank = allowBlank;
			}
		};

		var dateFieldsValidator = function () {
			var docDateFields,
				docDatesSelected,
				modifyDateFields,
				modifyDatesSelected,
				creationDateFields,
				creationDatesSelected;
			if (docDateRange && modifyDateRange && creationDateRange) {
				docDateFields = docDateRange.getFields();
				modifyDateFields = modifyDateRange.getFields();
				creationDateFields = creationDateRange.getFields();
				docDatesSelected = docDateFields.dateTo.getValue() || docDateFields.dateFrom.getValue();
				modifyDatesSelected = modifyDateFields.dateTo.getValue() || modifyDateFields.dateFrom.getValue();
				creationDatesSelected = creationDateFields.dateTo.getValue() || creationDateFields.dateFrom.getValue();

				if (
					(docDatesSelected && modifyDatesSelected && creationDatesSelected) ||
					(!docDatesSelected && !modifyDatesSelected && !creationDatesSelected)
				) {
					setAllowBlankForDateRange(docDateFields, false);
					setAllowBlankForDateRange(creationDateFields, false);
					setAllowBlankForDateRange(modifyDateFields, false);
				} else if (creationDatesSelected) {
					setAllowBlankForDateRange(creationDateFields, false);
					setAllowBlankForDateRange(docDateFields, true);
					setAllowBlankForDateRange(modifyDateFields, true);
				} else if (docDatesSelected) {
					setAllowBlankForDateRange(docDateFields, false);
					setAllowBlankForDateRange(creationDateFields, true);
					setAllowBlankForDateRange(modifyDateFields, true);
				} else {
					//If modifyDate selected
					setAllowBlankForDateRange(modifyDateFields, false);
					setAllowBlankForDateRange(docDateFields, true);
					setAllowBlankForDateRange(creationDateFields, true);
				}
			}
			return true;
		};

		let filiationsCombo;
		var enableUnlimitedDatesSearch = edi.permissions.hasPermission('CLIENT_DOCUMENTS_UNLIMITED_PERIOD'),
			attrValueInput = null;

		const formItemsMap = {
			// Doc number field
			number: createSearchTypeSelectionFieldForGrid(
				{
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					fieldConf: {
						name: 'number',
						type: 'tagTextField',
						listeners: {
							change: function (field, value) {
								const combo = field.prev('combo');
								const comboValue = combo?.getValue();
								const onlySingleAllowed = comboValue !== 'number';
								if (onlySingleAllowed && value.length) {
									field.setValue(value.join(' '));
								}
							}
						}
					},
					comboConf: {
						onSelect: function (combo, field) {
							const val = combo.getValue();
							let onlySingleAllowed = val !== 'number';
							field.setValue(
								onlySingleAllowed
									? field.getValue().join(' ')
									: [...new Set(field.getValue()[0]?.split(' '))]
							);
						}
					}
				},
				undefined,
				true
			),
			// Partner combo
			partner: createCombo({
				fieldLabel: edi.i18n.getMessage('documents.form.partner'),
				store: edi.stores.initRelatedOrganizationsFirstEmptyStore(),
				anyMatch: true,
				autoValue: true,
				name: 'partner',
				showQtips: true,
				listeners: {
					select: function (combo, record) {
						var data = record && record.getData ? record.getData() : null;
						if (data && filiationsCombo) {
							filiationsCombo.setVisible(data.hasFilials);

							if (data.hasFilials && filiationsCombo.getStore()) {
								filiationsCombo.getStore().setProxy(
									createProxyConfig({
										type: 'ajax',
										url: edi.utils.compileURL(edi.rest.services.FILIATIONS.GET, {
											headOrgId: data.id
										})
									})
								);

								filiationsCombo.getStore().load();
							}
						}
					}
				}
			}),
			includedFilials: (filiationsCombo = edi.permissions.hasPermission('CLENT_GET_FILIALS')
				? createAutocomplete(
						{
							name: 'includedFilials',
							fieldLabel: edi.i18n.getMessage('documents.partner.filiation'),
							width: '100%',
							hidden: true,
							setLastValueAfterTextClear: true,
							multiSelect: true,
							showQtips: true
						},
						{
							autoLoad: false,
							proxy: createProxyConfig({
								type: 'ajax',
								url: edi.rest.services.FILIATIONS.GET
							}),
							model: edi.models.getModel('ORGANIZATIONS')
						}
				  )
				: null),
			// Doctype combo
			docType: (docTypeCombo = createDocumentTypeFilterSelectForGrid()),
			//Doc status combo
			includeBusinessStates: (docStatusCombo = createCombo({
				name: 'includeBusinessStates',
				fieldLabel: edi.i18n.getMessage('documents.column.status'),
				store: edi.stores.createInlineStore(docStates, 'SIMPLE', undefined, {
					addEmptyRecord: true
				}),
				multiSelect: true,
				setLastValueAfterTextClear: true,
				anyMatch: true
			})),
			deliveryGLN: createSearchTypeSelectionFieldForGrid(
				{
					containerConf: {
						cls: 'edi-form-field'
					},
					fieldLabel: edi.i18n.getMessage('document.delivery.point.gln'),
					fieldConf: {
						name: 'deliveryGLN'
					}
				},
				['exact', 'LikeRight'],
				true
			),
			//Attributes search field
			attribute: (attributesSearchCombo = createContainer({
				layout: {
					type: 'grid',
					area: [[4, 8]],
					gap: 0
				},
				items: [
					createCombo({
						name: 'attribute',
						ignoreChips: true,
						fieldLabel: edi.i18n.getMessage('documents.column.attribute'),
						ignoreAutoFilter: true,
						value: 'orderNumber',
						store: edi.stores.createSimpleInlineStore(
							['orderNumber', 'desadvNumber', 'contractNumber'],
							function (id) {
								return edi.i18n.getMessage('documents.column.' + id);
							}
						),
						listeners: {
							select: function () {
								if (attrValueInput && attrValueInput.getValue()) {
									var autoSearchCheckbox = form.down("checkbox[name='filterFormAutoSearchCheckbox']");
									if (
										form &&
										'function' === typeof form.fireSearch &&
										(!autoSearchCheckbox || !!autoSearchCheckbox.getValue())
									) {
										form.fireSearch();
									}
								}
							}
						}
					}),
					createSearchTypeSelectionFieldForGrid(
						{
							containerConf: {
								cls: 'edi-form-field documents-attribute-filter-value-container'
							},
							fieldConf: {
								name: 'attrNumber',
								chipTitle: edi.i18n.getMessage('documents.column.attribute'),
								multiMode: false
							}
						},
						['exact', 'LikeRight', 'LikeBoth'],
						true
					)
				]
			})),
			[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]: (docDateRange = createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('documents.column.date'),
				maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 92,
				validator: dateFieldsValidator,
				fieldsConfig: {
					common: {
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
						enabledUTC: true
					},
					from: {
						chipTitle: edi.i18n.getMessage('documents.column.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.date.from'),
						name: 'fromDocTime'
					},
					to: {
						chipTitle: ' — ',
						name: 'toDocTime'
					}
				}
			})),
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: (creationDateRange = createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.date.create'),
				maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 92,
				validator: dateFieldsValidator,
				fieldsConfig: {
					common: {
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
						fieldLabel: edi.i18n.getMessage('document.date.create.from'),
						name: 'creationDateFrom'
					},
					to: {
						chipTitle: ' — ',
						name: 'creationDateTo'
					}
				}
			})),
			[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: (modifyDateRange = createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.modify.date'),
				maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 92,
				period: edi.utils.getUserDefaultSearchPeriod('week'),
				validator: dateFieldsValidator,
				fieldsConfig: {
					common: {
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.modify.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.modify.date.from')
					},
					to: {
						chipTitle: ' — '
					}
				}
			}))
		};
		const form = createModuleFilterForm(
			{
				usePlaceholderWrapper: true,
				formItemsMap,
				items: [
					createPanel({
						layout: {
							type: 'grid',
							area: [
								[4, 4, 4],
								[6, 6],
								[6, 6],
								[4, 4, 4]
							]
						},
						scrollable: 'vertical',
						items: [
							formItemsMap.number,
							formItemsMap.partner,
							formItemsMap.includedFilials,
							formItemsMap.docType,
							formItemsMap.includeBusinessStates,
							formItemsMap.deliveryGLN,
							formItemsMap.attribute,
							formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE],
							formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
							formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]
						]
					})
				]
			},
			fireSearch
		);

		attrValueInput = form.down('[name="attrNumber"]');
		form.fireSearch = fireSearch;
		return form;
	};
	var createFieldContainerFn = function (fieldLabel, input, hidden) {
		return createFieldContainer({
			fieldLabel: edi.i18n.getMessage(fieldLabel),
			layout: 'hbox',
			hidden: hidden,
			margin: '0 12 0 17',
			defaults: {
				layout: '100%'
			},
			fieldDefaults: {
				msgTarget: 'under',
				labelAlign: 'top'
			},
			columnWidth: 0.25,
			items: [input],
			cls: 'edi-form-field'
		});
	};

	const certificateHandler = createCertificateHandler();
	const poaHandler = createPoaHandler();

	/**
	 * Find active certificate for document type
	 * @param {String} docType
	 * @returns {null|Object}
	 */
	var findActiveCertForDocType = function (docType) {
		if (allActiveCerts.hasOwnProperty(docType)) {
			return allActiveCerts[docType];
		}
		if (allActiveCerts.hasOwnProperty('ALL')) {
			return allActiveCerts['ALL'];
		}
		return null;
	};
	/**
	 * Check that any active certificate contains at least one document type from records
	 * @param {Array} records
	 * @returns {boolean}
	 */
	var checkExistsActiveCertsForDocs = function (records) {
		for (var i = 0; i < records.length; i++) {
			var recordData = records[i].getData();
			if (findActiveCertForDocType(recordData.type)) {
				return true;
			}
		}
		return false;
	};
	/**
	 * Find all active certificates for current user
	 * @param {function} callback
	 */
	var getAllActiveCerts = function (callback) {
		allActiveCerts = {};
		edi.rest.sendRequest(
			edi.rest.services.USER.SELF.CERTIFICATE.LIST.GET,
			'GET',
			{ gridName: 'without_deactivated' },
			function (data) {
				var i, certs;
				if (data && data.items && data.items.length) {
					certs = data.items;
					for (i = 0; i < certs.length; i++) {
						if (certs[i].header.state == 'ACTIVE') {
							certs[i].data.availableDocTypesForSignature.forEach(function (docType) {
								allActiveCerts[docType] = certs[i];
							});
						}
					}
				}
				callback();
			},
			edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data')
		);
	};
	/**
	 * Creates callback for sign
	 * @param    {Object}    record      grid row record
	 * @param    {Object}    grid        grid where from we receive records
	 * @param    {Function}  callback    callback on success
	 * @param    {Function}  failure     callback on failure
	 */
	var createdPart2DocIds = [];
	var signActionHandler = function (record, grid, callback, failure) {
		return function () {
			var recordData = record.getData();
			var realType = recordData.type;
			var direction = edi.utils.getDocumentDirection(recordData.toOrg, recordData.fromOrg);
			var beforeSign = function (success, failure) {
				var data = {};
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.SEND.PUT,
						{
							documentId: recordData.id
						},
						true
					),
					'PUT',
					Ext.encode(data),
					success,
					failure
				);
			};

			var signCallback = function (failed, errorData) {
				var gridSelModel = grid.getSelectionModel();
				var continueMethod = function () {
					edi.events.documents.fireEvent('sign', recordData);
					gridSelModel.deselect(record);
					callback && 'function' == typeof callback ? callback() : null;
				};
				if (failed) {
					edi.core.logMessage(
						'Error occurred during signing of document ' +
							edi.i18n.getMessage('documents.doctype.' + recordData.type) +
							' ' +
							recordData.number,
						'warn'
					);
					failure(record, errorData);
					gridSelModel.deselect(record);
					callback && 'function' == typeof callback ? callback() : null;
				} else {
					if (
						'object' == typeof edi.constants.PUSH_AFTER_SIGN_RULES &&
						edi.constants.PUSH_AFTER_SIGN_RULES.DOCUMENT_TYPES.some((it) => it === recordData.type) &&
						edi.constants.PUSH_AFTER_SIGN_RULES.STATES.some((it) => it === recordData.state)
					) {
						beforeSign(continueMethod, failure);
					} else {
						continueMethod();
					}
				}
			};
			var startSign = function (contentId, beforeSign) {
				Object.assign(signProperties, {
					signObjectProcessor: function (signature) {
						var signObj = {};
						signObj[edi.constants.BUSINESS_PROCESS_PROPERTIES.SIGNATURE] = signature;
						if (edi.methods.documents.needToAddTaskId(recordData)) {
							signObj[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID] = recordData.state;
						}
						return signObj;
					},
					signRefuse: function () {
						grid.getStore().reload();
						grid.getSelectionModel().deselectAll();
					}
				});

				var nextActionStartSign = function () {
					//case when we are signing a several docs and using an active certificates
					if (useActiveCerts) {
						var currentCert = findActiveCertForDocType(recordData.type);
						if (currentCert) {
							edi.sign.getCertificates({
								callback: function (data) {
									var cert = edi.utils.findObjectInArray(
										data.data,
										edi.constants.CERTIFICATE.ACTIVE_SEARCH_PARAMS.STORE,
										currentCert.data[edi.constants.CERTIFICATE.ACTIVE_SEARCH_PARAMS.DB]
									);
									if (cert) {
										certificateHandler.set(cert, currentCert.data);
										if (currentCert?.poaData?.header) {
											poaHandler.set(currentCert.poaData.header);
										}
										edi.utils.sign(
											recordData,
											moduleData.tab,
											signCallback,
											contentId,
											beforeSign,
											false,
											certificateHandler,
											signProperties,
											poaHandler
										);
									} else {
										signCallback();
									}
								}
							});
						} else {
							notHaveActiveCertType.push(recordData.type);
							signCallback();
						}
					} else {
						edi.utils.sign(
							recordData,
							moduleData.tab,
							signCallback,
							contentId,
							beforeSign,
							false,
							certificateHandler,
							signProperties,
							poaHandler
						);
					}
				};

				if (
					'object' == typeof edi.constants.BEFORE_SIGN_PARENT_DOC &&
					edi.constants.BEFORE_SIGN_PARENT_DOC.some((it) => it === recordData.type)
				) {
					var signIzv = function (id) {
						Object.assign(signProperties, {
							signUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, { documentId: id })
						});
						nextActionStartSign();
					};
					var getParentDocId = function (parentData) {
						var parentDocId = parentData.data.parent.id;
						signIzv(parentDocId);
					};
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
							documentId: recordData.id
						}),
						'GET',
						undefined,
						getParentDocId
					);
				} else if (
					'object' == typeof edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES &&
					edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.DOCUMENT_TYPES.some((it) => it === recordData.type) &&
					edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.STATES.some((it) => it === recordData.state)
				) {
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: recordData.id
							},
							true
						),
						'PUT',
						Ext.encode({}),
						nextActionStartSign
					);
				} else {
					nextActionStartSign();
				}
			};

			if (edi.constants.PARTS_DOC_TYPES.hasOwnProperty(recordData.type)) {
				var objKey =
					edi.constants.STATE.SENDER_REVIEW === recordData.state ||
					edi.constants.STATE.DRAFT === recordData.state
						? 'P1'
						: [
								edi.constants.STATE.RECEIVER_REVIEW,
								edi.constants.STATE.DEBTOR_REVIEW,
								edi.constants.STATE.READ
						  ].some((it) => it === recordData.state) ||
						  createdPart2DocIds.some((it) => it === recordData.id)
						? 'P2'
						: null;

				if (objKey) {
					realType = edi.constants.PARTS_DOC_TYPES[recordData.type][objKey];
				}
			}

			var loading = edi.utils.formatString(edi.i18n.getMessage('document.sign.loading.message'), {
				type: edi.i18n.getMessage('documents.doctype.' + recordData.type),
				number: recordData.number
			});
			moduleData.tab.setLoading(loading);

			if (
				Array.isArray(edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES) &&
				edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES.some((it) => it === recordData.type)
			) {
				if (edi.constants.PARTS_DOC_TYPES.hasOwnProperty(recordData.type)) {
					var startSignPart = function (docPartType) {
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
								documentId: recordData.id
							}),
							'GET',
							undefined,
							function (data) {
								if (data && data.data && data.data.children && data.data.children.length) {
									var headData;
									for (var i = 0; i < data.data.children.length; i++) {
										if (data.data.children[i].type == docPartType) {
											headData = data.data.children[i];
											break;
										}
									}
									if (headData) {
										startSign(
											headData.id,
											recordData.state === edi.constants.STATE.DRAFT ||
												recordData.state === edi.constants.STATE.READ ||
												createdPart2DocIds.some((it) => it === recordData.id)
												? beforeSign
												: null
										);
									} else {
										signCallback(true);
									}
								} else {
									signCallback(true);
								}
							},
							function () {
								signCallback(true);
							}
						);
					};
					if (
						edi.constants.DIRECTIONS.OUTGOING === direction ||
						recordData.hasPart2 ||
						createdPart2DocIds.some((it) => it === recordData.id)
					) {
						startSignPart(realType);
					} else {
						signCallback(true);
					}
				} else if (
					recordData.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER ||
					recordData.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR ||
					recordData.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE
				) {
					dpContainerSign(recordData, moduleData.tab);
				} else {
					var useBeforeSign =
						recordData.state === edi.constants.STATE.DRAFT ||
						recordData.state === edi.constants.STATE.READ ||
						checkBeforeSignRules(recordData.type, recordData.state);
					if (
						edi.utils.getAttributeByName(recordData.attributes, 'bpName') ===
						edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION
					) {
						useBeforeSign = false;
					}
					startSign(undefined, useBeforeSign ? beforeSign : null);
				}
			} else {
				startSign();
			}
		};
	};
	/**
	 * Initializes grid
	 * @returns {Object} Instance of grid
	 */
	var createGridFn = function () {
		var columns = edi.columns.get('documents_all_statuses');
		var actionItems = [
			{
				id: edi.constants.DOCUMENT_ACTIONS.SIGN,
				name: edi.i18n.getMessage('document.sign.document')
			}
		];

		var topBar = undefined;
		if (actionItems.length) {
			var onMenuItemClick = function (id) {
				var options = {
					grid: grid,
					moduleData: moduleData,
					action: id
				};
				if (edi.constants.DOCUMENT_ACTIONS.SIGN === id) {
					Object.assign(options, {
						handler: signActionHandler,
						beforeStart: function (data, callback, options) {
							//before start docFilter
							var processDocs = [];
							var noProcessDocs = [];
							data?.forEach(function (doc) {
								var docData = doc.getData();
								var direction = edi.utils.getDocumentDirection(docData.toOrg, docData.fromOrg);
								if (
									edi.constants.DIRECTIONS.INCOMING == direction &&
									(docData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
										docData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD)
								) {
									if (docData.versionId === '5.01-N') {
										processDocs.push(doc);
									} else {
										doc.tooltip = 'documents.error.upd.old.version';
										noProcessDocs.push(doc);
									}
								} else {
									processDocs.push(doc);
								}
							});
							data = processDocs;
							options.noProcessDocs = noProcessDocs;
							options.data = processDocs;

							Object.assign(signProperties, {
								showActiveCertConfirm: !edi.constants.CERTIFICATE.ONLY_ACTIVE,
								doNotUseDefaultCert: false
							});

							var continueStart = function () {
								edi.document.actions.defaultGridBeforeSignStart(data, callback);
							};

							//if signing several documents and allow active certs - looking for at least one doc with active cert
							if (data.length > 1 && edi.constants.CERTIFICATE.ALLOW_ACTIVE) {
								getAllActiveCerts(function () {
									if (checkExistsActiveCertsForDocs(data)) {
										if (!edi.constants.CERTIFICATE.ONLY_ACTIVE) {
											edi.core.confirm(
												null,
												'use.active.certificate.to.sign.confirmation',
												function () {
													Object.assign(signProperties, { showActiveCertConfirm: false });
													useActiveCerts = true;
													continueStart();
												},
												function () {
													Object.assign(signProperties, {
														showActiveCertConfirm: false,
														doNotUseDefaultCert: true
													});
													useActiveCerts = false;
													continueStart();
												},
												undefined,
												function () {
													useActiveCerts = false;
													return false;
												}
											);
										} else {
											useActiveCerts = true;
											continueStart();
										}
									} else {
										continueStart();
									}
								});
							} else {
								continueStart();
							}
						},
						loadingMessage: 'document.sign.started',
						forceCompleteEvent: true,
						completeCallback: function () {
							if (notHaveActiveCertType.length > 0) {
								var msgTypes = '';
								for (var i = 0; i < notHaveActiveCertType.length; i++) {
									if (i + 1 == notHaveActiveCertType.length) {
										msgTypes +=
											edi.i18n.getMessage('documents.doctype.' + notHaveActiveCertType[i]) + ' ';
									} else {
										msgTypes +=
											edi.i18n.getMessage('documents.doctype.' + notHaveActiveCertType[i]) + ', ';
									}
								}

								edi.core.showInfo(
									edi.i18n.getMessage('sign.document.types.not.available', {
										types: msgTypes
									}),
									undefined,
									undefined,
									{
										closable: false
									}
								);
								notHaveActiveCertType = [];
							}
							useActiveCerts = false;
							certificateHandler.set(null, null);
							poaHandler.set(null);
							poaHandler.setPoaConfirmCheck(false);
						}
					});
				}
				edi.document.actions.documentGridActionProcess(options);
			};

			topBar = createGridActionBar({
				actionCfgs: actionItems,
				defaultHandler: onMenuItemClick
			});
		}
		/**
		 * Checkbox selection model processor linked with grid top bar action items
		 * @param model
		 * @param selected
		 * @param actionItems
		 * @param isProcessible
		 */
		var selectionProcessor = function (model, selected, actionItems, isProcessible) {
			var i,
				j,
				groupActionBtn,
				actionConf,
				totalSum = 0;
			for (i = 0; i < actionItems.length; i++) {
				groupActionBtn = actionItems[i];
				actionConf = groupActionBtn.rulesData;
				if (isProcessible(actionConf)) {
					var allowedDocs,
						allowFromGridConf = edi.document.actions.availableFromGrid[actionConf.id] || {},
						isAvailableFromGrid = true;

					allowedDocs = selected.filter(function (docRecord) {
						isAvailableFromGrid = true;
						var document = docRecord.getData();

						if (!Ext.isEmpty(allowFromGridConf) && Array.isArray(allowFromGridConf)) {
							Ext.each(allowFromGridConf, function (config) {
								if ('function' == typeof config.allow) {
									//Check action for allow performing from grid if doctypes  aren't specified in alloFromGrid conf
									//Or if  doctype exists in specified doctypes
									if (
										!config.documents ||
										(Array.isArray(config.documents) &&
											config.documents.indexOf(document.type) > -1)
									) {
										isAvailableFromGrid = config.allow(document);
									}
								}
							});
						}

						return (
							isAvailableFromGrid &&
							edi.action.isAvailable(actionConf.id, edi.action.getDocumentData(docRecord))
						);
					});

					if (edi.constants.DOCUMENT_ACTIONS.COPY === actionConf.id && allowedDocs.length > 1) {
						groupActionBtn.setDisabled(true);
					} else if (!actionConf.notDisabled) {
						groupActionBtn.setDisabled(!allowedDocs.length);
					}
				}
			}
			for (j = 0; j < selected.length; j++) {
				totalSum += Math.round(selected[j].get('totalSumm') * 100) / 100;
			}
		};
		var grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: edi.rest.services.DOCUMENTS.GET
			},
			storeConfig: {
				pageSize: edi.constants.STATUSES_PER_PAGE,
				model: edi.models.getModel('DOCUMENT'),
				groupField: null,
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				listeners: {
					load: function (store, records) {
						if (records && records.length) {
							var view = grid.getView();
							if (view && view.body && view.body.dom) {
								grid.getSelectionModel().selectAll();
							} else {
								view.on(
									'afterrender',
									function () {
										grid.getSelectionModel().selectAll();
									},
									undefined,
									{
										single: true
									}
								);
							}
						}
					}
				},
				autoLoad: false
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				layout: 'fit',
				border: 1,
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: selectionProcessor
						  })
						: undefined,
				tbar: topBar
			},
			pagingBarConfig: {
				itemsPerPage: edi.constants.STATUSES_PER_PAGE
			}
		});
		return grid;
	};
	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		grid = createGridFn();
		filterForm = createFilterForm();
		modulePanel.add(filterForm);

		modulePanel.add(grid);
		moduleData.tab.add(modulePanel);

		if (typeof initCallBack === 'function') {
			initCallBack();
		}
		filterForm.fireSearch();
	};
	/**
	 * Reloads grid and resets filter form
	 */
	var gridDataChangedHandler = function () {
		grid.getStore().reload();
	};
	/**
	 * Routine that must be done before module destroy
	 */
	var onDestroy = function () {
		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.events.documents.un('change', gridDataChangedHandler);
		edi.events.documents.un('delete', gridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
	};
};
