const DELJIT_LINE_COLUMN = 'deljit_lines';
const DELJIT_SUMMARY_DETAIL_COLUMN = 'deljit_summary';
const DELJIT_LINE_SHIPMENT_CONDITIONS_COLUMN = 'deljit_line_shipment_conditions';

edi.columns.addColumns({
	[DELJIT_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 1
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 2
		},
		itemDescription: {
			text: 'column.item.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 4
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'column.ordered.unit.net.price',
			dataIndex: 'OrderedUnitNetPrice',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 1
		},
		unitGrossPrice: {
			text: 'column.ordered.unit.gross.price',
			dataIndex: 'OrderedUnitGrossPrice',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		unitPacksize: {
			text: 'column.ordered.unit.pack.size',
			dataIndex: 'OrderedUnitPacksize',
			flex: 1,
			hidden: true,
			renderer: 'decimal'
		},
		netAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'OrderedNetAmount',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		taxAmount: {
			text: 'column.ordered.tax.amount',
			dataIndex: 'OrderedTaxAmount',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		grossAmount: {
			text: 'column.gross.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'OrderedGrossAmount',
			flex: 1
		},
		orderedQuantity: {
			text: 'column.ordered.quantity',
			dataIndex: 'OrderedQuantity',
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10',
				decimalPrecision: 3,
				allowDecimals: true,
				allowBlank: false,
				minValue: 1
			},
			renderer: 'decimal'
		}
	},
	[DELJIT_SUMMARY_DETAIL_COLUMN]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'TotalLines',
			flex: 1,
			sortable: false,
			hideable: false
		},
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		},
		TotalOrderedAmount: {
			text: 'column.total.ordered.amount',
			dataIndex: 'TotalOrderedAmount',
			menuDisabled: true,
			flex: 1,
			order: 2,
			renderer: 'decimal'
		}
	},
	[DELJIT_LINE_SHIPMENT_CONDITIONS_COLUMN]: {
		CumulativeQuantity: {
			text: 'line.item.shipment.conditions.CumulativeQuantity',
			dataIndex: 'CumulativeQuantity',
			flex: 1
		},
		QuantityToBeDelivered: {
			text: 'line.item.shipment.conditions.QuantityToBeDelivered',
			dataIndex: 'QuantityToBeDelivered',
			flex: 1
		},
		ExpectedDespatchDateTime: {
			text: 'line.item.shipment.conditions.ExpectedDespatchDate',
			dataIndex: 'ExpectedDespatchDate',
			renderer: function (value, column, record) {
				var time = record.get('ExpectedDespatchTime');
				return (value ? value : '') + (value || time ? ' ' : '') + (time ? time : '');
			},
			flex: 1
		},
		RequestedDeliveryDateTime: {
			text: 'line.item.shipment.conditions.RequestedDeliveryDate',
			dataIndex: 'RequestedDeliveryDate',
			renderer: function (value, column, record) {
				var time = record.get('RequestedDeliveryTime');
				return (value ? value : '') + (value || time ? ' ' : '') + (time ? time : '');
			},
			flex: 1
		}
	}
});

export { DELJIT_LINE_COLUMN, DELJIT_SUMMARY_DETAIL_COLUMN, DELJIT_LINE_SHIPMENT_CONDITIONS_COLUMN };
