// @ts-ignore
import { createLabel } from '@UIkit/components/fields';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel
	// @ts-ignore
} from '@UIkit/components/grid';
// @ts-ignore
import { showConfirm } from '@UIkit/components/modal/MessageBox';
import { filterMethods } from './filter';
import { DOCUMENT_PACKAGES_COLUMNS_MODAL, DOCUMENT_PACKAGES_COLUMNS_NAME } from './columns';
import { DOCUMENT_PACKAGES_MODEL_CONFIG_NAME } from './models';
import { createModulePanel } from '@Components/panels';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { documentPackageApi, DocumentPackageHeader, PackageType } from '@Edi/modules/documentPackages/entities';
import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';
import { documentsModal } from '@Edi/methods/methods';
import { MassDocumentActions } from '@Edi/methods/massDocumentActions';
import { allowActionMethods } from '@Edi/modules/documentPackages/allowActions';
// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal/Modal';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

export class PackagesGridModule {
	filterObject: AnyObject;
	moduleData: ModuleData<DocumentPackageHeader>;
	filterForm: ExtComponent;
	grid: ExtComponent;
	actionsToolbar: ExtComponent | null;
	_gridDataChangedHandler: PackagesGridModule['gridDataChangedHandler'];
	_fireSearch: PackagesGridModule['fireSearch'];
	massDocumentActions: MassDocumentActions;
	_beforeSignStart: MassDocumentActions['beforeSignStart'];
	_signActionHandler: MassDocumentActions['signActionHandler'];
	_signCompleteCallback: MassDocumentActions['signCompleteCallback'];
	_rejectReason: string | undefined; //сохраняем причину отклонения и уточнения в процессе, после завершения обнуляем

	constructor() {
		const me = this;
		me._gridDataChangedHandler = me.gridDataChangedHandler.bind(me);
		me._gridDataChangedHandler = me.gridDataChangedHandler.bind(me);
		me._fireSearch = me.fireSearch.bind(me);
	}

	/**
	 * Module initialization
	 */
	init(data: ModuleData<DocumentPackageHeader>, initCallBack: Function): () => boolean {
		const me = this;
		me.moduleData = data;
		me.massDocumentActions = new MassDocumentActions({
			moduleData: me.moduleData,
			moduleTab: me.moduleData.tab,
			skipMaskHandling: true,
			setModuleLoading: me.setModuleLoading.bind(me)
		});
		me._beforeSignStart = function (
			documents: ExtRecord<DocumentHeader>[],
			callback: Function,
			options: AnyObject
		) {
			options = options || {};
			options.optionsForPart2 = {
				onClose: function () {
					me.setModuleLoading(false);
				}
			};
			return me.massDocumentActions.beforeSignStart.apply(me.massDocumentActions, [documents, callback, options]);
		};
		me._signActionHandler = me.massDocumentActions.signActionHandler.bind(me.massDocumentActions);
		me._signCompleteCallback = me.massDocumentActions.signCompleteCallback.bind(me.massDocumentActions);
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		documentPackageMethods.eventsObserver.on('uploadNewDocument', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.on('update', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.on('changeDocuments', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.on('delete', me._gridDataChangedHandler);
	}

	setModuleLoading(loading: boolean | string | AnyObject) {
		const me = this;
		!me.moduleData.tab.destroyed && me.moduleData.tab.setLoading(loading);
	}

	fireSearch() {
		const me = this;
		if (!me.filterObject) {
			me.filterObject = edi.filters.createGridFilter(
				edi.utils.compileURL(documentPackageApi.endpoints.GET_PACKAGES, {
					gridName: 'all'
				}),
				me.filterForm,
				me.grid,
				filterMethods.createArgsPackages,
				null,
				{
					persistence: {
						enabled: true,
						name: `${me.moduleData.modName}_packages_grids`
					}
				}
			);
		}
		me.filterObject.filter();
	}

	/**
	 * Creates filter form
	 */
	createFilterForm(): ExtComponent {
		const me = this;
		const { formItemsMap, items } = filterMethods.createPackagesFormItems();
		return createModuleFilterForm(
			{
				usePlaceholderWrapper: true,
				formItemsMap,
				items
			},
			me._fireSearch
		) as ExtComponent;
	}

	async signPackage(nextPackage: ExtRecord<DocumentPackageHeader>): Promise<{
		notProcessedPackage: ExtRecord<DocumentPackageHeader> | null;
		notProcessedDocs: ExtRecord<DocumentHeader>[] | null;
	}> {
		const me = this;
		nextPackage.set('isPackage', true);
		return new Promise(async (resolve) => {
			const { success, items, errorResponse } = await documentPackageApi.fetchPackageItems(
				nextPackage.getData().id
			);
			if (success) {
				if (
					allowActionMethods.allowSignPackage({
						packageDocs: items,
						packageHeader: nextPackage.getData()
					})
				) {
					let records: ExtRecord<DocumentHeader>[] = [];
					//отравляем на подписание все документы пакета
					for (let item of items) {
						records.push(edi.models.createInstance('DOCUMENT', item));
					}
					edi.document.actions.documentGridActionProcess({
						action: edi.constants.DOCUMENT_ACTIONS.SIGN,
						moduleData: null,
						initialData: records,
						data: records,
						handler: me._signActionHandler,
						beforeStart: me._beforeSignStart,
						loadingMessage: 'document.sign.started',
						forceCompleteEvent: true,
						dontShowNotProccessed: true,
						skipInfoAndConfirm: true,
						additionalCheckOptions: {
							actionFromPackageModule: true
						},
						completeCallback: function (
							signedDocs: ExtRecord<DocumentHeader>[],
							notSignedDocs: ExtRecord<DocumentHeader>[]
						) {
							if (notSignedDocs.length > 0) {
								resolve({ notProcessedPackage: nextPackage, notProcessedDocs: notSignedDocs });
							} else {
								resolve({ notProcessedPackage: null, notProcessedDocs: null });
							}
						}
					});
				}
				//не можем подписать пакет, статусы документов не подходят
				else {
					resolve({ notProcessedPackage: nextPackage, notProcessedDocs: null });
				}
			} else {
				resolve({ notProcessedPackage: nextPackage, notProcessedDocs: null });
			}
		});
	}

	async signPackages(rowSelected: ExtRecord<DocumentPackageHeader>[]) {
		//делаем первичную обработку пакетов по их статусу
		//Завершен, отклонен уже не подписываем
		let notSignPackages: (ExtRecord<DocumentPackageHeader> | ExtRecord<DocumentHeader>)[] = [];
		let needSignPackages = [];
		for (let row of rowSelected) {
			if (
				row.get('state') === edi.constants.STATE.COMPLETED ||
				row.get('state') === edi.constants.STATE.CANCELED
			) {
				row.set('isPackage', true);
				notSignPackages.push(row);
			} else {
				needSignPackages.push(row);
			}
		}
		//для каждого пакета из needSignPackages получаем список его документов и проверяем можно ли подписать
		//если можно подписать отправляем на подпись последовательно
		//при ошибке добавляем в notSignPackages

		const me = this;
		for (let row of needSignPackages) {
			let notProcessedPackage: ExtRecord<DocumentPackageHeader> | null;
			let notProcessedDocs: ExtRecord<DocumentHeader>[] | null;
			({ notProcessedPackage: notProcessedPackage, notProcessedDocs: notProcessedDocs } = await me.signPackage(
				row
			));
			if (notProcessedPackage) {
				notProcessedPackage.set('isPackage', true);
				notSignPackages.push(notProcessedPackage);
			}
			if (notProcessedDocs) {
				notSignPackages = notSignPackages.concat(notProcessedDocs);
			}
		}

		//обработали все пакеты
		me._signCompleteCallback();
		me.setModuleLoading(false);
		if (notSignPackages.length > 0) {
			//выводим не подписанные пакеты И документы в один грид, заполняя разные столбцы render
			//документы по которым падает ошибка подписания через веб-сокет не попадают в грид
			documentsModal(
				{
					width: MODAL_SIZE.widthLarge,
					title: edi.i18n.getMessage('warn'),
					text: edi.utils.formatString(edi.i18n.getMessage('documents.that.will.not.be.processed'), []),
					model: edi.models.getModel(DOCUMENT_PACKAGES_MODEL_CONFIG_NAME),
					columns: edi.columns.get(DOCUMENT_PACKAGES_COLUMNS_MODAL)
				},
				notSignPackages,
				me
			);
		}
	}

	signActionHandler(rowSelected: ExtRecord<DocumentPackageHeader>[]) {
		const me = this;
		edi.core.confirm(undefined, 'documentPackage.confirm.mass.action', function () {
			me.setModuleLoading(true);
			if (!edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')) {
				edi.core.showError(edi.i18n.getMessage('documents.action.tooltip.not_enough_permissions'));
				return;
			}

			me.signPackages(rowSelected);
		});
	}

	async rejectPackage(nextPackage: ExtRecord<DocumentPackageHeader>): Promise<{
		notProcessedPackage: ExtRecord<DocumentPackageHeader> | null;
		notProcessedDocs: ExtRecord<DocumentHeader>[] | null;
	}> {
		const me = this;
		nextPackage.set('isPackage', true);
		let docsForReject: ExtRecord<DocumentHeader>[] = [];
		let docsNotReject: ExtRecord<DocumentHeader>[] = [];
		const { success, items, errorResponse } = await documentPackageApi.fetchPackageItems(nextPackage.getData().id);
		if (success) {
			//отравляем на подписание все документы пакета
			for (let item of items) {
				const docRecord = edi.models.createInstance('DOCUMENT', item);
				const docCheckOptions = edi.action.getDocumentData(docRecord, null, {
					actionFromPackageModule: true
				});
				const rejectAllowed = edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.REJECT, docCheckOptions);
				const clarifyAllowed = edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.CLARIFY, docCheckOptions);
				if (rejectAllowed || clarifyAllowed) {
					docsForReject.push(docRecord);
				} else {
					docsNotReject.push(docRecord);
				}
			}
			//запускаем отклонение
			if (docsForReject.length > 0) {
				const { notProcessed: notRejectedRecords, reason } = await documentPackageMethods.rejectDocs(
					docsForReject,
					nextPackage.getData(),
					me.massDocumentActions,
					null,
					me.moduleData,
					me._rejectReason //передаем причину может быть undefined
				);
				//если причину не указали при reject промис возвращает reason пустую строку
				//в этом случае не переезаписываем
				if (!!reason && reason != '') {
					me._rejectReason = reason;
				}
				const { notProcessed: notClarifiedRecords, reason: utochReason } =
					await documentPackageMethods.clarifyDocs(
						docsForReject,
						me._rejectReason,
						nextPackage.getData(),
						me.massDocumentActions,
						null,
						me.moduleData,
						true
					);
				//если причину не указали при reject промис возвращает reason пустую строку
				//в этом случае не переезаписываем
				if (!!utochReason && utochReason != '') {
					me._rejectReason = utochReason;
				}
				docsNotReject = [...docsNotReject, ...notRejectedRecords, ...notClarifiedRecords];
				if (docsNotReject.length > 0) {
					return { notProcessedPackage: nextPackage, notProcessedDocs: docsNotReject };
				} else {
					return { notProcessedPackage: null, notProcessedDocs: null };
				}
			}
			//нет документов на отклонение
			//тут можно добавить notProcessedDocs: docsNotReject, но в нем будут все документы пакета, думаю нет смысла в этом
			else {
				return {
					notProcessedPackage: nextPackage,
					notProcessedDocs: null
				};
			}
		} else {
			return {
				notProcessedPackage: nextPackage,
				notProcessedDocs: null
			};
		}
	}

	async rejectPackages(rowSelected: ExtRecord<DocumentPackageHeader>[]) {
		//делаем первичную обработку пакетов по их статусу
		let notRejectPackages: (ExtRecord<DocumentPackageHeader> | ExtRecord<DocumentHeader>)[] = [];
		let needRejectPackages = [];
		for (let row of rowSelected) {
			if (row.get('state') === edi.constants.STATE.DRAFT || row.get('state') === edi.constants.STATE.REJECTED) {
				row.set('isPackage', true);
				notRejectPackages.push(row);
			} else {
				needRejectPackages.push(row);
			}
		}
		//для каждого пакета из notRejectPackages получаем список его документов и проверяем можно ли отклонить
		//отправляем на отклонение последовательно
		//при ошибке добавляем в notRejectPackages

		const me = this;
		for (let row of needRejectPackages) {
			let { notProcessedPackage, notProcessedDocs } = await me.rejectPackage(row);
			if (notProcessedPackage) {
				notProcessedPackage.set('isPackage', true);
				notRejectPackages.push(notProcessedPackage);
			}
			if (notProcessedDocs) {
				notRejectPackages = notRejectPackages.concat(notProcessedDocs);
			}
		}

		//обработали все пакеты
		me._rejectReason = undefined;
		me.massDocumentActions.clearSelectedCertAndPoa();
		if (notRejectPackages.length > 0) {
			//выводим не подписанные пакеты И документы в один грид, заполняя разные столбцы render
			//документы по которым падает ошибка подписания через веб-сокет не попадают в грид
			documentsModal(
				{
					width: MODAL_SIZE.widthLarge,
					title: edi.i18n.getMessage('warn'),
					text: edi.utils.formatString(edi.i18n.getMessage('documents.that.will.not.be.processed'), []),
					model: edi.models.getModel(DOCUMENT_PACKAGES_MODEL_CONFIG_NAME),
					columns: edi.columns.get(DOCUMENT_PACKAGES_COLUMNS_MODAL)
				},
				notRejectPackages,
				me
			);
		}
	}

	rejectActionHandler(rowSelected: ExtRecord<DocumentPackageHeader>[]) {
		const me = this;
		edi.core.confirm(undefined, 'documentPackage.confirm.mass.action', function () {
			me.setModuleLoading(true);
			if (!edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')) {
				edi.core.showError(edi.i18n.getMessage('documents.action.tooltip.not_enough_permissions'));
				return;
			}

			me.rejectPackages(rowSelected);
		});
	}

	deleteActionHandler(rowSelected: ExtRecord<DocumentPackageHeader>[]) {
		const me = this;
		showConfirm({
			msgText: 'documents.package.delete.confirm',
			success: async function () {
				me.setModuleLoading(true);
				const deletedIds: number[] = [];
				let notDeletePackages: ExtRecord<DocumentHeader>[] = [];
				for (let row of rowSelected) {
					const packageId: number = row.getData().id;
					//поверяем статус Черновик
					if (row.get('state') === edi.constants.STATE.DRAFT) {
						const { success, response } = await documentPackageApi.deletePackage(packageId);
						if (success) {
							deletedIds.push(packageId);
						} else {
							edi.rest.getErrorHandler()(response);
						}
					} else {
						// @ts-ignore
						notDeletePackages.push(row);
					}
				}
				deletedIds.forEach((id) =>
					documentPackageMethods.eventsObserver.fireEvent('delete', { packageId: id })
				);
				me.setModuleLoading(false);
				//показываем окно не удаленных пакетов
				if (notDeletePackages.length > 0) {
					documentsModal(
						{
							title: edi.i18n.getMessage('warn'),
							text: edi.utils.formatString(
								edi.i18n.getMessage('documents.that.will.not.be.processed'),
								[]
							),
							model: edi.models.getModel(DOCUMENT_PACKAGES_MODEL_CONFIG_NAME),
							columns: edi.columns.get(DOCUMENT_PACKAGES_COLUMNS_MODAL)
						},
						notDeletePackages,
						me
					);
				}
			}
		});
	}

	createActionHandler() {
		edi.document.actions.openCreateModule(edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE);
	}

	async mergeActionHandler(rowSelected: ExtRecord<DocumentPackageHeader>[]) {
		const packageIds = rowSelected.map((rec) => rec.getData().id);
		const { success, response } = await documentPackageApi.mergePackages(packageIds);
		if (success) {
			packageIds.forEach((packageId) => documentPackageMethods.eventsObserver.fireEvent('delete', { packageId }));
		} else {
			edi.rest.getErrorHandler()(response);
		}
	}

	groupActionsItemClick(id: string) {
		const me = this;
		let rowSelected: ExtRecord<DocumentPackageHeader>[] = me.grid.getSelectionModel().selected?.items ?? [];
		switch (id) {
			case edi.constants.DOCUMENT_ACTIONS.SIGN:
				me.signActionHandler(rowSelected);
				break;
			case edi.constants.DOCUMENT_ACTIONS.REJECT:
				me.rejectActionHandler(rowSelected);
				break;
			case edi.constants.DOCUMENT_ACTIONS.DELETE:
				me.deleteActionHandler(rowSelected);
				break;
			case edi.constants.DOCUMENT_ACTIONS.CREATE:
				me.createActionHandler();
				break;
			case 'MERGE':
				me.mergeActionHandler(rowSelected);
				break;
		}
	}

	printHandler() {
		const me = this;

		const gridSelModel = me.grid.getSelectionModel();
		const dataSelected = gridSelModel?.getSelection();
		dataSelected?.length
			? documentPackageMethods.packagesGroupPrint({
					grid: me.grid,
					maskElement: me.moduleData.tab
			  })
			: edi.methods.documentsGroupPrint(me.grid, me.moduleData, null, 'PACKAGE_DOCUMENTS');
	}

	createGroupActionItems(): AnyObject[] {
		const me = this;
		return [
			{
				id: edi.constants.DOCUMENT_ACTIONS.CREATE,
				name: edi.i18n.getMessage('form.btn.create'),
				notDisabled: edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT'),
				isActionDisabled: () => !edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
			},
			//кнопка подписать всегда доступна, возможность подписания определяется на этапе нажатия на нее
			{
				id: edi.constants.DOCUMENT_ACTIONS.SIGN,
				name: edi.i18n.getMessage('form.btn.sign')
			},
			//кнопка отклонить всегда доступна, возможность подписания определяется на этапе нажатия на нее
			{
				id: edi.constants.DOCUMENT_ACTIONS.REJECT,
				name: edi.i18n.getMessage('document.reject.document')
			},
			{
				id: 'MERGE',
				name: edi.i18n.getMessage('documentPackage.action.merge'),
				additionalData: {
					glyph: edi.constants.ICONS.LIBRARY_ADD
				},
				isActionDisabled: (btn: ExtComponent, selected: ExtRecord<DocumentPackageHeader>[]) => {
					if (!edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT') || selected.length < 2) {
						return true;
					}
					const type = selected[0].get('type');
					const selectedAreSameType = selected.every((rec) => rec.get('type') === type);
					const selectedAreDraft = selected.every((rec) => rec.get('state') === edi.constants.STATE.DRAFT);
					return !(selectedAreSameType && selectedAreDraft);
				}
			},
			{
				id: edi.constants.DOCUMENT_ACTIONS.DELETE,
				name: edi.i18n.getMessage('form.btn.delete'),
				isActionDisabled: (btn: ExtComponent, selected: ExtRecord<DocumentPackageHeader>[]) => {
					if (!edi.permissions.hasPermission('DELETE_PACKAGE_OBJECT')) {
						return true;
					}
					const selectedDraft = selected.some((rec) => rec.get('state') === edi.constants.STATE.DRAFT);
					return !selectedDraft;
				}
			},
			{
				id: edi.constants.DOCUMENT_ACTIONS.PRINT,
				name: edi.i18n.getMessage('action.print'),
				notDisabled: true,
				handler: () => me.printHandler()
			}
		];
	}

	createGridActionsToolbar(): PackagesGridModule['actionsToolbar'] {
		const me = this;
		me.actionsToolbar = createGridActionBar({
			actionCfgs: me.createGroupActionItems(),
			defaultHandler: me.groupActionsItemClick.bind(me)
		}) as ExtComponent | null;
		return me.actionsToolbar;
	}

	/**
	 * Initializes grid
	 */
	createGridFn(): ExtComponent {
		const me = this;
		const columns = edi.columns.get(DOCUMENT_PACKAGES_COLUMNS_NAME);
		const actions = me.createActionColumn();
		if (actions !== null) {
			columns.push(actions);
		}

		const totalsLabel = createLabel({
			text: edi.i18n.getMessage('records.selected.totals', {
				selectedRows: 0
			}),
			cls: 'edi-tbar-total-label-right'
		});

		const topBar = me.createGridActionsToolbar();

		return createGrid({
			saveSorters: true,
			savedSortersName: 'document_packages',
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: edi.models.getModel(DOCUMENT_PACKAGES_MODEL_CONFIG_NAME),
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: false
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				layout: 'fit',
				border: 1,
				listeners: {
					celldblclick: function (
						_view: ExtComponent,
						_td: unknown,
						_cellIndex: number,
						record: ExtRecord<DocumentPackageHeader>
					) {
						edi.document.actions.openDetailsModule(
							edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE,
							record.getData()
						);
					}
				},
				selModel: topBar?.menuActionItemsCnt
					? createGridCheckboxSelectionModel({
							topBar: topBar,
							selectionProcessor: function (
								selModel: ExtComponent,
								selected: ExtRecord<DocumentPackageHeader>[],
								actionItems: ExtComponent[]
							) {
								edi.methods.gridSelModules(selModel, selected, actionItems, topBar, totalsLabel);
							}
					  })
					: undefined,
				tbar: topBar
			},
			pagingBarConfig: {
				totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined,
				addButtonsConfig: createButton({
					tooltip: edi.i18n.getMessage('document.group.print.documents'),
					cls: `${BUTTON_CLS.light} edi-label-button test-grid-paginator-group-print`,
					margin: '0 0 0 35',
					glyph: edi.document.actions.getActionIcon(edi.constants.DOCUMENT_ACTIONS.GROUP_PRINT),
					handler: () => me.printHandler()
				})
			}
		}) as ExtComponent;
	}

	/**
	 * Creates action column of the grid
	 */
	createActionColumn() {
		const items = [
			{
				glyph: edi.constants.ICONS.DETAILS,
				testCls: 'test-action-column-details',
				handler: function (
					_view: ExtComponent,
					_rowIndex: number,
					_colindex: number,
					_actionItem: unknown,
					_event: unknown,
					record: ExtRecord<DocumentPackageHeader>
				) {
					edi.document.actions.openDetailsModule(
						edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE,
						record.getData()
					);
				}
			},
			{
				glyph: edi.constants.ICONS.EDIT,
				testCls: 'test-action-column-edit',
				handler: function (
					_view: ExtComponent,
					_rowIndex: number,
					_colindex: number,
					_actionItem: unknown,
					_event: unknown,
					record: ExtRecord<DocumentPackageHeader>
				) {
					edi.document.actions.openCreateModule(
						edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE,
						record.getData(),
						true
					);
				},
				isActionDisabled: function (
					_view: ExtComponent,
					_rowIndex: number,
					_colindex: number,
					_actionItem: unknown,
					record: ExtRecord<DocumentPackageHeader>
				) {
					const checkOptions = edi.action.getDocumentData(
						edi.models.createInstance('DOCUMENT', record.getData())
					);
					checkOptions.docType = edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE;
					checkOptions.packageHeader = record.getData();
					return !edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.EDIT, checkOptions);
				}
			}
		];
		return createActionsColumnConfig({
			items: items,
			width: edi.utils.getActionColumnWidth(items.length)
		});
	}

	/**
	 * Renders page layout
	 */
	renderData(initCallBack?: Function) {
		const me = this;
		const modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		}) as ExtComponent;

		me.grid = me.createGridFn();
		me.filterForm = me.createFilterForm() as ExtComponent;
		modulePanel.add(me.filterForm);

		modulePanel.add(me.grid);
		me.moduleData.tab.add(modulePanel);

		if (typeof initCallBack === 'function') {
			initCallBack();
		}
		me._fireSearch();
	}

	/**
	 * Reloads grid and resets filter form
	 */
	gridDataChangedHandler() {
		const me = this;
		me.fireSearch();
	}

	/**
	 * Routine that must be done before module destroy
	 */
	onDestroy() {
		const me = this;
		if (me.filterObject && me.filterObject.searchTimeout) {
			clearTimeout(me.filterObject.searchTimeout);
		}
		documentPackageMethods.eventsObserver.un('uploadNewDocument', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.un('update', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.un('changeDocuments', me._gridDataChangedHandler);
		documentPackageMethods.eventsObserver.un('delete', me._gridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

edi.modules['document.packages'] = PackagesGridModule;
edi.modulesCfg['document.packages'] = {
	title: 'navigation.document.packages',
	modName: 'document.packages',
	folder: 'edi/modules/documentPackages',
	permissions: ['READ_PACKAGE_OBJECT']
};
