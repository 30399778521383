import { createCombo } from '@Components/fields';
import { createDateRangeField } from '@UIkit/components/fields';
import { createPanel } from '@Components/panels';

export const invitationsFilter = {
	createFormItems: function () {
		const formItemsMap = {
			direction: createCombo({
				fieldLabel: edi.i18n.getMessage('documents.column.direction'),
				name: 'direction',
				store: edi.stores.createMemoryStore(
					[
						{
							id: 'incoming',
							name: edi.i18n.getMessage('documents.direction.incoming')
						},
						{
							id: 'outgoing',
							name: edi.i18n.getMessage('documents.direction.outgoing')
						}
					],
					'SIMPLE',
					true
				)
			}),
			status: createCombo({
				fieldLabel: edi.i18n.getMessage('column.status'),
				name: 'status',
				store: edi.stores.createSimpleInlineStore(
					['DRAFT', 'ACCEPTED', 'REJECTED'],
					function (id) {
						const keyVal = id === 'DRAFT' ? 'AWAITING_ACCEPT' : id;
						return edi.i18n.getTr(['STATE', keyVal]);
					},
					true
				)
			}),
			[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.modify.date'),
				period: edi.utils.getUserDefaultSearchPeriod('month'),
				fieldsConfig: {
					common: {
						invalidText: edi.i18n.getMessage('invalid.date.format.fns'),
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						prefix: 'modifyDate'
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.modify.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.modify.date.from')
					},
					to: {
						chipTitle: ' — '
					}
				}
			})
		};
		const items = [
			createPanel({
				layout: {
					type: 'grid',
					area: [[6, 6], [6]]
				},
				items: [
					formItemsMap.direction,
					formItemsMap.status,
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]
				]
			})
		];
		return {
			formItemsMap,
			items
		};
	},
	createArgs: function (values) {
		const res = {};
		if (values['direction'] === 'incoming') {
			res.gridName = 'inbox';
		} else if (values['direction'] === 'outgoing') {
			res.gridName = 'outbox';
		}
		if (values['status']) {
			res.status = values['status'];
		}
		if (values['modifyDate-dateFrom']) {
			res.dateFrom = edi.utils.getSearchTime(values['modifyDate-dateFrom'], edi.constants.DATE_FORMAT.SERVER);
		}
		if (values['modifyDate-dateTo']) {
			res.dateTo = edi.utils.getSearchTime(
				values['modifyDate-dateTo'],
				edi.constants.DATE_FORMAT.SERVER,
				edi.constants.SEARCH_DAY_IN_MS
			);
		}
		return res;
	}
};
