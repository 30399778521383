import { createDateRangeField } from '@UIkit/components/fields';
import { createPanel } from '@Components/panels';

const filterMethods = {
	createFormItems: function () {
		const formItemsMap = {
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('column.creation.date'),
				fieldsConfig: {
					common: {
						invalidText: edi.i18n.getMessage('invalid.date.format.fns'),
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
					},
					from: {
						chipTitle: edi.i18n.getMessage('column.creation.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.created.to')
					},
					to: {
						chipTitle: ' — '
					}
				}
			})
		};
		const items = [
			createPanel({
				layout: {
					type: 'grid',
					area: [[6]]
				},
				items: [formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]]
			})
		];
		return {
			formItemsMap,
			items
		};
	},
	createArgs: function (values) {
		if (values.dateFrom) {
			values.dateFrom = edi.utils.getSearchTime(values.dateFrom, edi.constants.DATE_FORMAT.SERVER);
		}
		if (values.dateTo) {
			values.dateTo = edi.utils.getSearchTime(
				values.dateTo,
				edi.constants.DATE_FORMAT.SERVER,
				edi.constants.SEARCH_DAY_IN_MS
			);
		}
		return values;
	}
};

export { filterMethods };
