/**
 * Modules configuration for fns
 * @author Vsevolod Liapin
 */

Ext.namespace('edi.modulesCfg');
new (function () {
	var path = 'fns/modules';
	Object.assign(edi.modulesCfg, {
		'document.create.fns_upd_5_01n': {
			title: 'document.add.fns_upd_5_01n',
			modName: 'document.create.fns_upd_5_01n',
			folder: path + '/documents/EDI_FNS_UPD',
			permissions: ['CLIENT_EDI_FNS_UPD_5_01_N_CREATE'],
			permissionsEdit: ['EDIT_EDI_FNS_UPD']
		},
		'document.create.fns_upd_5_02n': {
			title: 'document.add.fns_upd_5_02n',
			modName: 'document.create.fns_upd_5_02n',
			folder: path + '/documents/EDI_FNS_UPD',
			permissions: ['CLIENT_EDI_FNS_UPD_5_02_N_CREATE'],
			permissionsEdit: ['EDIT_EDI_FNS_UPD']
		},
		'document.create.fns_ukd_5_01n': {
			title: 'document.add.fns_ukd_5_01n',
			modName: 'document.create.fns_ukd_5_01n',
			folder: path + '/documents/EDI_FNS_UKD',
			permissions: ['CREATE_EDI_FNS_UKD'],
			permissionsEdit: ['EDIT_EDI_FNS_UKD']
		},
		'document.create.service_act': {
			title: 'document.add.service_act',
			modName: 'document.create.service_act',
			folder: path + '/documents/EDI_FNS_UPD_SERVICE_AKT',
			permissions: ['CREATE_EDI_FNS_UPD_SERVICE_AKT'],
			permissionsEdit: ['EDIT_EDI_FNS_UPD_SERVICE_AKT']
		},
		'document.details.fns_upd': {
			title: 'document.details.fns_upd',
			modName: 'document.details.fns_upd',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_FNS_UPD',
			permissions: ['READ_EDI_FNS_UPD']
		},
		'document.details.fns_upd_5_01n': {
			title: 'document.details.fns_upd_5_01n',
			modName: 'document.details.fns_upd_5_01n',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_FNS_UPD',
			permissions: ['READ_EDI_FNS_UPD']
		},
		'document.details.fns_upd_5_02': {
			title: 'document.details.fns_upd_5_02',
			modName: 'document.details.fns_upd_5_02',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_FNS_UPD',
			permissions: ['READ_EDI_FNS_UPD']
		},
		'document.details.fns_upd_5_02n': {
			title: 'document.details.fns_upd_5_02n',
			modName: 'document.details.fns_upd_5_02n',
			folder: path + '/documents/EDI_FNS_UPD',
			permissions: ['READ_EDI_FNS_UPD']
		},
		'document.details.fns_ukd': {
			title: 'document.details.fns_ukd',
			modName: 'document.details.fns_ukd',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_FNS_UKD',
			permissions: ['READ_EDI_FNS_UKD']
		},
		'document.details.fns_ukd_5_01n': {
			title: 'document.details.fns_ukd_5_01n',
			modName: 'document.details.fns_ukd_5_01n',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_FNS_UKD',
			permissions: ['READ_EDI_FNS_UKD']
		},
		'document.details.service_act': {
			title: 'document.details.service_act',
			modName: 'document.details.service_act',
			highlightMenuId: 'documents',
			folder: path + '/documents/EDI_FNS_UPD_SERVICE_AKT',
			permissions: ['CREATE_EDI_FNS_UPD_SERVICE_AKT']
		}
	});
})();
