import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createNumberField,
	createTextField,
	CHECKBOX_CLS,
	createLabel
} from '@UIkit/components/fields';
import { createButton as createUIButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createFieldBlock, createForm, createContainer, createButtonContainer } from '@UIkit/components/panels';
import { createActionsPanel, createAddModulePanel } from '@Components/panels';
import { createCreateAndSendButton, createSendButton } from '@Components/buttons';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { dsfModuleMethods } from './methods';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { showApprovalModal } from '@Ediweb/modules/APPROVAL_DOCUMENTS/methods';
import { coreMethods } from '@Core/commons';

/**
 * Class for new free format document creation
 */
Ext.namespace('edi.modules');
edi.modules['document.create.dsf'] = function () {
	let moduleData,
		fields = {},
		fieldsmap = {},
		uploadForm,
		parentId,
		id,
		isEdit = false,
		isCopy = false,
		documentData,
		fileValue,
		numberGenerationPatterns,
		receiverId,
		form,
		receiverOrg,
		initialData,
		costDecimals,
		doctype = dsfModuleMethods.DEFAULT_DSF,
		userData = edi.core.getUserData(),
		receiverPrefix = 'receiver',
		senderPrefix = 'sender',
		isNumberAutoGenerated = false,
		copyReceiverId,
		copySenderId,
		maxFileSize,
		formLayout,
		topPath = '//DocumentFreeFormat/',
		customFieldsObj,
		createAndSignButton,
		createAndSendButton,
		createAndSignSendButton,
		createAndSignSendMenuButton,
		fileDropper,
		selectedCertificate,
		customFieldsContainer;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		costDecimals = coreMethods.getUserOrgCostDecimals({ defaultCostDecimals: dsfModuleMethods.DECIMAL_PRECISION });

		createMaps();
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	let certificateHandler = {
		get: () => selectedCertificate,
		set: (cert) => (selectedCertificate = cert)
	};

	/**
	 * Дополнительные поля
	 */
	const createCustomFieldsContainer = function () {
		return (customFieldsContainer = createContainer({}));
	};

	let getCompanyModalConf = function (additionalConf) {
		let bankFieldListeners = {
			regex: edi.constants.VALIDATORS.BANK_ACC,
			invalidText: edi.i18n.getMessage('invalid.value.bank.acc'),
			allowBlank: true
		};

		// EW begin
		additionalConf = {
			...additionalConf,
			address: {
				company_address: {
					fields: {
						codeStateAddressRegisterAddress: null
					}
				}
			}
		};
		// EW end

		return edi.selectors.getGridFNSModalConf(
			{
				main: {
					company_okpo: edi.selectors.defaultsFNS.main.company_okpo,
					company_inn_kpp: {
						fields: {
							kpp: {
								allowBlank: true
							}
						}
					},
					individual_inn: {
						allowBlank: false
					}
				},
				address: {
					region: {
						fieldConfig: {
							validator: function () {
								return dsfModuleMethods.isWithoutRegionValidation(doctype)
									? true
									: !!this.getValue() || edi.i18n.getMessage('error.value.mandatory');
							}
						}
					}
				},
				bank: {
					bank_acc_select: Ext.applyIf(
						{
							fieldConfig: {
								listeners: {
									render: (cmp) =>
										edi.selectors.fieldControls.setBankNameAllowBlankByBankAccValue({
											bankAccField: cmp
										}),
									change: (cmp, newValue) =>
										edi.selectors.fieldControls.setBankNameAllowBlankByBankAccValue({
											bankAccField: cmp,
											bankAccValue: newValue
										})
								}
							}
						},
						bankFieldListeners
					),
					bank_corr_acc: bankFieldListeners,
					bank_id: Ext.applyIf(
						{
							regex: edi.constants.VALIDATORS.BIK,
							invalidText: edi.i18n.getMessage('invalid.value.bank.id'),
							listeners: {
								change: function (cmp, newValue) {
									edi.methods.fillBankNameByBik(cmp, newValue);
								}
							}
						},
						bankFieldListeners
					),
					bank_name: {
						listeners: {
							render: function (cmp) {
								const modal = cmp.modalInstance,
									form = modal ? modal.formPanel : null,
									value = cmp.getValue();
								if (!form) {
									return;
								}
								let field = form.down('textfield[name=bank_acc]');
								field.allowBlank = !value.length;
								field.validate();
							},
							change: function (cmp, newValue) {
								const modal = cmp.modalInstance,
									form = modal ? modal.formPanel : null;
								if (!form) {
									return;
								}
								let field = form.down('textfield[name=bank_acc]');
								field.allowBlank = !newValue.length;
								field.validate();
							}
						}
					}
				}
			},
			additionalConf
		);
	};

	let updateCompanySelectorConfigs = function () {
		if (!doctype) {
			return;
		}
		if (fields.receiver) {
			cacheValuesForCompanySelector(fields.receiver);
			setConfigForCompanySelector(fields.receiver, doctype, receiverPrefix);
			setNameForCompanySelector(fields.receiver, 'document.dsf.receiver.' + doctype);
		}
		if (fields.sender) {
			cacheValuesForCompanySelector(fields.sender);
			setConfigForCompanySelector(fields.sender, doctype, senderPrefix);
			setNameForCompanySelector(fields.sender, 'document.dsf.sender.' + doctype);
		}

		let isDsfFnsIvoice = doctype === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE;
		if (fields.ship) {
			setConfigForCompanySelector(fields.ship, doctype, 'consignor');
			setNameForCompanySelector(fields.ship, isDsfFnsIvoice ? 'document.seller' : 'document.ship.from');
		}
		if (fields.consignee) {
			setConfigForCompanySelector(fields.consignee, doctype, 'consignee');
			setNameForCompanySelector(fields.consignee, isDsfFnsIvoice ? 'document.buyer' : 'documents.torg.consignee');
		}
	};

	let setNameForCompanySelector = function (cmp, title) {
		let fieldBlock = cmp ? cmp.getFieldBlock() : null;
		let modalConf = cmp ? cmp.modalConf : null;
		if (fieldBlock) {
			fieldBlock.setTitle(edi.i18n.getMessage(title));
		}
		if (modalConf) {
			modalConf.title = edi.i18n.getMessage(title);
		}
	};

	const cacheValuesForCompanySelector = function (cmp) {
		if (!cmp) {
			return;
		}

		const fieldValues = Ext.clone(cmp.fieldValues);
		//При выборе другой Орг стираем oldValues
		if (cmp.oldValues?.id !== fieldValues?.id) {
			cmp.oldValues = {};
		}
		edi.utils.clearEmptyValues(fieldValues);
		Ext.merge(cmp.oldValues, fieldValues);
	};

	let setConfigForCompanySelector = function (cmp, docType, prefix) {
		cmp.modalConf = getCompanyModalConf(docType);
		cmp.fieldsMap = edi.selectors.getDSFFieldsMap(prefix);
		cmp.updateHiddenFields();
		if (cmp.isVisible() && !cmp.isEmptyValues()) {
			cmp.is_valid = isValidOrganization(cmp.fieldValues);
		}
		checkValid();
	};

	const isValidOrganization = function (values) {
		let isValid,
			isValidDoctypes = dsfModuleMethods.isWithoutRegionValidation(doctype);
		const isValidIndividual =
				values['individual_firstname'] && values['individual_lastname'] && values['individual_inn'],
			isValidPerson =
				values['person_firstname'] &&
				values['person_lastname'] &&
				values['person_inn'] &&
				values['person_snils'],
			isValidAddress = isValidDoctypes
				? true
				: values['addr_rus_region'] ||
				  (values['addr_for_country'] && values['addr_for_text']) ||
				  values['addr_code_gar'],
			isValidLegal = values['company_name'] && values['company_inn'],
			isValidBank = (values['bank_acc'] && values['bank_name']) || (!values['bank_acc'] && !values['bank_name']);
		isValid = !!(
			isValidAddress &&
			values['company_iln'] &&
			(isValidLegal || isValidIndividual || isValidPerson) &&
			isValidBank
		);

		return isValid;
	};

	/**
	 * create dsf types store limited by permissions
	 */
	const createDSFTypesStore = function () {
		let rawTypes = edi.constants.CREATE_ACTUAL_FREE_DOCUMENT_TYPES;
		const packageTypes = moduleData.initData?.packageOptions?.allowedDsfTypes;
		if (packageTypes) {
			rawTypes = rawTypes.filter((type) => packageTypes.includes(type));
		}
		const types = [];
		rawTypes.forEach(function (docType) {
			const permission =
				edi.constants.DOCUMENT_ACTIONS_PERMISSIONS_CHECK_MAP[edi.constants.DOCUMENT_ACTIONS.CREATE] +
				'_' +
				docType;
			if (edi.permissions.hasPermission(permission)) {
				types.push({
					id: docType,
					name: edi.i18n.getMessage('documents.doctype.' + docType)
				});
			}
		});
		return edi.stores.createInlineStore(types);
	};
	/**
	 * Creates price catalogues types store
	 */
	const createPriceCatalogueTypesStore = function () {
		let i,
			types = [];
		for (i = 0; i < edi.constants.PRICE_CATALOGUE_TYPES.length; i++) {
			types.push({
				id: edi.constants.PRICE_CATALOGUE_TYPES[i],
				name: edi.i18n.getMessage('document.dsf.price.catalogue.type.' + edi.constants.PRICE_CATALOGUE_TYPES[i])
			});
		}
		return edi.stores.createInlineStore(types, 'SIMPLE');
	};

	const isX5Doc = function (doctype) {
		return !!edi.constants.DSF_TYPES_FROV.find((type) => {
			return type === doctype;
		});
	};
	/**
	 * Creates dsf form
	 * @returns {Object}
	 */
	const createModuleForm = function (documentData) {
		let data = moduleData.initData.data,
			dateValue = edi.utils.getObjectProperty(documentData, 'date'),
			autogenCheckbox,
			processAutoGenerationStateChange = function (checkbox, isChecked) {
				numberDocument.setEmptyText(
					isChecked ? edi.i18n.getMessage('document.generation.numbers.text') : edi.i18n.getMessage('nr')
				);
				if (isChecked) {
					numberDocument.setValue('');
				}
				numberDocument.setDisabled(isChecked);
				numberDocument.allowBlank = isChecked;
				numberDocument.validate();
			};

		let numberDocument = createTextField({
			fieldLabel: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('nr'),
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			value:
				documentData && data
					? edi.utils.getObjectProperty(data, 'number') +
					  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
					: undefined,
			name: 'number',
			maxLength: isX5Doc(isEdit || isCopy ? data.type : doctype) ? 35 : 255
		});

		let autogenCheckboxContainer = isEdit
			? null
			: createContainer({
					items: [
						(autogenCheckbox = createCheckbox({
							boxLabel: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
							inputValue: true,
							checked: isNumberAutoGenerated,
							name: 'numberAutoGenerated',
							listeners: {
								change: processAutoGenerationStateChange
							}
						}))
					]
			  });
		if (autogenCheckboxContainer && !isNumberAutoGenerated) {
			autogenCheckboxContainer.hide();
		}

		let title = createLabel({
			cls: 'heading_02',
			text: edi.i18n.getMessage(isEdit ? 'document.edit.dsf' : 'document.add.dsf')
		});

		let headData = createFieldBlock({
			title: edi.i18n.getMessage('document.data'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 4, 2]]
			},
			items: [
				createCombo({
					fieldLabel: edi.i18n.getMessage('document.type'),
					allowBlank: false,
					name: 'type',
					store: createDSFTypesStore(),
					readOnly: isEdit,
					value: isEdit || isCopy ? data.type : doctype,
					autoSelect: true,
					listeners: isEdit
						? undefined
						: {
								select: function (comp) {
									let value = comp.getValue();
									let doctypeChanged = value !== doctype;
									doctype = value;

									let continueFn = function () {
										if (doctypeChanged) {
											showHideControls();
											updateCompanySelectorConfigs();
											numberDocument.maxLength = isX5Doc(isEdit || isCopy ? data.type : doctype)
												? 35
												: 255;
											if (numberGenerationPatterns) {
												isNumberAutoGenerated = false;
												for (let i = 0; i < numberGenerationPatterns.items.length; i++) {
													const numberingRule = numberGenerationPatterns.items[i];
													if (
														numberingRule.typeDoc === doctype &&
														'ALLOW' === numberingRule.policy
													) {
														isNumberAutoGenerated = true;
														break;
													}
												}
												processAutoGenerationStateChange(
													autogenCheckbox,
													isNumberAutoGenerated
												);
												autogenCheckbox.setValue(isNumberAutoGenerated);
												isNumberAutoGenerated
													? autogenCheckboxContainer.show()
													: autogenCheckboxContainer.hide();
											}
											form.isValid();
										}
										if (!doctype) {
											comp.setValue(null);
										}
									};
									let receiverId = fields.receiver.getValues().id || fields.receiver.selectedOrg?.id;
									let senderId = fields.sender.getValues().id || fields.sender.selectedOrg?.id;

									if (isCopy) {
										receiverId = copyReceiverId;
										senderId = copySenderId;
									}

									if (receiverId && senderId && doctype) {
										edi.methods.custom_fields.initCustomFields({
											customFieldsObj,
											docType: doctype,
											toOrgId: receiverId,
											fromOrgId: senderId,
											container: customFieldsContainer,
											topPath: topPath,
											finishCallback(obj) {
												customFieldsObj = obj;
												form.isValid();
												continueFn();
											},
											fail() {
												form.isValid();
												continueFn();
											}
										});
									} else {
										continueFn();
									}
								}
						  }
				}),
				numberDocument,
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					format: edi.constants.DATE_FORMAT.FNS,
					submitFormat: edi.constants.DATE_FORMAT.CLIENT,
					allowBlank: false,
					name: 'date',
					value:
						dateValue && !isCopy
							? edi.utils.formatDate(
									dateValue,
									edi.constants.DATE_FORMAT.CLIENT,
									edi.constants.DATE_FORMAT.SERVER
							  )
							: new Date()
				})
			]
		});

		let documentFileBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.file'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 0
			},
			items: [
				(uploadForm = createForm({
					layout: {
						type: 'grid',
						area: [6]
					},
					submitEmptyText: false,
					bodyPadding: 0,
					items: [
						(fileDropper = createFileDropper({
							name: 'attachment',
							itemId: 'fileDropper',
							allowBlank: false,
							maxFileSize,
							checkMaxFileSize: true,
							allowZeroFileSize: false,
							getAllowedFileTypesText: () =>
								edi.i18n.getMessage('document.dsf.upload.file.types.and.limit', {
									limit: maxFileSize
								}),
							fileData:
								isEdit && !!documentData.file
									? {
											fileName: documentData.file.fileName,
											fileDate: edi.utils.formatDate(
												documentData.file.modifyDate,
												edi.constants.DATE_FORMAT.FNS
											),
											fileTime: edi.utils.formatDate(
												documentData.file.modifyDate,
												edi.constants.DATE_FORMAT.TIME
											),
											fileSize: edi.utils.formatFileSize(documentData.file.fileSize)
									  }
									: null
						}))
					]
				}))
			]
		});

		let fieldValues = edi.converters.convertOrgToPartie(userData.org),
			isValidSender = isValidOrganization(fieldValues);

		const createOS = function (selectorConf) {
			Ext.applyIf(selectorConf, {
				cls: '',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				margin: 0,
				disableCommonAddressTitleRenderer: true,
				downloadBankAccountsData: true,
				hideTpls: ['bank', 'contact'],
				useHiddenFields: true,
				disableAutoValidation: true,
				fieldValues: isEdit || isCopy ? documentData : null,
				disableCommonAddressRenderer: true,
				forceChangeFields: {
					bank_id: true,
					bank_corr_acc: true,
					bank_name: true,
					bank_acc: true
				}
			});
			return createOrgSelector(selectorConf);
		};

		if (isEdit || isCopy) {
			Ext.merge(documentData, {
				sender: {
					address: {
						addr_category: documentData?.reference?.addressTypeSender
					}
				},
				receiver: {
					address: {
						addr_category: documentData?.reference?.addressTypeReceiver
					}
				},
				consignor: {
					address: {
						addr_category: documentData?.reference?.addressTypeShip
					}
				},
				consignee: {
					address: {
						addr_category: documentData?.reference?.addressTypeConsignee
					}
				}
			});
		}
		const senderGLN = edi.utils.getObjectProperty(documentData, 'sender.iln');
		const senderOrg = edi.utils.getOrg({ orgILN: senderGLN }) || userData.org;
		if (senderOrg && documentData?.[senderPrefix]?.bankInfo) {
			senderOrg.accounts = [documentData[senderPrefix].bankInfo];
		}
		const senderData = edi.converters.convertOrgToLegacyPartie(senderOrg);
		fields.sender = createOS({
			itemId: 'sender',
			allowBlank: false,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: copySenderId ?? userData.org.id
			}),
			readOnly: isEdit,
			fieldValues: isEdit || isCopy ? documentData : fieldValues,
			selectedOrgValues: senderData,
			selectedOrg: senderOrg ? senderOrg : null,
			is_valid: isEdit || isCopy || isValidSender,
			valuesByMap: isEdit || isCopy,
			callback: function (values) {
				fields.sender.is_valid = isValidOrganization(values);
				checkValid();
				fields.sender.isValid();
			},
			fieldsMap: edi.selectors.getDSFFieldsMap(senderPrefix),
			modalConf: getCompanyModalConf()
		});

		const preselectedReceiverIdFromPackage = moduleData.initData?.packageOptions?.receiverOrgId;
		if (preselectedReceiverIdFromPackage) {
			receiverOrg = edi.utils.getOrg({
				orgId: preselectedReceiverIdFromPackage
			});
			receiverId = preselectedReceiverIdFromPackage;
		}
		if (receiverOrg && documentData?.[receiverPrefix]?.bankInfo) {
			receiverOrg.accounts = [documentData[receiverPrefix].bankInfo];
		}
		const receiverOrgValues = receiverOrg ? edi.converters.convertOrgToPartie(receiverOrg) : null;
		fields.receiver = createOS({
			itemId: 'receiver',
			relationsOnly: true,
			allowBlank: false,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: receiverId
			}),
			valuesByMap: !receiverOrg,
			readOnly: isEdit,
			alwaysHideSelect: !!preselectedReceiverIdFromPackage,
			relations: edi.relations.getRelations(),
			is_valid: isEdit || isCopy,
			selectedOrgValues: receiverOrgValues ? receiverOrgValues : null,
			fieldValues: receiverOrgValues ? receiverOrgValues : null,
			selectedOrg: receiverOrg ? receiverOrg : null,
			callback: function (values, org) {
				fields.receiver.is_valid = isValidOrganization(values);
				let orgId = org ? org.id : null;
				receiverId = orgId;
				copyReceiverId = orgId;
				let continueFn = function () {
					if (orgId != fields.consignee.getOrgIdForDelcat()) {
						fields.consignee.reset();
						fields.consignee.setOrgIdForDelcat(orgId);
						fields.consignee.setPartnerOrg(org);
					}
					if (orgId != fields.ship.getOrgIdForLoccat()) {
						fields.ship.reset();
						fields.ship.setOrgIdForLoccat(orgId);
					}
					checkValid();
					if (receiverId) {
						receiverOrg = org;
					}
				};

				let senderId = fields.sender.getValues().id || fields.sender.selectedOrg?.id;
				if (isCopy) {
					senderId = copySenderId;
				}
				if (receiverId && senderId && form.getValues().type !== '') {
					edi.methods.custom_fields.initCustomFields({
						customFieldsObj,
						docType: form.getValues().type,
						toOrgId: receiverId,
						fromOrgId: senderId,
						container: customFieldsContainer,
						topPath: topPath,
						finishCallback(obj) {
							customFieldsObj = obj;
							form.isValid();
							continueFn();
						},
						fail() {
							form.isValid();
							continueFn();
						}
					});
				} else {
					continueFn();
				}

				fields.receiver.isValid();
			},
			fieldsMap: edi.selectors.getDSFFieldsMap(receiverPrefix),
			modalConf: getCompanyModalConf()
		});

		fields.comment = createFieldBlock({
			title: edi.i18n.getMessage('documents.column.remark'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: 'grid',
			items: [
				createTextField({
					grid: {
						col: 7
					},
					name: 'comment',
					maxLength: 255,
					isTextarea: true,
					valueSrc: documentData,
					height: 100
				})
			]
		});

		fields.receiverSignatureField = createFieldBlock({
			layout: 'grid',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createCheckbox({
					grid: {
						col: 6
					},
					name: 'receiverSignatureField',
					boxLabel: edi.i18n.getMessage('column.expected.recipients.signature.under.document'),
					checked:
						isEdit || isCopy
							? edi.utils.getObjectProperty(documentData, 'reference.receiverSignatureExpected') == 'true'
							: documentData,
					inputValue: true,
					uncheckedValue: false
				})
			]
		});

		fields.summ = createFieldBlock({
			title: edi.i18n.getMessage('document.summ'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [2]
			},
			items: [
				createNumberField({
					allowBlank: false,
					name: 'totalSumm',
					allowDecimals: true,
					decimalPrecision: costDecimals,
					value: edi.utils.getObjectProperty(documentData, 'totalSumm')
				})
			]
		});

		const currCode =
			isEdit || isCopy
				? String(edi.utils.getObjectProperty(documentData, 'payment.currencyCode'))
				: edi.utils.getOkv({
						charCode: edi.constants.DEFAULT.CURRENCY
				  }).code;

		const recalculateNetPrice = function (tax, grossPrice) {
			if (tax !== null && grossPrice !== null) {
				return Number(grossPrice) - Number(tax);
			}
		};

		const recalculateGrossPrice = function (tax, netPrice) {
			if (tax !== null && netPrice !== null) {
				return Number(netPrice) + Number(tax);
			}
		};

		fields.paymentInfo = createFieldBlock({
			title: edi.i18n.getMessage('documents.dsf.paymentInformation'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 2, 2, 2], 2]
			},
			items: [
				createCombo({
					fieldLabel: edi.i18n.getMessage('invoice.currency'),
					value: currCode,
					name: 'payment.currencyCode',
					store: edi.stores.initOkvCurrencyStore(),
					anyMatch: true,
					displayField: 'displayName',
					valueField: 'codeStr',
					allowBlank: false
				}),
				createNumberField({
					fieldLabel: edi.i18n.getMessage('total.net.amount'),
					allowBlank: false,
					name: 'payment.netAmount',
					allowDecimals: true,
					decimalPrecision: dsfModuleMethods.DECIMAL_PRECISION,
					listeners: {
						change(field, newNetAmount) {
							const taxAmountField = fields.paymentInfo.down('[name=payment.taxAmount]');
							const grossAmountField = fields.paymentInfo.down('[name=payment.grossAmount]');
							if (field.isValid() && taxAmountField.isValid()) {
								let taxAmount = taxAmountField.getValue();
								grossAmountField.setValue(recalculateGrossPrice(taxAmount, newNetAmount));
							}
						}
					},
					value: edi.utils.getObjectProperty(documentData, 'payment.netAmount'),
					vtype: 'numberN10_2',
					tooltip: edi.renderers.getCustomDecimalTranslation(10, 2)
				}),
				createNumberField({
					fieldLabel: edi.i18n.getMessage('total.tax.amount'),
					allowBlank: false,
					name: 'payment.taxAmount',
					allowDecimals: true,
					decimalPrecision: dsfModuleMethods.DECIMAL_PRECISION,
					listeners: {
						change(field, newTaxVal) {
							const netAmountField = fields.paymentInfo.down('[name=payment.netAmount]');
							const grossAmountField = fields.paymentInfo.down('[name=payment.grossAmount]');
							if (field.isValid() && netAmountField.isValid()) {
								let netAmount = netAmountField.getValue();
								grossAmountField.setValue(recalculateGrossPrice(newTaxVal, netAmount));
							}
						}
					},
					value: edi.utils.getObjectProperty(documentData, 'payment.taxAmount'),
					vtype: 'numberN10_2',
					tooltip: edi.renderers.getCustomDecimalTranslation(10, 2)
				}),
				createNumberField({
					fieldLabel: edi.i18n.getMessage('total.gross.amount'),
					allowBlank: false,
					name: 'payment.grossAmount',
					allowDecimals: true,
					decimalPrecision: dsfModuleMethods.DECIMAL_PRECISION,
					value: edi.utils.getObjectProperty(documentData, 'payment.grossAmount'),
					vtype: 'numberN10_2',
					tooltip: edi.renderers.getCustomDecimalTranslation(10, 2)
				}),
				createCheckbox({
					cls: CHECKBOX_CLS.inFieldBlock,
					boxLabel: edi.i18n.getMessage('document.dsf.prepayment'),
					inputValue: true,
					uncheckedValue: false,
					checked: edi.utils.getObjectProperty(documentData, 'payment.prepayment'),
					name: 'payment.prepayment'
				})
			]
		});
		dateValue = edi.utils.getObjectProperty(documentData, 'order.date');
		fields.additionalInfo = createFieldBlock({
			title: edi.i18n.getMessage('document.order'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 2, 2]]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('order.number'),
					name: 'order.number',
					value: edi.utils.getObjectProperty(documentData, 'order.number') || undefined,
					maxLength: 255
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('order.date'),
					name: 'order.date',
					value: dateValue
						? edi.utils.formatDate(
								dateValue,
								edi.constants.DATE_FORMAT.CLIENT,
								edi.constants.DATE_FORMAT.SERVER
						  )
						: undefined
				})
			]
		});

		const startDateValue = edi.utils.getObjectProperty(documentData, 'startDate');
		const endDateValue = edi.utils.getObjectProperty(documentData, 'endDate');
		fields.startDate = createContainer({
			layout: {
				type: 'grid',
				area: [[4, 4]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('document.dsf.price.catalogue.start.date'),
					layout: {
						type: 'grid',
						area: [2]
					},
					items: [
						createDateField({
							allowBlank: false,
							submitFormat: edi.constants.DATE_FORMAT.FNS,
							name: 'startDate',
							value: startDateValue
								? edi.utils.formatDate(startDateValue, edi.constants.DATE_FORMAT.FNS)
								: undefined
						})
					]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.dsf.price.catalogue.end.date'),
					layout: {
						type: 'grid',
						area: [2]
					},
					items: [
						createDateField({
							allowBlank: false,
							submitFormat: edi.constants.DATE_FORMAT.FNS,
							name: 'endDate',
							value: endDateValue
								? edi.utils.formatDate(endDateValue, edi.constants.DATE_FORMAT.FNS)
								: undefined
						})
					]
				})
			]
		});

		const typeValue = edi.utils.getObjectProperty(documentData, 'subType');

		fields.priceCatalogueType = createFieldBlock({
			title: edi.i18n.getMessage('document.dsf.price.catalogue.type'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [4]
			},
			items: [
				createCombo({
					fieldLabel: edi.i18n.getMessage('document.dsf.price.catalogue.type.empty'),
					allowBlank: false,
					name: 'subType',
					store: createPriceCatalogueTypesStore(),
					forceSelection: true,
					value: typeValue ? typeValue : undefined
				})
			]
		});

		dateValue = edi.utils.getObjectProperty(documentData, 'shipmentDate');
		fields.shipmentDateCmp = createFieldBlock({
			title: edi.i18n.getMessage('document.shipment.date'),
			layout: {
				type: 'grid',
				area: [2]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createDateField({
					name: 'shipmentDate',
					value: dateValue
						? edi.utils.formatDate(
								dateValue,
								edi.constants.DATE_FORMAT.CLIENT,
								edi.constants.DATE_FORMAT.SERVER
						  )
						: undefined
				})
			]
		});

		dateValue = edi.utils.getObjectProperty(documentData, 'acceptanceDate');
		fields.acceptanceDateCmp = createFieldBlock({
			title: edi.i18n.getMessage('document.receipt.date'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [2]
			},
			items: [
				createDateField({
					name: 'acceptanceDate',
					value: dateValue
						? edi.utils.formatDate(
								dateValue,
								edi.constants.DATE_FORMAT.CLIENT,
								edi.constants.DATE_FORMAT.SERVER
						  )
						: undefined
				})
			]
		});

		dateValue = edi.utils.getObjectProperty(documentData, 'performDate');
		fields.performDateCmp = createFieldBlock({
			title: edi.i18n.getMessage('document.dsf.akt.acting.date'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [2]
			},
			items: [
				createDateField({
					name: 'performDate',
					submitFormat: edi.constants.DATE_FORMAT.CLIENT,
					value: dateValue
						? edi.utils.formatDate(
								dateValue,
								edi.constants.DATE_FORMAT.CLIENT,
								edi.constants.DATE_FORMAT.SERVER
						  )
						: undefined
				})
			]
		});

		fields.baseData = createFieldBlock({
			title: edi.i18n.getMessage('document.dsf.base'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 3, 2]]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.type'),
					allowBlank: true,
					name: 'reason.baseName',
					value: edi.utils.getObjectProperty(documentData, 'reason.baseName')
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					allowBlank: true,
					name: 'reason.baseNumber',
					value: edi.utils.getObjectProperty(documentData, 'reason.baseNumber')
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: true,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					name: 'reason.baseDate',
					value: edi.utils.getObjectProperty(documentData, 'reason.baseDate')
				})
			]
		});
		dateValue = edi.utils.getObjectProperty(documentData, 'contract.date');
		fields.contractData = createFieldBlock({
			title: edi.i18n.getMessage('document.select.contract'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 2, 3]]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.dsf.contract.number'),
					allowBlank: true,
					name: 'contract.number',
					value: edi.utils.getObjectProperty(documentData, 'contract.number'),
					maxLength: 255
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('document.dsf.contract.date'),
					allowBlank: true,
					name: 'contract.date',
					value: dateValue
						? edi.utils.formatDate(
								dateValue,
								edi.constants.DATE_FORMAT.CLIENT,
								edi.constants.DATE_FORMAT.SERVER
						  )
						: undefined
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.dsf.supplementary.agreement.number'),
					name: 'contract.supplementaryContract',
					allowBlank: true,
					valueSrc: documentData,
					maxLength: 255
				})
			]
		});

		fields.promo = createFieldBlock({
			title: edi.i18n.getMessage('document.dsf.supplementary.agreement.promo.name'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [4]
			},
			items: [
				createTextField({
					name: 'stock',
					maxLength: 200,
					allowBlank: true,
					valueSrc: documentData
				})
			]
		});

		const shipHasData = documentData && documentData.consignor;
		fields.ship = createOS({
			itemId: 'ship',
			is_valid: true,
			allowBlank: true,
			relationsOnly: false,
			ownCatalog: true,
			ownOrg: true,
			allowReset: true,
			valuesByMap: true,
			relationsFromLoccatByOrgId:
				!(isEdit || isCopy) && shipHasData ? undefined : receiverId ? receiverId : undefined,
			callback: function (values) {
				fields.ship.is_valid = !fields.ship.hasData || isValidOrganization(values);
				initialData ? edi.utils.setObjectProperty(initialData, 'consignor', null) : null;
				initialData ? edi.utils.setObjectProperty(initialData, 'supplier', null) : null;
				checkValid();
				if (fields.ship.isVisible()) {
					fields.ship.checkOrgDataValid();
				}
				fields.ship.isValid();
			},
			fieldsMap: edi.selectors.getDSFFieldsMap('consignor'),
			modalConf: getCompanyModalConf({
				main: {
					structural_subdivision: null,
					company_name: {
						maxLength: 1000
					}
				}
			})
		});

		fields.consignee = createOS({
			itemId: 'consignee',
			is_valid: true,
			allowBlank: true,
			relationsOnly: false,
			partnerOrg: true,
			ownCatalog: false,
			allowReset: true,
			valuesByMap: true,
			partnerOrgValues: receiverOrg,
			relationsFromDelcatByOrgId:
				(documentData && !documentData.consignee && receiverId) || (isEdit || isCopy ? receiverId : undefined),
			callback: function (values) {
				fields.consignee.is_valid = !fields.consignee.hasData || isValidOrganization(values);
				initialData ? edi.utils.setObjectProperty(initialData, 'consignee', null) : null;
				initialData ? edi.utils.setObjectProperty(initialData, 'payer', null) : null;
				checkValid();
				if (fields.consignee.isVisible()) {
					fields.consignee.checkOrgDataValid();
				}
				fields.consignee.isValid();
			},
			fieldsMap: edi.selectors.getDSFFieldsMap('consignee'),
			modalConf: getCompanyModalConf({
				main: {
					structural_subdivision: null,
					company_name: {
						maxLength: 1000
					}
				}
			})
		});

		fields.commentText = createFieldBlock({
			title: edi.i18n.getMessage('document.dsf.commentText'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [7]
			},
			items: [
				createTextField({
					name: 'commentText',
					allowBlank: false,
					maxLength: 1000,
					isTextarea: true,
					valueSrc: documentData,
					height: 200
				})
			]
		});

		if (isEdit || isCopy) {
			fields.consignee.setPartnerOrg(
				edi.utils.getOrg({
					orgId: receiverId
				})
			);
		}

		let action = function (callback) {
			if (
				!edi.utils.setFocusToDocumentsWithGrid(
					form,
					['ship', 'sender', 'receiver', 'consignee', 'fileDropper'],
					[fields.ship, fields.sender, fields.receiver, fields.consignee, fileDropper],
					null,
					false
				)
			) {
				return;
			}
			let values = edi.utils.collectFormValues(form);
			edi.utils.clearEmptyValues(values);
			!values.filename && fileValue ? (values.filename = edi.utils.base64.encode(fileValue.name)) : null;

			'function' == typeof callback ? callback(values) : null;
		};
		createAndSignButton = createSendButton(
			function () {
				action((values) => {
					createAndSign(values, getIsMultiSign(), {
						warningWindowBtnText: edi.i18n.getMessage('document.sign.document')
					});
				});
			},
			{
				disabled: false,
				formBind: false,
				hidden: !getIsMultiSign(),
				text: edi.i18n.getMessage(
					isEdit ? 'document.save.and.sign.document' : 'document.create.and.sign.document'
				)
			}
		);

		createAndSignSendButton = createSendButton(
			function () {
				action((values) => {
					createAndSign(values, false, {
						warningWindowBtnText: edi.i18n.getMessage('document.sign.and.send.document')
					});
				});
			},
			{
				disabled: false,
				formBind: false,
				hidden: isEdit && doctype === edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
				text: edi.i18n.getMessage('document.sign.and.send.document')
			}
		);

		// EDIWEB begin

		createAndSignSendMenuButton = createSendButton(
			function () {
				action((values) => {
					createAndSign(values, false);
				});
			},
			{
				disabled: false,
				formBind: false,
				hidden: isEdit && doctype === edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
				text: edi.i18n.getMessage('document.sign.and.send.document'),
				handler: null,
				menu: {
					plain: true,
					hideMode: 'display',
					items: [
						{
							text: edi.i18n.getMessage('ediweb.send.and.sign.parnter.btn'),
							handler() {
								action((values) => {
									createAndSign(values, false);
								});
							}
						},
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.documents.btn'),
							handler() {
								action((values) => {
									showApprovalModal(
										values,
										moduleData,
										save,
										edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS
									);
								});
							}
						},
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.employees.btn'),
							handler() {
								action((values) => {
									showApprovalModal(
										values,
										moduleData,
										save,
										edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES
									);
								});
							}
						}
					]
				}
			}
		);

		// EDIWEB end

		createAndSendButton = createCreateAndSendButton(
			function () {
				const afterSave = function (documentId, callback) {
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: documentId
							},
							true
						),
						'PUT',
						Ext.encode({}),
						null,
						edi.document.actions.defaultFailureHandler(moduleData.tab, 'document.error.in.sending.process'),
						callback
					);
				};
				action((values) => {
					save(values, afterSave);
				});
			},
			{
				hidden: isEdit && doctype !== edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT
			},
			isEdit
		);

		const createButton = createUIButton({
			cls: BUTTON_CLS.secondary,
			text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
			handler: function () {
				action((values) => {
					save(values);
				});
			}
		});

		let organizationsTwoColumnsLayout = createContainer({
			layout: {
				type: 'grid',
				area: [
					[6, 6],
					[6, 6]
				]
			},
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('document.dsf.sender.' + doctype),
					items: [fields.sender]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.dsf.receiver.' + doctype),
					items: [fields.receiver]
				}),
				(fields.shipBlock = createFieldBlock({
					title: edi.i18n.getMessage('document.ship.from'),
					items: [fields.ship]
				})),
				(fields.consigneeBlock = createFieldBlock({
					title: edi.i18n.getMessage('documents.torg.consignee'),
					items: [fields.consignee]
				}))
			]
		});

		formLayout = [
			fields.contractData,
			fields.promo,
			fields.priceCatalogueType,
			fields.startDate,
			fields.summ,
			fields.paymentInfo,
			fields.shipmentDateCmp,
			fields.acceptanceDateCmp,
			fields.performDateCmp,
			fields.additionalInfo,
			documentFileBlock,
			fields.comment,
			fields.commentText,
			fields.receiverSignatureField
		];

		let formItems = [title, headData, autogenCheckboxContainer, organizationsTwoColumnsLayout, fields.baseData];
		formItems = formItems.concat(formLayout);

		form = createForm({
			submitEmptyText: false,
			autoScroll: true,
			bodyPadding: 24,
			items: [
				createContainer({
					cls: 'edi-form-maxwidth',
					layout: {
						type: 'grid',
						gap: 24
					},
					items: formItems
				}),
				createCustomFieldsContainer()
			],
			buttonAlign: 'left',
			buttons: createButtonContainer({
				items: [
					createAndSignSendButton,
					edi.constants.IS_EDIWEB_CLIENT ? createAndSignSendMenuButton : null,
					createAndSendButton,
					createAndSignButton,
					createButton
				]
			}),
			listeners: {
				afterrender: function () {
					showHideControls();

					form.isValid();
					form.on('validitychange', checkValid);

					uploadForm.isValid();
					uploadForm.on('validitychange', checkValid);

					checkValid();
					edi.utils.processModuleFormChange(form, moduleData);
					edi.utils.processModuleFormChange(uploadForm, moduleData);
				}
			}
		});

		return form;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		let valid = false;
		const validCompanies = validateCompanySelectors();
		const hasInvalid = form.hasInvalidField();
		if (!hasInvalid && validCompanies) {
			valid = true;
		}
		if (valid) {
			valid = uploadForm.isValid();
		}
		return valid;
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	const validateCompanySelectors = function () {
		const isReceiverValid = fields.receiver.isValid(),
			isSuppValid = fields.sender.isValid(),
			isConsValid = fields.consignee.isDisabled() || fields.consignee.isValid(),
			isShipValid = fields.ship.isDisabled() || fields.ship.isValid();

		return isReceiverValid && isSuppValid && isConsValid && isShipValid;
	};

	const buildDocument = function (formValues, fileId) {
		const prefixes = getPrefixes(formValues.type);

		if (receiverPrefix != prefixes['receiver']) {
			formValues[prefixes['receiver']] = formValues[receiverPrefix];
			delete formValues[receiverPrefix];
		}
		if (senderPrefix != prefixes['sender']) {
			formValues[prefixes['sender']] = formValues[senderPrefix];
			delete formValues[senderPrefix];
		}
		if (formValues.type === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE) {
			formValues['supplier'] = edi.utils.getObjectProperty(formValues, 'consignor');
			formValues['payer'] = edi.utils.getObjectProperty(formValues, 'consignee');

			delete formValues.consignor;
			delete formValues.consignee;
		}

		if (fileId) {
			formValues['file'] = fileId;
		} else if (fileValue) {
			formValues['file'] = fileValue.id;
		}

		if (formValues['startDate']) {
			formValues['startDate'] = edi.utils.getUTCTimeZoneFree(
				formValues['startDate'],
				edi.constants.DATE_FORMAT.SERVER,
				undefined,
				edi.constants.DATE_FORMAT.FNS
			);
		}
		if (formValues['endDate']) {
			formValues['endDate'] = edi.utils.getUTCTimeZoneFree(
				formValues['endDate'],
				edi.constants.DATE_FORMAT.SERVER,
				undefined,
				edi.constants.DATE_FORMAT.FNS
			);
		}
		if (formValues['payment']) {
			if (formValues['payment']['currencyCode']) {
				formValues['payment']['currencyLocal'] = edi.utils.getOkv({
					code: formValues['payment']['currencyCode']
				}).charCode;
			}

			if (formValues['payment']['grossAmount']) {
				formValues['totalSumm'] = formValues['payment']['grossAmount'];
			}
		}
		if (edi.constants.DSF_TYPES_FROV.some((it) => it === formValues.type)) {
			formValues['documentType'] = formValues.type.substr(4);
			formValues['commentType'] = 'COMMENT_' + formValues.type.substr(4);
		}
		if (formValues.type === edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT) {
			delete formValues.commentType;
		}

		delete formValues.numberAutoGenerated;

		return formValues;
	};
	const createAndSign = function (formValues, isMultiSign, options = {}) {
		if (!edi.sign.isAvailable()) {
			edi.sign.displayNotAvailableMessage();
			return;
		}
		const changeCountSignatureFail = edi.rest.getErrorHandler(null, function () {
			if (moduleData.tab && !moduleData.tab.isDestroyed) {
				moduleData.tab.setLoading(false);
			}
		});
		var signMethod = function (selectedCert, certObj, isLocalCert, activePoa) {
			certificateHandler.set(selectedCert);
			let selectedPoa;
			let docId;

			const signAfterSaveHandler = function ({ documentId, callback }) {
				let attr = { countSignature: 0 };
				const signAfterSave = function () {
					const failure = function (data) {
						certificateHandler.set(null);
						if (isMultiSign) {
							edi.methods.documents.changeCountSignatureAttribute(
								-1,
								attr,
								doctype,
								documentId,
								edi.constants.DIRECTIONS.OUTGOING,
								(data) => {
									if (data?.success === false) {
										changeCountSignatureFail(data);
									}
								}
							);
						}
						callback && 'function' == typeof callback ? callback(true, data) : null;
					};
					const signObjectProcessor = function (signature) {
						let signObj = {};
						signObj[edi.constants.BUSINESS_PROCESS_PROPERTIES.SIGNATURE] = signature;
						if (selectedPoa && selectedPoa !== 'USER_SELECT_NOT_USE_POA') {
							signObj['POA_ID'] = selectedPoa;
						}
						return Ext.encode(signObj);
					};
					const signContentUrl = edi.utils.formatString(edi.rest.services.DOCUMENTS.SIGN.GET, {
						documentId: documentId
					});
					const signUrl = edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, {
						documentId: documentId
					});
					if (isLocalCert || certificateHandler.get()?.Thumbprint) {
						const callSetSignature = function (contentToSign) {
							edi.sign.setSignature({
								content: contentToSign,
								certificate: certificateHandler.get(),
								callback: function (signObj) {
									if (signObj.data) {
										const signProcess = function () {
											let signature = signObjectProcessor(signObj.data);
											const callPushSignature = function (body, callback) {
												edi.rest.sendRequest(
													signUrl,
													'PUT',
													body,
													function (data) {
														callback && 'function' == typeof callback
															? callback(data)
															: null;
													},
													(data) => {
														edi.document.actions.defaultFailureHandler(
															moduleData.tab,
															'document.error.in.sign.process'
														)(data);
														failure(data);
													}
												);
											};
											callPushSignature('{}', function () {
												callPushSignature(signature, function (data) {
													callback &&
													'function' == typeof callback &&
													(certificateHandler.get() || isLocalCert)
														? callback(false, data, certificateHandler.get())
														: null;
												});
											});
										};
										edi.sign.checkCertificate(signProcess, certObj);
									} else {
										failure(signObj.error);
									}
								}
							});
						};
						edi.rest.sendRequest(
							signContentUrl,
							'GET',
							undefined,
							function (data) {
								if (data.data) {
									callSetSignature(data.data);
								} else {
									failure(data);
								}
							},
							failure
						);
					} else {
						edi.core.showError('error.certificate.not.correct');
						failure();
					}
				};
				//если это документ по БП с мультиподписанием и нажали кнопку Создать и подписать(без отправки)
				if (isMultiSign) {
					//нужно получить хедер
					edi.document.actions.getDocumentHeaderData(documentId, function (headerData) {
						if (headerData) {
							const side = edi.document.actions.getSignSide(doctype, edi.constants.DIRECTIONS.OUTGOING);
							const attrName = 'countSignature_' + side;
							attr.countSignature = parseInt(
								edi.utils.getAttributeByName(headerData.attributes, attrName),
								10
							);
							//изменить атрибуты и вызвать afterChangeCountSignature
							edi.methods.documents.changeCountSignatureAttribute(
								1,
								attr,
								doctype,
								documentId,
								edi.constants.DIRECTIONS.OUTGOING,
								signAfterSave
							);
						}
					});
				} else {
					signAfterSave();
				}
			};

			const afterSaveMethod = function (documentId, callback, responseData) {
				const attributes = responseData.data?.attributes;
				docId = documentId;
				const isWarning = edi.utils.getAttributeByName(attributes, 'isWarning', undefined, true);
				const isBlocked = edi.utils.getAttributeByName(attributes, 'isBlocked', undefined, true);
				const isUnformalized = responseData.data?.type === edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED;
				warningWindowSignBtn.setVisible(isWarning && !isBlocked);
				if (!(isUnformalized && (isWarning || isBlocked))) {
					signAfterSaveHandler({ documentId, callback });
				} else {
					if ('function' == typeof callback) callback();
				}
			};

			const warningWindowSignBtn = createUIButton({
				text: options?.warningWindowBtnText,
				cls: BUTTON_CLS.secondary,
				handler: function () {
					const me = this;
					const warningWindow = me.up('window');
					if (warningWindow && !warningWindow.isDestroyed) {
						warningWindow.setLoading(true);
					}
					signAfterSaveHandler({
						documentId: docId,
						callback: () => warningWindow?.close()
					});
				}
			});
			// Прокидываем кнопку подписания ДСФ в окно предупреждения
			const saveOptions = {
				processDocumentAdditionalProperties: {
					warningWindowConf: {
						additionalButtons: [warningWindowSignBtn]
					}
				}
			};

			//Выбор МЧД для подписания документа, если до этого выбрали то шаг просто пропустится
			const selectPoa = function () {
				const nextStep = () => save(formValues, afterSaveMethod, undefined, saveOptions);
				let certificateObj = edi.utils.fullStringCertParse(certificateHandler.get()?.SubjectName);
				const innUlFromCert = certificateObj.innle;
				const isUl = !!innUlFromCert;
				let isFL = !innUlFromCert && !certificateObj.ogrn && !certificateObj.ogrnip && certificateObj.snils;
				const innUlMatches = innUlFromCert && innUlFromCert === edi.core.getUserData().org.inn;
				//при подписании сертом ЮЛ проверяем совпадает ли ИНН в серте с ИНН текущей организации
				//если совпал, то подписываем без МЧД
				//если отличается то показываем окно "Использовать МЧД при подписании?" (ДА - окно выбора МЧД | НЕТ - подписываем без МЧД)
				if (edi.constants.ENABLE_POA_FOR_ALL_CERT || isFL || (isUl && !innUlMatches)) {
					if (activePoa) {
						selectedPoa = activePoa;
					}
					if (selectedPoa) {
						nextStep();
					} else {
						const selectPoaAndContinue = () =>
							edi.methods.poa.selectPoA(certificateObj, true, function (poa) {
								if (poa?.docId) {
									selectedPoa = poa.docId;
								}
								nextStep();
							});

						if (isUl && !innUlMatches) {
							edi.core.confirm(null, 'sign.confirm.use_poa', selectPoaAndContinue, nextStep);
						} else {
							selectPoaAndContinue();
						}
					}
				} else {
					nextStep();
				}
			};

			//проверка заявления в ФНС, если есть такой метод, после нее прыгаем на выбор МЧД
			if (typeof edi.methods.application_to_fns?.signApplicationAndCert === 'function') {
				var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data');
				edi.methods.application_to_fns.signApplicationAndCert(
					certificateHandler,
					false,
					function (fail, data, selectedCert, poa, silent = false) {
						certificateHandler.set(selectedCert);
						selectedPoa = poa;

						if (!fail) {
							selectPoa();
						} else {
							certificateHandler.set(null);
							const isSilent = data?.typeError
								? edi.constants.SILENT_TYPE_ERRORS.includes(data.typeError)
								: silent;
							if (!isSilent) {
								failure();
							}
						}
					}
				);
			} else {
				selectPoa();
			}
		};
		const selectSert = function (doctype) {
			edi.sign.selectCertificate(signMethod, function (correctClose) {}, false, doctype, true);
		};
		selectSert(formValues.type);
	};
	/**
	 * Saves dsf
	 * @param	{Object}	formValues
	 * @param	{Function}	[afterSave]
	 * @param	{Function}	[onSaveFailed]
	 * @param	{Object}	[options]
	 */
	const save = function (formValues, afterSave, onSaveFailed, options) {
		let prefixes = getPrefixes(formValues.type);
		if (isCopy) {
			const collectValues = edi.utils.collectFormValues(form);
			Object.values(prefixes).forEach((prefix) => {
				formValues[prefix] = collectValues[prefix];
			});
		}
		const numberAutoGenerated = formValues.numberAutoGenerated;
		let customFieldsData;
		delete formValues.numberAutoGenerated;

		if (dsfModuleMethods.hasCustomFields(formValues.type)) {
			customFieldsData = edi.methods.custom_fields.getCustomFieldsData(
				customFieldsObj,
				formValues,
				null,
				topPath
			);
			Object.keys(customFieldsData).forEach(function (key) {
				delete formValues[key];
			});
		}
		if (!uploadForm.isValid()) {
			edi.core.showError('document.upload.error.select.file');
			if (typeof onSaveFailed === 'function') {
				onSaveFailed(moduleData);
			}
		} else {
			formValues.reference = {
				receiverSignatureExpected: !!formValues.receiverSignatureField,
				addressTypeSender: edi.utils.getObjectProperty(
					formValues,
					`${prefixes['sender']}.address.addr_category`
				),
				addressTypeReceiver: edi.utils.getObjectProperty(
					formValues,
					`${prefixes['receiver']}.address.addr_category`
				),
				addressTypeShip: edi.utils.getObjectProperty(formValues, `${prefixes['ship']}.address.addr_category`),
				addressTypeConsignee: edi.utils.getObjectProperty(
					formValues,
					`${prefixes['consignee']}.address.addr_category`
				)
			};
			formValues.senderDocumentFields =
				isEdit &&
				(edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT === doctype ||
					edi.constants.DOCUMENT_TYPES.DSF_AKTPRM === doctype)
					? [
							{
								code: 'senderSubdivision',
								title: edi.i18n.getMessage('dsf.create.senderSubdivision'),
								value: edi.utils.getObjectProperty(
									formValues,
									'senderDocumentFields.performer.structural_subdivision'
								)
							},
							{
								code: 'receiverSubdivision',
								title: edi.i18n.getMessage('dsf.create.receiverSubdivision'),
								value: edi.utils.getObjectProperty(
									formValues,
									'senderDocumentFields.customer.structural_subdivision'
								)
							}
					  ]
					: [
							{
								code: 'senderSubdivision',
								title: edi.i18n.getMessage('dsf.create.senderSubdivision'),
								value: edi.utils.getObjectProperty(
									formValues,
									'senderDocumentFields.sender.structural_subdivision'
								)
							},
							{
								code: 'receiverSubdivision',
								title: edi.i18n.getMessage('dsf.create.receiverSubdivision'),
								value: edi.utils.getObjectProperty(
									formValues,
									'senderDocumentFields.receiver.structural_subdivision'
								)
							}
					  ];

			const onUploaded = function (fileId) {
				moduleData.tab.setLoading(edi.i18n.getMessage('dsf.create.saving'));

				let document = buildDocument(formValues, fileId);
				delete document['true'];

				const fieldsToRemove = Ext.clone(edi.constants.FIELDS_TO_REMOVE_AFTER_MERGE) ?? [];
				if (document.type === edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT) {
					fieldsToRemove.push('commentText', 'commentType');
				}

				document = edi.document.actions.mergeDataBeforeSave(initialData, document, undefined, {
					fieldsToRemove
				});

				edi.utils.clearEmptyValues(document);

				if (document.type === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE) {
					!!document.supplier && delete document.supplier.address.addr_category;
					!!document.payer && delete document.payer.address.addr_category;
				}

				if (document[prefixes['sender']]?.address?.addr_category) {
					delete document[prefixes['sender']].address.addr_category;
				}
				if (document[prefixes['receiver']]?.address?.addr_category) {
					delete document[prefixes['receiver']].address.addr_category;
				}
				if (document[prefixes['ship']]?.address?.addr_category) {
					delete document[prefixes['ship']].address.addr_category;
				}
				if (document[prefixes['consignee']]?.address?.addr_category) {
					delete document[prefixes['consignee']].address.addr_category;
				}

				if (isCopy && edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT === doctype) {
					if (receiverPrefix !== prefixes['receiver'] && document[receiverPrefix]?.address?.addr_category) {
						delete document[receiverPrefix].address.addr_category;
					}
					if (senderPrefix !== prefixes['sender'] && document[senderPrefix]?.address?.addr_category) {
						delete document[senderPrefix].address.addr_category;
					}
				}

				let headerData = {
					data: Ext.encode(document),
					date: document.date,
					dateFormat: edi.constants.DATE_FORMAT.CLIENT
				};
				if (customFieldsData && Object.keys(customFieldsData).length > 0) {
					headerData.customFields = customFieldsData;
				}

				if (isNumberAutoGenerated && numberAutoGenerated) {
					headerData.autogen = true;
				} else {
					headerData.number = formValues.number;
				}

				var success = function (responseData) {
					const handler =
						options?.isSendingToApproval === true && !isEdit
							? function (respData) {
									//после создания дока сразу обновим модуль, т.к. он уже черновик в редактировании
									//и после обновления запустим уже процесс согласования
									//это все из-за того, что в процессе создания или отправки согласования
									//что-то может пойти не так, и это позволит нам сразу работать с черновиком,
									//у которого уже есть id и загруженный файл
									if (respData?.data?.id) {
										moduleData.initData.data = respData.data;
										let title = edi.i18n.getMessage(
											edi.modulesCfg['document.create.dsf'].title + '.edit'
										);
										title += ` ${respData.data.number}`;
										moduleData.tab.setTitle(title);
										moduleData.tab.tab.setTooltip(title);
										moduleData.tab.setGlyph(edi.constants.ICONS.EDIT);
										moduleData.instance.init(moduleData, () => afterSave(respData.data.id));
									}
							  }
							: edi.document.actions.createSaveSuccessHandler(
									moduleData,
									isEdit ? id : undefined,
									afterSave,
									isEdit
							  );

					handler(...arguments);
					if (
						typeof moduleData.initData?.packageOptions?.afterCreate === 'function' &&
						responseData.data.id
					) {
						moduleData.initData.packageOptions.afterCreate(responseData.data.id);
					}
				};

				const failure = edi.document.actions.createSaveErrorHandler(isEdit, moduleData, doctype, function () {
					if (typeof onSaveFailed === 'function') {
						onSaveFailed(moduleData);
					}
				});
				edi.document.actions.processDocument(
					receiverId,
					null,
					doctype,
					parentId,
					isEdit ? id : undefined,
					success,
					failure,
					headerData,
					options?.processDocumentAdditionalProperties
				);
			};

			const failure = function (data) {
				fileDropper?.clearFileData(true);
				checkValid();
				edi.core.logMessage(
					'Error uploading dsf file' + (data && data.status ? ' status - ' + data.status : ''),
					'warn'
				);
				edi.core.showError(
					edi.utils.formatComplexServerError(
						data,
						data && data.status ? 'error.server.' + data.status : 'dsf.upload.failure'
					),
					function () {
						moduleData.tab.setLoading(false);
					}
				);
				if (typeof onSaveFailed === 'function') {
					onSaveFailed(moduleData);
				}
			};

			let fileCmp = uploadForm.getForm().findField('attachment');
			if (!fileCmp || !fileCmp.getValue()) {
				onUploaded(null);
			} else {
				edi.core.submitUploadForm(
					uploadForm,
					edi.rest.services.DSF.FILE.POST,
					'dsf.create.saving',
					function (responseData) {
						if (responseData && responseData.data && responseData.data.id) {
							onUploaded(responseData.data.id);
						} else {
							failure(responseData);
						}
					},
					failure
				);
			}
		}
	};
	/**
	 * Creates action pane above the data panel
	 */
	const createModuleActionsPanel = function () {
		return createActionsPanel();
	};
	/**
	 * Create field maps visibility by document type
	 */
	const createMaps = function () {
		let additionalFields;
		edi.constants.CREATE_ACTUAL_FREE_DOCUMENT_TYPES.forEach(function (doctype) {
			fieldsmap[doctype] = {
				sender: true,
				receiver: true
			};
			additionalFields = [];
			if (
				doctype === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT ||
				doctype === edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
			) {
				additionalFields = ['baseData', 'summ', 'performDateCmp'];
			} else if (
				doctype === edi.constants.DOCUMENT_TYPES.DSF_INVOICE ||
				doctype === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
			) {
				additionalFields = ['paymentInfo', 'additionalInfo', 'contractData', 'promo'];
				if (doctype === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE) {
					additionalFields = additionalFields.concat(['shipBlock', 'consigneeBlock', 'ship', 'consignee']);
				}
			} else if (
				doctype == edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT ||
				doctype == edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL
			) {
				additionalFields = ['contractData', 'promo'];
			}

			if (edi.constants.DSF_TYPES_FROV.some((it) => it === doctype)) {
				additionalFields = ['commentText'];
			} else {
				additionalFields = additionalFields.concat('comment', 'receiverSignatureField');
			}

			if (doctype === edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT) {
				additionalFields = [];
			}
			additionalFields.forEach(function (fieldName) {
				fieldsmap[doctype][fieldName] = true;
			});
		});
	};
	/**
	 * Hides/shows controls according to selected DSF type
	 */
	const showHideControls = function () {
		moduleData.tab.suspendLayouts();
		let map = fieldsmap[doctype],
			i;
		for (i in fields) {
			if (fields.hasOwnProperty(i) && !!map) {
				switchControl(fields[i], !map[i]);
			}
		}
		updateCompanySelectorConfigs();

		if (fields.contractData) {
			let contractNumberInput = fields.contractData.down('[name="contract.number"]'),
				contractDateInput = fields.contractData.down('[name="contract.date"]'),
				isSupplementaryAgreement =
					doctype != edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT ||
					doctype != edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL;

			contractNumberInput ? (contractNumberInput.allowBlank = isSupplementaryAgreement) : null;
			contractDateInput ? (contractDateInput.allowBlank = isSupplementaryAgreement) : null;
		}
		getIsMultiSign() ? createAndSignButton.show() : createAndSignButton.hide();
		createAndSendButton.setVisible(doctype === edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT);
		// EDIWEB begin
		var hideButtonDocTypes = Ext.Array.contains(edi.constants.DSF_WITH_APPROVE, doctype);

		createAndSignSendButton.setVisible(
			doctype !== edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT && !hideButtonDocTypes
		);
		createAndSignSendMenuButton.setVisible(hideButtonDocTypes);
		// EDIWEB end
		form.isValid();
		uploadForm.isValid();
		moduleData.tab.resumeLayouts();
		checkValid();
	};
	/**
	 * Helper function for disable/hide, enable/show controls
	 * @returns {Boolean} s it supported multi sign
	 */
	const getIsMultiSign = function () {
		let needChangeCountSignature = edi.constants.DSF_FOR_MULTY_SIGN.some((it) => it === doctype);
		let canMultiSign = true;
		if (isEdit) {
			canMultiSign = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'canMultiSign');
			canMultiSign = canMultiSign === true || canMultiSign === 'true';
		}
		//если документ из списка и атрибут true и есть право
		return canMultiSign && needChangeCountSignature && edi.permissions.hasPermission('SIGN_' + doctype + '_BUTTON');
	};

	/**
	 * Helper function for disable/hide, enable/show controls
	 * @param    {Object}    control    control to process
	 * @param    {Boolean}   disable    true to set disabled
	 * @param    {Boolean}   show       true to set visible, by default work as !disable
	 */
	const switchControl = function (control, disable, show) {
		show = show ? true : false == show ? show : !disable;
		if (control && 'function' == typeof control.setDisabled) {
			control.setDisabled(disable);
		}
		if (control && 'function' == typeof control.setVisible) {
			control.setVisible(show);
		}
	};

	/**
	 * Returns controls prefixes map
	 * @param    {String}    doctype    document type for witch we calculate prefixes
	 */
	var getPrefixes = function (doctype) {
		return {
			sender:
				doctype == edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT ||
				doctype == edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
					? 'performer'
					: 'sender',
			receiver:
				doctype == edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT ||
				doctype == edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
					? 'customer'
					: 'receiver'
		};
		if (doctype === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE) {
			Ext.merge(defaultPrefixes, {
				ship: 'consignor',
				consignee: 'consignee'
			});
		}
		return defaultPrefixes;
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		let data = moduleData.initData.data;
		if (data) {
			receiverOrg = edi.utils.getOrg({
				orgId: data.toOrg.id
			});
			receiverId = data.toOrg.id;
			copyReceiverId = data.toOrg.id;
			copySenderId = data.fromOrg.id;
			doctype = data.type;
		}
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const continueLoading = function () {
			if (isEdit) {
				const prefixes = getPrefixes(doctype);
				receiverPrefix = prefixes['receiver'];
				senderPrefix = prefixes['sender'];
			}
			let modulePanel = createAddModulePanel({
				items: [createModuleForm(documentData)]
			});

			moduleData.tab.removeAll();
			//moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);

			if (isEdit) {
				if ('function' == typeof initCallBack) {
					initCallBack();
				}
				moduleData.tab.updateLayout();
			} else {
				fields.receiver.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			}

			if (data?.fromOrg?.id && data?.toOrg?.id && doctype !== '') {
				edi.methods.custom_fields.initCustomFields({
					customFieldsObj,
					docType: doctype,
					toOrgId: data.toOrg.id,
					fromOrgId: data.fromOrg.id,
					docId: documentData ? documentData.header : null,
					container: customFieldsContainer,
					topPath: topPath,
					finishCallback(obj) {
						customFieldsObj = obj;
						form.isValid();
						checkValid();
					},
					fail() {
						form.isValid();
						checkValid();
					}
				});
			}
		};

		const loadSettingsAndContinue = function () {
			const failure = function (data) {
				edi.core.logMessage(
					'Error get limit size of file for upload ' +
						(data && data.status ? ' status - ' + data.status : ''),
					'warn'
				);
				edi.core.showError(
					edi.utils.formatComplexServerError(
						data,
						data && data.status ? 'error.server.' + data.status : 'dsf.get.limit.file.size.failure'
					),
					function () {
						maxFileSize = 1;
						continueLoading();
					}
				);
			};
			const success = function (data) {
				maxFileSize = parseInt(data.data);
				if (!isNaN(maxFileSize)) {
					maxFileSize = maxFileSize > 10 ? 10 : maxFileSize;
					continueLoading();
				} else {
					failure();
				}
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.SERVER.SETTING.GET, {
					configuration_code: 'LIMIT_SIZE_DSF'
				}),
				'GET',
				{},
				success,
				failure
			);
		};

		if (data && data.id) {
			id = data.id;
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
					documentId: data.id
				}),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						initialData = data.data;
						documentData = data.data;
						if (isCopy && documentData?.signature) {
							delete documentData.signature;
						}

						let senderDocumentFields = documentData.senderDocumentFields,
							senderSubdivision = '',
							receiverSubdivision = '';

						if (senderDocumentFields?.length) {
							senderDocumentFields.forEach(function (item) {
								switch (item.code) {
									case 'senderSubdivision':
										senderSubdivision = item.value;
										break;
									case 'receiverSubdivision':
										receiverSubdivision = item.value;
										break;
								}
							});
						}
						documentData.senderDocumentFields =
							edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT === documentData.type ||
							documentData.type === edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
								? {
										performer: {
											structural_subdivision: senderSubdivision
										},
										customer: {
											structural_subdivision: receiverSubdivision
										}
								  }
								: {
										sender: {
											structural_subdivision: senderSubdivision
										},
										receiver: {
											structural_subdivision: receiverSubdivision
										}
								  };
						if (documentData.type === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE) {
							documentData.consignor = documentData.supplier;
							documentData.consignee = documentData.payer;

							delete documentData.supplier;
							delete documentData.payer;
						}
						fileValue = {
							id: edi.utils.getObjectProperty(documentData, 'file.id'),
							name: edi.utils.getObjectProperty(documentData, 'file.realName')
						};
						loadSettingsAndContinue();
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET,
					'GET',
					{},
					function (data) {
						if (data && data.items) {
							numberGenerationPatterns = data;
							for (let i = 0; i < data.items.length; i++) {
								let numberingRule = data.items[i];
								if (numberingRule.typeDoc === doctype && 'ALLOW' === numberingRule.policy) {
									isNumberAutoGenerated = true;
									break;
								}
							}
							loadSettingsAndContinue();
						} else {
							failure(data);
						}
					},
					failure
				);
			} else {
				loadSettingsAndContinue();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
