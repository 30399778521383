// @ts-ignore
import { createLabel, createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import {
	createFieldBlock,
	createFieldBlockForDetails,
	createMaxWidthContainer,
	createModalForm,
	FIELD_BLOCK_CLS
	// @ts-ignore
} from '@UIkit/components/panels';
// @ts-ignore
import { createGrid } from '@UIkit/components/grid';
// @ts-ignore
import { BUTTON_CLS, createActionsButton, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { showConfirm } from '@UIkit/components/modal/MessageBox';
import { DOCUMENT_PACKAGES_DETAILS_WINDOW_MODEL_CONFIG_NAME } from './models';
import { DOCUMENT_PACKAGES_DOCS_COLUMNS_NAME } from './columns';
import { documentPackageSelectors } from './selectors';
import { documentPackageApi, DocumentPackageHeader, PackageType } from './entities';
import { documentPackageMethods } from './methods';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createActionsColumnConfig } from '@Components/grid';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { asyncDownloadTask } from '@Edi/methods/asyncDownloadTask';
import { MassDocumentActions } from '@Edi/methods/massDocumentActions';
import { documentsModal } from '@Edi/methods/methods';

export class DocumentPackageDetailsModule {
	moduleData: ModuleData<DocumentPackageHeader>;
	modulePanel: ExtComponent;
	moduleActionsPanel: ExtComponent;
	moduleForm: ExtComponent;
	senderField: ExtComponent;
	receiverField: ExtComponent;
	moduleGrid: ExtComponent;
	deleteButton?: ExtComponent;
	signButton?: ExtComponent;
	exportButton?: ExtComponent;
	printButton?: ExtComponent;
	rejectButton?: ExtComponent;
	annulButton?: ExtComponent;
	packageHeader: DocumentPackageHeader;
	packageDocs: DocumentHeader[] = [];
	massDocumentActions: MassDocumentActions;
	_beforeSignStart: MassDocumentActions['beforeSignStart'];
	_signActionHandler: MassDocumentActions['signActionHandler'];
	_signCompleteCallback: MassDocumentActions['signCompleteCallback'];
	_createAnnulActionHandler: MassDocumentActions['createAnnulActionHandler'];
	_approveAnnulActionHandler: MassDocumentActions['approveAnnulActionHandler'];
	_rejectAnnulActionHandler: MassDocumentActions['rejectAnnulActionHandler'];
	_annulCompleteCallback: MassDocumentActions['annulCompleteCallback'];
	_deleteEventHandler: DocumentPackageDetailsModule['deleteEventHandler'];
	_updateModuleHandler: DocumentPackageDetailsModule['updateModuleHandler'];

	constructor() {
		const me = this;
		me._deleteEventHandler = me.deleteEventHandler.bind(me);
		me._updateModuleHandler = me.updateModuleHandler.bind(me);
	}

	init(data: ModuleData<DocumentPackageHeader>, initCallBack: Function): () => boolean {
		const me = this;
		me.moduleData = data;
		me.packageHeader = me.moduleData.initData.data;
		me.massDocumentActions = new MassDocumentActions({
			moduleData: me.moduleData,
			moduleTab: me.moduleData.tab,
			setModuleLoading: me.setModuleLoading.bind(me)
		});
		me._beforeSignStart = me.massDocumentActions.beforeSignStart.bind(me.massDocumentActions);
		me._signActionHandler = me.massDocumentActions.signActionHandler.bind(me.massDocumentActions);
		me._signCompleteCallback = me.massDocumentActions.signCompleteCallback.bind(me.massDocumentActions);
		me._createAnnulActionHandler = me.massDocumentActions.createAnnulActionHandler.bind(me.massDocumentActions);
		me._approveAnnulActionHandler = me.massDocumentActions.approveAnnulActionHandler.bind(me.massDocumentActions);
		me._rejectAnnulActionHandler = me.massDocumentActions.rejectAnnulActionHandler.bind(me.massDocumentActions);
		me._annulCompleteCallback = me.massDocumentActions.annulCompleteCallback.bind(me.massDocumentActions);
		me.renderData(initCallBack).catch(me.moduleErrorHandler.bind(me));
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		documentPackageMethods.eventsObserver.on('uploadNewDocument', me._updateModuleHandler);
		documentPackageMethods.eventsObserver.on('changeDocuments', me._updateModuleHandler);
		documentPackageMethods.eventsObserver.on('delete', me._deleteEventHandler);
		documentPackageMethods.eventsObserver.on('update', me._updateModuleHandler);
	}

	deleteEventHandler({ packageId }: { packageId: number }) {
		const me = this;
		if (packageId === me.packageHeader?.id) {
			me.moduleData.isChanged = false;
			edi.modulesHandler.removeModule(me.moduleData);
		}
	}

	updateModuleHandler({ packageId }: { packageId: number }) {
		const me = this;
		if (packageId === me.packageHeader?.id) {
			me.renderData();
		}
	}

	setModuleLoading(loading: boolean | string | AnyObject) {
		const me = this;
		!me.moduleData.tab.destroyed && me.moduleData.tab.setLoading(loading);
	}

	moduleErrorHandler(err?: AnyObject) {
		const me = this;
		edi.rest.getErrorHandler(null, () => edi.modulesHandler.removeModule(me.moduleData))(err);
	}

	moduleChangeHandler() {
		const me = this;
		me.renderData().catch(me.moduleErrorHandler.bind(me));
	}

	async exportGroupHandler(exportType: string) {
		const me = this;
		const payload = Ext.encode({
			exportType,
			docIds: me.moduleGrid
				.getStore()
				.getRange()
				.map((rec: ExtRecord<DocumentHeader>) => rec.get('id'))
		});
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.rest.services.EXPORT.GROUP_REQUEST.POST,
			method: 'POST',
			params: payload
		});

		if (success) {
			asyncDownloadTask.check({ taskId: data.data.id });
		} else {
			edi.rest.getErrorHandler()(data);
		}
	}

	createExportButton(): ExtComponent {
		const me = this;
		let exportMenu = [
			{
				text: edi.i18n.getMessage('export.group.request.menu.btn.all'),
				handler: function () {
					me.exportGroupHandler(edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON);
				}
			},
			{
				text: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
				handler: function () {
					me.exportGroupHandler(edi.constants.EXPORT_GROUP_REQUEST.TYPE.SIMPLE);
				}
			}
		];
		me.exportButton = createActionsButton({
			itemId: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP,
			text: edi.i18n.getMessage('action.export.short'),
			glyph: edi.constants.ICONS.FILE_DOWNLOAD,
			allowMultiClick: true,
			menu: exportMenu
		}) as ExtComponent;
		return me.exportButton;
	}

	createPrintButton() {
		const me = this;
		me.printButton = createActionsButton({
			itemId: edi.constants.DOCUMENT_ACTIONS.PRINT,
			text: edi.i18n.getMessage('action.print'),
			glyph: edi.constants.ICONS.PRINT,
			handler: function () {
				edi.methods.documentsGroupPrint(me.moduleGrid, me.moduleData, me.moduleGrid.getStore().getRange());
			}
		}) as ExtComponent;
		return me.printButton;
	}

	warnBeforeMassAction(props: {
		handler: () => void;
		text?: string;
		beforeGridText?: string;
		gridItems?: DocumentHeader[];
	}) {
		const warnItems: ExtComponent[] = [
			createLabel({
				html: edi.i18n.getMessage(props.text ?? 'documentPackage.warn.before.action')
			})
		];
		if (props.gridItems?.length) {
			warnItems.push(
				createLabel({
					html: edi.i18n.getMessage(props.beforeGridText ?? 'documentPackage.warn.not.processed')
				})
			);

			warnItems.push(
				createGrid({
					store: edi.stores.createMemoryStore(props.gridItems, 'DOCUMENTS_MODAL'),
					gridConfig: {
						cls: 'ui-documents-list',
						maxHeight: 500,
						disablePaging: true,
						columns: edi.columns.get('documents_modal'),
						hideSettingsButton: true
					}
				}) as ExtComponent
			);
		}
		showConfirm({
			width: MODAL_SIZE.widthMedium,
			items: [
				createModalForm({
					items: warnItems
				})
			],
			success: props.handler
		});
	}

	inverseSelectedRecords(selectedRecords: ExtRecord<DocumentHeader>[]): ExtRecord<DocumentHeader>[] {
		const me = this;
		const selectedMap = selectedRecords.reduce((acc, rec) => {
			acc[rec.get('id')] = rec;
			return acc;
		}, {} as { [id: string]: ExtRecord<DocumentHeader> });
		return me.moduleGrid
			.getStore()
			.getRange()
			.filter((rec: ExtRecord<DocumentHeader>) => !selectedMap[rec.get('id')]);
	}

	getRecordsForSign(): ExtRecord<DocumentHeader>[] {
		const me = this;
		return me.moduleGrid
			.getStore()
			.getRange()
			.filter((rec: ExtRecord<DocumentHeader>) => {
				const docCheckOptions = edi.action.getDocumentData(rec, null, {
					actionFromPackageModule: true
				});
				return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.SIGN, docCheckOptions);
			});
	}

	signRecords(records: ExtRecord<DocumentHeader>[]) {
		const me = this;
		edi.document.actions.documentGridActionProcess({
			action: edi.constants.DOCUMENT_ACTIONS.SIGN,
			moduleData: me.moduleData,
			grid: me.moduleGrid,
			initialData: records,
			data: records,
			handler: me._signActionHandler,
			beforeStart: me._beforeSignStart,
			loadingMessage: 'document.sign.started',
			forceCompleteEvent: true,
			additionalCheckOptions: {
				actionFromPackageModule: true
			},
			completeCallback: function () {
				me._signCompleteCallback();
				documentPackageMethods.eventsObserver.fireEvent('update', { packageId: me.packageHeader.id });
			}
		});
	}

	signButtonHandler() {
		const me = this;
		const records = me.getRecordsForSign();
		me.warnBeforeMassAction({
			text:
				me.packageHeader.state === edi.constants.STATE.DRAFT
					? edi.i18n.getMessage('documentPackage.warn.before.sign.draft')
					: undefined,
			handler: () => me.signRecords(records),
			gridItems: me.inverseSelectedRecords(records).map((rec) => rec.getData())
		});
	}

	createSignButton() {
		const me = this;
		me.signButton = createActionsButton({
			itemId: edi.constants.DOCUMENT_ACTIONS.SIGN,
			text: edi.i18n.getMessage('document.sign.document'),
			glyph: edi.constants.ICONS.SIGN,
			handler: function () {
				me.signButtonHandler();
			}
		}) as ExtComponent;
		return me.signButton;
	}

	async rejectButtonHandler() {
		const me = this;
		const records = me.moduleGrid.getStore().getRange();
		const { notProcessed: notRejectedRecords, reason } = await documentPackageMethods.rejectDocs(
			records,
			me.packageHeader,
			me.massDocumentActions,
			me.moduleGrid,
			me.moduleData
		);
		const { notProcessed: notClarifiedRecords } = await documentPackageMethods.clarifyDocs(
			records,
			reason,
			me.packageHeader,
			me.massDocumentActions,
			me.moduleGrid,
			me.moduleData
		);
		const notProcessedDocuments = [...notRejectedRecords, ...notClarifiedRecords];
		if (notProcessedDocuments.length) {
			documentsModal(
				{
					title: edi.i18n.getMessage('documents.not.processed.title'),
					text: edi.utils.formatString(edi.i18n.getMessage('documents.that.were.not.processed'), []),
					width: MODAL_SIZE.widthLarge,
					model: 'DOCUMENTS_MODAL',
					columns: edi.columns.get('documents_modal_with_error')
				},
				notProcessedDocuments.map(function (doc) {
					doc.data.error =
						edi.utils.formatComplexServerError(doc.errorData) ||
						edi.utils.formatComplexServerError(doc.errorResponse);
					return doc;
				}),
				me.moduleData
			);
		}
	}

	getDocsNotForReject(): ExtRecord<DocumentHeader>[] {
		const me = this;
		return me.moduleGrid
			.getStore()
			.getRange()
			.filter((rec: ExtRecord<DocumentHeader>) => {
				const docCheckOptions = edi.action.getDocumentData(rec, null, {
					actionFromPackageModule: true
				});
				return !(
					edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.REJECT, docCheckOptions) ||
					edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.CLARIFY, docCheckOptions)
				);
			});
	}

	createRejectButton() {
		const me = this;
		me.rejectButton = createActionsButton({
			itemId: edi.constants.DOCUMENT_ACTIONS.REJECT,
			showInSecondHalf: true,
			text: edi.i18n.getMessage('document.reject.document'),
			glyph: edi.constants.ICONS.REJECT,
			handler: function () {
				me.warnBeforeMassAction({
					handler: () => me.rejectButtonHandler(),
					gridItems: me.getDocsNotForReject().map((rec) => rec.getData())
				});
			}
		}) as ExtComponent;
		return me.rejectButton;
	}

	annulButtonHandler(type: 'createAnnul' | 'approveAnnul' | 'rejectAnnul', docRecords: ExtRecord<DocumentHeader>[]) {
		const me = this;
		edi.document.actions.documentGridActionProcess({
			action: edi.constants.DOCUMENT_ACTIONS.ANNUL,
			moduleData: me.moduleData,
			grid: me.moduleGrid,
			initialData: docRecords,
			data: docRecords,
			additionalCheckOptions: {
				actionFromPackageModule: true
			},
			handler:
				type === 'createAnnul'
					? me._createAnnulActionHandler
					: type === 'approveAnnul'
					? me._approveAnnulActionHandler
					: me._rejectAnnulActionHandler,
			completeCallback: function () {
				me._annulCompleteCallback();
				documentPackageMethods.eventsObserver.fireEvent('update', { packageId: me.packageHeader.id });
			}
		});
	}

	filterDocsReadyForAnnul(records: ExtRecord<DocumentHeader>[]): ExtRecord<DocumentHeader>[] {
		return records.filter((rec) => rec.get('businessState') !== edi.constants.STATE.ON_ANNUL);
	}

	filterDocsReadyForAnnulRejection(records: ExtRecord<DocumentHeader>[]): ExtRecord<DocumentHeader>[] {
		return records.filter((rec) => {
			const isOnAnnul = rec.get('businessState') === edi.constants.STATE.ON_ANNUL;
			const direction: string = edi.utils.getDocumentDirection(rec.get('toOrg'), rec.get('fromOrg'));
			const annulCreatorOrgId: number = +edi.utils.getAttributeByName(rec.get('attributes'), 'ANNUL_CREATOR');
			const isIncomingAnnul =
				direction === edi.constants.DIRECTIONS.INCOMING && annulCreatorOrgId === rec.get('fromOrg').id;
			const isOutgoingAnnul =
				direction === edi.constants.DIRECTIONS.OUTGOING && annulCreatorOrgId === rec.get('toOrg').id;
			return isOnAnnul && (isIncomingAnnul || isOutgoingAnnul);
		});
	}

	createAnnulButton() {
		const me = this;
		const allRecords: ExtRecord<DocumentHeader>[] = me.moduleGrid
			.getStore()
			.getRange()
			.filter((record: ExtRecord<DocumentHeader>) => {
				const docCheckOptions = edi.action.getDocumentData(
					edi.models.createInstance('DOCUMENT', record.getData()),
					undefined,
					{ actionFromPackageModule: true }
				);
				return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.ANNUL, docCheckOptions);
			});

		const annulMenuItems = [];
		const recordsReadyForAnnul = me.filterDocsReadyForAnnul(allRecords);
		if (recordsReadyForAnnul.length > 0) {
			annulMenuItems.push({
				text: edi.i18n.getMessage('document.mark.annul'),
				handler: function () {
					me.warnBeforeMassAction({
						handler: () => me.annulButtonHandler('createAnnul', recordsReadyForAnnul),
						gridItems: me.inverseSelectedRecords(recordsReadyForAnnul).map((rec) => rec.getData())
					});
				}
			});
		}
		const recordsReadyForAnnulRejection = me.filterDocsReadyForAnnulRejection(allRecords);
		if (recordsReadyForAnnulRejection.length > 0) {
			const notForAnnulRejection = me
				.inverseSelectedRecords(recordsReadyForAnnulRejection)
				.map((rec) => rec.getData());
			annulMenuItems.push(
				{
					text: edi.i18n.getMessage('document.mark.annul.accept'),
					handler: function () {
						me.warnBeforeMassAction({
							handler: () => me.annulButtonHandler('approveAnnul', recordsReadyForAnnulRejection),
							gridItems: notForAnnulRejection
						});
					}
				},
				{
					text: edi.i18n.getMessage('document.mark.annul.reject'),
					handler: function () {
						me.warnBeforeMassAction({
							handler: () => me.annulButtonHandler('rejectAnnul', recordsReadyForAnnulRejection),
							gridItems: notForAnnulRejection
						});
					}
				}
			);
		}

		me.annulButton =
			annulMenuItems.length === 1
				? (createActionsButton(
						Ext.merge(annulMenuItems[0], {
							glyph: edi.constants.ICONS.ANNUL
						})
				  ) as ExtComponent)
				: annulMenuItems.length > 0
				? (createActionsButton({
						itemId: edi.constants.DOCUMENT_ACTIONS.ANNUL,
						text: edi.i18n.getMessage('document.mark.annul'),
						glyph: edi.constants.ICONS.ANNUL,
						menu: annulMenuItems
				  }) as ExtComponent)
				: undefined;
		return me.annulButton;
	}

	async deleteButtonHandler() {
		const me = this;
		const { success, response } = await documentPackageApi.deletePackage(me.packageHeader.id);
		if (success) {
			documentPackageMethods.eventsObserver.fireEvent('delete', { packageId: me.packageHeader.id });
		} else {
			edi.rest.getErrorHandler()(response);
		}
	}

	warnBeforeDelete() {
		const me = this;
		showConfirm({
			msgText: 'documentPackage.warn.before.delete',
			success: () => me.deleteButtonHandler()
		});
	}

	createDeleteButton() {
		const me = this;
		me.deleteButton = createActionsButton({
			itemId: edi.constants.DOCUMENT_ACTIONS.DELETE,
			text: edi.i18n.getMessage('document.delete.document'),
			glyph: edi.constants.ICONS.DELETE,
			handler: function () {
				me.warnBeforeDelete();
			}
		}) as ExtComponent;
		return me.deleteButton;
	}

	createModuleActionsPanel(): ExtComponent {
		const me = this;
		me.moduleActionsPanel = createActionsPanel() as ExtComponent;
		//клонируем заголовок, что бы мутировать его, т.к. формат у него не такой же как у документов
		const packageData = Ext.clone(me.packageHeader);
		packageData.type = edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE;
		const direction = edi.utils.getDocumentDirection(packageData.toOrg, packageData.fromOrg);
		edi.document.actions.createDocumentActionButtons(me.moduleActionsPanel, {
			data: packageData,
			direction: direction,
			moduleData: me.moduleData,
			needSignatures: 0,
			additionalDocOptions: {
				packageHeader: me.packageHeader,
				packageDocs: me.packageDocs
			},
			actionProps: {
				REFRESH: {
					showInFirstHalf: true,
					handler: () => me.moduleChangeHandler()
				},
				DELETE: {
					createButtonMethod: () => me.createDeleteButton()
				},
				SIGN: {
					createButtonMethod: () => me.createSignButton()
				},
				EXPORT: {
					createButtonMethod: () => me.createExportButton()
				},
				PRINT: {
					createButtonMethod: () => me.createPrintButton()
				},
				REJECT: {
					createButtonMethod: () => me.createRejectButton()
				},
				ANNUL: {
					createButtonMethod: () => me.createAnnulButton()
				}
			}
		});
		return me.moduleActionsPanel;
	}

	openIdentificationModal() {
		const me = this;
		let uuidLabel: ExtComponent;
		let idLabel: ExtComponent;
		const modal = createModalPanel({
			title: edi.i18n.getMessage('documentPackage.identWindow.title'),
			items: [
				createModalForm({
					items: [
						createFieldBlock({
							title: edi.i18n.getMessage('documentPackage.package_uuid'),
							layout: {
								type: 'grid',
								gap: [16, 16],
								area: [[10, 1]]
							},
							items: [
								(uuidLabel = createLabel({
									text: me.packageHeader.guid
								})),
								createButton({
									cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
									tooltip: edi.i18n.getMessage('document.copy.document'),
									glyph: edi.constants.ICONS.COPY,
									handler: function () {
										edi.utils.copyToClipboard(uuidLabel.textEl.el.dom);
									}
								})
							]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('documentPackage.package_id'),
							layout: {
								type: 'grid',
								gap: [16, 16],
								area: [[10, 1]]
							},
							items: [
								(idLabel = createLabel({
									text: me.packageHeader.id
								})),
								createButton({
									cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
									tooltip: edi.i18n.getMessage('document.copy.document'),
									glyph: edi.constants.ICONS.COPY,
									handler: function () {
										edi.utils.copyToClipboard(idLabel.textEl.el.dom);
									}
								})
							]
						})
					]
				})
			]
		});
		modal.show();
	}

	createIdentField(headerPanel: ExtComponent): ExtComponent {
		const me = this;
		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('documentPackage.identWindow.title'),
			grid: headerPanel.DEFAULT_GRID_FIELD_CONFIG,
			layout: headerPanel.DEFAULT_LAYOUT_FIELD_CONFIG,
			order: 160,
			items: [
				createButton({
					cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
					tooltip: edi.i18n.getMessage('documentPackage.identWindow.title'),
					glyph: edi.constants.ICONS.DETAILS,
					handler: function () {
						me.openIdentificationModal();
					}
				})
			]
		});
	}

	createHeaderBlock(): ExtComponent {
		const me = this;
		return createDocumentHeaderPanel(me.moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noId: true,
			noSignatures: true,
			noLinked: true,
			noUsage: true,
			noLog: true,
			noEdemStatus: true,
			noAperakStatus: true,
			stateRenderer: function () {
				return documentPackageMethods.packageStateRenderer(me.packageHeader);
			},
			getAdditionFields: function () {
				const headerPanel: ExtComponent = this as ExtComponent;
				return [me.createIdentField(headerPanel)];
			}
		});
	}

	createTypeNumberDateBlock(): ExtComponent {
		const me = this;
		const isClosed = me.packageHeader.type === PackageType.ENCLOSED;
		return createFieldBlock({
			cls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('documentPackage.type'),
							text: edi.i18n.getMessage(
								isClosed ? 'documentPackage.type.closed' : 'documentPackage.type.opened'
							),
							plugins: [documentPackageMethods.createPackageTypeHintPlugin('textEl')]
						},
						{ title: edi.i18n.getMessage('field.name.number'), text: me.packageHeader.number },
						{
							title: edi.i18n.getMessage('column.date'),
							text: edi.utils.formatDate(me.packageHeader.docTime, edi.constants.DATE_FORMAT.FNS)
						}
					]
				})
			]
		});
	}

	createSenderBlock(): ExtComponent {
		const me = this;
		const senderOrg = me.packageHeader.fromOrg;
		const senderOrgValues = orgSelectorMethods.getDefaultOrgConverter()(senderOrg);
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.sender'),
			items: [
				(me.senderField = createOrgSelector({
					readOnly: true,
					notReturnedVoid: true,
					fieldsMapOnly: true,
					fieldValues: senderOrgValues,
					selectedOrg: senderOrg,
					selectedOrgValues: senderOrgValues,
					fieldsMap: documentPackageSelectors.getFieldsMap('fromOrg'),
					modalConf: documentPackageSelectors.getModalConf()
				}) as ExtComponent)
			]
		}) as ExtComponent;
	}

	createReceiverBlock(): ExtComponent {
		const me = this;
		const receiverOrg = me.packageHeader.toOrg;
		const receiverOrgValues = receiverOrg ? orgSelectorMethods.getDefaultOrgConverter()(receiverOrg) : undefined;
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.receiver'),
			items: [
				(me.receiverField = createOrgSelector({
					readOnly: true,
					notReturnedVoid: true,
					fieldsMapOnly: true,
					fieldValues: receiverOrgValues,
					selectedOrg: receiverOrg,
					selectedOrgValues: receiverOrgValues,
					fieldsMap: documentPackageSelectors.getFieldsMap('toOrg'),
					modalConf: documentPackageSelectors.getModalConf()
				}) as ExtComponent)
			]
		}) as ExtComponent;
	}

	createRemarksBlock(): ExtComponent | null {
		const me = this;
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.remark'),
			items: [
				createLabelForDetails({
					text: me.packageHeader.remark
				})
			]
		}) as ExtComponent;
	}

	getProxyConfig(): AnyObject {
		return {
			type: 'memory'
		};
	}

	getStoreConfig(): AnyObject {
		const me = this;
		return {
			pageSize: edi.constants.ITEMS_PER_PAGE,
			model: edi.models.getModel(DOCUMENT_PACKAGES_DETAILS_WINDOW_MODEL_CONFIG_NAME),
			data: me.packageDocs
		};
	}

	canOpenDetails(record: ExtRecord<DocumentHeader>): boolean {
		return edi.document.actions.checkDocumentActionPermission(
			record.get('type'),
			edi.constants.DOCUMENT_ACTIONS.DETAILS
		);
	}

	openDocDetails(record: ExtRecord<DocumentHeader>) {
		let recordData = record.getData();
		edi.document.actions.openDetailsModule(recordData.type, recordData);
	}

	createDetailsColumnAction(): AnyObject {
		const me = this;
		return {
			glyph: edi.constants.ICONS.DETAILS,
			iconCls: 'edi-grid-row-button-details',
			testCls: 'test-action-icon-details',
			handler: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colindex: number,
				_actionItem: unknown,
				_event: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				me.openDocDetails(record);
			},
			isActionDisabled: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colIndex: number,
				_item: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				return !me.canOpenDetails(record);
			}
		};
	}

	getActionsColumn(): AnyObject {
		const me = this;
		return createActionsColumnConfig({
			items: [me.createDetailsColumnAction()]
		});
	}

	getGridColumns(): AnyObject[] {
		const me = this;
		const cols = edi.columns.get(DOCUMENT_PACKAGES_DOCS_COLUMNS_NAME);
		cols.push(me.getActionsColumn());
		return cols;
	}

	getGridConfig(): AnyObject {
		const me = this;
		return {
			title: edi.i18n.getMessage('documentPackage.grid.title'),
			disablePaging: true,
			columns: me.getGridColumns(),
			maxHeight: 480,
			listeners: {
				celldblclick: function (
					_view: ExtComponent,
					_td: unknown,
					_cellIndex: number,
					record: ExtRecord<DocumentHeader>
				) {
					if (me.canOpenDetails(record)) {
						me.openDocDetails(record);
					}
				}
			}
		};
	}

	createDocsGrid(): ExtComponent {
		const me = this;
		me.moduleGrid = createGrid({
			proxyConfig: me.getProxyConfig(),
			storeConfig: me.getStoreConfig(),
			gridConfig: me.getGridConfig()
		}) as ExtComponent;
		me.moduleGrid._prevReceiverId = me.receiverField.selectedOrg?.id;
		return me.moduleGrid;
	}

	createModuleForm(): ExtComponent {
		const me = this;
		me.moduleForm = createFormForModule({
			region: 'center',
			items: [
				me.createHeaderBlock(),
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [12, [6, 6], 4]
					},
					items: [
						me.createTypeNumberDateBlock(),
						me.createSenderBlock(),
						me.createReceiverBlock(),
						me.createRemarksBlock()
					]
				}),
				me.createDocsGrid()
			]
		});
		return me.moduleForm;
	}

	createModuleView() {
		const me = this;
		if (me.moduleData.tab && !me.moduleData.tab.destroyed) {
			me.moduleData.tab.removeAll();
			me.modulePanel = createDetailsModulePanel({
				items: [me.createModuleForm()]
			}) as ExtComponent;
			me.moduleData.tab.add(me.createModuleActionsPanel());
			me.moduleData.tab.add(me.modulePanel);
		}
	}

	/**
	 * Renders page layout
	 */
	async renderData(initCallBack?: Function) {
		const me = this;

		me.setModuleLoading(true);
		const { packageData, packageItems, error } = await documentPackageApi.fetchFullPackageData(me.packageHeader.id);
		if (error) {
			edi.rest.getErrorHandler(null, () => me.moduleData.tab.close())(error);
			return;
		} else {
			me.packageHeader = packageData;
			me.moduleData.initData.data = me.packageHeader;
			me.packageDocs = packageItems;
		}

		const title = edi.i18n.getMessage('documentPackage.details') + ' ' + me.packageHeader.number;
		me.moduleData.tab.setTitle(title);
		me.setModuleLoading(false);

		me.createModuleView();

		if (typeof initCallBack === 'function') {
			initCallBack();
		}
	}

	/**
	 * Routine that must be done before module destroy
	 */
	onDestroy() {
		const me = this;
		documentPackageMethods.eventsObserver.un('uploadNewDocument', me._updateModuleHandler);
		documentPackageMethods.eventsObserver.un('changeDocuments', me._updateModuleHandler);
		documentPackageMethods.eventsObserver.un('delete', me._deleteEventHandler);
		documentPackageMethods.eventsObserver.un('update', me._updateModuleHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

edi.modules['details.documentPackage'] = DocumentPackageDetailsModule;
edi.modulesCfg['details.documentPackage'] = {
	title: 'documentPackage.details',
	modName: 'details.documentPackage',
	folder: 'edi/modules/documentPackages',
	permissions: ['READ_PACKAGE_OBJECT']
};
