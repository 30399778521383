import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createActionsButton } from '@Components/buttons';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { PRICAT_EANCOM_V2_DOC_FLOW_TYPES } from './configuration';
import { createDateField, createLabelBlockForDetails, createTextField } from '@UIkit/components/fields';
import { createContainer, createFieldBlock, createFieldBlockForDetails } from '@UIkit/components/panels';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { PRICAT_EANCOM_V2_LINE_COLUMN } from './columns';
import { PRICAT_EANCOM_V2_METHODS } from './methods';
import { createTab, createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
import { createEditableGrid } from '@Components/editableGrid/EditableGrid';

Ext.namespace('edi.modules');
edi.modules['document.details.pricateancom_v2'] = function () {
	let moduleData,
		invoice = null,
		noticeDoc = null,
		utochDoc = null,
		failure;
	const firstProductPath = 'sg17.sg36.0';
	let supplierNDSPayerState, isPricatCorrection, isPricatCancellation, docFlowType;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docFlowType = edi.utils.getAttributeByName(moduleData.initData?.data?.attributes, 'doc_flow_type');
		isPricatCorrection = docFlowType === PRICAT_EANCOM_V2_DOC_FLOW_TYPES.CORRECTION;
		isPricatCancellation = docFlowType === PRICAT_EANCOM_V2_DOC_FLOW_TYPES.CANCELLATION;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates invoice form
	 * @returns {Object}
	 */
	const createDetailsPanel = function (pricatEancom) {
		const mainData = Ext.clone(moduleData.initData.data);
		mainData.hasLinkedDocuments = true;

		supplierNDSPayerState = edi.utils.getAttributeByName(mainData.attributes, 'NDS_payer');

		const getComboBoxValue = function (valuesArray, fieldName) {
			let nameCode = edi.utils.getObjectProperty(pricatEancom, fieldName);
			if (!valuesArray.some((it) => it === String(nameCode))) {
				nameCode = null;
			}
			return nameCode;
		};

		const getPricatEancomValue = function ({ fieldName, path, asArray }) {
			return path
				? edi.utils.getObjectPropertyEx(pricatEancom, path)
				: edi.utils.getObjectProperty(pricatEancom, fieldName, asArray);
		};

		const headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			noUsage: true
		});

		const headData = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: 'column',
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('pricat_eancom.document.type'),
							text: edi.i18n.getMessage(
								'pricate_eancom.name.code.' + getComboBoxValue(['9', '25E'], 'bgm.c002.e1001')
							)
						},
						{
							title: edi.i18n.getMessage('pricat.nr'),
							text: getPricatEancomValue({ fieldName: 'bgm.c106.e1004' })
						},
						{
							title: edi.i18n.getMessage('date'),
							text: PRICAT_EANCOM_V2_METHODS.formatPricatDate(
								getPricatEancomValue({ fieldName: 'dtm.0.c507.e2380' })
							)
						}
					]
				})
			]
		});

		const seller_parties = edi.utils.getObjectPropertyEx(pricatEancom, 'sg2[nad.e3035==SU]');
		const seller = createOrgSelector({
			readOnly: true,
			valuesByMap: true,
			is_valid: true,
			fieldValues: seller_parties,
			fieldsMap: {
				company_name: 'nad.c080.e3036',
				company_iln: 'nad.c082.e3039',
				company_inn: 'sg3[rff.c506.e1153==FC]rff.c506.e1154',
				company_kpp: 'sg3[rff.c506.e1153==XA]rff.c506.e1154'
			},
			getObjectProperty: edi.utils.getObjectPropertyEx
		});

		const sellerBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.pricate_eancom.seller'),
			items: [seller]
		});

		const buyer_parties = edi.utils.getObjectPropertyEx(pricatEancom, 'sg2[nad.e3035==BY]');
		const buyer = createOrgSelector({
			readOnly: true,
			valuesByMap: true,
			is_valid: true,
			fieldValues: buyer_parties,
			fieldsMap: {
				company_name: 'nad.c080.e3036',
				company_iln: 'nad.c082.e3039',
				company_inn: 'sg3[rff.c506.e1153==FC]rff.c506.e1154',
				contact_email: 'sg4.0.com.c076.e3148'
			},
			getObjectProperty: edi.utils.getObjectPropertyEx
		});

		const buyerBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.pricate_eancom.buyer'),
			items: [buyer]
		});

		const typePriceNoticeValue = getComboBoxValue(['ZAK', 'MAR'], 'sg17.ftx.0.c108.e4440');
		const contractBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('pricat_eancom.contract.details'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('pricate_eancom.delivery.contract.number'),
							text: getPricatEancomValue({ path: 'sg1[rff.c506.e1153==CT]rff.c506.e1154' })
						},
						{
							title: edi.i18n.getMessage('pricate_eancom.date.delivery.agreement'),
							text: PRICAT_EANCOM_V2_METHODS.formatPricatDate(
								getPricatEancomValue({ path: 'sg1[rff.c506.e1153==CT]dtm.c507.e2380' })
							)
						},
						{
							title: edi.i18n.getMessage('pricat_eancom.contract.details.price.type.notice'),
							text: typePriceNoticeValue
								? edi.i18n.getMessage('pricate_eancom.type.price.notice.code.' + typePriceNoticeValue)
								: null
						}
					]
				})
			]
		});

		const documentDataBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('catalog.partin.documentData'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('documents.pricat_eancom_v2.document_purpose'),
							text: getPricatEancomValue({ fieldName: 'sg17.ftx.1.c108.e4440' })
						},
						{
							title: edi.i18n.getMessage('documents.pricate_eancom_v2.SRM_id'),
							text: getPricatEancomValue({ path: 'sg1[rff.c506.e1153==ACE]rff.c506.e1154' })
						},
						{
							title: edi.i18n.getMessage('pricat.product.unitGrossPrice'),
							text: getPricatEancomValue({ fieldName: `${firstProductPath}.sg40.1.pri.c509.e5118` })
						}
					]
				})
			]
		});

		const createPlaceGrid = ({ values }) =>
			createEditableGrid(
				{
					fields: [
						{
							title: 'column.name',
							name: 'name'
						},
						{
							title: 'company.gln.short',
							name: 'iln'
						}
					],
					limit: undefined,
					model: 'PARTIN_DELIVERY_PLACE',
					modalWidth: MODAL_SIZE.widthLarge,
					readOnly: true,
					columnsConfig: 'partin_delivery_place',
					gridConfig: {
						cls: 'modal-grid'
					}
				},
				values
			);

		const lines = edi.utils.getObjectProperty(pricatEancom, 'sg17.sg36');
		const pricatLines = [];
		if (lines && 'string' != typeof lines) {
			for (let i = 0; i < lines.length; i++) {
				pricatLines.push(lines[i]);
			}
		}

		const products = PRICAT_EANCOM_V2_METHODS.getPricatLinesProperties({ pricatLines });
		const createProductInfoFields = ({ recordData }) => {
			const ean = createFieldBlock({
				title: edi.i18n.getMessage('line.item.ean'),
				items: createTextField({
					name: 'EAN',
					disabled: true,
					valueSrc: recordData
				})
			});
			const itemCode = createFieldBlock({
				title: edi.i18n.getMessage('column.pricate.eancom.item.code'),
				items: createTextField({
					name: 'ItemCode',
					disabled: true,
					valueSrc: recordData
				})
			});
			const heading = createFieldBlock({
				title: edi.i18n.getMessage('column.pricate.eancom.heading'),
				items: createTextField({
					name: 'Heading',
					disabled: true,
					valueSrc: recordData
				})
			});
			const newPrice = createFieldBlock({
				title: edi.i18n.getMessage('column.pricate.eancom.new.price'),
				items: createTextField({
					name: 'NewPrice',
					disabled: true,
					valueSrc: recordData
				})
			});
			const taxRate = createFieldBlock({
				title: edi.i18n.getMessage('documents.pricate_eancom_v2.tax_rate'),
				hidden: supplierNDSPayerState !== 'mixed',
				items: createTextField({
					name: 'taxRate',
					disabled: true,
					valueSrc: recordData,
					listeners: {
						beforerender: (label) => {
							if (label.text === 'null') {
								label.setText(edi.constants.taxRates.taxNoVat);
							}
						}
					}
				})
			});

			const startingDateNewPrice = createDateField({
				disabled: true,
				value: PRICAT_EANCOM_V2_METHODS.formatPricatDate(recordData?.startingDateNewPrice)
			});

			const endingDateNewPrice = createDateField({
				disabled: true,
				value: PRICAT_EANCOM_V2_METHODS.formatPricatDate(recordData?.endingDateNewPrice)
			});

			const pricePeriod = createFieldBlock({
				title: edi.i18n.getMessage('pricat_eancom.new.price.period'),
				layout: {
					type: 'grid',
					area: [[6, 6]]
				},
				items: [startingDateNewPrice, endingDateNewPrice]
			});

			const volume = createFieldBlock({
				title: edi.i18n.getMessage('documents.pricate_eancom_v2.volume'),
				items: createTextField({
					name: 'volume',
					disabled: true,
					valueSrc: recordData
				})
			});

			return [
				ean,
				itemCode,
				heading,
				volume,
				taxRate,
				newPrice,
				!isPricatCancellation ? pricePeriod : null
			].filter(Boolean);
		};
		const createModalForm = (recordData) =>
			createTabPanel({
				cls: TAB_PANEL_CLS.simpleWithoutPadding,
				items: [
					createTab({
						title: edi.i18n.getMessage('pricat_eancom.lines.grid'),
						padding: '24',
						closable: false,
						autoScroll: true,
						layout: {
							type: 'grid',
							gap: 24
						},
						items: createProductInfoFields({ recordData })
					}),
					createTab({
						title: edi.i18n.getMessage('documents.pricate_eancom.storage'),
						padding: '24',
						closable: false,
						autoScroll: true,
						items: createPlaceGrid({ values: recordData.deliveryPlace })
					}),
					createTab({
						title: edi.i18n.getMessage('documents.pricate_eancom.exception.from.storage'),
						padding: '24',
						closable: false,
						autoScroll: true,
						items: createPlaceGrid({ values: recordData.exceptionsPlace })
					})
				]
			});

		const productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('pricat_eancom.lines.grid'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'EANCOM_LINE',
			gridColumnConfig: PRICAT_EANCOM_V2_LINE_COLUMN,
			data: products,
			readOnly: true,
			hasTotal: false,
			modalConf: {
				width: MODAL_SIZE.widthMedium
			},
			gridConfig: {
				settingsBtnConf: {
					panelMinWidth: 720
				}
			},
			createModalForm,
			modalFormConfig: {
				title: 'pricat.product.modal.title'
			}
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16]
			},
			items: [
				headPanel,
				createContainer({
					cls: 'edi-form-maxwidth',
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [12, [6, 6]]
					},
					items: [headData, sellerBlock, buyerBlock, contractBlock, documentDataBlock]
				}),
				!isPricatCancellation ? productsGrid : null
			]
		});
	};

	/**
	 * Creates action panel
	 */
	const createModuleActionsPanel = function (invoice) {
		const data = moduleData.initData.data;
		invoice.id = data.id;
		const actionsPanel = createActionsPanel();
		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const needSignatures = edi.document.actions.getSignCount(data);
		const needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.SUPPLIER);
		let signaturesToSet = needSignatures + needSignatures2 - data.countSignatures;
		if (signaturesToSet < 0) {
			signaturesToSet = 0;
		}

		let readCmp = null;
		if (
			edi.constants.DIRECTIONS.INCOMING === direction &&
			data.state === edi.constants.STATE.WAIT_RECEIVER_DECISION
		) {
			readCmp = createActionsButton({
				text: edi.i18n.getMessage('document.mark.read'),
				glyph: edi.constants.ICONS.READ,
				handler: function () {
					edi.core.confirm(
						edi.i18n.getMessage('document.mark.read'),
						edi.i18n.getMessage('document.mark.read.question'),
						function () {
							moduleData.tab.setLoading();
							const success = function () {
								moduleData.tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: data.id
								});
							};
							const failure = edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.mark.read'
							);
							const markRead = function () {
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: data.id
										},
										true
									),
									'PUT',
									Ext.encode({
										CREATE_IZVPOL: true
									}),
									success,
									failure
								);
							};
							markRead();
						}
					);
				}
			});
		}

		const customButtons = [];
		const isPricatOriginal = edi.utils.getObjectProperty(invoice, 'bgm.e1225') === '9';
		if (isPricatOriginal && data.countSignatures >= 2 && edi.constants.DIRECTIONS.OUTGOING === direction) {
			const { CORRECTION, CANCELLATION } = PRICAT_EANCOM_V2_DOC_FLOW_TYPES;
			const createDocConfig = [
				{
					permission: 'CREATE_PRICAT_EANCOM',
					title: `documents.doctype.${CANCELLATION}`,
					docType: edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
					config: {
						addData: {
							docFlowType: CANCELLATION,
							parentId: invoice.id,
							versionId: '2',
							title: edi.i18n.getMessage(`documents.doctype.${CANCELLATION}`),
							meta: {
								//  нужно для openModule, чтобы можно было отличить эти модули друг от друга по уникальному id
								id: `${invoice.id}_${CANCELLATION}`
							}
						},
						isFromTransformation: true,
						postData: {
							docType: edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
							docFlowType: CANCELLATION,
							direction: 'OUTBOX',
							version: '2'
						}
					}
				},
				{
					permission: 'CREATE_PRICAT_EANCOM',
					title: `documents.doctype.${CORRECTION}`,
					docType: edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
					config: {
						addData: {
							docFlowType: CORRECTION,
							parentId: invoice.id,
							versionId: '2',
							title: edi.i18n.getMessage(`documents.doctype.${CORRECTION}`),
							meta: {
								//  нужно для openModule, чтобы можно было отличить эти модули друг от друга по уникальному id
								id: `${invoice.id}_${CORRECTION}`
							}
						},
						isFromTransformation: true,
						postData: {
							docType: edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
							docFlowType: CORRECTION,
							direction: 'OUTBOX',
							version: '2'
						}
					}
				}
			];

			const basedDocuments = edi.document.actions.createListBasedDocuments(
				'CREATE_FROM',
				data,
				moduleData,
				invoice,
				'pricateancom',
				createDocConfig
			);
			if (basedDocuments?.length) {
				customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
			}
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesToSet,
			infoTitle: edi.i18n.getMessage(
				isPricatCorrection || isPricatCancellation
					? `documents.doctype.${docFlowType}`
					: `documents.doctype.PRICAT_EANCOM_V2`
			),
			actionProps: {
				EDIT: {
					moduleName: 'document.create.pricateancom_v2'
				},
				SIGN: {
					methodAddOptions: {
						useBeforeAction:
							data.state === edi.constants.STATE.DRAFT ||
							data.state === edi.constants.STATE.SENT ||
							data.state === edi.constants.STATE.WAIT_RECEIVER_DECISION
					}
				},
				READ: {
					component: readCmp
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				REJECT: {
					methodAddOptions: {
						process: {
							callback: async function (_signData, success, failed) {
								await new Promise((resolve) => {
									edi.document.actions.updateDocumentHeaderData(moduleData, function (headData) {
										getLinkedDocument(function (responseData) {
											if (
												responseData &&
												Array.isArray(responseData.items) &&
												responseData.items.length
											) {
												const linkedDocs = responseData.items;
												for (let i = 0; i < linkedDocs.length; i++) {
													if (
														linkedDocs[i].type ===
														edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_UVUTOCH
													) {
														utochDoc = linkedDocs[i];
													}
												}

												if (utochDoc && utochDoc.id) {
													edi.document.actions.signUtochDocument(
														utochDoc,
														headData,
														function () {
															edi.core.showInfo('dsf.utoch.created.success');
															success();
															resolve();
														},
														function (response) {
															failed(response);
															resolve();
														},
														moduleData.tab,
														null,
														{
															signContentUrlMethod: 'PUT'
														}
													);
												} else {
													changeHandler(headData);
													resolve();
												}
											} else {
												changeHandler(headData);
												resolve();
											}
										});
									});
								});
								edi.events.documents.fireEvent('change');
							}
						}
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE, {
						documentId: moduleData.initData.id
					})
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};

	const getLinkedDocument = function (callback) {
		const url = edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
			depth: edi.constants.DEFAULT.TREE_DEPTH
		});
		edi.rest.sendRequest(url, 'GET', {}, callback, failure);
	};

	const showIzvPolSignModal = function (document) {
		edi.core.showWarn('dsf.need.sign.izv.pol', function () {
			edi.utils.sign(
				document,
				moduleData.tab,
				function (failed, responseData) {
					if (failed) {
						edi.core.showError(edi.utils.formatComplexServerError(responseData));
					} else {
						edi.events.documents.fireEvent('change', {
							id: document.id
						});
					}
				},
				undefined,
				undefined,
				undefined,
				undefined,
				{
					signContentUrl: edi.utils.formatString(
						'AB' === edi.login.getAuthType()
							? edi.rest.services.DOCUMENTS.SIGN_AB.GET
							: edi.rest.services.DOCUMENTS.SIGN.GET,
						{
							documentId: noticeDoc.id
						}
					),
					signContentUrlMethod: 'PUT'
				}
			);
		});
	};
	const showUtochSignModal = function (document) {
		edi.core.showWarn('pricate_eancom.need.sign.rejection', function () {
			moduleData.tab.setLoading();
			edi.document.actions.signUtochDocument(
				utochDoc,
				document,
				function (response) {
					if (response?.data) {
						edi.events.documents.fireEvent('change', {
							id: document.id,
							deleted: true
						});
						edi.core.showInfo('dsf.utoch.created.success');
					}
					moduleData.tab.setLoading(false);
				},
				function (responseData) {
					edi.core.showError(edi.utils.formatComplexServerError(responseData, 'error.sign.document'));
					moduleData.tab.setLoading(false);
				},
				null,
				null,
				{
					signContentUrlMethod: 'PUT'
				}
			);
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		const data = moduleData.initData.data;
		const documentDirection = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const modulePanel = createDetailsModulePanel();

		if (isPricatCorrection || isPricatCancellation) {
			const title = `${edi.i18n.getMessage(`documents.doctype.${docFlowType}`)} № ${
				moduleData.initData?.data?.number || ''
			}`;
			moduleData.tab.tab.setText(title);
			moduleData.tab.tab.setTooltip(title);
		}

		failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const finishLoad = function () {
			const needSignIzvPol =
				data.state === edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER &&
				edi.constants.DIRECTIONS.INCOMING === documentDirection;
			const needSignUtoch =
				data.state === edi.constants.STATE.PRICAT_EANCOM_DP_UTOCH_RECEIVER &&
				edi.constants.DIRECTIONS.INCOMING === documentDirection;

			if (needSignUtoch && data.id) {
				showUtochSignModal(data);
			} else if (needSignIzvPol && data.id) {
				showIzvPolSignModal(data);
			}

			edi.rest.sendRequest(
				edi.document.actions.formatDetailsUri(moduleData.initData),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						moduleData.tab.removeAll();

						invoice = data.data;
						modulePanel.add(createDetailsPanel(invoice));
						const actionsPanel = createModuleActionsPanel(invoice);
						moduleData.tab.add(actionsPanel);
						moduleData.tab.add(modulePanel);
						moduleData.tab.setLoading(false);
					} else {
						failure(data);
					}
				},
				failure
			);
		};

		const annulStatus = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'ANNUL_STATUS');
		const rejectReasonText = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'REJECT_REASON'),
			hasUnfinishedAnnul =
				annulStatus &&
				(annulStatus === edi.constants.STATE.CREATED || annulStatus === edi.constants.STATE.DRAFT),
			hasIzvPol = data.state === edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER;

		if (rejectReasonText || hasUnfinishedAnnul || hasIzvPol) {
			getLinkedDocument(function (responseData) {
				if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
					const linkedDocs = responseData.items;
					for (let i = 0; i < linkedDocs.length; i++) {
						if (linkedDocs[i].type === edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_IZVPOL) {
							if (data.state === edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER) {
								noticeDoc = linkedDocs[i];
							}
						} else if (linkedDocs[i].type === edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_UVUTOCH) {
							utochDoc = linkedDocs[i];
						}
					}
				}
				finishLoad();
			});
		} else {
			finishLoad();
		}
		// чтобы выполнилась подписка при событии onRender, вызовем initCallBack параллельно рендеру
		if ('function' == typeof initCallBack) {
			initCallBack();
			moduleData.tab.setLoading();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
