import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule, createPanel } from '@Components/panels';
import { createDeliveryCoordination } from '@Edi/specialComponents/deliveryCoordination';
import { ORDER_LINE_COLUMN, ORDER_SUMMARY_DETAIL_COLUMN } from './columns';
import { ORDER_LINE_MODEL, ORDER_SUMMARY_MODEL } from './models';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import {
	createContainer,
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createMaxWidthContainerForDetail,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createCurrencyStore } from '@Edi/methods/methods';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { orderSelectors } from './selectors';

/**
 * Class for order details
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.details.order'] = function () {
	let moduleData,
		linesData,
		customFieldsContainer,
		productsGrid,
		topPath = '//Document-Order/';
	let userOrg = edi.core.getUserData()?.org,
		buyerOrg;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('incomingBlockRemoved', onBlockRemoved);
		edi.events.documents.on('change', changeHandler);
	};

	const onBlockRemoved = function (eventData) {
		if (eventData.docId === moduleData.initData.data.id && !moduleData.tab.isDestroyed) {
			moduleData.isChanged = false;
			edi.modulesHandler.removeModule(moduleData);
		}
	};

	/**
	 * Creates order form
	 * @returns {Object}
	 */
	const createDetailsPanel = function (document) {
		const headPanel = createDocumentHeaderPanel(moduleData.initData, {
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
		});

		const formatDate = function (date) {
			return edi.utils.formatDate(date, edi.constants.DATE_FORMAT.FNS);
		};

		let projectName = edi.utils.getAttributeByName(moduleData.initData.data?.attributes, 'project_s');
		if (projectName) {
			headPanel.add(
				createPanel({
					cls: 'edi-document-header-panel-row',
					margin: '0 0 16 0',
					items: [
						createFieldBlockForDetails({
							cls: FIELD_BLOCK_CLS.small,
							title: edi.i18n.getMessage('documents.fns_upd.project'),
							grid: {
								col: 2
							},
							items: [
								createLabelForDetails({
									text: projectName
								})
							]
						})
					]
				})
			);
		}
		const orderRemarks = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.remark'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			cls: 'edi-form-maxwidth',
			items: createLabelForDetails({
				text: edi.utils.getObjectProperty(document, 'Order-Header.Remarks')
			})
		});

		const createMainDataBlock = function () {
			const getComboBoxValue = function (valuesArray, fieldName) {
				let nameCode = edi.utils.getObjectProperty(document, fieldName)
					? edi.utils.getObjectProperty(document, fieldName)
					: '';
				if (!valuesArray.some((it) => it === String(nameCode))) {
					nameCode = null;
				}
				return nameCode;
			};

			const getCurrency = function () {
				const currencyValue = edi.utils.getObjectProperty(document, 'Order-Header.OrderCurrency');
				if (currencyValue) {
					const currencyRecord = createCurrencyStore().findRecord('id', currencyValue);
					return currencyRecord?.get('name');
				}
			};

			const content = [
				{
					title: edi.i18n.getMessage('order.name.code'),
					text: edi.i18n.getMessage(
						'document.ordersp.order.name.code.' +
							getComboBoxValue(['105', '788', '22E', '222', '220'], 'Order-Header.DocumentNameCode')
					)
				},
				{
					title: edi.i18n.getMessage('document.form.number'),
					text: edi.utils.getObjectProperty(document, 'Order-Header.OrderNumber')
				},
				{
					title: edi.i18n.getMessage('documents.column.date'),
					text: formatDate(edi.utils.getObjectProperty(document, 'Order-Header.OrderDate'))
				},
				{
					title: edi.i18n.getMessage('order.currency'),
					text: getCurrency(),
					isBlock: true
				},
				{
					title: edi.i18n.getMessage('functional.document.code'),
					text: edi.i18n.getMessage(
						'functional.document.code.' +
							edi.utils.getObjectProperty(document, 'Order-Header.DocumentFunctionCode')
					)
				},
				{
					title: edi.i18n.getMessage('document.form.discount'),
					text: edi.utils.getObjectProperty(document, 'Order-Header.Discount')
				},
				{
					title: edi.i18n.getMessage('promotion.reference'),
					text: edi.utils.getObjectProperty(document, 'Order-Header.PromotionReference')
				},
				{
					title: edi.i18n.getMessage('document.form.specialInstructions'),
					text: edi.utils.getObjectProperty(document, 'Order-Header.SpecialInstructions'),
					isBlock: true,
					isVertical: true
				}
			];

			return createFieldBlockForDetails({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						gap: [8, 16],
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						contents: content
					})
				]
			});
		};

		const createTransportInfoBlock = function () {
			const termsOfDeliveryRawValue = edi.utils.getObjectProperty(
				document,
				'Order-Header.Order-Transport.TermsOfDelivery'
			);
			let termsOfDeliveryDisplayValue = null;
			if (termsOfDeliveryRawValue) {
				termsOfDeliveryDisplayValue = edi.i18n.getMessage('terms.of.delivery.' + termsOfDeliveryRawValue);
			}

			const transportContent = [
				{
					title: edi.i18n.getMessage('mode.of.transport'),
					text: edi.renderers.modeOfTransport(
						edi.utils.getObjectProperty(document, 'Order-Header.Order-Transport.ModeOfTransport')
					)
				},
				{
					title: edi.i18n.getMessage('document.ordersp.terms.of.delivery'),
					text: termsOfDeliveryDisplayValue
				},
				{
					title: edi.i18n.getMessage('document.ordersp.transport.number'),
					text: edi.utils.getObjectProperty(
						document,
						'Order-Header.Order-Transport.ConveyanceReferenceNumber'
					)
				}
			];

			const expectedDeliveryDate = formatDate(
				edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryDate')
			);
			const expectedDeliveryTime = edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryTime');
			const lastExpectedDeliveryDate = formatDate(
				edi.utils.getObjectProperty(document, 'Order-Header.LastExpectedDeliveryDate')
			);
			const lastExpectedDeliveryTime = edi.utils.getObjectProperty(
				document,
				'Order-Header.LastExpectedDeliveryTime'
			);

			const periodContent = [
				{
					title: edi.i18n.getMessage('date'),
					text: expectedDeliveryDate
				},
				{
					title: edi.i18n.getMessage('time'),
					text: expectedDeliveryTime
				},
				{
					title: '',
					html:
						(expectedDeliveryDate || expectedDeliveryTime) &&
						(lastExpectedDeliveryDate || lastExpectedDeliveryTime)
							? '&mdash;'
							: null,
					color: '--color-grey-50'
				},
				{
					title: '',
					text: lastExpectedDeliveryDate
				},
				{
					title: edi.i18n.getMessage('time'),
					text: lastExpectedDeliveryTime
				}
			];

			return createFieldSetForDetails({
				title: edi.i18n.getMessage('document.section.delivery'),
				collapsible: true,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						gap: 8
					},
					items: [
						createLabelBlockForDetails({
							gap: [0, 16],
							contents: transportContent
						}),
						createLabelBlockForDetails({
							gap: [0, 16],
							contents: periodContent
						}),
						createLabelForDetails({
							title: edi.i18n.getMessage('period.of.delivery'),
							text: edi.utils.getObjectProperty(document, 'Order-Header.DeliveryPeriod')
						})
					]
				})
			});
		};

		/**
		 * Дополнительная информация
		 */
		const createAdditionalInformationBlock = function () {
			return createFieldSetForDetails({
				title: edi.i18n.getMessage('documents.special.identifiers'),
				collapsible: true,
				items: createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						gap: 24
					},
					items: [
						createFieldBlockForDetails({
							title: edi.i18n.getMessage('document.order.correction'),
							userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
							items: [
								createLabelBlockForDetails({
									gap: 16,
									contents: [
										{
											title: edi.i18n.getMessage('order.number'),
											text: edi.utils.getObjectProperty(
												document,
												'Order-Header.Reference.OrderCorrectionNumber'
											)
										},
										{
											title: edi.i18n.getMessage('order.date'),
											text: formatDate(
												edi.utils.getObjectProperty(
													document,
													'Order-Header.Reference.OrderCorrectionDate'
												)
											)
										}
									]
								})
							]
						}),
						createFieldBlockForDetails({
							title: edi.i18n.getMessage('document.agreement'),
							userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
							items: [
								createLabelBlockForDetails({
									gap: 16,
									contents: [
										{
											title: edi.i18n.getMessage('field.name.number'),
											text: edi.utils.getObjectProperty(
												document,
												'Order-Header.Reference.ContractNumber'
											)
										},
										{
											title: edi.i18n.getMessage('date'),
											text: formatDate(
												edi.utils.getObjectProperty(
													document,
													'Order-Header.Reference.ContractDate'
												)
											)
										}
									]
								})
							]
						})
					]
				})
			});
		};

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		const createPartiesBlock = function () {
			const parties = edi.utils.getObjectProperty(document, 'Order-Parties');

			const createCS = function (selectorConfig) {
				const config = Object.assign({}, selectorConfig, {
					showDetailsButton: true,
					fieldsMapOnly: true,
					is_valid: true,
					readOnly: true,
					valuesByMap: true,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					fieldValues: parties
				});

				return createOrgSelector(config);
			};

			const docHeader = moduleData.initData.data;
			const buyer = createCS({
				selectedOrg: edi.utils.getOrg({
					orgId: docHeader.fromOrg.id
				}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				fieldsMap: orderSelectors.getBuyerFieldsMap(),
				modalConf: orderSelectors.getBuyerModalCong()
			});

			buyerOrg = edi.utils.getOrg({ orgINN: buyer?.getValues()?.company_inn });

			const seller = createCS({
				selectedOrg: edi.utils.getOrg({
					orgId: docHeader.toOrg.id
				}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				fieldsMap: orderSelectors.getSellerFieldsMap(),
				modalConf: orderSelectors.getSellerModalConf()
			});

			const shipFrom = createCS({
				fieldsMap: orderSelectors.getShipFromFieldsConf(),
				modalConf: orderSelectors.getShipFromModalConf()
			});

			const delivery = createCS({
				fieldsMap: orderSelectors.getDeliveryFieldsConf(),
				modalConf: orderSelectors.getDeliveryModalConf()
			});

			const hasUltimateCustomer = !!edi.utils.getObjectProperty(parties, 'UltimateCustomer');
			const ultimateCustomer = hasUltimateCustomer
				? createCS({
						fieldsMap: orderSelectors.getUltimateCustomerFieldsConf(),
						modalConf: orderSelectors.getUltimateCustomerModalConf()
				  })
				: null;

			return createFieldSetForDetails({
				title: edi.i18n.getMessage('document.section.parties'),
				collapsible: true,
				items: createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						area: [[6, 6], [6, 6], [6]]
					},
					items: [
						createFieldBlockForDetails({
							title: edi.i18n.getMessage('documents.order.buyer'),
							items: [buyer]
						}),
						createFieldBlockForDetails({
							title: edi.i18n.getMessage('documents.order.seller'),
							items: [seller]
						}),
						createFieldBlockForDetails({
							title: edi.i18n.getMessage('document.ship.from'),
							items: [shipFrom]
						}),
						createFieldBlockForDetails({
							title: edi.i18n.getMessage('document.delivery'),
							items: [delivery]
						}),
						hasUltimateCustomer
							? createFieldBlockForDetails({
									title: edi.i18n.getMessage('documents.ultimate.customer'),
									items: [ultimateCustomer]
							  })
							: null
					]
				})
			});
		};

		var lines = edi.utils.getObjectProperty(document, 'Order-Lines.Line', true);
		linesData = [];
		if ('string' != typeof lines) {
			for (var i = 0; i < lines.length; i++) {
				var values = lines[i]['Line-Item'];
				values['Line-Reference'] = lines[i]['Line-Reference'];

				var lineReference = edi.utils.getObjectProperty(
					lines[i],
					'Line-Reference.Reference-Elements.Reference-Element',
					true
				);
				if (Array.isArray(lineReference)) {
					lineReference = lineReference.filter(function (item) {
						var referenceType = edi.utils.getObjectProperty(item, 'Reference-Type', false);
						return referenceType === 'spc';
					});
				} else {
					lineReference = [];
				}
				edi.utils.setObjectProperty(
					values,
					'Line-Reference.Reference-Elements.Reference-Element',
					lineReference[0]
				);

				values['BuyerLocationCode'] = edi.utils.getObjectProperty(
					lines[i],
					'Line-Delivery.BuyerLocationCode',
					true
				);
				values['ExpectedDeliveryDate'] = edi.utils.getObjectProperty(
					lines[i],
					'Line-Delivery.ExpectedDeliveryDate',
					true
				);
				values['TaxRate'] = edi.renderers.taxRateString(values['TaxRate']);
				linesData.push(values);
			}
		}
		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.order'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			data: linesData,
			readOnly: true,
			gridModel: ORDER_LINE_MODEL,
			totalModel: ORDER_SUMMARY_MODEL,
			gridColumnConfig: ORDER_LINE_COLUMN,
			totalColumnConfig: ORDER_SUMMARY_DETAIL_COLUMN,
			lineNumberFieldName: 'LineNumber',
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
			docData: document,
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			modalFormConfig: {
				title: 'document.order.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						customFieldTab: 'PRODUCT',
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [5, [6, 3, 3], [3, 3], [3, 3]]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.product.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN'
							},
							{
								title: edi.i18n.getMessage('line.item.model'),
								name: 'Model'
							},
							{
								title: edi.i18n.getMessage('line.item.ordered.quantity'),
								name: 'OrderedQuantity',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								name: 'SupplierItemCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						customFieldTab: 'PRICES',
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [3, [3, 3], 3, [3, 3, 3], 3]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.ordered.unit.net.price'),
								name: 'OrderedUnitNetPrice',
								type: 'number',
								allowDecimals: true
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'OrderedUnitGrossPrice',
								type: 'number',
								allowDecimals: true
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate'
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								name: 'OrderedNetAmount',
								type: 'number',
								allowDecimals: true
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								name: 'OrderedTaxAmount',
								type: 'number',
								allowDecimals: true
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								name: 'OrderedGrossAmount',
								type: 'number',
								allowDecimals: true
							},
							{
								title: edi.i18n.getMessage('line.item.discount'),
								name: 'Discount',
								type: 'text'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
						customFieldTab: 'PACKAGE_AND_DELIVERY',
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [6, [4, 3], 4, [4, 4], 4]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.delivery.buyer.location.code'),
								name: 'BuyerLocationCode',
								maxLength: 6
							},
							{
								title: edi.i18n.getMessage('line.item.ordered.unit.packsize'),
								name: 'OrderedUnitPacksize',
								type: 'number',
								allowDecimals: true
							},
							{
								title: edi.i18n.getMessage('line.item.expiration.date'),
								name: 'ExpirationDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.max.expiration.date'),
								name: 'MaxExpirationDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.tab.package.ExpectedDespatchDate'),
								name: 'ExpectedDespatchDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.tab.package.RequestedDeliveryDate'),
								name: 'RequestedDeliveryDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.tab.package.ExpectedDeliveryDate'),
								name: 'ExpectedDeliveryDate',
								type: 'date'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.item.description.long'),
						customFieldTab: 'ADDITIONAL_DATA',
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [[6, 6]]
						},
						items: [
							{
								title: edi.i18n.getMessage('column.reference.element.reference.id'),
								name: 'Line-Reference.Reference-Elements.Reference-Element.Reference-Id',
								type: 'text'
							},
							{
								title: edi.i18n.getMessage('column.reference.element.reference.date'),
								name: 'Line-Reference.Reference-Elements.Reference-Element.Reference-Date',
								type: 'date'
							}
						]
					}
				]
			}
		});

		var lineTransport = edi.utils.getObjectProperty(document, 'DetailsOfTransport.Line-Transport', true);
		var deliveryCoordination = lineTransport.length
			? createDeliveryCoordination(lineTransport, {
					creator: edi.utils.getObjectProperty(document, 'Document-Parties.Creator') || undefined,
					readOnly: true
			  })
			: null;

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				headPanel,
				createMainDataBlock(),
				createPartiesBlock(),
				createTransportInfoBlock(),
				createAdditionalInformationBlock(),
				orderRemarks,
				createCustomFieldsContainer(),
				productsGrid
			]
		});
	};

	/**
	 * Creates action panel
	 */
	var createModuleActionsPanel = function (document) {
		var data = moduleData.initData.data;
		document.id = data.id;
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		var needSignatures = edi.document.actions.getSignCount(data);
		var needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.CONSUMER);
		var signaturesCount = needSignatures + needSignatures2 - data.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero
		var actionsPanel = createActionsPanel();

		const customButtons = [];
		let createDocConfig = [
			{
				permission: 'CREATE_LEGACY_DES_ADV',
				title: 'action.desadv',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
				config: {
					isFromTransformation: true,
					transformUri: edi.rest.services.DOCUMENTS.TRANSFORMATION_WITH_CUSTOM_FIELDS.POST,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			},
			{
				permission: 'CREATE_LEGACY_INVOICE',
				title: 'action.invoice',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
				config: {
					isFromTransformation: true,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			},
			{
				permission: 'CREATE_LEGACY_ORDER_RESP',
				title: 'action.ordrsp',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
				config: {
					isFromTransformation: true,
					transformUri: edi.rest.services.DOCUMENTS.TRANSFORMATION_WITH_CUSTOM_FIELDS.POST,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			}
		];

		if (userOrg?.id !== buyerOrg?.id) {
			createDocConfig.push({
				permission: 'CLIENT_EDI_FNS_UPD_5_01_N_CREATE',
				title: 'action.upd_5_01n',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				createCustomMethod: edi.document.actions.methodCreateUPDfromDocuments(
					data.id,
					direction,
					linesData,
					'OrderedUnitGrossPrice',
					moduleData
				),
				config: {
					isFromTransformation: false,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			});

			createDocConfig.push({
				permission: 'CLIENT_EDI_FNS_UPD_5_02_N_CREATE',
				title: 'action.upd_5_02n',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				createCustomMethod: edi.document.actions.methodCreateUPDfromDocuments(
					data.id,
					direction,
					linesData,
					'OrderedUnitGrossPrice',
					moduleData,
					{
						version: '5.02-N',
						getFunction: (documentContent) => documentContent?.dokument?.funktsiya
					}
				),
				config: {
					isFromTransformation: false,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			});

			createDocConfig.push({
				permission: 'CREATE_EDI_FNS_UPD',
				title: 'action.upd_5_03n',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				createCustomMethod: edi.document.actions.methodCreateUPDfromDocuments(
					data.id,
					direction,
					linesData,
					'OrderedUnitGrossPrice',
					moduleData,
					{
						version: '5.03-N',
						getFunction: (documentContent) => documentContent?.dokument?.funktsiya
					}
				),
				config: {
					isFromTransformation: false,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			});
		}

		const notAllowedDoctypes = edi.methods.quick_answer.notAllowedFlowTypes(data);
		const newCreateDocConfig = [];
		createDocConfig.forEach(function (item) {
			if (!notAllowedDoctypes.some((it) => it === item.docType)) {
				newCreateDocConfig.push(item);
			}
		});
		createDocConfig = newCreateDocConfig;

		const basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_FROM',
			data,
			moduleData,
			document,
			'order',
			createDocConfig
		);

		if (basedDocuments?.length) {
			customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.order'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					hideDefaultExport: true,
					addOriginalExport: true,
					addTransitExport: true
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var docHeader = moduleData.initData.data;
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (resp) {
				if (resp && resp.data) {
					var document = resp.data;
					const detailsPanel = createDetailsPanel(document);
					modulePanel.add(detailsPanel);
					moduleData.tab.add(createModuleActionsPanel(document));
					moduleData.tab.add(modulePanel);
					if (docHeader?.toOrg?.id && docHeader?.fromOrg?.id) {
						edi.methods.custom_fields.initCustomFields({
							docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
							toOrgId: docHeader.toOrg.id,
							fromOrgId: docHeader.fromOrg.id,
							docId: document.id,
							container: customFieldsContainer,
							grid: productsGrid,
							topPath: topPath,
							readOnly: true
						});
					}
					if ('function' == typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					failure(resp);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('incomingBlockRemoved', onBlockRemoved);
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
