import { createDocumentHeaderPanel } from '@App/js/edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
// @ts-ignore
import { createLabelBlockForDetails } from '@UIkit/components/fields';
import {
	createFieldBlockForDetails,
	createMaxWidthContainerForDetail,
	createContainer,
	createFieldSetForDetails
	// @ts-ignore
} from '@UIkit/components/panels';
import {
	getPurposeTypeValue,
	hasSchfInBpName,
	isDOP_BusinessProcess,
	isSCHFDOP_BussinessProcess,
	PURPOSES_TYPES
} from './methods';
import { createRowsBlock } from '@Components/createRows/create.rows';
import { createTransportAndCargoForDetail } from './5_02N/transportAndCargo/createTransportAndCargoFieldSetForDetail';
import {
	AdditionalInformation,
	AdditionalInformationProps
} from './5_02N/additionalInformation/createAdditionalInformationFieldSetForDetail';
import { UpdOrgSelectors } from './5_02N/selectors';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createNamedDocumentBlockForDetail } from './5_02N/documentBlock/createDocumentBlockForDetail';
import { DocLines } from './5_02N/createDocLinesGrid';
import { Dokument, OrgProductFields, TekstInf, UPD502N } from './5_02N/definitions/commonsDef';
import { createSignerAndPoaForDetail } from './5_02N/signerAndPoa/createSignerAndPoaForDetail';
import { createActionsButton } from '@Components/buttons';
import { checkCrptValid } from './checkCrptValid';
import { convertUPDFrom502Nto501N } from './5_02N/converter';
import { SvPRD } from './5_02N/definitions/svSchFakt';
import { SendRequestResponse } from '@App/js/definitions/request';
import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';

const TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER = 'цена_с_ндс';

export class DocumentDetailsFns_upd_5_02n {
	[key: string]: any;

	moduleData: ModuleData<DocumentHeader>;
	headerData: DocumentHeader;
	documentContent: UPD502N;
	part1: UPD502N;
	part2: AnyObject | null;
	part1Head: DocumentHeader;
	part2Head: DocumentHeader | null;
	bpName: string;
	utochReasonText: string | null = null;
	annulDoc: AnyObject | null;
	updRoamingStatus6: AnyObject;
	infoColumnsConfig: AnyObject;
	rejectReasonText: string;
	textNoticeUtoch: string;
	rejectReasonTextValue: string;
	productsValues: AnyObject;
	selectorConfigsBuilder: UpdOrgSelectors;
	isCorrect: boolean = false;
	orgProductFields: OrgProductFields[] = [];
	ProductGridConstructor: typeof DocLines;
	AdditionalInformationConstructor: typeof AdditionalInformation;
	version: string = '5.02-N';

	constructor() {
		const me = this;

		me.positionsStore = edi.stores.initPositionsStore();
		me.areaStore = edi.stores.initAuthorityAreasStore();
		me.signerStatusesStore = edi.stores.initSignerStatusesStore();
		me.signerBuyerStatusesStore = edi.stores.initSignerBuyerStatusesStore();

		//Биндим методы, т.к. они будут передаваться как колбэки и вызываться опосредовано без контекста
		me._changeHandler = me.changeHandler.bind(this);
		me._signHandler = me.signHandler.bind(this);

		me._detailsPanel = me.createDetailsPanel.bind(this);
		me._headerPanel = me.getHeadData.bind(this);

		me.ProductGridConstructor = DocLines;
		me.AdditionalInformationConstructor = AdditionalInformation;
	}

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data: ModuleData<DocumentHeader>, initCallBack: Function) {
		const me = this;
		me.moduleData = data;
		me.headerData = me.moduleData.initData.data;
		me.selectorConfigsBuilder = new UpdOrgSelectors();
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.documents.on('change', me._changeHandler);
		edi.events.documents.on('sign', me._signHandler);
	}

	addCustomButtons(options?: AnyObject) {
		return [];
	}

	addExcludeButtons(options?: AnyObject) {
		return {};
	}

	createEditAction(): ExtComponent {
		const me = this;

		return edi.document.actions.createEditActionButton(
			'document.create.fns_upd_5_02n',
			me.headerData,
			me.headerData.number
		);
	}

	createReadAction() {
		const me = this;
		return createActionsButton({
			text: edi.i18n.getMessage('document.mark.read'),
			glyph: edi.constants.ICONS.READ,
			handler: function () {
				edi.core.confirm(
					edi.i18n.getMessage('document.mark.read'),
					edi.i18n.getMessage('document.mark.read.question'),
					function () {
						me.moduleData.tab.setLoading();
						const success = function () {
							me.moduleData.tab.setLoading(false);
							edi.events.documents.fireEvent('change', {
								id: me.headerData.id
							});
						};
						const failure = edi.document.actions.defaultFailureHandler(
							me.moduleData.tab,
							'document.error.mark.read'
						);
						const markRead = function () {
							const postData = {
								[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID]: edi.constants.STATE.SENT
							};
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: me.headerData.id
									},
									true
								),
								'PUT',
								Ext.encode(postData),
								success,
								failure
							);
						};
						if (me.updRoamingStatus6) {
							edi.utils.sign(
								me.updRoamingStatus6,
								me.moduleData.tab,
								function (failed: boolean) {
									if (failed) {
										failure();
									} else {
										success();
									}
								},
								undefined,
								undefined,
								true,
								null,
								{
									signRefuse: () => me.moduleData.tab?.setLoading(false)
								}
							);
						} else {
							markRead();
						}
					}
				);
			}
		});
	}

	convertUPDToOldVersion(content: Dokument) {
		return convertUPDFrom502Nto501N({ content });
	}

	createModuleActionsPanel() {
		const me = this;

		const data = me.headerData;
		const businessState = edi.utils.getObjectProperty(data, 'businessState');

		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg),
			isOutGoingDocument = edi.constants.DIRECTIONS.OUTGOING === direction;
		const hasPart2 = !!edi.utils.getAttributeByName(data.attributes, 'hasPart2') || !!me.part2Head;
		const totalSignaturesCount = edi.document.actions.getSignCount(data);

		const neededSignaturesCount = isOutGoingDocument
			? totalSignaturesCount - me.part1Head.countSignatures
			: totalSignaturesCount - (hasPart2 && me.part2Head ? me.part2Head.countSignatures : 0);
		let actionsPanel = createActionsPanel();

		let excludeActions: { [actionName: string]: boolean } = {};

		let versionFormat = data.versionFormat || data.versionId;
		let allowCRPTChecking =
			data.state === edi.constants.STATE.DRAFT &&
			(isSCHFDOP_BussinessProcess(me.bpName) || isDOP_BusinessProcess(me.bpName)) &&
			versionFormat === '5.01-N' &&
			edi.utils.getAttributeByName(data.attributes, 'DOCUMENT_INCLUDE_MARK') === 'true';

		// Костыль, чтобы была возможность подписать УПД в статусе UTOCH
		// т.к. при UTOCH действие READ должно быть скрыто, но оно нужно для подписания по БП (если state = sent)
		// мы разрешаем его в actionRule, но скрываем в excludeActions, чтобы нельзя было прочитать на UI
		if (data.businessState === edi.constants.STATE.UTOCH) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.READ] = true;
		}

		let customButtons = [];
		let renouncement = edi.utils.getAttributeByName(data.attributes, 'RENOUNCEMENT', true);
		let utochReason = edi.utils.getAttributeByName(data.attributes, 'UTOCH_REASON', true);
		let isRenounced = utochReason && renouncement && renouncement.value === 'true';

		let hidePlusButton = false;
		if (isOutGoingDocument) {
			const rawBpName = edi.utils.getAttributeByName(data.attributes, 'bpName');
			if (isRenounced && hasSchfInBpName(rawBpName)) {
				hidePlusButton = true;
			}
		}

		const actionCreateUKDConfig = [
			{
				permission: 'CREATE_EDI_FNS_UKD',
				title: 'action.ukd_5_01n',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				config: { versionId: '5.01-N' }
			}
		];

		const actionCreateCUDConfig = [
			{
				permission: 'CREATE_EDI_FNS_UPD',
				title: 'action.cupd',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				config: {
					buyerOrg: me.moduleData.initData.data.toOrg,
					versionId: me.version
				}
			}
		];
		let modData = Ext.clone(me.moduleData);
		let initialFromOrg = modData.initData.data.fromOrg;
		modData.initData.data.fromOrg = modData.initData.data.toOrg;
		modData.initData.data.toOrg = initialFromOrg;

		let createTORG2Config = [
			{
				permission: 'CREATE_EDI_FNS_TORG2',
				title: 'documents.doctype.EDI_FNS_TORG2',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1,
				createCustomMethod: edi.document.actions.methodCreateTORG2fromDocuments(
					me.part1Head.id,
					direction,
					me.productsValues,
					'UnitGrossPrice',
					modData
				),
				config: {
					isFromTransformation: false
				}
			}
		];

		const clonedPart1 = Ext.clone(me.part1);
		const convertedDocumentData = me.convertUPDToOldVersion(clonedPart1.dokument);
		edi.utils.clearEmptyValues(convertedDocumentData);
		let basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_UKD',
			data,
			me.moduleData,
			{
				creationDate: clonedPart1.creationDate,
				header: clonedPart1.header,
				id: clonedPart1.id,
				idFayl: clonedPart1.idFayl,
				modifyDate: clonedPart1.modifyDate,
				versForm: clonedPart1.versForm,
				versProg: clonedPart1.versProg,
				document: convertedDocumentData
			},
			'fns_upd',
			actionCreateUKDConfig
		);
		basedDocuments = basedDocuments.concat(
			edi.document.actions.createListBasedDocuments(
				'CREATE_CUD',
				data,
				me.moduleData,
				me.part1,
				'cupd',
				actionCreateCUDConfig
			)
		);
		basedDocuments = basedDocuments.concat(
			edi.document.actions.createListBasedDocuments(
				'CREATE_TORG2',
				data,
				me.moduleData,
				me.part1,
				null,
				createTORG2Config
			)
		);
		if (!hidePlusButton && basedDocuments?.length) {
			customButtons.push(
				edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments, {
					text: edi.i18n.getMessage('action.correct.or.fix')
				})
			);
		}

		let customButtonsOptions = {
			// EW start
			direction,
			hasPart2,
			neededSignaturesCount,
			allowCRPTChecking
			// EW end
		};

		customButtons = customButtons.concat(me.addCustomButtons(customButtonsOptions));
		excludeActions = { ...excludeActions, ...me.addExcludeButtons() };
		const actionProps = {
			SIGN: {
				methodAddOptions: {
					contentId:
						edi.constants.DIRECTIONS.OUTGOING === direction
							? me.part1Head.id
							: me.part2Head
							? me.part2Head.id
							: null,
					useBeforeAction:
						data.state === edi.constants.STATE.DRAFT ||
						data.state === edi.constants.STATE.READ ||
						data.state === edi.constants.STATE.READ,
					signProperties: {
						sendPoaForSignContent: true
					},
					beforeInit: allowCRPTChecking
						? function (continueSign: Function) {
								checkCrptValid(data).then(({ success, skipped, selectedCertificate }) => {
									if (success || skipped) {
										continueSign(selectedCertificate);
									}
								});
						  }
						: null
				}
			},
			EDIT: {
				component: me.createEditAction()
			},
			READ: {
				component: me.createReadAction()
			},
			REFRESH: {
				handler: function () {
					me._changeHandler(data);
				}
			},
			ANNUL: {
				methodAddOptions: me.annulDoc
			},
			EXPORT: {
				label: edi.i18n.getMessage('action.export.document'),
				exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
				exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE_WITH_SIGN, {
					documentId: data.id
				}),
				extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
				extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON_ALL_DOCUMENT, {
					documentId: data.id
				}),
				addExtendedExport: true,
				xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
				addXmlExport: true
			},
			CUSTOM_BUTTONS: {
				buttons: customButtons
			}
		};

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: me.moduleData,
			infoTitle: edi.utils.formatString(edi.i18n.getMessage('document.module.title'), {
				docType: edi.i18n.getMessage('documents.doctype.EDI_FNS_UPD'),
				number: edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.nomerDok') || '',
				date: edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.dataDok') || ''
			}),
			infoSubTitle: me.documentContent?.dokument?.funktsiya,
			needSignatures: neededSignaturesCount,
			hasPart2: hasPart2,
			excludeActions: excludeActions,
			actionProps: actionProps
		});

		return actionsPanel;
	}

	addModuleActionsPanel() {
		const me = this;
		me.moduleData.tab.add(me.createModuleActionsPanel());
	}

	createDocumentHeaderConfig() {
		const me = this;
		const mainData = Ext.clone(me?.moduleData?.initData?.data) ?? {};
		const attrs = mainData.attributes ?? {};
		mainData.countSignatures =
			(me.part2Head ? me.part2Head.countSignatures : 0) + (me.part1Head ? me.part1Head.countSignatures : 0);
		mainData.ids = [me.part1Head?.id];
		if (me.part2Head) {
			mainData.ids.push(me.part2Head?.id);
		}
		return {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			utochReasonText: me.utochReasonText,
			rejectReasonText: me.textNoticeUtoch,
			showAnnulLog: true,
			isReceipts: !(me.moduleData.initData && me.moduleData.initData.isDocumentSharing),
			usingReport: true,
			isCrptStatus: true,
			showCRPTPrintBtn: edi.methods.checkAllowToShowCrptPrintBtn(attrs),
			defaultFormat: edi.constants.REPORT_FORMATS.XLS,
			costDecimal: edi.constants.UPD_PRICE_PRECISION
		};
	}

	createModuleHeadPanel() {
		const me = this;
		return createDocumentHeaderPanel(me.moduleData.initData, me.createDocumentHeaderConfig());
	}

	createDetailsPanel() {
		const me = this;

		const headPanel = me.createModuleHeadPanel();

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				headPanel,
				me.createMaxContainer(
					[me.getHeadData(), me.getParties(), me.createPaymentInformationBlock()].filter(
						Boolean
					) as ExtComponent[]
				),
				me.createShipDocConfirmationBlock(),
				createTransportAndCargoForDetail({
					documentData: me.documentContent,
					initialData: me.initialData
				}),
				me.createAdditionalInformationFieldSet(),
				me.createTextInfosBlock(),
				...me.createExtraBlocks(),
				me.createProductGrid(),
				me.createMaxContainer(
					[
						createSignerAndPoaForDetail({
							documentData: me.documentContent
						}),
						me.createReasonForBeingInvoiceFileCreatorBlock()
					].filter(Boolean) as ExtComponent[]
				)
			]
		});
	}

	changeHandler(data: DocumentHeader) {
		const me = this;

		if (
			data &&
			data.id &&
			(data.id == me.moduleData.initData.data.id ||
				(me.part1Head && data.id == me.part1Head.id) ||
				(me.part2Head && data.id == me.part2Head.id))
		) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(me.moduleData);
			} else {
				me.renderData();
			}
		}
	}

	signHandler(data: ModuleData) {
		const me = this;

		if (data && data.id && data.id == me.moduleData.initData.data.id) {
			let markNotification =
					edi.utils.getObjectProperty(edi.core.getUserData(), 'org.attributes.markNotification.value') ===
					'true',
				productInfo = me.part1?.dokument?.tablSchFakt?.svedTov,
				condition = false;
			if (markNotification) {
				if (productInfo?.length) {
					productInfo.forEach(function (item) {
						condition = condition || !Ext.isEmpty(item.dopSvedTov?.nomSredIdentTov);
					});
				}
				if (condition) {
					edi.methods.checkMarkingPartners();
				}
			}
		}
	}

	createMaxContainer(items: ExtComponent[]): ExtComponent {
		return createMaxWidthContainerForDetail({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: items as ExtComponent[]
		}) as ExtComponent;
	}

	setNameForCompanySelector(cmp: ExtComponent, title: string) {
		if (cmp?.modalConf) {
			cmp.modalConf.title = edi.i18n.getMessage(title);
		}
	}

	createOS(selectorConfig: AnyObject): ExtComponent {
		const me = this;

		Ext.applyIf(selectorConfig, {
			cls: '',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			is_valid: true,
			readOnly: true,
			fieldValues: me.documentContent,
			valuesByMap: true,
			disableAutoValidation: true,
			hideInTemplate: ['company_iln'],
			forceChangeFields: {
				bank_id: true,
				bank_corr_acc: true,
				bank_name: true,
				bank_acc: true
			}
		});
		return createOrgSelector(selectorConfig) as ExtComponent;
	}

	createSeller() {
		const me = this;

		me.seller = me.createOS({
			fieldsMap: me.selectorConfigsBuilder.getSellerFieldsMap('dokument.svSchFakt.svProd.0')
		});
	}

	createBuyer() {
		const me = this;

		me.buyer = me.createOS({
			fieldsMap: me.selectorConfigsBuilder.getBuyerFieldsMap('dokument.svSchFakt.svPokup.0')
		});
	}

	createShipFrom() {
		const me = this;

		me.shipFrom = me.createOS({
			fieldsMap: me.selectorConfigsBuilder.getShipFromFieldsMap('dokument.svSchFakt.gruzOt.0.gruzOtpr')
		});
	}

	createCargoReceiver() {
		const me = this;

		me.cargoReceiver = me.createOS({
			fieldsMap: me.selectorConfigsBuilder.getCargoReceiverFieldsMap('dokument.svSchFakt.gruzPoluch.0')
		});
	}

	getHeadData(): ExtComponent {
		const me = this;

		const getCurrency = function () {
			const currencyValue = edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.denIzm.kodOKV');
			if (currencyValue) {
				const currencyRecord = edi.stores.initOkvCurrencyStore().findRecord('codeStr', currencyValue);
				return currencyRecord?.get('displayName');
			}
		};

		return createFieldBlockForDetails({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			itemId: 'updHeadData',
			layout: {
				type: 'grid'
			},
			items: [
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('documents.fns_upd.invoice.corrections'),
					items: [
						createLabelBlockForDetails({
							contents: [
								{
									title: edi.i18n.getMessage('document.form.number'),
									text: edi.utils.getObjectProperty(
										me.documentContent,
										'dokument.svSchFakt.isprDok.nomIspr'
									)
								},
								{
									title: edi.i18n.getMessage('date'),
									date: edi.utils.getObjectProperty(
										me.documentContent,
										'dokument.svSchFakt.isprDok.dataIspr'
									)
								}
							]
						})
					]
				}),
				createLabelBlockForDetails({
					gap: [8, 16],
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						{
							title: edi.i18n.getMessage('invoice.currency'),
							itemId: 'updCurrency',
							text: getCurrency(),
							isBlock: true
						}
					]
				})
			]
		}) as ExtComponent;
	}

	getParties(): ExtComponent {
		const me = this;

		me.createSeller();
		me.createBuyer();

		me.createShipFrom();
		me.createCargoReceiver();

		return createContainer({
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [
					[6, 6],
					[6, 6]
				]
			},
			items: [
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.seller'),
					items: [me.seller]
				}),
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.buyer'),
					items: [me.buyer]
				}),
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.ship.from'),
					items: [me.shipFrom]
				}),
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.delivery'),
					items: [me.cargoReceiver]
				})
			]
		}) as ExtComponent;
	}

	createPaymentInformationBlock(): ExtComponent {
		const me = this;

		const createPaymentInformationField = function (isMainField: boolean, fieldData: SvPRD | {}) {
			return createLabelBlockForDetails({
				gap: [16, 16],
				contents: [
					{
						title: edi.i18n.getMessage('column.license.number'),
						text: edi.utils.getObjectProperty(fieldData, 'nomerPRD')
					},
					{
						title: edi.i18n.getMessage('date'),
						text: edi.utils.getObjectProperty(fieldData, 'dataPRD')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.paymentInformation.sum'),
						text: edi.utils.getObjectProperty(fieldData, 'summaPRD')
					}
				]
			}) as ExtComponent;
		};

		const svPRD = edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.svPRD');
		const paymentInformationColumns =
			svPRD && svPRD.length > 0
				? createRowsBlock({
						isReadOnly: true,
						createContentFieldsFn: createPaymentInformationField,
						initialData: svPRD || []
				  })
				: null;

		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.paymentInformation'),
			items: paymentInformationColumns
		});
	}

	createShipDocConfirmationBlock(): ExtComponent | null {
		const me = this;
		const name = 'dokument.svSchFakt.dokPodtvOtgrNom';
		return createNamedDocumentBlockForDetail(
			{
				data: edi.utils.getObjectProperty(me.documentContent, name, true),
				name,
				title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.shipDocConfirmation')
			},
			{ isReadOnly: true },
			{ readOnly: true }
		);
	}

	createAdditionalInformationFieldSet(props: Partial<AdditionalInformationProps> = {}): ExtComponent {
		const me = this;
		const additionalInformation = new me.AdditionalInformationConstructor(
			Object.assign(
				{
					documentData: me.documentContent,
					createOS: me.createOS,
					selectorConfigsBuilder: me.selectorConfigsBuilder,
					setNameForCompanySelector: me.setNameForCompanySelector.bind(me)
				},
				props
			)
		);

		return (me.additionalInformationFieldSet = additionalInformation.createView());
	}

	createTextInfosBlock(): ExtComponent {
		const me = this;
		const exludedTextInfos = [TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER];

		const infPolFHZH1 = edi.utils
			.getObjectProperty(me.documentContent, 'dokument.svSchFakt.infPolFHZH1.tekstInf', true)
			.filter((textInfo: TekstInf) => !exludedTextInfos.includes(textInfo.identif))
			.map((textInfo: TekstInf) => {
				if (Object.values(PURPOSES_TYPES).includes(textInfo.identif)) {
					return {
						...textInfo,
						identif: getPurposeTypeValue(textInfo.identif)
					};
				}
				return textInfo;
			});
		const infPolFHZH3 = edi.utils.getObjectProperty(
			me.documentContent,
			'dokument.svProdPer.infPolFHZH3.tekstInf',
			true
		);

		const textInfos = [...infPolFHZH1, ...infPolFHZH3];

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.special_identifiers'),
			collapsed: true,
			collapsible: true,
			layout: {
				type: 'grid',
				gap: 8
			},
			items: textInfos.map((textInfo) =>
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('column.id'),
							text: textInfo.identif
						},
						{
							title: edi.i18n.getMessage('column.value'),
							text: textInfo.znachen,
							isNewLine: true
						}
					]
				})
			)
		}) as ExtComponent;
	}

	createExtraBlocks(): ExtComponent[] {
		return [];
	}

	createProductGrid(): ExtComponent {
		const me = this;
		const lines = Ext.clone(edi.utils.getObjectProperty(me.documentContent, 'dokument.tablSchFakt.svedTov', true));

		const docLines = new me.ProductGridConstructor({
			documentData: me.documentContent,
			data: lines,
			readOnly: true,
			getCurrentUPDState: () => ({
				taxCalculationMethod: '', //не важно для деталей
				updFunction: me.bpName,
				spObstFSCHFValue: null, //не важно для деталей
				spObstFDOP: null, //не важно для деталей
				isCreatedFromDoc: false,
				orgProductFields: me.orgProductFields,
				isCreate: false,
				isCorrect: me.isCorrect
			})
		});

		return (me.productsGrid = docLines.createProductGrid());
	}

	createReasonForBeingInvoiceFileCreatorBlock(): ExtComponent | null {
		const me = this;
		const name = 'dokument.osnDoverOrgSost';
		return createNamedDocumentBlockForDetail(
			{
				data: edi.utils.getObjectProperty(me.documentContent, name),
				name,
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.file.creator.reason')
			},
			{
				isSingleDocument: true,
				isReadOnly: true
			},
			{ readOnly: true }
		);
	}

	failure(responseData: SendRequestResponse) {
		const errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
		edi.core.showWarn(errorMsg);
	}

	defaultFailureHandler(data?: SendRequestResponse) {
		const me = this;
		const defaultFailure = edi.document.actions.defaultFailureHandler(
			me.moduleData.tab,
			'error.getting.data',
			function () {
				edi.modulesHandler.removeModule(me.moduleData);
			}
		);
		defaultFailure(data);
	}

	async finishLoad() {
		const me = this;

		let modulePanel = createDetailsModulePanel() as ExtComponent;

		if (me.moduleData.tab.isDestroyed) {
			return;
		}

		modulePanel.add(me.createDetailsPanel());
		me.moduleData.tab.removeAll();

		me.addModuleActionsPanel();
		me.moduleData.tab.add(modulePanel);

		const isBlocked = edi.utils.getAttributeByName(me.headerData.attributes, 'isBlocked', undefined, true);
		const docCheckOptions = edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', me.headerData));
		if (
			!isBlocked &&
			!documentPackageMethods.isDocActionBlocked(docCheckOptions) &&
			me.annulDoc &&
			me.annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
			me.annulDoc.toOrg.id == edi.core.getUserOrgID()
		) {
			edi.methods.documents.showActiveAnnulModal(me.annulDoc, me.moduleData);
		}
	}

	async getUtochContent({ utochId }: { utochId: number }) {
		const me = this;
		const { success, data } = edi.rest.asyncSendRequest({
			url: edi.document.actions.formatDetailsUri(me.moduleData.initData, utochId, true)
		});

		if (success) {
			if (data.success && data.data) {
				me.utochReasonText = edi.utils.getObjectProperty(data.data, 'Document.DataOfUvUtoch.TextNoticeUtoch');
			}
			await me.finishLoad();
		} else {
			me.failure(data);
		}
	}

	async getLinkDocsTree({ isAnnulUtoch, isIncomingRoaming }: { isAnnulUtoch: boolean; isIncomingRoaming: boolean }) {
		const me = this;

		const { success, error, linkedDocs, annulDoc } = await edi.document.actions.getLinkedAnnulDocForUpd(
			me.headerData
		);

		if (success) {
			let utochId;
			me.annulDoc = annulDoc;
			if (Array.isArray(linkedDocs) && linkedDocs.length) {
				linkedDocs.forEach((doc) => {
					if (
						isAnnulUtoch &&
						doc.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH &&
						doc.state !== edi.constants.STATE.DRAFT &&
						doc.state !== edi.constants.STATE.DELETED
					) {
						utochId = doc.id;
					} else if (
						isIncomingRoaming &&
						doc.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL &&
						doc.state === edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK
					) {
						me.updRoamingStatus6 = doc;
					}
				});
			}

			if (!utochId) {
				await me.finishLoad();
			} else {
				await me.getUtochContent({ utochId });
			}
		} else {
			me.failure(error);
		}
	}

	async continueWithRendering() {
		const me = this;

		const direction = edi.utils.getDocumentDirection(me.headerData.toOrg, me.headerData.fromOrg);

		me.bpName = edi.utils.getAttributeByName(me.headerData.attributes, 'bpName');
		const businessState = edi.utils.getObjectProperty(me.headerData, 'businessState');

		me.annulDoc = null;
		me.utochReasonText = edi.utils.getAttributeByName(me.headerData.attributes, 'UTOCH_REASON');
		const isAnnulUtoch =
			!!me.utochReasonText ||
			businessState === edi.constants.STATE.ON_ANNUL ||
			businessState === edi.constants.STATE.ANNUL_SIGN;
		me.rejectReasonTextValue = edi.utils.getAttributeByName(
			me.headerData.attributes,
			'utochCreated',
			undefined,
			true
		);
		const isIncomingRoaming =
			edi.constants.DIRECTIONS.INCOMING === direction &&
			me.headerData.state === edi.constants.STATE.SENT &&
			me.bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_DECREE_14;

		if (isAnnulUtoch || isIncomingRoaming) {
			await me.getLinkDocsTree({ isAnnulUtoch, isIncomingRoaming });
		} else {
			await me.finishLoad();
		}
	}

	async getRejectReason() {
		const me = this;

		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.document.actions.formatRejectReasonUri(me.moduleData.initData)
		});

		if (success) {
			me.textNoticeUtoch = data?.data?.TextNoticeUtoch;
		}
	}

	async getOrganizationAdditionalFields(): Promise<{ success: boolean }> {
		const me = this;
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.document.actions.formatDocDataUri(me.moduleData.initData, {
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				orgId: me.headerData.toOrg.id
			})
		});
		if (success && data?.items) {
			me.infoColumnsConfig = data.items.length ? data.items : null;
			return { success: true };
		} else {
			me.failure(data);
			return { success: false };
		}
	}

	async getPartsData({ ids }: { ids: number[] }): Promise<{ success: boolean }> {
		const me = this;
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.document.actions.formatBatchContentUri(me.moduleData.initData),
			method: 'POST',
			params: Ext.encode(ids)
		});

		if (success) {
			me.part2 = null;
			if (data && data.items) {
				for (let i = 0; i < data.items.length; i++) {
					if (me.part1Head.id == data.items[i].header) {
						me.part1 = data.items[i];
						me.documentContent = me.part1;
					} else if (me.part2Head && me.part2Head.id == data.items[i].header) {
						me.part2 = data.items[i];
					}
				}
				return { success: true };
			} else {
				me.failure(data);
				return { success: false };
			}
		} else {
			me.failure(data);
			return { success: false };
		}
	}

	async getDocPartsHead({ ids }: { ids: number[] }): Promise<{ success: boolean }> {
		const me = this;
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.document.actions.formatBatchHeaderUri(me.moduleData.initData),
			method: 'POST',
			params: Ext.encode(ids)
		});

		if (success) {
			me.part2Head = null;
			for (let i = 0; i < data.items.length; i++) {
				if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
					me.part1Head = data.items[i];
					edi.document.actions.changeTabTitle(me.moduleData.tab, me.part1Head.number);
				} else if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2) {
					me.part2Head = data.items[i];
				}
			}
			return { success: true };
		} else {
			me.defaultFailureHandler(data);
			return { success: false };
		}
	}

	async getLinkedDocs(): Promise<{ success: boolean; response?: { ids: number[] } }> {
		const me = this;
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.document.actions.formatLinkedUri(me.moduleData.initData)
		});

		if (success) {
			if (data && data.data && data.data.children && data.data.children.length) {
				let i,
					ids = [];
				for (i = 0; i < data.data.children.length; i++) {
					if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL) {
						me.rejectReasonText = edi.utils.getObjectProperty(
							data.data.children[i],
							'attributes.REJECT_REASON.value'
						);
					}
				}

				(function (children) {
					let latestAnnulRejectReason = null;

					for (i = 0; i < children.length; i++) {
						let child = children[i];
						if (child.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL) {
							if (!latestAnnulRejectReason || latestAnnulRejectReason.modifyDate < child.modifyDate) {
								latestAnnulRejectReason = child;
							}
						}
					}
				})(data.data.children);

				for (i = 0; i < data.data.children.length; i++) {
					if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
						ids.push(data.data.children[i].id);
					} else if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2) {
						ids.push(data.data.children[i].id);
					}
					if (ids.length === 2) {
						break;
					}
				}
				if (ids.length) {
					return { success: true, response: { ids } };
				} else {
					me.defaultFailureHandler();
					return { success: false };
				}
			} else {
				me.defaultFailureHandler(data);
				return { success: false };
			}
		} else {
			me.defaultFailureHandler(data);
			return { success: false };
		}
	}

	updateDocumentHeaderData() {
		const me = this;
		return new Promise<void>((resolve) => {
			edi.document.actions.updateDocumentHeaderData(me.moduleData, function () {
				me.headerData = me.moduleData.initData.data;
				resolve();
			});
		});
	}

	async loadData() {
		const me = this;

		const { success: isLinkedDocsLoaded, response } = await me.getLinkedDocs();
		if (!isLinkedDocsLoaded || !response) return;
		const ids = response.ids;

		const { success: isDocPartsHeadLoaded } = await me.getDocPartsHead({ ids });
		if (!isDocPartsHeadLoaded) return;

		const { success: isPartsDataLoaded } = await me.getPartsData({ ids });
		if (!isPartsDataLoaded) return;

		const { success: isOrgAdditionalFieldsLoaded } = await me.getOrganizationAdditionalFields();
		if (!isOrgAdditionalFieldsLoaded) return;

		if (me.rejectReasonTextValue) {
			await me.getRejectReason();
		}

		await me.continueWithRendering();
	}

	async renderData(initCallBack?: Function) {
		const me = this;

		me.moduleData.tab.setLoading();

		await me.updateDocumentHeaderData();
		await me.loadData();

		if ('function' == typeof initCallBack) {
			initCallBack();
		} else {
			me.moduleData.tab.setLoading(false);
		}
	}
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy() {
		const me = this;

		edi.events.documents.un('change', me._changeHandler);
		edi.events.documents.un('sign', me._signHandler);

		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

Ext.namespace('edi.modules');
edi.modules['document.details.fns_upd_5_02n'] = DocumentDetailsFns_upd_5_02n;
