import { createAddModulePanel, createForm } from '@Components/panels';
import { moduleMethods } from '@Edi/modules/power_of_attorney/POA_002/methods';
import { createCreateSaveButton } from '@Components/buttons';
import { createEditableBlock } from '@UIkit/components/blocks/EditableBlock';
import { createBlocksHolder } from '@UIkit/components/blocks/BlocksHolder';
import { moduleMethods_003 } from '@Edi/modules/power_of_attorney/POA_003/methods_003';
import {
	createContainer,
	createFieldBlock,
	createFormContainer,
	createPanel,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import {
	createCombo,
	createDateField,
	createDateRangeField,
	createHiddenField,
	createLabel,
	createLabelBlockForDetails,
	createTextField
} from '@UIkit/components/fields';
import { createSimpleSelector } from '@UIkit/components/selectors';
import { createMenuItem } from '@Components/miscComponents';
import { MODAL_CLS, MODAL_SIZE } from '@UIkit/components/modal';
import { createEmailField } from '@Components/fields';
import { createHelpElementPlugin, createInfoElementPlugin } from '@Core/methods';
import { PoACompanyPart } from './methods/poaCompanyPart';
//По требованию бизнеса временно убираем блок ограничений, что бы люди привыкли работать хотя бы с полномочиями
//import { createRowsBlock } from '@Components/createRows/create.rows.v2';

/**
 * Класс для создания и редактирования машиночитаемых доверенностей (МЧД)
 */
Ext.namespace('edi.modules');
edi.modules['power.of.attorney.create_003'] = function () {
	let moduleData,
		docId,
		docHeader,
		docContent,
		isEdit = false,
		isCopy = false,
		form,
		userData = edi.core.getUserData(),
		saveBtn,
		validLabel,
		confidantBlocksHolder,
		principalBlocksHolder;
	let powerTypeCombo, humanReadablePowerTextArea, machineReadablePowerBlock;

	/**
	 * Main module initialization methodДо
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		//когда открываем из грида, то там своя кастомная вьюха и айди лежит в docId
		//а когда открываем из формы просмотра, то там уже нормальный хэдер
		docId = moduleData.initData.data?.docId || moduleData.initData.data?.id;
		isCopy = !!moduleData.initData.isCopy;
		if (isCopy) {
			docHeader = Ext.clone(moduleData.initData.data);
			docContent = docHeader.data;
			if (!!docContent?.document?.dover?.svDov?.doverRegNumber) {
				delete docContent.document.dover.svDov.doverRegNumber;
			}
			if (!!docContent?.document?.dover?.svDov?.vnNomDover) {
				docContent.document.dover.svDov.vnNomDover += '-' + edi.i18n.getMessage('document.number.copied');
			}
			let principalBlock = docContent?.document?.dover?.svDoverit;
			if (principalBlock) {
				if (!Array.isArray(principalBlock)) {
					principalBlock = [principalBlock];
				}
				principalBlock.forEach(function (item) {
					delete item.podpisant;
				});
			}
		} else {
			isEdit = !!docId;
		}

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/*****
	 * Получение адреса организации из userData в виде строки
	 */
	const getUserOrgAddress = function () {
		if (!userData.org.addresses || !Array.isArray(userData.org.addresses)) {
			return '';
		}

		let legalAddress = userData.org.addresses.find(function (element) {
			return element.type === 'LEGAL' || element.type === 'RU_LEGAL';
		});
		if (!legalAddress) {
			legalAddress = userData.org[0];
		}
		var address = [];
		if (legalAddress?.zip) {
			address.push(legalAddress.zip);
		}
		if (legalAddress?.area) {
			address.push(legalAddress.area);
		}
		if (legalAddress?.city) {
			address.push(edi.i18n.getMessage('address.city') + ' ' + legalAddress.city);
		}
		if (legalAddress?.street) {
			address.push(edi.i18n.getMessage('address.street') + ' ' + legalAddress.street);
		}
		if (legalAddress?.building) {
			address.push(edi.i18n.getMessage('address.building') + ' ' + legalAddress.building);
		}
		if (legalAddress?.block) {
			address.push(edi.i18n.getMessage('housing.short') + ' ' + legalAddress.block);
		}
		if (legalAddress?.flat) {
			address.push(edi.i18n.getMessage('company.flat') + ' ' + legalAddress.flat);
		}
		return address.join(', ');
	};
	const getUserOrgRegion = function () {
		if (!userData.org.addresses || !Array.isArray(userData.org.addresses)) {
			return '';
		}

		let legalAddress = userData.org.addresses.find(function (element) {
			return element.type === 'LEGAL' || element.type === 'RU_LEGAL';
		});
		if (!legalAddress) {
			legalAddress = userData.org[0];
		}
		return legalAddress?.regionCode;
	};

	/*****
	 * Поля ФЛ
	 */
	const getFLFieldsContainer = function (partData, docPath, defaults, hiddenItemIdArray = [], area, config = {}) {
		let kodVidDokCombo, kodVydDokField, vidDokField, expDateField, serNomDokField, dataDokField;
		let lastName = createFieldBlock({
			title: edi.i18n.getMessage('last.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value:
						moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.fio.familiya') || defaults?.lastName,
					name: docPath + 'svedFL.fio.familiya',
					allowBlank: false,
					maxLength: 200
				})
			]
		});

		let firstName = createFieldBlock({
			title: edi.i18n.getMessage('first.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.fio.imya') || defaults?.firstName,
					name: docPath + 'svedFL.fio.imya',
					allowBlank: false,
					maxLength: 200
				})
			]
		});

		let middleName = createFieldBlock({
			title: edi.i18n.getMessage('patronymic.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value:
						moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.fio.otchestvo') ||
						defaults?.middleName,
					name: docPath + 'svedFL.fio.otchestvo',
					maxLength: 200
				})
			]
		});
		let inn = createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			itemId: 'inn',
			items: [
				createTextField({
					value: moduleMethods_003.getFieldValue(partData, docPath, 'innfl') || defaults?.innfl,
					name: docPath + 'innfl',
					allowBlank: !(config.isIP || config.isFL || config.isULIP || config.isULFL),
					regex: edi.constants.VALIDATORS.INDI_INN
				})
			]
		});

		let snils = createFieldBlock({
			title: edi.i18n.getMessage('person.snils'),
			cls: FIELD_BLOCK_CLS.small,
			itemId: 'snils',
			items: [
				createTextField({
					value: moduleMethods_003.getFieldValue(partData, docPath, 'snils') || defaults?.snils,
					name: docPath + 'snils',
					allowBlank: false,
					regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
					regexText: edi.i18n.getMessage('invalid.snils.format'),
					inputMask: edi.constants.FIELD_MASK.SNILS
				})
			]
		});

		let birthday = createFieldBlock({
			title: edi.i18n.getMessage('person.birthday'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createDateField({
					value:
						moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.dataRozhd') || defaults?.dateOfBirth,
					name: docPath + 'svedFL.dataRozhd',
					allowBlank: !(config.IP || config.isFL),
					validator: (value) => {
						const birthdate = Ext.Date.parse(value, edi.constants.DATE_FORMAT.FNS);

						if (birthdate) {
							const currentDate = new Date();
							const age = Ext.Date.diff(birthdate, currentDate, 'y');
							if (age < 18 || age > 120) {
								return edi.i18n.getMessage(
									'document.cant.create.power.of.attorney.have.not.correct.date.of.birthday.value'
								);
							}
						}
						return true;
					},
					maxValue: new Date(),
					srcFormat: edi.constants.DATE_FORMAT.CLIENT
				})
			]
		});

		let gender = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('power.of.attorney.gender'),
			items: [
				createCombo({
					name: docPath + 'svedFL.pol',
					store: edi.stores.createSimpleInlineStore(['1', '2'], function (id) {
						return edi.i18n.getMessage('power.of.attorney.gender.' + id);
					}),
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.pol') || defaults?.gender
				})
			]
		});

		let nomERN = createFieldBlock({
			title: edi.i18n.getMessage('person.population.register.number'),
			cls: FIELD_BLOCK_CLS.small,
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				createTextField({
					name: docPath + 'svedFL.nomERN',
					regex: /^[0-9]{12}$/,
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.nomERN')
				})
			]
		});
		let mestoRozhd = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.birth.place'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					name: docPath + 'svedFL.mestoRozhd',
					maxLength: 250,
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.mestoRozhd')
				})
			]
		});

		let region = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.rus.region'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createCombo({
					name: docPath + 'svedFL.adrMZH.region',
					allowBlank: false,
					store: edi.stores.initRegionsStore(),
					valueField: 'id',
					displayField: 'display_name',
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.adrMZH.region')
				})
			]
		});
		let adrMZH = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.residence.address'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					name: docPath + 'svedFL.adrMZH.adrRF',
					maxLength: 1000,
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.adrMZH.adrRF')
				})
			]
		});
		let phone = createFieldBlock({
			title: edi.i18n.getMessage('company.contact.phone'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					name: docPath + 'svedFL.kontaktTlf',
					maxLength: 50,
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.kontaktTlf')
				})
			]
		});
		let email = createFieldBlock({
			title: edi.i18n.getMessage('user.profile.email'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createEmailField({
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.adrElPocht'),
					name: docPath + 'svedFL.adrElPocht',
					minLength: 3,
					maxLength: 129
				})
			]
		});

		let dolzhnost = createFieldBlock({
			title: edi.i18n.getMessage('job.title'),
			itemId: 'dolzhnost',
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value: moduleMethods_003.getFieldValue(partData, docPath, 'dolzhnost'),
					name: docPath + 'dolzhnost',
					maxLength: 250
					// allowBlank: false
				})
			]
		});

		let identityDocumentTitle = createLabel({
			typography: 'heading_01',
			padding: '16 0 0 0',
			text: edi.i18n.getMessage('person.identity.document')
		});

		let switchDocBlock = function () {
			const value = kodVidDokCombo.getValue();
			// Блок "Документ, удостоверяющий личность" НЕ обязателен для любого типа доверителя (см EW-4686)
			// но для уполномоченного представителя по прежнему обязателен
			// Если kodVidDok заполнен - логика старая
			const identityDocumentAllowBlank = !config.isConfidant && !value;
			const identityDocumentFields = [
				kodVidDokCombo,
				serNomDokField,
				vidDokField,
				kodVydDokField,
				dataDokField,
				expDateField
			];
			identityDocumentFields.forEach((field) => {
				field.allowBlank = identityDocumentAllowBlank;
				if (field !== kodVidDokCombo) {
					field.setDisabled(identityDocumentAllowBlank);
				}
			});

			const identityDocumentFieldBlocks = [serNomDok, vidDok, kodVydDok, dataDok, expDate];
			identityDocumentFieldBlocks.forEach((fieldBlock) => {
				fieldBlock.setVisible(!identityDocumentAllowBlank);
			});

			expDateField.setDisabled(value !== '22');
			expDate.setVisible(value === '22');

			kodVydDokField.allowBlank = identityDocumentAllowBlank || value === '10';
			vidDokField.allowBlank = identityDocumentAllowBlank || value === '10';

			expDateField.up('form').isValid();
		};

		let kodVidDok = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.document.view'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(kodVidDokCombo = createCombo({
					name: docPath + 'svedFL.udLichnFL.kodVidDok',
					store: moduleMethods_003.createFLPersonIdentityCardTypeStore(),
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.udLichnFL.kodVidDok'),
					listeners: {
						change: switchDocBlock
					}
				}))
			]
		});

		let serNomDok = createFieldBlock({
			title: edi.i18n.getMessage('person.identity.document.series.number'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(serNomDokField = createTextField({
					name: docPath + 'svedFL.udLichnFL.serNomDok',
					maxLength: 25,
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.udLichnFL.serNomDok')
				}))
			]
		});

		let vidDok = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.issuing.authority'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(vidDokField = createTextField({
					name: docPath + 'svedFL.udLichnFL.vidDok',
					maxLength: 4000,
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.udLichnFL.vidDok')
				}))
			]
		});

		let kodVydDok = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.department.code'),
			layout: {
				type: 'grid',
				area: [3]
			},
			cls: FIELD_BLOCK_CLS.small,
			items: [
				(kodVydDokField = createTextField({
					name: docPath + 'svedFL.udLichnFL.kodVydDok',
					minLength: 7,
					maxLength: 7,
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.udLichnFL.kodVydDok')
				}))
			]
		});

		var dataDok = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('person.identity.document.date'),
			items: [
				(dataDokField = createDateField({
					name: docPath + 'svedFL.udLichnFL.dataDok',
					maxValue: new Date(),
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.udLichnFL.dataDok')
				}))
			]
		});
		var expDate = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('person.identity.expDate'),
			hidden: true,
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				(expDateField = createDateField({
					name: docPath + 'svedFL.udLichnFL.expDate',
					value: moduleMethods_003.getFieldValue(partData, docPath, 'svedFL.udLichnFL.expDate')
				}))
			]
		});

		let items = [
			lastName,
			firstName,
			middleName,
			inn,
			snils,
			birthday,
			gender,
			nomERN,
			mestoRozhd,
			region,
			adrMZH,
			phone,
			email,
			dolzhnost,
			identityDocumentTitle,
			kodVidDok,
			serNomDok,
			vidDok,
			dataDok,
			expDate,
			kodVydDok
		];

		items.forEach((item) => {
			if (hiddenItemIdArray.includes(item?.itemId)) {
				item.setVisible(false);
				item.setDisabled(true);
			}
		});
		return createPanel({
			layout: {
				type: 'grid',
				gap: [16, 16],
				area: area ? area : [6, [6, 6], [4, 4], 4, 3, 12, 12, [4, 8], 4, 12, 12, 12, 6, 6, 12, [4, 8], 12]
			},
			items: items,
			listeners: {
				afterrender: switchDocBlock
			}
		});
	};

	const getIPFieldsContainer = function (partData, docPath, defaults, hiddenItemIdArray = [], area) {
		let naimIP = createFieldBlock({
			title: edi.i18n.getMessage('company.name'),
			cls: FIELD_BLOCK_CLS.small,
			itemId: 'naimIP',
			items: [
				createTextField({
					allowBlank: false,
					value: moduleMethods_003.getFieldValue(partData, docPath, 'naimIP') || defaults?.naimIP,
					name: docPath + 'naimIP',
					maxLength: 1000
				})
			]
		});
		let ipOgrn = createFieldBlock({
			title: edi.i18n.getMessage('company.ogrnip.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value: moduleMethods_003.getFieldValue(partData, docPath, 'ogrnip') || defaults?.ogrnip,
					name: docPath + 'ogrnip',
					allowBlank: false,
					regex: edi.constants.VALIDATORS.INDI_OGRN
				})
			]
		});
		let ipInn = createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value: moduleMethods_003.getFieldValue(partData, docPath, 'innfl') || defaults?.innfl,
					name: docPath + 'innfl',
					allowBlank: false,
					regex: edi.constants.VALIDATORS.INDI_INN
				})
			]
		});

		let ipSnils = createFieldBlock({
			title: edi.i18n.getMessage('person.snils'),
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					value: moduleMethods_003.getFieldValue(partData, docPath, 'snils') || defaults?.snils,
					name: docPath + 'snils',
					allowBlank: false,
					regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
					regexText: edi.i18n.getMessage('invalid.snils.format'),
					inputMask: edi.constants.FIELD_MASK.SNILS
				})
			]
		});
		let items = [naimIP, ipOgrn, ipInn, ipSnils];
		items.forEach((item) => {
			if (hiddenItemIdArray.includes(item?.itemId)) {
				item.setVisible(false);
				item.setDisabled(true);
			}
		});
		return createPanel({
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: area ? area : [12, [4, 4, 4]]
			},
			items: items
		});
	};

	//	Верхний блок с полями
	const createHeaderFieldsBlock = function () {
		const title = createLabel({
			typography: 'heading_02',
			text: edi.i18n.getMessage(isEdit ? 'power.of.attorney.title.edit' : 'power.of.attorney.title.create'),
			plugins: [
				createHelpElementPlugin({
					url: 'https://ediweb.com/ru-ru/support/kb/1832',
					tooltip: edi.i18n.getMessage('control.center.title.tooltip')
				})
			]
		});
		const format = createFieldBlock({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.small}`,
			title: edi.i18n.getMessage('power.of.attorney.format'),
			items: [
				createCombo({
					name: 'format',
					value: 'EMCHD_1',
					plugins: [
						createInfoElementPlugin({
							hintConfig: {
								html: edi.i18n.getMessage('power.of.attorney.format.tooltip')
							},
							isField: true
						})
					],
					readOnly: true,
					store: edi.stores.createSimpleInlineStore(['EMCHD_1'], function (id) {
						return edi.i18n.getMessage('power.of.attorney.format.' + id);
					})
				})
			]
		});
		//используется только для отображения
		const area = createFieldBlock({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.small}`,
			title: edi.i18n.getMessage('power.of.attorney.application.area'),
			items: [
				createTextField({
					submitValue: false,
					plugins: [
						createInfoElementPlugin({
							hintConfig: {
								html: edi.i18n.getMessage('power.of.attorney.application.area.tooltip')
							},
							isField: true
						})
					],
					value: docContent?.prElForm
						? moduleMethods_003.calculateApplicationArea(docContent?.prElForm)
						: edi.i18n.getMessage('power.of.attorney.applicationArea.b2b'),
					readOnly: true
				})
			]
		});
		//уходит на бек
		const areaHidden = createHiddenField({
			name: 'prElForm',
			value: docContent?.prElForm || '00000000'
		});
		const nomDover = createHiddenField({
			name: 'document.dover.svDov.nomDover',
			value: isEdit ? docContent?.document?.dover?.svDov?.nomDover : null
		});
		const svedSist = createHiddenField({
			name: 'document.dover.svDov.svedSist',
			value: docContent?.document?.dover?.svDov?.svedSist || 'https://m4d.nalog.gov.ru/'
		});
		const vnNomDover = createFieldBlock({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.small}`,
			title: edi.i18n.getMessage('power.of.attorney.internal.number'),
			items: [
				createTextField({
					name: 'document.dover.svDov.vnNomDover',
					valueSrc: docContent,
					allowBlank: false,
					maxLength: 50,
					plugins: [
						createInfoElementPlugin({
							hintConfig: {
								html: edi.i18n.getMessage('power.of.attorney.internal.number.tooltip')
							},
							isField: true
						})
					]
				})
			]
		});
		const dateValid = createFieldBlock({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.small}`,
			title: edi.i18n.getMessage('power.of.attorney.date.validity'),
			layout: {
				gap: [16, 24],
				type: 'grid',
				area: [[4, 8]]
			},
			items: [
				createDateRangeField({
					fieldsConfig: {
						from: {
							fieldLabel: '',
							name: 'document.dover.svDov.dataVidDover',
							value: docContent ? new Date(docContent?.document?.dover?.svDov?.dataVidDover) : '',
							//format: edi.constants.DATE_FORMAT.CLIENT,
							//submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							allowBlank: false,
							validator: function (values) {
								const dateFromValue = values?.from,
									dateToValue = values?.to,
									clearToday = Ext.Date.clearTime(new Date(), true);
								let res = true;

								if (Ext.isDate(dateFromValue)) {
									if (clearToday.getTime() > dateFromValue.getTime()) {
										res = edi.i18n.getMessage('power.of.attorney.issue.date.cannot.be.earlier');
									}
								}
								if (Ext.isDate(dateFromValue) && Ext.isDate(dateToValue)) {
									let validLabelText = edi.renderers.getTextDiffBetweenTwoDate(
										dateFromValue,
										dateToValue
									);
									validLabel.setVisible(validLabelText);
									if (validLabelText) {
										validLabelText = `${edi.i18n.getMessage(
											'power.of.attorney.valid.text'
										)} ${validLabelText}`;
										validLabel.setText(validLabelText);
									} else {
										validLabel.setText('');
									}
								}
								return res;
							}
						},
						to: {
							fieldLabel: '',
							name: 'document.dover.svDov.srokDeyst',
							value: docContent ? new Date(docContent.document?.dover?.svDov?.srokDeyst) : '',
							allowBlank: false,
							//format: edi.constants.DATE_FORMAT.CLIENT,
							//submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							validator: function (values) {
								const dateFromValue = values?.from,
									dateToValue = values?.to;
								let res = true;

								if (Ext.isDate(dateFromValue) && Ext.isDate(dateToValue)) {
									if (dateFromValue.getTime() > dateToValue.getTime()) {
										res = edi.i18n.getMessage('power.of.attorney.invalid.date.span.error');
									}
									let validLabelText = edi.renderers.getTextDiffBetweenTwoDate(
										dateFromValue,
										dateToValue
									);
									validLabel.setVisible(validLabelText);
									if (validLabelText) {
										validLabelText = `${edi.i18n.getMessage(
											'power.of.attorney.valid.text'
										)} ${validLabelText}`;
										validLabel.setText(validLabelText);
									} else {
										validLabel.setText('');
									}
								}
								return res;
							}
						}
					}
				}),
				(validLabel = createLabel({
					typography: 'body-short_01',
					padding: '14 0 0 0',
					hidden: true,
					text: ''
				}))
			]
		});
		let prUtrPolnCombo, prUtrPolnFieldBlock;
		const switchPrPeredov = function (prPeredovCombo) {
			const showPrUtrPoln = ['2', '3'].includes(prPeredovCombo.getValue());
			prUtrPolnFieldBlock.setVisible(showPrUtrPoln);
			prUtrPolnCombo.setDisabled(!showPrUtrPoln);
		};

		const additionConditions = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.addition.conditions'),
			cls: [`${FIELD_BLOCK_CLS.default}`, `${FIELD_BLOCK_CLS.titleMarginMedium}`],
			layout: {
				type: 'grid',
				gap: [16, 24],
				area: [[3, 2, 2, 2]],
				ignoreHiddenElements: true
			},
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('power.of.attorney.reassignment'),
					cls: FIELD_BLOCK_CLS.small,
					items: [
						createCombo({
							name: 'document.dover.svDov.prPeredov',
							store: edi.stores.createSimpleInlineStore(['1', '2', '3'], function (id) {
								return edi.i18n.getMessage('power.of.attorney.reassignment.003.' + id);
							}),
							value: docContent?.document?.dover?.svDov?.prPeredov || '1',
							editable: false,
							allowManualInput: true,
							forceSelection: false,
							listeners: {
								change: switchPrPeredov,
								afterrender: switchPrPeredov
							},
							plugins: [
								createInfoElementPlugin({
									hintConfig: {
										html: edi.i18n.getMessage('power.of.attorney.reassignment.tooltip')
									},
									isField: true
								})
							]
						})
					]
				}),
				(prUtrPolnFieldBlock = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('power.of.attorney.loss.of.authority'),
					items: [
						(prUtrPolnCombo = createCombo({
							name: 'document.dover.svPoln.prUtrPoln',
							store: edi.stores.createSimpleInlineStore(['1', '2'], function (id) {
								return edi.i18n.getMessage('power.of.attorney.loss.of.authority.' + id);
							}),
							value: docContent?.document?.dover?.svPoln?.prUtrPoln || '1',
							editable: false,
							allowManualInput: true,
							forceSelection: false
						}))
					]
				})),
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('power.of.attorney.power.select'),
					items: [
						createCombo({
							name: 'document.dover.svPoln.prSovmPoln',
							store: edi.stores.createSimpleInlineStore(['1', '2'], function (id) {
								return edi.i18n.getMessage('power.of.attorney.power.code.' + id);
							}),
							value: docContent?.document?.dover?.svPoln?.prSovmPoln || '1',
							editable: false,
							readOnly: true,
							allowManualInput: true,
							forceSelection: false,
							plugins: [
								createInfoElementPlugin({
									hintConfig: {
										html: edi.i18n.getMessage('power.of.attorney.power.select.tooltip')
									},
									isField: true
								})
							]
						})
					]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('power.of.attorney.revoke.possibility'),
					cls: FIELD_BLOCK_CLS.small,
					items: [
						createCombo({
							name: 'document.dover.svDov.vidDover',
							store: edi.stores.createSimpleInlineStore(['1'], function (id) {
								return edi.i18n.getMessage('power.of.attorney.revoke.mark.' + id);
							}),
							value: docContent?.document?.dover?.svDov?.vidDover || '1',
							readOnly: true,
							plugins: [
								createInfoElementPlugin({
									hintConfig: {
										html: edi.i18n.getMessage('power.of.attorney.revoke.mark.tooltip')
									},
									isField: true
								})
							]
						})
					]
				})
			]
		});

		return createContainer({
			layout: {
				type: 'grid',
				gap: [24, 24],
				area: [12, [2, 2, 1, 1, 1], [4], 12, 12]
			},
			items: [title, format, area, areaHidden, nomDover, svedSist, vnNomDover, dateValid, additionConditions]
		});
	};

	//	Доверитель
	const createPrincipalFieldsBlock = function () {
		let defaultData = null;
		let principalValues = docContent?.document?.dover?.svDoverit || [];
		let defPrincipalTypes = [];
		if (!isEdit && !isCopy) {
			defaultData = {
				rosOrgDover: {
					svRosOrg: {
						naimOrg: userData.org.name,
						ogrn: userData.org.regNr,
						innyul: userData.org.inn,
						kpp: userData.org.kpp,
						kontaktTlf: userData.org.phones,
						adrElPocht: userData.org.email,
						adrReg: {
							region: getUserOrgRegion(),
							adrRF: getUserOrgAddress()
						}
					},
					litsoBezDov: {
						svFL: {
							snils: userData.personalId ? edi.converters.SNILS.convertForShow(userData.personalId) : null
						}
					}
				},
				ipDover: {
					naimIP: userData.org.name,
					ogrnip: userData.org.regNr,
					innfl: userData.org.inn,
					snils: userData.personalId ? edi.converters.SNILS.convertForShow(userData.personalId) : null
				},
				flDover: {
					snils: userData.personalId ? edi.converters.SNILS.convertForShow(userData.personalId) : null
				}
			};
		}

		/****
		 * Часть svRosOrg доверителя - ИП
		 */
		const principalIPSection = function (values) {
			let flTitle = createLabel({
				typography: 'heading_01',
				text: edi.i18n.getMessage('power.of.attorney.individual.data')
			});
			const hiddenItemIdIP = ['naimIP'];
			delete defaultData?.ipDover?.naimIP;
			let selectorIPBlock = createFieldBlock({
				title: edi.i18n.getMessage('company.type.INDIVIDUAL'),
				items: [
					createSimpleSelector({
						name: 'doverit.ipDover',
						itemId: 'doverit-ip',
						showSelectButton: false,
						showEditButton: true,
						allowBlank: false,
						fieldValues: values?.doverit?.ipDover ? values?.doverit?.ipDover : defaultData?.ipDover,
						modalConf: {
							cls: MODAL_CLS.withShadows,
							width: MODAL_SIZE.widthSmall,
							title: edi.i18n.getMessage('power.of.attorney.confidant.individual.person.title')
						},
						editBtnConfig: {
							text: edi.i18n.getMessage('document.edit.document')
						},
						infoPanelComponentTpl: moduleMethods_003.getIPTpl(''),
						isValidFunc: moduleMethods_003.isValidSimpleSelectorWindow,
						createFormPanel: (objectData, readOnly) =>
							createFormContainer({
								padding: '16 24 24 24',
								items: [getIPFieldsContainer(objectData, '', {}, hiddenItemIdIP, [6, [6, 6]])]
							})
					})
				]
			});

			const hiddenItemIdArray = ['inn', 'snils', 'dolzhnost'];
			return createPanel({
				layout: {
					type: 'grid',
					gap: [16, 16],
					area: [12, 12]
				},
				items: [
					createHiddenField({
						name: 'tipDoverit',
						value: moduleMethods_003.defPrincipalType.INDIV_ENTREPRENEUR
					}),
					selectorIPBlock,
					flTitle,
					getFLFieldsContainer(values, 'doverit.ipDover.', {}, hiddenItemIdArray, null, { isIP: true })
				]
			});
		};
		const createPrincipalIP = function (name, values) {
			return createEditableBlock({
				modal: true,
				modalConf: {
					cls: MODAL_CLS.withShadows,
					title: values
						? edi.i18n.getMessage('power.of.attorney.principal.edit')
						: edi.i18n.getMessage('power.of.attorney.principal.add'),
					width: MODAL_SIZE.widthMedium,
					plugins: [
						createHelpElementPlugin({
							url: 'https://ediweb.com/ru-ru/support/kb/1932#point1',
							tooltip: edi.i18n.getMessage('control.center.title.tooltip')
						})
					]
				},
				inputsFormCfg: {
					bodyPadding: '16 24'
				},
				getItems: function (me, formValues) {
					return principalIPSection(formValues);
				},
				values: values,
				detailsTpl: moduleMethods_003.getLitsoBezDovIpTpl(
					'doverit.ipDover',
					edi.i18n.getMessage('company.type.INDIVIDUAL')
				),
				validateForm: function (__self, form) {
					let invalidField = edi.utils.searchErrorField(form, ['doverit-ip']); //массив itemId которые НЕ нужно проверять
					if (invalidField) {
						return false;
					}
					//массив составных полей которые нужно проверять
					let compositeFields = [form.down('[itemId="doverit-ip"]')];
					invalidField = edi.utils.searchErrorCompositeField(compositeFields);
					if (invalidField) {
						return false;
					}

					return true;
				}
			});
		};

		/****
		 * Часть svRosOrg доверителя - ЮЛ
		 */
		const createPrincipalCompanyPart = function (docPath, partData, defaults, isCompany) {
			const principalCompanyPart = new PoACompanyPart({
				docPath,
				partData,
				defaults,
				isCompany
			});

			return principalCompanyPart.getSortedFieldList();
		};

		const principalCompanySection = function (partData) {
			const litsoBezDovFlData = partData?.doverit?.rosOrgDover?.litsoBezDov?.[0]?.svFL || {};
			const litsoBezDovIpData = partData?.doverit?.rosOrgDover?.litsoBezDov?.[0]?.svIP || {};
			const litsoBezDovUkData = partData?.doverit?.rosOrgDover?.litsoBezDov?.[0]?.svyul || {};

			let personActingWithoutPoaPanel, comboPoa;
			let currentValues = {};

			const getLitsoBezDovSection = function (comp, newValue, oldValue) {
				let currentForm = personActingWithoutPoaPanel.up('form')?.getForm();
				if (currentForm) {
					currentValues[oldValue] = edi.utils.collectFormValues(currentForm);
				}
				personActingWithoutPoaPanel.removeAll();
				switch (newValue) {
					case 'managementCompany': {
						let flTitle = createLabel({
							typography: 'heading_01',
							padding: '16 0 0 0',
							text: edi.i18n.getMessage('power.of.attorney.organization.head.info')
						});
						let UKPanel = createPanel({
							layout: {
								type: 'grid',
								gap: [16, 16],
								area: [12, 12, [4, 4, 4], 12, 12, 6, 12, [4, 8], 12, 12]
							},
							items: [
								createPanel({
									items: [
										createHiddenField({
											name: 'doverit.rosOrgDover.eiouk',
											value: '1'
										}),
										createHiddenField({
											name: 'doverit.rosOrgDover.eiofl',
											value: '0'
										}),
										createHiddenField({
											name: 'doverit.rosOrgDover.eioip',
											value: '0'
										})
									]
								}),
								...createPrincipalCompanyPart(
									'doverit.rosOrgDover.litsoBezDov.0.svyul.svYULEIO.',
									currentValues[newValue] || partData,
									{}
								),
								flTitle,
								getFLFieldsContainer(
									currentValues[newValue] || partData,
									'doverit.rosOrgDover.litsoBezDov.0.svyul.svFL.0.',
									{}
								)
							]
						});

						personActingWithoutPoaPanel.add(UKPanel);
						break;
					}
					case 'INDIVIDUAL': {
						const hiddenItemIdArray = ['inn', 'snils', 'dolzhnost'];
						let flTitle = createLabel({
							typography: 'heading_01',
							padding: '16 0 0 0',
							text: edi.i18n.getMessage('power.of.attorney.individual.data')
						});
						let ipPanel = createPanel({
							layout: {
								type: 'grid',
								gap: [16, 16],
								area: [12, 12, 12, 12]
							},
							items: [
								createPanel({
									items: [
										createHiddenField({
											name: 'doverit.rosOrgDover.eiouk',
											value: '0'
										}),
										createHiddenField({
											name: 'doverit.rosOrgDover.eiofl',
											value: '0'
										}),
										createHiddenField({
											name: 'doverit.rosOrgDover.eioip',
											value: '1'
										})
									]
								}),
								getIPFieldsContainer(
									currentValues[newValue] || partData,
									'doverit.rosOrgDover.litsoBezDov.0.svIP.'
								),
								flTitle,
								getFLFieldsContainer(
									currentValues[newValue] || partData,

									'doverit.rosOrgDover.litsoBezDov.0.svIP.',
									{},
									hiddenItemIdArray,
									null,
									{ isULIP: true }
								)
							]
						});
						personActingWithoutPoaPanel.add(ipPanel);
						break;
					}

					case 'person': {
						let flPanel = createPanel({
							layout: {
								type: 'grid',
								gap: [16, 16],
								area: [12, 12]
							},
							items: [
								createPanel({
									items: [
										createHiddenField({
											name: 'doverit.rosOrgDover.eiouk',
											value: '0'
										}),
										createHiddenField({
											name: 'doverit.rosOrgDover.eiofl',
											value: '1'
										}),
										createHiddenField({
											name: 'doverit.rosOrgDover.eioip',
											value: '0'
										})
									]
								}),
								getFLFieldsContainer(
									currentValues[newValue] || partData,
									'doverit.rosOrgDover.litsoBezDov.0.svFL.',
									{},
									[],
									null,
									{
										isULFL: true
									}
								)
							]
						});
						personActingWithoutPoaPanel.add(flPanel);
						break;
					}
				}
			};

			let litsoBezDovComboValue = 'person';
			if (!edi.utils.isEmptyObject(litsoBezDovIpData)) {
				litsoBezDovComboValue = 'INDIVIDUAL';
			}
			if (!edi.utils.isEmptyObject(litsoBezDovUkData)) {
				litsoBezDovComboValue = 'managementCompany';
			}
			if (!edi.utils.isEmptyObject(litsoBezDovFlData)) {
				litsoBezDovComboValue = 'person';
			}

			let items = [
				createHiddenField({
					name: 'tipDoverit',
					value: moduleMethods_003.defPrincipalType.COMPANY
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('company.type.LEGAL_ENTITY'),
					items: [
						createSimpleSelector({
							itemId: 'principal-ul',
							name: 'doverit.rosOrgDover.svRosOrg',
							showSelectButton: false,
							showEditButton: true,
							allowBlank: false,
							fieldValues: partData?.doverit?.rosOrgDover?.svRosOrg ?? defaultData?.rosOrgDover?.svRosOrg,
							modalConf: {
								cls: MODAL_CLS.withShadows,
								width: MODAL_SIZE.widthSmall,
								title: edi.i18n.getMessage('power.of.attorney.confidant.company.title')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							getFormValuesFunc: (formPanel) => {
								return edi.utils.collectFormValues(formPanel.getForm());
							},
							infoPanelComponentTpl: moduleMethods_003.getLegalEntityTpl(''),
							isValidFunc: moduleMethods_003.isValidSimpleSelectorWindow,
							createFormPanel: (objectData, readOnly) => {
								return createFormContainer({
									gap: [16, 24],
									area: [12, 7, [6, 6], 12, 12, 6, 12, 12, 12],
									padding: '16 24 24 24',
									items: [
										...createPrincipalCompanyPart(
											'',
											objectData ?? {},
											defaultData?.rosOrgDover?.svRosOrg,
											true
										)
									]
								});
							}
						})
					]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('power.of.attorney.person.acting.without.poa'),
					plugins: [
						createInfoElementPlugin({
							hintConfig: {
								html: edi.i18n.getMessage('power.of.attorney.person.acting.without.poa.tooltip')
							},
							refEl: 'titleEl'
						})
					],
					items: [
						(comboPoa = createCombo({
							title: edi.i18n.getMessage('power.of.attorney.person.acting.without.poa'),
							allowBlank: false,
							store: edi.stores.createSimpleInlineStore(
								['managementCompany', 'INDIVIDUAL', 'person'],
								function (id) {
									return edi.i18n.getMessage('company.type.' + id);
								}
							),
							value: litsoBezDovComboValue,
							listeners: {
								change(comp, newValue, oldValue) {
									if (arguments.length > 3) {
										getLitsoBezDovSection(comp, newValue, oldValue);
									}
								}
							}
						}))
					]
				}),
				(personActingWithoutPoaPanel = createPanel({
					items: []
				}))
			];
			return createPanel({
				layout: {
					type: 'grid',
					gap: [16, 24],
					area: [12, 12, 12]
				},
				items: items,
				listeners: {
					added() {
						let value = comboPoa.getValue();
						getLitsoBezDovSection(comboPoa, value);
					}
				}
			});
		};

		const createLegalEntityWithLitsoBezDov = function (name, values) {
			return createEditableBlock({
				modal: true,
				modalConf: {
					cls: MODAL_CLS.withShadows,
					title: values
						? edi.i18n.getMessage('power.of.attorney.principal.edit')
						: edi.i18n.getMessage('power.of.attorney.principal.add'),
					width: MODAL_SIZE.widthMedium,
					plugins: [
						createHelpElementPlugin({
							url: 'https://ediweb.com/ru-ru/support/kb/1932#point1',
							tooltip: edi.i18n.getMessage('control.center.title.tooltip')
						})
					]
				},
				inputsFormCfg: {
					bodyPadding: '16 24'
				},
				values: values,
				getItems: function (me, formValues) {
					return principalCompanySection(formValues);
				},
				detailsTpl: moduleMethods_003.getLegalEntityWithLitsoBezDov(
					'doverit.rosOrgDover',
					edi.i18n.getMessage('company.type.LEGAL_ENTITY')
				),
				validateForm: function (__self, form) {
					let invalidField = edi.utils.searchErrorField(form, ['principal-ul']); //массив itemId которые НЕ нужно проверять
					if (invalidField) {
						return false;
					}
					//массив составных полей которые нужно проверять
					let compositeFields = [form.down('[itemId="principal-ulp"]')];
					invalidField = edi.utils.searchErrorCompositeField(compositeFields);
					if (invalidField) {
						return false;
					}

					return true;
				}
			});
		};

		const getKeyByValue = function (object, value) {
			return Object.keys(object).find((key) => object[key] === value);
		};

		moduleMethods_003.getDoveritSideTypes().forEach(function (item) {
			if (
				item.id === moduleMethods_003.defPrincipalType.COMPANY ||
				item.id === moduleMethods_003.defPrincipalType.INDIV_ENTREPRENEUR
			) {
				let menuItem = createMenuItem({
					text: item.name,
					itemId: getKeyByValue(moduleMethods_003.defPrincipalType, item.id),
					handler: function (comp) {
						switch (comp.itemId) {
							case 'COMPANY':
								principalBlocksHolder.createNewBlockInWindow('companyForm');
								break;
							case 'INDIV_ENTREPRENEUR':
								principalBlocksHolder.createNewBlockInWindow('ipForm');
								break;
						}
					}
				});

				defPrincipalTypes.push(menuItem);
			}
		});

		return (principalBlocksHolder = createBlocksHolder({
			title: edi.i18n.getMessage('power.of.attorney.principal.info'),
			itemId: 'principalBlocksHolder',
			collapsible: true,
			allowBlank: false,
			values: [
				{
					entityModel: 'companyForm',
					values:
						principalValues?.[0]?.tipDoverit === moduleMethods_003.defPrincipalType.COMPANY
							? principalValues
							: []
				},
				{
					entityModel: 'ipForm',
					values:
						principalValues?.[0]?.tipDoverit === moduleMethods_003.defPrincipalType.INDIV_ENTREPRENEUR
							? principalValues
							: []
				}
			],
			entityModels: [
				{
					name: 'companyForm',
					blockConstructor: createLegalEntityWithLitsoBezDov
				},
				{
					name: 'ipForm',
					blockConstructor: createPrincipalIP
				}
			],
			addButtonConf: {
				text: edi.i18n.getMessage('power.of.attorney.add.principal'),
				menu: new Ext.menu.Menu({
					items: defPrincipalTypes
				})
			},
			onChange(__self, items) {
				__self.addButton.setVisible(items.items.length < 1);
			}
		}));
	};

	// Уполномоченный представитель
	const createConfidantFieldsBlock = function () {
		let confidantValues = docContent?.document?.dover?.svUpPred ?? [];
		//форма с SimpleSelector ФЛ
		const FLCompanySection = function (values) {
			const area = [12, [6, 6], [6, 6], 6, 4, 12, 12, 12, 12, 6, 12, 12, 12, 8, 8, 12, 6, 12, 12];
			let items = [
				createHiddenField({
					name: 'pred.tipPred',
					value: moduleMethods_003.defConfidantType.INDIVIDUAL
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('company.type.person'),
					items: [
						createSimpleSelector({
							itemId: 'confidant-fl',
							cls: 'ui-org-selector',
							name: 'pred',
							//заглушка для EW там будет выбор сотрудника
							showSelectButton: 'function' == typeof moduleMethods_003.selectionModalFl,
							selectionModalFunc:
								'function' == typeof moduleMethods_003.selectionModalFl
									? moduleMethods_003.selectionModalFl
									: null,
							fieldValues: values?.pred,
							showEditButton: true,
							allowBlank: false,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								cls: MODAL_CLS.withShadows,
								title: values?.pred
									? edi.i18n.getMessage('power.of.attorney.confidant.individual.edit')
									: edi.i18n.getMessage('power.of.attorney.confidant.individual.add')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							isValidFunc: moduleMethods_003.isValidSimpleSelectorWindow,
							infoPanelComponentTpl: moduleMethods_003.getSvedFizLTpl('svedFizL'),
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									padding: '16 24 24 24',
									items: [
										getFLFieldsContainer(objectData, 'svedFizL.', null, [], area, {
											isFL: true,
											isConfidant: true
										})
									]
								})
						})
					]
				})
			];
			return items;
		};
		const createFLConfidant = function (name, values) {
			return createEditableBlock({
				modal: true,
				modalConf: {
					title: values
						? edi.i18n.getMessage('power.of.attorney.confidant.edit')
						: edi.i18n.getMessage('power.of.attorney.confidant.add'),
					width: MODAL_SIZE.widthMedium,
					cls: MODAL_CLS.withShadows,
					plugins: [
						createHelpElementPlugin({
							url: 'https://ediweb.com/ru-ru/support/kb/1932#point2',
							tooltip: edi.i18n.getMessage('control.center.title.tooltip')
						})
					]
				},
				inputsFormCfg: {
					bodyPadding: '16 24'
				},
				getItems: function (me, formValues) {
					return FLCompanySection(formValues);
				},
				detailsTpl: moduleMethods_003.getSvedFizLTpl(
					'pred.svedFizL',
					edi.i18n.getMessage('company.type.person')
				),
				values: values,
				validateForm: function (__self, form) {
					//тк на этой форме только один createSimpleSelector валидируем только его
					return form.down('[itemId="confidant-fl"]').isValid();
				}
			});
		};

		const createConfidantCompanyPart = function ({ docPath, partData }) {
			class ConfidantPoACompanyPart extends PoACompanyPart {
				createRegNomerField(props) {
					return super.createRegNomerField({
						fieldBlockCfg: {
							title: edi.i18n.getMessage('company.regNr')
						}
					});
				}

				createAdrRFField(props) {
					return super.createAdrRFField({
						fieldCfg: {
							allowBlank: true
						}
					});
				}

				createNaimDokPdtvField(props = {}) {
					const { fieldCfg = {}, fieldBlockCfg = {}, order = 55 } = props;

					const me = this;

					const field = (me.fields.naimDokPdtv = createTextField(
						Object.assign(
							{
								value: moduleMethods_003.getFieldValue(
									me.props.partData,
									me.props.docPath,
									'dokPdtv.naimDokPdtv'
								),
								name: me.props.docPath + 'dokPdtv.naimDokPdtv',
								allowBlank: false,
								maxLength: 120
							},
							me.defaultFieldCfg,
							fieldCfg
						)
					));

					const fieldBlock = createFieldBlock(
						Object.assign(
							{
								title: edi.i18n.getMessage('documents.document.name'),
								items: field
							},
							me.defaultFieldBlockCfg,
							fieldBlockCfg
						)
					);

					return { field, fieldBlock, order };
				}

				getFieldList() {
					const me = this;
					const { fieldBlock: naimDokPdtv, order: naimDokPdtvOrder } = me.createNaimDokPdtvField();

					const result = super.getFieldList();
					return result.concat({
						cmp: naimDokPdtv,
						order: naimDokPdtvOrder
					});
				}
			}

			const confidantCompanyPart = new ConfidantPoACompanyPart({
				docPath,
				partData
			});

			return confidantCompanyPart.getSortedFieldList();
		};

		// форма с SimpleSelector ЮЛ
		const ULCompanySection = function (values) {
			return [
				createHiddenField({
					name: 'pred.tipPred',
					value: moduleMethods_003.defConfidantType.COMPANY
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('company.type.company'),
					items: [
						createSimpleSelector({
							itemId: 'confidant-ul',
							cls: 'ui-org-selector',
							name: 'pred',
							fieldValues: values?.pred,
							showEditButton: true,
							showSelectButton: false,
							allowBlank: false,
							modalConf: {
								width: MODAL_SIZE.widthSmall,
								cls: MODAL_CLS.withShadows,
								title: values?.pred
									? edi.i18n.getMessage('power.of.attorney.confidant.company.title')
									: edi.i18n.getMessage('power.of.attorney.confidant.company.edit')
							},
							editBtnConfig: {
								text: edi.i18n.getMessage('document.edit.document')
							},
							infoPanelComponentTpl: moduleMethods_003.getLegalEntityTpl('svedOrg'),
							isValidFunc: moduleMethods_003.isValidSimpleSelectorWindow,
							createFormPanel: (objectData, readOnly) =>
								createFormContainer({
									gap: [16, 24],
									area: [12, 7, [6, 6], 12, 12, 12, 6, 12, 12, 12],
									padding: '16 24 24 24',
									items: createConfidantCompanyPart({
										docPath: 'svedOrg.',
										partData: objectData ?? {}
									})
								})
						})
					]
				})
			];
		};
		const createULConfidant = function ({ values }) {
			return createEditableBlock({
				modal: true,
				modalConf: {
					title: values
						? edi.i18n.getMessage('power.of.attorney.confidant.edit')
						: edi.i18n.getMessage('power.of.attorney.confidant.add'),
					width: MODAL_SIZE.widthMedium,
					cls: MODAL_CLS.withShadows,
					plugins: [
						createHelpElementPlugin({
							url: 'https://ediweb.com/ru-ru/support/kb/1932#point2',
							tooltip: edi.i18n.getMessage('control.center.title.tooltip')
						})
					]
				},
				inputsFormCfg: {
					bodyPadding: '16 24'
				},
				getItems: function (me, formValues) {
					return ULCompanySection(formValues);
				},
				createView: (values) => {
					const svedOrg = values?.pred?.svedOrg ?? {};
					return createLabelBlockForDetails({
						contents: [
							{
								typography: 'heading_01',
								text: edi.i18n.getMessage('company.type.LEGAL_ENTITY')
							},
							{
								typography: 'body-short_02',
								text: svedOrg.naimOrg,
								isNewLine: true
							},
							{
								title: edi.i18n.getMessage('column.register.number'),
								text: svedOrg.ogrn,
								isNewLine: true
							},
							{
								title: edi.i18n.getMessage('company.inn.short'),
								text: svedOrg.innyul
							},
							{
								title: edi.i18n.getMessage('company.kpp.short'),
								text: svedOrg.kpp
							},
							{
								title: edi.i18n.getMessage('company.regNr'),
								text: svedOrg.regNomer,
								isNewLine: true
							},
							{
								title: edi.i18n.getMessage('documents.document.name'),
								text: svedOrg.dokPdtv?.naimDokPdtv,
								isNewLine: true
							},
							{
								title: edi.i18n.getMessage('power.of.attorney.establish.doc.name'),
								text: svedOrg.naimUchrDok,
								isNewLine: true
							},
							{
								title: edi.i18n.getMessage('company.contact.phone'),
								text: svedOrg.kontaktTlf,
								isNewLine: true
							},
							{
								title: edi.i18n.getMessage('user.profile.email'),
								text: svedOrg.adrElPocht,
								isNewLine: true
							},
							{
								title: edi.i18n.getMessage('power.of.attorney.rus.region'),
								text: svedOrg.adrReg?.region,
								isNewLine: true
							},
							{
								title: edi.i18n.getMessage('power.of.attorney.russ.address'),
								text: svedOrg.adrReg?.adrRF,
								isNewLine: true
							}
						]
					});
				},
				values: values,
				validateForm: function (__self, form) {
					//тк на этой форме только один createSimpleSelector валидируем только его
					return form.down('[itemId="confidant-ul"]').isValid();
				}
			});
		};

		return (confidantBlocksHolder = createBlocksHolder({
			title: edi.i18n.getMessage('power.of.attorney.confidants.column'),
			itemId: 'confidantBlocksHolder',
			collapsible: true,
			allowBlank: false,
			values: [
				{
					entityModel: 'individualParticipant',
					values: confidantValues.filter(
						({ tipPred } = {}) => tipPred === moduleMethods_003.defConfidantType.INDIVIDUAL
					)
				},
				{
					entityModel: 'company',
					values: confidantValues.filter(
						({ tipPred } = {}) => tipPred === moduleMethods_003.defConfidantType.COMPANY
					)
				}
			],
			entityModels: [
				{
					name: 'individualParticipant',
					blockConstructor: createFLConfidant
				},
				{
					name: 'company',
					blockConstructor: createULConfidant
				}
			],
			addButtonConf: {
				text: edi.i18n.getMessage('power.of.attorney.add.confidant'),
				menu: new Ext.menu.Menu({
					items: [
						createMenuItem({
							text: edi.i18n.getMessage('company.type.individualParticipant'),
							itemId: 'individualParticipant',
							handler: function () {
								confidantBlocksHolder.createNewBlockInWindow('individualParticipant');
							}
						}),
						createMenuItem({
							text: edi.i18n.getMessage('company.type.company'),
							itemId: 'company',
							handler: function () {
								confidantBlocksHolder.createNewBlockInWindow('company');
							}
						})
					]
				})
			},
			onChange(__self, items) {
				__self.addButton.setVisible(items.items.length < 1);
			}
		}));
	};

	//	Полномочия
	const createPowersList = function () {
		const powers = docContent?.document?.dover?.svPoln ?? {};

		const switchPowerType = function () {
			const isManual = powerTypeCombo.getValue() === '0';
			humanReadablePowerBlock[isManual ? 'show' : 'hide']();
			humanReadablePowerTextArea.setDisabled(!isManual);
			machineReadablePowerBlock[isManual ? 'hide' : 'show']();
		};

		powerTypeCombo = createCombo({
			allowBlank: false,
			name: 'document.dover.svPoln.tipPoln',
			value: powers.tipPoln ?? 0,
			plugins: [
				createInfoElementPlugin({
					hintConfig: {
						html: edi.i18n.getMessage('power.of.attorney.power.type.tooltip')
					},
					isField: true
				})
			],
			store: edi.stores.createSimpleInlineStore(['0', '1'], function (id) {
				return edi.i18n.getMessage(`power.of.attorney.power.type.com.edisoft:general_ui:war:1.0.19.2-RC7`);
			}),
			listeners: {
				change: switchPowerType,
				afterrender: switchPowerType
			}
		});

		const powerTypeBlock = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.power.type'),
			cls: FIELD_BLOCK_CLS.small,
			items: [powerTypeCombo]
		});

		humanReadablePowerTextArea = createTextField({
			isTextarea: true,
			allowBlank: false,
			rowsHtmlAttributeValue: 6,
			name: 'document.dover.svPoln.tekstPoln',
			value: powers.tekstPoln ?? ''
		});

		const humanReadablePowerBlock = createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.power.tekstPoln'),
			cls: FIELD_BLOCK_CLS.small,
			items: [humanReadablePowerTextArea]
		});

		const createPolnEditableBlock = function (entityName, blockValues) {
			return createEditableBlock({
				modal: true,
				modalConf: {
					cls: MODAL_CLS.withShadows,
					title: blockValues
						? edi.i18n.getMessage('power.of.attorney.power.mashPoln.modal.edit')
						: edi.i18n.getMessage('power.of.attorney.power.mashPoln.modal'),
					width: MODAL_SIZE.widthLarge
				},
				inputsFormCfg: {
					bodyPadding: '16 24'
				},
				values: blockValues,
				getItems: function (me, formValues) {
					const kodPolnField = createCombo({
						name: 'kodPoln',
						value: formValues?.kodPoln,
						allowBlank: false,
						editable: false,
						allowManualInput: true,
						forceSelection: false,
						store: moduleMethods_003.powersSection.createKodPolnStore(),
						plugins: [
							createInfoElementPlugin({
								hintConfig: {
									html: edi.i18n.getMessage('power.of.attorney.code.or.name.of.authority.tooltip')
								},
								isField: true
							})
						],
						listeners: {
							change: function (cmp, newValue, oldValue) {
								const rec = cmp.getSelectedRecord();
								if (!newValue) {
									naimPolnField.setValue('');
								} else if (!!newValue && !!rec) {
									naimPolnField.setValue(rec.data.originalName);
									//По требованию бизнеса временно убираем блок ограничений, что бы люди привыкли работать хотя бы с полномочиями
									//if (newValue !== oldValue) {
									//	ogrSvPolnRowsBlock
									//		.query('[itemId="remove_button"]')
									//		.forEach((btn) => btn.click());
									//}
								}
							}
						}
					});
					const naimPolnField = createHiddenField({
						name: 'naimPoln',
						value: formValues?.naimPoln
					});

					//По требованию бизнеса временно убираем блок ограничений, что бы люди привыкли работать хотя бы с полномочиями
					//const ogrSvPolnRowsBlock = createRowsBlock({
					//	initialData: formValues?.ogrSvPoln || [],
					//	addRowBtnConf: {
					//		text: edi.i18n.getMessage('power.of.attorney.power.add.ogr')
					//	},
					//	createContentFieldsFn: function (isFirstRow, values) {
					//		const index = ogrSvPolnRowsBlock
					//			? ogrSvPolnRowsBlock.items.items.length + 1
					//			: values?.idOgr;
					//		const indexLabel = createLabel({
					//			cls: 'edi-poa-power-block-ogr-index',
					//			text: index + '.',
					//			margin: '16 4 0 0'
					//		});
					//
					//		const setHiddenNaimZhachOgr = function (cmp) {
					//			const newValue = cmp.getValue();
					//			if (!newValue) {
					//				naimOgrField.setValue('');
					//			} else if (!!newValue && !!cmp.getSelectedRecord()) {
					//				const txt = edi.i18n.getMessage(`power.of.attorney.power.kodOgr.${newValue}`);
					//				naimOgrField.setValue(txt);
					//			}
					//		};
					//
					//		const kodOgrField = createCombo({
					//			flex: 1,
					//			fieldLabel: edi.i18n.getMessage('power.of.attorney.power.kodOgr'),
					//			name: 'kodOgr',
					//			value: values?.kodOgr,
					//			allowBlank: false,
					//			editable: false,
					//			allowManualInput: true,
					//			forceSelection: false,
					//			store: moduleMethods_003.powersSection.createKodOgrStore(),
					//			valueToRaw: function (value) {
					//				return Array.isArray(value) ? value[0] : value;
					//			},
					//			listeners: {
					//				change: function (cmp, newValue) {
					//					//костыль для валидации когда форма не отрисовыается, то в нее посылаются события
					//					// change изditableBlock по количеству аргументов мы их и различаем
					//					if (arguments.length > 3) {
					//						setHiddenNaimZhachOgr(cmp);
					//						const kodZnachOgrStore = kodZnachOgrField.getStore();
					//						kodZnachOgrStore.clearFilter(true);
					//						kodZnachOgrStore.addFilter([
					//							function filterByDodOgr(item) {
					//								return item.data.kodOgr === newValue;
					//							}
					//						]);
					//						kodZnachOgrField.setValue(null);
					//					}
					//				}
					//			}
					//		});
					//
					//		const naimOgrField = createHiddenField({
					//			name: 'naimOgr',
					//			value: values?.naimOgr,
					//			valueToRaw: function (value) {
					//				return Array.isArray(value) ? value[0] : value;
					//			}
					//		});
					//
					//		const setHiddenNaimZnachOgr = function (cmp) {
					//			let newValue = cmp.getValue();
					//			if (!newValue) {
					//				naimZnachOgrField.setValue('');
					//			} else if (!!newValue && !!cmp.getSelectedRecord()) {
					//				const txt = edi.i18n.getMessage(`power.of.attorney.power.kodZhachOgr.${newValue}`);
					//				naimZnachOgrField.setValue(txt);
					//			}
					//		};
					//
					//		const kodZnachOgrField = createCombo({
					//			flex: 1,
					//			fieldLabel: edi.i18n.getMessage('power.of.attorney.power.kodZhachOgr'),
					//			name: 'kodZnachOgr',
					//			value: values?.kodZnachOgr,
					//			allowBlank: false,
					//			editable: false,
					//			allowManualInput: true,
					//			forceSelection: false,
					//			store: moduleMethods_003.powersSection.createKodZnachOgrStore(),
					//			valueToRaw: function (value) {
					//				return Array.isArray(value) ? value[0] : value;
					//			},
					//			listeners: {
					//				change: function (cmp) {
					//					//костыль для валидации когда форма не отрисовыается, то в нее посылаются события
					//					// change изditableBlock по количеству аргументов мы их и различаем
					//					if (arguments.length > 3) {
					//						setHiddenNaimZnachOgr(cmp);
					//					}
					//				}
					//			}
					//		});
					//
					//		const naimZnachOgrField = createHiddenField({
					//			name: 'naimZnachOgr',
					//			value: values?.naimZnachOgr,
					//			valueToRaw: function (value) {
					//				return Array.isArray(value) ? value[0] : value;
					//			}
					//		});
					//
					//		return createContainer({
					//			cls: 'edi-poa-power-block-ogr-row',
					//			layout: {
					//				type: 'grid',
					//				gap: [0, 16],
					//				area: [[6, 6]]
					//			},
					//			items: [
					//				createContainer({
					//					layout: 'hbox',
					//					padding: '0 1 0 0',
					//					items: [indexLabel, kodOgrField, naimOgrField]
					//				}),
					//				createContainer({
					//					layout: 'hbox',
					//					padding: '0 1 0 0',
					//					items: [kodZnachOgrField, naimZnachOgrField]
					//				})
					//			],
					//			listeners: {
					//				added: function () {
					//					setHiddenNaimZhachOgr(kodOgrField);
					//					setHiddenNaimZnachOgr(kodZnachOgrField);
					//				}
					//			}
					//		});
					//	}
					//});

					return createPanel({
						layout: {
							type: 'grid',
							gap: [24, 0],
							area: [10, 12]
						},
						items: [
							createFieldBlock({
								cls: FIELD_BLOCK_CLS.small,
								title: edi.i18n.getMessage('power.of.attorney.code.or.name.of.authority'),
								items: [kodPolnField, naimPolnField]
							})
							//По требованию бизнеса временно убираем блок ограничений, что бы люди привыкли работать хотя бы с полномочиями
							//createFieldBlock({
							//	cls: FIELD_BLOCK_CLS.small,
							//	title: edi.i18n.getMessage('power.of.attorney.limitations.of.powers'),
							//	items: [ogrSvPolnRowsBlock]
							//})
						]
					});
				},
				detailsTpl: Ext.create(
					'Ext.XTemplate',
					'<div class="edi-poa-power-card">',
					`<div class='edi-poa-power-card-title'>{[ edi.i18n.getMessage('power.of.attorney.code.name.authority') ]}</div>`,
					'<div class="edi-poa-power-card-value">{[ this.getPolnText(values) ]}</div>',
					'{[ this.getLimitations(values) ]}',
					'</div>',
					{
						getPolnText: function (values) {
							const code = values?.kodPoln;
							const text = values?.naimPoln;
							return code && text ? `${code} — ${text}` : code ? code : null;
						},
						getLimitations: function (values) {
							const limitations = moduleMethods_003.powersSection.convertLimitationsFromBlock(values);
							return limitations.length > 0
								? [
										`<div class='edi-poa-power-card-limitations-title'>${edi.i18n.getMessage(
											'power.of.attorney.limitations.of.powers'
										)}</div>`,
										'<ol class="edi-poa-power-card-limitations-list">',
										this.getLimitationsList(limitations),
										'</ol>'
								  ].join('')
								: '';
						},
						getLimitationsList: function (limitations) {
							const subtitle = edi.i18n.getMessage('power.of.attorney.values.limitations');
							const renderLimitation = function (lim) {
								const code = lim?.kodOgr;
								const text = lim?.naimOgr;
								const limitationValue = code && text ? `${code} — ${text}` : code ? code : null;
								const kodZnachOgr = lim?.kodZnachOgr;
								const naimZnachOgr = lim?.naimZnachOgr;
								const limitationDescription =
									kodZnachOgr && naimZnachOgr
										? `${kodZnachOgr} — ${naimZnachOgr}`
										: kodZnachOgr
										? kodZnachOgr
										: null;
								return !limitationValue
									? ''
									: [
											'<li>',
											'<span>',
											`<div class='edi-poa-power-card-limitations-value'>${limitationValue}:</div>`,
											limitationDescription
												? `<div class='edi-poa-power-card-limitations-subtitle'>${subtitle}:</div>`
												: '',
											limitationDescription
												? `<div class='edi-poa-power-card-limitations-description'>${limitationDescription}</div>`
												: '',
											'</span>',
											'</li>'
									  ].join('');
							};

							return limitations.map((lim) => renderLimitation(lim)).join('');
						}
					}
				)
			});
		};

		machineReadablePowerBlock = createBlocksHolder({
			cls: 'edi-poa-power-block',
			itemId: 'machineReadablePowerBlock',
			allowBlank: false,
			values: [
				{
					entityModel: 'polnItem',
					values: powers.mashPoln || []
				}
			],
			entityModels: [
				{
					name: 'polnItem',
					blockConstructor: createPolnEditableBlock
				}
			],
			addButtonConf: {
				text: edi.i18n.getMessage('power.of.attorney.power.add'),
				handler: function () {
					machineReadablePowerBlock.createNewBlockInWindow('polnItem');
				}
			}
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('power.of.attorney.list.of.powers'),
			cls: [`${FIELD_BLOCK_CLS.default}`, `${FIELD_BLOCK_CLS.titleMarginMedium}`],
			layout: {
				type: 'grid',
				gap: [16, 0],
				area: [4, 8, 12]
			},
			items: [powerTypeBlock, humanReadablePowerBlock, machineReadablePowerBlock]
		});
	};

	const setFocusToDocuments = function () {
		let invalidField = edi.utils.searchErrorField(
			form,
			//массив itemId которые не нужно проверять
			//все составные поля
			['confidantBlocksHolder', 'machineReadablePowerBlock', 'principalBlocksHolder']
		);
		if (invalidField) {
			return;
		}
		//массив составных полей которые нужно проверять
		let compositeFields = [principalBlocksHolder, confidantBlocksHolder];
		if (machineReadablePowerBlock.isVisible()) {
			compositeFields.push(machineReadablePowerBlock);
		}

		invalidField = edi.utils.searchErrorCompositeField(compositeFields);
		if (invalidField) {
			return;
		}

		//проверка что доверитель и доверенное лицо не пустые
		let principalValues = principalBlocksHolder.getValues();
		//тк пока у нас доверитель только один смотрим что есть
		principalValues = principalValues?.ipForm || principalValues?.companyForm;
		if (!principalValues.length) {
			principalBlocksHolder.focus();
			return;
		}

		const confidantBlockValues = confidantBlocksHolder.getValues();
		let confidantValues = confidantBlockValues?.individualParticipant ?? confidantBlockValues?.company;
		if (!confidantValues.length) {
			confidantBlocksHolder.focus();
			return;
		}
		if (machineReadablePowerBlock.isVisible() && !machineReadablePowerBlock.getValues().polnItem.length) {
			machineReadablePowerBlock.focus();
			return;
		}

		return true;
	};

	const createModuleForm = function () {
		/**
		 * Создание формы документа - основной метод
		 */
		saveBtn = createCreateSaveButton(
			{
				handler: function () {
					if (!setFocusToDocuments()) {
						return;
					}
					save();
				}
			},
			isEdit
		);
		form = createForm({
			autoScroll: true,
			cls: 'edi-form',
			bodyPadding: '24',
			items: [
				createFormContainer({
					gap: [24, 24],
					items: [
						//	Верхний блок с полями
						createHeaderFieldsBlock(),
						//	Доверитель
						createPrincipalFieldsBlock(),
						// Уполномоченный представитель
						createConfidantFieldsBlock(),
						//	Полномочия
						createPowersList()
					]
				})
			],
			buttonAlign: 'left',
			buttons: [saveBtn]
		});

		form.isValid();
		form.on('validitychange', function (__self, valid) {
			form.isValid();
		});

		return form;
	};

	const save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));

		//Шапка документа
		let formValues = edi.utils.collectFormValues(form);
		let confidantsSnils = [];
		let principalSnils;
		//Доверитель
		let principalValues = principalBlocksHolder.getValues();
		//тк пока у нас доверитель только один смотрим что есть
		principalValues = principalValues?.ipForm || principalValues?.companyForm;
		principalValues.forEach(function (principalValue) {
			principalSnils = principalValue?.doverit?.ipDover?.snils;
			const principalLitsoBezDov = edi.utils.getObjectProperty(
				principalValue,
				'doverit.rosOrgDover.litsoBezDov.0'
			);
			if (principalLitsoBezDov) {
				principalLitsoBezDov.polnYUL = '1'; //поля нет на форме поэтому хардкодим
				principalValue.doverit.rosOrgDover.litsoBezDov = [principalLitsoBezDov];
				if (principalLitsoBezDov?.svyul?.svFL) {
					const svFL = edi.utils.getObjectProperty(principalLitsoBezDov, 'svyul.svFL.0');
					principalSnils = svFL?.snils;
					let dataDok = svFL?.svedFL?.udLichnFL?.dataDok;
					if (Array.isArray(dataDok)) {
						svFL.svedFL.udLichnFL.dataDok = dataDok[0];
					}
					principalLitsoBezDov.svyul.svFL = [svFL];
				} else if (principalLitsoBezDov?.svFL) {
					const svFL = edi.utils.getObjectProperty(principalLitsoBezDov, 'svFL');
					principalSnils = svFL?.snils;
					let dataDok = svFL?.svedFL?.udLichnFL?.dataDok;
					if (Array.isArray(dataDok)) {
						svFL.svedFL.udLichnFL.dataDok = dataDok[0];
					}
					principalLitsoBezDov.svFL = svFL;
				} else if (principalLitsoBezDov?.svIP) {
					const svFL = edi.utils.getObjectProperty(principalLitsoBezDov, 'svIP');
					principalSnils = svFL?.snils;
					let dataDok = svFL?.svedFL?.udLichnFL?.dataDok;
					if (Array.isArray(dataDok)) {
						svFL.svedFL.udLichnFL.dataDok = dataDok[0];
					}
					principalLitsoBezDov.svIP = svFL;
				}
			}
		});

		//Уполномоченный представитель
		const confidantBlockValues = confidantBlocksHolder.getValues();
		let confidantValues = confidantBlockValues?.individualParticipant ?? confidantBlockValues?.company;
		confidantValues.forEach(function (value) {
			value.tipPred =
				(value?.pred?.svedFizL && moduleMethods_003.defConfidantType.INDIVIDUAL) ||
				(value?.pred?.svedOrg && moduleMethods_003.defConfidantType.COMPANY);
			const snils = value?.pred?.svedFizL?.snils;
			if (snils) {
				confidantsSnils.push(snils);
			}
		});

		//Перечень полномочий
		const powersType = powerTypeCombo.getValue();
		const isMachineReadablePowers = powersType === '1';
		const machineReadablePowers = machineReadablePowerBlock.getValues().polnItem?.map((power) => {
			const limits = moduleMethods_003.powersSection.convertLimitationsFromBlock(power);
			return {
				kodPoln: power.kodPoln,
				naimPoln: power.naimPoln,
				mnPoln: null,
				ogrSvPoln: limits.length > 0 ? limits : null
			};
		});

		let data = {
			fileId: docContent?.fileId || '11cb9029d958',
			versForm: 'EMCHD_1',
			document: {
				dover: {
					svPoln: {
						tipPoln: powersType,
						mashPoln: isMachineReadablePowers ? machineReadablePowers : null,
						tekstPoln: !isMachineReadablePowers ? humanReadablePowerTextArea.getValue() : null
					},
					svUpPred: confidantValues,
					svDoverit: principalValues
				}
			}
		};

		let saveObj = {
			docType: edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY,
			toOrgId: userData.org.id,
			versionId: 'EMCHD_1',
			data: Ext.merge(data, formValues)
		};

		edi.utils.clearEmptyValues(saveObj);

		saveObj.data = Ext.encode(saveObj.data);

		const success = function (resp) {
			moduleData.tab.setLoading(false);
			moduleData.tab.close();
			let docData = isEdit ? docHeader : resp.data;
			let docId = isEdit ? docHeader.id : resp.data.id;
			edi.events.powerOfAttorney.fireEvent('change', {
				id: docData?.docId
			});
			const titlePostfix = docContent?.document?.dover?.svDov?.vnNomDover;
			let personalId = userData?.personalId;
			if (personalId) {
				personalId = edi.converters.SNILS.convertForShow(personalId);
			}
			if (personalId === principalSnils || confidantsSnils.includes(personalId)) {
				edi.core.openModule('power.of.attorney.details_003', docData, titlePostfix, isEdit, docId);
			}
		};

		const failure = function (response) {
			moduleData.tab.setLoading(false);
			edi.core.showError(edi.utils.formatComplexServerError(response, 'power.of.attorney.error.saving'));
		};
		if (!!docId && !isCopy) {
			let updateObj = {
				UPDATE: true,
				UPDATE_PARAMS: saveObj
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.PUT, {
					documentId: docId
				}),
				'PUT',
				Ext.encode(updateObj),
				success,
				failure,
				null
			);
		} else {
			edi.rest.sendRequest(
				edi.rest.services.POWER_OF_ATTORNEY.POST,
				'POST',
				Ext.encode(saveObj),
				success,
				failure,
				null
			);
		}
	};

	const renderData = function (initCallBack) {
		const finishRender = function () {
			let modulePanel = createAddModulePanel();

			modulePanel.add(createModuleForm());
			moduleData.tab.add(modulePanel);

			if (initCallBack && typeof initCallBack === 'function') {
				initCallBack();
			}
		};

		const loadCodes = function () {
			moduleMethods_003.powersSection
				.loadKodPolnItems()
				.then(finishRender)
				.catch(function (resp) {
					moduleData.tab.close();
					edi.rest.getErrorHandler()(resp);
				});
		};

		if (docId && !isCopy) {
			moduleMethods
				.fetchData({
					poaId: docId,
					fromSignature: moduleData.initData.fromSignature,
					docId: moduleData.initData.signedWithPoaDocId
				})
				.then(function ({ header, content }) {
					docHeader = header;
					docContent = content;
					moduleData.initData.data = docHeader;
					loadCodes();
				})
				.catch(function (resp) {
					moduleData.tab.close();
					edi.rest.getErrorHandler()(resp);
				});
		} else {
			loadCodes();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
