Ext.namespace('edi.rest');
Ext.merge(edi.rest.services, {
	REPORT: {
		GROUP: {
			ON_PACKAGE_LIST: {
				POST: edi.rest.prfx.c + 'report/request/group/on_packet_list',
				EXPORT: {
					POST: edi.rest.prfx.c + 'report/request/group/on_packet_list/export'
				}
			}
		}
	}
});
