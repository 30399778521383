import { createProductGridBase } from '@Components/productGrid/ProductGrid';
import {
	createCombo,
	createHiddenField,
	createLabel,
	createLabelBlockForDetails,
	createNumberField,
	createTagTextField,
	createTextField
	// @ts-ignore
} from '@UIkit/components/fields';
// @ts-ignore
import { createTab, createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
import {
	createContainer,
	createFieldBlock,
	createFieldSet,
	FIELD_BLOCK_CLS,
	FIELD_SET_CLS
	// @ts-ignore
} from '@UIkit/components/panels';
import { createNamedDocumentBlock } from './documentBlock/createDocumentBlock';
import * as okof from './json/okof.json';
import * as scCodes from './json/sc_codes.json';
import {
	OKOF_MODEL,
	UPD_5_02_DECLARATION_INFORMATION_MODEL,
	UPD_5_02_LINE_MODEL,
	UPD_5_02_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS_MODEL,
	UPD_5_02_PRODUCT_INFO_TRACKING_MODEL,
	UPD_5_02_PRODUCT_TEXT_INFO_MODEL,
	UPD_5_02_SUMMARY_MODEL
} from './models';
// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal';
import { createEditableGrid } from '@Components/editableGrid/EditableGrid';
import { createInfoElementPlugin } from '@Core/methods';
import {
	UPD_5_02_DECLARATION_INFO_COLUMN_CONFIG_NAME,
	UPD_5_02_IDENTITY_PRODUCT_TOOL_NUMBERS_COLUMN_CONFIG_NAME,
	UPD_5_02_LINES_COLUMN_CONFIG_NAME,
	UPD_5_02_SUMMARY_COLUMN_CONFIG_NAME
} from './columns';
// @ts-ignore
import { ROW_COLOR_CLS } from '@UIkit/components/grid';
// @ts-ignore
import { createEditableBlock } from '@UIkit/components/blocks/EditableBlock';
import { createRowsBlock } from '@Components/createRows/create.rows.v2';
import {
	NomSredIdentTov,
	SvedProslezh,
	SvGosSist,
	SvedTov,
	Upd5_02LineProductGridRecordData
} from './definitions/producLineDef';
import { OrgProductFields, TekstInf } from './definitions/commonsDef';
import { createNamedDocumentBlockForDetail } from './documentBlock/createDocumentBlockForDetail';
import { UPD502productIdentifyRecordData } from './definitions/records';
import { calculateUpdBusinessProcess } from '../methods';

export interface DocLinesProps {
	data: SvedTov[] | [];
	readOnly?: boolean;
	documentData: AnyObject;

	getCurrentUPDState: () => {
		orgProductFields: OrgProductFields[];
		updFunction: string;
		taxCalculationMethod: string;
		spObstFSCHFValue: string | null;
		spObstFDOP: string | null;
		isCreatedFromDoc: boolean;
		isCreate: boolean;
		isCorrect: boolean;
	};

	[key: string]: any;
}

export class DocLines {
	fields: {
		[fieldName: string]: ExtComponent;
	};
	blocks: {
		[blockName: string]: ExtComponent | null;
	};
	grids: {
		[gridName: string]: ExtComponent;
	};

	textInfosUnitGrossPriceIdentifier: 'цена_с_ндс' = 'цена_с_ндс';

	productsGrid: ExtComponent;

	props: DocLinesProps;

	path: string = 'dokument.tablSchFakt.svedTov';

	constructor(props: DocLinesProps) {
		const me = this;
		me.modifyConfig(props);
	}

	modifyConfig(props: DocLinesProps) {
		const me = this;

		me.fields = {};
		me.blocks = {};
		me.grids = {};

		me.props = props;
	}

	createFieldName(...name: (string | undefined)[]): string {
		const me = this;
		//return [me.path, ...name].filter(Boolean).join('.');
		return [...name].filter(Boolean).join('.');
	}

	recordValueAfterEdit(values: AnyObject, isLoadData?: boolean) {
		const me = this;

		const { taxCalculationMethod, isCreatedFromDoc } = me.props.getCurrentUPDState();

		//Сумма без НДС
		let costWithoutNDS = 0,
			//Сумма НДС
			taxSum = 0,
			//Сумма с НДС
			gross = 0,
			//Цена с НДС
			unitGross =
				values[me.textInfosUnitGrossPriceIdentifier] ||
				values.infPolFHZH2?.find(
					(textInfo: TekstInf) =>
						textInfo?.identif === me.textInfosUnitGrossPriceIdentifier && textInfo.znachen
				)?.value,
			//Цена без НДС
			cost = values.tsenaTov;
		const amount = parseFloat(values.kolTov);
		const price = parseFloat(values.tsenaTov);
		const rate = edi.utils.getObjectProperty(values, 'nalSt');
		const theRate = edi.methods.calculateTaxRate(rate) / 100;

		if (amount && price) {
			costWithoutNDS = amount * price;
			taxSum = costWithoutNDS * theRate;
		}

		if (!taxCalculationMethod || taxCalculationMethod === 'netPrice') {
			gross = costWithoutNDS + taxSum;
			unitGross = price * (1 + theRate);
		} else if (taxCalculationMethod === 'without') {
			gross = isCreatedFromDoc && isLoadData && values.stTovUchNal ? values.stTovUchNal : costWithoutNDS + taxSum;
			taxSum = isCreatedFromDoc && isLoadData && values.sumNal?.sumNal ? values.sumNal.sumNal : taxSum;
			costWithoutNDS = isCreatedFromDoc && isLoadData && values.stTovBezNDS ? values.stTovBezNDS : costWithoutNDS;
		} else if (taxCalculationMethod === 'grossPrice') {
			gross = unitGross * amount;
			taxSum = (unitGross - unitGross / (1 + theRate)) * amount;
			costWithoutNDS = (unitGross / (1 + theRate)) * amount;
			cost = unitGross / (1 + theRate);
		}

		values.tsenaTov = edi.utils.roundTo(parseFloat(cost), 2);
		values.sumNal = {
			sumNal: edi.utils.roundTo(taxSum, 2)
		};
		values.stTovBezNDS = edi.utils.roundTo(costWithoutNDS, 2);
		values.stTovUchNal = edi.utils.roundTo(gross, 2);
		values[me.textInfosUnitGrossPriceIdentifier] = edi.utils.roundTo(parseFloat(unitGross), 2);
	}

	setTaxFields() {
		const me = this;

		const values = Ext.clone(me.productsGrid.getFormValues());
		let { costWithoutNDS, grossAmount, unitNetPrice, unitGrossPrice, taxSum } = me.fields;
		me.recordValueAfterEdit(values);

		costWithoutNDS?.setValue(values.stTovBezNDS);
		taxSum.setValue(values.sumNal.sumNal);
		grossAmount?.setValue(values.stTovUchNal);
		unitNetPrice?.setValue(values.tsenaTov);
		unitGrossPrice?.setValue(values[me.textInfosUnitGrossPriceIdentifier]);
	}

	validateDataProductField() {
		const me = this;

		const { updFunction } = me.props.getCurrentUPDState();
		const { isDOP, isSCHF, isSCHFDOP } = calculateUpdBusinessProcess(updFunction);

		const fields = [
			me.fields.productName, //<НаимТов>
			me.fields.productDesc, //<ХарактерТов>
			me.fields.productCode, //<КодТов>
			me.fields.productReference //<АртикулТов>
		];

		const isAllFieldEmpty = !fields.some((field) => field.getValue());

		//При <Функция> = СЧФ | СЧФДОП элемент обязателен.
		//Для <Функция> = ДОП элемент обязателен при отсутствии элементов <ХарактерТов> и <АртикулТов> и <КодТов>.
		me.fields.productName.allowBlank = !(isSCHF || isSCHFDOP || (isDOP && isAllFieldEmpty));

		//Для <Функция>=ДОП элемент обязателен при отсутствии элементов <НаимТов> и <КодТов> и <АртикулТов>.
		me.fields.productDesc.allowBlank = !(isDOP && isAllFieldEmpty);

		//Для <Функция> = ДОП элемент обязателен при отсутствии <НаимТов> и <ХарактерТов> и <АртикулТов>.
		me.fields.productCode.allowBlank = !(isDOP && isAllFieldEmpty);

		//Для <Функция> = ДОП элемент обязателен при отсутствии <НаимТов> и <ХарактерТов> и <КодТов>
		me.fields.productReference.allowBlank = !(isDOP && isAllFieldEmpty);

		me.productsGrid.formPanel?.isValid();
	}

	validateProductFields() {
		const me = this;
		const { spObstFSCHFValue, spObstFDOP, updFunction } = me.props.getCurrentUPDState();
		const { isDOP, isSCHF, isSCHFDOP } = calculateUpdBusinessProcess(updFunction);

		const isSpObstFSCHF2 = spObstFSCHFValue === '2';

		// Элемент обязателен при <Функция> = СЧФ | СЧФДОП, кроме случаев, когда <СпОбстФСЧФ > = 2.
		// Элемент обязателен при наличии <ОКЕИ_Тов>
		me.fields.quantity.allowBlank = !(((isSCHF || isSCHFDOP) && !isSpObstFSCHF2) || me.fields.okei.getValue());

		//Элемент обязателен при <Функция>=СЧФ | СЧФДОП, кроме случаев, когда <СпОбстФСЧФ> = 2
		const unitNetPriceAllowBlank = !((isSCHF || isSCHFDOP) && !isSpObstFSCHF2);
		me.fields.unitNetPrice.allowBlank = unitNetPriceAllowBlank;
		//Поле "Цена с НДС" должно иметь туже логику обязательности заполнения, что и поле "Цена без НДС"
		me.fields.unitGrossPrice.allowBlank = unitNetPriceAllowBlank;

		//Элемент обязателен при <Функция>=СЧФДОП | ДОП при наличии натурального измерителя факта хозяйственной жизни.
		me.fields.okei.allowBlank = !isSCHFDOP && !isDOP;

		//Элемент обязателен при <Функция> = СЧФ | СЧФДОП, кроме случаев, когда <СпОбстФСЧФ> = 2.
		//Для <Функция> = ДОП обязателен, если отсутствуют <СпОбстФДОП> и <СтТовУчНал>
		me.fields.costWithoutNDS.allowBlank = !(
			((isSCHF || isSCHFDOP) && !isSpObstFSCHF2) ||
			(isDOP && !spObstFDOP && !me.fields.grossAmount?.getValue())
		);

		//Элемент обязателен при <Функция> = СЧФ | СЧФДОП.
		me.fields.grossAmount.allowBlank = !isSCHF && !isSCHFDOP;

		me.productsGrid.formPanel?.isValid();
	}

	changeFieldsVisibility() {
		const me = this;
		if (me.fields.unitOfMeasure && me.fields.okei) {
			const isManualInput = me.fields.okei.getValue() === '0000';
			me.fields.unitOfMeasure.getFieldBlock()?.setVisible(isManualInput);
			me.fields.unitOfMeasure.setDisabled(!isManualInput);
		}
	}

	createProductNameField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const name = (me.fields.productName = createTextField({
			name: me.createFieldName('naimTov'),
			valueSrc: values,
			maxLength: 2000,
			listeners: {
				change: () => me.validateDataProductField()
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: name
		});
	}

	createProductQuantityField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const quantity = (me.fields.quantity = createNumberField({
			maxText: edi.i18n.getMessage('invalid.max.text.fns'),
			validator: edi.methods.validators.positiveNonZero,
			allowDecimals: true,
			replaceComa: true,
			vtype: 'fnsNumberN26_11',
			name: me.createFieldName('kolTov'),
			valueSrc: values,
			decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
			allowBlank: false,
			listeners: {
				change: () => me.setTaxFields()
			}
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.invoice.quantity'),
			cls: FIELD_BLOCK_CLS.small,
			items: quantity
		});
	}

	createOkeiField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const okei = (me.fields.okei = createCombo({
			store: edi.stores.initOkeiStoreForN(),
			forceSelection: true,
			anyMatch: true,
			valueField: 'id',
			displayField: 'name_code',
			name: me.createFieldName('okeiTov'),
			valueSrc: values,
			allowBlank: false,
			listeners: {
				change: (combo: ExtComponent, value: string | null) => {
					me.changeFieldsVisibility();
					me.validateProductFields();
				}
			}
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.unit.of.measure'),
			cls: FIELD_BLOCK_CLS.small,
			items: okei
		});
	}

	createUnitOfMeasureField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const unitOfMeasure = (me.fields.unitOfMeasure = createTextField({
			maxLength: 255,
			name: me.createFieldName('naimEdIzm'),
			valueSrc: values,
			allowBlank: false
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.unit.of.measure.inputManual'),
			cls: FIELD_BLOCK_CLS.small,
			layout: {
				type: 'grid',
				area: [4]
			},
			items: unitOfMeasure
		});
	}

	createAccompanyingDocumentBlock({ values }: { values?: SvedTov } = {}): ExtComponent | null {
		const me = this;

		const name = me.createFieldName('dopSvedTov.soprDokTov');
		return (me.blocks.assignmentBasis = me.props.readOnly
			? createNamedDocumentBlockForDetail({
					title: edi.i18n.getMessage('document.fns_upd_5_02n.accompanying.documents'),
					data: edi.utils.getObjectProperty(values, name, true),
					name
			  })
			: createNamedDocumentBlock({
					title: edi.i18n.getMessage('document.fns_upd_5_02n.accompanying.documents'),
					data: edi.utils.getObjectProperty(values, name, true),
					name
			  }));
	}

	createTaxCalculationMethodField(): ExtComponent {
		const me = this;

		const { taxCalculationMethod } = me.props.getCurrentUPDState();

		const taxCalculationMethodField = (me.fields.taxCalculationMethod = createTextField({
			disabled: true,
			value: taxCalculationMethod
				? edi.i18n.getMessage('organization.taxCalculationMethod.' + taxCalculationMethod)
				: ''
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('organization.taxCalculationMethod'),
			cls: FIELD_BLOCK_CLS.small,
			hidden: me.props.readOnly,
			items: taxCalculationMethodField
		});
	}

	createUnitNetPriceField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const unitNetPrice = (me.fields.unitNetPrice = createNumberField({
			maxText: edi.i18n.getMessage('invalid.max.text.fns'),
			type: 'number',
			allowDecimals: true,
			replaceComa: true,
			vtype: 'fnsNumberN26_11',
			validator: edi.methods.validators.positiveNonZero,
			name: me.createFieldName('tsenaTov'),
			valueSrc: values,
			decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
			allowBlank: false,
			listeners: {
				change: () => me.setTaxFields()
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('column.bill.unit.net.price'),
			cls: FIELD_BLOCK_CLS.small,
			items: unitNetPrice
		});
	}

	createUnitGrossPriceField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const unitGrossPrice = (me.fields.unitGrossPrice = createNumberField({
			name: me.createFieldName(me.textInfosUnitGrossPriceIdentifier),
			valueSrc: values,
			type: 'number',
			allowDecimals: true,
			replaceComa: true,
			decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
			allowBlank: false,
			listeners: {
				change: () => me.setTaxFields()
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('pricat.product.unitGrossPrice'),
			cls: FIELD_BLOCK_CLS.small,
			items: unitGrossPrice
		});
	}

	taxValueValid(tax: string) {
		const me = this;
		return !me.getfnsNTaxRates().some((it) => it.id === tax.replace(/[%]*/g, ''))
			? edi.i18n.getMessage('form.field.tax.invalid')
			: true;
	}

	getfnsNTaxRates(): { id: string; name: string }[] {
		//Аналогичен edi.stores.data.fnsNTaxRates, но без 18 и 18/118
		//0%, 10%, 20%, 10/110, 20/120, без НДС, НДС исчисляется налоговым агентом
		return [
			{
				id: '0',
				name: '0'
			},
			{
				id: '5',
				name: '5'
			},
			{
				id: '7',
				name: '7'
			},
			{
				id: '9,09',
				name: '9,09'
			},
			{
				id: '10',
				name: '10'
			},
			{
				id: '16,67',
				name: '16,67'
			},
			{
				id: '20',
				name: '20'
			},
			{
				id: '5/105',
				name: '5/105'
			},
			{
				id: '7/107',
				name: '7/107'
			},
			{
				id: '10/110',
				name: '10/110'
			},
			{
				id: '20/120',
				name: '20/120'
			},
			{
				id: edi.constants.taxRates.taxNoVat,
				name: edi.i18n.getMessage('tax.no.vat')
			},
			{
				id: edi.constants.taxRates.taxVatByTaxAgent,
				name: edi.i18n.getMessage('tax.vat.by.tax.agent')
			}
		];
	}
	createTaxRateField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const taxRate = (me.fields.taxRate = createCombo({
			store: edi.stores.createInlineStore(me.getfnsNTaxRates(), 'SIMPLE'),
			forceSelection: false,
			allowBlank: false,
			name: me.createFieldName('nalSt'),
			value: edi.utils.getObjectProperty(values, me.createFieldName('nalSt'))?.replace(/[%]*/g, ''),
			validator: me.taxValueValid.bind(me),
			listeners: {
				change: function () {
					me.setTaxFields();
				}
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.fns.tax.rate'),
			cls: FIELD_BLOCK_CLS.small,
			items: taxRate
		});
	}

	createSumExciseField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const sumExcise = (me.fields.sumExcise = createNumberField({
			allowDecimals: true,
			replaceComa: true,
			decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
			name: me.createFieldName('aktsiz.sumAktsiz'),
			valueSrc: values,
			vtype: 'fnsNumberN19_2',
			emptyText: 'без акциза'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.upd.excise'),
			cls: FIELD_BLOCK_CLS.small,
			items: [sumExcise]
		});
	}

	createCostWithoutNDSField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const costWithoutNDS = (me.fields.costWithoutNDS = createNumberField({
			maxText: edi.i18n.getMessage('invalid.max.text.fns'),
			allowDecimals: true,
			replaceComa: true,
			decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
			valueSrc: values,
			name: me.createFieldName('stTovBezNDS')
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.net.amount'),
			cls: FIELD_BLOCK_CLS.small,
			items: costWithoutNDS
		});
	}

	createTaxSumField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const taxSum = (me.fields.taxSum = createNumberField({
			maxText: edi.i18n.getMessage('invalid.max.text.fns'),
			allowDecimals: true,
			replaceComa: true,
			name: me.createFieldName('sumNal.sumNal'),
			valueSrc: values,
			decimalPrecision: edi.constants.UPD_PRICE_PRECISION
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.tax.amount'),
			cls: FIELD_BLOCK_CLS.small,
			items: taxSum
		});
	}

	createGrossAmountField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const grossAmount = (me.fields.grossAmount = createNumberField({
			maxText: edi.i18n.getMessage('invalid.max.text.fns'),
			allowDecimals: true,
			replaceComa: true,
			name: me.createFieldName('stTovUchNal'),
			valueSrc: values,
			decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
			allowBlank: false,
			listeners: {
				change: (combo: ExtComponent, value: string | null) => {
					//Для валидации costWithoutNDS
					me.validateProductFields();
				}
			}
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.gross.amount'),
			cls: FIELD_BLOCK_CLS.small,
			items: grossAmount
		});
	}

	createDepreciationGroupField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const depreciationGroup = (me.fields.depreciationGroup = createTextField({
			maxLength: 2,
			valueSrc: values,
			name: me.createFieldName('dopSvedTov.nalUchAmort.amGruppa'),
			requiredFields: [
				me.createFieldName('dopSvedTov.nalUchAmort.kodOKOF'),
				me.createFieldName('dopSvedTov.nalUchAmort.srPolIspOS'),
				me.createFieldName('dopSvedTov.nalUchAmort.faktSrokIsp')
			]
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('document.fns_upd_5_02n.depreciation.group'),
			cls: FIELD_BLOCK_CLS.small,
			items: depreciationGroup
		});
	}

	createFixedAssetCodeField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const fixedAssetCode = (me.fields.fixedAssetCode = createCombo({
			store: edi.stores.createInlineStore(okof, OKOF_MODEL),
			name: me.createFieldName('dopSvedTov.nalUchAmort.kodOKOF'),
			valueSrc: values,
			displayField: 'displayName',
			anyMatch: true,
			valueField: 'code',
			requiredFields: [
				me.createFieldName('dopSvedTov.nalUchAmort.amGruppa'),
				me.createFieldName('dopSvedTov.nalUchAmort.srPolIspOS'),
				me.createFieldName('dopSvedTov.nalUchAmort.faktSrokIsp')
			]
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('document.fns_upd_5_02n.fixed.asset.code'),
			cls: FIELD_BLOCK_CLS.small,
			items: fixedAssetCode
		});
	}

	createSellerEstimatedUsefulLifeField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const sellerEstimatedUsefulLife = (me.fields.sellerEstimatedUsefulLife = createTextField({
			maxLength: 3,
			emptyText: edi.i18n.getMessage('document.fns_upd_5_02n.seller.useful.life.period'),
			name: me.createFieldName('dopSvedTov.nalUchAmort.srPolIspOS'),
			requiredFields: [
				me.createFieldName('dopSvedTov.nalUchAmort.amGruppa'),
				me.createFieldName('dopSvedTov.nalUchAmort.kodOKOF'),
				me.createFieldName('dopSvedTov.nalUchAmort.faktSrokIsp')
			],
			valueSrc: values
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('document.fns_upd_5_02n.seller.estimated.useful.life'),
			cls: FIELD_BLOCK_CLS.small,
			items: sellerEstimatedUsefulLife
		});
	}

	createSellerActualUsefulLifeField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const sellerActualUsefulLife = (me.fields.sellerActualUsefulLife = createNumberField({
			maxLength: 3,
			emptyText: edi.i18n.getMessage('document.fns_upd_5_02n.seller.useful.life.period'),
			name: me.createFieldName('dopSvedTov.nalUchAmort.faktSrokIsp'),
			requiredFields: [
				me.createFieldName('dopSvedTov.nalUchAmort.amGruppa'),
				me.createFieldName('dopSvedTov.nalUchAmort.kodOKOF'),
				me.createFieldName('dopSvedTov.nalUchAmort.srPolIspOS')
			],
			valueSrc: values
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('document.fns_upd_5_02n.seller.actual.useful.life'),
			layout: {
				type: 'grid',
				area: [10]
			},
			cls: FIELD_BLOCK_CLS.small,
			items: sellerActualUsefulLife
		});
	}

	createProductCodeField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const productCode = (me.fields.productCode = createNumberField({
			allowBlank: true,
			mandatory: false,
			maxLength: 100,
			name: me.createFieldName('dopSvedTov.kodTov'),
			valueSrc: values,
			listeners: {
				change: () => me.validateDataProductField()
			}
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.productCode'),
			cls: FIELD_BLOCK_CLS.small,
			items: productCode
		});
	}

	createNumberShouldReleaseField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const numberShouldRelease = (me.fields.numberShouldRelease = createNumberField({
			allowDecimals: true,
			replaceComa: true,
			decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
			vtype: 'fnsNumberN26_11',
			name: me.createFieldName('dopSvedTov.nadlOtp'),
			valueSrc: values
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.numberShouldRelease'),
			cls: FIELD_BLOCK_CLS.small,
			items: numberShouldRelease
		});
	}

	createProductTypeField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const productType = (me.fields.productType = createCombo({
			store: edi.stores.createSimpleInlineStore(['1', '2', '3', '4', '5'], function (id: string) {
				return edi.i18n.getMessage('documents.fns_upd.product.type.' + id);
			}),
			name: me.createFieldName('dopSvedTov.prTovRab'),
			valueSrc: values
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.feature'),
			cls: FIELD_BLOCK_CLS.small,
			items: productType
		});
	}

	createFeatureAdditionalInfoField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const featureAdditionalInfo = (me.fields.featureAdditionalInfo = createTextField({
			maxLength: 4,
			name: me.createFieldName('dopSvedTov.dopPrizn'),
			valueSrc: values
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.featureAdditionalInfo'),
			cls: FIELD_BLOCK_CLS.small,
			items: featureAdditionalInfo
		});
	}

	createProductDescField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const productDesc = (me.fields.productDesc = createTextField({
			maxLength: 1000,
			isTextarea: true,
			rowsHtmlAttributeValue: 4,
			name: me.createFieldName('dopSvedTov.harakterTov'),
			valueSrc: values,
			listeners: {
				change: () => me.validateDataProductField()
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.productDesc'),
			cls: FIELD_BLOCK_CLS.small,
			items: productDesc
		});
	}

	createProductSortField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const productSort = (me.fields.productSort = createTextField({
			maxLength: 10,
			name: me.createFieldName('dopSvedTov.sortTov'),
			valueSrc: values
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.productSort'),
			cls: FIELD_BLOCK_CLS.small,
			items: productSort
		});
	}

	createProductReferenceField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const productReference = (me.fields.productReference = createTextField({
			maxLength: 50,
			name: me.createFieldName('dopSvedTov.artikulTov'),
			valueSrc: values,
			listeners: {
				change: () => me.validateDataProductField()
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.productReference'),
			cls: FIELD_BLOCK_CLS.small,
			items: productReference
		});
	}

	createProductSeriesField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const productSeries = (me.fields.productSeries = createTextField({
			maxLength: 50,
			name: me.createFieldName('dopSvedTov.seriyaTov'),
			valueSrc: values
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.productSeries'),
			cls: FIELD_BLOCK_CLS.small,
			items: productSeries
		});
	}

	createGlobalTradeItemNumberField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		// Для указания сведений о маркированном товаре в ОСУ необходимо использовать тег <ГТИН> (gtin) и <КолВедМарк> (kolVedMark)
		// "Код ГТИН" обязателен, если в гриде "Номера средств идентификации" есть хотя бы одна запись с kolVedMark
		const isGtinMandatory = () => {
			const identifyProductToolNumbersRecords: ExtRecord<UPD502productIdentifyRecordData>[] =
				me.grids.identifyProductToolNumbers?.getStore().getRange() ?? [];
			const isGtinMandatory = identifyProductToolNumbersRecords.some((record) => {
				const { kolVedMark, numbers } = record.getData();
				return kolVedMark && !numbers?.length;
			});
			return isGtinMandatory;
		};

		const validateIdentifyProductToolNumbersGrid = () => me.grids.identifyProductToolNumbers.getView().refresh();
		// Чтобы не дергать валидацию слишком часто
		const bufferedValidateIdentProdToolNumGrid = Ext.Function.createBuffered(
			validateIdentifyProductToolNumbersGrid,
			800
		);

		const globalTradeItemNumber = (me.fields.globalTradeItemNumber = createTextField({
			name: me.createFieldName('dopSvedTov.gtin'),
			maxLength: 14,
			minLength: 14,
			valueSrc: values,
			isGtinMandatory: () => {
				globalTradeItemNumber.allowBlank = !isGtinMandatory();
			},
			listeners: {
				change: (field: ExtComponent, value: string) => {
					field.isGtinMandatory();
					bufferedValidateIdentProdToolNumGrid();
				},
				afterrender: (field: ExtComponent) => {
					field.allowBlank = !isGtinMandatory();
					field.isValid();
				}
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.globalTradeItemNumber'),
			cls: FIELD_BLOCK_CLS.small,
			items: globalTradeItemNumber
		});
	}

	createCatalogCodeField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const catalogCode = (me.fields.catalogCode = createTextField({
			minLength: 27,
			maxLength: 27,
			name: me.createFieldName('dopSvedTov.kodKat'),
			valueSrc: values
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.catalogCode'),
			cls: FIELD_BLOCK_CLS.small,
			items: catalogCode
		});
	}

	createProductTypeCodeField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const productTypeCode = (me.fields.productTypeCode = createTextField({
			minLength: 10,
			maxLength: 10,
			name: me.createFieldName('dopSvedTov.kodVidTov'),
			valueSrc: values
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.productTypeCode'),
			cls: FIELD_BLOCK_CLS.small,
			items: productTypeCode
		});
	}

	createOkdpCode2Field({
		values,
		fieldConfig = {},
		fieldBlockConfig = {}
	}: { values?: SvedTov; fieldConfig?: AnyObject; fieldBlockConfig?: AnyObject } = {}): ExtComponent {
		const me = this;

		const okdpCode2 = (me.fields.okdpCode2 = createTextField(
			Object.assign(
				{
					minLength: 2,
					maxLength: 12,
					name: me.createFieldName('dopSvedTov.kodTovOKDP2'),
					valueSrc: values
				},
				fieldConfig
			)
		));

		return createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('line.item.additional.okdpCode2'),
					cls: FIELD_BLOCK_CLS.small,
					items: okdpCode2
				},
				fieldBlockConfig
			)
		);
	}

	createProductClassificationCode({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const productClassificationCode = (me.fields.productClassificationCode = createCombo({
			store: edi.stores.createSimpleInlineStore(
				[630, 640, 650, 665, 671, 672, 673, 674, 676, 677, 680, 681],
				(id: string) =>
					`com.edisoft:general_ui:war:1.0.19.2-RC7 - ${edi.i18n.getMessage('documents.fns_upd_5_02n.productClassificationCode.' + id)}`,
				true
			),
			name: me.createFieldName('dopSvedTov.kodVidPr'),
			valueSrc: values
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.productClassificationCode'),
			cls: FIELD_BLOCK_CLS.small,
			items: productClassificationCode
		});
	}

	createAdditionalOperationInformationField({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const additionalOperationInformation = (me.fields.additionalOperationInformation = createCombo({
			store: edi.stores.createInlineStore(scCodes, 'AUTHORITY_AREA'),
			valueSrc: values,
			name: me.createFieldName('dopSvedTov.dopInfPVidO')
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.additionalOperationInformation'),
			cls: FIELD_BLOCK_CLS.small,
			layout: {
				type: 'grid',
				area: [4]
			},
			items: additionalOperationInformation
		});
	}

	createTrackableItemInfoGridFields(): AnyObject {
		const me = this;
		return {
			nomTovProslezh: {
				order: 10,
				title: edi.i18n.getMessage('line.item.infoTracking.trackingProdNum'),
				name: 'nomTovProslezh',
				type: 'text',
				regex: /^[\d]{8}[\/](0[1-9]|[12][\d]|3[01])(0[1-9]|1[012])\d{2}[\/][a-zA-ZА-Яа-яёЁ\d]{7}[\/][\d]{3}$/,
				invalidText: edi.i18n.getMessage('line.item.infoTracking.trackingProdNum.format'),
				allowBlank: false
			},
			edIzmProslezh: {
				order: 20,
				store: edi.stores.initOkeiStore(),
				title: edi.i18n.getMessage('line.item.infoTracking.trackingUnitMes'),
				name: 'edIzmProslezh',
				type: 'combo',
				forceSelection: true,
				anyMatch: true,
				valueField: 'id',
				displayField: 'name_code',
				allowBlank: false,
				renderer(val: string, meta: AnyObject, rec: ExtRecord<AnyObject>) {
					return rec.get('naimEdIzmProslezh') || val;
				}
			},
			kolVEdProslezh: {
				order: 30,
				title: edi.i18n.getMessage('line.item.infoTracking.trackingProdQuan'),
				type: 'number',
				allowBlank: false,
				name: 'kolVEdProslezh',
				allowDecimals: true,
				replaceComa: true,
				decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
				vtype: 'fnsNumberN26_11'
			},
			stTovBezNDSProslezh: {
				order: 40,
				type: 'fieldblock',
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('line.item.infoTracking.priceExcludingVat'),
				renderer(val: string, meta: AnyObject, rec: ExtRecord<AnyObject>) {
					return rec.get('stTovBezNDSProslezh') || val;
				},
				plugins: [
					createInfoElementPlugin({
						hintConfig: {
							maxWidth: 200,
							items: [
								createLabel({
									style: {
										textAlign: 'center'
									},
									typography: 'caption_01',
									text: edi.i18n.getMessage('line.item.infoTracking.priceExcludingVat.info')
								})
							]
						},
						refEl: 'titleEl'
					})
				],
				createCustomItems: function (
					_conf: AnyObject,
					values: AnyObject,
					{ readOnly, disabled }: { readOnly: boolean; disabled: boolean }
				) {
					return [
						createNumberField({
							type: 'number',
							valueSrc: values,
							readOnly,
							disabled,
							name: 'stTovBezNDSProslezh',
							allowBlank: false,
							replaceComa: true,
							maxLength: 19
						})
					];
				}
			},
			dopInfProslezh: {
				order: 50,
				title: edi.i18n.getMessage('line.item.infoTracking.trackingAdd'),
				type: 'text',
				name: 'dopInfProslezh',
				maxLength: 255
			}
		};
	}

	createTrackableItemInfoGrid({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;

		const name = 'dopSvedTov.svedProslezh';
		const fields = me.createTrackableItemInfoGridFields();
		return (me.grids.trackableItemInfo = createEditableGrid(
			{
				modalWidth: MODAL_SIZE.widthSmall,
				modalTitle: edi.i18n.getMessage('line.item.infoTracking'),
				layout: {
					type: 'grid',
					gap: 24
				},
				readOnly: me.props.readOnly,
				fields: Object.values(fields).sort((f1, f2) => f1.order - f2.order),
				viewConfig: {
					getRowClass: function (record: ExtRecord<SvedProslezh>) {
						const fieldsConfig = fields;
						let isValidRecord = true,
							i,
							fieldValue,
							allowEmptyValue;

						for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
							fieldValue = record.get(fieldsConfig[i].name);

							//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
							allowEmptyValue =
								!fieldsConfig[i].hasOwnProperty('allowBlank') || fieldsConfig[i].allowBlank;
							if (!allowEmptyValue && !fieldValue) {
								isValidRecord = false;
							}
						}
						return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
					}
				},
				limit: 20,
				autoScroll: true,
				model: UPD_5_02_PRODUCT_INFO_TRACKING_MODEL,
				recordsUniqueByField: 'trackingProdNum',
				duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
				beforeAdd: function (callback: Function) {
					return me.grids.identifyProductToolNumbers?.getStore().getCount() > 0
						? edi.core.showError(
								edi.i18n.getMessage('document.warning.errorNomSredIdentTovAndSvedProslezh')
						  )
						: callback();
				},
				gridConfig: {
					cls: 'modal-grid',
					name
				}
			},
			edi.utils.getObjectProperty(values, name, true)
		) as ExtComponent);
	}

	createCustomsDeclarationInfoGridFields() {
		const me = this;
		const { spObstFSCHFValue, spObstFDOP, updFunction } = me.props.getCurrentUPDState();
		const { isDOP, isSCHF, isSCHFDOP } = calculateUpdBusinessProcess(updFunction);
		const isSpObstFSCHF2 = spObstFSCHFValue === '2';
		//При <Функция> = СЧФ | СЧФДОП элемент обязателен, кроме случая, когда <СпОбстФСЧФ> = 2
		const allowBlank = !((isSCHF || isSCHFDOP) && !isSpObstFSCHF2);
		return [
			{
				title: 'line.item.country.of.origin',
				name: 'kodProish',
				type: 'combo',
				store: edi.stores.initValidCountryFullStore(),
				anyMatch: true,
				valueField: 'iso_number_3',
				displayField: 'display_name',
				allowBlank
			},
			{
				title: 'column.nomer.td',
				name: 'nomerDT',
				type: 'text',
				disableIfReadonly: false,
				minLength: 1,
				maxLength: 29,
				allowBlank,
				readOnly: function () {
					let result = false;
					// Если есть записи во вкладке "Сведения о прослеживаемости",
					//  то номер менять нельзя (уже всем выставлен "Прослеж")
					const trackingStore = me.grids.trackableItemInfo?.getStore();
					if (trackingStore && trackingStore.getCount() > 0) {
						result = true;
					}
					return result;
				}
			}
		];
	}
	createCustomsDeclarationInfoGrid({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const name = 'svDT';
		return (me.grids.customsDeclarationInfo = createEditableGrid(
			{
				columnsConfig: UPD_5_02_DECLARATION_INFO_COLUMN_CONFIG_NAME,
				layout: {
					type: 'grid',
					gap: 24
				},
				readOnly: me.props.readOnly,
				fields: me.createCustomsDeclarationInfoGridFields(),
				limit: undefined,
				modalTitle: edi.i18n.getMessage('line.item.tab.sv.td'),
				model: UPD_5_02_DECLARATION_INFORMATION_MODEL,
				modalWidth: MODAL_SIZE.widthSmall,
				beforeAdd: function (callback: Function) {
					if (me.grids.trackableItemInfo?.getStore().getCount() > 0) {
						const initialFieldsData = {
							nomerDT: edi.i18n.getMessage('line.item.tab.sv.td.number.value')
						};
						callback(undefined, undefined, { initialFieldsData });
					} else {
						callback();
					}
				},
				canAddRow: function () {
					return false; // это означает "можно добавлять декларации всегда"
				},
				canDeleteRow: function () {
					let result = true;
					const trackingStore = me.grids.trackableItemInfo?.getStore();
					const declarationStore = me.grids.customsDeclarationInfo.getStore();
					// Если есть "сведения о прослеживаемости",
					//  то можно удалять все декларации, кроме одной
					if (
						trackingStore &&
						declarationStore &&
						declarationStore.getCount() < 2 &&
						trackingStore.getCount() > 0
					) {
						result = false;
					}
					return result;
				},
				gridConfig: {
					cls: 'modal-grid',
					name
				}
			},
			values?.[name] ?? []
		) as ExtComponent);
	}

	extractSortAccounting(sortAccountingNumber: string): {
		isSortAccounting: boolean;
		gtin?: string;
		kolVedMark?: number;
	} {
		//ОСУ = [02]GTIN[37]kolVedMark
		const isSortAccounting = !!sortAccountingNumber?.match(/02[0-9]{14}37[0-9]*$/);
		const gtin = isSortAccounting ? sortAccountingNumber.slice(2, 16) : undefined;
		const kolVedMark = isSortAccounting ? +sortAccountingNumber.slice(18) : undefined;

		return { isSortAccounting, gtin, kolVedMark };
	}

	createIdentifyProductToolNumbersGridFields(): AnyObject[] {
		const me = this;

		const renderUseSortAccounting = function (comp: ExtComponent) {
			comp.getFieldBlock().setVisible(false);
		};

		const renderIdentifyProductToolNumbers = function (comp: ExtComponent) {
			let formValues = comp.up('form')?.getValues();
			edi.utils.clearEmptyValues(formValues);
			if (Object.keys(formValues).length === 0) {
				comp.getFieldBlock().setVisible(true);
				return null;
			}
		};

		const isSortAccounting = function (form: ExtComponent) {
			const value = Ext.clone(form.getValues());
			const mainGtin = me.fields.globalTradeItemNumber.getValue();
			const isUseMainGtin = mainGtin && value.GTIN && mainGtin === value.GTIN;

			const {
				isSortAccounting = false,
				gtin: extractedGtin = undefined,
				kolVedMark: extractedKolVedMark = undefined
			} = value?.numbers?.length === 1 ? me.extractSortAccounting(value?.numbers[0]) : {};
			const isSortAccountingNumber = isUseMainGtin || isSortAccounting;

			if (isSortAccountingNumber) {
				form.down('[itemId="sortAccounting"]')?.setValue(!!isSortAccountingNumber);

				const gtin = isUseMainGtin ? value.GTIN : extractedGtin;
				const kolVedMark = isUseMainGtin ? value.kolVedMark : extractedKolVedMark;
				form.down('[name="GTIN"]')?.setValue(gtin);
				form.down('[name="kolVedMark"]')?.setValue(kolVedMark);
			}
		};

		const checkExistSortAccounting = function () {
			const editableGrid = me.grids.identifyProductToolNumbers;
			const gridValues = Ext.clone(editableGrid?.getStore()?.getRange()) ?? [];
			const mainGtin = me.fields.globalTradeItemNumber.getValue();

			return !!gridValues.find((identifyProductToolNumber: ExtRecord<UPD502productIdentifyRecordData>) => {
				const { numbers = [], kolVedMark } = identifyProductToolNumber.getData();
				const gtin = identifyProductToolNumber.get('GTIN') as UPD502productIdentifyRecordData['GTIN'];
				return (
					(numbers.length === 1 && !!numbers[0].match(/02[0-9]{14}37[0-9]*$/)) ||
					(mainGtin && gtin && mainGtin === gtin && kolVedMark)
				);
			});
		};

		const setNumbersField = function (field: ExtComponent, value: string, itemId: string) {
			const mainGtin = me.fields.globalTradeItemNumber.getValue();
			const form = field.up('panel'),
				numbersField = form && form.down ? form.down('[itemId="numbers"]') : null,
				itemIdField = form && form.down ? form.down(`[itemId="${itemId}"]`) : null,
				gtin = itemId === 'purchaseQuantity' ? value : itemIdField?.getValue(),
				purchaseQuantity = itemId === 'purchaseQuantity' ? itemIdField?.getValue() : value;
			const isUseMainGtin = mainGtin === gtin;

			if (!gtin || !purchaseQuantity || isUseMainGtin) {
				numbersField?.setValue([]);
				const gridRecord = numbersField.getEditableGridRecord();
				// Нужно для кейса, когда старый код ОСУ редактируется в новый, т.е. указывается "GTIN" такой-же как "Код ГТИН"
				// в этом случае нужно обнулить nomUpak, иначе при сете рекорда (handler в EditableGrid) вызывается convert из модели (для поля numbers)
				// и в поле numbers берется значение из nomUpak
				if (gridRecord) gridRecord.set('nomUpak', []);
				return;
			}

			numbersField?.setValue(['02' + gtin + '37' + purchaseQuantity]);
		};

		return [
			{
				title: null,
				boxLabel: edi.i18n.getMessage('line.item.identifyProductToolNumbers.use.sort.accounting'),
				itemId: 'sortAccounting',
				type: 'switch',
				submitValue: false,
				value: false,
				inputValue: true,
				listeners: {
					render: renderIdentifyProductToolNumbers,
					change: function (field: ExtComponent, useSortAccounting: boolean) {
						const form = me.grids.identifyProductToolNumbers.getModalForm();
						const fields = edi.utils.getFormFields(form);

						const clearForm = function () {
							fields.kolVedMark.setValue(null);

							const gtin = me.fields.globalTradeItemNumber.getValue();
							fields.GTIN.setValue(gtin || null);
							fields.identTransUpak.setValue(null);
							fields.type.setValue(null);
							fields.numbers.setValue([]);
						};

						const setForm = function () {
							fields.identTransUpak.getFieldBlock().setVisible(!useSortAccounting);
							fields.identTransUpak.allowBlank = useSortAccounting;
							fields.type.setValue(useSortAccounting ? 'packNumber' : null);
							useSortAccounting ? (fields.numbers.allowBlank = useSortAccounting) : null;
							fields.type.setReadOnly(field.getValue());
							fields.numbers.requiredFields = useSortAccounting ? [] : ['type'];
							fields.type.requiredFields = useSortAccounting ? [] : ['numbers'];
							fields.numbers.getFieldBlock().setVisible(!useSortAccounting);
							fields.kolVedMark.getFieldBlock().setVisible(useSortAccounting);
							fields.kolVedMark.allowBlank = !useSortAccounting;
							fields.GTIN.getFieldBlock().setVisible(useSortAccounting);
							fields.GTIN.allowBlank = !useSortAccounting;
							fields.prPartMark.getFieldBlock().setVisible(!useSortAccounting);
						};

						clearForm();
						setForm();
						form.isValid();
					},
					afterrender: function (checkbox: ExtComponent) {
						checkbox.up('form')?.on('afterrender', (form: ExtComponent) => {
							isSortAccounting(form);
							const isSortAccountingAlreadyExist = checkExistSortAccounting();
							//Чекбокс нельзя установить если уже есть хотя бы один код ОСУ, но можно снять там где он проставлен
							checkbox.setReadOnly(checkbox.getValue() ? false : !!isSortAccountingAlreadyExist);
						});
					}
				}
			},
			{
				title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
				//name: me.createFieldName('dopSvedTov.svedProslezh.kolVEdProslezh'),
				name: 'identTransUpak',
				itemId: 'packId',
				type: 'text',
				allowBlank: false,
				renderer: 'desadvLineEAN',
				maxLength: 255,
				listeners: {
					render: renderIdentifyProductToolNumbers
				}
			},
			{
				store: edi.stores.createSimpleInlineStore(
					['kiz', 'packNumber'],
					function (id: string) {
						return edi.i18n.getMessage('line.item.identifyProductToolNumbers.' + id);
					},
					true
				),
				displayField: 'name',
				valueField: 'id',
				type: 'combo',
				forceSelection: true,
				valueInitialize: false,
				requiredFields: ['numbers'],
				title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
				itemId: 'type',
				name: 'type',
				listeners: {
					change: function (field: ExtComponent, value: string) {
						const form = me.grids.identifyProductToolNumbers.getModalForm();
						if (!form?.rendered) return;

						const packId = form.down('[name=identTransUpak]');
						if (packId) {
							packId.allowBlank = !!value;
							packId.isValid();
						}
					}
				}
			},
			{
				title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.numbers'),
				requiredFields: ['type'],
				name: 'numbers',
				itemId: 'numbers',
				type: 'tagTextField',
				delimiter: '[\\s]',
				emptyTextKey: 'uikit.tagtextfield.empty.withoutComma'
			},
			{
				title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.purchase.quantity'),
				itemId: 'purchaseQuantity',
				name: 'kolVedMark',
				type: 'number',
				listeners: {
					change: function (field: ExtComponent, value: string) {
						const form = me.grids.identifyProductToolNumbers?.getModalForm();
						const sortAccounting = form.down('[itemId=sortAccounting]');

						if (sortAccounting?.getValue()) setNumbersField(field, value, 'GTIN');
					},
					render: renderUseSortAccounting
				}
			},
			{
				title: edi.i18n.getMessage('line.item.additional.batchCode'),
				itemId: 'batchCode',
				name: 'prPartMark',
				maxLength: 55,
				type: 'text'
			},
			{
				title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.GTIN'),
				itemId: 'GTIN',
				name: 'GTIN',
				type: 'text',
				listeners: {
					change: function (field: ExtComponent, value: string) {
						setNumbersField(field, value, 'purchaseQuantity');
					},
					render: renderUseSortAccounting,
					afterrender: function (field: ExtComponent) {
						const form = me.grids.identifyProductToolNumbers.getModalForm();
						const mainGtin = me.fields.globalTradeItemNumber.getValue();
						const kolVedMark = form.down('[itemId=purchaseQuantity]');
						const gtin = field.getValue();
						if (mainGtin && !gtin && kolVedMark?.getValue()) {
							field.setValue(mainGtin);
						}
					}
				}
			}
		];
	}

	createIdentifyProductToolNumbersGrid({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const name = 'dopSvedTov.nomSredIdentTov';
		const fields = me.createIdentifyProductToolNumbersGridFields();
		return (me.grids.identifyProductToolNumbers = createEditableGrid(
			{
				modalWidth: MODAL_SIZE.widthSmall,
				modalTitle: edi.i18n.getMessage('line.item.identifyProductToolNumbers'),
				columnsConfig: UPD_5_02_IDENTITY_PRODUCT_TOOL_NUMBERS_COLUMN_CONFIG_NAME,
				layout: {
					type: 'grid',
					gap: 24
				},
				readOnly: me.props.readOnly,
				fields,
				isRowValid: function (record: ExtRecord<NomSredIdentTov>) {
					const mainGtin = me.fields.globalTradeItemNumber.getValue();
					const { type, numbers, kiz, kolVedMark, identTransUpak } = record.getData();
					//Должен присутствовать хотя бы один из элементов ИдентТрансУпак, КолВедМарк (в связке с ГТИН), КИЗ, НомУпак
					if (!numbers?.length && !kiz?.length && !(kolVedMark && mainGtin) && !identTransUpak) {
						return false;
					}

					return true;
				},
				limit: 20,
				autoScroll: true,
				model: UPD_5_02_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS_MODEL,
				recordsUniqueByField: 'identTransUpak',
				duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
				beforeAdd: function (callback: Function) {
					return me.grids.trackableItemInfo?.getStore().getCount() > 0
						? edi.core.showError(
								edi.i18n.getMessage('document.warning.errorNomSredIdentTovAndSvedProslezh')
						  )
						: callback();
				},
				afterEditFunction: function () {
					const gtinField = me.fields.globalTradeItemNumber;
					if (typeof gtinField.isGtinMandatory === 'function') {
						gtinField.isGtinMandatory();
						gtinField.isValid();
					}
				},
				gridConfig: {
					cls: 'modal-grid',
					name
				},
				storeConfig: {
					remoteSort: false
				}
			},
			edi.utils.getObjectProperty(values, name, true)
		) as ExtComponent);
	}

	createNameOfGovernmentInformationSystemField({ values }: { values?: SvGosSist } = {}): ExtComponent {
		const me = this;
		const nameOfGovernmentInformationSystem = (me.fields.nameOfGovernmentInformationSystem = createTextField({
			allowBlank: false,
			maxLength: 255,
			valueSrc: values,
			name: 'naimGosSist'
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.nameOfGovernmentInformationSystem'),
			cls: FIELD_BLOCK_CLS.small,
			items: [nameOfGovernmentInformationSystem]
		});
	}

	createIdentificationNumberForUnitField({ values }: { values?: SvGosSist } = {}): ExtComponent {
		const me = this;
		const identificationNumberForUnit = (me.fields.identificationNumberForUnit = createTagTextField({
			delimiter: '[\\s]',
			emptyTextKey: 'uikit.tagtextfield.empty.withoutComma',
			name: 'idNomUchetEd',
			requiredFields: ['naimGosSist'],
			valueSrc: values
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.identificationNumberForUnit'),
			cls: FIELD_BLOCK_CLS.small,
			items: [identificationNumberForUnit]
		});
	}

	createAccountingEntityField({ values }: { values?: SvGosSist } = {}): ExtComponent {
		const me = this;
		const accountingEntity = (me.fields.accountingEntity = createTextField({
			maxLength: 255,
			valueSrc: values,
			requiredFields: ['naimGosSist'],
			name: 'uchetEd'
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.accountingEntity'),
			cls: FIELD_BLOCK_CLS.small,
			items: [accountingEntity]
		});
	}

	createOperationSpecificDetailsField({ values }: { values?: SvGosSist } = {}): ExtComponent {
		const me = this;
		const operationSpecificDetails = (me.fields.operationSpecificDetails = createTextField({
			maxLength: 1000,
			isTextarea: true,
			rowsHtmlAttributeValue: 4,
			valueSrc: values,
			requiredFields: ['naimGosSist'],
			name: 'inayaInf'
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('line.item.additional.additionalOperationInformation'),
			cls: FIELD_BLOCK_CLS.small,
			items: [operationSpecificDetails]
		});
	}

	createInformationTrackedItemBlockConstructor(values: SvGosSist) {
		const me = this;
		return createEditableBlock({
			values,
			firstEdit: true,
			allowBlank: true,
			readOnly: me.props.readOnly,
			actionsConfig: {
				approve: {
					//Костыль чтобы кнопка approve не появлялась
					setVisible: () => {}
				}
			},
			getItems: (editableBlock: ExtComponent, formValues: SvGosSist) => {
				return createContainer({
					layout: {
						type: 'grid',
						area: [12, 8, [6, 4], 6]
					},
					items: [
						createLabel({
							typography: 'heading_01',
							text: edi.i18n.getMessage('documents.fns_upd_5_02n.additional.information.tracked.item')
						}),
						me.createNameOfGovernmentInformationSystemField({ values: formValues }),
						me.createIdentificationNumberForUnitField({ values: formValues }),
						me.createAccountingEntityField({ values: formValues }),
						me.createOperationSpecificDetailsField({ values: formValues })
					]
				});
			},
			createView: (values: SvGosSist) =>
				createLabelBlockForDetails({
					contents: [
						{
							typography: 'heading_01',
							text: edi.i18n.getMessage('documents.fns_upd_5_02n.additional.information.tracked.item')
						},
						{
							title: edi.i18n.getMessage('line.item.additional.nameOfGovernmentInformationSystem'),
							text: values.naimGosSist,
							isVertical: true,
							isNewLine: true
						},
						{
							title: edi.i18n.getMessage('line.item.additional.identificationNumberForUnit'),
							text: values.idNomUchetEd?.join(', '),
							isVertical: true,
							isNewLine: true
						},
						{
							title: edi.i18n.getMessage('line.item.additional.accountingEntity'),
							text: values.uchetEd,
							isVertical: true,
							isNewLine: true
						}
					]
				})
		});
	}

	createInformationTrackedItemBlock({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const data = values?.dopSvedTov?.svGosSist;
		const rowPanel = (me.blocks.trackedItem = createRowsBlock({
			createRow: (rowData: SvGosSist, isFirstRow: boolean) =>
				me.createInformationTrackedItemBlockConstructor(rowData),
			//При открытии окна сразу должно быть доступна 1 строка
			initialData: data ?? [null],
			addRowBtnConf: {
				hidden: me.props.readOnly,
				text: edi.i18n.getMessage('documents.fns_upd_5_02n.additional.information.tracked.item.add')
			}
		}));
		//TODO избавиться от HiddenField
		const valueField = createHiddenField({
			name: 'dopSvedTov.svGosSist',
			getRawValue: function () {
				if (!rowPanel?.rendered) return;
				//храним значение всех строк RowsBlock внутри HiddenField
				const rows = rowPanel.getRows();
				return rows.map((row: ExtComponent) => row.getValues());
			}
		});
		return createContainer({
			items: [rowPanel, valueField]
		}) as ExtComponent;
	}

	createTextInfosGridFields(): AnyObject[] {
		const me = this;

		const renderer = (value: string, meta: AnyObject, record: ExtRecord<TekstInf>) => {
			const id = record.get('identif');

			const { orgProductFields = [] } = me.props.getCurrentUPDState();

			const textInfosFromOrg = orgProductFields.find((field) => field.identifier === id);
			if (textInfosFromOrg?.infoFieldTooltip) {
				meta.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(textInfosFromOrg.infoFieldTooltip) + '"';
			}
			return value;
		};
		return [
			{
				name: 'identif',
				type: 'text',
				minLength: 1,
				maxLength: 50,
				allowBlank: false,
				disableIfReadonly: false,
				readOnly: function (record: ExtRecord<TekstInf>) {
					const { orgProductFields } = me.props.getCurrentUPDState();

					if (record) {
						const data = record.getData();
						if (data && data.identif) {
							if (
								orgProductFields.find(function (product) {
									return product.identifier === data.identif;
								})
							) {
								return true;
							}
						}
					}
					return false;
				},
				renderer
			},
			{
				name: 'znachen',
				type: 'text',
				minLength: 1,
				maxLength: 200,
				allowBlank: false,
				renderer
			}
		];
	}

	createTextInfosGrid({ values }: { values?: SvedTov } = {}): ExtComponent {
		const me = this;
		const name = 'infPolFHZH2';
		return (me.grids.textInfos = createEditableGrid(
			{
				modalTitle: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
				limit: 20,
				layout: {
					type: 'grid',
					gap: 24
				},
				readOnly: me.props.readOnly,
				modalWidth: MODAL_SIZE.widthSmall,
				autoScroll: true,
				model: UPD_5_02_PRODUCT_TEXT_INFO_MODEL,
				recordsUniqueByField: 'identif',
				duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
				fields: me.createTextInfosGridFields(),
				storeConfig: {
					filters: [
						function (textInfo: ExtRecord<TekstInf>) {
							return textInfo.get('identif') !== me.textInfosUnitGrossPriceIdentifier;
						}
					]
				},
				gridConfig: {
					cls: 'modal-grid',
					name
				},
				viewConfig: {
					getRowClass: function (record: ExtRecord<TekstInf>) {
						let isValidRecord = true;

						const id = record.get('identif');
						const value = record.get('znachen');

						const { orgProductFields = [] } = me.props.getCurrentUPDState();

						const textInfosFromOrg = orgProductFields.find((field) => field.identifier === id);
						if (textInfosFromOrg) {
							isValidRecord = textInfosFromOrg.allowEmptyValue || !!value;
						} else {
							isValidRecord = !!id && !!value;
						}

						return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
					}
				}
			},
			edi.utils.getObjectProperty(values, name, true)
		) as ExtComponent);
	}

	createProductGridProductTab({ values }: { values: SvedTov }): ExtComponent {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('line.item.tab.product'),
			closable: false,
			autoScroll: true,
			layout: 'auto',
			items: createContainer({
				padding: 24,
				layout: {
					type: 'grid',
					gap: 16,
					area: [6, [3, 5], 12, 12]
				},
				disabled: me.props.readOnly,
				items: [
					me.createProductNameField({ values }),
					me.createProductQuantityField({ values }),
					me.createOkeiField({ values }),
					me.createUnitOfMeasureField({ values }),
					me.createAccompanyingDocumentBlock({ values })
				]
			})
		}) as ExtComponent;
	}

	createProductGridTaxAndPriceTab({ values }: { values: SvedTov }): ExtComponent {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
			closable: false,
			autoScroll: true,
			layout: 'auto',
			items: createContainer({
				padding: 24,
				layout: {
					type: 'grid',
					gap: 16,
					area: [4, [3, 3, 3], 3, [3, 3, 3], 12]
				},
				disabled: me.props.readOnly,
				items: [
					me.createTaxCalculationMethodField(),
					me.createUnitNetPriceField({ values }),
					me.createUnitGrossPriceField({ values }),
					me.createTaxRateField({ values }),
					me.createSumExciseField({ values }),
					me.createCostWithoutNDSField({ values }),
					me.createTaxSumField({ values }),
					me.createGrossAmountField({ values }),
					createFieldSet({
						title: edi.i18n.getMessage(
							'documents.fns_upd_5_02n.additional.tax.data.for.deprecation.calculation'
						),
						collapsible: false,
						cls: FIELD_SET_CLS.card,
						layout: {
							type: 'grid',
							area: [
								[3, 4],
								[4, 6]
							]
						},
						items: [
							me.createDepreciationGroupField({ values }),
							me.createFixedAssetCodeField({ values }),
							me.createSellerEstimatedUsefulLifeField({ values }),
							me.createSellerActualUsefulLifeField({ values })
						]
					})
				]
			})
		}) as ExtComponent;
	}

	createProductGridAdditionalTab({ values }: { values: SvedTov }): ExtComponent {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('line.item.tab.additional.data'),
			closable: false,
			autoScroll: true,
			layout: 'auto',
			items: createContainer({
				padding: 24,
				layout: {
					type: 'grid',
					gap: 16,
					area: [6, 6, [4, 6], 6, [4, 4, 4], [4, 4, 4], [4, 4], 12]
				},
				disabled: me.props.readOnly,
				items: [
					me.createProductCodeField({ values }),
					me.createNumberShouldReleaseField({ values }),
					me.createProductTypeField({ values }),
					me.createFeatureAdditionalInfoField({ values }),
					me.createProductDescField({ values }),
					me.createProductSortField({ values }),
					me.createProductReferenceField({ values }),
					me.createProductSeriesField({ values }),
					me.createGlobalTradeItemNumberField({ values }),
					me.createCatalogCodeField({ values }),
					me.createProductTypeCodeField({ values }),
					me.createOkdpCode2Field({ values }),
					me.createProductClassificationCode({ values }),
					me.createAdditionalOperationInformationField({ values })
				]
			})
		}) as ExtComponent;
	}

	createProductGridSvTdTab({ values }: { values: SvedTov }): ExtComponent {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('line.item.tab.sv.td'),
			closable: false,
			autoScroll: true,
			layout: 'auto',
			items: createContainer({
				padding: 24,
				items: me.createCustomsDeclarationInfoGrid({ values })
			})
		}) as ExtComponent;
	}

	createProductGridInfoTrackingTab({ values }: { values: SvedTov }): ExtComponent {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('line.item.infoTracking'),
			closable: false,
			autoScroll: true,
			layout: 'auto',
			items: createContainer({
				padding: 24,
				items: me.createTrackableItemInfoGrid({ values })
			})
		}) as ExtComponent;
	}

	createProductGridIdentifyProductToolNumbersTab({ values }: { values: SvedTov }): ExtComponent {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('line.item.identifyProductToolNumbers'),
			closable: false,
			autoScroll: true,
			layout: 'auto',
			items: createContainer({
				padding: 24,
				layout: {
					type: 'grid',
					gap: 24
				},
				items: [
					me.createIdentifyProductToolNumbersGrid({ values }),
					me.createInformationTrackedItemBlock({ values })
				]
			})
		}) as ExtComponent;
	}

	createProductGridTextInfosTab({ values }: { values: SvedTov }): ExtComponent {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
			closable: false,
			autoScroll: true,
			layout: 'auto',
			items: createContainer({
				padding: 24,
				items: me.createTextInfosGrid({ values })
			})
		}) as ExtComponent;
	}

	createProductGridModalForm(values: SvedTov): ExtComponent {
		const me = this;
		return createTabPanel({
			cls: TAB_PANEL_CLS.simpleWithoutPadding,
			activeTab: 0,
			items: [
				me.createProductGridProductTab({ values }),
				me.createProductGridTaxAndPriceTab({ values }),
				me.createProductGridAdditionalTab({ values }),
				me.createProductGridSvTdTab({ values }),
				me.createProductGridInfoTrackingTab({ values }),
				me.createProductGridIdentifyProductToolNumbersTab({ values }),
				me.createProductGridTextInfosTab({ values })
			],
			listeners: {
				afterrender: () => {
					me.validateDataProductField();
					me.validateProductFields();
					me.changeFieldsVisibility();
				}
			}
		}) as ExtComponent;
	}

	isValidProductRecord(record: ExtRecord<SvedTov>) {
		const me = this;
		const { orgProductFields, spObstFDOP, spObstFSCHFValue, updFunction } = me.props.getCurrentUPDState();
		const { isDOP, isSCHF, isSCHFDOP } = calculateUpdBusinessProcess(updFunction);

		const isSpObstFSCHF2 = spObstFSCHFValue === '2';

		const {
			naimTov,
			okeiTov,
			naimEdIzm,
			kolTov,
			stTovBezNDS,
			stTovUchNal,
			aktsiz,
			nalSt,
			tsenaTov,
			sumNal,
			dopSvedTov,
			infPolFHZH2 = []
		} = record.getData();
		const { harakterTov, artikulTov, kodTov, kodVidPr } = dopSvedTov ?? {};

		// При <Функция> = СЧФ | СЧФДОП элемент обязателен.
		if ((isSCHF || isSCHFDOP) && !naimTov) {
			return false;
		}
		// Для <Функция> = ДОП НаимТов обязателен при отсутствии элементов <ХарактерТов> и <АртикулТов> и <КодТов>.
		if (isDOP && !(harakterTov || artikulTov || kodTov) && !naimTov) {
			return false;
		}

		//Элемент обязателен при <Функция>=СЧФДОП | ДОП при наличии натурального измерителя факта хозяйственной жизни.
		const isOkeiMandatory = isSCHFDOP || isDOP;
		// Если в элементе указан ОКЕИ, то должно быть указано и название единицы измерения
		const okeiIsOk = okeiTov === '0000' ? !!naimEdIzm : !isOkeiMandatory || !!okeiTov;
		if (!okeiIsOk) return false;

		// Если в элементе указан ОКЕИ, то должно быть указано и количество товар
		if (okeiTov && !kolTov) {
			return false;
		}

		// КолТов обязателен при <Функция> = СЧФ | СЧФДОП, кроме случаев, когда <СпОбстФСЧФ > = 2.
		if ((isSCHF || isSCHFDOP) && !isSpObstFSCHF2 && !kolTov) {
			return false;
		}

		// СтТовБезНДС обязателен при <Функция> = СЧФ | СЧФДОП, кроме случаев, когда <СпОбстФСЧФ> = 2.
		if ((isSCHF || isSCHFDOP) && !isSpObstFSCHF2 && !stTovBezNDS) {
			return false;
		}

		// СтТовБезНДС обязателен при <Функция> = ДОП, если отсутствуют <СпОбстФДОП> и <СтТовУчНал>
		// СтТовУчНал обязателен при <Функция> = ДОП, если отсутствуют <СпОбстФДОП> и <СтТовБезНДС>
		if (isDOP && !spObstFDOP && !Ext.isNumber(stTovUchNal) && !Ext.isNumber(stTovBezNDS)) {
			return false;
		}

		// СтТовУчНал обязателен при <Функция> = СЧФ | СЧФДОП.
		if ((isSCHF || isSCHFDOP) && !Ext.isNumber(stTovUchNal)) {
			return false;
		}

		// Акциз обязателен при наличии <КодВидПр>
		if (Ext.isEmpty(aktsiz) && !kodVidPr) {
			return false;
		}

		// Для <Функция>=ДОП ХарактерТов обязателен при отсутствии элементов <НаимТов> и <КодТов> и <АртикулТов>.
		if (isDOP && !(kodTov || artikulTov || naimTov) && !harakterTov) {
			return false;
		}

		// Для <Функция> = ДОП АртикулТов обязателен при отсутствии <НаимТов> и <ХарактерТов> и <КодТов>
		if (isDOP && !(kodTov || harakterTov || naimTov) && !artikulTov) {
			return false;
		}

		// Для <Функция> = ДОП КодТов обязателен при отсутствии <НаимТов> и <ХарактерТов> и <АртикулТов>.
		if (isDOP && !(artikulTov || harakterTov || naimTov) && !kodTov) {
			return false;
		}

		// в XSD КолТов и ЦенаТов имеют minExclusive value="0", т.е. значение должно быть больше 0
		if ((Number.isFinite(kolTov) && kolTov! <= 0) || (Number.isFinite(tsenaTov) && tsenaTov! <= 0)) {
			return false;
		}

		const taxSumValid =
			('sumNal' in sumNal && sumNal.sumNal <= edi.utils.getMaxValueByLength(15)) ||
			('bezNDS' in sumNal && sumNal.bezNDS === edi.constants.taxRates.taxNoVat);
		const isValidTax = (nalSt == '0' || !!nalSt) && me.taxValueValid(nalSt) === true && taxSumValid;
		if (!isValidTax) return false;

		//Check info fields
		if (Array.isArray(infPolFHZH2)) {
			let isValidInfPolStr = true;
			for (let i = 0; i < infPolFHZH2.length && isValidInfPolStr; i++) {
				const info = infPolFHZH2[i];
				const textInfosFromOrg = orgProductFields.find((field) => field.identifier === info.identif);
				isValidInfPolStr = textInfosFromOrg
					? textInfosFromOrg.allowEmptyValue || !!info.znachen
					: !!(info.identif && info.znachen);
			}

			if (!isValidInfPolStr) return false;
		}

		return true;
	}

	selectProductHandler(product: AnyObject) {
		const me = this;

		const { orgProductFields } = me.props.getCurrentUPDState();

		if (product) {
			const grid = me.grids.textInfos,
				store = grid ? grid.getStore() : null;
			if (store) {
				store.each(function (record: ExtRecord<TekstInf>) {
					const recordData = record.getData();

					const textInfosFromOrg = orgProductFields.find((field) => field.identifier === recordData.identif);

					if (recordData && textInfosFromOrg?.valueSrc) {
						if (product.EAN && 'EANproduct' === textInfosFromOrg.valueSrc) {
							record.set('znachen', product.EAN);
						}
						if (product.BuyerItemCode && 'BuyerCode' === textInfosFromOrg.valueSrc) {
							record.set('znachen', product.BuyerItemCode);
						}
						if (product.SupplierItemCode && 'SupplierCode' === textInfosFromOrg.valueSrc) {
							record.set('znachen', product.SupplierItemCode);
						}
					}
				});
			}
		}
		const fields = me.productsGrid.getFormFields();
		const PackDetails = product.PackDetails ? product.PackDetails : {};
		const storeOkei = edi.stores.initOkeiStoreForN();
		const getOkeiRecord = function (field: string, value: string) {
			return storeOkei.findRecord(field, value, 0, false, false, true);
		};
		const okeiRecord =
			getOkeiRecord('name_international', product.UnitOfMeasure) ||
			getOkeiRecord('name', (product['UnitOfMeasure'] || '').toLowerCase());
		if (okeiRecord) {
			fields.okeiTov?.setValue(okeiRecord.get('id'));
			okeiRecord.get('id') !== '0000' && fields.naimEdIzm?.setValue(okeiRecord.get('name'));
		}

		fields.kolTov && fields.kolTov.getValue() < PackDetails.MinOrderedQuantity
			? fields.kolTov.setValue(PackDetails.MinOrderedQuantity)
			: null;
		fields.tsenaTov?.setValue(product.UnitNetPrice);
		fields.nalSt?.setValue(String(product.TaxRate));
		fields.naimTov?.setValue(product.ItemDescription);
		fields.stTovUchNal?.setValue(product.ItemDescriptionLong);
	}

	createProductGrid(): ExtComponent {
		const me = this;
		return (me.productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: UPD_5_02_LINE_MODEL,
			totalModel: UPD_5_02_SUMMARY_MODEL,
			readOnly: me.props.readOnly,
			allowBlank: !!me.props.readOnly,
			padding: '24 0 0 0',
			gridColumnConfig: UPD_5_02_LINES_COLUMN_CONFIG_NAME,
			totalColumnConfig: UPD_5_02_SUMMARY_COLUMN_CONFIG_NAME,
			lineNumberFieldName: 'nomStr',
			lockIfNoPartner: true,
			docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.02-N',
			data: me.props.data,
			docData: me.props.documentData,
			resetFormOnCatalogSelection: true,
			modalConf: {
				width: 860,
				height: 610
			},
			modalFormConfig: {
				title: 'document.upd.line'
			},
			afterRowEdit: function (values: AnyObject, field: string) {
				if (field === 'kolTov') {
					me.recordValueAfterEdit(values);
				}
			},
			createModalForm: (recordData: SvedTov) => me.createProductGridModalForm(recordData),
			checkValuesBeforeSave: (values: SvedTov) => {
				const trackedItemRows = me.blocks.trackedItem?.getRows() ?? [];
				//Дергаем сохранение строк в EditableBlock, чтобы с них можно было собрать значения
				trackedItemRows.forEach((row: ExtComponent) => row.actionApprove?.handler());
				return true;
			},
			changeValuesBeforeEdit: function (values: Upd5_02LineProductGridRecordData) {
				const textInfos = values.infPolFHZH2 || [];
				const textInfosUnitGrossPrice = textInfos.find(
					(textInfo) => textInfo.identif === me.textInfosUnitGrossPriceIdentifier && textInfo.znachen
				);
				if (textInfosUnitGrossPrice) {
					values[me.textInfosUnitGrossPriceIdentifier] = textInfosUnitGrossPrice.znachen;
				}
			},
			isValidRecord: me.isValidProductRecord.bind(me),
			changeValuesAfterEdit: function (values: Upd5_02LineProductGridRecordData) {
				if (Array.isArray(values.dopSvedTov?.nomSredIdentTov)) {
					values.dopSvedTov.nomSredIdentTov = values.dopSvedTov.nomSredIdentTov.map(
						({ type, numbers, identTransUpak, kolVedMark, prPartMark }) => {
							const mainGtin = values.dopSvedTov?.gtin;
							const {
								isSortAccounting = false,
								gtin = undefined,
								kolVedMark: extractedKolVedMark = undefined
							} = Array.isArray(numbers) && numbers[0] ? me.extractSortAccounting(numbers[0]) : {};
							const isUseMainGtin = isSortAccounting && mainGtin && gtin && mainGtin === gtin;
							// Если изначально было сохранено по старой логике ОСУ (в nomUpak), но затем был указан gtin
							// который совпал с GTIN из регулярки [02]GTIN[37]purchaseQuantity, т.е. используется новая логика (с kolVedMark)
							if (isUseMainGtin) {
								kolVedMark = extractedKolVedMark;
							}
							return {
								kiz: type === 'kiz' ? numbers : undefined,
								nomUpak: type === 'packNumber' && !isUseMainGtin ? numbers : undefined,
								identTransUpak,
								kolVedMark: !numbers?.length || isUseMainGtin ? kolVedMark : undefined,
								prPartMark
							};
						}
					);
				}

				if (Array.isArray(values.dopSvedTov?.svedProslezh)) {
					values.dopSvedTov.svedProslezh = values.dopSvedTov.svedProslezh.map((svedProslezh) => ({
						nomTovProslezh: svedProslezh.nomTovProslezh,
						edIzmProslezh: svedProslezh.edIzmProslezh,
						naimEdIzmProslezh: edi.stores
							.initOkeiStore()
							?.findRecord('id', svedProslezh.edIzmProslezh)
							?.get('name'),
						kolVEdProslezh: svedProslezh.kolVEdProslezh,
						stTovBezNDSProslezh: svedProslezh.stTovBezNDSProslezh,
						dopInfProslezh: svedProslezh.dopInfProslezh
					}));
				}

				if (values.okeiTov) {
					let okeiRecord = edi.stores.initOkeiStoreForN().getById(values.okeiTov);
					if (okeiRecord) {
						if (okeiRecord.get('id') != '0000') {
							values.naimEdIzm = okeiRecord.get('name');
						}
					} else {
						values.naimEdIzm = '';
					}
				} else {
					values.naimEdIzm = '';
				}

				if (
					values?.aktsiz &&
					'sumAktsiz' in values.aktsiz &&
					String(values.aktsiz.sumAktsiz).length &&
					String(values.aktsiz.sumAktsiz) !== 'null'
				) {
					values.aktsiz.sumAktsiz = +Number(values.aktsiz.sumAktsiz).toFixed(2);
				} else {
					edi.utils.setObjectProperty(values, 'aktsiz.bezAktsiz', 'без акциза');
				}

				if (edi.methods.taxRates.getNumberTypeRates('RUS', true).includes(values.nalSt)) {
					values.nalSt += '%';
				}

				if (values.nalSt === edi.constants.taxRates.taxNoVat) {
					values.sumNal = {
						bezNDS: values.nalSt
					};
				} else if ('sumNal' in values.sumNal) {
					values.sumNal.sumNal ||= 0;
				}

				if (values[me.textInfosUnitGrossPriceIdentifier]) {
					const textInfosUnitGrossPrice = {
						identif: me.textInfosUnitGrossPriceIdentifier,
						znachen: values[me.textInfosUnitGrossPriceIdentifier] as string
					};
					if (Array.isArray(values.infPolFHZH2)) {
						values.infPolFHZH2.push(textInfosUnitGrossPrice);
					} else {
						values.infPolFHZH2 = [textInfosUnitGrossPrice];
					}
					delete values[me.textInfosUnitGrossPriceIdentifier];
				}
			},
			selectProductHandler: me.selectProductHandler.bind(me),
			defaultValuesGetter: function () {
				const { orgProductFields } = me.props.getCurrentUPDState();

				return {
					infPolFHZH2: orgProductFields.map((field) => ({
						identif: field.identifier,
						znachen: field.value
					}))
				};
			}
		}));
	}
}

export const createDocLinesGrid = (props: DocLinesProps) => {
	const docLines = new DocLines(props);
	return docLines.createProductGrid();
};
