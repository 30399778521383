const RETANN_LINE_COLUMN = 'retann_lines';
const RETANN_SUMMARY_COLUMN = 'retann_summary';

edi.columns.addColumns({
	[RETANN_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 1
		},
		itemDescription: {
			text: 'line.item.product.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 2
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'column.unit.net.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitNetPrice',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 0.3
		},
		unitGrossPrice: {
			text: 'column.unit.gross.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitGrossPrice',
			flex: 1
		},
		netAmount: {
			text: 'column.net.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'NetAmount',
			flex: 1
		},
		taxAmount: {
			text: 'column.tax.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'TaxAmount',
			flex: 1
		},
		grossAmount: {
			text: 'column.gross.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'GrossAmount',
			flex: 1
		},
		receivedQuantity: {
			text: 'column.quantity.received',
			dataIndex: 'ReceivedQuantity',
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10',
				allowDecimals: true,
				decimalPrecision: 3,
				allowBlank: true,
				minValue: 1
			}
		},
		returnedQuantity: {
			text: 'column.quantity.returned',
			dataIndex: 'ReturnedQuantity',
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10WithZero',
				allowDecimals: true,
				decimalPrecision: 3,
				allowBlank: false,
				minValue: 1
			}
		}
	},
	[RETANN_SUMMARY_COLUMN]: {
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalReceivedAmount: {
			text: 'column.quantity.received',
			dataIndex: 'TotalReceivedAmount',
			menuDisabled: true,
			order: 0,
			flex: 1
		},
		totalReturnedAmount: {
			text: 'column.quantity.returned',
			dataIndex: 'TotalReturnedAmount',
			menuDisabled: true,
			order: 0,
			flex: 1
		}
	}
});

export { RETANN_LINE_COLUMN, RETANN_SUMMARY_COLUMN };
