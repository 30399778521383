import { createOrgSelector } from '@App/js/components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createSimpleStore } from '@Components/storeComponents';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createField,
	createHiddenField,
	createLabel,
	createNumberField,
	createRadio,
	createTextField,
	createTimeField
} from '@Components/fields';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createCreateSaveButton } from '@Components/buttons';
import { PTYPE_COMBO_ITEMS_DISABLEABLE } from '@UIkit/plugins';
import { createButtonContainer } from '@UIkit/components/panels';
import { ROW_COLOR_CLS } from '@Components/grid';
import { createRowsBlock } from '@Components/createRows/create.rows';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, createContainer, createFieldSet } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

/**
 * @author Vsevolod Liapin
 * Class for creation DELJIT document
 */
Ext.namespace('edi.modules');
edi.modules['document.create.torg_2'] = function () {
	var moduleData,
		documentData = {},
		productValues = [],
		parentId,
		isEdit = false,
		parentData,
		cargoInfoByAccompanyingDocuments = [],
		userData = edi.core.getUserData(),
		receiverId = null,
		senderId = null,
		sellerOrgId = null,
		buyerOrgId = null,
		buyer,
		seller,
		shipper,
		consignee,
		insurer,
		transporter,
		hasDataFromTransformation,
		isCreateFromTransformation = false,
		docFlowMembers;

	var form;
	var documentLinesGrid = null;
	var signerFillFromCert;
	var isIP = userData.org.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL;
	var signingPanel;
	var signerAuthorityAreaValue;
	var authorityStatus;

	var valuesMap = {
		nameAccompanyingDoc: {
			path: 'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.nameAccompanyingDoc',
			defaultValue: 'БСД'
		},
		numberAccompanyingDoc: {
			path: 'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.numberAccompanyingDoc',
			defaultValue: 'б/н'
		}
	};

	/**
	 * для передачи на бэк-энд нужно к каждой строке добавлять "Date",
	 *  а для отдельных полей на UI - "PureDate" и "PureTime".
	 *  Соответственно, массив предназначен для конвертеров и для рисования контролов
	 * @type {string[]}
	 */
	var cargoTimeFields = [
		'document.contentFactEconomLife1.acceptanceTimeInfo.shipmentCargo',
		'document.contentFactEconomLife1.acceptanceTimeInfo.giveAwayCargo',
		'document.contentFactEconomLife1.acceptanceTimeInfo.openCargo',
		'document.contentFactEconomLife1.acceptanceTimeInfo.deliveryCargo'
	];

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		hasDataFromTransformation = moduleData.initData && moduleData.initData.data && moduleData.initData.data.data;
		if (moduleData.initData && moduleData.initData.data && moduleData.initData.data.fromOrg) {
			senderId = moduleData.initData.data.fromOrg.fnsId;
		} else {
			senderId = userData.org.fnsId;
		}

		if (hasDataFromTransformation) {
			documentData = moduleData.initData.data.data;
			var sellerOrgData = moduleData.initData.data.toOrg;
			sellerOrgId = sellerOrgData ? sellerOrgData.id : null;
			docFlowMembers = documentData.docFlowMembers;

			isEdit = true;
			isCreateFromTransformation = true;
		}
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	var modalAdditionalConf = {
		main: {
			company_gln: null,
			individual_gln: null,
			individual_inn: {
				allowBlank: false
			}
		},
		address: {
			block: {
				maxLength: 20
			}
		},
		bank: {
			bank_acc: {
				allowBlank: true
			},
			bank_corr_acc: {
				maxLength: 20
			},
			bank_id: {
				regex: edi.constants.VALIDATORS.BIK,
				invalidText: edi.i18n.getMessage('invalid.value.bank.id'),
				listeners: {
					change: function (cmp, newValue) {
						edi.methods.fillBankNameByBik(cmp, newValue);
					}
				}
			},
			bank_name: {
				maxLength: 1000
			}
		},
		contact: {
			fax: null
		}
	};

	var switchCompanyTypeBlocks = function (component) {
		edi.selectors.fieldControls.switchCompanyTypeBlocks(
			component,
			function (form, orgType, modal) {
				var isModalReadOnly = edi.utils.getObjectProperty(modal, 'modalConf.readOnly') || false,
					forceChangeFieldsMap = edi.utils.getObjectProperty(modal, 'forceChangeFields') || {},
					forceChangeAnyField = !!modal.forceChangeAnyField,
					fieldValues = modal ? modal.selectedOrgValues : null,
					fieldsForm = form.getForm();

				var enableFieldBlockByType = function (fieldName, type) {
					var field = fieldsForm.findField(fieldName);
					if (field && field.ownerCt) {
						edi.selectors.utils.enableFieldBlock(
							field.ownerCt,
							orgType === type,
							fieldValues,
							isModalReadOnly,
							forceChangeFieldsMap,
							forceChangeAnyField
						);
					}
				};
				enableFieldBlockByType('foreign_info', 'legalForeigner');
				enableFieldBlockByType('foreign_name', 'legalForeigner');
				enableFieldBlockByType('foreign_identifier', 'legalForeigner');
				enableFieldBlockByType('physical_inn', 'individualParticipant');
				enableFieldBlockByType('physical_requisites', 'individualParticipant');
				enableFieldBlockByType('physical_additInfo', 'individualParticipant');
				enableFieldBlockByType('physical_lastname', 'individualParticipant');
				enableFieldBlockByType('physical_firstname', 'individualParticipant');
				enableFieldBlockByType('physical_patronymicname', 'individualParticipant');
			},
			function (fieldValues) {
				var orgType;
				if (fieldValues.foreign_name) {
					orgType = 'legalForeigner';
				} else if (fieldValues.individual_firstname) {
					orgType = 'individual';
				} else if (fieldValues.company_name) {
					orgType = 'company';
				} else if (fieldValues.physical_firstname) {
					orgType = 'individualParticipant';
				}

				return orgType;
			}
		);
	};

	var switchAddressBlocks = function (addressTypeCombo) {
		var modal = addressTypeCombo.modalInstance,
			form = modal ? modal.formPanel : null,
			isModalReadOnly = edi.utils.getObjectProperty(modal, 'modalConf.readOnly') || false,
			forceChangeFieldsMap = edi.utils.getObjectProperty(modal, 'forceChangeFields') || {},
			forceChangeAnyField = !!modal.forceChangeAnyField,
			value = addressTypeCombo.getValue(),
			org = modal ? modal.selectedOrgValues : null;
		var types = {
			0: 'rus',
			1: 'foreign',
			2: 'codeStateAddressRegister'
		};

		if (!form) {
			return;
		}
		var enableFieldBlockByType = function (fieldName, type) {
			var fields = form.down('textfield[name=' + fieldName + ']');
			if (fields) {
				var block = fields.up('fieldset');
				if (block) {
					edi.selectors.utils.enableFieldBlock(
						block,
						types[value] === type,
						org,
						isModalReadOnly,
						forceChangeFieldsMap,
						forceChangeAnyField
					);
				}
			}
		};
		enableFieldBlockByType('addr_rus_region', 'rus');
		enableFieldBlockByType('addr_for_text', 'foreign');
		enableFieldBlockByType('addr_code_gar', 'codeStateAddressRegister');

		if (form) {
			form.isValid();
		}
	};

	var defaultsNewFNSFields = {
		main: {
			company_type: edi.selectors.mergeConfigs('company_type_combo', {
				fieldConfig: {
					store: 'initCompany4TypeStore',
					disabled: function (org) {
						return (
							!!org &&
							(org.type === 'company' ||
								org.type === 'individual' ||
								org.type === 'legalForeigner' ||
								org.type === 'individualParticipant')
						);
					},
					listeners: {
						change: switchCompanyTypeBlocks,
						afterRender: switchCompanyTypeBlocks
					}
				}
			}),
			//foreign fields
			foreign_fields: {
				type: 'panel',
				panelConf: {
					layout: 'anchor',
					defaults: {
						anchor: '100%'
					}
				},
				fields: {
					foreign_name: {
						title: 'company.name',
						name: 'foreign_name',
						emptyText: 'company.name.comment',
						allowBlank: false,
						maxLength: 1000
					},
					foreign_identifier: {
						title: 'column.log.object.id',
						name: 'foreign_identifier',
						emptyText: 'column.log.object.id',
						allowBlank: true,
						maxLength: 1000
					},
					foreign_info: {
						title: 'company.type.legalForeigner.field.info',
						labelConf: {
							margin: 0
						},
						isTextarea: true,
						maxLength: 255,
						name: 'foreign_info'
					},
					info_for_members: {
						title: 'company.infoForMembers',
						name: 'info_for_members',
						allowBlank: true,
						maxLength: 255
					},
					short_name: {
						title: 'company.shortName',
						name: 'short_name',
						allowBlank: true,
						maxLength: 255
					}
				}
			},
			//physical fields
			physical_fields: {
				type: 'panel',
				panelConf: {
					layout: 'anchor',
					defaults: {
						anchor: '100%'
					}
				},
				fields: {
					physical_lastname: {
						name: 'physical_lastname',
						title: 'last.name',
						emptyText: 'company.last.name.comment',
						allowBlank: false
					},
					physical_name: {
						name: 'physical_firstname',
						title: 'first.name',
						emptyText: 'company.first.name.comment',
						allowBlank: false
					},
					physical_patronymicname: {
						name: 'physical_patronymicname',
						title: 'patronymic.name',
						emptyText: 'company.patronymic.name.comment'
					},
					physical_inn: {
						name: 'physical_inn',
						title: 'company.inn.individual',
						emptyText: 'company.inn.individual.error',
						maskRe: /\d/i,
						regex: edi.constants.VALIDATORS.INDI_INN,
						regexText: 'company.inn.individual.error',
						allowBlank: true
					},
					physical_requisites: {
						name: 'physical_requisites',
						title: 'company.physical.requisites',
						maxLength: 100,
						allowBlank: true
					},
					physical_additInfo: {
						name: 'physical_additInfo',
						title: 'company.physical.additInfo',
						maxLength: 225,
						allowBlank: true
					}
				}
			}
		},
		address: {
			addressType: edi.selectors.mergeConfigs('address_type_combo', {
				fieldConfig: {
					store: 'initNewUPDAddressTypeStore',
					value: function (org) {
						return org.address_type == 0 || org.address_type == 1 || org.address_type == 2
							? org.address_type
							: 0;
					},
					listeners: {
						change: switchAddressBlocks,
						afterRender: switchAddressBlocks
					},
					disabled: false
				}
			}),
			codeStateAddressRegisterAddress: {
				type: 'fieldset',
				panelConf: {
					layout: 'grid'
				},
				title: 'company.address.type.codeStateAddressRegister',
				fields: {
					addr_code_gar: {
						name: 'addr_code_gar',
						title: 'company.address.type.codeStateAddressRegister',
						emptyText: 'company.address.type.codeStateAddressRegister',
						maxLength: 36,
						allowBlank: false
					}
				}
			}
		}
	};

	const createOS = function (selectorConf) {
		Ext.applyIf(selectorConf, {
			fieldsMapOnly: true,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			valuesByMap: isEdit,
			useHiddenFields: true,
			disableAutoValidation: true,
			downloadBankAccountsData: true
		});
		return createOrgSelector(selectorConf);
	};

	//Сведения о документе
	var createDocumentInformationItems = function (documentData) {
		if (!isEdit) {
			edi.utils.setObjectProperty(
				documentData,
				'document.primaryDocName',
				edi.i18n.getMessage('document.torg2.valuable.acceptance.document')
			);
		}
		var valuableAcceptanceDocument = createFieldBlock({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					readOnly: true,
					name: 'document.primaryDocName',
					valueSrc: documentData
				})
			]
		});

		var documentNumber = createFieldBlock({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					name: 'document.acceptanceDocInfo.docNumber',
					valueSrc: documentData,
					allowBlank: false,
					maxLength: 255
				})
			]
		});

		var fixesDocumentInfo = createFieldBlock({
			layout: {
				type: 'grid',
				area: [[4, 4, 4]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createDateField({
					fieldLabel: edi.i18n.getMessage('document.torg2.document.date'),
					allowBlank: false,
					name: 'document.acceptanceDocInfo.docDate',
					value: isEdit
						? edi.utils.getObjectProperty(documentData, 'document.acceptanceDocInfo.docDate')
						: new Date(),
					submitFormat: edi.constants.DATE_FORMAT.FNS
				}),
				createNumberField({
					fieldLabel: edi.i18n.getMessage('documents.column.correction.number'),
					name: 'document.correctionsDocInfo.docNumber',
					minValue: 1,
					maxValue: 999,
					allowBlank: Ext.isEmpty(
						edi.utils.getObjectProperty(documentData, 'document.correctionsDocInfo.docDate')
					),
					valueSrc: documentData,
					listeners: {
						change: function (cmp, newValue) {
							var correctionDate = cmp.up('form').down('[name=document.correctionsDocInfo.docDate]');
							correctionDate.allowBlank = !newValue;
							correctionDate.validate();
						}
					}
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('document.torg2.correction.date'),
					name: 'document.correctionsDocInfo.docDate',
					allowBlank: Ext.isEmpty(
						edi.utils.getObjectProperty(documentData, 'document.correctionsDocInfo.docNumber')
					),
					value: edi.utils.getObjectProperty(documentData, 'document.correctionsDocInfo.docDate'),
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					listeners: {
						change: function (cmp, newValue) {
							var correctionNumber = cmp.up('form').down('[name=document.correctionsDocInfo.docNumber]');
							correctionNumber.allowBlank = !newValue;
							correctionNumber.validate();
						}
					}
				})
			]
		});

		var userOrganization = edi.converters.convertOrgToPartie(userData.org);
		var forcedBankData = {
			bank_id: true,
			bank_name: true,
			bank_corr_acc: true,
			bank_acc: true
		};

		let buyerValues = edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.buyer')
			? documentData
			: null;

		buyer = createOS({
			itemId: 'buyer',
			is_valid:
				!!edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.buyer') ||
				!!userData.org.iln,
			fieldValues: isEdit ? buyerValues : userOrganization,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id
			}),
			selectedOrgValues: isEdit ? undefined : userOrganization,
			title: 'document.torg2.buyer',
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.buyer', true),
			callback: function (values, org) {
				senderId = values && values.fns_id ? values.fns_id : null;
				buyerOrgId = org ? org.id : edi.core.getUserData().org.id;
				checkValid();
			},
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf, defaultsNewFNSFields)
		});

		var sellers = edi.relations.getRelations();
		var sellerRelation = null;
		var sellerIln =
			moduleData.initData.data && moduleData.initData.data.toOrg && moduleData.initData.data.toOrg.iln;
		if (isEdit && sellerIln) {
			for (var i = 0; i < sellers.length; i++) {
				if (sellerIln === sellers[i].iln) {
					sellerRelation = sellers[i];
					break;
				}
			}
		}

		seller = createOS({
			itemId: 'seller',
			relationsOnly: true,
			relations: sellers,
			allowReset: true,
			fieldValues: edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.seller')
				? documentData
				: null,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id
			}),
			is_valid: !!edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.seller'),
			selectedOrgValues: isEdit ? sellerRelation : null,
			title: 'document.torg2.seller',
			forceChangeFields: forcedBankData,
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.seller', true),
			callback: function (values, org) {
				receiverId = values && values.fns_id ? values.fns_id : null;
				sellerOrgId = org ? org.id : null;
				checkValid();
			},
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf, defaultsNewFNSFields)
		});

		var circumstancesDocument = createFieldBlock({
			title: edi.i18n.getMessage('document.torg2.circumstances.filling.document'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [4, 4]
			},
			items: [
				createTextField({
					name: 'document.baseFileInvoiceCreation',
					valueSrc: documentData,
					maxLength: 120,
					fieldLabel: edi.i18n.getMessage('document.torg2.basis.economic.entity')
				}),
				createCombo({
					name: 'document.createDocInfo',
					valueSrc: documentData,
					store: edi.stores.createInlineStore(edi.methods.torg2.getCreationCircumstances(), 'SIMPLE', null, {
						addEmptyRecord: true
					}),
					fieldLabel: edi.i18n.getMessage('document.torg2.circumstances.drawing.up.document')
				})
			]
		});

		var mandatorySignersForOptions = ['4', '6', '8'];
		var additionalInfoSignersText;

		var additionalInfo = createFieldBlock({
			title: edi.i18n.getMessage('document.torg2.additional.info.options'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createCombo({
					name: 'document.infoCreateAdditionalInfo',
					allowBlank: false,
					forceSelection: true,
					value: edi.utils.getObjectProperty(documentData, 'document.infoCreateAdditionalInfo'),
					displayField: 'name',
					store: createSimpleStore({
						data: edi.methods.torg2.getAdditionalInfoOptions(),
						model: edi.models.getModel('SIMPLE')
					}),
					fieldLabel: edi.i18n.getMessage('document.torg2.additional.info.for.document'),
					listeners: {
						change: function (cmp, newValue, oldValue) {
							if (mandatorySignersForOptions.some((it) => it === newValue)) {
								additionalInfoSignersText.allowBlank = false;
							} else {
								additionalInfoSignersText.allowBlank = true;
							}
							checkValid();
						}
					}
				})
			]
		});

		var additionalInfoSigners = createFieldBlock({
			title: edi.i18n.getMessage('document.torg2.additional.info.assigning.signers'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				(additionalInfoSignersText = createTextField({
					fieldLabel: edi.i18n.getMessage('document.torg2.additional.info.assigning.signers'),
					name: 'document.destinationAdditionalInfo',
					valueSrc: documentData,
					maxLength: 2000
				}))
			]
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.document.information'),
				collapsible: true,
				layout: {
					type: 'grid',
					area: [6, [6, 6], [6, 6], 12, 4, 4]
				},
				items: [
					valuableAcceptanceDocument,
					documentNumber,
					fixesDocumentInfo,
					buyer,
					seller,
					circumstancesDocument,
					additionalInfo,
					additionalInfoSigners
				]
			})
		];
	};

	// Сведения об обстоятельствах приемки
	var shippingDetailsInformationItems = function () {
		shipper = createOS({
			itemId: 'shipper',
			allowBlank: true,
			ownOrg: true,
			relations: edi.relations.getRelations(),
			is_valid: true, // !!edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.cargoSender'),
			fieldValues: edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.cargoSender')
				? documentData
				: null,
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.cargoSender', true, true),
			callback: function () {
				checkValid();
				shipper?.checkOrgDataValid();
			},
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf, defaultsNewFNSFields)
		});

		let shipperBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.ship.from'),
			items: [shipper]
		});

		consignee = createOS({
			itemId: 'consignee',
			allowBlank: true,
			ownOrg: true,
			relations: edi.relations.getRelations(),
			is_valid: true, // !!edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.cargoReceiver'),
			fieldValues: edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.cargoReceiver')
				? documentData
				: null,
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.cargoReceiver', true, true),
			callback: function (values, org) {
				checkValid();
				consignee?.checkOrgDataValid();
			},
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf, defaultsNewFNSFields)
		});

		let consigneeBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.delivery'),
			items: [consignee]
		});

		insurer = createOS({
			itemId: 'insurer',
			allowBlank: true,
			ownOrg: true,
			relations: edi.relations.getRelations(),
			is_valid: true, // !!edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.insurer'),
			fieldValues: edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.insurer')
				? documentData
				: null,
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.insurer', true, true),
			callback: function () {
				checkValid();
			},
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf, defaultsNewFNSFields)
		});

		let insurerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.torg2.insurance.company'),
			items: [insurer]
		});

		var conditionsCode = createFieldBlock({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createNumberField({
					regex: /^[1234][\d]{3}$/,
					regexText: edi.i18n.getMessage('document.torg2.creation.code.tooltip'),
					name: 'document.contentFactEconomLife1.formationCode',
					valueSrc: documentData,
					fieldLabel: edi.i18n.getMessage('document.torg2.creation.conditions.code')
				})
			]
		});

		var stateContract = createFieldBlock({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.torg2.state.contract'),
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.torg2.identifier.state.contract'),
					name: 'document.contentFactEconomLife1.govAgreementId',
					valueSrc: documentData,
					maxLength: 255
				})
			]
		});

		var toggleOrderFields = function (form) {
			var dateField = form.down('[name=document.contentFactEconomLife1.order.orderDate]');
			var numberField = form.down('[name=document.contentFactEconomLife1.order.orderNumber]');
			var isMandatory = !Ext.isEmpty(dateField.getValue()) || !Ext.isEmpty(numberField.getValue());
			dateField.allowBlank = !isMandatory;
			numberField.allowBlank = !isMandatory;
			dateField.validate();
			numberField.validate();
		};

		var orderOnAppointmentCommission = createFieldBlock({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.torg2.order.on.appointment.commission'),
			layout: {
				type: 'grid',
				area: [[4, 2]]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.torg2.order.number'),
					name: 'document.contentFactEconomLife1.order.orderNumber',
					valueSrc: documentData,
					maxLength: 255,
					listeners: {
						change: function (cmp) {
							toggleOrderFields(cmp.up('form'));
						}
					}
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					name: 'document.contentFactEconomLife1.order.orderDate',
					valueSrc: documentData,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					listeners: {
						change: function (cmp) {
							toggleOrderFields(cmp.up('form'));
						}
					}
				})
			]
		});

		let createInfoField = function (isFirstRow, fieldData) {
			fieldData = fieldData || {};

			let validateInputs = function () {
				if (identifier && value) {
					let bothAreEmpty = !identifier.getValue() && !value.getValue();
					identifier.allowBlank = bothAreEmpty;
					identifier.isValid();
					value.allowBlank = bothAreEmpty;
					value.isValid();
				}
			};

			let identifier = createTextField({
				maxLength: 50,
				name: 'document.contentFactEconomLife1.info.textInfo.identifier',
				value: edi.utils.getObjectProperty(fieldData, 'identifier'),
				fieldLabel: edi.i18n.getMessage('document.torg2.identifier'),
				listeners: {
					change: validateInputs
				}
			});
			let value = createTextField({
				maxLength: 2000,
				name: 'document.contentFactEconomLife1.info.textInfo.value',
				value: edi.utils.getObjectProperty(fieldData, 'value'),
				fieldLabel: edi.i18n.getMessage('document.torg2.value'),
				listeners: {
					change: validateInputs
				}
			});
			return createContainer({
				layout: {
					type: 'grid',
					area: [[6, 6]]
				},
				items: [identifier, value]
			});
		};
		let acceptanceResultsInfo = createFieldBlock({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.torg2.additional.information.head'),
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.torg2.inspection.id.file'),
					name: 'document.contentFactEconomLife1.info.fileId',
					valueSrc: documentData,
					regex: /^[\w-_]{36}$/,
					regexText: edi.i18n.getMessage('document.torg2.id.file.tooltip')
				}),
				createRowsBlock({
					createContentFieldsFn: createInfoField,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					initialData: edi.utils.getObjectProperty(
						documentData,
						'document.contentFactEconomLife1.info.textInfo',
						true
					),
					onAddRow() {
						checkValid();
					},
					onRemoveRow() {
						checkValid();
					}
				})
			]
		});

		// var acceptanceResultsInfo = createIdFileInfo(
		// 	'document.torg2.additional.information.head',
		// 	'document.contentFactEconomLife1.info.fileId',
		// 	'document.contentFactEconomLife1.info.textInfo'
		// );

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.inspection.conditions.info'),
				collapsible: true,
				layout: {
					type: 'grid',
					gap: 16,
					area: [[6, 6], 4, 4, 6, 12, 12]
				},
				items: [
					shipperBlock,
					consigneeBlock,
					conditionsCode,
					stateContract,
					insurerBlock,
					orderOnAppointmentCommission,
					acceptanceResultsInfo
				]
			})
		];
	};

	//разделяет компоненты на 3 колонки равного размера
	var createThreeColumnsLayout = function (arrayFields) {
		var arrayFieldLayout = [];
		arrayFields.forEach(function (field) {
			if (!field) {
				return;
			}
			if (field.isComponent) {
				arrayFieldLayout.push(field);
			}
			var fieldLayout;
			if (field.store) {
				fieldLayout = createCombo({
					margin: '0 0 0 5',
					store: field.store,
					name: field.name,
					allowBlank: typeof field.allowBlank === 'boolean' ? field.allowBlank : true,
					emptyText: edi.i18n.getMessage(field.emptyText),
					fieldLabel: edi.i18n.getMessage(field.fieldLabel),
					qtipText: field.tooltip,
					columnWidth: field.columnWidth ? field.columnWidth : 0.33,
					value: edi.utils.getObjectProperty(documentData, field.name),
					listeners: {
						change: field.onChange ? field.onChange : function () {}
					}
				});
			} else {
				fieldLayout = createTextField({
					margin: '0 0 0 5',
					columnWidth: field.columnWidth ? field.columnWidth : 0.33,
					name: field.name,
					qtipText: field.tooltip,
					valueSrc: documentData,
					emptyText: edi.i18n.getMessage(field.emptyText),
					fieldLabel: edi.i18n.getMessage(field.fieldLabel),
					maxLength: field.maxLength || 255,
					allowBlank: typeof field.allowBlank === 'boolean' ? field.allowBlank : true,
					readOnly: field.readOnly ? field.readOnly : false
				});
			}
			arrayFieldLayout.push(fieldLayout);
		});
		return createPanel({
			layout: 'column',
			items: arrayFieldLayout
		});
	};

	var createLabelFn = function (text) {
		return createLabel({
			margin: '5 0 0 5',
			text: edi.i18n.getMessage(text)
		});
	};

	//Результаты приемки
	var acceptanceResultsInformationItems = function () {
		let resultsBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.torg2.acceptance.results'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[6, 6]]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.torg2.brief.description.event'),
					name: 'document.contentFactEconomLife2.eventDescription',
					maxLength: 2000,
					value:
						edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife2.eventDescription') ||
						edi.i18n.getMessage('document.torg2.default.description.event.text')
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.torg2.brief.conclusion.event'),
					name: 'document.contentFactEconomLife2.conclusion',
					valueSrc: documentData,
					maxLength: 2000
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('operation.kind'),
					maxLength: 255,
					value: edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife2.operationType'),
					name: 'document.contentFactEconomLife2.operationType'
				})
			]
		});

		let additInfo = createFieldBlock({
			title: edi.i18n.getMessage('document.torg2.additional.information.head'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: 'grid',
			items: [
				createRowsBlock({
					createContentFieldsFn: createReceptionLineField,
					initialData: edi.utils.getObjectProperty(
						documentData,
						'document.contentFactEconomLife2.info',
						true
					),
					onAddRow() {
						checkValid();
					},
					onRemoveRow() {
						checkValid();
					}
				})
			]
		});

		let attachedDocuments = createFieldBlock({
			title: edi.i18n.getMessage('document.torg2.attached.documents'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: 'grid',
			items: [
				createRowsBlock({
					createContentFieldsFn: createAttachedDocumentLineField,
					initialData: edi.utils.getObjectProperty(
						documentData,
						'document.contentFactEconomLife2.attachedDoc',
						true
					),
					onAddRow() {
						checkValid();
					},
					onRemoveRow() {
						checkValid();
					}
				})
			]
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.information.acceptance.discrepancies'),
				collapsible: true,
				layout: 'grid',
				items: [resultsBlock, additInfo, attachedDocuments]
			})
		];
	};

	var cargoReceptionTimeDetailsItems = function () {
		var cargoEmptyTextKeys = [
			'document.torg2.cargo.arrival.time',
			'document.torg2.cargo.delivery.time',
			'document.torg2.cargo.opening.time',
			'document.torg2.warehouse.delivery.time'
		];
		var cargoPanelItems = [];

		for (let i = 0; i < cargoTimeFields.length; ++i) {
			let fullDate = edi.utils.getObjectProperty(documentData, cargoTimeFields[i] + 'Time');
			let dateParts;
			let pureDate;
			let pureTime;
			if (fullDate) {
				dateParts = fullDate.split(' '); // формат входящей даты: "дд.мм.гггг чч.мм.сс"
				pureDate = dateParts[0];
				pureTime = dateParts[1].replaceAll('.', ':');
			} else {
				pureDate = null;
				pureTime = null;
			}

			let checkAllowBlankDateTimeFields = function () {
				if (pureDateField && pureTimeField) {
					let bothAreEmpty = !pureDateField.getValue() && !pureTimeField.getValue();
					pureDateField.allowBlank = bothAreEmpty;
					pureDateField.isValid();
					pureTimeField.allowBlank = bothAreEmpty;
					pureTimeField.isValid();
				}
			};

			let pureDateField = createDateField({
				name: cargoTimeFields[i] + 'PureDate',
				value: pureDate,
				format: edi.constants.DATE_FORMAT.FNS,
				fieldLabel: edi.i18n.getMessage('column.date'),
				submitFormat: edi.constants.DATE_FORMAT.FNS,
				listeners: {
					change: checkAllowBlankDateTimeFields
				}
			});
			let pureTimeField = createTimeField({
				format: 'H:i:s',
				name: cargoTimeFields[i] + 'PureTime',
				value: pureTime,
				fieldLabel: edi.i18n.getMessage('column.time'),
				listeners: {
					change: checkAllowBlankDateTimeFields
				}
			});
			let cargoBlock = createPanel({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				layout: {
					type: 'grid',
					area: [[6, 3, 3]]
				},
				items: [
					createLabel({
						text: edi.i18n.getMessage(cargoEmptyTextKeys[i])
					}),
					pureDateField,
					pureTimeField
				],
				listeners: {
					afterrender: checkAllowBlankDateTimeFields
				}
			});
			cargoPanelItems.push(cargoBlock);
		}

		var dateTimeCargoAcceptanceField = createPanel({
			layout: {
				type: 'grid',
				area: [
					[6, 6],
					[6, 6]
				]
			},
			items: cargoPanelItems
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.cargo.reception.time.details'),
				collapsible: true,
				items: [dateTimeCargoAcceptanceField]
			})
		];
	};

	var otherCargoReceptionItems = function () {
		var storageConditions = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.48,
					name: 'document.contentFactEconomLife1.otherInfoAcceptance.storageConditions',
					valueSrc: documentData,
					maxLength: 1000,
					fieldLabel: edi.i18n.getMessage('document.torg2.warehouse.storage.conditions')
				}),
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.3,
					name: 'document.contentFactEconomLife1.otherInfoAcceptance.containerCondition',
					valueSrc: documentData,
					maxLength: 1000,
					fieldLabel: edi.i18n.getMessage('document.torg2.examined.package.container.condition')
				})
			]
		});

		var typeDefinitionMissingValues = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.48,
					name: 'document.contentFactEconomLife1.otherInfoAcceptance.definitionShortage',
					valueSrc: documentData,
					maxLength: 200,
					fieldLabel: edi.i18n.getMessage('document.torg2.type.definition.missing.values')
				}),
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.3,
					name: 'document.contentFactEconomLife1.otherInfoAcceptance.additionalInfo',
					valueSrc: documentData,
					maxLength: 1000,
					fieldLabel: edi.i18n.getMessage('document.torg2.additional.information')
				})
			]
		});

		transporter = createOS({
			itemId: 'otherInfoAcceptanceTransporter',
			title: 'document.torg2.cargo.transporter',
			allowBlank: true,
			ownOrg: true,
			relations: edi.relations.getRelations(),
			is_valid: !!edi.utils.getObjectProperty(
				documentData,
				'document.contentFactEconomLife1.otherInfoAcceptance.transporter'
			),
			fieldValues: edi.utils.getObjectProperty(
				documentData,
				'document.contentFactEconomLife1.otherInfoAcceptance.transporter'
			)
				? documentData
				: null,
			fieldsMap: edi.selectors.getTorg2FieldsMap(
				'document.contentFactEconomLife1.otherInfoAcceptance.transporter',
				true,
				true
			),
			callback: function () {
				checkValid();
			},
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf, defaultsNewFNSFields)
		});

		var coveringTransportLabel = createLabel({
			margin: '5 0 0 5',
			text: edi.i18n.getMessage('document.torg2.covering.document.details')
		});

		var triggerCoveringDocumentName = function (form) {
			var nameField = form.down(
				'[name=document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.nameAccompanyingDoc]'
			);
			var numberField = form.down(
				'[name=document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.numberAccompanyingDoc]'
			);
			var dateField = form.down(
				'[name=document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.dateAccompanyingDoc]'
			);
			var additField = form.down(
				'[name=document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.additionalIdAccompanyingDoc]'
			);
			var isMandatory =
				!Ext.isEmpty(numberField.getValue()) ||
				!Ext.isEmpty(dateField.getValue()) ||
				!Ext.isEmpty(additField.getValue());
			nameField.allowBlank = !isMandatory;
			nameField.validate();
		};

		var coveringTransportFields = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.22,
					name: 'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.nameAccompanyingDoc',
					valueSrc: documentData,
					allowBlank: true,
					maxLength: 200,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.name'),
					listeners: {
						change: function (cmp, newValue, oldValue) {
							triggerCoveringDocumentName(cmp.up('form'));
						}
					}
				}),
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.11,
					name: 'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.numberAccompanyingDoc',
					valueSrc: documentData,
					maxLength: 100,
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					listeners: {
						change: function (cmp, newValue, oldValue) {
							triggerCoveringDocumentName(cmp.up('form'));
						}
					}
				}),
				createDateField({
					margin: '0 0 0 5',
					columnWidth: 0.11,
					name: 'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.dateAccompanyingDoc',
					value: edi.utils.getObjectProperty(
						documentData,
						'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.dateAccompanyingDoc'
					),
					fieldLabel: edi.i18n.getMessage('document.torg2.document.date'),
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					listeners: {
						change: function (cmp, newValue, oldValue) {
							triggerCoveringDocumentName(cmp.up('form'));
						}
					}
				}),
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.3,
					name: 'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.additionalIdAccompanyingDoc',
					valueSrc: documentData,
					fieldLabel: edi.i18n.getMessage('document.torg2.additional.document.identifier'),
					maxLength: 255,
					listeners: {
						change: function (cmp, newValue, oldValue) {
							triggerCoveringDocumentName(cmp.up('form'));
						}
					}
				})
			]
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.other.cargo.reception.circumstances'),
				collapsible: true,
				items: [
					storageConditions,
					typeDefinitionMissingValues,
					createTwoColumnsLayout([transporter]),
					coveringTransportLabel,
					coveringTransportFields
				]
			})
		];
	};

	var createCheckBoxFn = function (name, checked, title) {
		return createField({
			title: edi.i18n.getMessage(title),
			input: createCheckbox({
				columnWidth: 0.8,
				checked: checked,
				name: name,
				inputValue: '1'
			}),
			containerConfig: {
				columnWidth: 1
			}
		});
	};

	var isCheckedSignerOrgType = function (name) {
		var namesArray = name.split('.');
		return namesArray[1] && documentData.signer && documentData.signer[namesArray[1]];
	};

	var createRadioFn = function (name, title, checked, onChangeSignerOrgType) {
		return createRadio({
			boxLabel: edi.i18n.getMessage(title),
			name: 'signer.orgType',
			inputValue: name,
			checked: checked,
			listeners: {
				change: function (comp, newValue) {
					onChangeSignerOrgType(comp, newValue, name);
				}
			}
		});
	};

	var createSignerFields = function () {
		var docSigners = edi.utils.getObjectProperty(documentData, 'document.signer');
		var savedSigner = Array.isArray(docSigners) ? docSigners[0] : docSigners;
		var signer = savedSigner || documentData.signer || {};
		var availableAreasData = edi.stores.data.torg2AuthorityAreas.OUTBOX;
		var baseAuthory = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
					input: createTextField({
						columnWidth: 0.7,
						maxLength: 255,
						allowBlank: false,
						name: 'signer.baseAuthory',
						value: edi.utils.getObjectProperty(signer, 'baseAuthory')
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var baseOrgAuthory = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
					input: createTextField({
						columnWidth: 0.7,
						maxLength: 255,
						name: 'signer.baseOrgAuthory',
						value: edi.utils.getObjectProperty(signer, 'baseOrgAuthory'),
						listeners: {
							change: function (comp) {
								var value = comp.getValue(),
									authorityInput = authorityAreaAndStatus.down('[name=signer.status]'),
									baseOrgAuthoryInput = baseOrgAuthory.down('[name=signer.baseOrgAuthory]');

								if (value && authorityInput) {
									baseOrgAuthoryInput.allowBlank = authorityInput.getValue() !== '3';
									baseOrgAuthoryInput.isValid();
								}
								comp.isValid();
							}
						}
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var signerPositionCombo = !isIP
			? createCombo({
					store: edi.stores.initPositionsStore(),
					displayField: 'shortName',
					autoSelect: true,
					forceSelection: false,
					columnWidth: 0.3,
					maxLength: 128,
					margin: '0 0 0 5',
					qtipText: edi.i18n.getMessage('document.signer.get.data.from.profile'),
					readOnly: true,
					fieldLabel: edi.i18n.getMessage('signer.job.position'),
					name: 'signer.legalEntity.position',
					value: edi.utils.getObjectProperty(signer, 'legalEntity.position')
			  })
			: null;
		var signerSection = createPanel({
			layout: 'column',
			margin: '10 10 5',
			cls: 'edi-create-field-line',
			items: [
				createHiddenField({
					value: userData.org.inn,
					name: !isIP ? 'signer.legalEntity.tin' : 'signer.individualPerson.tin'
				}),
				signerPositionCombo,
				createTextField({
					columnWidth: 0.2 + isIP * 0.1,
					margin: '0 0 0 5',
					maxLength: 60,
					qtipText: signerFillFromCert.firstName
						? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
						: edi.i18n.getMessage('document.signer.get.data.from.cert'),
					readOnly: true,
					fieldLabel: edi.i18n.getMessage('first.name'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.firstName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.firstName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.firstName'),
					name: !isIP ? 'signer.legalEntity.fullName.firstName' : 'signer.individualPerson.name.firstName'
				}),
				createTextField({
					columnWidth: 0.3 + isIP * 0.1,
					margin: '0 0 0 5',
					qtipText: signerFillFromCert.lastName
						? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
						: edi.i18n.getMessage('document.signer.get.data.from.cert'),
					readOnly: true,
					maxLength: 60,
					fieldLabel: edi.i18n.getMessage('last.name'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.lastName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.lastName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.lastName'),
					name: !isIP ? 'signer.legalEntity.fullName.lastName' : 'signer.individualPerson.name.lastName'
				}),
				createTextField({
					columnWidth: 0.2 + isIP * 0.1,
					margin: '0 0 0 5',
					qtipText: signerFillFromCert.middleName
						? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
						: edi.i18n.getMessage('document.signer.get.data.from.cert'),
					readOnly: true,
					maxLength: 60,
					fieldLabel: edi.i18n.getMessage('value.not.specified'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.middleName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.middleName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.middleName'),
					name: !isIP ? 'signer.legalEntity.fullName.middleName' : 'signer.individualPerson.name.middleName'
				})
			]
		});

		var certificateRegistrationIP = isIP
			? createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					items: [
						createField({
							title: edi.i18n.getMessage('individual.certificateRegistration'),
							input: createTextField({
								columnWidth: 0.7,
								maxLength: 255,
								name: 'signer.individualPerson.certificateRegistrationIP',
								value:
									edi.utils.getObjectProperty(signer, 'individualPerson.certificateRegistrationIP') ||
									edi.utils.getObjectProperty(signer, 'individual.certificateRegistrationIP')
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
			  })
			: null;

		// If editing we load store manually
		var authorityArea = createCombo({
			name: 'signer.authorityArea',
			fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
			store: edi.stores.createInlineStore(availableAreasData, 'AUTHORITY_AREA'),
			displayField: 'name',
			value: (signerAuthorityAreaValue = edi.utils.getObjectProperty(signer, 'authorityArea')),
			columnWidth: 0.6,
			allowBlank: false,
			validator: function (val) {
				if (!val) {
					if (!isEdit && !availableAreasData.length) {
						return edi.i18n.getMessage('document.fns_upd.field.authorityArea.mandatory.qtip');
					} else {
						return edi.i18n.getMessage('form.field.required');
					}
				}
				return true;
			},
			forceSelection: true
		});

		var signerStateStore = edi.stores.createInlineStore(
			edi.stores.data.torg2SignerStatuses.OUTBOX,
			'AUTHORITY_AREA'
		);
		var authorityStatusCheck = function (comp) {
			var value = comp.getValue();
			var baseAuthoryInput = baseAuthory.down('[name=signer.baseAuthory]');
			var baseOrgAuthoryInput = baseOrgAuthory.down('[name=signer.baseOrgAuthory]');
			if (value && baseAuthoryInput) {
				baseOrgAuthoryInput.allowBlank = value != '3';
				baseOrgAuthoryInput.isValid();
				if (['1', '2', '3'].indexOf(value) > -1) {
					baseAuthoryInput.setValue(edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default'));
				} else {
					baseAuthoryInput.setValue(
						edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default.state.4')
					);
				}
				checkValid();
			}
		};

		var currentStatusRec = signerStateStore.findRecord('id', signer.status, false, false, true);
		var authorityAreaAndStatus = createPanel({
			layout: 'column',
			items: [
				authorityArea,
				(authorityStatus = createCombo({
					name: 'signer.status',
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.status'),
					store: signerStateStore,
					displayField: 'name',
					value: currentStatusRec && !currentStatusRec.get('disabled') ? signer.status : '4',
					columnWidth: 0.4,
					margin: '0 0 0 5',
					allowBlank: false,
					forceSelection: true,
					qtipText: signerFillFromCert.status
						? edi.i18n.getMessage('document.signer.inn.cert.not.equal.inn.org')
						: undefined,
					plugins: [
						{
							ptype: PTYPE_COMBO_ITEMS_DISABLEABLE,
							disabledField: 'disabled'
						}
					],
					listeners: {
						afterrender: authorityStatusCheck,
						change: authorityStatusCheck
					}
				}))
			]
		});

		return [authorityAreaAndStatus, certificateRegistrationIP, baseAuthory, baseOrgAuthory, signerSection];
	};

	//Подписант
	var signatoryInformationItems = function () {
		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.signatory'),
				collapsible: true,

				items: [
					(signingPanel = createPanel({
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						items: createSignerFields()
					}))
				]
			})
		];
	};

	// Описание полей для сведений о лице, принявшем товар
	var personInfoFields = {
		buyerEmployee: [
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeePosition',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeeAuthority',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeeAdditionalInfo',
				required: false
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.name.lastName',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.name.firstName',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.name.middleName',
				required: false
			}
		],
		receiverPerson: [
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonPosition',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonAuthority',
				required: false
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonAdditionalInfo',
				required: false
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonOrgName',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonRightPresentResults',
				required: false
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.lastName',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.firstName',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.middleName',
				required: false
			}
		],
		individualPerson: [
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.individualPersonReceiverGoogsAuthority',
				required: false
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.individualPersonReceiverGoogsAdditInfo',
				required: false
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.lastName',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.firstName',
				required: true
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.middleName',
				required: false
			}
		]
	};

	// Блок "сведения о лице, принявшем товар (получившем груз)"
	var personAcceptedGoodsInformationItems = function () {
		// Фамилия - обязательное поле в любом из трёх случаев
		var receiverPersonSurname = edi.utils.getObjectProperty(
			documentData,
			'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.lastName'
		);
		var individualPersonSurname = edi.utils.getObjectProperty(
			documentData,
			'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.lastName'
		);
		var acceptorComboValue = 'buyerEmployee';

		if (!Ext.isEmpty(individualPersonSurname)) {
			acceptorComboValue = 'individualPerson';
		} else if (!Ext.isEmpty(receiverPersonSurname)) {
			acceptorComboValue = 'receiverPerson';
		}

		var position = [
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeePosition',
				fieldLabel: 'document.torg2.position',
				allowBlank: acceptorComboValue !== 'buyerEmployee',
				maxLength: 128
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeeAuthority',
				fieldLabel: 'document.torg2.basis.authority',
				allowBlank: acceptorComboValue !== 'buyerEmployee',
				maxLength: 120
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeeAdditionalInfo',
				fieldLabel: 'document.torg2.other.information',
				maxLength: 255
			}
		];
		var definitionMissingValues = [
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.name.lastName',
				fieldLabel: 'document.torg2.surname',
				allowBlank: acceptorComboValue !== 'buyerEmployee',
				maxLength: 60
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.name.firstName',
				fieldLabel: 'document.torg2.name',
				allowBlank: acceptorComboValue !== 'buyerEmployee',
				maxLength: 60
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.buyerEmployee.name.middleName',
				fieldLabel: 'document.torg2.patronymic',
				maxLength: 60
			}
		];
		var memberPosition = [
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonPosition',
				fieldLabel: 'document.torg2.position',
				allowBlank: acceptorComboValue !== 'receiverPerson',
				maxLength: 128
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonAuthority',
				fieldLabel: 'document.torg2.basis.authority',
				maxLength: 120
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonAdditionalInfo',
				fieldLabel: 'document.torg2.other.information',
				maxLength: 255
			}
		];
		var memberOrganization = [
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonOrgName',
				fieldLabel: 'document.torg2.name.organization',
				allowBlank: acceptorComboValue !== 'receiverPerson',
				maxLength: 125
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonRightPresentResults',
				fieldLabel: 'document.torg2.base.authority.organization',
				maxLength: 120
			}
		];
		var memberInfo = [
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.lastName',
				fieldLabel: 'document.torg2.surname',
				allowBlank: acceptorComboValue !== 'receiverPerson',
				maxLength: 60
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.firstName',
				fieldLabel: 'document.torg2.name',
				allowBlank: acceptorComboValue !== 'receiverPerson',
				maxLength: 60
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.middleName',
				fieldLabel: 'document.torg2.patronymic',
				maxLength: 60
			}
		];
		var individualPosition = [
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.individualPersonReceiverGoogsAuthority',
				fieldLabel: 'document.torg2.basis.authority',
				maxLength: 120
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.individualPersonReceiverGoogsAdditInfo',
				fieldLabel: 'document.torg2.other.information',
				maxLength: 255
			}
		];
		var individualInfo = [
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.lastName',
				fieldLabel: 'document.torg2.surname',
				allowBlank: acceptorComboValue !== 'individualPerson',
				maxLength: 60
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.firstName',
				fieldLabel: 'document.torg2.name',
				allowBlank: acceptorComboValue !== 'individualPerson',
				maxLength: 60
			},
			{
				name: 'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.middleName',
				fieldLabel: 'document.torg2.patronymic',
				maxLength: 60
			}
		];

		var buyerEmployeePanel = createPanel({
			hidden: acceptorComboValue !== 'buyerEmployee',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [createThreeColumnsLayout(position), createThreeColumnsLayout(definitionMissingValues)]
		});

		var receiverPersonPanel = createPanel({
			hidden: acceptorComboValue !== 'receiverPerson',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createThreeColumnsLayout(memberPosition),
				createThreeColumnsLayout(memberOrganization),
				createThreeColumnsLayout(memberInfo)
			]
		});

		var individualPersonPanel = createPanel({
			hidden: acceptorComboValue !== 'individualPerson',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [createThreeColumnsLayout(individualPosition), createThreeColumnsLayout(individualInfo)]
		});

		var acceptorTypes = ['buyerEmployee', 'receiverPerson', 'individualPerson'];
		var acceptorPanels = [buyerEmployeePanel, receiverPersonPanel, individualPersonPanel];

		var toggleAcceptorByType = function (selectedType) {
			var currentType;
			var panelElem;
			var elem;
			var j;
			for (var i = 0; i < acceptorTypes.length; ++i) {
				currentType = acceptorTypes[i];
				if (currentType === selectedType) {
					acceptorPanels[i].show();
					for (j = 0; j < personInfoFields[currentType].length; ++j) {
						panelElem = personInfoFields[currentType][j];
						elem = form.down('[name=' + panelElem.name + ']');
						elem.enable();
						if (panelElem.required) {
							elem.allowBlank = false;
						}
					}
				} else {
					acceptorPanels[i].hide();
					for (j = 0; j < personInfoFields[currentType].length; ++j) {
						panelElem = personInfoFields[currentType][j];
						elem = form.down('[name=' + panelElem.name + ']');
						elem.disable();
						elem.allowBlank = true;
					}
				}
			}

			checkValid();
		};

		var acceptorTypeSelector = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createCombo({
					columnWidth: 0.635,
					name: 'acceptorType',
					value: acceptorComboValue,
					store: edi.stores.createSimpleInlineStore(
						acceptorTypes,
						function (id) {
							return edi.i18n.getMessage('document.torg2.receiver.' + id);
						},
						false
					),
					emptyText: edi.i18n.getMessage('document.torg2.circumstances.drawing.up.document'),
					listeners: {
						change: function (cmp, newValue, oldValue) {
							toggleAcceptorByType(newValue);
						}
					}
				})
			]
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.cargo.received.person'),
				collapsible: true,
				items: [acceptorTypeSelector, buyerEmployeePanel, receiverPersonPanel, individualPersonPanel]
			})
		];
	};

	var acceptResultsTabsFields = {
		senderInfo: [
			'senderInfo.count',
			'senderInfo.cost',
			'senderInfo.rateNDS',
			'senderInfo.costWithNDS',
			'senderInfo.sumNDS',
			'senderInfo.identifyProductToolNumbers'
		],
		actualInfo: [
			'actualInfo.count',
			'actualInfo.cost',
			'actualInfo.rateNDS',
			'actualInfo.costWithNDS',
			'actualInfo.sumNDS',
			'actualInfo.identifyProductToolNumbers'
		],
		defect: [
			'defect.count',
			'defect.costWithNDS',
			'defect.sumNDS',
			'defect.info',
			'defect.identifyProductToolNumbers'
		],
		breakage: [
			'breakage.count',
			'breakage.costWithNDS',
			'breakage.sumNDS',
			'breakage.info',
			'breakage.identifyProductToolNumbers'
		],
		shortage: [
			'shortage.count',
			'shortage.costWithNDS',
			'shortage.sumNDS',
			'shortage.info',
			'shortage.identifyProductToolNumbers'
		],
		surplus: [
			'surplus.count',
			'surplus.costWithNDS',
			'surplus.sumNDS',
			'surplus.info',
			'surplus.identifyProductToolNumbers'
		],
		costDeviation: [
			'costDeviation.delta.deltaCostWithNDS',
			'cosDeviation.delta.deltaNDS',
			'costDeviation.delta.deltaSumNDS',
			'costDeviation.delta.info'
		]
	};

	var documentLinesInformationItems = function () {
		productValues = edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife2.acceptanceResult');

		// Первая вкладка, 4 созависимых поля (если заполнено одно, остальные необязательны)
		let acceptResultsValuablesFields = [
			'values.valueName',
			'values.productDesc',
			'values.productReference',
			'values.productCode'
		];
		let toggleValuablesBlockBlank = function () {
			let fields = documentLinesGrid.getFormFields();

			let fieldsAreBlank = !acceptResultsValuablesFields.some((fieldName) => {
				return fields[fieldName] && !Ext.isEmpty(fields[fieldName].getValue());
			});

			acceptResultsValuablesFields.forEach((name) => {
				fields[name].allowBlank = !fieldsAreBlank;
				fields[name].validate();
			});
		};

		// проверяет пустоту полей на вкладке и грида по карте полей acceptResultsTabsFields
		let areTabFieldsBlank = function (tabName) {
			let fields = documentLinesGrid.getFormFields();
			let result = true;
			let tabFields = acceptResultsTabsFields[tabName];
			let grids = documentLinesGrid.formGrids;
			for (let i = 0; i < tabFields.length; ++i) {
				let name = tabFields[i];
				if (typeof fields[name]?.getValue === 'function' && !Ext.isEmpty(fields[name].getValue())) {
					result = false;
					break;
				}

				if (grids[name] && grids[name].getStore().getCount() > 0) {
					result = false;
					break;
				}
			}

			return result;
		};

		let deviationFields = [
			'costDeviation.delta.deltaCostWithNDS',
			'costDeviation.delta.deltaSumNDS',
			'costDeviation.delta.info'
		];
		let toggleCostFieldsBlank = function () {
			let fields = documentLinesGrid.getFormFields();
			let discrepancyInfoIsEmpty = Ext.isEmpty(fields['discrepancyInfo'].getValue());

			//мапа пустых табов (кроме самого первого - он ни олт кого не зависит, поэтому его и не проверяем совсем)
			//нужна она что б много раз не гонять одни и те же проверки для разных табов, т.к. там кросс-валидация поля Количество
			let tabsEmptyMap = {};
			Object.keys(acceptResultsTabsFields).forEach((tabName) => {
				tabsEmptyMap[tabName] = areTabFieldsBlank(tabName);
			});
			//возвращает true, если все табы кроме заданного пустые
			let otherTabsAreEmpty = function (currentTabName) {
				return Object.entries(tabsEmptyMap)
					.filter(([tabName]) => tabName !== currentTabName)
					.every(([tabName, isEmpty]) => isEmpty === true);
			};

			//Поле Количество обязательно, если заполнено хотя бы одно поле данной вкладки
			// ИЛИ не заполнено "Информация о расхождениях" на вкладке "Ценности".
			//т.е. табы с количеством и не обязательны к заполнению, если мы указали Информацию о расхождениях;
			//но если уж начали что-то писать, то заполняем и количество в этом табе
			let isCountFieldRequired = function (currentTabName) {
				let currentTabIsEmpty = tabsEmptyMap[currentTabName] === true;
				return !currentTabIsEmpty || discrepancyInfoIsEmpty;
			};

			//Ценности: Информация о расхождениях обязательно, если в соседних табах ничего не заполнено
			fields['discrepancyInfo'].allowBlank = !otherTabsAreEmpty('');
			fields['discrepancyInfo'].validate();

			//Информация по документам поставщика
			fields['senderInfo.count'].allowBlank = !isCountFieldRequired('senderInfo');
			fields['senderInfo.count'].validate();

			//По факту
			fields['actualInfo.count'].allowBlank = !isCountFieldRequired('actualInfo');
			fields['actualInfo.count'].validate();

			//Брак
			fields['defect.count'].allowBlank = !isCountFieldRequired('defect');
			fields['defect.count'].validate();

			//Бой
			fields['breakage.count'].allowBlank = !isCountFieldRequired('breakage');
			fields['breakage.count'].validate();

			//Недостача
			fields['shortage.count'].allowBlank = !isCountFieldRequired('shortage');
			fields['shortage.count'].validate();

			//Излишки
			fields['surplus.count'].allowBlank = !isCountFieldRequired('surplus');
			fields['surplus.count'].validate();

			// Ценовые отклонения: обязательность поля "стоимость с НДС" обязательно, если хотя бы одно заполнено
			fields['costDeviation.delta.deltaNDS'].allowBlank = !deviationFields.some((fieldName) => {
				return fields[fieldName] && !Ext.isEmpty(fields[fieldName].getValue());
			});
			fields['costDeviation.delta.deltaNDS'].validate();
			//тип отклонения обязателен, если заполняем "стоимость с НДС"
			fields['costDeviation.deviationType'].allowBlank =
				fields['costDeviation.delta.deltaNDS'].allowBlank && !fields['costDeviation.delta.deltaNDS'].getValue();
			fields['costDeviation.deviationType'].validate();
		};

		documentLinesGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.torg2.cargo.reception.results'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			margin: '16 0 0',
			allowBlank: false,
			data: edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife2.acceptanceResult'),
			showSelectProductBtn: false,
			lockIfNoPartner: false,
			hasTotal: false,
			gridModel: 'TORG2_LINE',
			gridColumnConfig: edi.columns.get('torg_2_document_lines'),
			docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
			docData: documentData,
			lineNumberFieldName: 'LineNumber',
			callback: function (values) {
				productValues = values.products;
				checkValid();
			},
			modalConf: {
				width: MODAL_SIZE.widthLarge,
				height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
				minHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGE,
				maxHeight: undefined,
				listeners: {
					afterrender() {
						toggleValuablesBlockBlank();
						toggleCostFieldsBlank();
					}
				}
			},
			modalFormConfig: {
				title: 'document.torg2.cargo.reception.results',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.torg2.cargo.valuables'),
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.column.unit.of.measure'),
								name: 'productOkei',
								store: edi.stores.initOkeiStore(),
								type: 'combo',
								forceSelection: true,
								anyMatch: true,
								valueField: 'id',
								displayField: 'name_code'
							},
							{
								title: edi.i18n.getMessage('document.torg2.discrepancy.info'),
								name: 'discrepancyInfo',
								type: 'text',
								maxLength: 2000,
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.covering.document.line.number'),
								name: 'values.lineNumberAttachedDoc',
								type: 'number',
								maxLength: 6,
								allowDecimals: false
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.name'),
								name: 'values.valueName',
								type: 'text',
								maxLength: 1000,
								listeners: {
									change: toggleValuablesBlockBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.feature'),
								name: 'values.productDesc',
								type: 'text',
								maxLength: 1000,
								listeners: {
									change: toggleValuablesBlockBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.grade'),
								name: 'values.productSort',
								type: 'text',
								maxLength: 50
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.vendor.code'),
								name: 'values.productReference',
								maxLength: 50,
								listeners: {
									change: toggleValuablesBlockBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.code'),
								name: 'values.productCode',
								maxLength: 100,
								listeners: {
									change: toggleValuablesBlockBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.passport.number'),
								maxLength: 100,
								name: 'values.passport'
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.cargo.info.from.sender'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'senderInfo.count',
								type: 'text',
								vtype: 'fnsNumberN26_11',
								tooltip: edi.renderers.getCustomDecimalTranslation(26, 11),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.price'),
								name: 'senderInfo.cost',
								type: 'text',
								vtype: 'fnsNumberN26_11',
								tooltip: edi.renderers.getCustomDecimalTranslation(26, 11),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.column.fns.tax.rate'),
								name: 'senderInfo.rateNDS',
								type: 'combo',
								store: createSimpleStore({
									data: edi.methods.torg2.getTaxRatesList(),
									model: edi.models.getModel('SIMPLE')
								}),
								forceSelection: false,
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.cost.with.NDS'),
								name: 'senderInfo.costWithNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'senderInfo.sumNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								type: 'editableGrid',
								name: 'senderInfo.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											allowBlank: false,
											renderer: 'desadvLineEAN',
											columnWidth: 0.7,
											maxLength: 255
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											forceSelection: true,
											valueInitialize: false,
											requiredFields: ['numbers'],
											columnWidth: 0.7,
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type',
											listeners: {
												change: function (field, value) {
													var fields = documentLinesGrid.getFormFields();
													var identifyProductToolNumbersGrid =
														fields['senderInfo.identifyProductToolNumbers'];
													var packId = identifyProductToolNumbersGrid
														.getModalForm()
														.down('[name=packId]');
													packId.allowBlank = !!value;
													packId.isValid();
												}
											}
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'numbers',
											type: 'tagTextField',
											requiredFields: ['type'],
											columnWidth: 0.7,
											maxLength: 255
										}
									],
									getRowClass: function (record, fieldsConfig) {
										var isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}
										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									recordsUniqueByField: 'packId',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									},
									storeConfig: {
										remoteSort: false,
										listeners: {
											add: toggleCostFieldsBlank,
											remove: toggleCostFieldsBlank
										}
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.column.count.to.return.in.fact'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'actualInfo.count',
								type: 'text',
								vtype: 'fnsNumberN26_11',
								tooltip: edi.renderers.getCustomDecimalTranslation(26, 11),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.price'),
								name: 'actualInfo.cost',
								type: 'text',
								vtype: 'fnsNumberN26_11',
								tooltip: edi.renderers.getCustomDecimalTranslation(26, 11),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.column.fns.tax.rate'),
								name: 'actualInfo.rateNDS',
								type: 'combo',
								store: createSimpleStore({
									data: edi.methods.torg2.getTaxRatesList(),
									model: edi.models.getModel('SIMPLE')
								}),
								forceSelection: false,
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.cost.with.NDS'),
								name: 'actualInfo.costWithNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'actualInfo.sumNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								type: 'editableGrid',
								name: 'actualInfo.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											allowBlank: false,
											renderer: 'desadvLineEAN',
											columnWidth: 0.7,
											maxLength: 255
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											forceSelection: true,
											valueInitialize: false,
											requiredFields: ['numbers'],
											columnWidth: 0.7,
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type',
											listeners: {
												change: function (field, value) {
													var fields = documentLinesGrid.getFormFields();
													var identifyProductToolNumbersGrid =
														fields['actualInfo.identifyProductToolNumbers'];
													var packId = identifyProductToolNumbersGrid
														.getModalForm()
														.down('[name=packId]');
													packId.allowBlank = !!value;
													packId.isValid();
												}
											}
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'numbers',
											type: 'tagTextField',
											requiredFields: ['type'],
											columnWidth: 0.7,
											maxLength: 255
										}
									],
									getRowClass: function (record, fieldsConfig) {
										var isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}
										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									recordsUniqueByField: 'packId',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									},
									storeConfig: {
										listeners: {
											add: toggleCostFieldsBlank,
											remove: toggleCostFieldsBlank
										}
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.cargo.defect'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'defect.count',
								type: 'text',
								vtype: 'fnsNumberN26_11',
								tooltip: edi.renderers.getCustomDecimalTranslation(26, 11),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.position.sum.with.NDS'),
								name: 'defect.costWithNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'defect.sumNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.comment.on.discrepancies'),
								name: 'defect.info',
								maxLength: 255,
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								type: 'editableGrid',
								name: 'defect.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											allowBlank: false,
											renderer: 'desadvLineEAN',
											columnWidth: 0.7,
											maxLength: 255
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											forceSelection: true,
											valueInitialize: false,
											requiredFields: ['numbers'],
											columnWidth: 0.7,
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type',
											listeners: {
												change: function (field, value) {
													var fields = documentLinesGrid.getFormFields();
													var identifyProductToolNumbersGrid =
														fields['defect.identifyProductToolNumbers'];
													var packId = identifyProductToolNumbersGrid
														.getModalForm()
														.down('[name=packId]');
													packId.allowBlank = !!value;
													packId.isValid();
												}
											}
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'numbers',
											type: 'tagTextField',
											requiredFields: ['type'],
											columnWidth: 0.7,
											maxLength: 255
										}
									],
									getRowClass: function (record, fieldsConfig) {
										var isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}
										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									recordsUniqueByField: 'packId',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									},
									storeConfig: {
										listeners: {
											add: toggleCostFieldsBlank,
											remove: toggleCostFieldsBlank
										}
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.cargo.damage'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'breakage.count',
								type: 'text',
								vtype: 'fnsNumberN26_11',
								tooltip: edi.renderers.getCustomDecimalTranslation(26, 11),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.position.sum.with.NDS'),
								name: 'breakage.costWithNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'breakage.sumNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.comment.on.discrepancies'),
								name: 'breakage.info',
								maxLength: 255,
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								type: 'editableGrid',
								name: 'breakage.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											allowBlank: false,
											renderer: 'desadvLineEAN',
											columnWidth: 0.7,
											maxLength: 255
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											forceSelection: true,
											valueInitialize: false,
											requiredFields: ['numbers'],
											columnWidth: 0.7,
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type',
											listeners: {
												change: function (field, value) {
													var fields = documentLinesGrid.getFormFields();
													var identifyProductToolNumbersGrid =
														fields['breakage.identifyProductToolNumbers'];
													var packId = identifyProductToolNumbersGrid
														.getModalForm()
														.down('[name=packId]');
													packId.allowBlank = !!value;
													packId.isValid();
												}
											}
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'numbers',
											type: 'tagTextField',
											requiredFields: ['type'],
											columnWidth: 0.7,
											maxLength: 255
										}
									],
									getRowClass: function (record, fieldsConfig) {
										var isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}
										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									recordsUniqueByField: 'packId',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									},
									storeConfig: {
										listeners: {
											add: toggleCostFieldsBlank,
											remove: toggleCostFieldsBlank
										}
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.dsf.deficit'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'shortage.count',
								type: 'text',
								vtype: 'fnsNumberN26_11',
								tooltip: edi.renderers.getCustomDecimalTranslation(26, 11),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.position.sum.with.NDS'),
								name: 'shortage.costWithNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'shortage.sumNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.comment.on.discrepancies'),
								name: 'shortage.info',
								maxLength: 255,
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								type: 'editableGrid',
								name: 'shortage.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											allowBlank: false,
											renderer: 'desadvLineEAN',
											columnWidth: 0.7,
											maxLength: 255
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											forceSelection: true,
											valueInitialize: false,
											requiredFields: ['numbers'],
											columnWidth: 0.7,
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type',
											listeners: {
												change: function (field, value) {
													var fields = documentLinesGrid.getFormFields();
													var identifyProductToolNumbersGrid =
														fields['shortage.identifyProductToolNumbers'];
													var packId = identifyProductToolNumbersGrid
														.getModalForm()
														.down('[name=packId]');
													packId.allowBlank = !!value;
													packId.isValid();
												}
											}
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'numbers',
											type: 'tagTextField',
											requiredFields: ['type'],
											columnWidth: 0.7,
											maxLength: 255
										}
									],
									getRowClass: function (record, fieldsConfig) {
										var isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}
										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									recordsUniqueByField: 'packId',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									},
									storeConfig: {
										listeners: {
											add: toggleCostFieldsBlank,
											remove: toggleCostFieldsBlank
										}
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.column.count.to.return.surplus'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'surplus.count',
								type: 'text',
								vtype: 'fnsNumberN26_11',
								tooltip: edi.renderers.getCustomDecimalTranslation(26, 11),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.position.sum.with.NDS'),
								name: 'surplus.costWithNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'surplus.sumNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.comment.on.discrepancies'),
								name: 'surplus.info',
								maxLength: 255,
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								type: 'editableGrid',
								name: 'surplus.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											allowBlank: false,
											renderer: 'desadvLineEAN',
											columnWidth: 0.7,
											maxLength: 255
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											forceSelection: true,
											valueInitialize: false,
											requiredFields: ['numbers'],
											columnWidth: 0.7,
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type',
											listeners: {
												change: function (field, value) {
													var fields = documentLinesGrid.getFormFields();
													var identifyProductToolNumbersGrid =
														fields['surplus.identifyProductToolNumbers'];
													var packId = identifyProductToolNumbersGrid
														.getModalForm()
														.down('[name=packId]');
													packId.allowBlank = !!value;
													packId.isValid();
												}
											}
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'numbers',
											type: 'tagTextField',
											requiredFields: ['type'],
											columnWidth: 0.7,
											maxLength: 255
										}
									],
									getRowClass: function (record, fieldsConfig) {
										var isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}
										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									recordsUniqueByField: 'packId',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									},
									storeConfig: {
										listeners: {
											add: toggleCostFieldsBlank,
											remove: toggleCostFieldsBlank
										}
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.cargo.price.divergences'),
						autoScroll: true,
						items: [
							{
								store: edi.stores.createSimpleInlineStore(
									['positiveDelta', 'negativeDelta'],
									(id) => edi.i18n.getMessage('document.torg2.delta.' + id),
									true
								),
								displayField: 'name',
								valueField: 'id',
								type: 'combo',
								forceSelection: true,
								title: edi.i18n.getMessage('document.torg2.delta.type'),
								name: 'costDeviation.deviationType'
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.cost'),
								name: 'costDeviation.delta.deltaCostWithNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.costWithNDS'),
								name: 'costDeviation.delta.deltaNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.sumNDS'),
								name: 'costDeviation.delta.deltaSumNDS',
								type: 'text',
								vtype: 'fnsNumberN19_2',
								tooltip: edi.renderers.getCustomDecimalTranslation(19, 2),
								listeners: {
									change: toggleCostFieldsBlank
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.info'),
								name: 'costDeviation.delta.info',
								maxLength: 255,
								listeners: {
									change: toggleCostFieldsBlank
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.info.fields'),
						layout: 'fit',
						items: [
							{
								type: 'editableGrid',
								name: 'info',
								config: {
									fields: [
										{
											name: 'identifier',
											type: 'text',
											minLength: 1,
											maxLength: 50,
											allowBlank: false,
											renderer: function (value, meta, record) {
												if (record.get('infoFieldTooltip')) {
													meta.tdAttr =
														'data-qtip="' +
														edi.utils.replaceQuotes(record.get('infoFieldTooltip')) +
														'"';
												}
												return value;
											}
										},
										{
											name: 'value',
											type: 'text',
											minLength: 1,
											maxLength: 2000,
											allowBlank: false,
											renderer: function (value, meta, record) {
												if (record.get('infoFieldTooltip')) {
													meta.tdAttr =
														'data-qtip="' +
														edi.utils.replaceQuotes(record.get('infoFieldTooltip')) +
														'"';
												}
												return value;
											}
										}
									],
									getRowClass: function (record, fieldsConfig) {
										var isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												record.get('allowEmptyValue') ||
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}

										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_TEXT_INFO',
									recordsUniqueByField: 'identifier',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									gridConfig: {
										cls: 'modal-grid',
										title: edi.i18n.getMessage('line.item.tab.inf.pol.str')
									}
								}
							}
						]
					}
				]
			}
		});

		return [documentLinesGrid];
	};

	var createIdFileInfo = function (labelText, idName, infoFieldName, changeListener) {
		var infoFieldLabel = createLabel({
			margin: '5 0 0 5',
			text: edi.i18n.getMessage(labelText)
		});

		var idFile = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.6,
					name: idName,
					valueSrc: documentData,
					regex: /^[\w-_]{36}$/,
					regexText: edi.i18n.getMessage('document.torg2.id.file.tooltip'),
					fieldLabel: edi.i18n.getMessage('document.torg2.inspection.id.file'),
					listeners: {
						change: function (cmp, newValue) {
							if (changeListener && 'function' === typeof changeListener) {
								changeListener(cmp, newValue);
							}
						}
					}
				})
			]
		});

		var createInfoField = function (isFirstRow, fieldData) {
			fieldData = fieldData || {};

			let validateInputs = function () {
				if (identifier && value) {
					let bothAreEmpty = !identifier.getValue() && !value.getValue();
					identifier.allowBlank = bothAreEmpty;
					identifier.isValid();
					value.allowBlank = bothAreEmpty;
					value.isValid();
				}
			};

			let identifier = createTextField({
				margin: '0 0 0 5',
				columnWidth: 0.6,
				maxLength: 50,
				name: infoFieldName + '.identifier',
				value: edi.utils.getObjectProperty(fieldData, 'identifier'),
				fieldLabel: edi.i18n.getMessage('document.torg2.identifier'),
				listeners: {
					change: function (cmp, newValue) {
						validateInputs();
						checkValid();
						if (changeListener && 'function' === typeof changeListener) {
							changeListener(cmp, newValue);
						}
					}
				}
			});
			let value = createTextField({
				margin: '0 0 0 5',
				columnWidth: 0.4,
				maxLength: 2000,
				name: infoFieldName + '.value',
				value: edi.utils.getObjectProperty(fieldData, 'value'),
				fieldLabel: edi.i18n.getMessage('document.torg2.value'),
				listeners: {
					change: function (cmp, newValue) {
						validateInputs();
						checkValid();
						if (changeListener && 'function' === typeof changeListener) {
							changeListener(cmp, newValue);
						}
					}
				}
			});
			return createPanel({
				layout: 'column',
				items: [identifier, value]
			});
		};

		const infoFieldField = createRowsBlock({
			createContentFieldsFn: createInfoField,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			initialData: edi.utils.getObjectProperty(documentData, infoFieldName, true),
			onAddRow() {
				checkValid();
			},
			onRemoveRow() {
				checkValid();
			}
		});
		return [infoFieldLabel, idFile, infoFieldField];
	};

	// аккордеон Осмотр груза
	var cargoInspectionInformationItems = function () {
		var checkFillingInspectionDateValues = function () {
			return (
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.inspectionDate'
				) ||
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.startTime'
				) ||
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.endTime'
				)
			);
		};

		var checkFillingCargoInspection = function () {
			return (
				checkFillingInspectionDateValues() ||
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.sendDate'
				) ||
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.createDocPlace'
				)
			);
		};

		var checkFillingSection = function () {
			var allCerts = edi.utils.getObjectProperty(
				documentData,
				'document.contentFactEconomLife1.inspectionCargoInfo.conformCertificate'
			);
			var allAdditionals = edi.utils.getObjectProperty(
				documentData,
				'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.textInfo'
			);
			var isEmptyCerts = Array.isArray(allCerts) ? !allCerts.some((it) => !!it) : !allCerts;
			var isEmptyAdditionals = Array.isArray(allAdditionals)
				? !allAdditionals.some((it) => !!it)
				: !allAdditionals;
			return (
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.numberAccompanyingDoc'
				) ||
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.dateAccompanyingDoc'
				) ||
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.additionalAccompanyingDoc'
				) ||
				!!edi.utils.getObjectProperty(
					documentData,
					'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.fileId'
				) ||
				!isEmptyCerts ||
				!isEmptyAdditionals
			);
		};

		var runtimeCheckFillingDate = function (form) {
			var dateField = form.down('[name=document.contentFactEconomLife1.inspectionCargoInfo.inspectionDate]');
			var startTimeField = form.down('[name=document.contentFactEconomLife1.inspectionCargoInfo.startTime]');
			var endTimeField = form.down('[name=document.contentFactEconomLife1.inspectionCargoInfo.endTime]');
			return (
				!Ext.isEmpty(dateField.getValue()) ||
				!Ext.isEmpty(startTimeField.getValue()) ||
				!Ext.isEmpty(endTimeField.getValue())
			);
		};

		var runtimeCheckFillingInspection = function (form) {
			var sendDateField = form.down('[name=document.contentFactEconomLife1.inspectionCargoInfo.sendDate]');
			var docPlaceField = form.down('[name=document.contentFactEconomLife1.inspectionCargoInfo.createDocPlace]');
			return (
				!Ext.isEmpty(sendDateField.getValue()) ||
				!Ext.isEmpty(docPlaceField.getValue()) ||
				runtimeCheckFillingDate(form)
			);
		};

		var runtimeCheckFillingSection = function (form) {
			var allValues = form.getValues();
			var certificateFieldValue =
				allValues['document.contentFactEconomLife1.inspectionCargoInfo.conformCertificate'];
			var fileFieldValue =
				allValues['document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.fileId'];
			var identFieldValue =
				allValues[
					'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.textInfo.identifier'
				];
			var valFieldValue =
				allValues[
					'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.textInfo.value'
				];
			var docNumberFieldValue =
				allValues['document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.numberAccompanyingDoc'];
			var docDateFieldValue =
				allValues['document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.dateAccompanyingDoc'];
			var additionFieldValue =
				allValues[
					'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.additionalIdAccompanyingDoc'
				];
			var isCertEmpty = Array.isArray(certificateFieldValue)
				? !certificateFieldValue.some((it) => !!it)
				: !certificateFieldValue;
			var isIdentEmpty = Array.isArray(identFieldValue) ? !identFieldValue.some((it) => !!it) : !identFieldValue;
			var isValEmpty = Array.isArray(valFieldValue) ? !valFieldValue.some((it) => !!it) : !valFieldValue;
			return (
				!isCertEmpty ||
				!Ext.isEmpty(fileFieldValue) ||
				!isIdentEmpty ||
				!isValEmpty ||
				!Ext.isEmpty(docNumberFieldValue) ||
				!Ext.isEmpty(docDateFieldValue) ||
				!Ext.isEmpty(additionFieldValue) ||
				runtimeCheckFillingInspection(form)
			);
		};

		var toggleBlankAccompanyingDocName = function (form) {
			var docField = form.down(
				'[name=document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.nameAccompanyingDoc]'
			);
			docField.allowBlank = !runtimeCheckFillingSection(form);
			docField.validate();
		};

		var isRequiredInspectionTime = checkFillingInspectionDateValues();
		var isRequiredWithinInspection = checkFillingCargoInspection();
		var isRequiredWithinSection = checkFillingSection();

		var CargoInspectionDate = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createDateField({
					margin: '0 0 0 5',
					name: 'document.contentFactEconomLife1.inspectionCargoInfo.inspectionDate',
					columnWidth: 0.16,
					value: edi.utils.getObjectProperty(
						documentData,
						'document.contentFactEconomLife1.inspectionCargoInfo.inspectionDate'
					),
					fieldLabel: edi.i18n.getMessage('document.torg2.cargo.date'),
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					allowBlank: !isRequiredInspectionTime,
					listeners: {
						change: function (cmp) {
							isRequiredInspectionTime = runtimeCheckFillingDate(cmp.up('form'));
							isRequiredWithinInspection = runtimeCheckFillingInspection(cmp.up('form'));
							updateInspectionDateFields();
							toggleBlankAccompanyingDocName(cmp.up('form'));
						}
					}
				}),
				createTimeField({
					margin: '0 0 0 5',
					format: 'H:i:s',
					forceSelection: false,
					columnWidth: 0.16,
					name: 'document.contentFactEconomLife1.inspectionCargoInfo.startTime',
					valueSrc: documentData,
					fieldLabel: edi.i18n.getMessage('document.torg2.start.inspection.time'),
					allowBlank: !isRequiredInspectionTime,
					listeners: {
						change: function (cmp) {
							isRequiredInspectionTime = runtimeCheckFillingDate(cmp.up('form'));
							isRequiredWithinInspection = runtimeCheckFillingInspection(cmp.up('form'));
							updateInspectionDateFields();
							toggleBlankAccompanyingDocName(cmp.up('form'));
						}
					}
				}),
				createTimeField({
					margin: '0 0 0 5',
					columnWidth: 0.16,
					format: 'H:i:s',
					forceSelection: false,
					name: 'document.contentFactEconomLife1.inspectionCargoInfo.endTime',
					valueSrc: documentData,
					fieldLabel: edi.i18n.getMessage('document.torg2.finish.inspection.time'),
					allowBlank: !isRequiredInspectionTime,
					listeners: {
						change: function (cmp) {
							isRequiredInspectionTime = runtimeCheckFillingDate(cmp.up('form'));
							isRequiredWithinInspection = runtimeCheckFillingInspection(cmp.up('form'));
							updateInspectionDateFields();
							toggleBlankAccompanyingDocName(cmp.up('form'));
						}
					}
				}),
				createDateField({
					margin: '0 0 0 5',
					name: 'document.contentFactEconomLife1.inspectionCargoInfo.sendDate',
					columnWidth: 0.16,
					value: edi.utils.getObjectProperty(
						documentData,
						'document.contentFactEconomLife1.inspectionCargoInfo.sendDate'
					),
					fieldLabel: edi.i18n.getMessage('document.torg2.send.cargo.date'),
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					listeners: {
						change: function (cmp) {
							toggleBlankAccompanyingDocName(cmp.up('form'));
						}
					}
				}),
				createTextField({
					margin: '0 0 0 5',
					name: 'document.contentFactEconomLife1.inspectionCargoInfo.createDocPlace',
					columnWidth: 0.36,
					maxLength: 100,
					value: edi.utils.getObjectProperty(
						documentData,
						'document.contentFactEconomLife1.inspectionCargoInfo.createDocPlace'
					),
					fieldLabel: edi.i18n.getMessage('document.torg2.creation.place'),
					listeners: {
						change: function (cmp) {
							toggleBlankAccompanyingDocName(cmp.up('form'));
						}
					}
				})
			]
		});

		var updateInspectionDateFields = function () {
			var inspectionDate = CargoInspectionDate.down(
				'[name=document.contentFactEconomLife1.inspectionCargoInfo.inspectionDate]'
			);
			var inspectionStartTime = CargoInspectionDate.down(
				'[name=document.contentFactEconomLife1.inspectionCargoInfo.startTime]'
			);
			var inspectionEndTime = CargoInspectionDate.down(
				'[name=document.contentFactEconomLife1.inspectionCargoInfo.endTime]'
			);
			inspectionDate.allowBlank = !isRequiredInspectionTime;
			inspectionStartTime.allowBlank = !isRequiredInspectionTime;
			inspectionEndTime.allowBlank = !isRequiredInspectionTime;
			inspectionDate.validate();
			inspectionStartTime.validate();
			inspectionEndTime.validate();
			CargoInspectionDate.update();
			toggleBlankAccompanyingDocName(CargoInspectionDate.up('form'));
		};

		var accompanyingDocumentsLabel = createLabel({
			margin: '5 0 0 5',
			text: edi.i18n.getMessage('document.torg2.covering.document')
		});

		var accompanyingDocumentsFields = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.48,
					name: valuesMap.nameAccompanyingDoc.path,
					valueSrc: documentData,
					allowBlank: !isRequiredWithinSection,
					maxLength: 200,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.name')
				}),
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.16,
					name: valuesMap.numberAccompanyingDoc.path,
					valueSrc: documentData,
					maxLength: 100,
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					listeners: {
						change: function (cmp) {
							toggleBlankAccompanyingDocName(cmp.up('form'));
						}
					}
				}),
				createDateField({
					margin: '0 0 0 5',
					columnWidth: 0.16,
					name: 'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.dateAccompanyingDoc',
					value: edi.utils.getObjectProperty(
						documentData,
						'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.dateAccompanyingDoc'
					),
					fieldLabel: edi.i18n.getMessage('document.torg2.document.date'),
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					listeners: {
						change: function (cmp) {
							toggleBlankAccompanyingDocName(cmp.up('form'));
						}
					}
				}),
				createTextField({
					margin: '0 0 0 5',
					columnWidth: 0.2,
					name: 'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.additionalIdAccompanyingDoc',
					valueSrc: documentData,
					fieldLabel: edi.i18n.getMessage('document.torg2.additional.document.identifier'),
					maxLength: 255,
					listeners: {
						change: function (cmp) {
							toggleBlankAccompanyingDocName(cmp.up('form'));
						}
					}
				})
			]
		});

		var certificateOfComplianceLabel = createLabel({
			margin: '5 0 0 5',
			text: edi.i18n.getMessage('document.torg2.certificate.of.compliance')
		});

		var createCertificateOfCompliance = function (isFirstRow, fieldData) {
			var certificateNumber = createTextField({
				margin: '0 0 0 5',
				columnWidth: 1,
				maxLength: 50,
				name: 'document.contentFactEconomLife1.inspectionCargoInfo.conformCertificate',
				value: Object.keys(fieldData).length > 0 ? fieldData : '',
				fieldLabel: edi.i18n.getMessage('line.item.certificate.number'),
				listeners: {
					change: function (cmp, newValue, oldValue) {
						toggleBlankAccompanyingDocName(cmp.up('form'));
					}
				}
			});
			return createPanel({
				layout: 'column',
				items: [certificateNumber]
			});
		};

		const certificateOfComplianceField = createRowsBlock({
			createContentFieldsFn: createCertificateOfCompliance,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			initialData: edi.utils.getObjectProperty(
				documentData,
				'document.contentFactEconomLife1.inspectionCargoInfo.conformCertificate',
				true
			),
			onAddRow() {
				checkValid();
			},
			onRemoveRow() {
				checkValid();
			}
		});

		var fileInfoChangeListener = function (cmp, newValue) {
			toggleBlankAccompanyingDocName(cmp.up('form'));
		};

		var idFileInfo = createIdFileInfo(
			'document.torg2.inspection.info.field',
			'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.fileId',
			'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.textInfo',
			fileInfoChangeListener
		);

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.cargo.inspection'),
				collapsible: true,
				items: [
					CargoInspectionDate, //строка 1
					accompanyingDocumentsLabel,
					accompanyingDocumentsFields, //строка 2
					certificateOfComplianceLabel,
					certificateOfComplianceField, //строка 3
					idFileInfo[0],
					idFileInfo[1],
					idFileInfo[2] //строка 4
				]
			})
		];
	};

	var cargoDetailsCoveringDocumentsGrid = function () {
		cargoInfoByAccompanyingDocuments = edi.utils.getObjectProperty(
			documentData,
			'document.contentFactEconomLife1.attachedDocInfo'
		);
		var cargoInfoByAccompanyingDocumentsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.torg2.cargo.covering.document.info'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			margin: '16 0 0',
			data: edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.attachedDocInfo'),
			showSelectProductBtn: false,
			lockIfNoPartner: false,
			hasTotal: false,
			allowBlank: true,
			gridModel: 'CARGO_INFO_BY_ACCOMPANYING_DOCUMENTS',
			gridColumnConfig: edi.columns.get('cargoInfoByAccompanyingDocuments'),
			callback: function (values) {
				cargoInfoByAccompanyingDocuments = values.products;
				checkValid();
			},
			afterRowRemove: function (record) {
				//Если запись в гриде одна, при её удалении callback не дергается
				//поэтому обновляем значение здесь
				const values = this?.getValues();
				cargoInfoByAccompanyingDocuments = values.products;
			},
			modalFormConfig: {
				title: 'document.torg2.cargo.covering.document.info',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.torg2.cargo.covering.document.info'),
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.cargo.name'),
								allowBlank: false,
								maxLength: 1000,
								name: 'nameCargo'
							},
							{
								title: edi.i18n.getMessage('document.torg2.count.place'),
								allowBlank: false,
								maxLength: 12,
								name: 'spaceCount',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('product.unit.name'),
								name: 'productOkei',
								store: edi.stores.initOkeiStore(),
								type: 'combo',
								forceSelection: true,
								anyMatch: true,
								valueField: 'id',
								displayField: 'name_code',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.torg2.view.packaging'),
								name: 'packType',
								maxLength: 100,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.torg2.sealing.mark'),
								name: 'sealMark',
								maxLength: 20,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.torg2.sent.gross.mass'),
								name: 'weightCargoStart.gross',
								type: 'number',
								allowDecimals: true,
								maxLength: 26,
								decimalPrecision: 11
							},
							{
								title: edi.i18n.getMessage('document.torg2.sent.net.mass'),
								name: 'weightCargoStart.net',
								type: 'number',
								allowDecimals: true,
								maxLength: 26,
								decimalPrecision: 11
							},
							{
								title: edi.i18n.getMessage('document.torg2.received.gross.mass'),
								name: 'weightCargoActual.gross',
								type: 'number',
								allowDecimals: true,
								maxLength: 26,
								decimalPrecision: 11
							},
							{
								title: edi.i18n.getMessage('document.torg2.received.net.mass'),
								name: 'weightCargoActual.net',
								type: 'number',
								allowDecimals: true,
								maxLength: 26,
								decimalPrecision: 11
							},
							{
								title: edi.i18n.getMessage('document.torg2.identifier'),
								name: 'textInfo.identifier',
								maxLength: 50,
								listeners: {
									change: function (cmp, newValue, oldValue) {
										var valueField = cmp.up('form').down('[name=textInfo.value]');
										valueField.allowBlank = !newValue;
										valueField.validate();
									}
								}
							},
							{
								title: edi.i18n.getMessage('document.torg2.value'),
								name: 'textInfo.value',
								maxLength: 2000,
								listeners: {
									change: function (cmp, newValue, oldValue) {
										var identField = cmp.up('form').down('[name=textInfo.identifier]');
										identField.allowBlank = !newValue;
										identField.validate();
									}
								}
							}
						]
					}
				]
			}
		});

		return [cargoInfoByAccompanyingDocumentsGrid];
	};

	/**
	 * Подготовка данных для передачи на бэк-энд (форматирование)
	 * @param values		данные с формы
	 * @returns {Object}	объект в формате, ожидаемом на бэк-энде
	 */
	var convertFormValuesToProduct = function (values) {
		var convertTime = function (timeStr) {
			return Ext.isString(timeStr) ? timeStr.replaceAll(':', '.') : null;
		};

		var cargoInfo = edi.utils.getObjectProperty(values, 'document.contentFactEconomLife1.inspectionCargoInfo');
		if (cargoInfo) {
			cargoInfo.startTime = convertTime(cargoInfo.startTime);
			cargoInfo.endTime = convertTime(cargoInfo.endTime);
		}

		var cargoAccept = edi.utils.getObjectProperty(values, 'document.contentFactEconomLife1.acceptanceTimeInfo');
		if (cargoAccept) {
			for (var i = 0; i < cargoTimeFields.length; ++i) {
				var pureDate = edi.utils.getObjectProperty(values, cargoTimeFields[i] + 'PureDate') || '';
				var pureTime = edi.utils.getObjectProperty(values, cargoTimeFields[i] + 'PureTime') || '';
				pureTime = pureTime.replaceAll(':', '.');
				if (pureDate && pureTime) {
					edi.utils.setObjectProperty(values, cargoTimeFields[i] + 'Time', `${pureDate} ${pureTime}`);
				}
				edi.utils.deleteObjectProperty(values, cargoTimeFields[i] + 'PureDate');
				edi.utils.deleteObjectProperty(values, cargoTimeFields[i] + 'PureTime');
			}
		}

		var getMeasureUnitName = function (unitId) {
			const okeiStore = edi.stores.initLegacyOkeiStore();
			const index = okeiStore.findExact('id', unitId);
			return index !== -1 ? okeiStore.getAt(index).get('name') : unitId;
		};

		if (!hasDataFromTransformation) {
			edi.utils.setObjectProperty(values, 'document.nameEconomicSubject', buyer.getValues().company_name);
		} else {
			var nameEconomicSubject = edi.utils.getObjectProperty(documentData, 'document.nameEconomicSubject');
			edi.utils.setObjectProperty(values, 'document.nameEconomicSubject', nameEconomicSubject);
		}

		// закомментировано до выяснения причин (не сохраняется на бэк, выдаёт ошибку)
		/*
    	var buyerOrg = edi.utils.getOrg({
			orgId: buyerOrgId
		});
		edi.utils.setObjectProperty(values, 'document.contentFactEconomLife1.cargoSender.identification.organizationInfo.informationAboutForeigner.orgName', sellerOrg.name);
		edi.utils.setObjectProperty(values, 'document.contentFactEconomLife1.cargoSender.identification.organizationInfo.informationAboutForeigner.identifier', senderId);
		edi.utils.setObjectProperty(values, 'document.contentFactEconomLife1.cargoReceiver.identification.organizationInfo.informationAboutForeigner.orgName', buyerOrg.name);
		edi.utils.setObjectProperty(values, 'document.contentFactEconomLife1.cargoReceiver.identification.organizationInfo.informationAboutForeigner.identifier', receiverId);
		edi.utils.deleteObjectProperty(values, 'document.contentFactEconomLife1.cargoReceiver.identification.organizationInfo.legalEntity');
		edi.utils.deleteObjectProperty(values, 'document.contentFactEconomLife1.cargoSender.identification.organizationInfo.legalEntity');
		*/

		var goods = edi.utils.getObjectProperty(values, 'document.contentFactEconomLife1.attachedDocInfo');
		if (Array.isArray(goods) && goods.length > 0) {
			for (var j = 0; j < goods.length; ++j) {
				var okei = edi.utils.getObjectProperty(goods[j], 'productOkei');
				edi.utils.setObjectProperty(goods[j], 'unitOfMeasure', getMeasureUnitName(okei));
			}
		}

		var results = edi.utils.getObjectProperty(values, 'document.contentFactEconomLife2.acceptanceResult');
		if (Array.isArray(results) && results.length > 0) {
			for (var k = 0; k < goods.length; ++k) {
				var resOkei = edi.utils.getObjectProperty(results[k], 'productOkei');
				edi.utils.setObjectProperty(results[k], 'unitOfMeasure', getMeasureUnitName(resOkei));
			}

			// приведение значений отклонения в зависимости от выбранного типа
			for (var m = 0; m < results.length; ++m) {
				var deviationType = edi.utils.getObjectProperty(results[m], 'costDeviation.deviationType');
				if (deviationType) {
					var deltaCostWithNDS = edi.utils.getObjectProperty(
						results[m],
						'costDeviation.delta.deltaCostWithNDS'
					);
					var deltaNDS = edi.utils.getObjectProperty(results[m], 'costDeviation.delta.deltaNDS');
					var deltaSumNDS = edi.utils.getObjectProperty(results[m], 'costDeviation.delta.deltaSumNDS');
					var deltaInfo = edi.utils.getObjectProperty(results[m], 'costDeviation.delta.info');
					if (deviationType === 'positiveDelta') {
						edi.utils.setObjectProperty(
							results[m],
							'costDeviation.positiveDelta.deltaCostWithNDS',
							deltaCostWithNDS
						);
						edi.utils.setObjectProperty(results[m], 'costDeviation.positiveDelta.deltaNDS', deltaNDS);
						edi.utils.setObjectProperty(results[m], 'costDeviation.positiveDelta.deltaSumNDS', deltaSumNDS);
						edi.utils.setObjectProperty(results[m], 'costDeviation.positiveDelta.info', deltaInfo);
					} else {
						edi.utils.setObjectProperty(
							results[m],
							'costDeviation.negativeDelta.deltaCostWithNDS',
							deltaCostWithNDS
						);
						edi.utils.setObjectProperty(results[m], 'costDeviation.negativeDelta.deltaNDS', deltaNDS);
						edi.utils.setObjectProperty(results[m], 'costDeviation.negativeDelta.deltaSumNDS', deltaSumNDS);
						edi.utils.setObjectProperty(results[m], 'costDeviation.negativeDelta.info', deltaInfo);
					}
					edi.utils.deleteObjectProperty(results[m], 'costDeviation.delta.deltaCostWithNDS');
					edi.utils.deleteObjectProperty(results[m], 'costDeviation.delta.deltaNDS');
					edi.utils.deleteObjectProperty(results[m], 'costDeviation.delta.deltaSumNDS');
					edi.utils.deleteObjectProperty(results[m], 'costDeviation.delta.info');
					edi.utils.deleteObjectProperty(results[m], 'costDeviation.delta');
					edi.utils.deleteObjectProperty(results[m], 'costDeviation.deviationType');
				}
			}
		}

		return values;
	};

	/**
	 * странное поведение полей productOkei и unitOfMeasure на UI, приходится
	 *  постоянно их гонять
	 */
	var updateFieldsBeforeRender = function () {
		var goods = edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife1.attachedDocInfo');
		if (Array.isArray(goods) && goods.length > 0) {
			for (var j = 0; j < goods.length; ++j) {
				var okei = edi.utils.getObjectProperty(goods[j], 'productOkei');
				edi.utils.setObjectProperty(goods[j], 'unitOfMeasure', okei);
			}
		}

		var results = edi.utils.getObjectProperty(documentData, 'document.contentFactEconomLife2.acceptanceResult');
		if (Array.isArray(results) && results.length > 0) {
			for (var k = 0; k < goods.length; ++k) {
				var resOkei = edi.utils.getObjectProperty(results[k], 'productOkei');
				edi.utils.setObjectProperty(results[k], 'unitOfMeasure', resOkei);
			}
		}

		var convertTimeForUI = function (timeStr) {
			return Ext.isString(timeStr) ? timeStr.replace('.', ':').substring(0, 5) : null;
		};

		var cargoInfo = edi.utils.getObjectProperty(
			documentData,
			'document.contentFactEconomLife1.inspectionCargoInfo'
		);
		if (cargoInfo) {
			cargoInfo.startTime = convertTimeForUI(cargoInfo.startTime);
			cargoInfo.endTime = convertTimeForUI(cargoInfo.endTime);
		}

		var acceptanceArray = edi.utils.getObjectProperty(
			documentData,
			'document.contentFactEconomLife2.acceptanceResult'
		);
		for (var m = 0; m < acceptanceArray.length; ++m) {
			var costDeviation = acceptanceArray[m].costDeviation;
			if (costDeviation) {
				if (costDeviation.positiveDelta) {
					costDeviation.delta = costDeviation.positiveDelta;
					costDeviation.deviationType = 'positiveDelta';
				} else {
					costDeviation.delta = costDeviation.negativeDelta;
					costDeviation.deviationType = 'negativeDelta';
				}
			}
		}
	};

	var save = function (values) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));

		var checkSignersPersonType = function (signers) {
			for (var i = 0; i < signers.length; i++) {
				if (signers[i].status === '4' && userData.org.orgType !== edi.constants.ORGANIZATION_TYPES.INDIVIDUAL) {
					var personPropName = 'individual';
					if (
						!signers[i].hasOwnProperty(personPropName) ||
						Object.keys(signers[i][personPropName]).length === 0
					) {
						['individualPerson', 'legalEntity'].every(function (value) {
							if (signers[i].hasOwnProperty(value) && Object.keys(signers[i][value]).length > 0) {
								if (
									signerFillFromCert.hasOwnProperty('inn') &&
									String(signerFillFromCert['inn']).substring(0, 2) !== '00'
								) {
									signers[i][value]['tin'] = signerFillFromCert['inn'];
								} else {
									delete signers[i][value]['tin'];
								}
								signers[i][personPropName] = signers[i][value];
								signers[i][value]['name'] = signers[i][value]['fullName'];
								delete signers[i][value]['fullName'];

								delete signers[i][value];
								return false;
							}
							return true;
						});
					}
				}
				if (signers[i].status !== '4' && !signers[i].legalEntity.position) {
					signers[i].legalEntity.position = edi.i18n.getMessage('document.torg2.position');
				}
			}
			return signers;
		};

		var signer =
			values && values.signer
				? checkSignersPersonType(
						edi.methods.convertValuesFromMultipleFields(edi.utils.getObjectProperty(values, 'signer'))
				  )
				: {};
		if (signer) {
			edi.utils.setObjectProperty(values, 'document.signer', signer);
			edi.utils.deleteObjectProperty(values, 'signer');
		}
		var textInfo = edi.utils.getObjectProperty(values, 'document.contentFactEconomLife1.info.textInfo');
		if (textInfo) {
			edi.utils.setObjectProperty(
				values,
				'document.contentFactEconomLife1.info.textInfo',
				edi.methods.convertValuesFromMultipleFields(textInfo)
			);
		}
		var cargoInfo = edi.utils.getObjectProperty(
			values,
			'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.textInfo'
		);
		if (cargoInfo) {
			edi.utils.setObjectProperty(
				values,
				'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.textInfo',
				edi.methods.convertValuesFromMultipleFields(cargoInfo)
			);
		}
		var additionalDocInfo = edi.utils.getObjectProperty(values, 'document.contentFactEconomLife2.attachedDoc');
		if (additionalDocInfo) {
			edi.utils.setObjectProperty(
				values,
				'document.contentFactEconomLife2.attachedDoc',
				edi.methods.convertValuesFromMultipleFields(additionalDocInfo)
			);
		}
		var lifeInfo = edi.utils.getObjectProperty(values, 'document.contentFactEconomLife2.info');
		if (lifeInfo) {
			edi.utils.setObjectProperty(
				values,
				'document.contentFactEconomLife2.info',
				edi.methods.convertValuesFromMultipleFields(lifeInfo)
			);
		}

		if (cargoInfoByAccompanyingDocuments && cargoInfoByAccompanyingDocuments.length) {
			edi.utils.setObjectProperty(
				values,
				'document.contentFactEconomLife1.attachedDocInfo',
				cargoInfoByAccompanyingDocuments
			);
		}
		if (productValues && productValues.length) {
			edi.utils.setObjectProperty(values, 'document.contentFactEconomLife2.acceptanceResult', productValues);
		}
		values = convertFormValuesToProduct(values);
		//чистим адреса
		var companiesPath = [
			'document.contentFactEconomLife1.buyer',
			'document.contentFactEconomLife1.seller',
			'document.contentFactEconomLife1.cargoSender',
			'document.contentFactEconomLife1.cargoReceiver',
			'document.contentFactEconomLife1.insurer',
			'document.contentFactEconomLife1.otherInfoAcceptance.transporter'
		];
		companiesPath.forEach(function (companyPath) {
			switch (edi.utils.getObjectProperty(values, companyPath + '.address_type')) {
				case '0':
					edi.utils.setObjectProperty(values, companyPath + '.address.codeStateAddressRegister', null);
					edi.utils.setObjectProperty(values, companyPath + '.address.addressInfo', null);
					break;
				case '1':
					edi.utils.setObjectProperty(values, companyPath + '.address.codeStateAddressRegister', null);
					edi.utils.setObjectProperty(values, companyPath + '.address.addressRu', null);
					break;
				case '2':
					edi.utils.setObjectProperty(values, companyPath + '.address.addressRu', null);
					edi.utils.setObjectProperty(values, companyPath + '.address.addressInfo', null);
					break;
			}
			edi.utils.setObjectProperty(values, companyPath + '.address_type', null);
		});

		// чистка от мусора
		delete values.fake_field;
		delete values.true;
		edi.utils.clearEmptyValues(values);

		var onSuccessSave = function (data) {
			if (data && data.data && data.data.id) {
				parentData = data;
			}
			moduleData.isChanged = false;
			moduleData.tab.setLoading(false);
			edi.document.actions.documentCreateProcessing(
				moduleData,
				parentData,
				isEdit && !isCreateFromTransformation
			);
			if (isEdit && !isCreateFromTransformation) {
				edi.events.documents.fireEvent('change', {
					id: parentId
				});
			} else {
				edi.events.documents.fireEvent('create');
			}
		};
		var onFailureSave = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
		);
		if (isEdit && !isCreateFromTransformation) {
			values.docFlowMembers = documentData.docFlowMembers ? documentData.docFlowMembers : {};
			values.fileId = documentData?.fileId ?? null;
			values.formVersion = documentData?.formVersion ?? null;
			values.progVersion = documentData?.progVersion ?? null;
			if (values && values.document) {
				values.document.infoFileDate = documentData?.document?.infoFileDate ?? null;
				values.document.infoFileTime = documentData?.document?.infoFileTime ?? null;
			}
			edi.document.actions.processDocument(
				sellerOrgId,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1,
				parentId,
				moduleData.initData.data.id,
				onSuccessSave,
				onFailureSave,
				{
					data: Ext.encode(values)
				}
			);
		} else {
			values.docFlowMembers = docFlowMembers
				? docFlowMembers
				: {
						receiverId: receiverId || seller.selectedOrg?.fnsId || seller.selectedOrgValues?.fns_id,
						senderId: senderId
				  };
			var headerData = {
				data: Ext.encode(values),
				date: edi.utils.getObjectProperty(values, 'document.acceptanceDocInfo.docDate')
					? edi.utils.getObjectProperty(values, 'document.acceptanceDocInfo.docDate')
					: new Date(),
				number: edi.utils.getObjectProperty(values, 'document.acceptanceDocInfo.docNumber')
			};
			edi.document.actions.processDocument(
				sellerOrgId,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1,
				undefined,
				undefined,
				onSuccessSave,
				onFailureSave,
				headerData,
				{
					postData: {
						containerDocType: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
					},
					createUri: edi.rest.services.DOCUMENTS.WITH_CONTAINER.POST
				}
			);
		}
	};

	/**
	 * Creates order create/edit form
	 * @param   {Object}    documentData    order data for editing, undefined for new order
	 * @returns {Object}
	 */
	var createModuleForm = function (documentData) {
		documentData = documentData || {};

		var formItems = [];

		formItems = formItems.concat(formItems, createDocumentInformationItems(documentData));
		formItems = formItems.concat(formItems, shippingDetailsInformationItems());
		formItems = formItems.concat(formItems, cargoInspectionInformationItems());
		formItems = formItems.concat(formItems, cargoDetailsCoveringDocumentsGrid());
		formItems = formItems.concat(formItems, cargoReceptionTimeDetailsItems());
		formItems = formItems.concat(formItems, otherCargoReceptionItems());
		formItems = formItems.concat(formItems, personAcceptedGoodsInformationItems());
		formItems = formItems.concat(formItems, acceptanceResultsInformationItems());
		formItems = formItems.concat(formItems, documentLinesInformationItems());
		formItems = formItems.concat(formItems, signatoryInformationItems());

		var createBtn = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipper', 'consignee'],
							[buyer, seller, shipper, consignee],
							documentLinesGrid,
							false
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					edi.utils.clearEmptyValues(values);
					save(values);
				}
			},
			true
		);

		form = createForm({
			cls: 'edi-form document-add-form',
			autoScroll: true,
			bodyPadding: 10,
			items: formItems,
			buttons: createButtonContainer({
				items: [createBtn]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};
	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		let isBuyerValid = buyer.checkOrgDataValid();
		let isSellerValid = seller.checkOrgDataValid();
		return isBuyerValid && isSellerValid;
	};
	var checkValid = function () {
		if (form) {
			form.isValid();
		}
		var validCompanies = validateCompanySelectors();
		var validForm = form ? !form.hasInvalidField() : true;

		return validForm && validCompanies;
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Создание набора "Идентификатор - значение" для блока "Сведения о приёмке и (или) расхождениях"
	 * @param isMainField
	 * @param fieldData
	 * @param props
	 * @returns {*}
	 */
	var createReceptionLineField = function (isMainField, fieldData, props) {
		fieldData = fieldData || {};
		let validateInputs = function () {
			if (identifierInput && valueInput) {
				let bothAreEmpty = !identifierInput.getValue() && !valueInput.getValue();
				identifierInput.allowBlank = bothAreEmpty;
				identifierInput.isValid();
				valueInput.allowBlank = bothAreEmpty;
				valueInput.isValid();
			}
		};

		let identifierInput = createTextField({
			fieldLabel: edi.i18n.getMessage('column.identif'),
			name: 'document.contentFactEconomLife2.info.identifier',
			maxLength: 50,
			value: edi.utils.getObjectProperty(fieldData, 'identifier'),
			listeners: {
				change: validateInputs
			}
		});

		let valueInput = createTextField({
			fieldLabel: edi.i18n.getMessage('column.value'),
			maxLength: 2000,
			name: 'document.contentFactEconomLife2.info.value',
			value: edi.utils.getObjectProperty(fieldData, 'value'),
			listeners: {
				change: validateInputs
			}
		});

		return createContainer({
			layout: {
				type: 'grid',
				area: [[6, 6]]
			},
			identifierInput,
			valueInput,
			items: [identifierInput, valueInput]
		});
	};

	var createAttachedDocumentLineField = function (isMainField, fieldData, props) {
		fieldData = fieldData || {};

		let validateInputs = function () {
			if (typeInput && numberInput && dateInput) {
				let allAreEmpty = !typeInput.getValue() && !numberInput.getValue() && !dateInput.getValue();
				typeInput.allowBlank = allAreEmpty;
				typeInput.isValid();
				numberInput.allowBlank = allAreEmpty;
				numberInput.isValid();
				dateInput.allowBlank = allAreEmpty;
				dateInput.isValid();
			}
		};

		let typeInput = createTextField({
			fieldLabel: edi.i18n.getMessage('document.torg2.document.kind'),
			maxLength: 500,
			name: 'document.contentFactEconomLife2.attachedDoc.docType',
			value: edi.utils.getObjectProperty(fieldData, 'docType'),
			listeners: {
				change: validateInputs
			}
		});

		let numberInput = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.list.document.number'),
			maxLength: 40,
			name: 'document.contentFactEconomLife2.attachedDoc.docNumber',
			value: edi.utils.getObjectProperty(fieldData, 'docNumber'),
			listeners: {
				change: validateInputs
			}
		});

		let dateInput = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			value: edi.utils.getObjectProperty(fieldData, 'docDate'),
			name: 'document.contentFactEconomLife2.attachedDoc.docDate',
			listeners: {
				change: validateInputs
			}
		});

		return createContainer({
			layout: {
				type: 'grid',
				area: [[4, 4, 4]]
			},
			docTypeInput: typeInput,
			docNumberInput: numberInput,
			docDateInput: dateInput,
			items: [typeInput, numberInput, dateInput]
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var continueWithRendering = function () {
			if (!documentData.hasOwnProperty('signer')) {
				documentData.signer = {};
			}
			var buildForm = function (fieldsFillFromCert) {
				signerFillFromCert = fieldsFillFromCert;
				if (!edi.utils.getObjectProperty(documentData, 'signer.legalEntity.tin')) {
					edi.utils.setObjectProperty(documentData, 'signer.legalEntity.tin', '1111111111');
				}
				edi.utils.setObjectProperty(
					documentData,
					'signer.status',
					edi.utils.getObjectProperty(documentData, 'signer.state')
				);

				moduleData.tab.removeAll();
				moduleData.tab.add(createModuleActionsPanel());
				var modulePanel = createAddModulePanel();
				moduleData.tab.add(modulePanel);
				modulePanel.add(createModuleForm(documentData));
				moduleData.tab.setLoading(false);

				if ('function' === typeof initCallBack) {
					initCallBack();
				}
			};
			var signersData = documentData.signer;
			edi.methods.fillUPDSignerValues(
				edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
				signersData,
				edi.converters.convertOrgToPartie(userData.org),
				buildForm,
				null,
				null,
				!isEdit
			);
		};

		var data = moduleData.initData.data;
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		if (data && data.id) {
			isEdit = true;
			parentData = moduleData.initData;
			parentId = moduleData.initData.data.id;

			sellerOrgId = moduleData.initData.data.fromOrg.id;
			buyerOrgId = moduleData.initData.data.toOrg.id;

			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
					documentId: parentId
				}),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						var id = null;
						for (var i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1) {
								id = data.data.children[i].id;
								break;
							}
						}
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
								documentId: id
							}),
							'GET',
							{},
							function (data) {
								if (data && data.data) {
									documentData = data.data;
									updateFieldsBeforeRender();
									continueWithRendering();
								} else {
									failure(data);
								}
							},
							failure
						);
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			buyerOrgId = edi.core.getUserData().org.id;
			continueWithRendering();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
