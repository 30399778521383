import { createCheckbox, createCombo, createDateField } from '@Components/fields';
import {
	createContainer,
	createFieldBlock,
	createFieldSet,
	createModalForm,
	FIELD_BLOCK_CLS
	// @ts-ignore
} from '@UIkit/components/panels';
import { SignerAndPoa } from './signerAndPoa/createSignerAndPoa';
// @ts-ignore
import { createHiddenField, createTextField } from '@UIkit/components/fields';
import {
	PersonHandingGoodsBlock,
	PersonHandingGoodsFieldsProps
} from './personHandingGoodsBlock/createPersonHandingGoodsBlock';
import { getPurposeTypeValue, PURPOSES_TYPES } from '../methods';
import { BUYER_HANDING_GOODS_TYPE, HANDING_GOODS_TYPE, TekstInf } from './definitions/commonsDef';
import { AsyncRequestResponseData } from '@App/js/definitions/request';
import { RekvDokTip } from './definitions/rekvDokTip';
import { Podpisant } from './definitions/podpisant';
import { FlPer, PredOrgPer, RabOrgProd } from './definitions/svLitsPer';

export interface Part2SignerProps {
	signerData: Partial<Podpisant>;
}
export class Part2Signer extends SignerAndPoa {
	constructor(props: Part2SignerProps) {
		super({
			signers: [props.signerData],
			isSCHFSelected: false,
			isSCHFDOPSelected: false,
			isDOPSelected: false
		});

		this.fields = {};
	}

	fields: {
		posPodtPolnom?: ExtComponent;
		signerPosition?: ExtComponent;
		familiya?: ExtComponent;
		imya?: ExtComponent;
		otchestvo?: ExtComponent;
		dopSvedPodp?: ExtComponent;
		[fieldName: string]: ExtComponent | undefined;
	};

	createBumPOA(signer: AnyObject): ExtComponent {
		const me = this;

		const vnNomDoverField = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			name: 'vnNomDover',
			maxLength: 50,
			allowBlank: false,
			submitValue: false,
			value: edi.utils.getObjectProperty(signer, 'svDoverBum.vnNomDover')
		});

		const dataVidDoverField = createDateField({
			fieldLabel: edi.i18n.getMessage('column.date.of.issue'),
			name: 'dataVidDover',
			allowBlank: false,
			submitValue: false,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			value: edi.utils.getObjectProperty(signer, 'svDoverBum.dataVidDover')
		});

		let firstNameField: ExtComponent | undefined,
			secondNameField: ExtComponent | undefined,
			patronymicNameField: ExtComponent | undefined;
		const poaFIOSection = createContainer({
			layout: {
				type: 'grid',
				gap: 16,
				area: [[4, 4, 4]]
			},
			items: [
				(firstNameField = createTextField({
					allowBlank: false,
					fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.surname'),
					submitValue: false,
					maxLength: 60,
					name: 'familiya',
					value: edi.utils.getObjectProperty(signer, 'svDoverBum.fio.familiya')
				})),
				(secondNameField = createTextField({
					allowBlank: false,
					fieldLabel: edi.i18n.getMessage('first.name'),
					submitValue: false,
					maxLength: 60,
					name: 'imya',
					value: edi.utils.getObjectProperty(signer, 'svDoverBum.fio.imya')
				})),
				(patronymicNameField = createTextField({
					fieldLabel: edi.i18n.getMessage('patronymic.name'),
					submitValue: false,
					maxLength: 60,
					name: 'otchestvo',
					value: edi.utils.getObjectProperty(signer, 'svDoverBum.fio.otchestvo')
				}))
			]
		});

		const svIdDoverField = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.poa.svIdDover'),
			submitValue: false,
			name: 'svIdDover',
			maxLength: 1000,
			value: edi.utils.getObjectProperty(signer, 'svDoverBum.svIdDover')
		});

		const valueField = createHiddenField({
			name: me.createFieldName('svDoverBum'),
			getRawValue: () => {
				if (!me.bumPOAFieldBlock?.rendered) return;
				// Значение бумажной доверенности хранится в массиве (одна подпись = одна мчд)
				return [
					{
						fio: {
							familiya: firstNameField?.getSubmitValue(),
							imya: secondNameField?.getSubmitValue(),
							otchestvo: patronymicNameField?.getSubmitValue()
						},
						dataVidDover: dataVidDoverField.getSubmitValue(),
						vnNomDover: vnNomDoverField.getSubmitValue(),
						svIdDover: svIdDoverField.getSubmitValue()
					}
				];
			}
		});

		return (me.bumPOAFieldBlock = createFieldBlock({
			hidden: true,
			title: edi.i18n.getMessage('company.signer.poa'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: 16,
				area: [[4, 4], 12, 8]
			},
			items: [vnNomDoverField, dataVidDoverField, poaFIOSection, svIdDoverField, valueField]
		}));
	}

	createSignerFields(signer: AnyObject, isSecond: boolean, signerTitle?: string | null) {
		const me = this;
		// Способ подтверждения полномочий
		const sposPodtPolnomField = (me.fields.posPodtPolnom = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.sposPodtPolnom'),
			cls: FIELD_BLOCK_CLS.small,
			items: createCombo({
				name: me.createFieldName('sposPodtPolnom'),
				allowBlank: false,
				displayField: 'name',
				valueField: 'id',
				store: edi.stores.createInlineStore(me.sposPodtPolnomStoreData, 'SIMPLE'),
				valueSrc: signer,
				listeners: {
					change: me.setVisibleBumPOA.bind(me),
					afterrender: me.setVisibleBumPOA.bind(me)
				}
			})
		}));

		//Должность (блок всегда скрыт на UI)
		const signerPosition = (me.fields.signerPosition = createHiddenField({
			name: me.createFieldName('dolzhn'),
			value: edi.utils.getObjectProperty(signer, 'dolzhn')
		}));

		// ФИО (блок всегда скрыт на UI)
		const signerSection = createContainer({
			items: [
				(me.fields.familiya = createHiddenField({
					value: edi.utils.getObjectProperty(signer, 'fio.familiya'),
					name: me.createFieldName(`fio.familiya`)
				})),
				(me.fields.imya = createHiddenField({
					value: edi.utils.getObjectProperty(signer, 'fio.imya'),
					name: me.createFieldName(`fio.imya`)
				})),
				(me.fields.otchestvo = createHiddenField({
					value: edi.utils.getObjectProperty(signer, 'fio.otchestvo'),
					name: me.createFieldName(`fio.otchestvo`)
				}))
			]
		});

		// Дополнительные сведения
		const dopSvedPodpField = (me.fields.dopSvedPodp = createTextField({
			name: me.createFieldName('dopSvedPodp'),
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.signer.dopSvedPodp'),
			maxLength: 255,
			value: edi.utils.getObjectProperty(signer, 'podpisant.dopSvedPodp')
		}));

		return [
			createContainer({
				layout: {
					type: 'grid',
					gap: 16,
					area: [10, 10]
				},
				items: [sposPodtPolnomField, dopSvedPodpField, signerPosition, signerSection, me.createBumPOA(signer)]
			})
		];
	}
}

export class Part2WorkerReceiptGoods extends PersonHandingGoodsBlock {
	constructor() {
		super({
			data: {}
		});
	}

	view: ExtComponent;
	path: string = 'dokument.sodFHZH4.svPrin.svLitsPrin';

	createOtherInformationField({ prefix }: { prefix: string }): ExtComponent {
		const me = this;
		const otherInformation = (me.fields.otherInformation = createTextField({
			valueSrc: me.cacheValues,
			isTextarea: true,
			maxLength: 255,
			name: me.createFieldName(prefix, 'inieSved')
		}));
		return createFieldBlock({
			title: edi.i18n.getMessage('company.type.legalForeigner.header.info'),
			cls: FIELD_BLOCK_CLS.small,
			items: otherInformation
		});
	}

	createHandingGoodsTypeField({ prefix }: PersonHandingGoodsFieldsProps = {}): ExtComponent {
		const me = this;
		const storeItems = Object.entries(BUYER_HANDING_GOODS_TYPE).map(([key, value]) => ({
			id: value,
			name: edi.i18n.getMessage('documents.fns_upd_5_02n.p2.signer.' + key)
		}));
		return (me.fields.handingGoodsTypeField = createCombo({
			fieldLabel: edi.i18n.getMessage('documents.fns_upd_5_02n.part2.person.received.goods'),
			store: edi.stores.createInlineStore(storeItems, undefined, undefined, {
				addEmptyRecord: true
			}),
			matchFieldWidth: false,
			forceSelection: false,
			editable: false,
			submitValue: false,
			listeners: {
				select: function (comp: ExtComponent, record: ExtRecord<{ id: string; name: string }>) {
					me.changeHandingGoodsFields(record?.get('id') ?? null);
				},
				afterrender: () => me.changeHandingGoodsFields(null)
			}
		}));
	}

	changeHandingGoodsFields(type: string | null) {
		const me = this;
		const viewModel = me.view.getViewModel();
		viewModel.set('handingGoodsType', type);
		me.updateFieldNames(type ?? HANDING_GOODS_TYPE.sellerOrganizationWorker);
	}

	updateFieldNames(type: string) {
		const me = this;
		let prefix = '';
		switch (type) {
			case HANDING_GOODS_TYPE.sellerOrganizationWorker:
				prefix = 'rabOrgPok';
				break;
			case HANDING_GOODS_TYPE.shippedGoodsWorker:
				prefix = 'inLitso.predOrgPrin';
				break;
			case HANDING_GOODS_TYPE.individualPersonShipsGoogs:
				prefix = 'inLitso.flPrin';
				break;
			default:
				prefix = '';
		}
		const { firstName, lastName, patronymicName, position, inn, orgName, orgInn, otherInformation } = me.fields;
		const { authority, orgAuthority, orgWorkerAuthority } = me.blocks;

		if (firstName) firstName.name = me.createFieldName(prefix, 'fio.imya');
		if (lastName) lastName.name = me.createFieldName(prefix, 'fio.familiya');
		if (patronymicName) patronymicName.name = me.createFieldName(prefix, 'fio.otchestvo');
		if (position) position.name = me.createFieldName(prefix, 'dolzhnost');
		if (inn) inn.name = me.createFieldName(prefix, 'innflPrin');
		if (orgName) orgName.name = me.createFieldName(prefix, 'naimOrgPrin');
		if (orgInn) orgInn.name = me.createFieldName(prefix, 'innOrgPrin');
		if (otherInformation) otherInformation.name = me.createFieldName(prefix, 'inieSved');
		if (authority) authority.hiddenField.name = me.createFieldName(prefix, 'osnDoverFL');
		if (orgAuthority) orgAuthority.hiddenField.name = me.createFieldName(prefix, 'osnDoverOrgPrin');
		if (orgWorkerAuthority) orgWorkerAuthority.hiddenField.name = me.createFieldName(prefix, 'osnPolnPredPrin');
	}

	createViewModel() {
		const me = this;

		return {
			data: {
				handingGoodsType: null
			},
			formulas: {
				isSellerOrganizationWorker: (get: Function) =>
					get('handingGoodsType') === HANDING_GOODS_TYPE.sellerOrganizationWorker,
				isShippedGoodsWorker: (get: Function) =>
					get('handingGoodsType') === HANDING_GOODS_TYPE.shippedGoodsWorker,
				isIndividualPersonShipsGoogs: (get: Function) =>
					get('handingGoodsType') === HANDING_GOODS_TYPE.individualPersonShipsGoogs
			}
		};
	}

	setFieldsBind() {
		const me = this;

		me.fields.firstName?.setBind({
			disabled: '{!handingGoodsType}',
			hidden: '{!handingGoodsType}'
		});
		me.fields.lastName?.setBind({
			disabled: '{!handingGoodsType}',
			hidden: '{!handingGoodsType}'
		});
		me.fields.patronymicName?.setBind({
			disabled: '{!handingGoodsType}',
			hidden: '{!handingGoodsType}'
		});

		me.fields.otherInformation?.getFieldBlock()?.setBind({
			disabled: '{!handingGoodsType}',
			hidden: '{!handingGoodsType}'
		});

		me.fields.inn?.setBind({
			disabled: '{!isIndividualPersonShipsGoogs}',
			hidden: '{!isIndividualPersonShipsGoogs}'
		});

		me.fields.orgInn?.setBind({
			disabled: '{!isShippedGoodsWorker}',
			hidden: '{!isShippedGoodsWorker}'
		});

		me.fields.position?.setBind({
			disabled: '{handingGoodsType ? isIndividualPersonShipsGoogs : true}',
			hidden: '{handingGoodsType ? isIndividualPersonShipsGoogs : true}'
		});

		me.blocks.authority?.setBind({
			disabled: '{!isIndividualPersonShipsGoogs}',
			hidden: '{!isIndividualPersonShipsGoogs}'
		});

		me.fields.orgName?.setBind({
			disabled: '{!isShippedGoodsWorker}',
			hidden: '{!isShippedGoodsWorker}'
		});

		me.blocks.orgAuthority?.setBind({
			disabled: '{!isShippedGoodsWorker}',
			hidden: '{!isShippedGoodsWorker}'
		});

		me.blocks.orgWorkerAuthority?.setBind({
			disabled: '{!isShippedGoodsWorker}',
			hidden: '{!isShippedGoodsWorker}'
		});
	}

	createPersonHandingGoodsBlock(): ExtComponent {
		const me = this;

		const prefix = 'inLitso.flPer';

		const type = me.createHandingGoodsTypeField();
		const firstName = me.createFirstNameField({ prefix });
		const lastName = me.createLastNameField({ prefix });
		const patronymicName = me.createPatronymicNameField({ prefix });
		const inn = me.createInnField({ prefix });
		const orgInn = me.createOrgInnField({ prefix });
		const otherInformation = me.createOtherInformationField({ prefix });
		const authority = me.createAuthorityDocumentBlockHolder({ prefix });
		const position = me.createPositionField({ prefix });
		const orgName = me.createOrgNameField({ prefix });
		const orgAuthority = me.createOrgAuthorityDocumentBlockHolder({ prefix });
		const orgWorkerAuthority = me.createOrgWorkerAuthorityDocumentBlockHolder({ prefix });

		return (me.view = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.p2.workerReceiptGoods'),
			layout: {
				type: 'grid',
				area: [[8, 4], [4, 4, 4], 12, 6, 12]
			},
			viewModel: me.createViewModel(),
			items: [
				type,
				createContainer({
					items: [orgInn, inn]
				}),
				lastName,
				firstName,
				patronymicName,
				createContainer({
					layout: {
						type: 'grid',
						area: [[4, 8]]
					},
					items: [position, orgName]
				}),
				otherInformation,
				authority,
				orgAuthority,
				orgWorkerAuthority
			],
			listeners: {
				afterrender: () => me.setFieldsBind()
			}
		}) as ExtComponent);
	}
}

export interface Upd502nPart2Props {
	signerData?: Partial<P2SignerData>;
}

export class Upd502nPart2Form {
	fields: {
		resultAcceptance?: ExtComponent;
		operationContent?: ExtComponent;
		dateReceiptGoods?: ExtComponent;
		forInternalPurpose?: ExtComponent;
		[fieldName: string]: ExtComponent | undefined;
	};

	blocks: {
		discrepancy?: ExtComponent;
		[blocksName: string]: ExtComponent | undefined;
	};

	props: Upd502nPart2Props;

	constructor(props: Upd502nPart2Props = {}) {
		const me = this;
		me.modifyConfig(props);
	}

	modifyConfig(props: Upd502nPart2Props) {
		const me = this;
		me.fields = {};
		me.blocks = {};
		me.props = props;
	}

	checkFieldsAllowBlank() {
		const me = this;

		if (!me.fields.resultAcceptance || !me.fields.operationContent) {
			return;
		}
		let resultAcceptanceIsBlank = !me.fields.resultAcceptance.getValue();
		let operationContentIsBlank = !me.fields.operationContent.getValue();
		if (resultAcceptanceIsBlank && operationContentIsBlank) {
			me.fields.resultAcceptance.allowBlank = false;
			me.fields.operationContent.allowBlank = false;
		} else {
			me.fields.resultAcceptance.allowBlank = !operationContentIsBlank;
			me.fields.operationContent.allowBlank = !resultAcceptanceIsBlank;
		}
		me.fields.resultAcceptance.isValid();
		me.fields.operationContent.isValid();
	}

	toggleEnable(cmp: ExtComponent, isEnabled: boolean = true) {
		if (cmp && !cmp.isDestroyed) {
			cmp.setVisible(isEnabled);
			cmp.setDisabled(!isEnabled);
		}
	}

	createResultAcceptanceField(): ExtComponent {
		const me = this;
		const resultAcceptanceField = (me.fields.resultAcceptance = createCombo({
			store: edi.stores.createSimpleInlineStore([1, 2, 3], function (id: number) {
				return edi.i18n.getMessage('document.part2.create.form.resultAcceptance.' + id);
			}),
			editable: false,
			maxLength: 255,
			autoSelect: true,
			allowBlank: true,
			name: 'dokument.sodFHZH4.svPrin.kodSodOper.kodItoga',
			queryMode: 'local',
			forceSelection: false,
			triggers: {
				close: {
					extraCls: 'edi-icon edi-icon-CLOSE',
					handler: function (cmp: ExtComponent, trigger: ExtBase) {
						if (trigger) {
							cmp.clearValue();
						}
					}
				}
			},
			triggerAction: 'last',
			listeners: {
				select(comp: ExtComponent) {
					const val = comp.getValue();
					if (me.blocks.discrepancy) {
						const isDiscrepancyAvailable = val === '2' || val === '3';
						me.toggleEnable(me.blocks.discrepancy, isDiscrepancyAvailable);
					}
					if (me.fields.operationContent) {
						me.toggleEnable(me.fields.operationContent.getFieldBlock(), val !== '3');
					}
				},
				change() {
					me.checkFieldsAllowBlank();
				}
			}
		}));

		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('documents.fns_upd.p2.resultAcceptance'),
			layout: {
				type: 'grid',
				area: [10]
			},
			items: [resultAcceptanceField]
		});
	}

	createOperationContentField(): ExtComponent {
		const me = this;
		let updLineList = ['productsAccepted', 'resultsAccepted', 'servicesAccepted'],
			correctingLineList = ['agreedWithChanges', 'costChangesTakenIntoAccount'],
			//isCorrecting = docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
			isCorrecting = false,
			operationContentComboList = isCorrecting ? correctingLineList : updLineList;
		const operationContentField = (me.fields.operationContent = createCombo({
			store: edi.stores.createSimpleInlineStore(
				operationContentComboList.map((item) =>
					edi.i18n.getMessage('document.part2.create.form.operationContent.' + item)
				)
			),
			forceSelection: false,
			maxLength: 255,
			allowBlank: me.fields.resultAcceptance ? !!me.fields.resultAcceptance.getValue() : false,
			name: 'dokument.sodFHZH4.svPrin.sodOper',
			triggers: {
				close: {
					extraCls: 'edi-icon edi-icon-CLOSE',
					handler: function (cmp: ExtComponent, trigger: ExtBase) {
						if (trigger) {
							cmp.clearValue();
						}
					}
				}
			},
			listeners: {
				change() {
					me.checkFieldsAllowBlank();
				}
			}
		}));

		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('documents.fns_upd.p2.operationContent'),
			layout: {
				type: 'grid',
				area: [10]
			},
			items: [operationContentField]
		});
	}

	createDateReceiptGoodsField() {
		const me = this;
		const dateField = (me.fields.dateReceiptGoods = createDateField({
			emptyText: edi.i18n.getMessage('date'),
			allowBlank: false,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			value: edi.utils.formatDate(new Date().getTime(), edi.constants.DATE_FORMAT.FNS),
			name: 'dokument.sodFHZH4.svPrin.dataPrin'
		}));

		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('documents.fns_upd.p2.dateReceiptGoods'),
			layout: {
				type: 'grid',
				area: [3]
			},
			items: dateField
		});
	}

	createForInternalPurposeField(): ExtComponent {
		const me = this;
		const forInternalPurpose = (me.fields.forInternalPurpose = createCheckbox({
			name: 'forInternalPurpose',
			boxLabel: edi.i18n.getMessage('upd.detail.field.for.internal.purpose'),
			inputValue: true
		}) as ExtComponent);

		return createContainer({
			items: forInternalPurpose
		}) as ExtComponent;
	}

	createAcceptorSameAsReceiverField(): ExtComponent {
		const me = this;
		const sameAsReceiver = createCheckbox({
			boxLabel: edi.i18n.getMessage('documents.fns_upd.p2.acceptor.same.as.receiver'),
			inputValue: true,
			name: 'sameAsReceiver',
			listeners: {
				change: function (cbx: ExtComponent, value: boolean) {
					if (me.blocks.workerReceiptGoods) {
						me.blocks.workerReceiptGoods.setVisible(!value);
						me.blocks.workerReceiptGoods.setDisabled(value);
					}
				}
			}
		}) as ExtComponent;
		return createContainer({
			items: sameAsReceiver
		}) as ExtComponent;
	}

	createSignerBlock(): ExtComponent {
		const me = this;
		const signerData = me.props.signerData ?? {};
		const signerAndPoa = new Part2Signer({
			signerData: {
				fio: {
					familiya: signerData.legalEntity?.fullName.middleName ?? '',
					imya: signerData.legalEntity?.fullName.firstName ?? '',
					otchestvo: signerData.legalEntity?.fullName.lastName
				},
				dolzhn: signerData.legalEntity?.position
			}
		});
		Object.assign(me.fields, signerAndPoa.fields);
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.p2.signer'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: 16
			},
			items: [me.createAcceptorSameAsReceiverField(), signerAndPoa.createSignerItems()]
		});
	}

	createDiscrepancyDocNumberField(): ExtComponent {
		const me = this;
		const discrepancyDocNumber = (me.fields.discrepancyDocNumber = createTextField({
			maxLength: 255,
			allowBlank: false,
			name: 'dokument.sodFHZH4.svPrin.kodSodOper.rekvDokRash.rekvNomerDok'
		}));
		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('documents.fns_upd.p2.discrepancyDocNumber'),
			items: [discrepancyDocNumber]
		});
	}

	createDiscrepancyDocNameField(): ExtComponent {
		const me = this;
		const discrepancyDocName = (me.fields.discrepancyDocName = createTextField({
			maxLength: 255,
			allowBlank: false,
			name: 'dokument.sodFHZH4.svPrin.kodSodOper.rekvDokRash.rekvNaimDok',
			value: ''
		}));
		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('documents.fns_upd.p2.discrepancyDocName'),
			items: discrepancyDocName
		});
	}

	createDiscrepancyDateField(): ExtComponent {
		const me = this;
		const discrepancyDate = (me.fields.discrepancyDate = createDateField({
			allowBlank: false,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			name: 'dokument.sodFHZH4.svPrin.kodSodOper.rekvDokRash.rekvDataDok'
		}));
		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('date'),
			items: discrepancyDate
		});
	}

	createDiscrepancyDocFileIdField(): ExtComponent {
		const me = this;
		const discrepancyDocFileId = (me.fields.discrepancyDocFileId = createTextField({
			maxLength: 255,
			allowBlank: true,
			name: 'dokument.sodFHZH4.svPrin.kodSodOper.rekvDokRash.rekvIdFaylDok',
			value: ''
		}));
		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('documents.fns_upd.p2.discrepancyDocFileId'),
			items: discrepancyDocFileId
		});
	}

	createDiscrepancyAdditionalInfoField(): ExtComponent {
		const me = this;
		const discrepancyAdditionalInfo = (me.fields.discrepancyAdditionalInfo = createTextField({
			maxLength: 2000,
			rowsHtmlAttributeValue: 4,
			allowBlank: true,
			isTextarea: true,
			name: 'dokument.sodFHZH4.svPrin.kodSodOper.rekvDokRash.rekvDopSvedDok',
			value: ''
		}));
		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.p2.discrepancy.additional.info'),
			items: discrepancyAdditionalInfo
		});
	}

	createDiscrepancyBlock(): ExtComponent {
		const me = this;
		return (me.blocks.discrepancy = createFieldSet({
			layout: {
				type: 'grid',
				area: [[5, 7], [6, 3], 6]
			},
			items: [
				me.createDiscrepancyDocNumberField(),
				me.createDiscrepancyDocNameField(),
				me.createDiscrepancyDocFileIdField(),
				me.createDiscrepancyDateField(),
				me.createDiscrepancyAdditionalInfoField()
			]
		}));
	}

	createWorkerReceiptGoodsBlock(): ExtComponent {
		const me = this;
		const workerReceiptGoods = new Part2WorkerReceiptGoods();
		return (me.blocks.workerReceiptGoods = workerReceiptGoods.createPersonHandingGoodsBlock());
	}

	createModalForm(): ExtComponent {
		const me = this;
		return createModalForm({
			submitEmptyText: false,
			items: [
				me.createResultAcceptanceField(),
				me.createOperationContentField(),
				me.createDateReceiptGoodsField(),
				me.createForInternalPurposeField(),
				me.createDiscrepancyBlock(),
				me.createSignerBlock(),
				me.createWorkerReceiptGoodsBlock()
			]
		});
	}
}

export interface UPD502nCreatePart2Props {
	formValues: AnyObject;
}
export class UPD502nCreatePart2 {
	props: UPD502nCreatePart2Props;

	constructor(
		props: UPD502nCreatePart2Props = {
			formValues: {}
		}
	) {
		const me = this;
		me.props = props;
	}

	async getLinkedDocs({ id }: { id: number }): Promise<AsyncRequestResponseData> {
		const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
			documentId: id
		});
		return edi.rest.asyncSendRequest({
			url
		});
	}

	async getPart2Header({ part1id }: { part1id: number }): Promise<DocumentHeader | undefined> {
		const me = this;
		const { success, data } = await me.getLinkedDocs({ id: part1id });
		if (success) {
			if (data?.data?.children?.length) {
				const part2 = data.data.children.find(
					(docHeader: AnyObject) => docHeader.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2
				);
				return part2;
			}
		}
	}

	async loadPartContent({ id }: { id: number }): Promise<AnyObject | undefined> {
		const me = this;
		const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
			documentId: id
		});
		const { success, data } = await edi.rest.asyncSendRequest({
			url
		});

		if (success) return data.data;
	}

	buildDocument() {
		const me = this;
		const clonedValues = Ext.clone(me.props.formValues);
		if (clonedValues.forInternalPurpose) {
			edi.utils.setObjectProperty(clonedValues, 'dokument.sodFHZH4.infPolFHZH4.tekstInf', [
				{
					identif: getPurposeTypeValue(PURPOSES_TYPES.SV_VIBITIYA),
					znachen: '1'
				}
			]);
		}
		if (clonedValues.sameAsReceiver) {
			const receiverData = clonedValues.podpisant;
			edi.utils.setObjectProperty(clonedValues, 'dokument.sodFHZH4.svPrin.svLitsPrin.rabOrgPok', {
				fio: receiverData.fio,
				dolzhnost: receiverData.dolzhn
			});
		}
		return {
			dokument: {
				...clonedValues.dokument,
				podpisant: [clonedValues.podpisant],
				sodFHZH4: {
					...clonedValues.dokument.sodFHZH4
				}
			}
		};
	}

	async createPart2({ part1Header }: { part1Header: AnyObject }) {
		const me = this;
		const isCreated = edi.utils.getAttributeByName(part1Header.attributes, 'hasPart2', undefined, true);
		let values = {};

		if (isCreated) {
			const part2Header = await me.getPart2Header({ part1id: part1Header.id });
			const part2Content = part2Header ? await me.loadPartContent({ id: part2Header.id }) : {};
			values = Ext.Object.merge({}, part2Content, me.buildDocument());
		} else {
			values = Ext.Object.merge(me.buildDocument());
		}

		edi.utils.clearEmptyValues(values);
		return Ext.encode(values);
	}
}

export interface P2SignerData {
	authorityArea: string;
	state: string;
	baseAuthory: string;
	baseOrgAuthory: string;
	legalEntity: {
		fullName: {
			firstName: string;
			middleName: string;
			lastName: string;
		};
		position: string;
	};
}

interface IdInfProd {
	ep: string[];
	idFaylInfPr: string;
	dataFaylInfPr: string;
	vremFaylInfPr: string;
}

interface SvPrin {
	kodSodOper?: {
		rekvDokRash?: RekvDokTip;
		kodItoga: number;
	};
	svLitsPrin?: SvLitsPrin;
	sodOper?: string;
	dataPrin: string;
}

interface FlPrin extends FlPer {}
interface PredOrgPrin extends PredOrgPer {
	osnDoverOrgPrin: PredOrgPer['osnDoverOrgPer'];
	osnPolnPredPrin: PredOrgPer['osnPolnPredPer'];
	naimOrgPrin: PredOrgPer['naimOrgPer'];
	innOrgPrin: PredOrgPer['innyulPer'];
}

interface InLitso {
	predOrgPrin?: PredOrgPrin;
	flPrin?: FlPrin;
}

interface RabOrgPok extends RabOrgProd {}

interface SvLitsPrin {
	rabOrgPok?: RabOrgPok;
	inLitso?: InLitso;
}

interface SodFHZH4 {
	svPrin?: SvPrin;
	infPolFHZH4?: InfPolFHZH4;
	naimDokOprPr: string;
	funktsiya: string;
	porNomDokInfPr?: string;
	dataDokInfPr: string;
	vidOper?: string;
}

interface InfPolFHZH4 {
	tekstInf?: TekstInf[];
	idFaylInfPol?: string;
}

interface InfSvedDenObyaz {
	nomStr: number;
	kodObektFAIP?: string;
	vidSredstv: number;
	kodPokByudzhKlass: string;
	kodTSeliPokup?: string;
	sumAvans: number;
}

interface InfPokZaGoskKazn {
	infSvedDenObyaz?: InfSvedDenObyaz[];
	idKodZak?: string;
	litsSchetPok: string;
	naimFinOrgPok: string;
	nomReestrZapPok: string;
	uchNomByudObyazPok?: string;
	kodKaznachPok?: string;
	naimKaznachPok?: string;
	oktmoPok: string;
	oktmoMesPost?: string;
	dataOplPred?: string;
	uchNomDenObyaz?: string;
	ocherPlat?: string;
	vidPlat?: number;
}

export interface UPDpart2 {
	idInfProd?: IdInfProd;
	sodFHZH4?: SodFHZH4;
	infPokZaGoskKazn?: InfPokZaGoskKazn;
	podpisant?: Podpisant[];
	osnDoverOrgSost?: RekvDokTip;
	knd: string;
	uid?: string;
	dataInfPok: string;
	vremInfPok: string;
	naimEkonSubSost?: string;
}

edi.constants.DOCUMENT_PART_2_FORM.push({
	docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	version: '5.02-N',
	createForm: function ({ signerData }: { signerData: Partial<P2SignerData> }) {
		const part2 = new Upd502nPart2Form({
			signerData
		});

		return part2.createModalForm();
	},
	createPart2: function ({ formValues }: { formValues: AnyObject }) {
		return new UPD502nCreatePart2({ formValues });
	}
});
