const ALCDES_LINE_COLUMN = 'alcdes_lines';
const ALCDES_SUMMARY_DETAIL_COLUMN = 'alcdes_summary';
const ALCDES_LINE_PARTY_COLUMN = 'alcdes_line_party';
const ALCDES_LINE_REFERENCE_COLUMN = 'alcdes_line_reference';
const ALCDES_LINE_CERTIFICATE_COLUMN = 'alcdes_line_certificate';

edi.columns.addColumns({
	[ALCDES_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 1.5
		},
		itemDescription: {
			text: 'column.item.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 3
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 2,
			hidden: true
		},
		quantityOrdered: {
			text: 'column.quantity.ordered',
			dataIndex: 'OrderedQuantity',
			flex: 1,
			hidden: true
		},
		quantityDespatched: {
			text: 'column.quantity.despatched',
			dataIndex: 'QuantityDespatched',
			flex: 1,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10',
				decimalPrecision: 3,
				allowDecimals: true,
				allowBlank: false,
				minValue: 1
			}
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'column.unit.net.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitNetPrice',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 1
		},
		unitGrossPrice: {
			text: 'column.unit.gross.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitGrossPrice',
			flex: 1
		},
		unitPacksize: {
			text: 'column.unit.packsize',
			dataIndex: 'UnitPacksize',
			flex: 1,
			hidden: true
		},
		netAmount: {
			text: 'column.net.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'NetAmount',
			flex: 1
		},
		taxAmount: {
			text: 'column.tax.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'TaxAmount',
			flex: 1
		},
		grossAmount: {
			text: 'column.gross.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'GrossAmount',
			flex: 1
		},
		alcoholCode: {
			text: 'column.alcohol.code',
			dataIndex: 'AlcoholCode',
			flex: 1
		},
		packagingDate: {
			text: 'column.packaging.date',
			dataIndex: 'PackagingDate',
			flex: 1
		}
	},
	[ALCDES_SUMMARY_DETAIL_COLUMN]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'TotalLines',
			flex: 1,
			sortable: false,
			hideable: false
		},
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalPSequence: {
			text: 'column.total.packing.sequence',
			dataIndex: 'TotalPSequence',
			menuDisabled: true,
			order: 1,
			flex: 1
		},
		totalGoodsDespatchedAmount: {
			text: 'column.total.goods.despatched.amount',
			dataIndex: 'TotalGoodsDespatchedAmount',
			menuDisabled: true,
			order: 3,
			flex: 1
		}
	},
	[ALCDES_LINE_PARTY_COLUMN]: {
		partyType: {
			text: 'column.party.type',
			dataIndex: 'PartyType',
			flex: 1,
			renderer: 'linePartyType'
		},
		iln: {
			text: 'column.iln',
			dataIndex: 'ILN',
			flex: 1
		},
		tax: {
			text: 'column.tax.id',
			dataIndex: 'TaxID',
			flex: 1
		},
		reason: {
			text: 'column.tax.registration.reason.code',
			dataIndex: 'TaxRegistrationReasonCode',
			flex: 1
		},
		name: {
			text: 'column.name',
			dataIndex: 'Name',
			flex: 1
		}
	},
	[ALCDES_LINE_REFERENCE_COLUMN]: {
		type: {
			text: 'column.reference.type',
			dataIndex: 'Reference-Type',
			menuDisabled: true,
			flex: 1
		},
		id: {
			text: 'column.reference.id',
			dataIndex: 'Reference-Id',
			menuDisabled: true,
			flex: 1
		}
	},
	[ALCDES_LINE_CERTIFICATE_COLUMN]: {
		number: {
			text: 'column.number',
			dataIndex: 'Number',
			flex: 1
		},
		authority: {
			text: 'column.issuing.authority',
			dataIndex: 'IssuingAuthority',
			flex: 1
		},
		issue: {
			text: 'column.date.of.issue',
			dataIndex: 'DateOfIssue',
			flex: 1
		},
		date: {
			text: 'column.expiration.date',
			dataIndex: 'ExpirationDate',
			flex: 1
		}
	}
});

export {
	ALCDES_LINE_COLUMN,
	ALCDES_SUMMARY_DETAIL_COLUMN,
	ALCDES_LINE_PARTY_COLUMN,
	ALCDES_LINE_REFERENCE_COLUMN,
	ALCDES_LINE_CERTIFICATE_COLUMN
};
