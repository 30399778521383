// @ts-ignore
import { createCombo, createDateField, createTextField } from '@UIkit/components/fields';
// @ts-ignore
import { createContainer, createFieldBlock, createFieldSet, createMaxWidthContainer } from '@UIkit/components/panels';
import { createNamedDocumentBlock } from '../documentBlock/createDocumentBlock';
import * as powerLimits from '../json/power.limits.json';
import * as scCodes from '../json/sc_codes.json';
import { POWER_LIMITS_MODEL } from '../models';
import { UpdOrgSelectors } from '../selectors';
import { VidObyaz } from '../definitions/svSchFakt';
import { isDOP_BusinessProcess, isSCHF_BusinessProcess, isSCHFDOP_BussinessProcess } from '../../methods';

export interface AdditionalInformationProps {
	documentData: AnyObject;

	//Для Фактора
	createOS: (selectorConfig: AnyObject) => ExtComponent;
	getOrgType: (org: AnyObject) => { type: string | undefined };
	factors: AnyObject[];
	forceChangeFieldsForShips: AnyObject;
	modalAdditionalConf: AnyObject;
	selectorConfigsBuilder: UpdOrgSelectors;
	setNameForCompanySelector: (cmp: ExtComponent, title: string) => void;
	checkValid: () => boolean;

	getCurrentUPDState: () => {
		updFunction: string;
		isEdit: boolean;
		isCopy: boolean;
		isCorrect: boolean;
	};

	specialConditionsChangedHandler: (specialConditions: {
		schf: string | null;
		schfdop: string | null;
		dop: string | null;
	}) => void;

	[key: string]: any;
}

export interface AdditionalInformationViewModel {
	updFunction: string | null;
}

export class AdditionalInformation {
	fields: {
		[fieldName: string]: ExtComponent;
	};
	blocks: {
		[blockName: string]: ExtComponent;
	};
	selectors: {
		[selectorName: string]: ExtComponent;
	};

	view: ExtComponent;

	props: AdditionalInformationProps;

	path: string = 'dokument.svSchFakt.dopSvFHZH1';

	sellerGovProcurementNamePrefix: string = 'infProdZaGosKazn';

	constructor(props: AdditionalInformationProps) {
		const me = this;
		me.modifyConfig(props);
	}

	modifyConfig(props: AdditionalInformationProps) {
		const me = this;

		me.fields = {};
		me.blocks = {};
		me.selectors = {};

		me.props = props;
	}

	createFieldName(...name: (string | undefined)[]): string {
		const me = this;
		return [me.path, ...name].filter(Boolean).join('.');
	}

	createGovernmentContractIdField(): ExtComponent {
		const me = this;
		return (me.fields.governmentContractId = createTextField({
			fieldLabel: edi.i18n.getMessage('column.identifier'),
			minLength: 20,
			maxLength: 25,
			valueSrc: me.props.documentData,
			name: me.createFieldName('idGosKon')
		}));
	}

	createGovernmentContractNumberField(): ExtComponent {
		const me = this;
		return (me.fields.governmentContractNumber = createTextField({
			maxLength: 100,
			fieldLabel: edi.i18n.getMessage('column.license.number'),
			name: me.createFieldName(me.sellerGovProcurementNamePrefix, 'nomerGosKont'),
			requiredFields: [me.createFieldName(me.sellerGovProcurementNamePrefix, 'dataGosKont')],
			valueSrc: me.props.documentData
		}));
	}

	createGovernmentContractDateField(): ExtComponent {
		const me = this;
		return (me.fields.governmentContractDate = createDateField({
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			fieldLabel: edi.i18n.getMessage('date'),
			name: me.createFieldName(me.sellerGovProcurementNamePrefix, 'dataGosKont'),
			requiredFields: [me.createFieldName(me.sellerGovProcurementNamePrefix, 'nomerGosKont')],
			valueSrc: me.props.documentData
		}));
	}

	createGovernmentContractFieldBlock(): ExtComponent {
		const me = this;

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.govContract'),
			layout: {
				type: 'grid',
				area: [[3, 3, 2]]
			},
			items: [
				me.createGovernmentContractIdField(),
				me.createGovernmentContractNumberField(),
				me.createGovernmentContractDateField()
			]
		});
	}

	createSellerAccountNumberField(): ExtComponent {
		const me = this;
		return (me.fields.sellerAccountNumber = createTextField({
			minLength: 11,
			fieldLabel: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.accountSeller'),
			maxLength: 11,
			name: me.createFieldName(me.sellerGovProcurementNamePrefix, 'litsSchetProd'),
			requiredFields: [
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'nomerGosKont'),
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'dataGosKont')
			],
			valueSrc: me.props.documentData
		}));
	}

	createSellerBudgetClassificationCodeField(): ExtComponent {
		const me = this;
		return (me.fields.sellerBudgetClassificationCode = createTextField({
			minLength: 20,
			fieldLabel: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.code'),
			maxLength: 20,
			name: me.createFieldName(me.sellerGovProcurementNamePrefix, 'kodProdByudzhKlass'),
			requiredFields: [
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'nomerGosKont'),
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'dataGosKont')
			],
			valueSrc: me.props.documentData
		}));
	}

	createSellerTargetClassificationCodeField(): ExtComponent {
		const me = this;
		return (me.fields.sellerTargetClassificationCode = createTextField({
			minLength: 20,
			fieldLabel: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.codeGoal'),
			maxLength: 20,
			name: me.createFieldName(me.sellerGovProcurementNamePrefix, 'kodTSeliProd'),
			requiredFields: [
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'nomerGosKont'),
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'dataGosKont')
			],
			valueSrc: me.props.documentData
		}));
	}

	createSellerFieldBlock(): ExtComponent {
		const me = this;

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.seller'),
			layout: {
				type: 'grid',
				area: [[3, 3, 3]]
			},
			items: [
				me.createSellerAccountNumberField(),
				me.createSellerBudgetClassificationCodeField(),
				me.createSellerTargetClassificationCodeField()
			]
		});
	}

	createSellerTreasuryOrgCodeField(): ExtComponent {
		const me = this;
		return (me.fields.sellerTreasuryOrgCode = createTextField({
			maxLength: 4,
			fieldLabel: edi.i18n.getMessage('column.code'),
			name: me.createFieldName(me.sellerGovProcurementNamePrefix, 'kodKaznachProd'),
			requiredFields: [
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'nomerGosKont'),
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'dataGosKont')
			],
			valueSrc: me.props.documentData
		}));
	}

	createSellerTreasuryOrgNameField(): ExtComponent {
		const me = this;
		return (me.fields.sellerTreasuryOrgName = createTextField({
			fieldLabel: edi.i18n.getMessage('column.short.name'),
			maxLength: 2000,
			name: me.createFieldName(me.sellerGovProcurementNamePrefix, 'naimKaznachProd'),
			requiredFields: [
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'nomerGosKont'),
				me.createFieldName(me.sellerGovProcurementNamePrefix, 'dataGosKont')
			],
			valueSrc: me.props.documentData
		}));
	}

	sellerTreasuryFieldBlock(): ExtComponent {
		const me = this;
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.sellerTreasury'),
			layout: {
				type: 'grid',
				area: [[3, 6]]
			},
			items: [me.createSellerTreasuryOrgCodeField(), me.createSellerTreasuryOrgNameField()]
		});
	}

	createFactorInfoFieldBlock(): ExtComponent {
		const me = this;

		const { isEdit, isCopy, isCorrect } = me.props.getCurrentUPDState();

		const name = me.createFieldName('svFaktor');
		const factorInfo = edi.utils.getObjectProperty(me.props.documentData, name);
		const isFactorInfoDefined = !!factorInfo;
		const canEditFactorInfo = isEdit || isCopy || isCorrect || !isFactorInfoDefined;

		const factor = (me.selectors.factor = me.props.createOS({
			itemId: 'factorInfo',
			allowBlank: true,
			ownCatalog: false,
			relations: me.props.factors,
			alwaysShowSelect: canEditFactorInfo,
			allowReset: canEditFactorInfo,
			ownOrg: false,
			fieldValues: isFactorInfoDefined
				? {
						...me.props.documentData,
						type: isFactorInfoDefined ? me.props.getOrgType(factorInfo).type : null
				  }
				: undefined,
			selectedOrgValues: isFactorInfoDefined ? me.props.documentData : undefined,
			downloadBankAccountsData: true,
			forceChangeFields: me.props.forceChangeFieldsForShips,
			callback: function () {
				me.props.checkValid();
				factor.checkOrgDataValid();
			},
			fieldsMap: me.props.selectorConfigsBuilder.getFactorFieldsMap(name),
			modalConf: me.props.selectorConfigsBuilder.getFactorModalConfig()
		}));
		me.props.setNameForCompanySelector(factor, 'documents.fns_upd.additionalInfo.factorInfo');

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.factorInfo'),
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [factor]
		});
	}

	createAssignmentBasisDocumentBlock(): ExtComponent {
		const me = this;

		const name = me.createFieldName('osnUstDenTreb');
		return (me.blocks.assignmentBasis = createNamedDocumentBlock(
			{
				title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.assignmentBasis'),
				data: edi.utils.getObjectProperty(me.props.documentData, name, true),
				name
			},
			{
				isSingleDocument: true
			}
		));
	}

	createTypeOfObligation(): ExtComponent {
		const me = this;
		const name = me.createFieldName('vidObyaz');
		const combo = (me.fields.obligationType = createCombo({
			store: edi.stores.createInlineStore(powerLimits, POWER_LIMITS_MODEL, undefined, {
				addEmptyRecord: false
			}),
			valueField: 'CODE_VALUE',
			displayField: 'displayValue',
			name,
			value: edi.utils
				.getObjectProperty(me.props.documentData, name, true)
				.map((vidObyaz: VidObyaz) => vidObyaz.kodVidObyaz),
			forceSelection: true,
			multiSelect: true,
			anyMatch: true,
			allowManualInput: true
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.obligation.type'),
			layout: {
				type: 'grid',
				area: [8]
			},
			items: [combo]
		});
	}

	createDOPspecialConditionsField(): ExtComponent {
		const me = this;

		const storeItems: { id: string; name: string }[] = scCodes;
		const dopSpecialConditions = (me.fields.dopSpecialConditions = createCombo({
			store: edi.stores.createInlineStore(storeItems, 'AUTHORITY_AREA'),
			name: me.createFieldName('spObstFDOP'),
			valueSrc: me.props.documentData,
			bind: {
				disabled: '{!isDOP}'
			},
			listeners: {
				change: () => me.onSpecialConditionsChanged()
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.dop.special.conditions'),
			layout: {
				type: 'grid',
				area: [8]
			},
			bind: {
				hidden: '{!isDOP}'
			},
			items: dopSpecialConditions
		});
	}

	createSCHFDOPspecialConditionsField(): ExtComponent {
		const me = this;

		const storeItems: { id: string; name: string }[] = scCodes;
		const schfdopSpecialConditions = (me.fields.schfdopSpecialConditions = createCombo({
			store: edi.stores.createInlineStore(storeItems, 'AUTHORITY_AREA'),
			name: me.createFieldName('spObstFSCHFDOP'),
			valueSrc: me.props.documentData,
			bind: {
				disabled: '{!isSCHFDOP}'
			},
			listeners: {
				change: () => me.onSpecialConditionsChanged()
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.schfdop.special.conditions'),
			layout: {
				type: 'grid',
				area: [8]
			},
			bind: {
				hidden: '{!isSCHFDOP}'
			},
			items: schfdopSpecialConditions
		});
	}

	createSCHFspecialConditionsStoreItems() {
		return [
			{
				id: 1,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.1')
			},
			{
				id: 2,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.2')
			},
			{
				id: 3,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.3')
			},
			{
				id: 4,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.4')
			},
			{
				id: 5,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.5')
			},
			{
				id: 6,
				name: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions.6')
			}
		];
	}
	createSCHFspecialConditionsField(): ExtComponent {
		const me = this;

		const storeItems: { id: number; name: string }[] = me.createSCHFspecialConditionsStoreItems();
		const schfSpecialConditions = (me.fields.schfSpecialConditions = createCombo({
			store: edi.stores.createInlineStore(storeItems, 'AUTHORITY_AREA'),
			name: me.createFieldName('spObstFSCHF'),
			valueSrc: me.props.documentData,
			bind: {
				disabled: '{!isSCHF}'
			},
			listeners: {
				change: () => me.onSpecialConditionsChanged()
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.schf.special.conditions'),
			layout: {
				type: 'grid',
				area: [8]
			},
			bind: {
				hidden: '{!isSCHF}'
			},
			items: schfSpecialConditions
		});
	}

	onSpecialConditionsChanged() {
		const me = this;
		if (typeof me.props.specialConditionsChangedHandler === 'function') {
			const { schfSpecialConditions, schfdopSpecialConditions, dopSpecialConditions } = me.fields;
			const specialCondition = {
				schf: !schfSpecialConditions?.isDisabled() ? schfSpecialConditions?.getValue() : null,
				schfdop: !schfdopSpecialConditions?.isDisabled() ? schfdopSpecialConditions?.getValue() : null,
				dop: !dopSpecialConditions?.isDisabled() ? dopSpecialConditions?.getValue() : null
			};
			me.props.specialConditionsChangedHandler(specialCondition);
		}
	}

	onUPDStateChangedHandler() {
		const me = this;

		const { updFunction } = me.props.getCurrentUPDState();

		const viewModel = me.view.getViewModel() as ExtViewModel<AdditionalInformationViewModel>;
		viewModel.set('updFunction', updFunction);
		me.view.updateLayout();
	}

	createSupportingDocumentBlock(): ExtComponent {
		const me = this;

		const name = me.createFieldName('soprDokFHZH');
		return (me.blocks.supportingDocumentDetails = createNamedDocumentBlock({
			title: edi.i18n.getMessage('documents.fns_upd_5_02n.supporting.document.details'),
			data: edi.utils.getObjectProperty(me.props.documentData, name, true),
			name
		}));
	}

	createView(): ExtComponent {
		const me = this;

		const { updFunction } = me.props.getCurrentUPDState();

		return (me.view = createFieldSet({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo'),
			collapsible: true,
			onUPDStateChanged: me.onUPDStateChangedHandler.bind(me),
			getFields: () => me.fields,
			viewModel: {
				data: {
					updFunction: updFunction ?? null
				} as AdditionalInformationViewModel,
				formulas: {
					isDOP: (get: Function) => isDOP_BusinessProcess(get('updFunction')),
					isSCHF: (get: Function) => isSCHF_BusinessProcess(get('updFunction')),
					isSCHFDOP: (get: Function) => isSCHFDOP_BussinessProcess(get('updFunction'))
				}
			},
			items: createContainer({
				layout: {
					type: 'grid',
					gap: [24, 16]
				},
				items: [
					createMaxWidthContainer({
						layout: {
							type: 'grid',
							gap: [24, 16]
						},
						items: [
							me.createGovernmentContractFieldBlock(),
							me.createSellerFieldBlock(),
							me.sellerTreasuryFieldBlock(),
							me.createFactorInfoFieldBlock()
						]
					}),
					me.createAssignmentBasisDocumentBlock(),
					createMaxWidthContainer({
						layout: {
							type: 'grid',
							gap: [24, 16]
						},
						items: [me.createTypeOfObligation(), me.createDOPspecialConditionsField()]
					}),
					me.createSupportingDocumentBlock(),
					createMaxWidthContainer({
						layout: {
							type: 'grid',
							gap: [24, 16]
						},
						items: [me.createSCHFDOPspecialConditionsField(), me.createSCHFspecialConditionsField()]
					})
				]
			})
		}));
	}
}

export const createAdditionalInformationFieldSet = (props: AdditionalInformationProps): ExtComponent => {
	const additionalInformation = new AdditionalInformation(props);
	return additionalInformation.createView();
};
