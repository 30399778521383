import { DocLines } from '../5_02N/createDocLinesGrid';
import { SvedTov } from '../5_02N/definitions/producLineDef';
// @ts-ignore
import { createNumberField } from '@UIkit/components/fields';

export class DocLines503 extends DocLines {
	createOkdpCode2Field({
		values,
		fieldConfig = {}
	}: { values?: SvedTov; fieldConfig?: AnyObject } = {}): ExtComponent {
		const me = this;
		return super.createOkdpCode2Field({
			values,
			fieldConfig: Object.assign(fieldConfig, {
				name: me.createFieldName('dopSvedTov.kodTovOKPD2')
			}),
			fieldBlockConfig: {
				title: edi.i18n.getMessage('line.item.additional.okpdCode2')
			}
		});
	}

	createTrackableItemInfoGridFields() {
		const fields = super.createTrackableItemInfoGridFields();
		return Ext.merge({}, fields, {
			stTovBezNDSProslezh: {
				createCustomItems: function (
					_conf: AnyObject,
					values: AnyObject,
					{ readOnly, disabled }: { readOnly: boolean; disabled: boolean }
				) {
					return [
						createNumberField({
							type: 'number',
							valueSrc: values,
							readOnly,
							disabled,
							name: 'stTovBezNDSProslezh',
							allowBlank: false,
							replaceComa: true,
							allowDecimals: true,
							vtype: 'fnsNumberN19_2'
						})
					];
				}
			}
		});
	}
}
