import { createFile, createLabel } from '@Components/fields';
import { createCreateButton } from '@Components/buttons';
import { createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createDetailLabel } from '@UIkit/components/fields';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createCertificateHandler } from '@App/js/signature/handlers/createCertificateHandler';
import { createPoaHandler } from '@App/js/signature/handlers/createPoaHandler';

const x5ContainerMethods = {
	/**
	 * Open modal for choosing variant of power of attorney - "paper_poa"  or "machine_readable_poa"
	 * @return {Promise<'paper_poa'|'machine_readable_poa'|null>}
	 */
	showPoaTypeSelectModal: function () {
		return new Promise((resolve) => {
			let selectedVariant = null;
			const modal = createModalPanel({
				selectedVariant: null,
				title: edi.i18n.getMessage('x5.agreement.container.poa.modal.title'),
				items: [
					createModalForm({
						items: [
							createLabel({
								html: edi.i18n.getMessage('x5.agreement.container.poa.modal.suggestion')
							})
						]
					})
				],
				buttons: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('x5.agreement.container.poa.modal.variant.machine'),
						handler: () => {
							selectedVariant = 'machine_readable_poa';
							modal.close();
						}
					}),
					createButton({
						cls: BUTTON_CLS.secondary,
						text: edi.i18n.getMessage('x5.agreement.container.poa.modal.variant.paper'),
						handler: () => {
							selectedVariant = 'paper_poa';
							modal.close();
						}
					})
				],
				listeners: {
					close: function () {
						resolve(selectedVariant);
					}
				}
			});
			modal.show();
		});
	},

	/**
	 * Show certificate selection modal
	 * @param	{Object}	certHandler
	 * @param	{string}	docType
	 * @param	{Object}	poaHandler
	 * @param	{Function}	[filterCertificatesFn]
	 * @return {Promise<{ok: boolean}>}
	 */
	selectCertificate: function (certHandler, docType, poaHandler, filterCertificatesFn) {
		return new Promise((resolve) => {
			edi.sign.selectCertificate(
				function (cert, _certObj, _isLocalCert, poa) {
					certHandler.set(cert);
					poaHandler.set(poa);
					resolve({ selectionCanceled: false });
				},
				function (isCloseAfterSelection) {
					if (!isCloseAfterSelection) {
						certHandler.set(null);
						poaHandler.set(null);
						resolve({ selectionCanceled: true });
					}
				},
				true,
				docType,
				undefined,
				{
					filterCertificatesFn
				}
			);
		});
	},

	/**
	 * Sign one document
	 * @param	{Object}	containerHeader
	 * @param	{String}	agreementId
	 * @param	{Object}	[certHandler]
	 * @param	{Object}	[poaHandler]
	 * @param	{Object}	[options]
	 * @return	{Promise<{success: boolean, doc: Object, canceled?: boolean, errorText?: string}>}
	 */
	signAgreementDoc: (containerHeader, agreementId, certHandler, poaHandler, options) =>
		new Promise(async (resolve) => {
			const certHandlers = certHandler || createCertificateHandler();
			const poaHandlers = poaHandler || createPoaHandler();
			if (!certHandlers.get()) {
				let { selectionCanceled } = await x5ContainerMethods.selectCertificate(
					certHandlers,
					edi.constants.DOCUMENT_TYPES.X5_AGREEMENT_CONTAINER,
					poaHandlers,
					options.filterCertificatesFn
				);
				if (selectionCanceled === true) {
					resolve({
						success: false,
						canceled: true,
						doc: containerHeader
					});
					return;
				}
			}

			edi.utils.sign(
				{
					id: containerHeader.id,
					type: containerHeader.type
				},
				undefined,
				function (failure, resultData) {
					resolve({
						success: !failure,
						doc: containerHeader,
						errorText: failure
							? edi.utils.formatComplexServerError(resultData, 'accept.registry.error.document.sign')
							: undefined
					});
				},
				agreementId,
				undefined,
				true,
				certHandlers,
				{
					signUrlMethod: 'PUT',
					signRefuse() {
						resolve({
							success: false,
							canceled: true,
							doc: containerHeader
						});
					}
				},
				poaHandlers
			);
		}),

	pushAgreementBeforeSign: function (containerId) {
		return new Promise((resolve) => {
			let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, { documentId: containerId }, true);
			edi.rest.sendRequest(url, 'PUT', Ext.encode({}), resolve, resolve);
		});
	},

	/**
	 * Retrieve children document which is ready for sign
	 * @param	{number}	containerId
	 * @return	{Promise<{success: boolean, doc: Object|null, errorObject?: Object}>}
	 */
	getAgreementForSign: function (containerId) {
		return new Promise((resolve) => {
			const success = function (response) {
				let docForSign = response?.data?.children?.find(
					(doc) => doc.type === edi.constants.DOCUMENT_TYPES.SUPPLIER_AGREEMENT
				);
				resolve({
					success: !!docForSign,
					doc: docForSign || null
				});
			};
			const fail = function (response) {
				resolve({
					success: false,
					doc: null,
					errorObject: response.data
				});
			};
			let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
				documentId: containerId
			});
			edi.rest.sendRequest(url, 'GET', undefined, success, fail);
		});
	},

	/**
	 * Sign container's child
	 * @param	{Object}	containerData
	 * @param	{Object}	options
	 * @param	{Object}	[certHandler]
	 * @param	{Object}	[poaHandler]
	 * @return	{Promise<{success: boolean, hasChanges: boolean, canceled?: boolean, errorText?: string, errorObject?: Object}>}		Promise
	 */
	x5ContainerSign: async function (containerData, options, certHandler, poaHandler) {
		let hasChanges = false;
		let poaVariant = options.hasUploadedPoa ? 'paper_poa' : null;
		//если бумажная доверенность уже загружена, то пропустим шаг с предложением выбора типа доверенности
		if (!poaVariant) {
			poaVariant = await x5ContainerMethods.showPoaTypeSelectModal();
		}
		//если нет типа доверенности, значит юзер закрыл окно выбора и дальше ничего не делаем
		if (!poaVariant) {
			return {
				success: false,
				hasChanges,
				canceled: true
			};
		}

		//если тип доверенности бумажная и загруженной доверенности нет, то покажем сначала окно загрузки файла доверенности
		let containerId = containerData.id;
		if (poaVariant === 'paper_poa' && !options.hasUploadedPoa) {
			const uploadResult = await x5ContainerMethods.uploadStatutoryDoc(
				containerId,
				options.senderOrgId,
				() => (hasChanges = true),
				null,
				options.getPushData
			);
			if (!uploadResult.success) {
				return {
					success: false,
					canceled: uploadResult.canceled,
					hasChanges,
					errorObject: uploadResult.errorObject,
					errorText: uploadResult.errorText
				};
			}
		}

		//если мы НЕ грузили бумажную доверенность (идем по пути МЧД) и документ только получен (еще не пушился),
		//то делаем ПУШ (что бы додвигать до WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT)
		//Если мы грузим бумажную доверенность, то пуш контейнера выполняется после создания LOA_RECEIVER
		if (poaVariant === 'machine_readable_poa' && containerData.state === edi.constants.STATE.READ) {
			await x5ContainerMethods.pushAgreementBeforeSign(containerId);
			hasChanges = true;
		}

		//получим из контейнера документ для подписания
		const getAgreementResult = await x5ContainerMethods.getAgreementForSign(containerId);
		const agreement = getAgreementResult.doc;
		if (!agreement || !getAgreementResult.success) {
			return {
				success: false,
				hasChanges,
				errorObject: getAgreementResult.errorObject
			};
		}

		//показываем выбор сертификата + МЧД если надо и выполняем подписание
		const signResult = await x5ContainerMethods.signAgreementDoc(
			containerData,
			agreement.id,
			certHandler,
			poaHandler,
			{
				filterCertificatesFn: function (cert) {
					let allowed = true;
					if (poaVariant === 'paper_poa') {
						//если тип доверенности "paper_poa", то покажем только сертификаты НЕ ФЛ
						allowed = cert.subject.innle || cert.subject.ogrnip;
					} else if (poaVariant === 'machine_readable_poa') {
						//если тип доверенности "machine_readable_poa", то покажем на выбор только сертификаты ФЛ и потом окно выбора МЧД
						allowed = !cert.subject.innle && !cert.subject.ogrnip;
					}
					return allowed;
				}
			}
		);

		return {
			success: signResult.success,
			canceled: signResult.canceled,
			hasChanges: hasChanges || signResult.success,
			errorText: signResult.errorText
		};
	},

	/**
	 * Show paper poa upload window
	 * @param	{number}	docId
	 * @param	{number}	senderOrgId
	 * @param	{Function}	submitSuccessCb
	 * @param	{Function}	beforePush
	 * @param	{()=>Object|null}	getPushData
	 * @return {Promise<{success: boolean, canceled?: boolean, errorText?: string, errorObject?: Object}>}
	 */
	uploadStatutoryDoc: function (docId, senderOrgId, submitSuccessCb, beforePush, getPushData) {
		return new Promise((resolve) => {
			const uploadForm = createModalForm({
				submitEmptyText: false,
				items: [
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.form.type'),
						items: [
							createDetailLabel({
								text: edi.i18n.getMessage(
									'documents.doctype.' + edi.constants.DOCUMENT_TYPES.LOA_RECEIVER
								)
							})
						]
					}),
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('document.file'),
						items: [
							createFile(
								{},
								{
									name: 'attachment',
									listeners: edi.fields.config.pdfFilefieldListeners
								}
							)
						]
					}),
					createLabel({
						text: edi.i18n.getMessage('document.package.upload.file.loa_receiver')
					})
				]
			});

			const modal = createModalPanel({
				title: edi.i18n.getMessage('document.file.upload.attachment.in.progress'),
				width: MODAL_SIZE.widthLarge,
				items: [uploadForm],
				listeners: {
					close: function () {
						if (!modal._normalClose) {
							resolve({
								success: false,
								canceled: true
							});
						}
					}
				},
				buttonsBefore: [
					createCreateButton(
						function () {
							const failure = function (resp) {
								edi.core.logMessage(
									'Error uploading dsf file' +
										(resp && resp.status ? ' status - ' + resp.status : ''),
									'warn'
								);
								const errorText = edi.utils.formatComplexServerError(
									resp,
									resp?.status ? 'error.server.' + resp.status : 'dsf.upload.failure'
								);
								modal.setLoading(false);
								edi.core.showError(errorText);
							};
							const pushDoc = function (postData, cb) {
								typeof beforePush === 'function' && beforePush();
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: docId
										},
										true
									),
									'PUT',
									Ext.encode(postData),
									cb,
									failure
								);
							};
							modal.setLoading(true);
							edi.core.submitUploadForm(
								uploadForm,
								edi.rest.services.DSF.FILE.POST,
								'dsf.create.saving',
								function (responseData) {
									if (responseData && responseData.data && responseData.data.id) {
										typeof submitSuccessCb === 'function' && submitSuccessCb(responseData);
										modal._normalClose = true;
										modal.close();
										const statutoryValue = {
											data: Ext.encode({ file: responseData.data.id }),
											doctime: Ext.Date.format(new Date(), 'd-m-Y'),
											parentId: docId,
											toOrgId: senderOrgId,
											docType: edi.constants.DOCUMENT_TYPES.LOA_RECEIVER
										};
										edi.rest.sendRequest(
											edi.rest.services.DOCUMENTS.POST,
											'POST',
											Ext.encode(statutoryValue),
											function () {
												const postData = typeof getPushData === 'function' && getPushData();
												if (postData) {
													pushDoc(postData, function () {
														resolve({ success: true });
														modal._normalClose = true;
														modal.close();
													});
												} else {
													resolve({ success: true });
													modal._normalClose = true;
													modal.close();
												}
											},
											failure
										);
									} else {
										failure(responseData);
									}
								},
								failure
							);
						},
						{
							scale: 'small'
						}
					)
				]
			});
			modal.show();
		});
	}
};

export { x5ContainerMethods };
