import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import {
	CHECKBOX_CLS,
	createCheckbox,
	createCombo,
	createDateField,
	createLabel,
	createTextField
} from '@UIkit/components/fields';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { RECADV_LINE_MODEL, RECADV_SUMMARY_MODEL } from './models';
import { RECADV_LINE_COLUMN, RECADV_SUMMARY_EDITABLE_COLUMN } from './columns';
import { createRecadv, createRecadvParties } from './methods';
import {
	createButtonContainer,
	createContainer,
	createFieldBlock,
	createFieldSet,
	createMaxWidthContainer
} from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { recadvSelectors } from '@Edi/modules/documents/LEGACY_REC_ADV/selectors';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { coreMethods } from '@Core/commons';

Ext.namespace('edi.modules');
edi.modules['document.create.recadv'] = function () {
	let moduleData,
		userData = edi.core.getUserData(),
		recadvFromInitData,
		buyer,
		seller,
		delivery,
		shipFrom,
		ultimateCustomer,
		createBtn,
		afterSave,
		initialData,
		costDecimals,
		createAndSendButton,
		form,
		id,
		parentId,
		isEdit = false,
		isCopy = false,
		isBasedOnDoc,
		productsGrid,
		sellerOrg,
		buyerId,
		sellerId,
		contractNumber = null,
		isNumberAutoGenerated = false,
		contractInternalId,
		availableTaxRates = edi.methods.taxRates.getRatesByCountry(userData.org.country, true),
		productValues = {
			products: []
		},
		taxCalculationMethod,
		customFieldsContainer;
	let topPath = '//Document-ReceivingAdvice/',
		customFieldsObj,
		customFieldsFromTransformation;
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param   {Object}    data            module data from modules handler
	 * @param   {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		customFieldsFromTransformation = moduleData.initData?.data?.data?.customFields;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		isBasedOnDoc = moduleData.initData.data?.hasLinkedDocuments || !!moduleData.initData.data?.parentId;

		let buyerOrg;
		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			recadvFromInitData = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			initialData = recadvFromInitData;
			delete initialData.fileId;
			const parties = moduleData.initData.data.parties;
			buyerOrg = moduleData.initData.data.buyerOrg;
			sellerId = parties.toOrg.id == userData.org.id ? parties.fromOrg.id : parties.toOrg.id;
		}

		buyerId = userData.org.id;
		if (buyerOrg && buyerOrg.id) {
			if (!buyerOrg.hasOwnProperty('attributes')) {
				buyerOrg = edi.utils.getOrg({
					orgId: buyerOrg.id
				});
			}
			taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
		}

		costDecimals = coreMethods.getUserOrgCostDecimals();

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const createMainDataBlock = function (docContent) {
		const header = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Header'
		);
		const docNumber = createTextField({
			fieldLabel: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('field.name.number'),
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			maxLength: 35,
			value:
				docContent || recadvFromInitData
					? edi.utils.getObjectProperty(
							docContent ? docContent : recadvFromInitData,
							'ReceivingAdvice-Header.ReceivingAdviceNumber'
					  ) + (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
					: undefined,
			name: 'ReceivingAdvice-Header.ReceivingAdviceNumber'
		});

		const docDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			value: docContent && !isCopy ? edi.utils.getObjectProperty(header, 'ReceivingAdviceDate') : new Date(),
			name: 'ReceivingAdvice-Header.ReceivingAdviceDate'
		});
		const goodsReceiptDate = createDateField({
			fieldLabel: edi.i18n.getMessage('goods.receipt.date'),
			allowBlank: false,
			value: docContent ? edi.utils.getObjectProperty(header, 'GoodsReceiptDate') : new Date(),
			name: 'ReceivingAdvice-Header.GoodsReceiptDate',
			emptyText: edi.i18n.getMessage('date')
		});

		const autogenCheckbox = isNumberAutoGenerated
			? createCheckbox({
					cls: CHECKBOX_CLS.inFieldBlock,
					boxLabel: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
					inputValue: true,
					checked: isNumberAutoGenerated,
					name: 'numberAutoGenerated',
					listeners: {
						change: function (_checkbox, isChecked) {
							docNumber.setFieldLabel(
								isChecked
									? edi.i18n.getMessage('document.generation.numbers.text')
									: edi.i18n.getMessage('field.name.number')
							);
							docNumber.setValue('');
							docNumber.setDisabled(isChecked);
							docNumber.allowBlank = isChecked;
							docNumber.validate();
						}
					}
			  })
			: null;

		const documentFunctionCodeValue = edi.utils.getObjectProperty(
			docContent,
			'ReceivingAdvice-Header.DocumentFunctionCode'
		);
		const functionalDocumentCode = createCombo({
			fieldLabel: edi.i18n.getMessage('functional.document.code'),
			name: 'ReceivingAdvice-Header.DocumentFunctionCode',
			store: edi.stores.createSimpleInlineStore(['9', '4', '3'], function (id) {
				return edi.i18n.getMessage('functional.document.code.' + id);
			}),
			value: docContent && documentFunctionCodeValue !== 'O' ? documentFunctionCodeValue : '9',
			anyMatch: true,
			allowBlank: false
		});

		return createMaxWidthContainer({
			layout: {
				type: 'grid',
				gap: [8, 16],
				area: [
					[4, 2, 2],
					[3, 6]
				]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [docNumber, docDate, goodsReceiptDate, functionalDocumentCode, autogenCheckbox]
		});
	};

	const createOrderNumberBlock = function (docContent) {
		const orderNumberValue = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Header.BuyerOrderNumber'
		);
		const orderDateValue = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Header.BuyerOrderDate'
		);

		const orderNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('buyer.order.number'),
			readOnly: orderNumberValue && isBasedOnDoc,
			value: orderNumberValue,
			name: 'ReceivingAdvice-Header.BuyerOrderNumber',
			maxLength: 35
		});

		const orderDate = createDateField({
			fieldLabel: edi.i18n.getMessage('order.date'),
			disabled: orderDateValue && isBasedOnDoc,
			value: orderDateValue,
			name: 'ReceivingAdvice-Header.BuyerOrderDate',
			emptyText: edi.i18n.getMessage('date')
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('to.order'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[4, 2]]
			},
			items: [orderNumber, orderDate]
		});
	};

	const createPartiesBlock = function (docContent) {
		const parties = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Parties'
		);
		const userOrg = userData.org;
		const userOrgAddr = edi.utils.getSomeAddressFromOrg(userOrg) || {};
		const fieldValues = {
			// for creation from scratch
			Name: userOrg.name,
			ILN: userOrg.iln,
			TaxID: userOrg.inn,
			Type: userOrgAddr.type,
			TaxRegistrationReasonCode: userOrg.kpp,
			UtilizationRegisterNumber: userOrg.regNr,
			PostalCode: userOrgAddr.zip || undefined,
			Country: userOrgAddr.countryCode || undefined,
			CityName: userOrgAddr.city || undefined,
			StreetAndNumber: userOrgAddr.street || undefined,
			Locality: userOrgAddr.locality || undefined,
			Housing: userOrgAddr.block || undefined,
			AdditionalInformation: userOrgAddr.addInf || undefined,
			District: userOrgAddr.area || undefined,
			State: userOrgAddr.regionCode || undefined,
			StateCode: userOrgAddr.regionCode || undefined,
			HouseNumber: userOrgAddr.building || undefined,
			RoomNumber: userOrgAddr.flat || undefined
		};

		const createOS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				valuesByMap: true,
				fieldValues: parties,
				callback: checkValid,
				forceChangeFields: {
					company_iln: true
				},
				processValues: orgConverter
			});

			return createOrgSelector(selectorConfig);
		};

		const buyerHasData = !!edi.utils.getObjectProperty(parties, 'Buyer.ILN');
		const buyerObj = {
			Buyer: fieldValues
		};

		buyer = createOS({
			readOnly: isEdit,
			processValues: orgConverter,
			is_valid: buyerHasData || (!recadvFromInitData && !docContent && !!fieldValues.ILN),
			fieldValues: parties || buyerObj,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id,
				converter: orgConverter
			}),
			selectedOrgValues: parties || orgConverter(userData.org),
			selectedOrg:
				edi.utils.getOrg({
					orgId: moduleData.initData.data?.fromOrg?.id
				}) || userData.org,
			onFormCreate: edi.selectors.fieldControls.updateInnField,
			callback: function (_values, org) {
				const orgId = org ? org.id : null;
				taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
				if (orgId) {
					taxCalculationMethod = edi.utils.getTaxCalculationMethod(org.attributes);
				}
				checkValid();
			},
			fieldsMap: recadvSelectors.getBuyerFieldsMap('Buyer'),
			modalConf: recadvSelectors.getBuyerModalCong()
		});
		const buyerBlock = createFieldBlock({
			title: edi.i18n.getMessage('documents.recadv.buyer'),
			items: [buyer]
		});

		const sellerIln = edi.utils.getObjectProperty(parties, 'Seller.ILN');
		const sellerHasData = !!sellerIln;
		const isSellerDefined = !docContent && sellerHasData;

		seller = createOS({
			relationsOnly: true,
			readOnly: isEdit,
			is_valid: sellerHasData,
			relations: isSellerDefined ? undefined : edi.relations.getRelations(),
			allowReset: !isSellerDefined,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			fieldValues: sellerHasData ? parties : undefined,
			selectedOrgValues: sellerHasData ? parties : undefined,
			selectedOrg:
				edi.utils.getOrg({
					orgILN: sellerIln
				}) ||
				edi.utils.getOrg({
					orgId: moduleData.initData.data?.toOrg?.id
				}),
			onFormCreate: edi.selectors.fieldControls.updateInnField,
			callback: function (_values, org) {
				const orgId = org ? org.id : null;
				if (!isBasedOnDoc && !isCopy) {
					sellerId = orgId;
				}

				const continueFn = function () {
					if (!isBasedOnDoc) {
						if (
							productsGrid.getPartnerId() != orgId &&
							productsGrid.productsGrid.getStore().getCount() > 0
						) {
							const modalClearWarn = edi.core.confirm(
								edi.i18n.getMessage('documents.order.modalClearWarn.title'),
								edi.i18n.getMessage('documents.order.modalClearWarn.text'),
								undefined,
								undefined,
								undefined,
								undefined,
								{
									closable: false,
									buttons: [
										createButton({
											cls: BUTTON_CLS.primary,
											text: edi.i18n.getMessage('documents.order.modalClearWarn.clear'),
											handler: function () {
												productsGrid.productsGrid.getStore().removeAll();
												modalClearWarn.close();
											}
										}),
										createButton({
											cls: BUTTON_CLS.secondary,
											text: edi.i18n.getMessage('documents.order.modalClearWarn.stay'),
											handler: function () {
												modalClearWarn.close();
											}
										})
									]
								}
							);
						}
						productsGrid.setPartnerId(orgId);
						if (shipFrom && orgId !== shipFrom.getOrgIdForLoccat()) {
							shipFrom.reset();
							shipFrom.isValid();
							shipFrom.setOrgIdForLoccat(orgId);
							shipFrom.setPartnerOrg(
								edi.utils.getOrg({
									orgId: orgId
								})
							);
						}
						if (orgId && (!sellerOrg || sellerOrg.id != orgId)) {
							sellerOrg = org;
						} else if (!orgId) {
							sellerOrg = null;
						}
					}
					validateGridRowsWithCustomFields();
					checkValid();
				};

				if (sellerId && buyerId) {
					edi.methods.custom_fields.initCustomFields({
						customFieldsObj,
						initialCustomFieldsData: customFieldsFromTransformation,
						docType: edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
						toOrgId: sellerId,
						fromOrgId: buyerId,
						container: customFieldsContainer,
						grid: productsGrid,
						topPath: topPath,
						finishCallback(obj) {
							customFieldsObj = obj;
							form.isValid();
							continueFn();
						},
						fail() {
							form.isValid();
							continueFn();
						}
					});
				} else {
					continueFn();
				}
			},
			fieldsMap: recadvSelectors.getSellerFieldsMap('Seller'),
			modalConf: recadvSelectors.getSellerModalConf()
		});
		const sellerBlock = createFieldBlock({
			title: edi.i18n.getMessage('documents.recadv.seller'),
			items: [seller]
		});

		const deliveryHasData = !!edi.utils.getObjectProperty(parties, 'DeliveryPoint.ILN');
		const isDeliveryDefined = !docContent && deliveryHasData;
		const deliveryObj = {
			DeliveryPoint: fieldValues
		};

		delivery = createOS({
			tooltip: 'document.delivery.tooltip',
			allowBlank: false,
			is_valid: deliveryHasData || (!recadvFromInitData && !docContent),
			fieldValues: parties || deliveryObj,
			selectedOrgValues: parties || deliveryObj,
			allowReset: true,
			alwaysShowSelect: !isDeliveryDefined,
			ownOrg: !isDeliveryDefined,
			ownCatalog: false,
			relationsFromDelcatByOrgId: buyerId,
			callback: function (values, org) {
				initialData
					? edi.utils.setObjectProperty(initialData, 'ReceivingAdvice-Parties.DeliveryPoint', null)
					: null;
				if (ultimateCustomer && org) {
					ultimateCustomer.setPartnerOrg(org);
				} else if (ultimateCustomer && values.company_iln) {
					ultimateCustomer.setPartnerOrg(edi.converters.convertValuesToOrg(values));
				}
				checkValid();
			},
			fieldsMap: recadvSelectors.getDeliveryFieldsMap('DeliveryPoint'),
			modalConf: recadvSelectors.getDeliveryModalConf()
		});
		const deliveryBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.delivery'),
			items: [delivery]
		});

		const partiesShipper = edi.utils.getObjectProperty(parties, 'ShipFrom');
		const isShipFromDefined = !!(partiesShipper && partiesShipper.ILN);

		shipFrom = createOS({
			tooltip: 'document.ship.from.tooltip',
			selectedRelationByMap: false,
			is_valid: true,
			partnerOrg: !!(docContent || !isShipFromDefined),
			partnerOrgText: 'form.btn.supplier.org',
			partnerOrgValues: sellerId
				? edi.utils.getOrg({
						orgId: sellerId
				  })
				: undefined,
			allowBlank: true,
			alwaysShowSelect: true,
			allowReset: true,
			fieldValues: isShipFromDefined ? parties : undefined,
			selectedOrgValues: isShipFromDefined ? parties : undefined,
			ownCatalog: false,
			relationsFromLoccatByOrgId: sellerId || sellerOrg?.id,
			callback: function () {
				initialData ? edi.utils.setObjectProperty(initialData, 'ReceivingAdvice-Parties.ShipFrom', null) : null;
				checkValid();
			},
			fieldsMap: recadvSelectors.getShipFromFieldsMap('ShipFrom'),
			modalConf: recadvSelectors.getShipFromModalConf()
		});
		const shipFromBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.ship.from'),
			items: [shipFrom]
		});

		const partiesCustomer = edi.utils.getObjectProperty(parties, 'UltimateCustomer');
		const isUltimateCustomerDefined = !!(partiesCustomer && partiesCustomer.ILN);

		ultimateCustomer = createOS({
			tooltip: 'documents.ultimate.customer.tooltip',
			selectedRelationByMap: false,
			is_valid: true,
			allowBlank: true,
			alwaysShowSelect: !partiesCustomer,
			allowReset: true,
			fieldValues: isUltimateCustomerDefined ? parties : undefined,
			selectedOrgValues: isUltimateCustomerDefined ? parties : undefined,
			ownCatalog: false,
			relationsFromDelcatByOrgId: buyerId,
			callback: checkValid,
			fieldsMap: recadvSelectors.getUltimateCustomerFieldsMap('UltimateCustomer'),
			modalConf: recadvSelectors.getUltimateCustomerModalConf()
		});
		const ultimateCustomerBlock = createFieldBlock({
			title: edi.i18n.getMessage('documents.ultimate.customer'),
			items: [ultimateCustomer]
		});

		return createFieldSet({
			title: edi.i18n.getMessage('document.section.parties'),
			collapsible: true,
			items: [
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [[6, 6], [6, 6], [6]]
					},
					items: [buyerBlock, sellerBlock, deliveryBlock, shipFromBlock, ultimateCustomerBlock]
				})
			]
		});
	};

	const createAdditionalInformationBlock = function (docContent) {
		const contractInput = createFieldBlock({
			title: edi.i18n.getMessage('document.select.contract'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3]]
			},
			items: [
				createTextField({
					name: 'contractNumber',
					value: contractNumber,
					maxLength: 175
				})
			]
		});

		const invoiceNumberValue = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Header.InvoiceNumber'
		);
		const invoiceNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			readOnly: invoiceNumberValue && isBasedOnDoc,
			value: invoiceNumberValue,
			name: 'ReceivingAdvice-Header.InvoiceNumber',
			maxLength: 35
		});

		const invoiceDateValue = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Header.InvoiceDate'
		);
		const invoiceDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			disabled: invoiceDateValue && isBasedOnDoc,
			value: invoiceDateValue,
			name: 'ReceivingAdvice-Header.InvoiceDate'
		});

		const invoiceNumberDate = createFieldBlock({
			title: edi.i18n.getMessage('documents.recadv.invoice'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [invoiceNumber, invoiceDate]
		});

		const despatchNumberValue = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Header.DespatchNumber'
		);
		const despatchNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			readOnly: despatchNumberValue && isBasedOnDoc,
			value: despatchNumberValue,
			allowBlank: false,
			name: 'ReceivingAdvice-Header.DespatchNumber',
			maxLength: 35
		});

		const despatchDateValue = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Header.DespatchDate'
		);
		const despatchDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			readOnly: despatchDateValue && isBasedOnDoc,
			value: despatchDateValue,
			name: 'ReceivingAdvice-Header.DespatchDate'
		});

		const despatchNumberDate = createFieldBlock({
			title: edi.i18n.getMessage('documents.recadv.waybill'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [despatchNumber, despatchDate]
		});

		return createFieldSet({
			title: edi.i18n.getMessage('documents.special.identifiers'),
			collapsible: true,
			items: [
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: [contractInput, invoiceNumberDate, despatchNumberDate]
				})
			]
		});
	};

	/**
	 * Дополнительные поля
	 */
	const createCustomFieldsContainer = function () {
		return (customFieldsContainer = createContainer({}));
	};

	const createProductsGrid = function (docContent) {
		/**
		 * Calculates price and tax summary according to tax recalculation method
		 * @param    {Object}     values              product data
		 * @param    {Object}     calcFieldsData      object with additional data that could override/extend values returned by gatherValuesRow
		 * @param    {Boolean}    roundResults        true to round calculated values
		 * @returns {*}
		 */
		const calculateValues = function (values, calcFieldsData, roundResults) {
			const data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV);
			if (calcFieldsData && 'object' == typeof calcFieldsData) {
				Ext.merge(data, calcFieldsData);
			}
			const calcData = edi.methods.product.price.recalculation(
				{
					taxRate: data.rateVal,
					amount: values.QuantityAccepted || values.QuantityReceived,
					netPrice: data.price,
					grossPrice: data.grossPrice,
					netSum: values.NetAmount,
					taxSum: values.TaxAmount,
					grossSum: values.GrossAmount
				},
				taxCalculationMethod,
				undefined,
				undefined,
				costDecimals
			);
			values.GrossAmount = calcData.grossSum;
			values.TaxAmount = calcData.taxSum;
			values.NetAmount = calcData.netSum;
			values.UnitGrossPrice = calcData.grossPrice;
			values.UnitNetPrice = calcData.netPrice;
			roundResults
				? (values = edi.methods.product.grid.roundValues(
						values,
						['GrossAmount', 'TaxAmount', 'NetAmount', 'UnitGrossPrice', 'UnitNetPrice'],
						costDecimals
				  ))
				: null;
			return values;
		};

		const processRecordValues = function (values) {
			const calculated = Ext.clone(values);
			calculateValues(calculated, undefined, true);
			return values;
		};

		const lines = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Lines.Line',
			true
		);
		if ('string' != typeof lines) {
			for (let i = 0; i < lines.length; i++) {
				const values = Ext.clone(lines[i]['Line-Item']);
				edi.document.actions.setInternalLineId(lines[i], values);
				productValues.products.push(processRecordValues(values));
			}
		}

		productsGrid = createProductGridBase({
			ownCatalog: false,
			title: edi.i18n.getMessage('receiving.advice.items'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: RECADV_LINE_MODEL,
			totalModel: RECADV_SUMMARY_MODEL,
			gridColumnConfig: RECADV_LINE_COLUMN,
			totalColumnConfig: RECADV_SUMMARY_EDITABLE_COLUMN,
			data: productValues.products,
			lockIfNoPartner: true,
			partnerId: sellerId || null,
			margin: '16 0 0',
			lineNumberFieldName: 'LineNumber',
			isValidRecord: function (record) {
				const product = record.getData(),
					receivedCount = product.QuantityReceived,
					acceptedCount = product.QuantityAccepted;

				const isValidLength = function (value) {
					return String(value).length <= 10;
				};

				const isValidPrices =
					isValidLength(product.NetAmount) &&
					isValidLength(product.UnitGrossPrice) &&
					isValidLength(product.UnitNetPrice);

				const isValidTax = edi.methods.taxRates.isValidRate(product.TaxRate, availableTaxRates, true);

				let isValidCount = receivedCount && isValidLength(receivedCount);
				if (acceptedCount) {
					isValidCount =
						isValidCount &&
						isValidLength(acceptedCount) &&
						parseInt(receivedCount) >= parseInt(acceptedCount);
				}

				return !!(isValidPrices && isValidCount && isValidTax);
			},
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
			docData: docContent,
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				checkValid();
			},
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			resetFormOnCatalogSelection: true,
			selectProductHandler: function (product) {
				const fields = this.getFormFields();
				const PackDetails = product.PackDetails ? product.PackDetails : {};

				fields.EAN ? fields.EAN.setValue(product.EAN) : null;
				fields.QuantityReceived && fields.QuantityReceived.getValue() < PackDetails.MinOrderedQuantity
					? fields.QuantityReceived.setValue(PackDetails.MinOrderedQuantity)
					: null;
				fields.ItemType ? fields.ItemType.setValue(product.ItemType) : null;
				fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
				fields.UnitGrossPrice ? fields.UnitGrossPrice.setValue(product.UnitGrossPrice) : null;
				fields.SupplierItemCode ? fields.SupplierItemCode.setValue(product.SupplierItemCode) : null;
				fields.BuyerItemCode ? fields.BuyerItemCode.setValue(product.BuyerItemCode) : null;
				fields.UnitNetPrice ? fields.UnitNetPrice.setValue(product.UnitNetPrice) : null;
				fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;

				fields.UnitOfMeasure ? fields.UnitOfMeasure.findAndSetValue(product.UnitOfMeasure) : null;
			},
			afterRowEdit: function (values, field) {
				if (field === 'QuantityAccepted' || field === 'QuantityReceived') {
					calculateValues(
						values,
						{
							amount: values.QuantityAccepted || values.QuantityReceived
						},
						true
					);
				}
			},
			modalFormConfig: {
				title: 'document.recadv.line',
				formMethods: {
					setTaxFields: function () {
						let values = Ext.clone(this.getFormValues());
						const fields = this.getFormFields();

						values = calculateValues(
							values,
							{
								amount: values.QuantityAccepted || values.QuantityReceived
							},
							true
						);
						fields.NetAmount ? fields.NetAmount.setValue(values.NetAmount) : null;
						fields.TaxAmount ? fields.TaxAmount.setValue(values.TaxAmount) : null;
						fields.GrossAmount ? fields.GrossAmount.setValue(values.GrossAmount) : null;
						fields.UnitNetPrice ? fields.UnitNetPrice.setValue(values.UnitNetPrice) : null;
						fields.UnitGrossPrice ? fields.UnitGrossPrice.setValue(values.UnitGrossPrice) : null;
					}
				},
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						customFieldTab: 'PRODUCT',
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								allowBlank: true,
								maskRe: /\d/i,
								validator: edi.methods.validators.ean
							},
							{
								title: edi.i18n.getMessage('column.quantity.received'),
								name: 'QuantityReceived',
								type: 'number',
								allowBlank: false,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3,
								listeners: {
									change: function () {
										this.setTaxFields();
									}
								},
								validator: function (receivedCount) {
									const fields = this.getFormFields();
									let isValid = true;
									if (fields.QuantityAccepted) {
										fields.QuantityAccepted.clearInvalid();
										const acceptedCount = fields.QuantityAccepted.getValue();
										isValid =
											!acceptedCount ||
											(receivedCount >= acceptedCount &&
												edi.constants.VALIDATORS.FLOAT.test(receivedCount) &&
												edi.methods.validators.positiveNonZero(receivedCount));
										if (acceptedCount) {
											fields.QuantityAccepted.validate(isValid);
										}
									}

									return (
										isValid ||
										edi.i18n.getMessage('recadv.accepted.count.must.be.less.than.received')
									);
								}
							},
							{
								title: edi.i18n.getMessage('column.quantity.accepted'),
								name: 'QuantityAccepted',
								type: 'number',
								allowBlank: true,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3,
								listeners: {
									change: function () {
										this.setTaxFields();
									}
								},
								validator: function (acceptedCount) {
									const fields = this.getFormFields();
									let isValid = true;
									if (fields.QuantityReceived) {
										fields.QuantityReceived.clearInvalid();
										const receivedCount = fields.QuantityReceived.getValue();
										isValid =
											!receivedCount ||
											(receivedCount >= acceptedCount &&
												edi.constants.VALIDATORS.FLOAT.test(receivedCount));
									}

									return (
										isValid ||
										edi.i18n.getMessage('recadv.accepted.count.must.be.less.than.received')
									);
								}
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.not.accepted'),
								name: 'QuantityNotAccepted',
								type: 'number',
								allowBlank: true,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.delivered'),
								name: 'QuantityDelivered',
								type: 'number',
								allowBlank: true,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.damaged'),
								name: 'QuantityDamaged',
								type: 'number',
								allowBlank: true,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.over.ordered'),
								name: 'QuantityOverOrdered',
								type: 'number',
								allowBlank: true,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.undelivered'),
								name: 'QuantityUndelivered',
								type: 'number',
								allowBlank: true,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.not.accept.reason'),
								name: 'ReasonCode',
								type: 'string'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								value: 'CU',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.product.description'),
								maxLength: 512,
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								maxLength: 35,
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								maxLength: 175,
								name: 'SupplierItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.country.of.origin'),
								name: 'CountryOfOrigin',
								type: 'combo',
								store: edi.stores.initOrgCountryStoreISO2()
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						customFieldTab: 'PRICES',
						items: [
							{
								title: edi.i18n.getMessage('organization.taxCalculationMethod'),
								type: 'label',
								text: edi.i18n.getMessage('organization.taxCalculationMethod.' + taxCalculationMethod)
							},
							{
								title: edi.i18n.getMessage('line.item.unit.net.price'),
								name: 'UnitNetPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 10,
								listeners: {
									change(_field, newVal, oldVal) {
										if (+newVal === +oldVal) {
											return;
										}
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.NET_PRICE ||
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.WITHOUT
											? this.setTaxFields()
											: null;
										const fields = this.getFormFields();
										if (fields.UnitGrossPrice) {
											fields.UnitGrossPrice.isValid();
										}
									}
								},
								validator: function (value) {
									let valid = true;
									const fields = this.getFormFields();
									if (fields.UnitGrossPrice) {
										if (!value && fields.UnitGrossPrice.getValue()) {
											valid = edi.i18n.getMessage('error.value.notvalid');
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.fns.tax.rate'),
								name: 'TaxRate',
								type: 'combo',
								store: edi.stores.createInlineStore(availableTaxRates),
								valueInitialize: true,
								validator: function (value) {
									return (
										edi.methods.taxRates.isValidRate(value, availableTaxRates, true) ||
										edi.i18n.getMessage('form.combo.not.selected')
									);
								},
								listeners: {
									change: function () {
										this.setTaxFields();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'UnitGrossPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 10,
								listeners: {
									change(_field, newVal, oldVal) {
										if (+newVal === +oldVal) {
											return;
										}
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.GROSS_PRICE
											? this.setTaxFields()
											: null;
										const fields = this.getFormFields();
										if (fields.UnitNetPrice) {
											fields.UnitNetPrice.isValid();
										}
									}
								},
								validator: function (value) {
									let valid = true;
									const fields = this.getFormFields();
									if (fields.UnitNetPrice) {
										if (
											(!value && fields.UnitNetPrice.getValue()) ||
											Number(value) < Number(fields.UnitNetPrice.getValue())
										) {
											valid = edi.i18n.getMessage('error.value.notvalid');
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								name: 'NetAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								value: 0
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								name: 'TaxAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								name: 'GrossAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							}
						]
					}
				]
			}
		});
		return productsGrid;
	};

	/**
	 * Creates receiving advice form
	 * @returns {Object}
	 */
	const createModuleForm = function (docContent) {
		const recadvRemarks = createFieldBlock({
			title: edi.i18n.getMessage('documents.column.remark'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				area: [[6]]
			},
			items: [
				createTextField({
					name: 'ReceivingAdvice-Header.Remarks',
					valueSrc: docContent ? docContent : recadvFromInitData,
					maxLength: 500,
					isTextarea: true
				})
			]
		});

		const referenceData = edi.utils.getObjectProperty(
			docContent ? docContent : recadvFromInitData,
			'ReceivingAdvice-Header.Reference.Reference-Elements.Reference-Element'
		);
		if (referenceData && referenceData.length) {
			for (let i = 0; i < referenceData.length; i++) {
				edi.document.actions.setInternalLineId(referenceData[i]);
				if (referenceData[i]['Reference-Type'] && referenceData[i]['Reference-Type'] === 'CN') {
					contractNumber = referenceData[i]['Reference-Id'];
					contractInternalId = referenceData[i][edi.constants.INTERNAL_LINE_ID];
				}
			}
		}
		const referenceTypeValue = edi.utils.getObjectProperty(referenceData, '0.Reference-Type');
		const referenceIdValue = edi.utils.getObjectProperty(referenceData, '0.Reference-Id', true);
		let referenceType = null;
		if (['FOR', 'FRC'].some((it) => it === referenceTypeValue)) {
			referenceType = createFieldBlock({
				title: edi.i18n.getMessage('documents.recadv.refference.type.' + referenceTypeValue),
				hidden: !referenceIdValue.length,
				layout: {
					type: 'grid',
					area: [[6]]
				},
				items: [
					createLabel({
						text: referenceIdValue
					})
				]
			});
		}

		createBtn = createCreateSaveButton(
			{
				formBind: true,
				disabled: true,
				handler: function () {
					const form = this.up('form').getForm();
					if (form.isValid()) {
						const values = edi.utils.collectFormValues(form);
						save(values);
					}
				}
			},
			isEdit
		);

		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV
				)
			)
		) {
			createAndSendButton = createCreateAndSendButton(
				function () {
					const form = this.up('form').getForm();
					if (form.isValid()) {
						const values = edi.utils.collectFormValues(form);
						afterSave = function (documentId, callback, responseData) {
							const isWarning =
								edi.utils.getAttributeByName(responseData.data?.attributes, 'isWarning') === 'true';
							const isBlocked =
								edi.utils.getAttributeByName(responseData.data?.attributes, 'isBlocked') === 'true';
							if (!isWarning && !isBlocked) {
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: documentId
										},
										true
									),
									'PUT',
									Ext.encode({}),
									null,
									edi.document.actions.defaultFailureHandler(
										moduleData.tab,
										'document.error.in.sending.process'
									),
									callback
								);
							} else {
								if ('function' == typeof callback) {
									callback();
								}
							}
						};
						save(values);
					}
				},
				null,
				isEdit
			);
		}

		form = createFormForModule({
			cls: 'edi-form document-add-form',
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage(isEdit ? 'document.recadv' : 'document.add.recadv')
				}),
				createMainDataBlock(docContent),
				createOrderNumberBlock(docContent),
				createPartiesBlock(docContent),
				createAdditionalInformationBlock(docContent),
				recadvRemarks,
				referenceType,
				createCustomFieldsContainer(),
				createProductsGrid(docContent)
			],
			buttons: createButtonContainer({
				items: [createAndSendButton, createBtn]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);
		return form;
	};

	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		let valid = false;
		const total = productValues.products.length;
		const validCompanies = validateCompanySelectors();
		const hasInvalid = form.hasInvalidField();
		const validGrid = productsGrid.isValid();
		if (!total || hasInvalid || !validCompanies || !validGrid) {
			createBtn.setDisabled(true);
			createAndSendButton ? createAndSendButton.setDisabled(true) : null;
		} else {
			createBtn.setDisabled(false);
			createAndSendButton ? createAndSendButton.setDisabled(false) : null;
		}
		if (total && !hasInvalid && validCompanies && validGrid) {
			valid = true;
		}
		return valid;
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	const validateCompanySelectors = function () {
		const isBuyerValid = buyer.isValid(),
			isSellerValid = seller.isValid(),
			isDeliveryValid = delivery.isValid(),
			isShipFromValid = shipFrom ? shipFrom.isValid() : true,
			isUltimateCustomerValid = ultimateCustomer ? ultimateCustomer.isValid() : true;

		return isBuyerValid && isSellerValid && isDeliveryValid && isShipFromValid && isUltimateCustomerValid;
	};

	/**
	 * @param {Object}    formValues    values from form
	 */
	const save = function (formValues) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		const values = formValues || {};
		let referenceData;
		const numberAutoGenerated = values.numberAutoGenerated;
		delete values.numberAutoGenerated;

		let clonedProductValues = Ext.clone(productsGrid.getValues());
		const customFieldsData = edi.methods.custom_fields.getCustomFieldsData(
			customFieldsObj,
			values,
			clonedProductValues.products,
			topPath
		);
		Object.values(customFieldsData).forEach(function (key) {
			delete values[key];
		});
		clonedProductValues.products.forEach(function (line) {
			Object.keys(line).forEach(function (key) {
				if (key.match(topPath)) {
					delete line[key];
				}
			});
		});
		if (values.contractNumber) {
			referenceData = [
				{
					'Reference-Id': values.contractNumber,
					'Reference-Type': 'CN'
				}
			];
			if (contractInternalId) {
				referenceData[0][edi.constants.INTERNAL_LINE_ID] = contractInternalId;
			}
			edi.utils.setObjectProperty(
				values,
				'ReceivingAdvice-Header.Reference.Reference-Elements.Reference-Element',
				referenceData
			);
			delete values.contractNumber;
		} else if (contractInternalId) {
			//Remove contract data, if we cleared field on edit
			referenceData = edi.utils.getObjectProperty(
				initialData,
				'ReceivingAdvice-Header.Reference.Reference-Elements.Reference-Element'
			);
			if (referenceData && referenceData.length) {
				for (let i = 0; i < referenceData.length; i++) {
					if (referenceData[i][edi.constants.INTERNAL_LINE_ID] == contractInternalId) {
						referenceData.splice(i, 1);
						break;
					}
				}
			}
		}
		let docContent = createRecadv({
			'ReceivingAdvice-Header': values['ReceivingAdvice-Header'],
			parties: createRecadvParties({
				Buyer: values['Buyer'],
				Seller: values['Seller'],
				DeliveryPoint: values['DeliveryPoint'],
				ShipFrom: values['ShipFrom'],
				UltimateCustomer: values['UltimateCustomer']
			}),
			productValues: clonedProductValues
		});
		const summary = edi.utils.getObjectProperty(docContent, 'ReceivingAdvice-Summary', false);
		!summary.TotalNetAmount ? (summary.TotalNetAmount = 0) : null;

		docContent = edi.document.actions.mergeDataBeforeSave(initialData, docContent, [
			{
				path: 'ReceivingAdvice-Lines.Line'
			},
			{
				path: 'ReceivingAdvice-Header.Reference.Reference-Elements.Reference-Element',
				type: edi.constants.MERGE_TYPES.MERGE_INTO
			}
		]);

		edi.utils.clearEmptyValues(docContent);
		const headerData = {
			data: Ext.encode(docContent),
			date: edi.utils.getObjectProperty(docContent, 'ReceivingAdvice-Header.ReceivingAdviceDate')
		};
		if (customFieldsData && Object.keys(customFieldsData).length > 0) {
			headerData.customFields = customFieldsData;
		}
		if (isNumberAutoGenerated && numberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(docContent, 'ReceivingAdvice-Header.ReceivingAdviceNumber');
		}
		const success = edi.document.actions.createSaveSuccessHandler(
			moduleData,
			isEdit ? id : undefined,
			afterSave,
			isEdit
		);
		const failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV
		);
		edi.document.actions.processDocument(
			sellerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
			parentId,
			isEdit ? id : undefined,
			success,
			failure,
			headerData
		);
	};

	/**
	 * Validates custom fields in grid's records
	 */
	let validateGridRowsWithCustomFields = function () {
		let records = productsGrid.productsGrid.getStore().getRange();
		records.forEach((rec) => {
			//проверять будем только валидные, т.к. невалидные все равно надо открыть и исправить
			if (rec.isExternalValid === true) {
				rec.isExternalValid = edi.methods.custom_fields.checkGridRow(customFieldsObj, rec.data);
			}
			productsGrid.productsGrid.getView().refreshNode(rec);
		});
		//productsGrid.productsGrid.getView().refresh()
	};

	/**
	 * Renders module layout
	 * @param   {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const callback = function () {
			seller.presetFromRelation(function () {
				'function' == typeof initCallBack ? initCallBack() : null;
				if (productValues.products.length) {
					edi.methods.taxRates.showInvalidProductsWarnIfNeeded(productValues.products, availableTaxRates, {
						allowBlankRate: true
					});
				}
			});
		};

		const data = moduleData.initData.data;
		id = data?.id;
		let initCustom = function () {
			let toOrgId = data?.toOrg?.id || sellerId;
			let fromOrgId = data?.fromOrg?.id || buyerId;
			if (toOrgId && fromOrgId) {
				edi.methods.custom_fields.initCustomFields({
					customFieldsObj,
					initialCustomFieldsData: customFieldsFromTransformation,
					docType: edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
					toOrgId: toOrgId,
					fromOrgId: fromOrgId,
					docId: id,
					container: customFieldsContainer,
					grid: productsGrid,
					topPath: topPath,
					finishCallback(obj) {
						customFieldsObj = obj;
						form.isValid();
						validateGridRowsWithCustomFields();
						checkValid();
					},
					fail() {
						form.isValid();
						validateGridRowsWithCustomFields();
						checkValid();
					}
				});
			}
		};

		const modulePanel = createAddModulePanel();
		const createModulePanel = function (docData) {
			modulePanel.add(createModuleForm(docData));
			moduleData.tab.add(modulePanel);
			callback();
			initCustom();
		};

		if (id) {
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			sellerOrg = data.toOrg;
			sellerId = sellerOrg.id;

			taxCalculationMethod = edi.utils.getTaxCalculationMethod(data.fromOrg.attributes);

			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (resp) {
					if (resp && resp.data) {
						initialData = resp.data;
						createModulePanel(initialData);
					} else {
						failure(resp);
					}
				},
				failure
			);
		} else {
			if (!taxCalculationMethod) {
				taxCalculationMethod = edi.utils.getTaxCalculationMethod(userData.org.attributes);
			}
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' === docNumberSettings.data.policy;
						}

						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return  {Boolean}      false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
