import { createAddModulePanel } from '@Components/panels';
import { createProxyConfig, createStore } from '@Components/storeComponents';
import { createTextField, createCombo, createDateField } from '@UIkit/components/fields';
import { createFieldBlock, createForm, createFormContainer } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { createOrgAutocomplete } from '@App/js/ediweb/components/OrgAutocomplete/OrgAutocomplete';
import { showErrorToast } from '@Ediweb/core';
import { createLabel } from '@Components/fields';

Ext.namespace('edi.modules');

edi.modules['approval.employees.create'] = function () {
	var moduleData,
		id,
		isEdit,
		isCorrect,
		isNewCorrect,
		form,
		documentData,
		documentFileBlock,
		uploadForm,
		maxFileSize,
		fileValue,
		fileDropper,
		employeeAutocomplete;
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;

		documentData = moduleData.initData.data;
		id = documentData?.id;
		isEdit = !!documentData;
		isNewCorrect = !!moduleData.initData.isNewCorrect;
		isCorrect = isNewCorrect || edi.utils.getAttributeByName(documentData?.attributes, 'CORRECTION') === 'true';

		if (isCorrect) {
			let tabTitleMsg = `${edi.i18n.getMessage('ediweb.approval.correct.title')} - ${documentData.title}`;
			moduleData.tab.module.title = tabTitleMsg;
			moduleData.tab.setTitle(tabTitleMsg);
			moduleData.tab.tab.setTooltip(tabTitleMsg);
		}

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Creates despatch advise form
	 * @returns {Object}
	 */
	var createModuleForm = function (documentData, routeArray) {
		const checkPermissions = function (employee) {
			let failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
					moduleData.tab.setLoading(false);
				});
			};

			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.CHECK_PERMISSIONS.GET, {
					id: employee.get('id')
				}),
				'GET',
				null,
				function (data) {
					if (!data.data) {
						employeeAutocomplete.clearValue();
						showErrorToast(
							edi.i18n.getMessage('approval.cannot.save.error.title'),
							edi.i18n.getMessage(
								'controller.approval.cannot.save.cause.no.permission.found.for.employee.error',
								[employee.get('displayname')]
							),
							{
								showCloseBtn: true
							}
						);
					}
				},
				failure
			);
		};

		var title = createLabel({
			margin: '8 0 32 0',
			typography: 'heading_02',
			text: isCorrect
				? `${edi.i18n.getMessage('ediweb.approval.correct.title')} - ${documentData.title}`
				: isEdit
				? documentData.title
				: edi.i18n.getMessage('approval.employees.create')
		});

		let party = documentData?.route
			? edi.utils.getObjectProperty(documentData, 'route.stages.0.parties.0')
			: edi.utils.getObjectProperty(documentData, 'stages.0.parties.0');
		let employeeValues = party?.employee;
		let employeeRec = employeeValues ? Ext.create(edi.models.getModel('EW_EMPLOYEES'), employeeValues) : null;
		var employee = createFieldBlock({
			title: edi.i18n.getMessage('org.profile.routes.responsible.employee'),
			items: [
				(employeeAutocomplete = createOrgAutocomplete(
					{
						name: 'employee',
						queryMode: 'remote',
						queryParam: 'name',
						displayField: 'displayname',
						valueField: 'id',
						forceSelection: true,
						anyMatch: true,
						allowBlank: false,
						listeners: {
							change: function (comp, value) {
								if (value) {
									checkPermissions(comp.lastSelectedRecords[0]);
								}
							},
							beforeQuery: function (plan) {
								plan.query = plan.query.trim();
							},
							afterrender: function (cmp) {
								if (employeeRec) {
									cmp.setValue(employeeRec);
									cmp.isValid();
								}

								const picker = cmp.getPicker();

								picker.on('viewlist', function (__self, record, itemEl) {
									const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

									var tpl = new Ext.XTemplate(
										'<tpl if="position">',
										'<span class="{[this.$comp.cls]}-text">{position}</span>',
										'</tpl>'
									);
									tpl.$comp = cmp;
									var el = tpl.append(wrapEl, record.getData());
								});
							}
						}
					},
					{
						sorters: [],
						proxy: createProxyConfig({
							type: 'ajax',
							url: edi.utils.compileURL(edi.rest.services.ORG_PROFILE.EMPLOYEES.GET, {
								gridName: 'active'
							})
						}),
						model: edi.models.getModel('EW_EMPLOYEES'),
						data: employeeRec ? [employeeRec] : undefined
					}
				))
			]
		});

		let actionsValues = null;
		if (party.actions) {
			actionsValues = Array.isArray(party.actions) ? party.actions : party.actions.split(',');
		}

		var actions = createFieldBlock({
			items: [
				createCombo({
					name: 'actions',
					fieldLabel: edi.i18n.getMessage('ediweb.employees.actions'),
					allowBlank: false,
					value: actionsValues,
					store: edi.stores.createSimpleInlineStore(
						['APPROVAL_REFUSAL', 'SIGNING_REFUSAL'],
						function (id) {
							return edi.i18n.getMessage('org.profile.routes.actions.' + id);
						},
						false
					),
					forceSelection: true,
					anyMatch: true
				})
			]
		});

		var comment = createFieldBlock({
			title: edi.i18n.getMessage('ew.documents.approvals.comment'),
			items: [
				createTextField({
					name: 'comment',
					maxLength: 255,
					isTextarea: true,
					valueSrc: documentData,
					minHeight: 100
				})
			]
		});
		var approvalTitle = createFieldBlock({
			title: edi.i18n.getMessage('ew.documents.approvals.title'),
			items: [
				createTextField({
					allowBlank: false,
					readOnly: isCorrect,
					name: 'title',
					value: edi.utils.getObjectProperty(documentData, 'title') || undefined
				})
			]
		});

		var docInfo = createFieldBlock({
			title: edi.i18n.getMessage('document.data'),
			layout: {
				type: 'grid',
				area: [[3, 4, 2]]
			},
			items: [
				createCombo({
					fieldLabel: edi.i18n.getMessage('document.type'),
					name: 'data.docType',
					readOnly: isCorrect,
					store: edi.stores.createSimpleInlineStore(
						[edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED, edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT],
						function (docType) {
							return edi.i18n.getMessage('documents.doctype.' + docType);
						}
					),
					anyMatch: true,
					forceSelection: false,
					value: isEdit
						? edi.utils.getObjectProperty(documentData, 'data.docType')
						: edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
					allowBlank: false
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					readOnly: isCorrect,
					allowBlank: false,
					name: 'data.docNumber',
					value: edi.utils.getObjectProperty(documentData, 'data.docNumber') || undefined
				}),
				createDateField({
					readOnly: isCorrect,
					fieldLabel: edi.i18n.getMessage('documents.column.date'),
					value: isEdit
						? edi.utils.formatDate(
								edi.utils.getObjectProperty(documentData, 'data.docDate'),
								edi.constants.DATE_FORMAT.CLIENT,
								edi.constants.DATE_FORMAT.SERVER
						  )
						: new Date(),
					submitFormat: edi.constants.DATE_FORMAT.CLIENT,
					name: 'data.docDate'
				})
			]
		});

		documentFileBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.file'),
			items: [
				(uploadForm = createForm({
					layout: {
						type: 'grid',
						area: [6]
					},
					submitEmptyText: false,
					bodyPadding: 0,
					items: [
						(fileDropper = createFileDropper({
							cls: 'drag-file ediweb-file-dropper',
							name: 'attachment',
							itemId: 'fileDropper',
							allowBlank: false,
							getAllowedFileTypesText: () =>
								edi.i18n.getMessage('document.dsf.upload.file.types.and.limit', {
									limit: maxFileSize
								}),
							fileData:
								isEdit && !!documentData.data.file
									? {
											fileName: documentData.data.file.fileName,
											fileDate: edi.utils.formatDate(
												documentData.data.file.modifyDate,
												edi.constants.DATE_FORMAT.FNS
											),
											fileTime: edi.utils.formatDate(
												documentData.data.file.modifyDate,
												edi.constants.DATE_FORMAT.TIME
											),
											fileSize: edi.utils.formatFileSize(documentData.data.file.fileSize)
									  }
									: null
						}))
					]
				}))
			]
		});

		var sendBtn = createButton({
			cls: [BUTTON_CLS.primary],
			text: edi.i18n.getMessage('document.send.to.approve.document'),
			itemId: 'createAndSignButtonItemId',
			glyph: edi.constants.ICONS.SEND,
			handler: function () {
				if (
					!edi.utils.setFocusToDocumentsWithGrid(
						form,
						null,
						[employee, actions, comment, approvalTitle, docInfo, uploadForm],
						null,
						false
					)
				) {
					return;
				}
				var values = edi.utils.collectFormValues(form);
				edi.utils.clearEmptyValues(values);
				save(values, function (respData, callback) {
					let docData = respData?.data?.id ? respData.data : documentData;

					//после создания дока сразу обновим модуль, т.к. он уже черновик в редактировании
					//и после обновления будем его ПУШать, иначе он будет повторно пытаться создать
					//новое согласование, а там уже файл не валидный (т.к. мы его уже загрузили он стерся)
					if (respData?.data?.id) {
						moduleData.initData.data = docData;
						moduleData.tab.setTitle(docData.title);
						moduleData.tab.tab.setTooltip(docData.title);
						moduleData.tab.setGlyph(edi.constants.ICONS.EDIT);
						moduleData.instance.init(moduleData, () => pushDoc(docData.id, callback));
					} else {
						pushDoc(docData.id, callback);
					}
				});
			}
		});
		var saveButton = createButton({
			cls: [BUTTON_CLS.secondary],
			text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
			handler: function () {
				if (
					!edi.utils.setFocusToDocumentsWithGrid(
						form,
						null,
						[employee, actions, comment, approvalTitle, docInfo, uploadForm],
						null,
						false
					)
				) {
					return;
				}
				var values = edi.utils.collectFormValues(form);
				edi.utils.clearEmptyValues(values);
				save(values);
			}
		});
		form = createForm({
			bodyPadding: '24',
			autoScroll: true,
			width: 1200,
			items: [
				title,
				createFormContainer({
					padding: '24 0 0 0',
					gap: [16, 24],
					area: [4, 4, 6, 4, 12, 12],
					items: [employee, actions, comment, approvalTitle, docInfo, documentFileBlock]
				})
			],
			buttonAlign: 'left',
			buttons: [sendBtn, saveButton]
		});

		form.isValid();
		form.on('validitychange', checkValid);

		uploadForm.isValid();
		uploadForm.on('validitychange', checkValid);

		checkValid();
		edi.utils.processModuleFormChange(form, moduleData);
		edi.utils.processModuleFormChange(uploadForm, moduleData);

		return form;
	};

	let pushDoc = function (docId, callback) {
		let failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
				moduleData.tab.setLoading(false);
			});
		};
		let success = function () {
			moduleData.tab.setLoading(false);
			edi.events.approval_documents.fireEvent('change', {
				id: docId
			});
			callback();
		};
		let url = edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.PUSH.PUT, {
			id: docId
		});
		edi.rest.sendRequest(url, 'PUT', null, success, failure);
	};

	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var hasInvalid = form.hasInvalidField();
		var valid = !hasInvalid;
		if (valid) {
			valid = uploadForm.isValid();
		}
		return valid;
	};

	var save = function (formValues, afterSave) {
		Object.entries(formValues).forEach(function (key, value, myself) {
			if (key.includes('hiddenfield') || key.includes('hidden')) {
				delete myself[key];
			}
		});
		if (!uploadForm.isValid() && !isEdit) {
			edi.core.showError('dsf.select.file');
		} else {
			var final = function (respData) {
				let docData = respData?.data?.id ? respData.data : documentData;
				moduleData.isChanged = false;
				moduleData.tab.setLoading(false);
				edi.document.actions.documentCreateProcessing(moduleData, docData, isEdit);
				if (docData?.route?.routeType === 'DYNAMIC') {
					edi.core.openModule('approval.employees.details', docData, null, null, null, {
						title: edi.i18n.getMessage('approval.employees.details') + ' ' + formValues.title
					});
				} else {
					edi.core.openModule('approval.documents.details', docData, null, null, null, {
						title: edi.i18n.getMessage('approval.documents.details') + ' ' + formValues.title
					});
				}
			};
			var success = function (responseData) {
				edi.events.approval_documents.fireEvent('change', {
					id: responseData?.data?.id || documentData?.id
				});
				//при создании исправления так же надо обновить и родителя, т.к. у него меняется набор действий
				if (isNewCorrect) {
					edi.events.approval_documents.fireEvent('change', {
						id: documentData.id
					});
				}
				if (typeof afterSave === 'function') {
					afterSave(responseData, function () {
						final(responseData);
					});
				} else {
					final(responseData);
				}
			};

			var failure = function (data) {
				fileDropper?.clearFileData(true);
				edi.core.showError(
					edi.utils.formatComplexServerError(
						data,
						data && data.status ? 'error.server.' + data.status : 'dsf.upload.failure'
					),
					function () {
						moduleData.tab.setLoading(false);
					}
				);
			};
			var onUploaded = function (fileId) {
				if (fileId) {
					formValues.data['file'] = fileId;
				} else if (fileValue) {
					formValues.data['file'] = fileValue.id;
				}

				let url = edi.utils.formatString(
					isNewCorrect
						? edi.rest.services.APPROVAL_DOCUMENTS.CORRECT.POST
						: isEdit
						? edi.rest.services.APPROVAL_DOCUMENTS.PUT
						: edi.rest.services.APPROVAL_DOCUMENTS.POST,
					{ id: documentData?.id }
				);

				let getNewCorrectionData = function () {
					let data = Ext.clone(documentData.data);
					data.file = data.file.id;
					data.docDate = edi.utils.formatDate(
						formValues.data.docDate,
						edi.constants.DATE_FORMAT.CLIENT,
						edi.constants.DATE_FORMAT.SERVER
					);
					return {
						route: {
							dynamic: true,
							stagesCount: 1,
							stages: [
								{
									stageNumber: 1,
									parties: [
										{
											partyNumber: 1,
											employee: formValues.employee,
											actions: [formValues.actions]
										}
									]
								}
							]
						},
						data: Ext.merge({}, data, formValues),
						title: documentData.title,
						comment: formValues.comment,
						attributes: {
							CORRECTION: {
								name: 'CORRECTION',
								value: true
							}
						}
					};
				};
				let routeId = documentData?.route?.id;
				let convertedData = {
					route: isEdit
						? routeId
						: {
								dynamic: true,
								stagesCount: 1,
								stages: [
									{
										stageNumber: 1,
										parties: [
											{
												partyNumber: 1,
												employee: formValues.employee,
												actions: [formValues.actions]
											}
										]
									}
								]
						  },
					title: formValues.title,
					comment: formValues.comment,
					data: formValues.data
				};
				if (isEdit) {
					let editData = {
						data: Ext.encode(convertedData)
					};
					editData.party = Ext.encode({
						employee: formValues.employee,
						actions: [formValues.actions]
					});
				}

				let payload = Ext.encode(
					isNewCorrect
						? getNewCorrectionData()
						: isEdit
						? {
								UPDATE: true,
								UPDATE_PARAMS: editData
						  }
						: convertedData
				);

				edi.rest.sendRequest(url, isEdit && !isNewCorrect ? 'PUT' : 'POST', payload, success, failure);
			};

			moduleData.tab.setLoading(true);
			var fileCmp = uploadForm.getForm().findField('attachment');
			if (!fileCmp || !fileCmp.getValue()) {
				onUploaded(null);
			} else {
				edi.core.submitUploadForm(
					uploadForm,
					edi.rest.services.DSF.FILE.POST,
					'dsf.create.saving',
					function (responseData) {
						if (responseData && responseData.data && responseData.data.id) {
							onUploaded(responseData.data.id);
						} else {
							failure(responseData);
						}
					},
					failure
				);
			}
		}
	};

	/**
	 * Renders module
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.removeAll();
		moduleData.tab.setLoading(true);

		var continueLoading = function (maxFileSizeValue) {
			maxFileSize = maxFileSizeValue;
			var modulePanel = createAddModulePanel();
			var failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {});
			};
			edi.rest.sendRequest(
				edi.rest.services.ORG_PROFILE.ROUTES.GLOBAL.GET,
				'GET',
				null,
				function (resp) {
					modulePanel.add(createModuleForm(isEdit ? documentData : {}, resp?.items ?? []));
					moduleData.tab.add(modulePanel);

					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				},
				failure
			);
		};

		var loadSettingsAndContinueFailure = function (data) {
			fileDropper?.clearFileData(true);
			edi.core.logMessage(
				'Error get limit size of file for upload ' + (data && data.status ? ' status - ' + data.status : ''),
				'warn'
			);
			edi.core.showError(
				edi.utils.formatComplexServerError(
					data,
					data && data.status ? 'error.server.' + data.status : 'dsf.get.limit.file.size.failure'
				),
				function () {
					continueLoading(1);
				}
			);
		};

		fileValue = {
			id: edi.utils.getObjectProperty(documentData, 'data.file.id'),
			name: edi.utils.getObjectProperty(documentData, 'data.file.fileName')
		};

		edi.methods.loadSettingsAndContinue(continueLoading, loadSettingsAndContinueFailure);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
