// @ts-ignore
import { createDateRangeField } from '@UIkit/components/fields';
import { filterMethods } from '@Edi/modules/documentGrids/filter';
import { createPanel } from '@Components/panels';
import { createCombo } from '@Components/fields';

export const forSignFilterMethods = {
	getFilterDoctypes: function (): string[] {
		return [
			edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
			edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
			edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED
		];
	},
	getFilterDoctypesStoreItems: function (docTypes: string[]): { id: string; name: string }[] {
		return docTypes.map((type) => ({
			id: type,
			name: edi.i18n.getMessage('documents.doctype.' + type)
		}));
	},
	createDoctypeField: function (): ExtComponent {
		const docTypes = forSignFilterMethods.getFilterDoctypes();
		const docTypesStoreItems = forSignFilterMethods.getFilterDoctypesStoreItems(docTypes);
		return createCombo({
			fieldLabel: edi.i18n.getMessage('documents.form.type'),
			emptyText: '',
			name: 'includeDocTypes',
			value: [],
			store: edi.stores.createInlineStore(docTypesStoreItems, 'SIMPLE'),
			allowBlank: false,
			multiSelect: true,
			anyMatch: true
		});
	},
	getFilterStates: function (): string[] {
		return [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW];
	},
	getFilterStatesStoreItems: function (states: string[]): { id: string; name: string }[] {
		return states.map((state) => ({
			id: state,
			name: edi.i18n.getTr(['BUSINESS_STATE', state])
		}));
	},
	createStateField: function (): ExtComponent {
		const states = forSignFilterMethods.getFilterStates();
		const statesStoreItems = forSignFilterMethods.getFilterStatesStoreItems(states);
		return createCombo({
			fieldLabel: edi.i18n.getMessage('documents.column.status'),
			name: 'includeBusinessStates',
			store: edi.stores.createInlineStore(statesStoreItems, 'SIMPLE'),
			value: [],
			allowBlank: false,
			multiSelect: true,
			anyMatch: true
		});
	},
	createFilterItems: function (): { formItemsMap: { [key: string]: ExtComponent }; items: ExtComponent } {
		const formItemsMap = {
			docType: forSignFilterMethods.createDoctypeField(),
			state: forSignFilterMethods.createStateField(),
			[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('documents.column.date'),
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
						enabledUTC: true
					},
					from: {
						chipTitle: edi.i18n.getMessage('documents.column.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.date.from'),
						name: 'fromDocTime'
					},
					to: {
						chipTitle: ' — ',
						name: 'toDocTime'
					}
				}
			}),
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.date.create'),
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
						fieldLabel: edi.i18n.getMessage('document.date.create.from'),
						name: 'creationDateFrom'
					},
					to: {
						chipTitle: ' — ',
						name: 'creationDateTo'
					}
				}
			}),
			[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.date.modify'),
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.date.modify') + ': ',
						fieldLabel: edi.i18n.getMessage('document.modify.date.from')
					},
					to: {
						chipTitle: ' — '
					}
				}
			})
		};

		const items = createPanel({
			layout: {
				type: 'grid',
				area: [
					[4, 4],
					[4, 4, 4]
				]
			},
			items: [
				formItemsMap.docType,
				formItemsMap.state,
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE],
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE],
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]
			]
		}) as ExtComponent;

		return { formItemsMap, items };
	},
	createArgs: function (): AnyObject {
		return filterMethods.createArgsCommon(...arguments);
	}
};
