import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import {
	createLabel,
	createCombo,
	createDateField,
	createHiddenField,
	createTextField
} from '@UIkit/components/fields';
import { createGrid } from '@UIkit/components/grid';
import { createCreateSaveButton } from '@Components/buttons';
import { PTYPE_COMBO_ITEMS_DISABLEABLE } from '@UIkit/plugins';
import { createContainer, createButtonContainer, createFieldBlock, createModalForm } from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { serviceActSelectors } from './selectors';
import { createLabelWithIcon } from '@Components/LabelWithIcon/LabelWithIcon';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

Ext.namespace('edi.modules');
edi.modules['document.create.service_act'] = function () {
	let moduleData,
		parentData,
		isNumberAutoGenerated = false,
		parentId,
		id,
		signerFillFromCert = {},
		userData = edi.core.getUserData(),
		productValues = { products: [] },
		isEdit = false,
		initialData,
		isCreate = false,
		buyerOrg,
		buyerOrgValues,
		seller,
		buyer,
		documentData = {},
		signerContainer,
		signerSecond = null,
		clearSecondSignerBtn,
		secondAuthorityField,
		authorityAreaInput,
		productsGrid,
		form,
		isIP = userData.org.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL,
		isPerson = userData.org.orgType === edi.constants.ORGANIZATION_TYPES.PERSON;
	let isFillingFromBackend = false;
	let isInitialBuild = false;
	let availableAreasData = Ext.clone(
		edi.stores.getStoreItemsByIds(edi.stores.data.authorityAreas, [0, 1, 2, 3, 4, 5, 6])
	);

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		if (moduleData.initData.data && moduleData.initData.data.data) {
			documentData = edi.utils.getObjectProperty(moduleData.initData.data.data, 'document');
			initialData = moduleData.initData.data.data;
			delete initialData.fileId;
			buyerOrg = moduleData.initData.data.buyerOrg;
			parentId = moduleData.initData.data.parentId;
			isEdit = true;
			isCreate = true;
		}

		if (buyerOrg) {
			if (!buyerOrg.hasOwnProperty('fnsId')) {
				buyerOrg = edi.utils.getOrg({
					orgId: buyerOrg.id
				});
			}
			buyerOrgValues = edi.converters.convertOrgToPartie(buyerOrg);
		}

		renderData(initCallBack);

		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const isValidOrganization = function (params) {
		params = params || {};

		const organization = params.organization;
		const organizationType = params.organizationType;
		const useOrganizationMap = params.useOrganizationMap;

		const getFieldValue = function (mapKey) {
			const organizationFieldsMap = {
				company_name: 'identification.legalEntity.orgName',
				company_kpp: 'identification.legalEntity.kpp',
				company_inn: 'identification.legalEntity.tin',

				individual_lastname: 'identification.individualPerson.name.lastName',
				individual_firstname: 'identification.individualPerson.name.firstName',
				individual_inn: 'identification.individualPerson.tin',

				addr_rus_region: 'address.addressRu.regionCode',
				addr_for_country: 'address.addressInfo.codeOfCountry',
				addr_for_text: 'address.addressInfo.addressText'
			};
			const fieldName = useOrganizationMap ? organizationFieldsMap[mapKey] : mapKey;

			return edi.utils.getObjectProperty(organization, fieldName);
		};

		let valid = getFieldValue('company_name');
		valid = valid || (getFieldValue('individual_lastname') && getFieldValue('individual_firstname'));

		if ('seller' === organizationType || 'buyer' === organizationType) {
			valid = valid && (getFieldValue('company_inn') || getFieldValue('individual_inn'));
			valid = valid && (!getFieldValue('company_inn') || getFieldValue('company_kpp'));
		}

		valid =
			valid &&
			(getFieldValue('addr_rus_region') || (getFieldValue('addr_for_country') && getFieldValue('addr_for_text')));
		return !!valid;
	};

	//Checks company controls, and marks them, if not valid
	const validateCompanySelectors = function () {
		const buyerIsValid = buyer.isValid();
		const sellerIsValid = seller.isValid();
		return buyerIsValid && sellerIsValid;
	};
	/**
	 * Set focus On error field
	 */
	//Checks document validity and enables/disables create button
	const checkValid = function () {
		const productsLength = productValues.products.length;
		form.isValid();
		const validForm = !form.hasInvalidField();
		const validCompanies = validateCompanySelectors();
		const validGrid = productsGrid.isValid();

		return productsLength && validForm && validCompanies && validGrid;
	};

	const createOS = function (selectorConfig) {
		Ext.applyIf(selectorConfig, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			selectedRelationByMap: true,
			valuesByMap: true,
			useHiddenFields: true,
			disableCommonAddressRenderer: true,
			disableCommonAddressTitleRenderer: true,
			disableAutoValidation: true
		});

		return createOrgSelector(selectorConfig);
	};

	const createFormItems = function () {
		const aktMainData = createFieldBlock({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [
				createTextField({
					fieldLabel: isNumberAutoGenerated
						? edi.i18n.getMessage('document.generation.numbers.text')
						: edi.i18n.getMessage('field.name.number'),
					allowBlank: isNumberAutoGenerated,
					disabled: isNumberAutoGenerated,
					maxLength: 35,
					value: isEdit ? edi.utils.getObjectProperty(documentData, 'actInfo.actNumber') : undefined,
					name: 'actInfo.actNumber'
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: false,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value: isEdit ? edi.utils.getObjectProperty(documentData, 'actInfo.actDate') : new Date(),
					name: 'actInfo.actDate'
				})
			]
		});

		const aktCurrency = createFieldBlock({
			title: edi.i18n.getMessage('invoice.currency'),
			items: [
				createCombo({
					valueSrc: documentData,
					value:
						edi.utils.getObjectProperty(documentData, 'actInfo.okv') ||
						edi.utils.getOkv({
							charCode: edi.constants.DEFAULT.CURRENCY
						}).code,
					name: 'actInfo.okv',
					store: edi.stores.initOkvCurrencyStore(),
					anyMatch: true,
					displayField: 'displayName',
					valueField: 'codeStr',
					allowBlank: false
				})
			]
		});

		const userOrganization = edi.converters.convertOrgToPartie(userData.org);

		const sellerInfo = edi.utils.getObjectProperty(documentData, 'actInfo.seller');
		const isSellerDefined = !!sellerInfo;
		const isValidSeller = isValidOrganization({
			organization: sellerInfo || userOrganization,
			useOrganizationMap: isSellerDefined,
			organizationType: 'seller'
		});

		seller = createOS({
			itemId: 'seller',
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id
			}),
			is_valid: isValidSeller,
			fieldValues: isSellerDefined ? documentData : userOrganization,
			selectedOrgValues: isSellerDefined ? documentData : userOrganization,
			selectedRelationByMap: isEdit,
			readOnly: isEdit && !isCreate,
			valuesByMap: isSellerDefined,
			callback: function (values) {
				seller.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'seller'
				});

				checkValid();
			},
			fieldsMap: serviceActSelectors.getSellerFieldsMap('actInfo.seller'),
			modalConf: serviceActSelectors.getSellerModalConf()
		});
		const sellerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.seller'),
			items: [seller]
		});

		const buyerInfo = edi.utils.getObjectProperty(documentData, 'actInfo.buyer'),
			isBuyerDefined = !!buyerInfo;
		const isValidBuyer = isValidOrganization({
			organization: buyerInfo,
			useOrganizationMap: isBuyerDefined,
			organizationType: 'buyer'
		});

		buyer = createOS({
			itemId: 'buyer',
			relationsOnly: true,
			allowBlank: false,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id
			}),
			is_valid: isValidBuyer,
			relations: !isCreate ? (!isEdit && buyerOrg ? buyerOrg : edi.relations.getRelations()) : undefined,
			allowReset: !isEdit && !isBuyerDefined,
			fieldValues: documentData,
			readOnly: isEdit && !isCreate,
			selectedOrgValues: !isEdit && buyerOrg ? buyerOrgValues : isBuyerDefined ? documentData : undefined,
			selectedOrg: buyerOrg ? buyerOrg : null,
			selectedRelationByMap: isEdit || buyerOrg,
			callback: function (values, org) {
				const orgId = org ? org.id : isCreate && buyerOrg ? buyerOrg.id : null,
					sameOrgSelected = buyerOrg && buyerOrg.id == orgId;
				initialData ? edi.utils.setObjectProperty(initialData, 'document.actInfo.buyer', null) : null;
				if (productsGrid.getPartnerId() != orgId) {
					productsGrid.productsGrid.getStore().removeAll();
				}
				productsGrid.setPartnerId(orgId);
				if (orgId && !sameOrgSelected) {
					buyerOrg = org;
				} else if (!orgId) {
					buyerOrg = null;
				}

				buyer.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'buyer'
				});

				checkValid();
			},
			fieldsMap: serviceActSelectors.getBuyerFieldsMap('actInfo.buyer'),
			modalConf: serviceActSelectors.getBuyerModalCong()
		});
		const buyerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.buyer'),
			items: [buyer]
		});

		const contractualRelationship = createFieldBlock({
			title: edi.i18n.getMessage('documents.service.act.field.supply.contract'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[4, 3]]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('field.name.number'),
					maxLength: 30,
					allowBlank: false,
					value: isEdit
						? edi.utils.getObjectProperty(documentData, 'contractualRelationship.contractNumber')
						: undefined,
					name: 'contractualRelationship.contractNumber'
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: false,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value: isEdit
						? edi.utils.getObjectProperty(documentData, 'contractualRelationship.contractDate')
						: new Date(),
					name: 'contractualRelationship.contractDate'
				})
			]
		});

		const additionalContract = createFieldBlock({
			title: edi.i18n.getMessage('documents.service.act.field.addaitional.contract'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[4, 3]]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('field.name.number'),
					maxLength: 30,
					allowBlank: false,
					value: isEdit
						? edi.utils.getObjectProperty(documentData, 'contractualRelationship.additionalContractNumber')
						: undefined,
					name: 'contractualRelationship.additionalContractNumber'
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: false,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value: isEdit
						? edi.utils.getObjectProperty(documentData, 'contractualRelationship.additionalContractDate')
						: new Date(),
					name: 'contractualRelationship.additionalContractDate'
				})
			]
		});

		return createContainer({
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6], 3, 12]
			},
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage(isEdit ? 'document.service_act' : 'document.add.service_act')
				}),
				aktMainData,
				sellerBlock,
				buyerBlock,
				aktCurrency,
				contractualRelationship,
				additionalContract
			]
		});
	};

	const getProductsData = function (productValues) {
		return {
			BounsType: productValues.products,
			totalPay: productValues.totalPay
		};
	};

	const createServiceActTableItem = function () {
		const lines = edi.utils.getObjectProperty(documentData, 'actServiceBonusType.BounsType', true);
		productValues.products = [];
		for (let i = 0; i < lines.length; i++) {
			const values = Ext.clone(lines[i]);
			edi.document.actions.setInternalLineId(lines[i], values);
			productValues.products.push(values);
		}

		if ('string' != typeof lines) {
			productValues.products = lines;
		}

		let i = 1;
		const productLineItemStoreData = [];
		while (i <= 23) {
			productLineItemStoreData.push(i++);
		}
		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'FNS_UPD_SERVICE_AKT_LINE',
			totalModel: 'FNS_UPD_SERVICE_AKT_SUMMARY',
			gridColumnConfig: 'fns_upd_service_act_lines',
			totalColumnConfig: 'fns_upd_service_act_summary',
			data: productValues.products,
			lineNumberFieldName: 'lineNumber',
			showSelectProductBtn: false,
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				checkValid();
			},
			docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
			docData: documentData,
			modalFormConfig: {
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('service_act.product.line.item.name'),
								name: 'name',
								type: 'combo',
								store: edi.stores.createSimpleInlineStore(productLineItemStoreData, function (id) {
									return edi.i18n.getMessage('service_act.product.line.item.name.item.' + id);
								}),
								valueField: 'name',
								forceSelection: false,
								editable: false,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.period'),
								name: 'period',
								type: 'combo',
								store: edi.stores.createSimpleInlineStore([1, 2], function (id) {
									return edi.i18n.getMessage('service_act.product.line.item.period.item.' + id);
								}),
								valueField: 'name',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.currency'),
								allowBlank: false,
								store: edi.stores.initOkeiStore(),
								type: 'combo',
								name: 'currency',
								forceSelection: false,
								anyMatch: true,
								valueField: 'id',
								allowManualInput: true,
								valueInitialize: true,
								displayField: 'name_code',
								validator: function (value) {
									const isValueInStore = !!edi.stores
										.initOkeiStore()
										.getRange()
										.find((item) => item.id === value.substr(0, 3));
									return (
										isValueInStore ||
										(value.length >= 3 && value.length <= 4) ||
										edi.i18n.getMessage('invalid.value.format')
									);
								}
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.planingSum'),
								name: 'planingSum',
								minLength: 1,
								maxLength: 30,
								type: 'number',
								allowDecimals: true,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.factSum'),
								name: 'factSum',
								minLength: 1,
								maxLength: 30,
								type: 'number',
								allowDecimals: true,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.sumWithoutNDS'),
								name: 'sumWithoutNDS',
								minLength: 1,
								maxLength: 30,
								type: 'number',
								allowDecimals: true,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.bonusAmountTotal'),
								name: 'bonusAmountTotal',
								type: 'number',
								allowDecimals: true,
								maxLength: 20,
								allowBlank: false
							}
						]
					}
				]
			}
		});

		productsGrid.setPartnerId(buyerOrg ? buyerOrg.id : null);

		return productsGrid;
	};

	const clearSecondSigner = function () {
		const fields = [
			'signers.legalEntity.position',
			!isIP ? 'signers.legalEntity.fullName.firstName' : 'signers.individualPerson.name.firstName',
			!isIP ? 'signers.legalEntity.fullName.lastName' : 'signers.individualPerson.name.lastName',
			!isIP ? 'signers.legalEntity.fullName.middleName' : 'signers.individualPerson.name.middleName',
			'signers.authorityArea',
			'signers.baseAuthory',
			'signers.baseOrgAuthory',
			'signers.state'
		];

		if (signerSecond && signerSecond.length) {
			for (let j = 0; j < fields.length; j++) {
				for (let i = 0, len = signerSecond.length; i < len; i++) {
					const elm = signerSecond[i].down('[name=' + fields[j] + ']');
					if (elm) {
						elm.setValue('');
					}
				}
			}
		}
		clearSecondSignerBtn.setDisabled(true);
	};

	const showModalListOfSecondSigner = function () {
		const rowClick = function (record) {
			moduleData.tab.setLoading(true);
			const data = record.getData(),
				mapper = {
					'subjectName.position': 'signers.legalEntity.position',
					'subjectName.firstName': !isIP
						? 'signers.legalEntity.fullName.firstName'
						: 'signers.individualPerson.name.firstName',
					'subjectName.lastname': !isIP
						? 'signers.legalEntity.fullName.lastName'
						: 'signers.individualPerson.name.lastName',
					'subjectName.middlename': !isIP
						? 'signers.legalEntity.fullName.middleName'
						: 'signers.individualPerson.name.middleName'
				},
				second_signer_default = {
					authorityArea: '6',
					baseAuthory: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default'),
					baseOrgAuthory: '',
					state: '1'
				};

			if (data && data.user && data.user.id) {
				clearSecondSignerBtn.setDisabled(false);
				edi.rest.sendRequest(
					edi.rest.services.SIGNER.SIGNERS.GET,
					'GET',
					{
						direction: edi.constants.DIRECTIONS.OUTBOX,
						userId: data.user.id,
						docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
					},
					function (headerData) {
						let prop, elm;
						if (signerSecond && signerSecond.length) {
							for (prop in mapper) {
								for (let i = 0; i < signerSecond.length; i++) {
									if (mapper.hasOwnProperty(prop)) {
										if (signerSecond[i] instanceof UI.components.FieldBlock) {
											elm = signerSecond[i]?.down('[name=' + mapper[prop] + ']');
											if (elm) {
												elm.setValue(edi.utils.getObjectProperty(data, prop));
												break;
											}
										}
									}
								}
							}
							if (headerData && headerData.items) {
								const itemsLen = headerData.items.length;
								let item; // must be One item or Zero
								if (itemsLen) {
									item = headerData.items[0]; // must be One item or Zero
								}
								isFillingFromBackend = true;
								const fillOrder = ['authorityArea', 'state', 'baseAuthory', 'baseOrgAuthory'];
								for (let k = 0; k < fillOrder.length; ++k) {
									if (second_signer_default.hasOwnProperty(fillOrder[k])) {
										prop = fillOrder[k];
										for (let j = 0, len = signerSecond.length; j < len; j++) {
											if (signerSecond[j] instanceof UI.components.FieldBlock) {
												elm = signerSecond[j].down('[name=' + 'signers.' + prop + ']');
												if (elm) {
													if (item && item.hasOwnProperty(prop)) {
														if (
															!(
																'authorityArea' === prop &&
																authorityAreaInput.getValue() === item[prop]
															)
														) {
															elm.setValue(item[prop]);
														}
													} else if (null !== second_signer_default[prop]) {
														elm.setValue(second_signer_default[prop]);
													}
													break;
												}
											}
										}
									}
								}
								isFillingFromBackend = false;
							}
						}
					},
					undefined
				);
			}
			moduleData.tab.setLoading(false);
			modal.close();
		};

		let grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: edi.utils.formatString(edi.rest.services.USER.CERTIFICATE.CERTIFICATES.ACTIVE.GET, {
					type: 'EDI_FNS_UPD'
				}),
				extraParams: {
					excludeCurrentUser: true
				}
			},
			storeConfig: {
				model: edi.models.getModel('USER_CERT'),
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'id',
					direction: 'ASC'
				}
			},
			gridConfig: {
				cls: 'modal-grid',
				region: 'center',
				columns: edi.columns.get('user_cert'),
				disablePaging: false,
				disableSelection: false,
				enableTextSelection: true,
				checkboxes: false,
				listeners: {
					select: function (_comp, record) {
						rowClick(record);
					}
				},
				dockedItems: undefined
			}
		});

		let modal = createModalPanel({
			cls: 'edi-modal-documents-selector',
			title: edi.i18n.getMessage('btn.tooltip.secondSigner'),
			width: MODAL_SIZE.widthLarge,
			height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
			layout: 'fit',
			items: [
				createModalForm({
					items: [grid]
				})
			],
			buttonsBefore: []
		});
		modal.show();
	};

	const checkSignersFields = function (field, addSignerFieldsHandler) {
		let value = field.getValue();
		if (['0', '1', '2', '3'].indexOf(value) > -1) {
			if (!signerSecond?.length && addSignerFieldsHandler) {
				signerSecond = signerContainer.add(
					addSignerFieldsHandler({}, true, edi.i18n.getMessage('company.signer') + ' 2')
				);
				signerContainer.layout.redoLayout();
			}
		} else if (signerSecond?.length) {
			for (let i = 0; i < signerSecond.length; i++) {
				signerContainer.remove(signerSecond[i]);
			}
			signerSecond = null;
			secondAuthorityField = null;
			signerContainer.layout.redoLayout();
		}
		checkValid();
	};

	const getSignerPathForTypeOfOwnership = function () {
		if (isIP) return 'individualPerson';
		if (isPerson) return 'individual';
		return 'legalEntity';
	};

	const createSignerFields = function (signer, isSecond, signerTitle) {
		const hasPermission =
			edi.permissions.hasPermission('READ_CERTIFICATE_DOCUMENT') &&
			edi.permissions.hasPermission('CLIENT_READ_EDI_SIGNER');

		clearSecondSignerBtn = hasPermission
			? createButton({
					cls: [BUTTON_CLS.light, BUTTON_CLS.small],
					tooltip: edi.i18n.getMessage('btn.tooltip.reset'),
					text: '',
					disabled: true,
					glyph: edi.constants.ICONS.HIGHLIGHT_OFF,
					handler: clearSecondSigner
			  })
			: null;

		const topText = !!isSecond
			? createLabelWithIcon({
					padding: 16,
					text: edi.i18n.getMessage('documents.fns_upd.signer.beforeSecondSigner'),
					glyph: edi.constants.ICONS.REJECT
			  })
			: null;

		const getSecondSignerBtn = !!isSecond
			? createButton({
					width: 200,
					margin: '8 0 16 0',
					cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
					tooltip: edi.i18n.getMessage('btn.tooltip.secondSigner'),
					text: edi.i18n.getMessage('form.btn.select.product'),
					disabled: false,
					glyph: edi.constants.ICONS.SEARCH,
					handler: showModalListOfSecondSigner
			  })
			: null;

		const baseAuthory = createContainer({
			items: [
				createTextField({
					maxLength: 255,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
					allowBlank: false,
					name: 'signers.baseAuthory',
					value: edi.utils.getObjectProperty(signer, 'baseAuthory')
				})
			]
		});

		const baseOrgAuthory = createContainer({
			items: [
				createTextField({
					maxLength: 255,
					name: 'signers.baseOrgAuthory',
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
					value: edi.utils.getObjectProperty(signer, 'baseOrgAuthory'),
					listeners: {
						change: function (comp) {
							const value = comp.getValue(),
								authorityInput = authorityAreaAndStatus.down('[name=signers.state]'),
								baseOrgAuthoryInput = baseOrgAuthory.down('[name=signers.baseOrgAuthory]');

							if (value && authorityInput) {
								baseOrgAuthoryInput.allowBlank = authorityInput.getValue() !== '3';
								baseOrgAuthoryInput.isValid();
							}
							comp.isValid();
						}
					}
				})
			]
		});

		const signerPositionCombo = !isIP
			? createCombo({
					fieldLabel: edi.i18n.getMessage('job.title'),
					store: edi.stores.initPositionsStore(),
					displayField: 'shortName',
					autoSelect: true,
					forceSelection: false,
					maxLength: 128,
					allowBlank: false,
					qtipText: !isSecond ? edi.i18n.getMessage('document.signer.get.data.from.profile') : undefined,
					readOnly: !isSecond,
					name: 'signers.legalEntity.position',
					value: edi.utils.getObjectProperty(signer, 'legalEntity.position')
			  })
			: null;

		const signerSection = createContainer({
			layout: {
				type: 'grid',
				area: [[0, 3, 3, 2, 2]]
			},
			items: [
				createHiddenField({
					value: userData.org.inn,
					name: `signers.${getSignerPathForTypeOfOwnership()}.tin`
				}),
				signerPositionCombo,
				createTextField({
					allowBlank: false,
					qtipText: !isSecond
						? signerFillFromCert.lastName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: edi.i18n.getMessage('document.signer.get.data.from.cert')
						: undefined,
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('last.name'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.lastName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.lastName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.lastName'),
					name: !isIP
						? `signers.${getSignerPathForTypeOfOwnership()}.fullName.lastName`
						: `signers.${getSignerPathForTypeOfOwnership()}.name.lastName`
				}),
				createTextField({
					allowBlank: false,
					maxLength: 60,
					qtipText: !isSecond
						? signerFillFromCert.firstName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: edi.i18n.getMessage('document.signer.get.data.from.cert')
						: undefined,
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('first.name'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.firstName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.firstName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.firstName'),
					name: !isIP
						? `signers.${getSignerPathForTypeOfOwnership()}.fullName.firstName`
						: `signers.${getSignerPathForTypeOfOwnership()}.name.firstName`
				}),
				createTextField({
					allowBlank: true,
					qtipText: !isSecond
						? signerFillFromCert.middleName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: edi.i18n.getMessage('document.signer.get.data.from.cert')
						: undefined,
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('patronymic.name'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.middleName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.middleName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.middleName'),
					name: !isIP
						? `signers.${getSignerPathForTypeOfOwnership()}.fullName.middleName`
						: `signers.${getSignerPathForTypeOfOwnership()}.name.middleName`
				})
			]
		});

		const certificateRegistrationIP = isIP
			? createContainer({
					layout: {
						type: 'grid',
						area: [10]
					},
					items: [
						createTextField({
							fieldLabel: edi.i18n.getMessage('individual.certificateRegistration'),
							maxLength: 255,
							name: `signers.individualPerson.certificateRegistrationIP`,
							value: edi.utils.getObjectProperty(
								signer,
								`${getSignerPathForTypeOfOwnership()}.certificateRegistrationIP`
							)
						})
					]
			  })
			: null;

		// If editing or for second signer we load store manually
		let authorityArea = createCombo({
			name: 'signers.authorityArea',
			fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
			store: edi.stores.createInlineStore(Ext.clone(availableAreasData), 'AUTHORITY_AREA'),
			displayField: 'name',
			value: edi.utils.getObjectProperty(signer, 'authorityArea'),
			allowBlank: false,
			blankText: undefined,
			listeners: !isSecond
				? {
						change: function (comp) {
							if (!isSecond && !isInitialBuild) {
								checkSignersFields(comp, createSignerFields);
							}
						}
				  }
				: undefined,
			forceSelection: true
		});

		if (!isSecond) {
			authorityAreaInput = authorityArea;
			authorityArea.on('select', function (comp) {
				if (secondAuthorityField && comp.getValue() == secondAuthorityField.getValue()) {
					secondAuthorityField.setValue('');
				}
			});
		} else {
			secondAuthorityField = authorityArea;
			authorityArea.on('expand', function (comp) {
				let secondSignerAreaStore = comp.getStore();
				let firstSignerArea = authorityAreaInput.getValue();

				//Second signer areas contains all available except an area, already selected by first signer
				const secondSignerAvailableAreas = availableAreasData.filter(function (item) {
					return item.id != firstSignerArea;
				});

				secondSignerAreaStore.loadData(secondSignerAvailableAreas);
			});
		}

		const signerStateStore = edi.stores.createInlineStore(edi.stores.data.signerStatuses, 'AUTHORITY_AREA');
		const authorityStatusCheck = function (comp) {
			const value = comp.getValue();
			let baseAuthoryInput = baseAuthory.down('[name=signers.baseAuthory]');
			let baseOrgAuthoryInput = baseOrgAuthory.down('[name=signers.baseOrgAuthory]');
			if (value && baseAuthoryInput) {
				baseOrgAuthoryInput.allowBlank = value != '3';
				baseOrgAuthoryInput.isValid();
				if (!isFillingFromBackend) {
					if (['1', '2', '3'].indexOf(value) > -1) {
						baseAuthoryInput.setValue(edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default'));
					} else {
						baseAuthoryInput.setValue(
							edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default.state.4')
						);
					}
				}
				checkValid();
			}
		};

		const authorityAreaAndStatus = createContainer({
			layout: {
				type: 'grid',
				area: [[5, 5]]
			},
			items: [
				authorityArea,
				createCombo({
					name: 'signers.state',
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.status'),
					store: signerStateStore,
					displayField: 'name',
					value: signer.state,
					allowBlank: false,
					forceSelection: true,
					qtipText: signerFillFromCert.state
						? edi.i18n.getMessage('document.signer.inn.cert.not.equal.inn.org')
						: undefined,
					plugins: [
						{
							ptype: PTYPE_COMBO_ITEMS_DISABLEABLE,
							disabledField: 'disabled'
						}
					],
					listeners: {
						afterrender: authorityStatusCheck,
						change: authorityStatusCheck
					}
				})
			]
		});

		const baseAuthoryFields = createContainer({
			layout: {
				type: 'grid',
				area: [[5, 5]]
			},
			items: [baseAuthory, baseOrgAuthory]
		});

		return [
			topText,
			createFieldBlock({
				title: signerTitle ?? edi.i18n.getMessage('company.signer') + (isSecond ? ' 2' : ''),
				layout: {
					type: 'grid',
					gap: 16
				},
				cls: 'edi-form-maxwidth',
				items: [
					getSecondSignerBtn,
					signerSection,
					authorityAreaAndStatus,
					certificateRegistrationIP,
					baseAuthoryFields
				]
			})
		];
	};

	const createSignerItems = function () {
		signerContainer = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 16
			}
		});

		let signer = edi.utils.getObjectProperty(documentData, 'signers');
		if (signer && Array.isArray(signer) && signer.length > 0) {
			let signersLength = signer.length;
			if (
				signersLength === 2 &&
				['5', '6'].indexOf(edi.utils.getObjectProperty(signer[0], 'authorityArea')) > -1
			) {
				signersLength = 1;
			}
			for (let i = 0; i < signersLength; i++) {
				const signerTitle =
					signersLength > 1 ? `${edi.i18n.getMessage('company.signer')}` + (i > 0 ? ' 2' : '') : null;
				let fields = signerContainer.add(createSignerFields(signer[i], !!i, signerTitle));
				if (!!i) {
					signerSecond = fields;
				}
			}
		} else {
			signerContainer.add(createSignerFields({}, false));
		}

		signerContainer.layout.redoLayout();
		return signerContainer;
	};

	/**
	 * Creates upd form
	 * @returns {Object}
	 */
	const createModuleForm = function () {
		const createBtn = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					save();
				}
			},
			isEdit
		);

		form = createFormForModule({
			cls: 'document-add-form',
			items: [createFormItems(), createServiceActTableItem(), createSignerItems()],
			buttons: createButtonContainer({
				items: [createBtn]
			})
		});

		form.on('validitychange', checkValid);
		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	const buildDocument = function (formValues) {
		const checkSignersPersonType = function (signers) {
			for (let i = 0; i < signers.length; i++) {
				if (signers[i].state == '4' && userData.org.orgType !== edi.constants.ORGANIZATION_TYPES.INDIVIDUAL) {
					const personPropName = 'individualPerson';
					if (
						!signers[i].hasOwnProperty(personPropName) ||
						Object.keys(signers[i][personPropName]).length === 0
					) {
						['individualPerson', 'legalEntity'].every(function (value) {
							if (signers[i].hasOwnProperty(value) && Object.keys(signers[i][value]).length > 0) {
								if (signers[i][value].hasOwnProperty('inn') && signers[i][value]['tin'].length !== 12) {
									signers[i][value]['tin'] = signers[i][value]['inn'];
									delete signers[i][value]['inn'];
								}
								signers[i][personPropName] = signers[i][value];
								signers[i][value]['name'] = signers[i][value]['fullName'];
								delete signers[i][value]['fullName'];
								delete signers[i][value]['position'];

								delete signers[i][value];
								return false;
							}
							return true;
						});
					}
				}

				if (signers[i].hasOwnProperty('legalEntity')) {
					delete signers[i]['legalEntity']['inn'];
				}
			}
			return signers;
		};

		const build = {
			actDocType: {
				actType: 2
			},
			signers: checkSignersPersonType(
				edi.methods.convertValuesFromMultipleFields(edi.utils.getObjectProperty(formValues, 'signers'))
			),
			actServiceBonusType: getProductsData(productValues),
			nameEconomicSubject: edi.i18n.getMessage('documents.fns_upd.economiclifeDocName.value'),
			contractualRelationship: edi.utils.getObjectProperty(formValues, 'contractualRelationship'),
			primaryDocName: 'Документ'
		};

		return Ext.Object.merge(formValues, build);
	};

	//Save document
	const saveDocument = function (document) {
		const doc = document.document;
		const success = function (data) {
			if (data && data.data && data.data.id) {
				parentData = data;
			}
			moduleData.isChanged = false;
			moduleData.tab.setLoading(false);
			edi.document.actions.documentCreateProcessing(moduleData, parentData, isEdit && !isCreate);
			if (parentId) {
				edi.events.documents.fireEvent('change', {
					id: parentId
				});
			} else {
				edi.events.documents.fireEvent('create');
			}
		};
		const failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
		);

		if (isEdit && !isCreate) {
			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P1,
				parentId,
				moduleData.initData.data.id,
				success,
				failure,
				{
					data: Ext.encode(document),
					number: edi.utils.getObjectProperty(doc, 'actInfo.actNumber'),
					date: edi.utils.getObjectProperty(doc, 'actInfo.actDate'),
					dateFormat: edi.constants.DATE_FORMAT.FNS
				}
			);
		} else {
			const headerData = {
				data: Ext.encode(document),
				number: edi.utils.getObjectProperty(doc, 'actInfo.actNumber'),
				date: edi.utils.getObjectProperty(doc, 'actInfo.actDate'),
				dateFormat: edi.constants.DATE_FORMAT.FNS
			};
			if (isNumberAutoGenerated) {
				headerData.autogen = true;
			} else {
				headerData.number = edi.utils.getObjectProperty(doc, 'actInfo.actNumber');
			}

			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P1,
				parentId,
				undefined,
				success,
				failure,
				headerData,
				{
					postData: {
						containerDocType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
					},
					createUri: edi.rest.services.DOCUMENTS.WITH_CONTAINER.POST
				}
			);
		}
	};

	/**
	 * Saves fns upd
	 */
	const save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		let values = buildDocument(edi.utils.collectFormValues(form));
		delete values['true'];
		values = {
			document: values
		};
		values = edi.document.actions.mergeDataBeforeSave(
			initialData,
			values,
			[
				{
					path: 'document.actServiceBonusType.BounsType'
				}
			],
			{
				fieldsToRemove: edi.constants.FIELDS_TO_REMOVE_AFTER_MERGE_FNS
			}
		);
		edi.utils.clearEmptyValues(values);
		saveDocument(values);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const data = moduleData.initData.data;
		const modulePanel = createAddModulePanel();
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		if (data && data.id) {
			isEdit = true;

			parentData = moduleData.initData;
			parentId = data.id;
			buyerOrg = data.toOrg;

			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
					documentId: parentId
				}),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						for (let i = 0; i < data.data.children.length; i++) {
							if (
								data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P1
							) {
								id = data.data.children[i].id;
								break;
							}
						}
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
								documentId: id
							}),
							'GET',
							{},
							function (data) {
								if (data && data.data) {
									isEdit = true;
									documentData = edi.utils.getObjectProperty(data.data, 'document');
									initialData = data.data;
									const buildForm = function (fieldsFillFromCert) {
										signerFillFromCert = fieldsFillFromCert;
										const form = createModuleForm();

										modulePanel.add(form);
										moduleData.tab.add(modulePanel);
										moduleData.tab.setLoading(false);
										if ('function' == typeof initCallBack) {
											initCallBack();
										}
									};
									const signersData = edi.utils.getObjectProperty(documentData, 'signers');
									edi.methods.fillUPDSignerValues(
										edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
										signersData[0],
										edi.utils.getObjectProperty(documentData, 'actInfo.seller'),
										buildForm,
										null,
										null,
										isCreate
									);
								} else {
									failure(data);
								}
							},
							failure
						);
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			const createModulePanel = function () {
				if (!documentData.signers) {
					documentData.signers = [{}];
				}
				const buildForm = function (fieldsFillFromCert) {
					signerFillFromCert = fieldsFillFromCert;
					const form = createModuleForm();
					modulePanel.add(form);
					moduleData.tab.add(modulePanel);
					moduleData.tab.setLoading(false);
					buyer.presetFromRelation(function () {
						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					});
				};

				edi.methods.fillUPDSignerValues(
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
					documentData.signers[0],
					edi.converters.convertOrgToPartie(userData.org),
					buildForm,
					null,
					null,
					isCreate
				);
			};
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' === docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
