import { DocumentPackageHeader, PackageType } from '@Edi/modules/documentPackages/entities';

export const allowActionMethods = {
	allowSignPackage: function (checkOptions: AnyObject): boolean {
		const hasAvailableDos = (checkOptions.packageDocs as DocumentHeader[]).some((docHeader) => {
			const docRecord = edi.models.createInstance('DOCUMENT', docHeader);
			const docCheckOptions = edi.action.getDocumentData(docRecord, null, {
				actionFromPackageModule: true
			});
			return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.SIGN, docCheckOptions);
		});
		if ((checkOptions.packageHeader as DocumentPackageHeader).type === PackageType.ENCLOSED) {
			const hasRejectedDsf = (checkOptions.packageDocs as DocumentHeader[]).some(
				(doc) => doc.businessState === edi.constants.STATE.REJECTED
			);
			return !hasRejectedDsf && hasAvailableDos;
		} else {
			return hasAvailableDos;
		}
	},
	allowRejectPackage: function (checkOptions: AnyObject): boolean {
		return (checkOptions.packageDocs as DocumentHeader[]).some((docHeader) => {
			const docRecord = edi.models.createInstance('DOCUMENT', docHeader);
			const docCheckOptions = edi.action.getDocumentData(docRecord, null, {
				actionFromPackageModule: true
			});
			const rejectAllowed = edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.REJECT, docCheckOptions);
			const clarifyAllowed = edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.CLARIFY, docCheckOptions);
			return rejectAllowed || clarifyAllowed;
		});
	}
};
