import './EDI_FNS_UKD/actionRules.js';
import './EDI_FNS_UKD/document.create.fns_ukd_5_01n.js';
import './EDI_FNS_UKD/document.details.fns_ukd.js';
import './EDI_FNS_UKD/document.details.fns_ukd_5_01n.js';
import './EDI_FNS_UPD/actionRules.js';
import './EDI_FNS_UPD/document.create.fns_upd_5_01n.js';
import './EDI_FNS_UPD/document.create.fns_upd_5_02n.ts';
import './EDI_FNS_UPD/document.create.fns_upd_5_03n.ts';
import './EDI_FNS_UPD/5_02N/index.ts';
import './EDI_FNS_UPD/5_03N/index.ts';
import './EDI_FNS_UPD/document.details.fns_upd.js';
import './EDI_FNS_UPD/document.details.fns_upd_5_01n.js';
import './EDI_FNS_UPD/document.details.fns_upd_5_02.js';
import './EDI_FNS_UPD/document.details.fns_upd_5_02n.ts';
import './EDI_FNS_UPD/document.details.fns_upd_5_03n.ts';
import './EDI_FNS_UPD_SERVICE_AKT/document.create.service_act.js';
import './EDI_FNS_UPD_SERVICE_AKT/document.details.service_act.js';
import './EDI_FNS_UPD_SERVICE_AKT/actionRules.js';
import './TORG_2/document.create.torg_2.js';
import './TORG_2/document.details.torg_2.js';
import './TORG_2/actionRules.js';
