export const getBpNameByDocFlowType = (docFlowType: string): string => {
	const map: { [docFlowType: string]: string } = {
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_502]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_503]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_502]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_503]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_502]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_503]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_502]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_503]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_502]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14,
		[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_503]:
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14
	};
	return map[docFlowType] ?? docFlowType;
};
