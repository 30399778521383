const RECADV_LINE_COLUMN = 'recadv_lines';
const RECADV_SUMMARY_EDITABLE_COLUMN = 'recadv_summary_editable';
const RECADV_SUMMARY_DETAIL_COLUMN = 'recadv_summary';
const RECADV_LINE_DETAIL_COLUMN = 'recadv_lines_details';

edi.columns.addColumns({
	[RECADV_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 1
		},
		itemDescription: {
			text: 'line.item.product.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 2
		},
		CountryOfOrigin: {
			text: 'line.item.country.of.origin',
			dataIndex: 'CountryOfOrigin',
			flex: 1,
			hidden: true
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'column.unit.net.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitNetPrice',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 1
		},
		unitGrossPrice: {
			text: 'column.unit.gross.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitGrossPrice',
			flex: 1
		},
		netAmount: {
			text: 'column.net.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'NetAmount',
			flex: 1
		},
		taxAmount: {
			text: 'column.tax.amount',
			flex: 1,
			dataIndex: 'TaxAmount',
			renderer: function (value, meta, record) {
				var data = record.data,
					result = '';
				if (Ext.isNumeric(value)) {
					result = edi.renderers.displayCurrencyDecimals(value);
					if (Ext.isNumeric(data.TaxAmount)) {
						result += ' (' + edi.renderers.displayCurrencyDecimals(data.TaxAmount) + ')';
					}
				}
				return result;
			}
		},
		grossAmount: {
			text: 'column.gross.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'GrossAmount',
			flex: 1
		},
		itemType: {
			text: 'column.item.type',
			dataIndex: 'ItemType',
			flex: 1,
			order: 4,
			renderer: 'itemType'
		},
		quantityReceived: {
			text: 'column.quantity.received',
			dataIndex: 'QuantityReceived',
			flex: 1,
			order: 5,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10',
				allowDecimals: true,
				decimalPrecision: 3,
				allowBlank: false,
				minValue: 1
			}
		},
		quantityAccepted: {
			text: 'column.quantity.accepted',
			dataIndex: 'QuantityAccepted',
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10WithZero',
				allowDecimals: true,
				decimalPrecision: 3,
				allowBlank: true,
				minValue: 1
			}
		},
		quantityDelivered: {
			text: 'line.item.quantity.delivered',
			dataIndex: 'QuantityDelivered',
			hidden: true,
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10WithZero',
				allowDecimals: true,
				decimalPrecision: 3,
				allowBlank: true,
				minValue: 1
			}
		},
		quantityDamaged: {
			text: 'line.item.quantity.damaged',
			dataIndex: 'QuantityDamaged',
			hidden: true,
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10WithZero',
				allowDecimals: true,
				decimalPrecision: 3,
				allowBlank: true,
				minValue: 1
			}
		},
		quantityOverOrdered: {
			text: 'line.item.quantity.over.ordered',
			dataIndex: 'QuantityOverOrdered',
			hidden: true,
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10WithZero',
				allowDecimals: true,
				decimalPrecision: 3,
				allowBlank: true,
				minValue: 1
			}
		},
		quantityUndelivered: {
			text: 'line.item.quantity.undelivered',
			dataIndex: 'QuantityUndelivered',
			hidden: true,
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10WithZero',
				allowDecimals: true,
				decimalPrecision: 3,
				allowBlank: true,
				minValue: 1
			}
		}
	},

	[RECADV_SUMMARY_DETAIL_COLUMN]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'TotalLines',
			flex: 1,
			sortable: false,
			hideable: false
		},
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalGoodsReceiptAmount: {
			text: 'column.quantity.received',
			dataIndex: 'TotalGoodsReceiptAmount',
			menuDisabled: true,
			order: 2,
			flex: 1
		}
	}
});
edi.columns.addColumns({
	[RECADV_LINE_DETAIL_COLUMN]: {
		parents: RECADV_LINE_COLUMN,
		config: {
			quantityNotAccepted: {
				text: 'line.item.quantity.not.accepted',
				dataIndex: 'QuantityNotAccepted',
				hidden: true,
				flex: 1
			},
			reasonCode: {
				text: 'line.item.quantity.not.accept.reason',
				dataIndex: 'ReasonCode',
				hidden: true,
				flex: 1
			}
		}
	},
	[RECADV_SUMMARY_EDITABLE_COLUMN]: {
		parents: RECADV_SUMMARY_DETAIL_COLUMN,
		config: {
			totalNetAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalTaxAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalGrossAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			}
		}
	}
});

export { RECADV_LINE_COLUMN, RECADV_SUMMARY_EDITABLE_COLUMN, RECADV_LINE_DETAIL_COLUMN, RECADV_SUMMARY_DETAIL_COLUMN };
