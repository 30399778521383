const INVOICE_LINE_COLUMN = 'invoice_lines';
const INVOICE_SUMMARY_COLUMN = 'invoice_summary';
const INVOICE_SUMMARY_EDITABLE_COLUMN = 'invoice_summary_editable';
const INVOICE_TAX_SUMMARY_COLUMN = 'invoice_tax_summary';

edi.columns.addColumns({
	[INVOICE_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 0.5
		},
		description: {
			text: 'column.item.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 0.5
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		count: {
			text: 'column.invoice.quantity',
			dataIndex: 'InvoiceQuantity',
			flex: 0.75,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount10', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 3,
				allowDecimals: true,
				allowBlank: false,
				minValue: 1
			},
			renderer: 'decimal'
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			hidden: true,
			flex: 1
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			hidden: true,
			flex: 1
		},
		taxCategoryCode: {
			text: 'column.tax.category.code',
			dataIndex: 'TaxCategoryCode',
			hidden: true,
			flex: 1,
			renderer: 'taxCategoryCode'
		},
		tax: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 0.5
		},
		pricePerUnit: {
			text: 'column.invoice.unit.net.price',
			dataIndex: 'InvoiceUnitNetPrice',
			flex: 1.25,
			renderer: 'displayCurrencyDecimals'
		},
		pricePerUnitWithTax: {
			text: 'column.unit.gross.price',
			dataIndex: 'InvoiceUnitGrossPrice',
			flex: 1.25,
			renderer: 'displayCurrencyDecimals'
		},
		priceWithoutTax: {
			text: 'column.net.amount',
			dataIndex: 'NetAmount',
			flex: 1,
			renderer: 'displayCurrencyDecimals'
		},
		taxAmount: {
			text: 'column.tax.amount',
			dataIndex: 'TaxAmount',
			flex: 0.75,
			renderer: 'displayCurrencyDecimals'
		},
		priceWithTax: {
			text: 'column.gross.amount',
			dataIndex: 'GrossAmount',
			flex: 0.75,
			renderer: 'displayCurrencyDecimals'
		}
	},
	[INVOICE_SUMMARY_COLUMN]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'TotalLines',
			flex: 1,
			sortable: false,
			hideable: false
		},
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		TotalInvoicedAmount: {
			text: 'column.total.amount',
			dataIndex: 'TotalInvoicedAmount',
			flex: 1,
			order: 2,
			sortable: false,
			hideable: false
		}
	},
	[INVOICE_TAX_SUMMARY_COLUMN]: {
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 1,
			sortable: false,
			hideable: false
		},
		taxCategoryCode: {
			text: 'column.tax.category.code',
			dataIndex: 'TaxCategoryCode',
			flex: 1,
			renderer: 'taxCategoryCode',
			sortable: false,
			hideable: false
		},
		taxAmount: {
			text: 'column.tax.amount',
			dataIndex: 'TaxAmount',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		taxableAmount: {
			text: 'column.taxable.amount',
			dataIndex: 'TaxableAmount',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		}
	}
});
edi.columns.addColumns({
	[INVOICE_SUMMARY_EDITABLE_COLUMN]: {
		parents: INVOICE_SUMMARY_COLUMN,
		config: {
			totalNetAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalTaxAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalGrossAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			}
		}
	}
});

export { INVOICE_LINE_COLUMN, INVOICE_SUMMARY_COLUMN, INVOICE_SUMMARY_EDITABLE_COLUMN, INVOICE_TAX_SUMMARY_COLUMN };
