import { createFormForModule, createModulePanel } from '@Components/panels';
import { createCombo } from '@Components/fields';
import { createCreateSaveButton } from '@Components/buttons';
import { createFieldBlock } from '@UIkit/components/panels';

Ext.namespace('edi.modules');
/**
 * Class current user data management
 * @author Anastasiya Zholudeva
 */
edi.modules['rounding.settings'] = function () {
	let moduleData, createButton, form;
	const userData = edi.core.getUserData(),
		orgData = userData.org ?? {},
		availableTaxRates = edi.methods.taxRates.getRatesByCountry(orgData.country, true);

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const createModuleForm = function () {
		const costDecimals = createCombo({
			fieldLabel: edi.i18n.getMessage('cost.decimals'),
			forceSelection: true,
			value: edi.utils.getAttributeByName(orgData.attributes, 'costDecimals') || '2',
			store: edi.stores.createSimpleInlineStore([2, 3, 4], function (id) {
				return id;
			}),
			name: 'costDecimals'
		});
		const defaultVat = edi.utils.getAttributeByName(orgData.attributes, 'defaultVat');
		const defaultVatField = createCombo({
			fieldLabel: edi.i18n.getMessage('column.org.defaultVat'),
			name: 'defaultVat',
			store: edi.stores.createInlineStore(availableTaxRates),
			value: defaultVat,
			forceSelection: true
		});

		createButton = createCreateSaveButton(
			{
				formBind: true,
				disabled: true,
				handler: function () {
					if (form.isValid()) {
						const values = edi.utils.collectFormValues(form);
						save(values);
					}
				}
			},
			true
		);

		const fieldBlock = createFieldBlock({
			layout: {
				type: 'grid',
				area: [[6, 6]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [costDecimals, defaultVatField]
		});

		form = createFormForModule({
			cls: 'edi-details-panel',
			submitEmptyText: false,
			bodyPadding: 10,
			autoScroll: true,
			items: [fieldBlock],
			buttons: [createButton]
		});

		return form;
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const modulePanel = createModulePanel({
			layout: 'fit',
			region: 'center',
			items: [createModuleForm()]
		});
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};

	const save = function (values) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));

		edi.utils.clearEmptyValues(values);

		const onSuccessSave = function (data) {
			if (data.data) {
				edi.core.updateUserData();
				const costDecimals = edi.utils.getObjectProperty(values, 'costDecimals');
				edi.constants.DEFAULT.SUMM_DISPLAY_PRECISION_NUMBER = costDecimals || 2;
				edi.constants.DEFAULT.PRECISION_NUMBER = costDecimals || 4;
				edi.core.showInfo('setting.save.success');
			}
			moduleData.isChanged = false;
			moduleData.tab.setLoading(false);
		};

		edi.rest.sendRequest(
			edi.rest.services.USER.ORGANIZATION.ATTRIBUTES.PUT,
			'PUT',
			Ext.encode({
				costDecimals: values.costDecimals ?? null,
				defaultVat: values.defaultVat ?? null
			}),
			onSuccessSave
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
