import { createCombo } from '@Components/fields';
import { createDateRangeField } from '@UIkit/components/fields';
import { createPanel } from '@Components/panels';

export const exportGroupFilter = {
	createFormItems: function () {
		var states = [];
		Object.values(edi.constants.EXPORT_GROUP_REQUEST.STATE).forEach(function (value) {
			states.push({
				id: value,
				name: edi.i18n.getMessage('export.group.request.state.' + value)
			});
		});
		const formItemsMap = {
			state: createCombo({
				fieldLabel: edi.i18n.getMessage('document.status'),
				store: edi.stores.createInlineStore(states, 'SIMPLE', undefined, {
					addEmptyRecord: true
				}),
				name: 'state',
				allowBlank: true,
				forceSelection: true,
				anyMatch: true,
				displayField: 'name',
				valueField: 'id',
				emptyText: edi.i18n.getMessage('form.combo.not.selected')
			}),
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.date.create'),
				period: edi.utils.getUserDefaultSearchPeriod('today'),
				fieldsConfig: {
					common: {
						prefix: 'create'
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
						fieldLabel: edi.i18n.getMessage('document.date.create.from')
					},
					to: {
						chipTitle: ' — '
					}
				}
			}),
			[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.modify.date'),
				period: edi.utils.getUserDefaultSearchPeriod('today'),
				fieldsConfig: {
					common: {
						prefix: 'modify'
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.modify.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.modify.date.from')
					},
					to: {
						chipTitle: ' — '
					}
				}
			})
		};
		const items = [
			createPanel({
				layout: {
					type: 'grid',
					area: [[6], [6, 6]]
				},
				items: [
					formItemsMap.state,
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]
				]
			})
		];
		return {
			formItemsMap,
			items
		};
	},
	createArgs: function (values) {
		if (values['create-dateFrom']) {
			values.creationDateFrom = edi.utils.getUTCTimeZoneFree(
				values['create-dateFrom'],
				edi.constants.DATE_FORMAT.SERVER
			);
			delete values['create-dateFrom'];
		}
		if (values['create-dateTo']) {
			values.creationDateTo = edi.utils.getUTCTimeZoneFree(
				values['create-dateTo'],
				edi.constants.DATE_FORMAT.SERVER,
				edi.constants.SEARCH_DAY_IN_MS
			);
			delete values['create-dateTo'];
		}
		if (values['modify-dateFrom']) {
			values.modifyDateFrom = edi.utils.getUTCTimeZoneFree(
				values['modify-dateFrom'],
				edi.constants.DATE_FORMAT.SERVER
			);
			delete values['modify-dateFrom'];
		}
		if (values['modify-dateTo']) {
			values.modifyDateTo = edi.utils.getUTCTimeZoneFree(
				values['modify-dateTo'],
				edi.constants.DATE_FORMAT.SERVER,
				edi.constants.SEARCH_DAY_IN_MS
			);
			delete values['modify-dateTo'];
		}
		edi.utils.clearEmptyValues(values);
		return values;
	}
};
