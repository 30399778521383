import { DocumentCreateFns_upd_5_02n } from './document.create.fns_upd_5_02n';
import { DocLines503 } from './5_03N/createDocLinesGrid';
import { AdditionalInformation503 } from './5_03N/additionalInformation/createAdditionalInformationFieldSet';
// @ts-ignore
import { createFieldSet } from '@UIkit/components/panels';
// @ts-ignore
import { PTYPE_APPEND_ICONBUTTON } from '@UIkit/plugins/AppendSibling/AppendIconButton';
// @ts-ignore
import { createLabel, createSwitch } from '@UIkit/components/fields';

Ext.namespace('edi.modulesCfg');
const moduleName = 'document.create.fns_upd_5_03n';
edi.modulesCfg[moduleName] = {
	title: 'document.add.fns_upd_5_03n',
	modName: moduleName,
	permissions: ['CREATE_EDI_FNS_UPD'],
	permissionsEdit: ['EDIT_EDI_FNS_UPD']
};

export class DocumentCreateFns_upd_5_03n extends DocumentCreateFns_upd_5_02n {
	version: string = '5.03-N';

	modifyConfig() {
		const me = this;

		super.modifyConfig();

		me.ProductGridConstructor = DocLines503;
		me.AdditionalInformationConstructor = AdditionalInformation503;
	}

	createBuyer(props: {
		userOrganization: AnyObject;
		forcedBankData: AnyObject;
		attrAllowDocCreation?: string;
		attrAllowDocCreationError?: string;
	}) {
		return super.createBuyer(
			Object.assign(
				{
					attrAllowDocCreation: 'workingWithUPD_503-N',
					attrAllowDocCreationError: 'document.upd_5_02n.allow.error'
				},
				props
			)
		);
	}

	createMarkingBlock(): ExtComponent {
		const me = this;

		const attributes = me.containerHeader?.attributes ?? {};
		const markBeer = edi.utils.getAttributeByName(attributes, 'markBeer', undefined, true);
		const markTobacco = edi.utils.getAttributeByName(attributes, 'markTobacco', undefined, true);

		const markBeerField = createSwitch({
			name: 'markBeer',
			nameable: true,
			checked: markBeer,
			boxLabel: edi.i18n.getMessage('document.product.bear'),
			inputValue: true,
			uncheckedValue: false
		});

		const markTobaccoField = createSwitch({
			name: 'markTobacco',
			nameable: true,
			checked: markTobacco,
			boxLabel: edi.i18n.getMessage('document.product.tobacco'),
			inputValue: true,
			uncheckedValue: false
		});

		return (me.blocks.markingSettings = createFieldSet({
			title: edi.i18n.getMessage('document.product.marking.product.groups.title'),
			collapsed: true,
			collapsible: true,
			nameHolder: true,
			plugins: [
				{
					ptype: PTYPE_APPEND_ICONBUTTON,
					queryEl: '.ui-fieldset-header-text',
					hintConfig: {
						items: [
							createLabel({
								text: edi.i18n.getMessage('document.product.marking.product.groups.help')
							})
						]
					}
				}
			],
			items: [me.createMaxContainer([markBeerField, markTobaccoField])]
		}));
	}

	createExtraBlocks() {
		const me = this;
		return [me.createMarkingBlock()];
	}

	buildHeaderData({ documentData }: { documentData: AnyObject }): AnyObject {
		const me = this;
		const headerData = super.buildHeaderData({ documentData });
		const markingEntries: [string, ExtComponent][] = Object.entries(
			me.blocks.markingSettings?.getNamedItems() ?? {}
		);
		const markingValues = markingEntries.reduce((values: AnyObject, [name, cmp]) => {
			if (name && cmp && !cmp.isDestroyed) {
				values[name] = cmp.getValue();
			}
			return values;
		}, {});

		return me.isEdit ? { ...headerData, ...markingValues } : { ...headerData, containerAttributes: markingValues };
	}
}

Ext.namespace('edi.modules');
edi.modules[moduleName] = DocumentCreateFns_upd_5_03n;
