import { GridColumnRenderer } from '@App/js/definitions/grids';

export enum TREE_MODE {
	PACKING = 'PACKING',
	PRODUCT = 'PRODUCT'
}

export type PackingId = string;

export interface Product {
	ediInternalId: string;
	productID: string;
	LineNumber: number;
	items: AnyObject;
	EAN: string;
	BuyerItemCode: string;
	SupplierItemCode: string;
	ItemDescription: string;
	OrderedQuantity: string;
	QuantityDespatched: string;
	ProductQuantityDespatched: string;
	UnitPacksize: string;
	UnitVolume: string;
	UnitOfMeasure: string;
	ExpirationDate: string;
	BatchNumber: string;
	ItemStatusCode: string;
	ColourCode: string;
	ItemSize: string;
	SupplierPackCode: string;
	UnitNetPrice: string;
	UnitGrossPrice: string;
	ItemType: string;
	TaxRate: string;
	SuggestedPrice: string;
	MaxRetailPrice: string;
	NetAmount: string;
	TaxAmount: string;
	GrossAmount: string;
	ProductNetAmount: string;
	ProductTaxAmount: string;
	ProductGrossAmount: string;
	Remarks: string;
	'Package-Identification': AnyObject;
	VSDNumber: string;
	SerialNumber: string;
	ProductionDate: string;
	'Line-AdditionalInformation': AnyObject;
	'Line-Order': AnyObject;
	LinePartyName: string;
	CertificateNumber: string;
	BoxesQuantity: string;
	CertificateDateOfIssue: string;
	'Line-Reference': AnyObject;
	ReasonCode: string;
	packingId?: PackingId; //FOR INTERNAL USE
	secondLvlPackingIds?: PackingId[]; //FOR INTERNAL USE
	children?: AnyObject[]; //FOR INTERNAL USE
	childItems?: UnconvertedLine[]; //FOR INTERNAL USE
}

export interface Packing {
	ediInternalId: string;
	Type: string;
	'ID-Begin': string;
	PackagingUnitUniversalCode: string;
	PackagingUnitName: string;
	PackagingUnitBuyerCode: string;
	PackagingUnitSupplierCode: string;
	VSDNumber: string;
	BatchNumber: string;
	PackProductionDate: string;
	PackExpirationDate: string;
	packingLevel: number;
	packingId?: PackingId;
	PackagingUnit: string;
	SizeUnitOfMeasure: string;
	SizeLength: string;
	SizeWidth: string;
	SizeHeight: string;
	WeightUnitOfMeasure: string;
	WeightNetWeight: string;
	WeightGrossWeight: string;
	WeightUnitWeight: string;
	WeightBoxWeight: string;
	WeightPalletWeight: string;
	children?: AnyObject[]; //FOR INTERNAL USE
	childItems?: UnconvertedLine[]; //FOR INTERNAL USE
	thirdLvlPackingId?: PackingId; //FOR INTERNAL USE
	ThirdLevelPackaging?: Packing; //FOR INTERNAL USE
}

export type UnconvertedLine = Product | Packing;

export interface Total {
	TotalPSequence: number;
	TotalLines: number;
	TotalGoodsDespatchedAmount: string;
	TotalNetAmount: number;
	TotalGrossAmount: number;
	TotalTaxAmount: string;
}

export interface TreeRecord<T> extends AnyObject {
	data: {
		data: T;
	};
}

export interface TreeActionHandler<T = Product | Packing> {
	(
		gridView: ExtComponent,
		rowIndex: number,
		colIndex: number,
		item: ExtComponent,
		event: ExtEvent,
		record: TreeRecord<T>
	): void;
}

export interface TreeActionHiddenCheck<T = Product | Packing> {
	(gridView: ExtComponent, rowIndex: number, colIndex: number, item: ExtComponent, record: TreeRecord<T>): boolean;
}

export interface TreeActionGetTip<T = Product | Packing> {
	(
		value: string,
		metadata: AnyObject,
		record: TreeRecord<T>,
		rowIndex: number,
		colIndex: number,
		store: ExtComponent,
		treeView: ExtComponent
	): string;
}

export interface TreeAction<T = Product | Packing> extends AnyObject {
	handler?: TreeActionHandler<T>;
	isHidden?: TreeActionHiddenCheck<T>;
	getTip?: TreeActionGetTip<T>;
}

export interface TreeColumnConfig extends AnyObject {
	renderer?: string | GridColumnRenderer<TreeRecord<Product | Packing>>;
}

export type ProductIdent = string;

export interface TreeMaps {
	productsByPack: {
		[key: ProductIdent]: Product;
	};
	productsById: {
		[key: Product['productID']]: Product;
	};
	secondLvlPacks: {
		[key: PackingId]: Packing;
	};
	thirdLvlPacks: {
		[key: PackingId]: Packing;
	};
}

export interface Tree extends ExtComponent {
	cls: string;
	lockIfNoPartner: boolean;
	isFocusOnInvalidField: boolean;
	disableColoringRows: boolean;
	readOnly: boolean;
	allowBlank: boolean;
	allowExportPackings: boolean;
	title: string;
	gridModel: string;
	gridColumnsConfig: string;
	totalModel: string;
	totalColumnConfig: string;
	totalsGridHeaderLabel: string;
	partnerId: number;
	allowBlankEditableGrids: string[];
	modalFormConfig: {
		[key: string]: any;
		modalFields: AnyObject[];
	};
	maps: TreeMaps;
	viewMode: TREE_MODE;
	customPackingFields?: {
		//key = tabName, value = array of field configs
		[key: string]: AnyObject[];
	}[];
	wrapper: ExtComponent;
	titleContainer: ExtComponent;
	modesTabPanel: ExtComponent;
	productsTotalGrid: ExtComponent;
	productsGrid: Tree;
	addNewItemBtn?: ExtComponent;
	addNewProductBtn?: ExtComponent;
	addNewPackBtn?: ExtComponent;
	moveSelectedBtn?: ExtComponent;
	openedModal: ExtComponent;
	loadProducts: Function;
	loadTotalValues: Function;
	setPartnerId: Function;
	updateToolsButtons: Function;
	updateGridHeader: Function;
	getLinesAmount: Function;
	isValid: Function;
	isValidProduct: Function;
	isValidPack: Function;
	getValues: Function;
	rebuildTree: Function;
	changeValuesBeforeEdit: Function;
	totalsHandler: Function;
	totalsHandlerCallback: Function;
	getTotalValues: Function;
	valuesCalculator?: Function;
	postProcessValues?: Function;
}

export interface TreeConfig {
	readOnly?: Tree['readOnly'];
	productValues?: AnyObject[];
	viewConfig?: AnyObject;
	lockIfNoPartner?: Tree['lockIfNoPartner'];
	allowExportPackings?: Tree['allowExportPackings'];
	packingList?: AnyObject[];
	modalFormConfig?: Tree['modalFormConfig'];
	pagingBarConfig?: {
		items?: (ExtComponent | AnyObject)[];
	};
	valuesCalculator?: Tree['valuesCalculator'];
	postProcessValues?: Tree['postProcessValues'];
	isValidProduct?: Tree['isValidProduct'];
	isValidPack?: Tree['isValidPack'];
	callback?: Tree['callback'];
	totalsHandlerCallback?: Tree['totalsHandlerCallback'];
}

/**
 * Type guard for TreeRecord<Product> interface
 */
export const isProductRecord = function (arg: TreeRecord<any>): arg is TreeRecord<Product> {
	return !arg.data.data.Type;
};

/**
 * Type guard for Product interface
 */
export const isProduct = function (arg: AnyObject): arg is Product {
	return !arg.Type;
};

/**
 * Type guard for TreeRecord<Packing> interface
 */
export const isPackingRecord = function (arg: TreeRecord<any>): arg is TreeRecord<Packing> {
	return !!arg.data.data.Type;
};

/**
 * Type guard for Packing interface
 */
export const isPacking = function (arg: AnyObject): arg is Packing {
	return !!arg.Type;
};
