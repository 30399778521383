import {
	createCombo,
	createHiddenField,
	createLabel,
	createLabelBlockForDetails,
	createLabelForDetails,
	createTextField
	// @ts-ignore
} from '@UIkit/components/fields';
// @ts-ignore
import { createContainer, createFieldBlock, createFieldSet, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
// @ts-ignore
import { BLOCKS_HOLDER_CLS, createBlocksHolder } from '@UIkit/components/blocks/BlocksHolder';
// @ts-ignore
import { createEditableBlock } from '@UIkit/components/blocks/EditableBlock';
// @ts-ignore
import { MODAL_SIZE } from '@UIkit/components/modal';
import { RekvIdRekSost } from './definitions/rekvIdRekSost';

export interface SideBlockProps {
	data: RekvIdRekSost[];
	[key: string]: any;
}

export enum ID_STAT {
	FOREIGN_ORG = 'ИО',
	FOREIGN_FL = 'ИГ'
}

export class SideBlock {
	props: SideBlockProps = {
		data: []
	};
	fields: {
		[fieldName: string]: ExtComponent;
	};

	constructor(props: SideBlockProps, overrides?: Partial<SideBlock>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: SideBlockProps, overrides?: Partial<SideBlock>) {
		const me = this;
		me.fields = {};
		Ext.merge(me, overrides);
		me.props = props;
	}

	/** Юридическое лицо */
	createCompanyInnField(values: AnyObject): ExtComponent {
		const me = this;

		const inn = (me.fields.companyInn = createTextField({
			valueSrc: values,
			name: 'innyul',
			allowBlank: false,
			regex: edi.constants.VALIDATORS.ORG_INN
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: inn
		});
	}
	createCompanyView(values: AnyObject): ExtComponent {
		const me = this;
		return createContainer({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: [
				createLabel({
					typography: 'heading_01',
					text: edi.i18n.getMessage('company.type.company')
				}),
				me.createCompanyInnField(values)
			]
		}) as ExtComponent;
	}
	createCompanyDetailsView(values: AnyObject): ExtComponent {
		return createLabelForDetails({
			title: edi.i18n.getMessage('company.inn.short'),
			text: values.innyul
		}) as ExtComponent;
	}

	/** Физическое лицо или ИП */
	createPersonInnField(values: AnyObject): ExtComponent {
		const me = this;
		const inn = (me.fields.personInn = createTextField({
			valueSrc: values,
			name: 'innfl',
			allowBlank: false,
			regex: edi.constants.VALIDATORS.INDI_INN
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('company.inn.short'),
			cls: FIELD_BLOCK_CLS.small,
			items: inn
		});
	}
	createPersonView(values: AnyObject): ExtComponent {
		const me = this;

		return createContainer({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: [
				createLabel({
					typography: 'heading_01',
					text: edi.i18n.getMessage('company.type.person')
				}),
				me.createPersonInnField(values)
			]
		}) as ExtComponent;
	}
	createPersonDetailsView(values: AnyObject): ExtComponent {
		return createLabelForDetails({
			title: edi.i18n.getMessage('company.inn.short'),
			text: values.innfl
		}) as ExtComponent;
	}

	/** Иностранная организация или иностранный гражданин */
	createForeignNameField(values: AnyObject): ExtComponent {
		const me = this;
		const type = (me.fields.foreignName = createTextField({
			valueSrc: values,
			allowBlank: false,
			maxLength: 1000,
			name: 'dannIno.naim'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('document.foreign.full.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: type
		});
	}
	createForeignIdField(values: AnyObject): ExtComponent {
		const me = this;
		const id = (me.fields.foreignIdentif = createTextField({
			valueSrc: values,
			maxLength: 255,
			name: 'dannIno.identif'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('column.identifier'),
			cls: FIELD_BLOCK_CLS.small,
			items: id
		});
	}
	createForeignIdStatField(values: AnyObject): ExtComponent {
		const me = this;
		const idStat = (me.fields.foreignIdStat = createCombo({
			forceSelection: true,
			name: 'dannIno.idStat',
			allowBlank: false,
			value: edi.utils.getObjectProperty(values, 'dannIno.idStat'),
			store: edi.stores.createInlineStore([
				{
					id: ID_STAT.FOREIGN_ORG,
					name: `${ID_STAT.FOREIGN_ORG} ${edi.i18n.getMessage('company.type.legalForeigner')}`
				},
				{
					id: ID_STAT.FOREIGN_FL,
					name: `${ID_STAT.FOREIGN_FL} ${edi.i18n.getMessage('company.type.foreignerPerson')}`
				}
			])
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('document.status_id'),
			cls: FIELD_BLOCK_CLS.small,
			items: idStat
		});
	}
	createForeignCountryNameField(values: AnyObject): ExtComponent {
		const me = this;
		const country = (me.fields.foreignCountry = createCombo({
			store: edi.stores.initValidCountryFullStore(),
			name: 'dannIno.naimStran',
			allowBlank: false,
			anyMatch: true,
			forceSelection: true,
			valueField: 'name',
			value: edi.utils.getObjectProperty(values, 'dannIno.naimStran'),
			displayField: 'display_name',
			listeners: {
				select: (
					combo: ExtComponent,
					record: ExtRecord<{
						display_name: string;
						id: number;
						iso_2: string;
						iso_3: string;
						iso_number: number;
						iso_number_3: string;
						name: number;
					}>
				) => {
					me.fields.foreignCountryCode?.setValue(record.get('iso_number_3'));
				}
			}
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('company.country'),
			cls: FIELD_BLOCK_CLS.small,
			items: country
		});
	}
	createForeignCountryCodeField(values: AnyObject): ExtComponent {
		const me = this;
		return (me.fields.foreignCountryCode = createHiddenField({
			valueSrc: values,
			name: 'dannIno.kodStr'
		}));
	}
	createForeignOtherInfoField(values: AnyObject): ExtComponent {
		const me = this;
		const otherInfo = (me.fields.foreignOtherInfo = createTextField({
			valueSrc: values,
			maxLength: 255,
			reserveSpace: true,
			name: 'dannIno.inieSved'
		}));

		return createFieldBlock({
			title: edi.i18n.getMessage('document.foreign.identify.other.information'),
			cls: FIELD_BLOCK_CLS.small,
			items: otherInfo
		});
	}
	createForeignView(values: AnyObject): ExtComponent {
		const me = this;

		return createContainer({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: [
				createLabel({
					typography: 'heading_01',
					text: edi.i18n.getMessage('company.type.foreign.legal.person')
				}),
				me.createForeignNameField(values),
				me.createForeignIdField(values),
				createFieldSet({
					layout: {
						type: 'grid',
						gap: 24
					},
					items: [
						me.createForeignIdStatField(values),
						me.createForeignCountryNameField(values),
						me.createForeignCountryCodeField(values)
					]
				}),
				me.createForeignOtherInfoField(values)
			]
		}) as ExtComponent;
	}
	createForeignDetailsView(values: AnyObject): ExtComponent {
		const { idStat, naim, identif, naimStran, inieSved } = values.dannIno ?? {};
		const idStatText =
			idStat === ID_STAT.FOREIGN_ORG
				? `${ID_STAT.FOREIGN_ORG} ${edi.i18n.getMessage('company.type.legalForeigner')}`
				: idStat === ID_STAT.FOREIGN_FL
				? `${ID_STAT.FOREIGN_FL} ${edi.i18n.getMessage('company.type.foreignerPerson')}`
				: null;

		return createLabelBlockForDetails({
			contents: [
				{
					title: edi.i18n.getMessage('document.foreign.full.name'),
					text: naim,
					isVertical: true
				},
				{
					title: edi.i18n.getMessage('column.identifier'),
					text: identif,
					isVertical: true,
					isNewLine: true
				},
				{
					title: edi.i18n.getMessage('document.status_id'),
					text: idStatText,
					isNewLine: true
				},
				{
					title: edi.i18n.getMessage('company.country'),
					text: naimStran,
					isNewLine: true
				},
				{
					title: edi.i18n.getMessage('document.foreign.identify.other.information'),
					text: inieSved,
					isVertical: true,
					isNewLine: true
				}
			]
		}) as ExtComponent;
	}

	/** Орган исполнительной власти */
	createExecutiveAuthorityNameField(values: AnyObject): ExtComponent {
		const me = this;

		const name = (me.fields.executiveAuthorityName = createTextField({
			name: 'naimOIV',
			valueSrc: values,
			allowBlank: false,
			maxLength: 255
		}));

		return createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('document.executive_authority'),
			items: name
		});
	}
	createExecutiveAuthorityView(values: AnyObject): ExtComponent {
		const me = this;
		return createContainer({
			layout: {
				type: 'grid',
				gap: [24, 16]
			},
			items: [
				createLabel({
					typography: 'heading_01',
					text: edi.i18n.getMessage('document.type.parties.executive_authority')
				}),
				me.createExecutiveAuthorityNameField(values)
			]
		}) as ExtComponent;
	}
	createExecutiveAuthorityDetailsView(values: AnyObject): ExtComponent {
		return createLabelForDetails({
			title: edi.i18n.getMessage('document.executive_authority'),
			text: values.naimOIV
		}) as ExtComponent;
	}

	blockConstructor({
		blockTitle,
		getItems,
		createView
	}: {
		blockTitle: string;
		createView: (values: AnyObject) => ExtComponent;
		getItems: (editableBlock: ExtComponent, values: AnyObject) => ExtComponent | ExtComponent[];
		readOnly?: boolean;
	}) {
		return function (_entityName: string, values: AnyObject): ExtComponent {
			return createEditableBlock({
				title: blockTitle,
				modal: true,
				values,
				createView,
				getItems,
				modalConf: {
					title: values
						? edi.i18n.getMessage('document.parties.edit.title')
						: edi.i18n.getMessage('document.parties.add.title'),
					width: MODAL_SIZE.widthSmall
				}
			});
		};
	}

	createBlockHolder(): ExtComponent {
		const me = this;
		const values = me.props.data.reduce(
			(
				result: {
					company: AnyObject[];
					individual: AnyObject[];
					executiveAuthority: AnyObject[];
					foreign: AnyObject[];
				},
				side: AnyObject
			) => {
				if (side.innyul) result.company.push(side);
				if (side.innfl) result.individual.push(side);
				if (side.naimOIV) result.executiveAuthority.push(side);
				if (side.dannIno?.idStat) result.foreign.push(side);
				return result;
			},
			{
				company: [],
				individual: [],
				executiveAuthority: [],
				foreign: []
			}
		);
		const blocksHolder = createBlocksHolder({
			cls: BLOCKS_HOLDER_CLS.withoutBorder,
			values: [
				{
					entityModel: 'company',
					values: values.company
				},
				{
					entityModel: 'individual',
					values: values.individual
				},
				{
					entityModel: 'executiveAuthority',
					values: values.executiveAuthority
				},
				{
					entityModel: 'foreign',
					values: values.foreign
				}
			],
			entityModels: [
				{
					name: 'company',
					blockConstructor: me.blockConstructor({
						blockTitle: edi.i18n.getMessage('company.type.company'),
						getItems: (editableBlock: ExtComponent, values) => me.createCompanyView(values),
						createView: (values) => me.createCompanyDetailsView(values)
					})
				},
				{
					name: 'individual',
					blockConstructor: me.blockConstructor({
						blockTitle: edi.i18n.getMessage('company.type.person'),
						getItems: (editableBlock: ExtComponent, values) => me.createPersonView(values),
						createView: (values) => me.createPersonDetailsView(values)
					})
				},
				{
					name: 'executiveAuthority',
					blockConstructor: me.blockConstructor({
						blockTitle: edi.i18n.getMessage('document.type.parties.executive_authority'),
						getItems: (editableBlock: ExtComponent, values) => me.createExecutiveAuthorityView(values),
						createView: (values) => me.createExecutiveAuthorityDetailsView(values)
					})
				},
				{
					name: 'foreign',
					blockConstructor: me.blockConstructor({
						blockTitle: edi.i18n.getMessage('company.type.foreign.legal.person'),
						getItems: (editableBlock: ExtComponent, values) => me.createForeignView(values),
						createView: (values) => me.createForeignDetailsView(values)
					})
				}
			],
			addButtonConf: {
				menu: [
					{
						text: edi.i18n.getMessage(`company.type.company`),
						handler: () => blocksHolder.createNewBlockInWindow('company')
					},
					{
						text: edi.i18n.getMessage(`company.type.person.or.ip`),
						handler: () => blocksHolder.createNewBlockInWindow('individual')
					},
					{
						text: edi.i18n.getMessage('company.type.foreign.legal.person'),
						handler: () => blocksHolder.createNewBlockInWindow('foreign')
					},
					{
						text: edi.i18n.getMessage('document.type.parties.executive_authority'),
						handler: () => blocksHolder.createNewBlockInWindow('executiveAuthority')
					}
				]
			}
		}) as ExtComponent;

		return blocksHolder;
	}
}

export const createSideBlockHolder = function (props: SideBlockProps, overrides?: Partial<SideBlock>): ExtComponent {
	const sideBlock = new SideBlock(props, overrides);
	return sideBlock.createBlockHolder() as ExtComponent;
};
