import { RekvDokTip } from './rekvDokTip';
import { Podpisant } from './podpisant';
import { SvProdPer } from './svProdPer';
import { TablSchFakt } from './producLineDef';
import { SvSchFakt } from './svSchFakt';

export interface InfoField {
	allowGridView: boolean;
	creationDate: number;
	gridViewName: string | null;
	id: number;
	infoId: string;
	modifyDate: number;
	required: boolean;
	tooltip: string;
	value: string;
	valueSrc: string;
}

export interface OrganizationInfoFields {
	modifyDate: number;
	docType: string;
	docPart: 'InvoiceTextInfos' | 'ProductTextInfos' | string;
	id: number;
	creationDate: number;
	fieldInfo: InfoField[];
	fields: AnyObject[]; // Тип данных в `fields` не ясен
}

export interface OrgProductFields {
	identifier: string;
	value: string;
	allowEmptyValue: boolean;
	infoFieldTooltip: string | null;
	valueSrc: string | null;
}

export interface TekstInf {
	identif: string;
	znachen: string;
}

export interface Fio {
	familiya: string;
	imya: string;
	otchestvo?: string;
}

export interface Dokument {
	svSchFakt: SvSchFakt;
	tablSchFakt?: TablSchFakt;
	svProdPer?: SvProdPer;
	podpisant: Podpisant[];
	osnDoverOrgSost?: RekvDokTip;
	knd: string;
	uid?: string;
	funktsiya: string;
	poFaktHZH?: string;
	naimDokOpr?: string;
	dataInfPr: string;
	vremInfPr: string;
	naimEkonSubSost?: string;
	soglStrDopInf?: string;
}

export interface UPD502N {
	creationDate: string;
	modifyDate: string | null;
	header: number | null;
	dokument: Dokument;
	idFayl: string;
	versForm: string;
	versProg: string;
	fileId: string;
	id: number | null;
	containerAttributes?: Record<string, boolean>;
	skipGetObjectTotalProperty?: boolean; //для totalHandler
}

export enum HANDING_GOODS_TYPE {
	sellerOrganizationWorker = '1',
	shippedGoodsWorker = '2',
	individualPersonShipsGoogs = '3'
}

export enum BUYER_HANDING_GOODS_TYPE {
	buyerOrganizationWorker = '1',
	acceptanceGoodsWorker = '2',
	individualPersonAcceptanceGoogs = '3'
}
