import { CertificateData } from '@Fns/modules/documents/definitions';
import { Org } from '@App/js/definitions/org';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import {
	createButtonContainer,
	createContainer,
	createFieldBlock,
	createFieldSet,
	createForm,
	createMaxWidthContainer
	// @ts-ignore
} from '@UIkit/components/panels';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

import {
	createCombo,
	createDateField,
	createLabel,
	createNumberField,
	createTextField
	// @ts-ignore
} from '@UIkit/components/fields';
import {
	getAuthorityAreasByBpName,
	getPurposesByTypeAndFunction,
	getPurposesTypes,
	getPurposeTypeFromValue,
	getPurposeTypeValue,
	isDOP_BusinessProcess,
	isSCHF_BusinessProcess,
	isSCHFDOP_BussinessProcess,
	PURPOSES_TYPES
} from './methods';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { createRowsBlock } from '@Components/createRows/create.rows';
// @ts-ignore
import { BLOCKS_HOLDER_CLS, createBlocksHolder } from '@UIkit/components/blocks/BlocksHolder';
import { createNamedDocumentBlock } from './5_02N/documentBlock/createDocumentBlock';
import { createTransportAndCargo } from './5_02N/transportAndCargo/createTransportAndCargoFieldSet';
import { UpdOrgSelectors } from './5_02N/selectors';
import {
	AdditionalInformation,
	AdditionalInformationProps
} from './5_02N/additionalInformation/createAdditionalInformationFieldSet';
import { DocLines } from './5_02N/createDocLinesGrid';
import {
	Dokument,
	HANDING_GOODS_TYPE,
	InfoField,
	OrganizationInfoFields,
	OrgProductFields,
	TekstInf,
	UPD502N
} from './5_02N/definitions/commonsDef';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createSignerAndPoa } from './5_02N/signerAndPoa/createSignerAndPoa';
import * as powerLimits from './5_02N/json/power.limits.json';
import { utils } from '@App/js/utilities';
import { MainDataBlock } from './5_02N/mainDataBlock/createMainDataBlock';
import { SendRequestResponse } from '@App/js/definitions/request';
import { SvPRD } from './5_02N/definitions/svSchFakt';
import { Podpisant } from './5_02N/definitions/podpisant';
import { SvedTov } from './5_02N/definitions/producLineDef';
import { getBpNameByDocFlowType } from './5_02N/methods';

const TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER = 'цена_с_ндс';

export class DocumentCreateFns_upd_5_02n {
	[key: string]: any;

	moduleData: ModuleData;
	documentContent?: UPD502N;
	containerHeader?: DocumentHeader;
	documentHeader?: DocumentHeader;
	parentData: ModuleData['initData'];
	isNumberAutoGenerated: boolean = false;
	parentId: number;
	signerFillFromCert: CertificateData;
	availableAreasData: { id: number; name: string }[] = [];
	isEdit: boolean = false;
	isCopy: boolean = false;
	initialData: AnyObject;
	isCreate: boolean = false;
	isCorrect: boolean = false;
	buyerOrg: Org | null;
	buyerOrgValues: AnyObject;
	seller: ExtComponent;
	buyer: ExtComponent;
	shipFromOrg: Org;
	sellerOrg: Org;
	deliveryOrg: Org;
	cargoReceiver: ExtComponent;
	shipFrom: ExtComponent;
	productsGrid: ExtComponent;
	mainForm: ExtComponent;
	invoiceDate: ExtComponent;
	contract: ExtComponent;
	signerAuthorityAreaValue: string;
	isCreatedFromDoc: boolean;
	isBuyerDefined: boolean;
	bpName: string;
	signerAndPoaContainer: ExtComponent;
	//TODO вероятно лишний как и getAuthorityAreasByBP
	authorityAreaInput: ExtComponent;
	isSCHFSelected: boolean = false;
	isSCHFDOPSelected: boolean = false;
	isDOPSelected: boolean = false;
	invoiceTextInfosColumns: ExtComponent;
	baseShipmentOfGoods: ExtComponent;
	orgInfoFields: AnyObject[] = [];
	orgProductFields: OrgProductFields[] = [];
	shipFromInfoField: ExtComponent | null;
	deliveryInfoField: ExtComponent | null;
	factors: Org[];
	signers: Podpisant[] = [];
	shipDocConfirmationNumber: ExtComponent;
	mainDataBlockInstance: MainDataBlock;
	selectorConfigsBuilder: UpdOrgSelectors;
	taxCalculationMethod: string = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
	version: string = '5.02-N';
	forceChangeFields: {
		[fieldName: string]: boolean;
	} = {
		addr_rus_zip: true,
		addr_rus_region: true,
		addr_rus_county: true,
		addr_rus_city: true,
		addr_rus_community: true,
		addr_rus_street: true,
		addr_rus_home: true,
		addr_rus_block: true,
		addr_rus_flat: true
	};
	transportAndCargoFieldSet: ExtComponent;
	additionalInformationFieldSet: ExtComponent;
	afterrenderQueue: Function[];
	blocks: {
		[blockName: string]: ExtComponent;
	};
	ProductGridConstructor: typeof DocLines;
	AdditionalInformationConstructor: typeof AdditionalInformation;

	constructor() {
		const me = this;
		me.modifyConfig();
	}

	modifyConfig() {
		const me = this;

		me.blocks = {};
		me.afterrenderQueue = [];

		me.userData = edi.core.getUserData();
		me.isIP = me.userData.org.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL;
		me.isPerson = me.userData.org.orgType === edi.constants.ORGANIZATION_TYPES.PERSON;

		me.forceChangeFieldsForShips = Object.assign(
			{
				okpo: true,
				company_inn: true,
				company_kpp: true,
				company_name: true,
				individual_firstname: true,
				individual_lastname: true,
				individual_patronymicname: true,
				foreign_name: true,
				contact_phone: true,
				contact_email: true,
				bank_id: true,
				bank_name: true,
				bank_acc: true,
				bank_corr_acc: true,
				addr_for_country: true,
				addr_for_country_name: true,
				addr_for_text: true,
				structural_subdivision: true
			},
			me.forceChangeFields
		);

		me.ProductGridConstructor = DocLines;
		me.AdditionalInformationConstructor = AdditionalInformation;
	}

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data: ModuleData, initCallBack: Function) {
		const me = this;

		me.moduleData = Ext.clone(data);
		me.containerHeader = me.moduleData.initData.data as DocumentHeader;
		me.parentId = me.moduleData.initData.meta ? me.moduleData.initData.meta.id : null;

		if (me.moduleData.initData.hasOwnProperty('cupd') && me.moduleData.initData.cupd) {
			me.documentContent = me.moduleData.initData.cupd;
			me.isCorrect = true;
			if (me.moduleData.initData.meta.hasOwnProperty('agreement') && me.moduleData.initData.meta.agreement) {
				me.contract = me.moduleData.initData.meta.agreement;
			}
			me.buyerOrg = me.moduleData.initData.meta.toOrg;
			me.bpName = edi.utils.getAttributeByName(me.moduleData.initData.meta.attributes, 'bpName');
			me.getAuthorityAreasByBP();
		} else if (
			me.moduleData.initData.data &&
			me.moduleData.initData.data.data &&
			me.moduleData.initData.data.bpName
		) {
			me.isCreatedFromDoc = true;
			me.documentContent = me.moduleData.initData.data.data as UPD502N;
			me.documentContent.skipGetObjectTotalProperty = true;
			me.bpName = me.moduleData.initData.data.bpName;
			me.getAuthorityAreasByBP();
			me.isSCHFSelected = isSCHF_BusinessProcess(me.bpName);
			me.buyerOrg = me.moduleData.initData.data.buyerOrg;
			me.parentId = me.moduleData.initData.data.parentId;
			me.isEdit = true;
			me.isCreate = true;
		}

		if (Object.values(edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION).includes(me.bpName)) {
			me.bpName = getBpNameByDocFlowType(me.bpName);
		}

		if (me.buyerOrg?.id) {
			if (!me.buyerOrg.hasOwnProperty('attributes')) {
				me.buyerOrg = edi.utils.getOrg({
					orgId: me.buyerOrg.id
				});
			}
			me.buyerOrgValues = edi.converters.convertOrgToPartie(me.buyerOrg);
			me.taxCalculationMethod = edi.utils.getAttributeByName(me.buyerOrg?.attributes, 'taxCalculationMethod');
			if (!me.taxCalculationMethod || me.taxCalculationMethod === 'null') {
				me.taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
			}
		}

		me.selectorConfigsBuilder = new UpdOrgSelectors();
		me.getFactors(function () {
			me.renderData(initCallBack);
		});

		return me.onDestroy.bind(me);
	}

	/**
	 * Reads factors data from backend
	 * @param    {Function}    callback     callback that will be called on success
	 */
	getFactors(callback: Function) {
		const me = this;
		const success = function (data: SendRequestResponse<undefined, Org>) {
			me.factors = [];
			if (data && data.items && data.items.length) {
				for (let i = 0; i < data.items.length; i++) {
					me.factors.push(edi.models.createInstance('ORGANIZATION', data.items[i]).getData());
				}
			}
			'function' == typeof callback ? callback() : null;
		};
		const failure = function (data: SendRequestResponse) {
			edi.core.handleException('Factors did not loaded properly');
			edi.core.showError(
				edi.utils.formatComplexServerError(data, 'offset.error.getting.factors.data'),
				function () {
					edi.modulesHandler.removeModule(me.moduleData);
				}
			);
		};
		edi.rest.sendRequest(edi.rest.services.FACTORS.GET, 'GET', null, success, failure);
	}

	onRender() {}

	//Checks company controls, and marks them, if not valid
	validateCompanySelectors() {
		const me = this;

		const isBuyerValid = me.buyer.isValid(),
			isSellerValid = me.seller.isValid(),
			isCargoReceiverValid = me.cargoReceiver.isValid(),
			isShipFromValid = me.shipFrom.isValid();

		return isBuyerValid && isSellerValid && isCargoReceiverValid && isShipFromValid;
	}

	orgConverter(org: Org) {
		let values = edi.converters.convertOrgToPartie(org);
		//addr_category переключает шаблон в новый режим
		//но данные сформированны для старого шаблона.
		//вероятно лучше исправить convertCommonAddress в шаблоне company.selector
		delete values.addr_category;
		return values;
	}

	//Checks document validity
	checkValid() {
		const me = this;

		if (!me.mainForm) {
			return false;
		}
		const productsLength = me.productsGrid.productsGrid.getStore().count();
		me.mainForm.isValid();
		const validForm = !me.mainForm.hasInvalidField();
		const validCompanies = me.validateCompanySelectors();
		const validGrid = me.productsGrid?.isValid();
		return productsLength && validForm && validCompanies && validGrid;
	}

	//Get authority areas related to selected BP name
	getAuthorityAreasByBP() {
		const me = this;

		me.availableAreasData = Ext.clone(
			edi.stores.getStoreItemsByIds(edi.stores.data.authorityAreas, getAuthorityAreasByBpName(me.bpName))
		);
	}

	checkMandatoryFields() {
		const me = this;

		me.transportAndCargoFieldSet?.validateFields();
		me.signerAndPoaContainer.checkSignerPositionMandatory();
		me.shipDocConfirmationValidation();

		me.mainForm.isValid();
	}

	updateTextInfosStores() {
		const me = this;

		let idents =
			me.invoiceTextInfosColumns.query('field[name="dokument.svSchFakt.infPolFHZH1.tekstInf.identif"]') ?? [];
		idents.forEach((ident: ExtComponent) => {
			let identValue = ident.getValue();
			if (identValue === PURPOSES_TYPES.SV_VIBITIYA || identValue === PURPOSES_TYPES.VID_OBOBOROTA) {
				let valueInput = ident.getInfoValueField();
				valueInput.getStore().removeAll();
				valueInput.getStore().add(getPurposesByTypeAndFunction(identValue, me.bpName));
				valueInput.setValue(null);
			}
		});
	}

	createOS(selectorConfig: AnyObject): ExtComponent {
		const me = this;
		Ext.applyIf(selectorConfig, {
			cls: '',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			selectedRelationByMap: true,
			valuesByMap: true,
			notReturnedVoid: true,
			useHiddenFields: true,
			disableAutoValidation: true,
			hideInTemplate: ['company_iln'],
			disableCommonAddressRenderer: true,
			disableCommonAddressTitleRenderer: true,
			forceChangeFields: {
				bank_id: true,
				bank_corr_acc: true,
				bank_name: true,
				bank_acc: true
			},
			customPreConvertFormValues: (values: AnyObject): AnyObject => {
				const deleteIParticipantValues = function () {
					const physicalKeys = [
						'physical_lastname',
						'physical_firstname',
						'physical_patronymicname',
						'physical_inn',
						'physical_requisites',
						'physical_additInfo',
						'idStatL'
					];
					physicalKeys.forEach((fieldName) => {
						if (values[fieldName]) delete values[fieldName];
					});
				};

				if (values.type !== 'individualParticipant') {
					deleteIParticipantValues();
				}

				return values;
			}
		});
		return createOrgSelector(selectorConfig) as ExtComponent;
	}

	findAndFocusInvalidField(): ExtComponent | undefined {
		const me = this;
		return utils.findAndFocusInvalidField({
			fields: [
				...me.mainForm.getForm().getFields().items,
				{
					field: me.buyer,
					isValid: () => me.buyer.isValid()
				},
				{
					field: me.seller,
					isValid: () => me.seller.isValid()
				},
				{
					field: me.cargoReceiver,
					isValid: () => me.cargoReceiver.isValid()
				},
				{
					field: me.shipFrom,
					isValid: () => me.shipFrom.isValid()
				},
				{
					field: me.blocks.shipDocConfirmation.blockHolder,
					isValid: () => me.blocks.shipDocConfirmation.blockHolder.isValid()
				},
				{
					field: me.productsGrid,
					isValid: () => me.productsGrid.isValid()
				}
			]
		});
	}

	getFooterButtons() {
		const me = this;

		const createBtn = createButton({
			cls: BUTTON_CLS.primary,
			text: edi.i18n.getMessage(me.isEdit ? 'form.btn.save' : 'form.btn.create'),
			handler: function () {
				const invalidField = me.findAndFocusInvalidField();
				if (invalidField) return;

				me.save();
			}
		});

		return [createBtn];
	}

	setNameForCompanySelector(cmp: ExtComponent, title: string) {
		if (cmp?.modalConf) {
			cmp.modalConf.title = edi.i18n.getMessage(title);
		}
	}

	getValidOrgFieldValue(params: { organization?: AnyObject; useOrganizationMap?: boolean } = {}, mapKey: string) {
		const organization = params.organization;
		const useOrganizationMap = params.useOrganizationMap;
		const organizationFieldsMap: { [fieldName: string]: string } = {
			company_name: 'idSv.svYULUch.naimOrg',
			company_kpp: 'idSv.svYULUch.kpp',
			company_inn: 'idSv.svYULUch.innyul',

			foreign_name: 'idSv.svInNeUch.naim',
			foreign_identifier: 'idSv.svInNeUch.identif',

			individual_lastname: 'idSv.svIP.fio.familiya',
			individual_firstname: 'idSv.svIP.fio.imya',
			individual_inn: 'idSv.svIP.ogrnip',
			okpo: 'idSv.svYULUch.okpo',

			physical_lastname: 'idSv.svFLUch.fio.familiya',
			physical_firstname: 'idSv.svFLUch.fio.imya',
			physical_inn: 'idSv.svFLUch.innfl',

			addr_rus_region: 'adres.adrRF.kodRegion',
			addr_for_country: 'adres.adrInf.kodStr',
			addr_for_text: 'adres.adrInf.adrTekst',
			addr_code_gar: 'adres.adrGAR.idNom',

			bank_acc: 'bankRekv.nomerScheta'
		};
		const fieldName = useOrganizationMap ? organizationFieldsMap[mapKey] : mapKey;
		return edi.utils.getObjectProperty(organization, fieldName);
	}

	isValidOrganization(
		params: { organization?: AnyObject; useOrganizationMap?: boolean; organizationType?: string } = {}
	) {
		const me = this;

		const organizationType = params.organizationType;
		let valid = me.getValidOrgFieldValue(params, 'company_name');
		valid =
			valid ||
			(me.getValidOrgFieldValue(params, 'individual_lastname') &&
				me.getValidOrgFieldValue(params, 'individual_firstname')) ||
			(me.getValidOrgFieldValue(params, 'physical_lastname') &&
				me.getValidOrgFieldValue(params, 'physical_firstname')) ||
			me.getValidOrgFieldValue(params, 'foreign_name');

		if ('seller' === organizationType || 'buyer' === organizationType) {
			valid =
				valid &&
				(me.getValidOrgFieldValue(params, 'company_inn') || me.getValidOrgFieldValue(params, 'individual_inn'));
			valid =
				valid &&
				(!me.getValidOrgFieldValue(params, 'company_inn') || me.getValidOrgFieldValue(params, 'company_kpp'));
			valid ||= me.getValidOrgFieldValue(params, 'foreign_identifier');
		}
		if (organizationType && ['cargoReceiver', 'cargoSender'].includes(organizationType)) {
			valid =
				valid &&
				(!me.getValidOrgFieldValue(params, 'bank_acc') ||
					me.getValidOrgFieldValue(params, 'bank_acc').toString().length === 20);
		}
		valid =
			valid &&
			(me.getValidOrgFieldValue(params, 'addr_rus_region') ||
				(me.getValidOrgFieldValue(params, 'addr_for_country') &&
					me.getValidOrgFieldValue(params, 'addr_for_text')) ||
				me.getValidOrgFieldValue(params, 'addr_code_gar'));
		return !!valid;
	}

	getModuleTitle() {
		const me = this;

		let titleText = edi.i18n.getMessage(me.isCorrect ? 'documents.fns_upd_5_02n.korrupd.title' : 'upd.title');
		if (me.isEdit) {
			titleText = edi.i18n.getMessage(
				me.isCorrect ? 'documents.fns_upd.invoice.corrections' : 'document.edit.fns_upd'
			);
		}
		return createLabel({
			typography: 'heading_02',
			text: titleText
		});
	}

	onUPDFunctionChange(value: string) {
		const me = this;
		Ext.suspendLayouts();
		if (value) {
			me.bpName = value;
			me.isSCHFSelected = isSCHF_BusinessProcess(me.bpName);
			me.isSCHFDOPSelected = isSCHFDOP_BussinessProcess(me.bpName);
			me.isDOPSelected = isDOP_BusinessProcess(me.bpName);
			me.getAuthorityAreasByBP();
			me.checkMandatoryFields();
			me.updateTextInfosStores();
			me.setShipDocConfirmationAutoValue();

			me.additionalInformationFieldSet.onUPDStateChanged();

			if (me.authorityAreaInput) {
				let store = me.authorityAreaInput.getStore();
				store.loadData(Ext.clone(me.availableAreasData));
				me.authorityAreaInput.setValue(me.signerAuthorityAreaValue);
			}
		}
		Ext.resumeLayouts(true);
	}

	createMainDataBlock() {
		const me = this;
		me.mainDataBlockInstance = new MainDataBlock({
			documentContent: me.documentContent ?? {},
			getCurrentUPDState: () => ({
				isEdit: me.isEdit,
				isCorrect: me.isCorrect,
				isCopy: me.isCopy,
				bpName: me.bpName,
				isNumberAutoGenerated: me.isNumberAutoGenerated
			}),
			onUPDFunctionChange: (updFunction) => me.onUPDFunctionChange(updFunction),
			onUPDNumberChange: (number) => {
				me.setShipDocConfirmationAutoValue();
			},
			onUPDDateChange: (date) => {
				me.setShipDocConfirmationAutoValue();
			}
		});

		if (me.isCorrect) return me.mainDataBlockInstance.createCorrectionView();
		return me.mainDataBlockInstance.createView();
	}

	//Shows additional text info fields, specified for selected buyer
	updateInfoFields(organizationInfoFields?: OrganizationInfoFields[]) {
		const me = this;

		organizationInfoFields = organizationInfoFields || [];
		if (!!me.productsGrid?.store) {
			me.productsGrid.reconfigColumns(edi.methods.createInfoColumns(organizationInfoFields));
		}

		let invoiceInfoObj =
				organizationInfoFields.find(function (item) {
					return item.docPart === 'InvoiceTextInfos';
				}) || {},
			productInfosObj =
				organizationInfoFields.find(function (item) {
					return item.docPart === 'ProductTextInfos';
				}) || {},
			invoiceInfoFields = edi.utils.getObjectProperty(
				invoiceInfoObj,
				'fieldInfo',
				true
			) as OrganizationInfoFields['fieldInfo'],
			productFields = edi.utils.getObjectProperty(
				productInfosObj,
				'fieldInfo',
				true
			) as OrganizationInfoFields['fieldInfo'];

		if (!me.isEdit && !me.isCopy) {
			me.invoiceTextInfosColumns.getRows().forEach(function (row: ExtComponent) {
				row.identifierInput.allowBlank = true;
				row.identifierInput.setReadOnly(false);
				row.identifierInput.isValid();

				row.valueInput.allowBlank = true;
				row.valueInput.isValid();

				row.removeBtn.setDisabled(false);
			});
		}

		me.orgProductFields = productFields.map(function (fieldConfig) {
			return {
				identifier: fieldConfig.infoId,
				value: !fieldConfig.valueSrc && fieldConfig.value ? fieldConfig.value : '',
				allowEmptyValue: !fieldConfig.required,
				infoFieldTooltip: fieldConfig.tooltip || null,
				valueSrc: fieldConfig.valueSrc || null
			};
		});

		if (invoiceInfoFields.length) {
			me.processOrgInvoiceInfos(invoiceInfoFields);
		}
	}

	getInfoFieldValueByMap(valueFieldName: string) {
		const me = this;
		if (!valueFieldName) {
			return null;
		} else if (valueFieldName === 'sellerILN') {
			return me.userData.org.iln;
		} else if (valueFieldName === 'buyerILN' && me.buyerOrg) {
			return me.buyerOrg.iln;
		} else if (valueFieldName === 'shipFromILN' && me.shipFromOrg) {
			return me.shipFromOrg.iln || me.shipFromOrg.company_iln || me.shipFromOrg.individual_gln;
		} else if (valueFieldName === 'cargoReceiverILN' && me.deliveryOrg) {
			return me.deliveryOrg.iln || me.deliveryOrg.company_iln || me.deliveryOrg.individual_gln;
		}
		return valueFieldName;
	}

	//Update fields in "Text info" block of UPD
	processOrgInvoiceInfos(orgAdditionalInfoFields: OrganizationInfoFields['fieldInfo']) {
		const me = this;
		me.orgInfoFields = orgAdditionalInfoFields;
		const isNewUPD = !me.isEdit && !me.isCorrect && !me.isCreatedFromDoc && !me.isCopy;

		const createTooltip = function (targetCmp: ExtComponent, tooltipText: string) {
			targetCmp.toolTip = Ext.create('Ext.tip.ToolTip', {
				target: targetCmp.getEl(),
				trackMouse: true,
				html: tooltipText
			});

			targetCmp.on('destroy', function () {
				if (targetCmp.toolTip) {
					targetCmp.toolTip.destroy();
				}
			});
		};

		//Sets tooltip & value for row according to its config
		const updateRowDueToConfig = function (row: ExtComponent, rowConfig: InfoField) {
			if (rowConfig.tooltip) {
				createTooltip(row.identifierInput, rowConfig.tooltip);
			}

			//Remember  shipFrom or delivery row to set value for it when changing appropriate org in selector
			if (rowConfig.valueSrc === 'shipFromILN') {
				me.shipFromInfoField = row;
			} else if (rowConfig.valueSrc === 'cargoReceiverILN') {
				me.deliveryInfoField = row;
			}

			//Set values for field only when create UPD
			//For corrected UPD, UPD from transformation values shouldn't be replaced

			//In new UPD   OR  In UPD from transformation, fill field only if fieldValue is empty
			if (isNewUPD || ((me.isCopy || me.isCreatedFromDoc) && !row.valueInput.getValue())) {
				if (rowConfig.valueSrc) {
					row.valueInput.setValue(me.getInfoFieldValueByMap(rowConfig.valueSrc));
				} else if (!rowConfig.valueSrc && rowConfig.value) {
					row.valueInput.setValue(rowConfig.value);
				}
			}

			row.valueInput.isValid();
			row.identifierInput.isValid();
		};

		//Checks if there already exists a row with 'id' as an identifier
		const getIdentifierRow = function (id: number | string) {
			return me.invoiceTextInfosColumns.getRows().find(function (row: ExtComponent) {
				return row.identifierInput.getValue() == id;
			});
		};

		orgAdditionalInfoFields.forEach(function (fieldConfig, fieldIndex) {
			let row = getIdentifierRow(fieldConfig.infoId),
				isMandatoryField = fieldConfig.required;

			//For editing UPD  we don't add rows, just check already added
			if (row) {
				row.identifierInput.setReadOnly(true);
				row.valueInput.allowBlank = !isMandatoryField;
				row.removeBtn.setDisabled(isMandatoryField);
			} else if (isNewUPD || me.isCreatedFromDoc || me.isCopy) {
				//For creating UPD we add info row if there is no such row yet
				row = me.invoiceTextInfosColumns.createRowWithAddRemoveButtons(
					!fieldIndex,
					{
						identif: fieldConfig.infoId
					},
					{
						hideAddButton: true,
						isMandatory: isMandatoryField,
						disableChangeIdentifier: true,
						disableRemoveButton: isMandatoryField
					}
				);

				me.invoiceTextInfosColumns.insert(0, row);
			}
			me.invoiceTextInfosColumns.orgAdditionalInfoIdentifiers?.push(fieldConfig.infoId);

			if (row) {
				updateRowDueToConfig(row, fieldConfig);
			}
		});
	}

	getOrganizationAdditionalFields(orgId: number | null | undefined) {
		const me = this;

		const failure = function (responseData?: SendRequestResponse) {
			const errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
			edi.core.showWarn(errorMsg, me.updateInfoFields);
		};

		if (!orgId) {
			me.updateInfoFields();
		} else {
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.INFO_FIELDS.GET, {
					docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
					orgId: orgId
				}),
				'GET',
				null,
				function (data: SendRequestResponse<undefined, OrganizationInfoFields>) {
					if (data && data.items) {
						me.updateInfoFields(data.items);
					} else {
						failure();
					}
				},
				failure
			);
		}
	}

	removeOrgAdditionalInfoFields() {
		const me = this;

		const allRows = me.invoiceTextInfosColumns.getRows();
		const orgAdditionalRows = allRows.filter((row: ExtComponent) => {
			const identifier = row.identifierInput.getValue();
			const isOrgAdditionalRow = me.invoiceTextInfosColumns.orgAdditionalInfoIdentifiers?.includes(identifier);
			return identifier && isOrgAdditionalRow;
		});
		orgAdditionalRows.forEach((row: ExtComponent) => me.invoiceTextInfosColumns.remove(row));
		me.invoiceTextInfosColumns.orgAdditionalInfoIdentifiers = [];
	}

	createSeller(userOrganization: AnyObject, forcedBankData: AnyObject) {
		const me = this;

		const sellerName = 'dokument.svSchFakt.svProd';

		let sellerInfo = edi.utils.getObjectProperty(me.documentContent, sellerName);
		if (Array.isArray(sellerInfo) && sellerInfo.length) {
			sellerInfo = sellerInfo[0];
		}
		const isSellerDefined = !!sellerInfo;
		const isValidSeller = me.isValidOrganization({
			organization: sellerInfo || userOrganization,
			useOrganizationMap: isSellerDefined,
			organizationType: 'seller'
		});

		if (me.isEdit || me.isCorrect || me.isCopy) {
			const orgNames = [sellerName, 'dokument.svSchFakt.svPokup', 'dokument.svSchFakt.gruzPoluch'];
			orgNames.forEach(function (org) {
				const orgInfo = edi.utils.getObjectProperty(me.documentContent, org + '.0');
				edi.utils.setObjectProperty(me.documentContent, org, orgInfo);
			});
		}

		me.seller = me.createOS({
			itemId: 'seller',
			is_valid: isValidSeller,
			fieldValues: isSellerDefined ? me.documentContent : userOrganization,
			selectedOrgValues: isSellerDefined ? me.documentContent : userOrganization,
			selectedRelationByMap: me.isEdit || me.isCorrect || me.isCopy,
			readOnly: (me.isEdit || me.isCorrect) && !me.isCreate,
			valuesByMap: isSellerDefined,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: me.moduleData.initData.data?.fromOrg?.id ?? me.userData.org.id,
				converter: me.orgConverter
			}),
			forceChangeFields: me.isCreatedFromDoc ? me.forceChangeFields : forcedBankData,
			downloadBankAccountsData: true,
			callback: function (values: AnyObject, org: Org) {
				me.seller.is_valid = me.isValidOrganization({
					organization: values,
					organizationType: 'seller'
				});
				const sellerId = org ? org.id : null;
				if (me.shipFrom && me.shipFrom.getOrgIdForLoccat() !== sellerId) {
					me.shipFrom.reset();
					me.shipFrom.setOrgIdForLoccat(sellerId);
				}
				me.seller.checkOrgDataValid();
			},
			fieldsMap: me.selectorConfigsBuilder.getSellerFieldsMap(sellerName),
			modalConf: me.selectorConfigsBuilder.getSellerModalConfig()
		});

		me.sellerOrg = me.seller?.selectedOrg;
		if (!me.sellerOrg && me.seller && sellerInfo && me.isCreatedFromDoc) {
			const orgINN = sellerInfo.idSv?.svYULUch?.innyul || sellerInfo.idSv?.svIP?.innfl;
			me.sellerOrg = edi.utils.getOrg({ orgINN });
			me.seller.setOrganization(me.sellerOrg);
		}
	}

	createBuyer(props: {
		userOrganization: AnyObject;
		forcedBankData: AnyObject;
		attrAllowDocCreation?: string;
		attrAllowDocCreationError?: string;
	}) {
		const me = this;

		const {
			userOrganization,
			forcedBankData,
			attrAllowDocCreation = 'workingWithUPD_502-N',
			attrAllowDocCreationError = 'web.upload.document.upd_5_02n.error'
		} = props;

		const buyerName = 'dokument.svSchFakt.svPokup';
		let buyerInfo = edi.utils.getObjectProperty(me.documentContent, buyerName);
		me.isBuyerDefined = !!buyerInfo;
		if (Array.isArray(buyerInfo) && buyerInfo.length) {
			buyerInfo = buyerInfo[0];
		}
		const isValidBuyer = me.isValidOrganization({
			organization: buyerInfo,
			useOrganizationMap: me.isBuyerDefined,
			organizationType: 'buyer'
		});

		const fillTransferPersonFn = function () {
			const removeNotSelectedRecord = function (store: ExtComponent) {
				const notSelectedRecord = store?.findRecord('id', 'notSelected');
				store?.remove(notSelectedRecord);
			};
			const addNotSelectedRecord = function (store: ExtComponent) {
				const notSelectedRecord = {
					id: 'notSelected',
					name: edi.i18n.getMessage('form.combo.not.selected')
				};
				store?.insert(0, notSelectedRecord);
			};

			const isFillingUPDTransferPerson = edi.utils.getAttributeByName(
				me.buyerOrg?.attributes,
				'FILLING_UPD_TRANSFER_PERSON',
				undefined,
				true
			);
			const { handingGoodsTypeField } = me.transportAndCargoFieldSet?.getFields() ?? {};
			const handingGoodsTypeComboStore = handingGoodsTypeField?.getStore();
			isFillingUPDTransferPerson
				? removeNotSelectedRecord(handingGoodsTypeComboStore)
				: addNotSelectedRecord(handingGoodsTypeComboStore);
			if (!me.isEdit || me.isCreatedFromDoc) {
				handingGoodsTypeField?.setValue(
					isFillingUPDTransferPerson
						? HANDING_GOODS_TYPE.sellerOrganizationWorker
						: handingGoodsTypeField.originalValue
				);

				const { firstName, lastName, patronymicName } = me.transportAndCargoFieldSet?.getFields() ?? {};

				if (isFillingUPDTransferPerson) {
					firstName?.setValue(me.userData?.firstName);
					lastName?.setValue(me.userData?.lastName);
					patronymicName?.setValue(me.userData?.patronymicName);
				}
			}
		};

		me.buyer = me.createOS({
			itemId: 'buyer',
			relationsOnly: true,
			allowBlank: false,
			is_valid: isValidBuyer,
			relations: !me.isCreate
				? !me.isEdit && !me.isCopy && !me.isCorrect && me.buyerOrg
					? me.buyerOrg
					: edi.relations.getRelations()
				: undefined,
			allowReset: (!(me.isEdit || me.isCorrect) && !me.isBuyerDefined) || me.isCopy,
			fieldValues: me.documentContent,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: me.moduleData.initData.data?.toOrg?.id,
				converter: me.orgConverter
			}),
			readOnly: me.isEdit && !me.isCreate,
			selectedOrgValues:
				!(me.isEdit || me.isCorrect || me.isCopy) && me.buyerOrg
					? me.buyerOrgValues
					: me.isBuyerDefined
					? me.documentContent
					: undefined,
			selectedOrg: me.buyerOrg ? me.buyerOrg : null,
			selectedRelationByMap: me.isEdit || me.isCopy || me.isCorrect || me.buyerOrg,
			forceChangeFields: me.isCreatedFromDoc ? me.forceChangeFields : forcedBankData,
			downloadBankAccountsData: true,
			getErrorText: function () {
				const orgSelector = this;

				const selectedOrg = orgSelector.selectedOrg;
				const attributes = selectedOrg?.attributes ?? me.documentHeader?.toOrg?.attributes;

				const isBuyerWorkWithUPD_502_N = edi.utils.getAttributeByName(
					attributes,
					attrAllowDocCreation,
					undefined,
					true
				);

				return edi.i18n.getMessage(
					attributes && !isBuyerWorkWithUPD_502_N ? attrAllowDocCreationError : orgSelector.errorLabelKey
				);
			},
			isValidFunc: (fieldValues: AnyObject, orgSelector: ExtComponent) => {
				const selectedOrg = orgSelector.selectedOrg as Org | null;
				const attributes = selectedOrg?.attributes ?? me.documentHeader?.toOrg?.attributes;

				const isBuyerWorkWithUPD_502_N = edi.utils.getAttributeByName(
					attributes,
					attrAllowDocCreation,
					undefined,
					true
				);

				if (attributes && !isBuyerWorkWithUPD_502_N) {
					return false;
				}

				return orgSelector.is_valid;
			},
			callback: function (values: AnyObject, org: Org) {
				const orgId = org ? org.id : me.isCreate && me.buyerOrg ? me.buyerOrg.id : null,
					sameOrgSelected = me.buyerOrg && me.buyerOrg.id == orgId;

				if (!sameOrgSelected) {
					me.shipFromInfoField = null;
					me.deliveryInfoField = null;
				}
				if (me.cargoReceiver && me.cargoReceiver.getOrgIdForDelcat() != orgId) {
					me.cargoReceiver.reset();
					me.cargoReceiver.setOrgIdForDelcat(orgId);
					me.cargoReceiver.setPartnerOrg(org);
				}
				if (me.productsGrid && me.productsGrid.getPartnerId() != orgId) {
					me.productsGrid.productsGrid.getStore().removeAll();
				}
				me.productsGrid?.setPartnerId(orgId);
				if (orgId && !sameOrgSelected) {
					me.buyerOrg = org;
					me.taxCalculationMethod = edi.utils.getAttributeByName(
						me.buyerOrg.attributes,
						'taxCalculationMethod'
					);
					if (!me.taxCalculationMethod || me.taxCalculationMethod === 'null') {
						me.taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
					}
				} else if (!orgId) {
					me.buyerOrg = null;
					me.taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
				}

				me.getOrganizationAdditionalFields(me.buyerOrg ? me.buyerOrg.id : null);

				me.buyer.is_valid = me.isValidOrganization({
					organization: values,
					organizationType: 'buyer'
				});

				if (me.mainForm?.rendered) {
					fillTransferPersonFn();
				} else {
					me.afterrenderQueue.push(fillTransferPersonFn);
				}

				me.buyer.checkOrgDataValid();

				if (!orgId || !sameOrgSelected) {
					me.removeOrgAdditionalInfoFields();
				}
			},
			fieldsMap: me.selectorConfigsBuilder.getBuyerFieldsMap(buyerName),
			modalConf: me.selectorConfigsBuilder.getBuyerModalConfig()
		});

		if (me.buyer && buyerInfo && me.isCreatedFromDoc) {
			const orgINN = buyerInfo.idSv?.svYULUch?.innyul || buyerInfo.idSv?.svIP?.innfl;
			let fullBuyerInfo = edi.utils.getOrg({ orgINN });
			me.buyer.setOrganization(fullBuyerInfo);
		}
	}

	createShipFrom(userOrganization: AnyObject) {
		const me = this;

		let shipFromInfo =
			edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.gruzOt.0.gruzOtpr') ||
			edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.gruzOt.gruzOtpr');
		edi.utils.setObjectProperty(me.documentContent, 'dokument.svSchFakt.gruzOt', { gruzOtpr: shipFromInfo });
		let isShipFromDefined = !!shipFromInfo;
		let canEditShip = me.isEdit || me.isCopy || me.isCorrect || !isShipFromDefined;

		const isValidShipFrom = me.isValidOrganization({
			organization: shipFromInfo,
			useOrganizationMap: isShipFromDefined,
			organizationType: 'cargoSender'
		});
		me.shipFrom = me.createOS({
			itemId: 'shipFrom',
			allowBlank: true,
			is_valid: isValidShipFrom || !isShipFromDefined,
			ownCatalog: false,
			allowReset: canEditShip,
			ownOrg: canEditShip,
			fieldValues: isShipFromDefined
				? {
						...me.documentContent,
						type: isShipFromDefined ? me.getOrgType(shipFromInfo).type : null
				  }
				: undefined,
			relationsFromLoccatByOrgId: me.sellerOrg?.id || userOrganization.id,
			selectedOrgValues: isShipFromDefined ? me.documentContent : undefined,
			forceChangeFields: me.forceChangeFieldsForShips,
			downloadBankAccountsData: true,
			callback: function (values: AnyObject, org: Org) {
				me.initialData
					? edi.utils.setObjectProperty(me.initialData, 'dokument.svSchFakt.gruzOt.gruzOtpr', null)
					: null;
				me.shipFrom.is_valid =
					me.isValidOrganization({
						organization: values,
						organizationType: 'cargoSender'
					}) || me.shipFrom.isEmptyValues();

				me.shipFromOrg = org || values;
				if (me.shipFromInfoField) {
					me.shipFromInfoField.valueInput.setValue(values.company_iln || values.individual_gln);
					me.shipFromInfoField.valueInput.isValid();
				}
				me.shipFrom.checkOrgDataValid();
			},
			fieldsMap: me.selectorConfigsBuilder.getShipFromFieldsMap('dokument.svSchFakt.gruzOt.gruzOtpr'),
			modalConf: me.selectorConfigsBuilder.getShipFromModalConfig(),
			processValues: me.orgConverter
		});
	}

	createCargoReceiver() {
		const me = this;

		let cargoReceiverInfo = edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.gruzPoluch'),
			isCargoReceiverDefined = !!cargoReceiverInfo,
			canEditReceiver = me.isEdit || me.isCopy || me.isCorrect || !isCargoReceiverDefined,
			partnerOrg = null;
		if (Array.isArray(cargoReceiverInfo) && cargoReceiverInfo.length) {
			cargoReceiverInfo = cargoReceiverInfo[0];
		}
		const isCargoReceiverValid = me.isValidOrganization({
			organization: cargoReceiverInfo,
			useOrganizationMap: isCargoReceiverDefined,
			organizationType: 'cargoReceiver'
		});
		if (!!me.buyerOrg) {
			partnerOrg = edi.utils.getOrg({
				orgId: me.buyerOrg.id
			});
		}

		me.cargoReceiver = me.createOS({
			itemId: 'cargoReceiver',
			allowBlank: true,
			ownCatalog: false,
			alwaysShowSelect: canEditReceiver,
			allowReset: canEditReceiver,
			is_valid: isCargoReceiverValid || !isCargoReceiverDefined,
			partnerOrg: canEditReceiver,
			partnerOrgText: 'form.btn.buyer.org',
			partnerOrgValues: partnerOrg,
			relationsFromDelcatByOrgId:
				canEditReceiver && me.isBuyerDefined && me.buyerOrg ? me.buyerOrg.id : undefined,
			fieldValues: isCargoReceiverDefined
				? {
						...me.documentContent,
						type: isCargoReceiverDefined ? me.getOrgType(cargoReceiverInfo).type : null
				  }
				: undefined,
			selectedOrgValues: isCargoReceiverDefined ? me.documentContent : undefined,
			forceChangeFields: me.forceChangeFieldsForShips,
			downloadBankAccountsData: true,
			callback: function (values: AnyObject, org: Org) {
				me.initialData
					? edi.utils.setObjectProperty(me.initialData, 'dokument.svSchFakt.gruzPoluch', null)
					: null;
				me.cargoReceiver.is_valid =
					me.isValidOrganization({
						organization: values,
						organizationType: 'cargoReceiver'
					}) || me.cargoReceiver.isEmptyValues();

				me.deliveryOrg = org || values;
				if (me.deliveryInfoField) {
					me.deliveryInfoField.valueInput.setValue(values.company_iln || values.individual_gln);
					me.deliveryInfoField.valueInput.isValid();
				}

				me.cargoReceiver.checkOrgDataValid();
			},
			fieldsMap: me.selectorConfigsBuilder.getCargoReceiverFieldsMap('dokument.svSchFakt.gruzPoluch'),
			modalConf: me.selectorConfigsBuilder.getCargoReceiverModalConfig()
		});
	}

	getParties(): ExtComponent {
		const me = this;

		const userOrganization: AnyObject = edi.converters.convertOrgToPartie(me.userData.org);

		const forcedBankData: AnyObject = {
			bank_id: true,
			bank_name: true,
			bank_corr_acc: true,
			bank_acc: true
		};

		me.createSeller(userOrganization, forcedBankData);
		me.createBuyer({ userOrganization, forcedBankData });

		me.createShipFrom(userOrganization);
		me.createCargoReceiver();

		me.setNameForCompanySelector(me.seller, 'document.seller');
		me.setNameForCompanySelector(me.buyer, 'document.buyer');
		me.setNameForCompanySelector(me.shipFrom, 'document.ship.from');
		me.setNameForCompanySelector(me.cargoReceiver, 'document.delivery');

		return createMaxWidthContainer({
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [
					[6, 6],
					[6, 6]
				]
			},
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('document.seller'),
					items: [me.seller]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.buyer'),
					items: [me.buyer]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.ship.from'),
					items: [me.shipFrom]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.delivery'),
					items: [me.cargoReceiver]
				})
			]
		}) as ExtComponent;
	}

	createInformationOfTheFactOfeconomicLifeField(isMainField: boolean, fieldData: TekstInf | null, props: AnyObject) {
		let isMandatoryField: boolean = props.isMandatory;

		let identifierInput = createTextField({
			fieldLabel: edi.i18n.getMessage('column.identif'),
			readOnly: true,
			msgTarget: 'qtip',
			qtipText: edi.i18n.getMessage('info.text.infos.read.only'),
			name: 'dokument.svProdPer.infPolFHZH3.tekstInf.identif',
			value: edi.utils.getObjectProperty(fieldData, 'identif')
		});

		let valueInput = createTextField({
			fieldLabel: edi.i18n.getMessage('column.value'),
			allowBlank: !isMandatoryField,
			readOnly: true,
			qtipText: edi.i18n.getMessage('info.text.infos.read.only'),
			name: 'dokument.svProdPer.infPolFHZH3.tekstInf.znachen',
			value: edi.utils.getObjectProperty(fieldData, 'znachen')
		});

		let invoiceTextInfoRow = createContainer({
			layout: {
				type: 'grid',
				area: [[4, 4]]
			},
			items: [identifierInput, valueInput]
		}) as ExtComponent;

		invoiceTextInfoRow.identifierInput = identifierInput;
		invoiceTextInfoRow.valueInput = valueInput;

		return invoiceTextInfoRow;
	}

	createInvoiceTextInfosField(
		isMainField: boolean,
		fieldData: { identif: string } | {} = {},
		props?: {
			hideAddButton?: boolean;
			isMandatory?: boolean;
			disableChangeIdentifier?: boolean;
			disableRemoveButton?: boolean;
		}
	) {
		const me = this;

		let isMandatoryField = !!props?.isMandatory;

		let identifierInput = createCombo({
			fieldLabel: edi.i18n.getMessage('column.identif'),
			readOnly: props?.disableChangeIdentifier,
			forceSelection: false,
			store: edi.stores.createInlineStore(getPurposesTypes(), 'SIMPLE'),
			name: 'dokument.svSchFakt.infPolFHZH1.tekstInf.identif',
			value: edi.utils.getObjectProperty(fieldData, 'identif'),
			triggers: {
				clear: {
					extraCls: 'edi-icon edi-icon-CLOSE',
					handler: function (field: ExtComponent) {
						field.clearValue();
					}
				}
			},
			getInfoValueField() {
				return valueInput;
			},
			validator: function (value: string) {
				if (!value) {
					return true;
				}
				const inputs = me.invoiceTextInfosColumns.query(
					'field[name="dokument.svSchFakt.infPolFHZH1.tekstInf.identif"]'
				) as ExtComponent[];
				let allOtherIdents: { [identifValue: string]: boolean } = {};
				inputs.forEach((input: ExtComponent) => {
					if (input !== identifierInput) {
						allOtherIdents[input.getValue()] = true;
					}
				});
				let sameValueInput = allOtherIdents[value] || allOtherIdents[getPurposeTypeFromValue(value)];
				return !sameValueInput || edi.i18n.getMessage('identifier.cant.be.duplicated');
			},
			listeners: {
				change: function (field: ExtComponent, identifierValue: string, oldValue: string) {
					if (
						identifierValue !== oldValue &&
						(identifierValue === PURPOSES_TYPES.VID_OBOBOROTA ||
							identifierValue === PURPOSES_TYPES.SV_VIBITIYA)
					) {
						valueInput.getStore().removeAll();
						valueInput.getStore().add(getPurposesByTypeAndFunction(identifierValue, me.bpName));
						valueInput.setValue(null);
					}
					valueInput.allowBlank = !identifierValue;
					valueInput.isValid();
				}
			}
		}) as ExtComponent;

		const valueInput = createCombo({
			fieldLabel: edi.i18n.getMessage('column.value'),
			allowBlank: !isMandatoryField,
			forceSelection: false,
			name: 'dokument.svSchFakt.infPolFHZH1.tekstInf.znachen',
			value: edi.utils.getObjectProperty(fieldData, 'znachen'),
			store: edi.stores.createInlineStore([], 'SIMPLE'),
			triggers: {
				clear: {
					extraCls: 'edi-icon edi-icon-CLOSE',
					handler: function (field: ExtComponent) {
						field.clearValue();
					}
				}
			},
			listeners: {
				change: function (field: ExtComponent, newValue: string) {
					identifierInput.allowBlank = !newValue;
					identifierInput.isValid();
				},
				afterrender(combo: ExtComponent) {
					combo.getStore().removeAll();
					combo.getStore().add(getPurposesByTypeAndFunction(identifierInput.getValue(), me.bpName));
				}
			}
		}) as ExtComponent;

		const invoiceTextInfoRow = createContainer({
			layout: {
				type: 'grid',
				area: [[6, 6]]
			},
			items: [identifierInput, valueInput]
		}) as ExtComponent;

		invoiceTextInfoRow.identifierInput = identifierInput;
		invoiceTextInfoRow.valueInput = valueInput;

		return invoiceTextInfoRow;
	}

	createTextInfosBlock(): ExtComponent {
		const me = this;
		const infos = me.documentContent?.dokument?.svSchFakt?.infPolFHZH1?.tekstInf ?? [];
		infos.forEach((inf) => {
			if (
				inf.identif === getPurposeTypeValue(PURPOSES_TYPES.VID_OBOBOROTA) ||
				inf.identif === getPurposeTypeValue(PURPOSES_TYPES.SV_VIBITIYA)
			) {
				inf.identif = getPurposeTypeFromValue(inf.identif);
			}
		});

		const textInfos = me.documentContent?.dokument?.svSchFakt?.infPolFHZH1?.tekstInf ?? [];
		const exludedTextInfos = [TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER];
		me.invoiceTextInfosColumns = createRowsBlock({
			createContentFieldsFn: me.createInvoiceTextInfosField.bind(me),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			initialData:
				me.isEdit || me.isCorrect || me.isCopy
					? textInfos.filter((textInfo) => !exludedTextInfos.includes(textInfo.identif))
					: [],
			setExtraDataToRow(row: ExtComponent) {
				row.identifierInput = row.contentFieldsPanel.identifierInput;
				row.valueInput = row.contentFieldsPanel.valueInput;
			},
			orgAdditionalInfoIdentifiers: []
		});

		const tekstInfRowBlock = createRowsBlock({
			createContentFieldsFn: me.createInformationOfTheFactOfeconomicLifeField.bind(me),
			initialData:
				me.isEdit || me.isCorrect || me.isCopy
					? edi.utils.getObjectProperty(me.documentContent, 'dokument.svProdPer.infPolFHZH3.tekstInf') || []
					: [],
			setExtraDataToRow(row: ExtComponent) {
				row.identifierInput = row.contentFieldsPanel.identifierInput;
				row.valueInput = row.contentFieldsPanel.valueInput;
			},
			isReadOnly: true
		});

		return createFieldSet({
			title: edi.i18n.getMessage('documents.fns_upd.special_identifiers'),
			collapsed: true,
			collapsible: true,
			items: [me.createMaxContainer([me.invoiceTextInfosColumns, tekstInfRowBlock])]
		});
	}

	createExtraBlocks(): ExtComponent[] {
		return [];
	}

	createPaymentInformationBlock(): ExtComponent {
		const me = this;

		const createPaymentInformationField = function (isMainField: boolean, fieldData: SvPRD | {}) {
			const numberField = createTextField({
				fieldLabel: edi.i18n.getMessage('column.license.number'),
				value: edi.utils.getObjectProperty(fieldData, 'nomerPRD'),
				name: 'dokument.svSchFakt.svPRD.nomerPRD',
				maxLength: 30,
				nameable: true
			});
			const dateField = createDateField({
				fieldLabel: edi.i18n.getMessage('date'),
				submitFormat: edi.constants.DATE_FORMAT.FNS,
				value: edi.utils.getObjectProperty(fieldData, 'dataPRD'),
				nameable: true,
				name: 'dokument.svSchFakt.svPRD.dataPRD'
			});
			const sumField = createNumberField({
				fieldLabel: edi.i18n.getMessage('documents.fns_upd.paymentInformation.sum'),
				value: edi.utils.getObjectProperty(fieldData, 'summaPRD'),
				nameable: true,
				name: 'dokument.svSchFakt.svPRD.summaPRD',
				vtype: 'fnsNumberN19_2',
				replaceComa: true,
				allowDecimals: true,
				decimalPrecision: 2
			});

			return createContainer({
				layout: {
					type: 'grid',
					columns: 8,
					area: [[4, 2, 2]]
				},
				nameHolder: true,
				items: [numberField, dateField, sumField]
			});
		};

		const paymentInformationColumns = createRowsBlock({
			createContentFieldsFn: createPaymentInformationField,
			initialData:
				me.isEdit || me.isCorrect || me.isCopy
					? edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.svPRD') || []
					: [],
			onAddRow() {
				me.checkValid();
			},
			onRemoveRow() {
				me.checkValid();
			}
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.paymentInformation'),
			items: paymentInformationColumns
		});
	}

	shipDocConfirmationValidation() {
		const me = this;
		if (me.blocks.shipDocConfirmation) {
			const isSCHF = isSCHF_BusinessProcess(me.bpName);
			const spObstFSCHFValue = me.additionalInformationFieldSet?.getFields()?.schfSpecialConditions?.getValue();
			const isSpObstFSCHF2 = spObstFSCHFValue === '2';
			const blockHolder = me.blocks.shipDocConfirmation.blockHolder;
			//Элемент обязателен при <Функция>=СЧФ, кроме случая, когда <СпОбстФСЧФ> = 2.
			blockHolder.allowBlank = !(isSCHF && !isSpObstFSCHF2);
			blockHolder.isValid();
			blockHolder.markAllowBlank();
		}
	}
	setShipDocConfirmationAutoValue() {
		const me = this;
		const isCreate = !me.documentContent;
		if (isCreate && isSCHFDOP_BussinessProcess(me.bpName) && me.blocks.shipDocConfirmation) {
			const blockHolder = me.blocks.shipDocConfirmation.blockHolder;
			const { invoiceDate, invoiceNumber } = me.mainDataBlockInstance.fields;
			const values = {
				rekvNaimDok: edi.i18n.getMessage('documents.fns_upd002.shipDocName.default'),
				rekvNomerDok: invoiceNumber?.getValue(),
				rekvDataDok: invoiceDate?.getSubmitValue()
			};
			if (blockHolder.naimDokOpr) {
				const currentValues = blockHolder.naimDokOpr.getValues();
				blockHolder.naimDokOpr.setValues(Object.assign({}, currentValues, values));
			} else {
				me.blocks.shipDocConfirmation.blockHolder.addBlock('addDocument', values);
				blockHolder.naimDokOpr = blockHolder.lastAddedBlock;
			}
		}
	}

	createShipDocConfirmationBlock(): ExtComponent {
		const me = this;
		const name = 'dokument.svSchFakt.dokPodtvOtgrNom';
		return (me.blocks.shipDocConfirmation = createNamedDocumentBlock(
			{
				data: edi.utils.getObjectProperty(me.documentContent, name, true),
				name,
				title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.shipDocConfirmation')
			},
			undefined,
			{
				listeners: {
					afterrender: () => me.shipDocConfirmationValidation()
				}
			}
		));
	}

	createMaxContainer(items: ExtComponent[]): ExtComponent {
		return createMaxWidthContainer({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: items as ExtComponent[]
		}) as ExtComponent;
	}

	createAdditionalInformationFieldSet(props: Partial<AdditionalInformationProps> = {}): ExtComponent {
		const me = this;

		const additionalInformation = new me.AdditionalInformationConstructor(
			Object.assign(
				{
					documentData: me.documentContent ?? {},
					createOS: me.createOS.bind(me),
					getOrgType: me.getOrgType,
					selectorConfigsBuilder: me.selectorConfigsBuilder,
					factors: me.factors,
					forceChangeFieldsForShips: me.forceChangeFieldsForShips,
					modalAdditionalConf: me.modalAdditionalConf,
					setNameForCompanySelector: me.setNameForCompanySelector.bind(me),
					checkValid: me.checkValid.bind(me),

					getCurrentUPDState: () => ({
						updFunction: me.bpName,
						isEdit: me.isEdit,
						isCopy: me.isCopy,
						isCorrect: me.isCorrect
					}),

					specialConditionsChangedHandler: () => {
						me.shipDocConfirmationValidation();
					}
				},
				props
			)
		);

		return (me.additionalInformationFieldSet = additionalInformation.createView());
	}

	createProductGrid(): ExtComponent {
		const me = this;
		const lines = Ext.clone(edi.utils.getObjectProperty(me.documentContent, 'dokument.tablSchFakt.svedTov', true));

		const docLines = new me.ProductGridConstructor({
			data: lines,
			documentData: me.documentContent?.dokument ?? {},
			getCurrentUPDState: () => ({
				taxCalculationMethod: me.taxCalculationMethod,
				updFunction: me.bpName,
				spObstFSCHFValue: me.additionalInformationFieldSet?.getFields()?.schfSpecialConditions?.getValue(),
				spObstFDOP: me.additionalInformationFieldSet?.getFields()?.dopSpecialConditions?.getValue(),
				isCreatedFromDoc: me.isCreatedFromDoc,
				orgProductFields: me.orgProductFields,
				isCreate: me.isCreate,
				isCorrect: me.isCorrect
			})
		});

		me.productsGrid = docLines.createProductGrid();

		me.productsGrid.setPartnerId(me.buyerOrg?.id);

		return me.productsGrid;
	}

	createReasonForBeingInvoiceFileCreatorBlock(): ExtComponent {
		const me = this;
		const name = 'dokument.osnDoverOrgSost';
		return createNamedDocumentBlock(
			{
				data: edi.utils.getObjectProperty(me.documentContent, name),
				name,
				title: edi.i18n.getMessage('documents.fns_upd_5_02n.file.creator.reason')
			},
			{
				isSingleDocument: true
			}
		);
	}

	createTransportAndCargoBlock() {
		const me = this;
		return (me.transportAndCargoFieldSet = createTransportAndCargo({
			documentData: me.documentContent ?? {},
			initialData: me.initialData,
			getCurrentUPDState: () => ({
				updFunction: me.bpName,
				isEdit: me.isEdit,
				isCopy: me.isCopy
			})
		}));
	}

	createModuleForm() {
		const me = this;

		const headData = [me.getModuleTitle(), me.createMainDataBlock()];

		me.mainForm = createFormForModule({
			items: [
				me.createMaxContainer([...headData, me.getParties(), me.createPaymentInformationBlock()]),
				me.createShipDocConfirmationBlock(),
				me.createTransportAndCargoBlock(),
				me.createAdditionalInformationFieldSet(),
				me.createTextInfosBlock(),
				...me.createExtraBlocks(),

				me.createProductGrid(),
				(me.signerAndPoaContainer = createSignerAndPoa({
					setModuleLoading: me.setLoading.bind(me),
					signers: me.signers,
					isSCHFSelected: me.isSCHFSelected,
					isSCHFDOPSelected: me.isSCHFDOPSelected,
					isDOPSelected: me.isDOPSelected
				})),
				me.createReasonForBeingInvoiceFileCreatorBlock()
			],
			buttons: createButtonContainer({
				items: me.getFooterButtons()
			}),

			listeners: {
				afterrender: () => {
					me.afterrenderQueue.forEach((fn) => {
						if (typeof fn === 'function') fn();
					});
				}
			}
		});

		me.mainForm.on('validitychange', me.checkValid);
		me.mainForm.isValid();
		edi.utils.processModuleFormChange(me.mainForm, me.moduleData);

		return me.mainForm;
	}

	setLoading(load: boolean | AnyObject | string) {
		const me = this;
		if (me.moduleData?.tab && !me.moduleData.tab.isDestroyed) {
			me.moduleData.tab.setLoading(load);
		}
	}

	/**
	 * Compare text infos values with values in valueSrc
	 * @param      infos                Text infos array
	 * @param      fieldsToCheckMap     Map of fieldNames to check and their valueSrc-s
	 * @param      successCallback      Callback to call after "Ok" button pressed
	 */
	checkSpecifiedInfosActuality(
		infos: TekstInf[],
		fieldsToCheckMap: { [infoId: string]: string },
		successCallback: Function
	) {
		const me = this;

		let notActual: {
				identifier: string;
				currentValue: string;
				actualValue: string;
			}[] = [],
			valueSrc = null,
			actualValue;

		const showCompareModal = function (
			notActualIdentifiers: {
				identifier: string;
				currentValue: string;
				actualValue: string;
			}[]
		) {
			// Creates html of non-equal values
			const createList = function (items: { identifier: string; currentValue: string; actualValue: string }[]) {
				let html,
					item,
					emptyValue = edi.i18n.getMessage('form.combo.not.selected'),
					infoIdHeader = edi.i18n.getMessage('documents.fns_upd.special_identifiers.compare.infoId'),
					currentValueHeader = edi.i18n.getMessage(
						'documents.fns_upd.special_identifiers.compare.currentValue'
					),
					actualValueHeader = edi.i18n.getMessage(
						'documents.fns_upd.special_identifiers.compare.actualValue'
					);

				html =
					"<table width='100%'>" +
					'<thead>' +
					"<th width='30%'>" +
					infoIdHeader +
					'</th>' +
					"<th width='30%'>" +
					currentValueHeader +
					'</th>' +
					"<th width='30%'>" +
					actualValueHeader +
					'</th>' +
					'</thead>' +
					'<tbody>';

				for (let i = 0; i < items.length; i++) {
					item = items[i];
					html +=
						"<td class='edi-label label-center'>" +
						item.identifier +
						'</td>' +
						"<td class='edi-label label-center edi-data-good'>" +
						(item.actualValue || emptyValue) +
						'</td>' +
						"<td class='edi-label label-center edi-data-error'>" +
						(item.currentValue || emptyValue) +
						'</td></tr>';
				}

				html += '</tbody></table>';
				return html;
			};

			const str = edi.i18n.getMessage('documents.fns_upd.special_identifiers.compare.warn');

			let modal = createModalPanel(
				{
					width: MODAL_SIZE.widthMedium,
					title: edi.i18n.getMessage('documents.fns_upd.special_identifiers.compare.title'),
					layout: 'fit',
					items: [
						createForm({
							bodyPadding: 10,
							items: [
								createLabel({
									html: edi.utils.formatString(str, [createList(notActualIdentifiers)])
								})
							],
							buttons: [
								createButton({
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage('documents.fns_upd.special_identifiers.compare.save'),
									formBind: true,
									disabled: true,
									handler: function () {
										modal.close();
										if ('function' == typeof successCallback) {
											successCallback();
										}
									}
								}),
								createButton({
									cls: BUTTON_CLS.secondary,
									text: edi.i18n.getMessage('btn.cancel'),
									glyph: edi.constants.ICONS.CANCEL,
									handler: function () {
										me.setLoading(false);
										modal.close();
									}
								})
							]
						})
					],
					listeners: {
						close: function () {
							me.setLoading(false);
						}
					}
				},
				true
			);
			modal.show();
		};

		infos.forEach(function (info) {
			if (fieldsToCheckMap.hasOwnProperty(info.identif)) {
				valueSrc = fieldsToCheckMap[info.identif];
				actualValue = me.getInfoFieldValueByMap(valueSrc);
				if (actualValue && info.znachen !== actualValue) {
					notActual.push({
						identifier: info.identif,
						currentValue: info.znachen,
						actualValue: actualValue
					});
				}
			}
		});

		if (notActual.length) {
			showCompareModal(notActual);
		} else if ('function' == typeof successCallback) {
			successCallback();
		}
	}

	async createModulePanel() {
		const me = this;

		const moduleInitData = me.moduleData.initData.data;
		if (me.isCreatedFromDoc && !me.buyerOrg) {
			me.buyerOrg = moduleInitData.toOrg ? moduleInitData.toOrg : null;
			me.taxCalculationMethod = edi.utils.getAttributeByName(me.buyerOrg?.attributes, 'taxCalculationMethod');
			if (!me.taxCalculationMethod || me.taxCalculationMethod === 'null') {
				me.taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
			}
		}

		await me.fillUPDSignerValues();
		await me.buildForm();
	}

	save() {
		const me = this;
		me.setLoading(edi.i18n.getMessage('loading.text'));

		let values = Ext.clone(me.buildDocument(edi.utils.collectFormValues(me.mainForm)));
		const originalData = Ext.clone(me.initialData);

		if (me.isEdit || me.isCopy) {
			//чтобы не объединять разные сведения о лице передавшем груз
			edi.utils.setObjectProperty(originalData, 'dokument.svProdPer.svPer.svLitsPer', null);
			//чтобы не объединять разные основания отгрузки товара (bezDokOsnPer, osnPer)
			edi.utils.setObjectProperty(originalData, 'dokument.svProdPer.svPer.bezDokOsnPer', null);
			edi.utils.setObjectProperty(originalData, 'dokument.svProdPer.svPer.osnPer', null);
			//чтобы не объединять разные НДС (bezNDS, sumNal)
			edi.utils.setObjectProperty(originalData, 'dokument.tablSchFakt.vsegoOpl.sumNalVsego', null);
		}

		const fieldsToRemove: string[] = [].concat(edi.constants.FIELDS_TO_REMOVE_AFTER_MERGE_FNS);
		if (me.isCopy) {
			fieldsToRemove.push('idFayl', 'fileId', 'versForm', 'versProg', 'dokument.vremInfPr', 'dokument.dataInfPr');
		}
		values = edi.document.actions.mergeDataBeforeSave(
			originalData,
			values,
			[
				{
					path: 'dokument.tablSchFakt.svedTov'
				}
			],
			{
				fieldsToRemove
			}
		);

		edi.utils.clearEmptyValues(values);

		const textInfos = values?.dokument?.svSchFakt?.infPolFHZH1?.tekstInf;
		//If org has specified fields and values of these fields must be taken from specified document values,
		//Create map of fields to check them (fields with "valueSrc" property) and show modal of differs if needed
		if (me.orgInfoFields.length && textInfos?.length) {
			let identifiersToCheckMap: { [infoId: string]: string } = {};

			me.orgInfoFields.forEach(function (fieldConfig) {
				if (fieldConfig.valueSrc) {
					identifiersToCheckMap[fieldConfig.infoId] = fieldConfig.valueSrc;
				}
			});

			if (Object.keys(identifiersToCheckMap).length > 0) {
				me.checkSpecifiedInfosActuality(textInfos, identifiersToCheckMap, function () {
					me.saveDocument(values);
				});
			} else {
				me.saveDocument(values);
			}
		} else {
			me.saveDocument(values);
		}
	}

	getOrgType(org: AnyObject): {
		isIP: boolean;
		isUL: boolean;
		isInNeUch: boolean;
		isFL: boolean;
		type: string | undefined;
	} {
		const ipData = org.idSv?.svIP;
		const isIP = Ext.isObject(ipData) && ipData.fio?.familiya && ipData.fio?.imya && ipData?.innfl;

		const ulData = org.idSv?.svYULUch;
		const isUL = Ext.isObject(ulData) && ulData.naimOrg && ulData.innyul;

		const inNeUchData = org.idSv?.svInNeUch;
		const isInNeUch = Ext.isObject(inNeUchData) && inNeUchData.idStat && inNeUchData.naim;

		const flData = org.idSv?.svFLUch;
		const isFL =
			(Ext.isObject(flData) && flData.fio?.familiya && flData.fio?.imya) ||
			flData?.innfl ||
			flData?.inieSved ||
			flData?.idStatL;

		let type;
		if (isUL) type = 'company';
		else if (isIP) type = 'individual';
		else if (isInNeUch) type = 'legalForeigner';
		else if (isFL) type = 'individualParticipant';

		return {
			isIP,
			isUL,
			isInNeUch,
			isFL,
			type
		};
	}

	buildDocument(formValues: AnyObject): UPD502N {
		const me = this;
		const cloneValues = Ext.clone(formValues);

		//чистим адреса
		const companiesPath = [
			'dokument.svSchFakt.dopSvFHZH1.svFaktor',
			'dokument.svSchFakt.svProd',
			'dokument.svSchFakt.svPokup',
			'dokument.svSchFakt.gruzOt.gruzOtpr',
			'dokument.svSchFakt.gruzPoluch'
		];

		companiesPath.forEach(function (companyPath) {
			switch (edi.utils.getObjectProperty(cloneValues, companyPath + '.address_type')) {
				case '0':
					edi.utils.setObjectProperty(cloneValues, companyPath + '.address.codeStateAddressRegister', null);
					edi.utils.setObjectProperty(cloneValues, companyPath + '.adres.adrInf', null);
					break;
				case '1':
					edi.utils.setObjectProperty(cloneValues, companyPath + '.address.codeStateAddressRegister', null);
					edi.utils.setObjectProperty(cloneValues, companyPath + '.adres.adrRF', null);
					break;
				case '2':
					edi.utils.setObjectProperty(cloneValues, companyPath + '.adres.adrRF', null);
					edi.utils.setObjectProperty(cloneValues, companyPath + '.adres.adrInf', null);
					break;
			}
			edi.utils.setObjectProperty(cloneValues, companyPath + '.address_type', null);

			const org = edi.utils.getObjectProperty(cloneValues, companyPath);
			const convertObjectValuesToArr = ['kontakt.elPochta', 'kontakt.tlf', 'adres.adrGAR.zdanie'];
			convertObjectValuesToArr.forEach((path) => {
				const value = edi.utils.getObjectProperty(org, path);
				if (value && Ext.isObject(value)) {
					edi.utils.setObjectProperty(org, path, Object.values(value));
				}
			});

			const foreignData = org?.idSv?.svInNeUch;
			if (foreignData?.kodStr && !foreignData?.naimStran) {
				const record = edi.stores.initCountryFullStore().findRecord('iso_number_3', foreignData?.kodStr);
				if (record) edi.utils.setObjectProperty(org, 'idSv.svInNeUch.naimStran', record.get('name'));
			}

			const adrGAR = org?.adres?.adrGAR;
			if (adrGAR?.region && !adrGAR?.naimRegion) {
				const record = edi.stores.initRegionsStore().findRecord('id', adrGAR?.region);
				if (record) edi.utils.setObjectProperty(adrGAR, 'naimRegion', record.get('name'));
			}

			const { isIP, isUL, isInNeUch, isFL } = me.getOrgType(org);
			if (!isIP && org?.idSv?.svIP) edi.utils.setObjectProperty(org, 'idSv.svIP', null);
			if (!isUL && org?.idSv?.svYULUch) edi.utils.setObjectProperty(org, 'idSv.svYULUch', null);
			if (!isInNeUch && org?.idSv?.svInNeUch) edi.utils.setObjectProperty(org, 'idSv.svInNeUch', null);
			if (!isFL && org?.idSv?.svFLUch) edi.utils.setObjectProperty(org, 'idSv.svFLUch', null);
		});

		if (Array.isArray(cloneValues.dokument.svSchFakt.dopSvFHZH1?.vidObyaz)) {
			cloneValues.dokument.svSchFakt.dopSvFHZH1.vidObyaz =
				cloneValues.dokument.svSchFakt.dopSvFHZH1?.vidObyaz?.map((kodVidObyaz: string) => ({
					kodVidObyaz,
					naimVidObyaz: powerLimits?.find((powerLimit) => powerLimit.CODE_VALUE === kodVidObyaz)?.NAME_VALUE
				}));
		}

		if (!cloneValues.dokument.svProdPer.svPer.osnPer?.length) {
			edi.utils.setObjectProperty(cloneValues, 'dokument.svProdPer.svPer.bezDokOsnPer', '1');
		}

		const productValues = Ext.clone(me.productsGrid.getValues());

		productValues.products = productValues.products.map((product: SvedTov) => {
			edi.utils.setObjectProperty(
				product,
				'dopSvedTov.krNaimStrPr',
				product?.svDT
					?.map(({ kodProish }) => edi.utils.getCountryFULLByISO(null, null, null, kodProish)?.name)
					.filter(Boolean)
					.join(',')
			);
			return product;
		});
		const sumNalVsego = productValues.products.some(
			(product: SvedTov) => 'sumNal' in product.sumNal && !!product.sumNal.sumNal
		)
			? {
					sumNal: productValues.sumNalVsego
			  }
			: {
					bezNDS: edi.constants.taxRates.taxNoVat
			  };

		const buildValues: { dokument: RecursivePartial<Dokument> } = {
			dokument: {
				svSchFakt: {
					denIzm: {
						naimOKV: me.mainDataBlockInstance?.currencyName,
						kursVal: undefined
					},
					svPRD: edi.methods.convertValuesFromMultipleFields(
						edi.utils.getObjectProperty(cloneValues, 'dokument.svSchFakt.svPRD')
					),
					svProd: [cloneValues.dokument.svSchFakt.svProd],
					svPokup: [cloneValues.dokument.svSchFakt.svPokup],
					gruzPoluch: [cloneValues.dokument.svSchFakt.gruzPoluch],
					gruzOt: [cloneValues.dokument.svSchFakt.gruzOt],
					infPolFHZH1: {
						tekstInf: edi.methods
							.convertValuesFromMultipleFields(
								edi.utils.getObjectProperty(cloneValues, 'dokument.svSchFakt.infPolFHZH1.tekstInf')
							)
							.filter(
								(textInf: { identif: string; znachen: string }) =>
									!!textInf.identif && !!textInf.znachen
							)
					}
				},
				svProdPer: {
					infPolFHZH3: {
						tekstInf: edi.methods
							.convertValuesFromMultipleFields(
								edi.utils.getObjectProperty(cloneValues, 'dokument.svProdPer.infPolFHZH3.tekstInf')
							)
							.filter(
								(textInf: { identif: string; znachen: string }) =>
									!!textInf.identif && !!textInf.znachen
							)
					}
				},
				tablSchFakt: {
					svedTov: productValues.products,
					vsegoOpl: {
						sumNalVsego,
						stTovBezNDSVsego: productValues.stTovBezNDSVsego,
						stTovUchNalVsego: productValues.stTovUchNalVsego,
						kolNettoVs: productValues.kolNettoVs
					}
				},
				podpisant: edi.methods.convertValuesFromMultipleFields(
					edi.utils.getObjectProperty(cloneValues, 'podpisant')
				),
				funktsiya: edi.i18n.getMessage('documents.fns_upd.type.' + me.bpName)
			}
		};

		if (!isSCHF_BusinessProcess(me.bpName)) {
			buildValues.dokument.poFaktHZH = edi.i18n.getMessage('documents.fns_upd.economiclifeDocName.value');
			buildValues.dokument.naimDokOpr = edi.i18n.getMessage('documents.fns_upd.primaryDocName.' + me.bpName);
		}

		return Ext.Object.merge({}, { dokument: cloneValues.dokument }, buildValues);
	}

	buildHeaderData({ documentData }: { documentData: AnyObject }): AnyObject {
		const me = this;

		const dokument = documentData.dokument;
		const bpNameToCreate = edi.constants.FNS_BPNAMES_CREATE_MAP[me.bpName] ?? me.bpName;
		const headerData: AnyObject =
			me.isEdit && !me.isCreate
				? {
						data: Ext.encode(documentData),
						number: edi.utils.getObjectProperty(dokument, 'svSchFakt.nomerDok'),
						date: edi.utils.getObjectProperty(dokument, 'svSchFakt.dataDok'),
						agreement: null,
						bpName: me.bpName,
						dateFormat: edi.constants.DATE_FORMAT.FNS,
						versionId: me.version
				  }
				: {
						data: Ext.encode(documentData),
						number: edi.utils.getObjectProperty(dokument, 'svSchFakt.nomerDok'),
						date: me.isCorrect
							? edi.utils.getObjectProperty(dokument, 'svSchFakt.isprDok.dataIspr')
							: edi.utils.getObjectProperty(dokument, 'svSchFakt.dataDok'),
						bpName: bpNameToCreate,
						dateFormat: edi.constants.DATE_FORMAT.FNS,
						versionId: me.version
				  };
		return headerData;
	}

	saveDocument(document: AnyObject) {
		const me = this;
		const doc = document.dokument;

		const success = function (data: ModuleData['initData']) {
			if (data && data.data && data.data.id) {
				me.parentData = data;
			}
			const afterSaveHandler = edi.document.actions.createSaveSuccessHandler(
				me.moduleData,
				me.isEdit ? me.parentId : undefined,
				undefined,
				me.isEdit && !me.isCreate
			);

			afterSaveHandler(me.parentData);

			if (typeof me.moduleData.initData?.packageOptions?.afterCreate === 'function' && data.data.id) {
				me.moduleData.initData.packageOptions.afterCreate(data.data.id);
			}
		};
		const failure = edi.document.actions.createSaveErrorHandler(
			me.isEdit,
			me.moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
			function () {}
		);

		const headerData = me.buildHeaderData({ documentData: document });

		if (me.isEdit && !me.isCreate) {
			edi.document.actions.processDocument(
				me.buyerOrg?.id,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
				me.parentId,
				me.moduleData.initData.data.id,
				success,
				failure,
				headerData
			);
		} else {
			if (me.isNumberAutoGenerated) {
				headerData.autogen = true;
			} else {
				headerData.number = edi.utils.getObjectProperty(doc, 'invoice.invoiceNumber');
			}

			edi.document.actions.processDocument(
				me.buyerOrg?.id,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
				!me.isCopy ? me.parentId : undefined,
				undefined,
				success,
				failure,
				headerData,
				{
					postData: {
						containerDocType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
					},
					createUri: edi.rest.services.DOCUMENTS.WITH_CONTAINER.POST
				}
			);
		}
	}

	async buildForm() {
		const me = this;
		let modulePanel = createAddModulePanel() as ExtComponent;

		const form = me.createModuleForm();

		modulePanel.add(form);
		me.moduleData.tab.removeAll();
		me.moduleData.tab.add(modulePanel);
		await me.preselectBuyer();
		me.moduleData.tab.setLoading(false);
	}

	preselectBuyer(): Promise<void> {
		const me = this;
		return new Promise((resolve) => {
			const preselectedReceiverIdFromPackage = me.moduleData.initData?.packageOptions?.receiverOrgId;
			if (preselectedReceiverIdFromPackage) {
				const buyerOrg = edi.utils.getOrg({
					orgId: preselectedReceiverIdFromPackage
				});
				if (buyerOrg && me.buyer) {
					me.buyer.setOrganization(buyerOrg);
					me.buyer.selectBtn?.setVisible(false);
				}
				resolve();
			} else {
				me.buyer.presetFromRelation(function () {
					if (me.buyer.selectedOrg) {
						me.getOrganizationAdditionalFields(me.buyer.selectedOrg.id);
					}
					resolve();
				});
			}
		});
	}

	fillUPDSignerValues() {
		return new Promise<void>((resolve) => {
			const me = this;

			me.signers = Ext.clone(me.documentContent?.dokument?.podpisant) ?? [];

			const signer: AnyObject = me.signers[0] ?? {};

			edi.methods.fillUPDSignerValues(
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				signer,
				edi.converters.convertOrgToPartie(me.userData.org),
				(fieldsFillFromCert: DocumentCreateFns_upd_5_02n['CertificateData']) => {
					const fio =
						signer.legalEntity?.fullName ?? signer.individualPerson?.name ?? signer.individual?.name;
					me.signers[0] = {
						...signer,
						fio: {
							familiya: fio.lastName,
							imya: fio.firstName,
							otchestvo: fio.middleName
						},
						dolzhn: signer.dolzhn || signer.legalEntity?.position,
						sposPodtPolnom: signer.sposPodtPolnom
					};
					me.signerFillFromCert = fieldsFillFromCert as CertificateData;

					resolve();
				},
				null,
				null,
				!me.isEdit
			);
		});
	}

	failure() {
		const me = this;

		return edi.document.actions.defaultFailureHandler(me.moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(me.moduleData);
		});
	}

	defaultFailureHandler(data: SendRequestResponse) {
		const me = this;
		const defaultFailure = edi.document.actions.defaultFailureHandler(
			me.moduleData.tab,
			'error.getting.data',
			function () {
				edi.modulesHandler.removeModule(me.moduleData);
			}
		);
		defaultFailure(data);
	}

	async getDocNumberSettings(): Promise<{ success: boolean }> {
		const me = this;

		if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
			const { success, data } = await edi.rest.asyncSendRequest({
				url: edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
					doctype: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
				})
			});

			if (success) {
				const docNumberSettings = data.data;
				if (docNumberSettings) {
					me.isNumberAutoGenerated = 'ALLOW' === docNumberSettings.policy;
				}
				return { success: true };
			} else {
				me.defaultFailureHandler(data);
				return { success: false };
			}
		} else {
			return { success: true };
		}
	}

	async getDocContent({ docId }: { docId: number }): Promise<{ success: boolean }> {
		const me = this;

		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
				documentId: docId
			})
		});

		if (success) {
			if (data && data.data) {
				me.documentContent = data.data;
				// в заголовке сейчас не всегда есть данные об ИУПД, поэтому проверяем ещё и тут
				me.isCorrect = !!edi.utils.getObjectProperty(me.documentContent, 'dokument.svSchFakt.isprDok.nomIspr');
				me.initialData = data.data;
			}
		} else {
			me.defaultFailureHandler(data);
		}

		return { success };
	}

	async getLinkedDocs(): Promise<{ success: boolean; response?: { part1ID: number } }> {
		const me = this;

		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
				documentId: me.parentId
			})
		});

		let part1ID;
		if (success) {
			if (data?.data?.children?.length) {
				for (let i = 0; i < data.data.children.length; i++) {
					if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
						me.documentHeader = data.data.children[i];
						part1ID = data.data.children[i].id;
						break;
					}
				}
			}
			return { success: true, response: { part1ID } };
		} else {
			me.defaultFailureHandler(data);
		}

		return { success };
	}

	async loadData() {
		const me = this;

		const { success: isLinkedDocsLoaded, response } = await me.getLinkedDocs();
		const part1ID = response?.part1ID;
		if (!isLinkedDocsLoaded || !part1ID) return;

		const { success: isDocContentLoaded } = await me.getDocContent({ docId: part1ID });
		if (!isDocContentLoaded) return;

		await me.fillUPDSignerValues();
		await me.buildForm();
	}

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	async renderData(initCallBack: Function) {
		const me = this;

		const data = me.moduleData.initData.data;

		if (data && data.id) {
			me.isCopy = !!me.moduleData.initData.isCopy;
			me.isEdit = !me.isCopy;

			me.parentData = me.moduleData.initData;
			me.parentId = data.id;

			me.buyerOrg = data.toOrg;
			me.contract = data.agreement;
			me.bpName = edi.utils.getAttributeByName(me.moduleData.initData.data.attributes, 'bpName');
			me.taxCalculationMethod = edi.utils.getAttributeByName(me.buyerOrg?.attributes, 'taxCalculationMethod');
			if (!me.taxCalculationMethod || me.taxCalculationMethod === 'null') {
				me.taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
			}
			me.getAuthorityAreasByBP();
			me.isSCHFSelected = isSCHF_BusinessProcess(me.bpName);
			await me.loadData();
		} else {
			const { success: isDocNumberSettingsLoaded } = await me.getDocNumberSettings();
			if (isDocNumberSettingsLoaded) {
				await me.createModulePanel();
			}
		}
		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	}

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy() {
		const me = this;

		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

Ext.namespace('edi.modules');
edi.modules['document.create.fns_upd_5_02n'] = DocumentCreateFns_upd_5_02n;
