// import { createButton } from "@Components/buttons";
import { createLabel, createEmailField } from '@Components/fields';
import { createContainer } from '@Components/miscComponents';
import { createForm, createPanel } from '@Components/panels';

import { createTextField, createCombo, createNumberField, createDateField } from '@UIkit/components/fields/index.js';
import { createFieldBlock } from '@UIkit/components/panels/FieldBlock/FieldBlock';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

const createOrgDetails = function () {
	let canEdit = edi.permissions.hasPermission('CLIENT_ORGANIZATION_DETAILS_EDIT');
	// let canEdit = false;

	let renderForm = function (orgData) {
		let fnsIdField;

		let label = createLabel({
			margin: '8 0 0 0',
			typography: 'heading_02',
			text: edi.utils.getObjectProperty(orgData, 'name')
		});

		let regOrgData = createFieldBlock({
			margin: '32 0 0 0',
			title: edi.i18n.getMessage('company.tab.registry.data'),
			layout: {
				type: 'grid',
				area: [
					[2, 2],
					[4, 1]
				],
				gap: [24, 16]
			},
			items: [
				createNumberField({
					fieldLabel: edi.i18n.getMessage('company.inn.short'),
					name: 'inn',
					value: edi.utils.getObjectProperty(orgData, 'orgData.inn'),
					readOnly: true
				}),
				createNumberField({
					fieldLabel: edi.i18n.getMessage('company.kpp.short'),
					name: 'kpp',
					value: edi.utils.getObjectProperty(orgData, 'orgData.kpp'),
					readOnly: true
				}),
				(fnsIdField = createTextField({
					fieldLabel: edi.i18n.getMessage('company.fns.id'),
					name: 'fnsId',
					value: edi.utils.getObjectProperty(orgData, 'orgData.fnsId'),
					readOnly: true
				})),
				createContainer({
					items: [
						createButton({
							cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
							text: edi.i18n.getMessage('document.copy.document'),
							glyph: edi.constants.ICONS.COPY,
							handler: () => {},
							listeners: {
								afterrender: function (cmp) {
									let fnsIdInput = formPanel.down('textfield[name=fnsId]').getEl().down('input').dom;
									let copyContent = function () {
										fnsIdInput.select();
										document.execCommand('copy');
										fnsIdInput.blur();
									};
									cmp.el.dom.addEventListener('click', copyContent);
								}
							}
						})
					]
				})
			]
		});

		let regIPData = createFieldBlock({
			title: edi.i18n.getMessage('company.tab.registry.data'),
			margin: '32 0 0 0',
			layout: {
				type: 'grid',
				area: [
					[2, 2],
					[3, 3, 3],
					[4, 1]
				],
				gap: [24, 16]
			},
			items: [
				createNumberField({
					fieldLabel: edi.i18n.getMessage('company.inn.short'),
					name: 'inn',
					value: edi.utils.getObjectProperty(orgData, 'orgData.inn'),
					readOnly: true
				}),
				createNumberField({
					fieldLabel: edi.i18n.getMessage('company.ogrnip.short'),
					name: 'regNr',
					value: edi.utils.getObjectProperty(orgData, 'orgData.regNr'),
					readOnly: true,
					inputMask: edi.constants.FIELD_MASK.OGRN
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('user.profile.last.name'),
					name: 'lastName',
					value: edi.utils.getObjectProperty(orgData, 'orgData.individualOrgAdditionalData.lastName'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('user.profile.first.name'),
					name: 'firstName',
					value: edi.utils.getObjectProperty(orgData, 'orgData.individualOrgAdditionalData.firstName'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('user.profile.patronymic.name'),
					name: 'middleName',
					value: edi.utils.getObjectProperty(orgData, 'orgData.individualOrgAdditionalData.middleName'),
					readOnly: true
				}),
				(fnsIdField = createTextField({
					fieldLabel: edi.i18n.getMessage('company.fns.id'),
					name: 'fnsId',
					value: edi.utils.getObjectProperty(orgData, 'orgData.fnsId'),
					readOnly: true
				})),
				createButton({
					cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
					text: edi.i18n.getMessage('document.copy.document'),
					glyph: edi.constants.ICONS.COPY,
					handler: () => {},
					listeners: {
						afterrender: function (cmp) {
							let fnsIdInput = formPanel.down('textfield[name=fnsId]').getEl().down('input').dom;
							let copyContent = function () {
								fnsIdInput.select();
								document.execCommand('copy');
								fnsIdInput.blur();
							};
							cmp.el.dom.addEventListener('click', copyContent);
						}
					}
				})
			]
		});
		let snilsData = edi.utils.getObjectProperty(orgData, 'orgData.personalOrgAdditionalData.snils');
		let regPersonData = createFieldBlock({
			title: edi.i18n.getMessage('company.tab.registry.data'),
			margin: '32 0 0 0',
			layout: {
				type: 'grid',
				area: [[2, 2], [3, 3, 3], 2, [4, 1]],
				gap: [24, 16]
			},
			items: [
				createNumberField({
					fieldLabel: edi.i18n.getMessage('company.inn.short'),
					name: 'inn',
					value: edi.utils.getObjectProperty(orgData, 'orgData.inn'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('snils.code'),
					name: 'snils',
					value: edi.converters.SNILS.convertForShow(snilsData),
					regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
					regexText: edi.i18n.getMessage('invalid.snils.format'),
					inputMask: edi.constants.FIELD_MASK.SNILS,
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('user.profile.last.name'),
					name: 'lastName',
					value: edi.utils.getObjectProperty(orgData, 'orgData.personalOrgAdditionalData.lastName'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('user.profile.first.name'),
					name: 'firstName',
					value: edi.utils.getObjectProperty(orgData, 'orgData.personalOrgAdditionalData.firstName'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('user.profile.patronymic.name'),
					name: 'middleName',
					value: edi.utils.getObjectProperty(orgData, 'orgData.personalOrgAdditionalData.middleName'),
					readOnly: true
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('birth.date'),
					name: 'dateOfBirth',
					value: edi.utils.getObjectProperty(orgData, 'orgData.personalOrgAdditionalData.dateOfBirth'),
					readOnly: true
				}),
				(fnsIdField = createTextField({
					fieldLabel: edi.i18n.getMessage('company.fns.id'),
					name: 'fnsId',
					value: edi.utils.getObjectProperty(orgData, 'orgData.fnsId'),
					readOnly: true
				})),
				createButton({
					cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
					text: edi.i18n.getMessage('document.copy.document'),
					glyph: edi.constants.ICONS.COPY,
					handler: () => {},
					listeners: {
						afterrender: function (cmp) {
							let fnsIdInput = formPanel.down('textfield[name=fnsId]').getEl().down('input').dom;
							let copyContent = function () {
								fnsIdInput.select();
								document.execCommand('copy');
								fnsIdInput.blur();
							};
							cmp.el.dom.addEventListener('click', copyContent);
						}
					}
				})
			]
		});

		let addressData = null;
		if (orgData && orgData.addresses && Array.isArray(orgData.addresses)) {
			addressData = orgData.addresses.find(function (address) {
				const type = address?.type ?? null;
				return type === edi.constants.ADDRESS_TYPES.LEGAL;
			});
			!addressData && orgData.addresses.length ? (addressData = orgData.addresses[0]) : null;
		}

		let regionField = createCombo({
			fieldLabel: edi.i18n.getMessage('company.region'),
			name: 'addr_region_code',
			displayField: 'display_name',
			store: 'initRegionsStore',
			value: edi.utils.getObjectProperty(addressData, 'regionCode'),
			readOnly: true
		});
		if (edi.utils.getObjectProperty(orgData, 'country') !== 'RUS') {
			regionField = createTextField({
				fieldLabel: edi.i18n.getMessage('company.region'),
				name: 'addr_region_code',
				value: edi.utils.getObjectProperty(addressData, 'regionType'),
				readOnly: true
			});
		}

		let address = createFieldBlock({
			title: edi.i18n.getMessage('company.address'),
			margin: '32 0 0 0',
			layout: {
				type: 'grid',
				area: [2, [2, 4], 3, 3, 3, [3, 2, 2, 2]],
				gap: [24, 16]
			},
			items: [
				createCombo({
					fieldLabel: edi.i18n.getMessage('company.address.type.selector'),
					store: edi.stores.createInlineStore(
						[
							{
								id: 0,
								name: edi.i18n.getMessage('company.address.type.rus')
							},
							{
								id: 1,
								name: edi.i18n.getMessage('company.address.type.foreign')
							}
						],
						'SIMPLE'
					),
					name: 'address_type',
					emptyText: '',
					value: edi.utils.getObjectProperty(orgData, 'country') === 'RUS' ? 0 : 1,
					readOnly: true
				}),
				createNumberField({
					fieldLabel: edi.i18n.getMessage('company.zip'),
					name: 'addr_zip',
					regex: edi.constants.VALIDATORS.ZIP,
					maxLength: 6,
					value: edi.utils.getObjectProperty(addressData, 'zip'),
					readOnly: true
				}),
				regionField,
				createTextField({
					fieldLabel: edi.i18n.getMessage('company.district'),
					name: 'addr_area',
					value: edi.utils.getObjectProperty(addressData, 'area'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('company.city'),
					name: 'addr_city',
					value: edi.utils.getObjectProperty(addressData, 'city'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('company.community'),
					name: 'addr_locality',
					value: edi.utils.getObjectProperty(addressData, 'locality'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('company.street'),
					name: 'addr_street',
					value: edi.utils.getObjectProperty(addressData, 'street'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('company.home'),
					name: 'addr_home',
					value: edi.utils.getObjectProperty(addressData, 'building'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('company.address.block'),
					name: 'addr_block',
					value: edi.utils.getObjectProperty(addressData, 'block'),
					readOnly: true
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('ediweb.company.flat'),
					name: 'addr_flat',
					value: edi.utils.getObjectProperty(addressData, 'flat'),
					readOnly: true
				})
			]
		});

		let getOrgPriorityAccount = function (accounts) {
			let acc = accounts.filter((item) => item.priority === true || item.priority === 'true');
			if (!acc.length) {
				acc = accounts.sort((a, b) => (a.modifyDate > b.modifyDate ? -1 : 1));
			}
			return acc[0];
		};

		let bankAccountData = getOrgPriorityAccount(orgData?.accounts || []);
		let number, corrAccNumber, bik, preferredOwnerName;

		let validBankAccForm = function () {
			let isValid = number.getValue() || corrAccNumber.getValue() || bik.getValue();

			number.allowBlank = !isValid;
			corrAccNumber.allowBlank = !isValid;
			bik.allowBlank = !isValid;

			formPanel.isValid();
		};

		let bankAccount = createFieldBlock({
			title: edi.i18n.getMessage('company.tab.bank.data'),
			margin: '32 0 0 0',
			layout: {
				type: 'grid',
				area: [[3, 3, 2], 8],
				gap: [24, 16]
			},
			items: [
				(number = createTextField({
					fieldLabel: edi.i18n.getMessage('company.bank.account'),
					name: 'bankAccount.number',
					value: edi.utils.getObjectProperty(bankAccountData, 'number'),
					maskRe: /\d/i,
					minLength: 20,
					maxLength: 20,
					readOnly: !canEdit,
					listeners: {
						change: validBankAccForm
					}
				})),
				(corrAccNumber = createTextField({
					fieldLabel: edi.i18n.getMessage('company.bank.account.corr'),
					name: 'bankAccount.corrAccNumber',
					value: edi.utils.getObjectProperty(bankAccountData, 'corrAccNumber'),
					maskRe: /\d/i,
					minLength: 20,
					maxLength: 20,
					readOnly: !canEdit,
					listeners: {
						change: validBankAccForm
					}
				})),
				(bik = createTextField({
					fieldLabel: edi.i18n.getMessage('column.bik'),
					name: 'bankAccount.bik',
					value: edi.utils.getObjectProperty(bankAccountData, 'bik'),
					maskRe: /\d/i,
					minLength: 9,
					maxLength: 9,
					readOnly: !canEdit,
					listeners: {
						change: validBankAccForm
					}
				})),
				(preferredOwnerName = createTextField({
					fieldLabel: edi.i18n.getMessage('company.bank.name'),
					name: 'bankAccount.preferredOwnerName',
					value: edi.utils.getObjectProperty(bankAccountData, 'preferredOwnerName'),
					readOnly: !canEdit
				}))
			]
		});

		let contacts = createFieldBlock({
			title: edi.i18n.getMessage('company.bank.contacts'),
			margin: '32 0 0 0',
			layout: {
				type: 'grid',
				area: [[3, 3], 4],
				gap: [24, 16]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('company.contact.phone'),
					name: 'contact_phone',
					value: edi.utils.getObjectProperty(orgData, 'phones'),
					inputMask: edi.constants.FIELD_MASK.PHONE,
					regex: edi.constants.VALIDATORS.PHONE,
					invalidText: edi.i18n.getMessage('ediweb.invalid.phone.format'),
					readOnly: !canEdit
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('company.contact.fax'),
					name: 'contact_fax',
					value: edi.utils.getObjectProperty(orgData, 'faxes'),
					maxLength: 12,
					maskRe: /\d/i,
					readOnly: !canEdit
				}),
				createEmailField({
					fieldLabel: edi.i18n.getMessage('company.contact.email'),
					name: 'contact_email',
					value: edi.utils.getObjectProperty(orgData, 'email'),
					readOnly: !canEdit
				})
			]
		});

		let saveBtn = createButton({
			cls: [BUTTON_CLS.primary],
			text: edi.i18n.getMessage('form.btn.save'),
			handler: function () {
				let form = formPanel.getForm();
				if (!edi.utils.setFocusToDocumentsWithGrid(formPanel)) return;

				let values = edi.utils.collectFormValues(form);
				let { number, corrAccNumber, bik, preferredOwnerName } = values.bankAccount;

				let data = {
					bankAccount: {
						number,
						corrAccNumber,
						bik,
						preferredOwnerName
					},
					phones: values?.contact_phone,
					faxes: values?.contact_fax,
					email: values?.contact_email
				};
				edi.utils.clearEmptyValues(data);

				panel.setLoading();

				edi.rest.sendRequest(
					edi.rest.services.USER.ORGANIZATION.DETAILS.PUT,
					'PUT',
					Ext.encode(data),
					function () {
						edi.core.showInfo('setting.save.success', () => {
							panel.setLoading(false);
							update();
						});
					},
					function (err) {
						edi.core.showError(err.errorMessage, () => {
							panel.setLoading(false);
						});
					}
				);
			},
			disabled: !canEdit
		});

		let formPanel = createForm({
			padding: '24 24 0 24',
			// width: 1200,
			items: [
				label,
				orgData.orgType === edi.constants.ORGANIZATION_TYPES.LEGAL_ENTITY ? regOrgData : null,
				orgData.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL ? regIPData : null,
				orgData.orgType === edi.constants.ORGANIZATION_TYPES.PERSON ? regPersonData : null,
				address,
				bankAccount,
				contacts
			],
			buttonAlign: 'left',
			buttons: [saveBtn]
		});

		return formPanel;
	};

	let panel = createPanel({
		cls: 'module-panel',
		autoScroll: true,
		tabTitle: 'company.details'
	});

	let update = function () {
		edi.rest.sendRequest(edi.rest.services.USER.ORGANIZATION.GET, 'GET', null, function (responsiveData) {
			if (responsiveData && responsiveData.data) {
				panel.removeAll();
				panel.add(renderForm(responsiveData.data));
			}
		});
	};

	update();

	return panel;
};

export { createOrgDetails };
