import { NomSredIdentTov, SvDT, SvedTov } from './definitions/producLineDef';
import { TekstInf } from './definitions/commonsDef';
import { UPD502productIdentifyRecordData } from './definitions/records';

const UPD_5_02_DECLARATION_INFO_COLUMN_CONFIG_NAME = 'upd_5_02_declaration_info';
const UPD_5_02_IDENTITY_PRODUCT_TOOL_NUMBERS_COLUMN_CONFIG_NAME = 'fns_upd_5_02_line_identifyProductToolNumbers';
const UPD_5_02_LINES_COLUMN_CONFIG_NAME = 'fns_upd_5_02_lines';
const UPD_5_02_SUMMARY_COLUMN_CONFIG_NAME = 'fns_upd_5_02_summary_editable';

edi.columns.addColumns({
	[UPD_5_02_LINES_COLUMN_CONFIG_NAME]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'nomStr',
			flex: 0.5
		},
		productName: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'naimTov',
			flex: 2
		},
		count: {
			text: 'column.invoice.quantity',
			dataIndex: 'kolTov',
			flex: 0.75,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 3,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 1
			},
			renderer: 'decimal'
		},
		okei: {
			text: 'column.unit.of.measure',
			dataIndex: 'okeiTov',
			flex: 0.75,
			hidden: true,
			renderer: 'okei'
		},
		presenceGDT: {
			text: 'column.fns.presence.gdt',
			dataIndex: 'svDT',
			flex: 0.75,
			hidden: true,
			renderer: function (value: SvDT[], column: AnyObject) {
				if (value?.length > 0) {
					const numbersGTD = value.map((item) => item.nomerDT);
					column.tdAttr = 'data-qtip="' + numbersGTD.join('<br/>') + '"';
					column.tdAttr += ` data-qtipFormatted="true"`;
					return edi.i18n.getMessage('answer.exist');
				} else {
					return edi.i18n.getMessage('answer.no');
				}
			}
		},
		price: {
			text: 'column.bill.unit.net.price',
			dataIndex: 'tsenaTov',
			flex: 0.75,
			renderer: (value: string) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		unitGrossPrice: {
			text: 'pricat.product.unitGrossPrice',
			flex: 0.75,
			hidden: true,
			renderer: (value: string | undefined, meta: AnyObject, record: ExtRecord<SvedTov>): number | undefined => {
				const textInfos = record.get('infPolFHZH2') as SvedTov['infPolFHZH2'];
				if (!textInfos) return;

				const textInfosUnitGrossPriceIdentifier = 'цена_с_ндс';
				const textInfosUnitGrossPrice = textInfos.find(
					(textInfo: TekstInf) => textInfo.identif === textInfosUnitGrossPriceIdentifier && textInfo.znachen
				);
				return edi.utils.displayCurrencyDecimals(
					textInfosUnitGrossPrice?.znachen,
					edi.constants.UKD_PRICE_PRECISION
				);
			}
		},
		taxSum: {
			text: 'column.tax.amount',
			dataIndex: 'sumNal',
			order: 7,
			flex: 0.75,
			renderer: function (value: { sumNal: string }) {
				return edi.renderers.currencyWithSeparators(edi.utils.displayCurrencyDecimals(value?.sumNal || 0, 2));
			}
		},
		tax: {
			text: 'column.fns.tax.rate',
			dataIndex: 'nalSt',
			order: 7,
			flex: 0.75
		},
		excise: {
			text: 'line.upd.excise',
			dataIndex: 'aktsiz',
			flex: 0.75,
			renderer: function (value: { sumAktsiz: number | null; bezAktsiz: 'без акциза' | null }) {
				return typeof value.sumAktsiz === 'number'
					? edi.utils.displayCurrencyDecimals(value.sumAktsiz, edi.constants.UPD_PRICE_PRECISION)
					: value.bezAktsiz
					? value.bezAktsiz
					: 'без акциза';
			}
		},
		costWithoutNDS: {
			text: 'column.net.amount',
			dataIndex: 'stTovBezNDS',
			flex: 0.75,
			renderer: (value: string) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		costWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'stTovUchNal',
			flex: 0.75,
			renderer: (value: string) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		}
	},
	[UPD_5_02_DECLARATION_INFO_COLUMN_CONFIG_NAME]: {
		countryCode: {
			text: 'line.item.country.of.origin',
			dataIndex: 'kodProish',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: function (value: string) {
				const countryStore = edi.stores.initValidCountryFullStore(),
					country = countryStore.findRecord('iso_number_3', value, 0, false, true, true);

				return country ? country.get('display_name') : value;
			}
		},
		number: {
			text: 'column.nomer.td',
			dataIndex: 'nomerDT',
			flex: 1,
			sortable: false,
			hideable: false
		}
	},
	[UPD_5_02_IDENTITY_PRODUCT_TOOL_NUMBERS_COLUMN_CONFIG_NAME]: {
		packId: {
			text: 'line.item.identifyProductToolNumbers.packId',
			dataIndex: 'identTransUpak',
			renderer: 'desadvLineEAN',
			flex: 1.5
		},
		type: {
			text: 'line.item.identifyProductToolNumbers.type',
			dataIndex: 'type',
			renderer: function (type: string, metaData: AnyObject) {
				if (!type) return;
				const retVal = edi.i18n.getMessage('line.item.identifyProductToolNumbers.' + type);
				metaData.tdAttr = 'data-qtip="' + edi.utils.safeString(retVal) + '"';
				return retVal;
			},
			flex: 1
		},
		numbers: {
			text: 'line.item.identifyProductToolNumbers.numbers',
			dataIndex: 'numbers',
			renderer: function (
				value: UPD502productIdentifyRecordData['numbers'],
				metaData: AnyObject,
				record: ExtRecord<UPD502productIdentifyRecordData>
			) {
				const data = record.get('numbers') as UPD502productIdentifyRecordData['numbers'];
				let retVal = '';
				if (data && data.length) {
					retVal = data.join(', ');
				}
				metaData.tdAttr = 'data-qtip="' + edi.utils.safeString(retVal) + '"';
				return retVal;
			},
			flex: 2
		},
		purchaseQuantity: {
			text: 'upd.line.item.marked.product.count',
			dataIndex: 'kolVedMark',
			hidden: true
		},
		batchCode: {
			text: 'line.item.additional.batchCode',
			dataIndex: 'prPartMark',
			hidden: true
		}
	},
	[UPD_5_02_SUMMARY_COLUMN_CONFIG_NAME]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'nomStrVsego',
			flex: 1,
			sortable: false,
			hideable: false
		},
		totalAmount: {
			text: 'column.total.amount',
			dataIndex: 'kolNettoVs',
			menuDisabled: true,
			order: 2,
			flex: 1,
			renderer: 'decimal'
		},
		totalWithoutNDS: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'stTovBezNDSVsego',
			flex: 1,
			sortable: false,
			hideable: false,
			tdCls: 'editable',
			renderer: (value: number) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION),
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount14',
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0
			}
		},
		mustPaid: {
			text: 'line.item.tax.amount',
			dataIndex: 'sumNalVsego',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: (value: number) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION),
			editor: {
				xtype: 'ui-numberfield',
				validator: function (value: number) {
					return value >= 0 && String(value).length <= 14 ? true : edi.i18n.getMessage('invalid.field.value');
				},
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0
			}
		},
		totalWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'stTovUchNalVsego',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: (value: number) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION),
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount14',
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0
			}
		}
	}
});

const getUPDdeclarationInfoColumns = () => edi.columns.get(UPD_5_02_DECLARATION_INFO_COLUMN_CONFIG_NAME);

export {
	getUPDdeclarationInfoColumns,
	UPD_5_02_LINES_COLUMN_CONFIG_NAME,
	UPD_5_02_DECLARATION_INFO_COLUMN_CONFIG_NAME,
	UPD_5_02_IDENTITY_PRODUCT_TOOL_NUMBERS_COLUMN_CONFIG_NAME,
	UPD_5_02_SUMMARY_COLUMN_CONFIG_NAME
};
