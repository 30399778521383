import { EWDocumentDetailsFns_upd_5_03nModule } from '@Ediweb/modules/EDI_FNS_UPD/document.details.fns_upd_5_03n';
import { UpdOrgSelectors } from '@Fns/modules/documents/EDI_FNS_UPD/5_02N/selectors';

export class ApprovalDocumentDetailsFns_upd_5_03nModule extends EWDocumentDetailsFns_upd_5_03nModule {
	constructor(part1, part2) {
		super();
		this.part1 = part1;
		this.part2 = part2;
		this.part2Head = {};
		this.part1Head = {};
		this.selectorConfigsBuilder = new UpdOrgSelectors();
		this.documentContent = part1;
	}

	createModuleHeadPanel() {}

	getPartsData(ids, documentHeaderData, initCallBack) {}
}
