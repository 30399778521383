/**
 * Requests configuration for edi
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.rest');
Ext.merge(edi.rest.services, {
	USER: {
		CERTIFICATE: {
			EXPIRING_LIST: {
				GET: edi.rest.prfx.c + 'user/certificate/expiring'
			}
		},
		POA: {
			LINK_BY_CERT: {
				POST: edi.rest.prfx.c + 'user/certificate/{id}/link/poa/{poaId}'
			},
			DELETE: edi.rest.prfx.c + 'user/certificate/{id}/link/poa/{poaId}'
		}
	},
	NOTIFICATIONS: {
		GET: edi.rest.prfx.c + 'notification',
		DETAILS: {
			GET: edi.rest.prfx.c + 'notification/{id}'
		},
		READ: {
			PUT: edi.rest.prfx.c + 'notification/read/{id}',
			RANGE: {
				PUT: edi.rest.prfx.c + 'notification/read'
			},
			ALL: {
				PUT: edi.rest.prfx.c + 'notification/read/all'
			}
		},
		COUNT: {
			GET: edi.rest.prfx.c + 'notification/count'
		},
		MAIL: {
			POST: edi.rest.prfx.c + 'user/mail/notification',
			PUT: edi.rest.prfx.c + 'user/mail/notification',
			GET: edi.rest.prfx.c + 'user/mail/notification'
		}
	},
	USER_EMAIL_NOTIFICATIONS_V2: {
		GET: edi.rest.prfx.c + 'user/notification/mail/v2/{notificationId}',
		POST: edi.rest.prfx.c + 'user/notification/mail/v2/',
		PUT: edi.rest.prfx.c + 'user/notification/mail/v2/{notificationId}',
		DELETE: edi.rest.prfx.c + 'user/notification/mail/v2/{notificationId}',
		LIST: {
			GET: edi.rest.prfx.c + 'user/notification/mail/v2/'
		},
		STATES: {
			GET: edi.rest.prfx.c + 'user/notification/mail/v2/states'
		}
	},
	EXPORT: {
		GROUP_REQUEST: {
			POST: edi.rest.prfx.c + 'group/export/request',
			PUT: edi.rest.prfx.c + 'group/export/request/{id}/push',
			LIST: {
				GET: edi.rest.prfx.c + 'group/export/request'
			},
			GET: edi.rest.prfx.c + 'group/export/request/{id}',
			EXPORT: {
				GET: edi.rest.prfx.c + 'group/export/request/{id}/export'
			}
		}
	},
	REPORT_REQUEST_GROUP: {
		POST: edi.rest.prfx.c + 'report/request/group',
		PUT: edi.rest.prfx.c + 'report/request/group/{reportRequestGroupId}/push',
		GET: edi.rest.prfx.c + 'report/request/group/{id}',
		EXPORT: {
			GET: edi.rest.prfx.c + 'report/request/group/{id}/export'
		},
		DOC_LIST: {
			POST: edi.rest.prfx.c + 'report/request/group/on_doc_list'
		}
	},
	EE: {
		ROUTES: {
			GET: edi.rest.prfx.c + 'route/{id}',
			PUT: edi.rest.prfx.c + 'route/{id}',
			DELETE: edi.rest.prfx.c + 'route/{id}',
			POST: edi.rest.prfx.c + 'route',
			FILTER: {
				GET: edi.rest.prfx.c + 'routes'
			},
			STEP: {
				GET: edi.rest.prfx.c + 'documents/{docId}/route/waiting_step'
			}
		},
		EXPENDITURE: {
			GET: edi.rest.prfx.c + 'expenditure/type/{id}',
			PUT: edi.rest.prfx.c + 'expenditure/type/{id}',
			DELETE: edi.rest.prfx.c + 'expenditure/type/{id}',
			POST: edi.rest.prfx.c + 'expenditure/type',
			FILTER: {
				GET: edi.rest.prfx.c + 'expenditure/types'
			},
			VALUE: {
				GET: edi.rest.prfx.c + 'expenditure/value/{expId}',
				PUT: edi.rest.prfx.c + 'expenditure/value/{expId}',
				DELETE: edi.rest.prfx.c + 'expenditure/value/{expId}',
				POST: edi.rest.prfx.c + 'expenditure/value',
				LIST: {
					GET: edi.rest.prfx.c + 'expenditure/values/{docId}'
				}
			}
		}
	},
	ANNUL: {
		POST: edi.rest.prfx.c + 'annul/{docId}'
	},
	UTOCH: {
		POST: edi.rest.prfx.c + 'utoch/{parentId}'
	},
	DOCUMENTS: {
		GET: edi.rest.prfx.c + 'documents',
		POST: edi.rest.prfx.c + 'documents',
		PUT: edi.rest.prfx.c + 'documents/{documentId}',
		WITH_CONTAINER: {
			POST: edi.rest.prfx.c + 'documents/withContainer'
		},
		COPY: {
			POST: edi.rest.prfx.c + 'documents/{documentId}/copy'
		},
		SEND: {
			PUT: edi.rest.prfx.c + 'documents/{documentId}/push'
		},
		TYPES: {
			GET: 'json/document.types.json'
		},
		LINK: {
			PUT: edi.rest.prfx.c + 'documents/{parentId}/{id}/link'
		},
		UNLINK: {
			PUT: edi.rest.prfx.c + 'documents/{id}/push'
		},
		APERAK: {
			PUT: edi.rest.prfx.c + 'documents/{documentId}/push'
		},
		HEADER: {
			GET: edi.rest.prfx.c + 'documents/{documentId}',
			MULTIPLE: {
				POST: edi.rest.prfx.c + 'documents/batch'
			},
			BY_CONTAINING_DOCUMENT: {
				GET: edi.rest.prfx.c + 'documents/{containerDocId}/{documentId}/header',
				MULTIPLE: {
					POST: edi.rest.prfx.c + 'documents/{containerDocId}/batch'
				}
			}
		},
		CONTENT: {
			GET: edi.rest.prfx.c + 'documents/{documentId}/content',
			MULTIPLE: {
				POST: edi.rest.prfx.c + 'documents/batch/content'
			},
			BY_CONTAINING_DOCUMENT: {
				GET: edi.rest.prfx.c + 'documents/{containerDocId}/{documentId}/content',
				MULTIPLE: {
					POST: edi.rest.prfx.c + 'documents/{containerDocId}/batch/content'
				}
			},
			FOR_PARENT: {
				GET: edi.rest.prfx.c + 'documents/{parentId}/child/{docType}/content'
			}
		},
		SIGN: {
			GET: edi.rest.prfx.c + 'documents/{documentId}/signContent',
			PUT: edi.rest.prfx.c + 'documents/{documentId}/signContent',
			INFO: {
				GET: edi.rest.prfx.c + 'documents/{documentId}/infoSigners',
				POA: {
					GET: edi.rest.prfx.c + 'documents/{documentId}/infoSigners/poa'
				},
				BY_CONTAINING_DOCUMENT: {
					GET: edi.rest.prfx.c + 'documents/{containerDocId}/{documentId}/infoSigners'
				}
			},
			SIGN_TRANSFORM_CONTENT: {
				PUT: edi.rest.prfx.c + 'documents/{documentId}/signTransformContent'
			}
		},
		POA_ERRORS: {
			GET: edi.rest.prfx.c + 'documents/{documentId}/poaErrors'
		},
		REPROCESS: {
			PUT: edi.rest.prfx.c + 'documents/reprocessLegacyDoc/{documentId}'
		},
		SIGN_AB: {
			GET: edi.rest.prfx.c + 'documents/{documentId}/signContent?handleId=AB'
		},
		LINKED: {
			GET: edi.rest.prfx.c + 'documents/{documentId}/linked',
			SORTED: {
				GET: edi.rest.prfx.c + 'documents/{documentId}/linked/sorted'
			},
			PARENTS: {
				GET: edi.rest.prfx.c + 'documents/parents/{documentId}'
			},
			CHILDREN: {
				GET: edi.rest.prfx.c + 'documents/{documentId}/children'
			},
			PATH: {
				GET: edi.rest.prfx.c + 'documents/{documentId}/linkDocsPath'
			},
			TREE: {
				GET: edi.rest.prfx.c + 'documents/{documentId}/linkDocsTree?root={root}&depth={depth}'
			},
			LINK: {
				GET: edi.rest.prfx.c + 'documents/{documentId}/linkDocsList?depth={depth}'
			},
			BY_CONTAINING_DOCUMENT: {
				GET: edi.rest.prfx.c + 'documents/{containerDocId}/{documentId}/linked'
			},
			ON_SERVICE: {
				GET: edi.rest.prfx.c + 'documents/on_service/by_upd/{documentId}/linked'
			}
		},
		EXPORT: {
			XML: edi.rest.prfx.c + 'documents/{documentId}/export',
			XML_DOCUMENT: edi.rest.prfx.c + 'documents/{documentId}/export/xml',
			COMMON: edi.rest.prfx.c + 'documents/{documentId}/export/common',
			EXPENDED: edi.rest.prfx.c + 'documents/{documentId}/extendedExport',
			ATTACHMENT: edi.rest.prfx.c + 'documents/attachment/download/{documentId}',
			BY_CONTAINING_DOCUMENT: {
				XML: edi.rest.prfx.c + 'documents/{containerDocId}/{documentId}/export',
				ATTACHMENT: edi.rest.prfx.c + 'documents/attachment/download/{containerDocId}/{documentId}'
			},
			CATALOGS: {
				PRODCAT: edi.rest.prfx.c + 'catalogs/v2/prodcat/{documentId}',
				LOCCAT: edi.rest.prfx.c + 'catalogs/v2/loccat/{documentId}',
				DELCAT: edi.rest.prfx.c + 'catalogs/v2/delcat/{documentId}'
			},
			SIMPLE: edi.rest.prfx.c + 'documents/{documentId}/export/simple',
			ORIGINAL: edi.rest.prfx.c + 'documents/{documentId}/export/original',
			TRANSIT: edi.rest.prfx.c + 'documents/{documentId}/export/transit'
		},
		GEN_DOCUMENT_NUMBER: {
			GET: edi.rest.prfx.c + 'numeration/settings',
			GET_BY_TYPE: {
				GET: edi.rest.prfx.c + 'numeration/settings/doctype/{doctype}'
			},
			COPY: edi.rest.prfx.c + 'numeration/settings/copyPattern',
			POST: edi.rest.prfx.c + 'numeration/settings',
			PUT: edi.rest.prfx.c + 'numeration/settings/{id}',
			DELETE: edi.rest.prfx.c + 'numeration/settings/{id}',
			PARTNERS: {
				GET: edi.rest.prfx.c + 'numeration/settings/partners',
				GET_BY_ID: edi.rest.prfx.c + 'numeration/settings/{id}/partners',
				PUT: edi.rest.prfx.c + 'numeration/settings/{id}/partners',
				DELETE: edi.rest.prfx.c + 'numeration/settings/{id}/partners/{partnerId}',
				NOT_INCLUDED: {
					GET: edi.rest.prfx.c + 'numeration/settings/{id}/notIncludedPartners'
				}
			}
		},
		SUB_DOC_TYPES: {
			GET: edi.rest.prfx.c + 'documents/subtypes'
		},
		X5NOTIFICATION: {
			GET: edi.rest.prfx.c + 'documents/{id}/business_attribute/X5Notification',
			NOTIFIED_REPLACEMENT: {
				GET: edi.rest.prfx.c + 'agreement/x5/{id}/notified_replacement'
			}
		},
		READ_OVZ_FROM_X5: {
			PUT: edi.rest.prfx.c + 'ovz_from_x5/read/{documentId}'
		},
		LEGACY_APERAK: {
			GET: edi.rest.prfx.c + 'documents/aperak/'
		},
		CREATE_UTOCH: {
			POST: edi.rest.prfx.c + 'utoch/{parentId}'
		},
		DSF_REJECT: {
			POST: edi.rest.prfx.c + 'reject/dsfv3/{parentId}'
		},
		ANNUL: {
			POST: edi.rest.prfx.c + 'documents/annul/{docId}'
		},
		ARCHIVE: {
			EXPORT: {
				GET: edi.rest.prfx.c + 'documents/archive/{archivedObjectId}/export/include_statuses'
			},
			NEW_EXPORT: {
				GET: edi.rest.prfx.c + 'archive/document/{archivedObjectId}/export'
			},
			RESTORE: {
				GET: edi.rest.prfx.c + 'documents/archive/{archivedObjectId}/restoring/include_statuses'
			}
		},
		TRANSFORMATION: {
			POST: edi.rest.prfx.c + 'documents/transform/{parentId}'
		},
		TRANSFORMATION_WITH_CUSTOM_FIELDS: {
			POST: edi.rest.prfx.c + 'documents/transform/with_custom_fields/{parentId}'
		},
		FD: {
			VIEW: edi.rest.prfx.c + 'documents/fd/view/{docId}',
			PRINT_FORM: {
				GET: edi.rest.prfx.c + 'reports/printform/view/{reportId}'
			}
		},
		INFO_FIELDS: {
			GET: edi.rest.prfx.c + 'documents/docdata/{docType}/{orgId}'
		},
		SHARING: {
			HEADER: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/doc/header'
			},
			CONTENT: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/doc/data'
			},
			LINKED: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/linked',
				CHILDREN: {
					GET: edi.rest.prfx.c + 'context/shared/access/doc/{documentId}/children'
				}
			},
			LINKED_TREE: {
				GET:
					edi.rest.prfx.c +
					'context/shared/access/doc/{containerDocId}/linkDocsTree?root={root}&depth={depth}'
			},
			EXPORT: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/export'
			},
			CONTENT_DOC: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/content'
			},
			BATCH: {
				POST: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/batch'
			},
			BATCH_CONTENT: {
				POST: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/batch/content'
			},
			SIGNERS: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/infoSigners'
			},
			FILTER: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc'
			},
			RESTORE: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc/{containerDocId}/doc/restoring'
			},
			DOCDATA: {
				GET: edi.rest.prfx.c + 'context/shared/access/doc/{documentId}/docdata/{orgId}'
			}
		},
		VIEWED: {
			PUT: edi.rest.prfx.c + 'documents/{docId}/viewed'
		},
		BATCH_VIEWED: {
			PUT: edi.rest.prfx.c + 'documents/batch/viewed'
		},
		DSF_ATTACHMENT: {
			VIEW: {
				POST: edi.rest.prfx.c + 'documents/attachment/view/{documentId}'
			}
		},
		ANNUL_LOG: {
			GET: edi.rest.prfx.c + 'annul_log/{docId}'
		},
		ATTRIBUTES: {
			PUT: edi.rest.prfx.c + 'documents/add/attributes/{docId}',
			DELETE: edi.rest.prfx.c + 'documents/remove/attribute/{attrName}/{docId}'
		},
		MESSAGE: {
			WARNING: {
				GET: edi.rest.prfx.c + 'documents/{id}/business_attribute/warningMessage'
			},
			BLOCKING: {
				GET: edi.rest.prfx.c + 'documents/{id}/business_attribute/blockingMessage'
			}
		}
	},
	INTEGRATION_CHANNELS: {
		GET_BY_DOC_ID: edi.rest.prfx.c + 'documents/{docId}/integration/channels',
		PUT_FILTER_DOCS_BY_IDS: edi.rest.prfx.c + 'documents/integration/channels'
	},
	PROJECTS: {
		OWN_PROJECTS: {
			GET: edi.rest.prfx.c + 'project/available'
		},
		PARTNERS_PROJECTS: {
			GET: edi.rest.prfx.c + 'organization/partner/{partnerId}/projects'
		}
	},
	REPORT: {
		POST: edi.rest.prfx.c + 'reports',
		BY_CONTAINING_DOCUMENT: {
			POST: edi.rest.prfx.c + 'reports/{containerDocId}'
		},
		GET: edi.rest.prfx.c + 'reports',
		DELETE: edi.rest.prfx.c + 'reports/{docId}',
		RESULT: {
			DELETE: edi.rest.prfx.c + 'reports/{docId}/{reportFormat}',
			DELETE_ONE: edi.rest.prfx.c + 'reports/request/{id}'
		},
		DOWNLOAD: {
			GET: edi.rest.prfx.c + 'reports/printform/{docId}/{reportId}'
		},
		PRINT_FORM: {
			GET: edi.rest.prfx.c + 'reports/printform/{reportId}',
			BY_CONTAINING_DOCUMENT: {
				GET: edi.rest.prfx.c + 'reports/printform/{containerDocId}/{reportId}'
			}
		},
		TEMPLATE: {
			GET: edi.rest.prfx.c + 'reports/templates'
		},
		TEMPLATES_AND_STATUSES: {
			GET: edi.rest.prfx.c + 'reports/statuses/{docId}/{format}',
			BY_CONTAINING_DOCUMENT: {
				GET: edi.rest.prfx.c + 'reports/statuses/{containerDocId}/{docId}/{format}'
			}
		},
		GROUP: {
			GET: edi.rest.prfx.c + 'report/request/group',
			PUT: edi.rest.prfx.c + 'report/request/group/{reportRequestGroupId}/push',
			POST: edi.rest.prfx.c + 'report/request/group/on_doc_list/simple',
			PDF: {
				POST: edi.rest.prfx.c + 'report/request/group/PDF/on_doc_list/simple',
				GET: edi.rest.prfx.c + 'report/request/group/{id}/export/combined/pdf'
			},
			DELETE: {
				PUT: edi.rest.prfx.c + 'report/request/group/delete'
			}
		}
	},
	REPORTS: {
		GET: edi.rest.prfx.c + 'report/request/processing/',
		POST: edi.rest.prfx.c + 'report/request/processing/',
		DELETE: edi.rest.prfx.c + 'report/request/processing/{id}/delete',
		PUT: edi.rest.prfx.c + 'report/request/processing/re_create/{id}'
	},
	RETAIL_NETWORKS: {
		PARTNERS: {
			GET: edi.rest.prfx.c + 'retailnetwork/partners'
		}
	},
	CATALOGS: {
		V2: {
			POST: edi.rest.prfx.c + 'catalogs/v2/{type}',
			UPLOAD_FROM_WEB: {
				POST: edi.rest.prfx.c + 'file_storage/upload_from_web/{type}'
			},
			FILTER: {
				GET: edi.rest.prfx.c + 'catalogs/v2'
			},
			RECORDS: {
				FILTER: {
					GET: edi.rest.prfx.c + 'catalogs/v2/{type}/records/{id}'
				},
				GET: edi.rest.prfx.c + 'catalogs/v2/{type}/records/{fromOrgId}/{toOrgId}'
			},
			DELETE: edi.rest.prfx.c + 'catalogs/v2/{type}/{id}',
			ACTIVATE: edi.rest.prfx.c + 'catalogs/v2/{type}/{id}/activate',
			COMPLETED: edi.rest.prfx.c + 'catalogs/v2/{type}/{id}/push'
		},
		FILTER: {
			GET: edi.rest.prfx.c + 'catalogs/v2/{type}/records/{fromOrgId}/{toOrgId}'
		},
		ITEM: {
			POST: edi.rest.prfx.c + 'catalogs/v2/{type}/{id}',
			PUT: edi.rest.prfx.c + 'catalogs/v2/{type}/{id}/{lineItemId}',
			DELETE: edi.rest.prfx.c + 'catalogs/v2/{type}/{id}/{lineItemId}',
			GROUP: {
				DELETE: edi.rest.prfx.c + 'catalogs/v2/{type}/{id}/lines'
			}
		},
		PRODCAT: {
			FILTER: {
				GET: edi.rest.prfx.c + 'catalogs/v2/prodcat/records/{groupId}'
			},
			GET: edi.rest.prfx.c + 'catalogs/v2/prodcat/records/{fromOrgId}/{toOrgId}?ean={id}',
			BATCH: {
				GET: edi.rest.prfx.c + 'catalogs/v2/prodcat/records/{fromOrgId}/{toOrgId}?eans={ids}'
			},
			DOWNLOAD: {
				GET: edi.rest.prfx.c + 'catalogs/v2/partner/prodcat/records/xls/{partnerId}'
			}
		},
		DELCAT: {
			FILTER: {
				GET: edi.rest.prfx.c + 'catalogs/v2/delcat/records/{groupId}'
			},
			GET: edi.rest.prfx.c + 'catalogs/v2/delcat/records/{fromOrgId}/{toOrgId}?id={id}',
			DOWNLOAD: {
				GET: edi.rest.prfx.c + 'catalogs/v2/partner/delcat/records/xls/{partnerId}'
			}
		},
		LOCCAT: {
			FILTER: {
				GET: edi.rest.prfx.c + 'catalogs/v2/loccat/records/{groupId}'
			},
			GET: edi.rest.prfx.c + 'catalogs/v2/loccat/records/{fromOrgId}/{toOrgId}?id={id}',
			DOWNLOAD: {
				GET: edi.rest.prfx.c + 'catalogs/v2/partner/loccat/records/xls/{partnerId}'
			}
		},
		PARTIN: {
			GET: edi.rest.prfx.c + 'catalogs/v2/partin/records/{type}/{org_source}/{org_target}'
		},
		LEGACY_PARTIN: {
			POST: edi.rest.prfx.c + 'catalogs/v2/partin/json',
			GET: edi.rest.prfx.c + 'catalogs/v2/partin/records/{id}/{type}',
			EXPORT: edi.rest.prfx.c + 'catalogs/v2/partin/{id}',
			HEADER: {
				GET: edi.rest.prfx.c + 'catalogs/v2/partin/{id}/header',
				PUT: edi.rest.prfx.c + 'catalogs/v2/partin/{id}/header'
			},
			ROW: {
				DELETE: edi.rest.prfx.c + 'catalogs/v2/partin/{id}/{type}',
				POST: edi.rest.prfx.c + 'catalogs/v2/partin/{id}/{type}',
				PUT: edi.rest.prfx.c + 'catalogs/v2/partin/{id}/{type}/{lineItemId}'
			}
		}
	},
	LOCK: {
		GET: edi.rest.prfx.c + 'documents/{id}/usage/modules',
		ACTIVE: {
			PUT: edi.rest.prfx.c + 'documents/{docID}/usage/module/{nameModule}/active'
		},
		DEACTIVE: {
			PUT: edi.rest.prfx.c + 'documents/{docID}/usage/module/{nameModule}/deactive'
		},
		BATCH: {
			ACTIVE: {
				PUT: edi.rest.prfx.c + 'documents/usage/module/{nameModule}/active'
			},
			DEACTIVE: {
				PUT: edi.rest.prfx.c + 'documents/usage/module/{nameModule}/deactive'
			}
		}
	},
	SSCC: {
		GET: edi.rest.prfx.c + 'edi/sscc/{sku}'
	},
	MODE_OF_TRANSPORT: {
		GET: 'json/mode_of_transport.json'
	},
	DSF: {
		FILE: {
			POST: edi.rest.prfx.c + 'files/upload/dsf'
		}
	},
	OKV_CURRENCIES: {
		GET: 'json/okv.codes.multi.json'
	},
	TRANSACTION_TYPES: {
		GET: 'json/transaction_types.json'
	},
	INVITATION: {
		POST: edi.rest.prfx.c + 'invitation',
		PUT: edi.rest.prfx.c + 'invitation/{id}/push',
		GET: edi.rest.prfx.c + 'invitation/list',
		DETAILS: {
			GET: edi.rest.prfx.c + 'invitation/{id}'
		},
		FILTER: {
			GET: edi.rest.prfx.c + 'invitation'
		}
	},
	PARTNER: {
		CATALOGS: {
			DELCAT: {
				FILTER: {
					GET: edi.rest.prfx.c + 'catalogs/v2/partner/delcat/records/{partnerId}'
				}
			},
			LOCCAT: {
				FILTER: {
					GET: edi.rest.prfx.c + 'catalogs/v2/partner/loccat/records/{partnerId}'
				}
			},
			PRODCAT: {
				FILTER: {
					GET: edi.rest.prfx.c + 'catalogs/v2/partner/prodcat/records/{partnerId}'
				}
			}
		}
	},
	REGIONS: {
		GET: 'json/region_codes.json'
	},
	OKEI_CODES: {
		GET: 'json/okei.codes.json'
	},
	WEIGHT_OF_ORDER: {
		GET: 'json/weightOfOrder.json'
	},
	PACKAGING_UNIT: {
		GET: 'json/packaging_unit.json'
	},
	DELFOR_CALENDAR_CODES: {
		GET: 'json/calendarCodeCatalog.json'
	},
	POA_CODES: {
		GET: 'json/power.of.attorney.code.json'
	},
	CONTRACTS: {
		AVAILABLE: {
			GET: edi.rest.prfx.c + 'agreements/supply/{partnerOrgId}/type/{documentType}'
		},
		SUPPLY: {
			AVAILABLE: {
				GET: edi.rest.prfx.c + 'agreements/factoring/available/supply/agreements/{consumerId}'
			}
		},
		GET: edi.rest.prfx.c + 'agreements/supply/{id}'
	},
	BIK: {
		GET: edi.rest.prfx.c + 'bank/name/{bik}'
	},
	LOGS: {
		FILTER: {
			GET: edi.rest.prfx.c + 'log'
		},
		GET: edi.rest.prfx.c + 'log/{id}'
	},
	WEB_UPLOAD_DOCUMENT: {
		UPLOAD: edi.rest.prfx.c + 'file_storage/upload_from_web',
		CHECK_CHANNEL: edi.rest.prfx.c + 'file_storage/check_channel'
	},
	FILIATIONS: {
		GET: edi.rest.prfx.c + 'organization/filials'
	},
	QUICK_ANSWER: {
		APPLY: {
			// for many CONFIRM action
			CONFIRM: {
				PUT: edi.rest.prfx.c + 'document/transformation/quick/answer/apply'
			}
		},
		CONFIG: {
			GET: edi.rest.prfx.c + 'document/transformation/quick/answer/by_partners'
		},
		CONFIRM: {
			PUT: edi.rest.prfx.c + 'document/transformation/quick/answer/apply/{documentId}/CONFIRM'
		},
		REJECT: {
			PUT: edi.rest.prfx.c + 'document/transformation/quick/answer/apply/{documentId}/REJECT'
		}
	},
	CUSTOM_FIELDS: {
		GET: edi.rest.prfx.c + 'custom/field/{docType}/{toOrgId}/{fromOrgId}',
		GET_DATA: edi.rest.prfx.c + 'documents/custom_fields/{docId}'
	},
	OBJECT: {
		HEADER: {
			GET: edi.rest.prfx.c + 'objects/header/{headerId}'
		}
	},
	POWER_OF_ATTORNEY: {
		LIST: {
			GET: edi.rest.prfx.c + 'poa'
		},
		HEADER: {
			GET: edi.rest.prfx.c + 'poa/{documentId}',
			BY_POA_ID: {
				GET: edi.rest.prfx.c + 'poa/{documentId}/{poaId}'
			}
		},
		GET: edi.rest.prfx.c + 'documents/{documentId}/content',
		POST: edi.rest.prfx.c + 'documents',
		PUT: edi.rest.prfx.c + 'documents/{documentId}',
		DELETE: edi.rest.prfx.c + 'poa/{documentId}',
		GET_NUMBER: {
			PUT: edi.rest.prfx.c + 'documents/{documentId}/push'
		},
		SIGN: {
			GET: edi.rest.prfx.c + 'documents/{documentId}/signContent'
		},
		REVOKE: {
			POST: edi.rest.prfx.c + 'poa/{documentId}/revoke'
		},
		SEARCH_BY_CERT: {
			POST: edi.rest.prfx.c + 'poa/search/by_cert'
		},
		UPLOAD_XML: {
			POST: edi.rest.prfx.c + 'file_storage/upload_from_web'
		},
		IMPORT_BY_GUID: {
			POST: edi.rest.prfx.c + 'poa/request'
		},
		GET_CONTROL_CENTER_URL: edi.rest.prfx.c + 'server/setting/EDIWEB_URL',
		KOD_POLN_CODES: {
			GET: 'json/kod_poln_items.json'
		}
	},
	SERVER: {
		SETTING: {
			GET: edi.rest.prfx.c + 'server/setting/{configuration_code}'
		}
	}
});
