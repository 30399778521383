import { P2SignerData, UPD502nCreatePart2, Upd502nPart2Form } from '../5_02N/createPart2';

export class Upd503nPart2Form extends Upd502nPart2Form {}

export class UPD503nCreatePart2 extends UPD502nCreatePart2 {}

edi.constants.DOCUMENT_PART_2_FORM.push({
	docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	version: '5.03-N',
	createForm: function ({ signerData }: { signerData: Partial<P2SignerData> }) {
		const part2 = new Upd503nPart2Form({
			signerData
		});

		return part2.createModalForm();
	},
	createPart2: function ({ formValues }: { formValues: AnyObject }) {
		return new UPD503nCreatePart2({ formValues });
	}
});
