import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createTextField,
	createTimeField,
	createLabel
} from '@UIkit/components/fields';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { RETANN_LINE_COLUMN, RETANN_SUMMARY_COLUMN } from './columns';
import { RETANN_LINE_MODEL, RETANN_SUMMARY_MODEL } from './models';
import { createRetann } from './methods';
import {
	createButtonContainer,
	createFieldBlock,
	createFieldSet,
	createMaxWidthContainer
} from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { coreMethods } from '@Core/commons';

Ext.namespace('edi.modules');
edi.modules['document.create.retann'] = function () {
	let moduleData,
		id,
		isEdit = false,
		isCopy = false,
		sellerId;

	let userData = edi.core.getUserData(),
		buyer,
		seller,
		shipFrom,
		initialData,
		afterSave,
		form,
		parentId,
		productsGrid,
		isNumberAutoGenerated = false,
		costDecimals,
		availableTaxRates = edi.methods.taxRates.getRatesByCountry(userData.org.country),
		linesData = [],
		productValues = {
			products: []
		},
		taxCalculationMethod;
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		costDecimals = coreMethods.getUserOrgCostDecimals();

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const createHeadBlock = function (docContent) {
		const docNumber = createTextField({
			fieldLabel: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('field.name.number'),
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.ReturnAnnouncementNumber') +
				  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
				: undefined,
			maxLength: 35,
			name: 'ReturnAnnouncement-Header.ReturnAnnouncementNumber'
		});

		const docDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			value:
				docContent && !isCopy
					? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.ReturnAnnouncementDate')
					: new Date(),
			name: 'ReturnAnnouncement-Header.ReturnAnnouncementDate'
		});

		const autogenCheckbox = isNumberAutoGenerated
			? createCheckbox({
					boxLabel: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
					inputValue: true,
					checked: isNumberAutoGenerated,
					name: 'numberAutoGenerated',
					listeners: {
						change: function (_checkbox, isChecked) {
							docNumber.setEmptyText(
								isChecked
									? edi.i18n.getMessage('document.generation.numbers.text')
									: edi.i18n.getMessage('field.name.number')
							);
							docNumber.setValue('');
							docNumber.setDisabled(isChecked);
							docNumber.allowBlank = isChecked;
							docNumber.validate();
						}
					}
			  })
			: null;

		const despatchDate = createDateField({
			fieldLabel: edi.i18n.getMessage('retann.despatch.date'),
			allowBlank: true,
			value:
				docContent && !isCopy
					? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.DespatchDate')
					: new Date(),
			name: 'ReturnAnnouncement-Header.DespatchDate'
		});

		const despatchTime = createTimeField({
			fieldLabel: edi.i18n.getMessage('retann.despatch.time'),
			allowBlank: true,
			value:
				docContent && !isCopy
					? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.DespatchTime')
					: new Date(),
			name: 'ReturnAnnouncement-Header.DespatchTime'
		});

		const functionalDocumentCode = createCombo({
			fieldLabel: edi.i18n.getMessage('functional.document.code'),
			name: 'ReturnAnnouncement-Header.DocumentFunctionCode',
			store: edi.stores.createSimpleInlineStore(['3', '4', '9'], function (id) {
				return edi.i18n.getMessage('functional.document.code.' + id);
			}),
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.DocumentFunctionCode')
				: '9',
			anyMatch: true,
			allowBlank: false
		});

		return createFieldBlock({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: [8, 16],
				area: [
					[4, 2, 2, 2],
					[3, 6]
				]
			},
			cls: 'edi-form-maxwidth',
			items: [docNumber, docDate, despatchDate, despatchTime, functionalDocumentCode, autogenCheckbox]
		});
	};

	const createPartiesBlock = function (docContent) {
		const parties = docContent ? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Parties') : undefined;
		const sellers = edi.relations.getRelations();
		let sellerRelation = null;
		if (docContent) {
			const partiesSellerIln = parties?.Seller?.iln || parties?.Seller?.ILN;
			sellerRelation = sellers.find((s) => partiesSellerIln === s.iln);
		}

		const createOS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				valuesByMap: !!docContent,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				processValues: orgConverter,
				forceChangeFields: {
					bank_acc: true,
					company_iln: true
				}
			});
			return createOrgSelector(selectorConfig);
		};

		const buyerFieldValues = edi.converters.convertOrgToLegacyPartie(userData.org);
		buyer = createOS({
			itemId: 'buyer',
			is_valid: parties?.Buyer?.ILN || userData.org.iln,
			fieldValues: docContent ? parties : buyerFieldValues,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id,
				converter: orgConverter
			}),
			selectedOrgValues: docContent ? undefined : buyerFieldValues,
			callback: function (_values, org) {
				const orgId = org ? org.id : null;
				if (orgId) {
					taxCalculationMethod = edi.utils.getTaxCalculationMethod(org.attributes);
				} else {
					taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
				}
				checkValid();
			},
			fieldsMap: edi.selectors.getRetannPartiesFieldsMap('Buyer'),
			modalConf: {
				tabs: {
					main: {
						company_name: edi.selectors.defaults.company_name,
						company_gln: edi.selectors.defaults.company_gln
					}
				}
			}
		});
		const buyerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.buyer'),
			items: [buyer]
		});

		seller = createOS({
			itemId: 'seller',
			relationsOnly: true,
			allowBlank: false,
			allowReset: !isEdit,
			relations: isEdit ? null : sellers,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			selectedOrgValues: docContent ? sellerRelation : null,
			is_valid: !!docContent,
			fieldValues: docContent ? parties : undefined,
			callback: function (_values, org) {
				const orgId = org ? org.id : null;
				sellerId = orgId;
				if (productsGrid.getPartnerId() !== orgId && productsGrid.productsGrid.getStore().getCount() > 0) {
					const modalClearWarn = edi.core.confirm(
						edi.i18n.getMessage('documents.retann.modalClearWarn.title'),
						edi.i18n.getMessage('documents.retann.modalClearWarn.text'),
						undefined,
						undefined,
						undefined,
						undefined,
						{
							closable: false,
							buttons: [
								createButton({
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage('documents.retann.modalClearWarn.clear'),
									handler: function () {
										productsGrid.productsGrid.getStore().removeAll();
										modalClearWarn.close();
									}
								}),
								createButton({
									cls: BUTTON_CLS.secondary,
									text: edi.i18n.getMessage('documents.retann.modalClearWarn.stay'),
									handler: function () {
										modalClearWarn.close();
									}
								})
							]
						}
					);
				}
				productsGrid.setPartnerId(orgId);
				checkValid();
			},
			fieldsMap: edi.selectors.getRetannPartiesFieldsMap('Seller'),
			modalConf: {
				tabs: {
					main: {
						company_gln: edi.selectors.defaults.company_gln
					}
				}
			}
		});
		const sellerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.seller'),
			items: [seller]
		});

		shipFrom = createOS({
			itemId: 'shipFrom',
			tooltip: 'document.ship.from.tooltip',
			allowReset: true,
			alwaysShowSelect: false,
			ownOrg: true,
			allowBlank: true,
			fieldValues: docContent ? docContent : undefined,
			is_valid: true,
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('ReturnAnnouncement-Header.Location', {
				includeFields: [
					'company_name',
					'company_iln',
					'addr_zip',
					'addr_country',
					'addr_city',
					'addr_street',
					'addr_region_code'
				]
			}),
			modalConf: edi.selectors.getLegacyModalConf({
				main: {
					company_reg_number: null
				},
				address: {
					country: {
						fieldConfig: {
							mandatory: false
						}
					}
				},
				contact: null
			})
		});
		const shipFromBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.ship.from'),
			items: [shipFrom]
		});

		return createFieldSet({
			title: edi.i18n.getMessage('document.section.parties'),
			collapsible: true,
			items: [
				createMaxWidthContainer({
					items: [buyerBlock, sellerBlock, shipFromBlock],
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [
							[6, 6],
							[6, 6]
						]
					}
				})
			]
		});
	};

	const createAdditionalInformationBlock = function (docContent) {
		const waybillNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			maxLength: 35,
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.WaybillNumber')
				: undefined,
			name: 'ReturnAnnouncement-Header.WaybillNumber'
		});
		const waybillDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'ReturnAnnouncement-Header.WaybillDate',
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.WaybillDate')
				: undefined
		});
		const wayBillBlock = createFieldBlock({
			title: edi.i18n.getMessage('reference.waybill'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [waybillNumber, waybillDate]
		});

		const orderNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			maxLength: 35,
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.Reference.OrderReferenceNumber')
				: undefined,
			name: 'ReturnAnnouncement-Header.Reference.OrderReferenceNumber'
		});
		const orderDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'ReturnAnnouncement-Header.Reference.OrderReferenceDate',
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.Reference.OrderReferenceDate')
				: undefined
		});
		const orderBlock = createFieldBlock({
			title: edi.i18n.getMessage('reference.order'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [orderNumber, orderDate]
		});

		const invoiceNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			maxLength: 35,
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.Reference.InvoiceReferenceNumber')
				: undefined,
			name: 'ReturnAnnouncement-Header.Reference.InvoiceReferenceNumber'
		});
		const invoiceDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'ReturnAnnouncement-Header.Reference.InvoiceReferenceDate',
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.Reference.InvoiceReferenceDate')
				: undefined
		});
		const invoiceBlock = createFieldBlock({
			title: edi.i18n.getMessage('reference.invoice'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [invoiceNumber, invoiceDate]
		});

		const recadvNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			maxLength: 35,
			value: docContent
				? edi.utils.getObjectProperty(
						docContent,
						'ReturnAnnouncement-Header.Reference.ReceivingAdviceReferenceNumber'
				  )
				: undefined,
			name: 'ReturnAnnouncement-Header.Reference.ReceivingAdviceReferenceNumber'
		});
		const recadvDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'ReturnAnnouncement-Header.Reference.ReceivingAdviceReferenceDate',
			value: docContent
				? edi.utils.getObjectProperty(
						docContent,
						'ReturnAnnouncement-Header.Reference.ReceivingAdviceReferenceDate'
				  )
				: undefined
		});
		const recadvBlock = createFieldBlock({
			title: edi.i18n.getMessage('reference.recadv'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [recadvNumber, recadvDate]
		});

		const contractNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			maxLength: 175,
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.Reference.ContractNumber')
				: undefined,
			name: 'ReturnAnnouncement-Header.Reference.ContractNumber'
		});
		const contractDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			name: 'ReturnAnnouncement-Header.Reference.ContractDate',
			value: docContent
				? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.Reference.ContractDate')
				: undefined
		});
		const contractBlock = createFieldBlock({
			title: edi.i18n.getMessage('reference.return.contract'),
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [contractNumber, contractDate]
		});

		return createFieldSet({
			title: edi.i18n.getMessage('documents.special.identifiers'),
			collapsible: true,
			items: createMaxWidthContainer({
				layout: {
					type: 'grid',
					gap: [24, 16]
				},
				items: [wayBillBlock, orderBlock, invoiceBlock, recadvBlock, contractBlock]
			})
		});
	};

	const createRemarks = function (docContent) {
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.column.remark'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				area: [[6]]
			},
			items: [
				createTextField({
					name: 'ReturnAnnouncement-Header.Remarks',
					value: docContent
						? edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.Remarks')
						: undefined,
					maxLength: 500,
					isTextarea: true
				})
			]
		});
	};

	const createProductsGrid = function (docContent) {
		/**
		 * Calculates price and tax summary according to tax recalculation method
		 * @param    {Object}     values              product data
		 * @param    {Boolean}    roundResults        true to round calculated values
		 * @returns {*}
		 */
		const calculateTaxes = function (values, roundResults) {
			const data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_RETANN);
			Ext.merge(data, { amount: values.ReturnedQuantity || 0 });

			const calcData = edi.methods.product.price.recalculation(
				{
					taxRate: data.rateVal,
					amount: data.amount,
					netPrice: data.price,
					grossPrice: data.grossPrice,
					netSum: values.NetAmount,
					taxSum: values.TaxAmount,
					grossSum: values.GrossAmount
				},
				taxCalculationMethod,
				undefined,
				undefined,
				costDecimals
			);
			values.GrossAmount = calcData.grossSum;
			values.TaxAmount = calcData.taxSum;
			values.NetAmount = calcData.netSum;
			values.UnitGrossPrice = calcData.grossPrice;
			values.UnitNetPrice = calcData.netPrice;
			roundResults
				? (values = edi.methods.product.grid.roundValues(
						values,
						['GrossAmount', 'TaxAmount', 'NetAmount', 'UnitGrossPrice', 'UnitNetPrice'],
						costDecimals
				  ))
				: null;
			return values;
		};

		if (docContent) {
			const lines = edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Lines.Line', true);

			const processRecordValues = function (values) {
				const calculated = Ext.clone(values);
				calculateTaxes(calculated, true);
				return values;
			};

			if ('string' != typeof lines) {
				for (let i = 0; i < lines.length; i++) {
					const values = Ext.clone(lines[i]['Line-Item']);
					edi.document.actions.setInternalLineId(lines[i], values);
					linesData.push(processRecordValues(values));
				}
				productValues.products = linesData;
			}
		}

		productsGrid = createProductGridBase({
			ownCatalog: false,
			title: edi.i18n.getMessage('document.retann.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			data: docContent ? linesData : undefined,
			lockIfNoPartner: true,
			gridModel: RETANN_LINE_MODEL,
			totalModel: RETANN_SUMMARY_MODEL,
			gridColumnConfig: RETANN_LINE_COLUMN,
			totalColumnConfig: RETANN_SUMMARY_COLUMN,
			lineNumberFieldName: 'LineNumber',
			isValidRecord: function (record) {
				const product = record.getData();
				const taxValid = edi.methods.taxRates.isValidRate(product.TaxRate, availableTaxRates, false);
				return (
					taxValid &&
					!!product.ReturnedQuantity &&
					parseFloat(product.GrossAmount || 0) <= edi.utils.getMaxValueByLength(12)
				);
			},
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				checkValid();
			},
			afterRowEdit: function (values, field) {
				if (field === 'ReturnedQuantity') {
					calculateTaxes(values, true);
				}
			},
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
			docData: docContent,
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			resetFormOnCatalogSelection: true,
			selectProductHandler: function (product) {
				const fields = this.getFormFields();
				fields.EAN ? fields.EAN.setValue(product.EAN) : null;
				fields.SupplierItemCode ? fields.SupplierItemCode.setValue(product.SupplierItemCode) : null;
				fields.BuyerItemCode ? fields.BuyerItemCode.setValue(product.BuyerItemCode) : null;
				fields.ReceivedQuantity ? fields.ReceivedQuantity.setValue(product.ReceivedQuantity) : null;
				fields.ReturnedQuantity ? fields.ReturnedQuantity.setValue(product.ReturnedQuantity) : null;
				fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
				fields.UnitNetPrice ? fields.UnitNetPrice.setValue(product.UnitNetPrice) : null;
				fields.UnitGrosstPrice ? fields.UnitGrosstPrice.setValue(product.UnitGrosstPrice) : null;
				fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;
				fields.UnitOfMeasure ? fields.UnitOfMeasure.findAndSetValue(product.UnitOfMeasure) : null;
				fields.NetAmount ? fields.NetAmount.setValue(product.NetAmount) : null;
				fields.TaxAmount ? fields.TaxAmount.setValue(product.TaxAmount) : null;
				fields.GrossAmount ? fields.GrossAmount.setValue(product.GrossAmount) : null;
				fields.Remarks ? fields.Remarks.setValue(product.Remarks) : null;
			},
			modalFormConfig: {
				title: 'document.retann.line',
				formMethods: {
					updateTaxesInModal: function () {
						let values = this.getFormValues();
						const fields = this.getFormFields();

						values = calculateTaxes(values, true);
						fields.NetAmount ? fields.NetAmount.setValue(values.NetAmount) : null;
						fields.TaxAmount ? fields.TaxAmount.setValue(values.TaxAmount) : null;
						fields.GrossAmount ? fields.GrossAmount.setValue(values.GrossAmount) : null;
						fields.UnitNetPrice ? fields.UnitNetPrice.setValue(values.UnitNetPrice) : null;
						fields.UnitGrossPrice ? fields.UnitGrossPrice.setValue(values.UnitGrossPrice) : null;
					}
				},
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								maskRe: /\d/i,
								validator: function (value) {
									let valid = true,
										result = edi.constants.VALIDATORS.EAN14.test(value),
										strVal = String(value);
									if (!result && strVal.length) {
										result = edi.constants.VALIDATORS.INT.test(value);
										if (!result || strVal.length > 14) {
											valid = edi.i18n.getMessage('invalid.field.ean.value.limit', [14]);
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.product.description'),
								name: 'ItemDescription',
								maxLength: 100
							},
							{
								title: edi.i18n.getMessage('column.quantity.received'),
								name: 'ReceivedQuantity',
								validator: edi.methods.validators.positiveNonZero,
								type: 'number',
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: costDecimals,
								listeners: {
									change: function () {
										this.updateTaxesInModal();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('column.quantity.returned'),
								name: 'ReturnedQuantity',
								validator: edi.methods.validators.positiveNonZero,
								type: 'number',
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: costDecimals,
								allowBlank: false,
								listeners: {
									change: function () {
										this.updateTaxesInModal();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								maxLength: 35,
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								maxLength: 35,
								name: 'SupplierItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.product.remarks'),
								name: 'Remarks',
								maxLength: 500
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('organization.taxCalculationMethod'),
								type: 'label',
								text: edi.i18n.getMessage('organization.taxCalculationMethod.' + taxCalculationMethod)
							},
							{
								title: edi.i18n.getMessage('line.item.ordered.unit.net.price'),
								name: 'UnitNetPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 10,
								listeners: {
									change(_field, newVal, oldVal) {
										if (+newVal === +oldVal) {
											return;
										}
										if (
											taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.NET_PRICE ||
											taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.WITHOUT
										) {
											this.updateTaxesInModal();
										}
										const fields = this.getFormFields();
										if (fields.UnitGrossPrice) {
											fields.UnitGrossPrice.isValid();
										}
									}
								},
								validator: function (value) {
									let valid = true;
									const fields = this.getFormFields();
									if (fields.UnitGrossPrice) {
										if (!value && fields.UnitGrossPrice.getValue()) {
											valid = edi.i18n.getMessage('error.value.notvalid');
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'UnitGrossPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 10,
								listeners: {
									change(_field, newVal, oldVal) {
										if (+newVal === +oldVal) {
											return;
										}
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.GROSS_PRICE
											? this.updateTaxesInModal()
											: null;
										const fields = this.getFormFields();
										if (fields.UnitNetPrice) {
											fields.UnitNetPrice.isValid();
										}
									}
								},
								validator: function (value) {
									let valid = true;
									const fields = this.getFormFields();
									if (fields.UnitNetPrice) {
										if (
											(!value && fields.UnitNetPrice.getValue()) ||
											Number(value) < Number(fields.UnitNetPrice.getValue())
										) {
											valid = edi.i18n.getMessage('error.value.notvalid');
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate',
								type: 'combo',
								store: edi.stores.createInlineStore(availableTaxRates),
								valueInitialize: true,
								allowBlank: false,
								validator: isEdit
									? function (value) {
											return (
												edi.methods.taxRates.isValidRate(value, availableTaxRates, true) ||
												edi.i18n.getMessage('form.field.tax.invalid')
											);
									  }
									: null,
								listeners: {
									change: function () {
										this.updateTaxesInModal();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								name: 'NetAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								value: 0
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								name: 'TaxAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								name: 'GrossAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							}
						]
					}
				]
			}
		});

		if (docContent) {
			productsGrid.setPartnerId(sellerId);
		}
		return productsGrid;
	};

	/**
	 * Creates retann create/edit form
	 * @param   {Object}    docContent    retann data for editing, undefined for new retann
	 * @returns {Object}
	 */
	const createModForm = function (docContent) {
		const createBtn = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom'],
							[buyer, seller, shipFrom],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					const values = edi.utils.collectFormValues(form);
					save(values);
				}
			},
			isEdit
		);

		let createAndSendButton;
		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.LEGACY_RETANN
				)
			)
		) {
			createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom'],
							[buyer, seller, shipFrom],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					const values = edi.utils.collectFormValues(form);
					afterSave = function (documentId, callback) {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							Ext.encode({}),
							null,
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.in.sending.process'
							),
							callback
						);
					};
					save(values);
				},
				null,
				isEdit
			);
		}

		form = createFormForModule({
			cls: 'edi-form document-add-form',
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage(isEdit ? 'document.retann' : 'document.add.retann')
				}),
				createHeadBlock(docContent),
				createPartiesBlock(docContent),
				createAdditionalInformationBlock(docContent),
				createRemarks(docContent),
				createProductsGrid(docContent)
			],
			buttons: createButtonContainer({
				items: [createAndSendButton, createBtn]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		let valid = false;
		const total = productValues.products.length;
		const validCompanies = validateCompanySelectors();
		const hasInvalid = form.hasInvalidField();
		const validGrid = productsGrid.isValid();
		if (total && !hasInvalid && validCompanies && validGrid) {
			valid = true;
		}
		return valid;
	};
	/**
	 * Checks company controls, and marks them, if not valid
	 */
	const validateCompanySelectors = function () {
		const isBuyerValid = buyer.isValid(),
			isSellerValid = seller.isValid(),
			isShipFromValid = shipFrom.isValid();

		return isBuyerValid && isSellerValid && isShipFromValid;
	};

	/**
	 * Saves retann
	 * @param {Object}    formValues    values from form
	 */
	const save = function (formValues) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		const values = formValues || {};
		const numberAutoGenerated = formValues.numberAutoGenerated;
		delete formValues.numberAutoGenerated;

		let document = createRetann({
			header: values['ReturnAnnouncement-Header'],
			parties: {
				Buyer: values['Buyer'],
				Seller: values['Seller']
			},
			productValues: Ext.clone(productValues)
		});
		document = edi.document.actions.mergeDataBeforeSave(initialData, document, [
			{
				path: 'ReturnAnnouncement-Lines.Line'
			}
		]);

		edi.utils.clearEmptyValues(document);
		const headerData = {
			data: Ext.encode(document),
			date: edi.utils.getObjectProperty(document, 'ReturnAnnouncement-Header.ReturnAnnouncementDate')
		};

		if (isNumberAutoGenerated && numberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(
				document,
				'ReturnAnnouncement-Header.ReturnAnnouncementNumber'
			);
		}
		const success = edi.document.actions.createSaveSuccessHandler(
			moduleData,
			isEdit ? id : undefined,
			afterSave,
			isEdit
		);
		const failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LEGACY_RETANN
		);
		edi.document.actions.processDocument(
			sellerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
			parentId,
			isEdit ? id : undefined,
			success,
			failure,
			headerData
		);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const data = moduleData.initData.data;
		const modulePanel = createAddModulePanel();

		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const createModuleForm = function (docData) {
			modulePanel.add(createModForm(docData));
			moduleData.tab.add(modulePanel);
		};

		if (data && data.id) {
			id = data.id;
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			sellerId = data.toOrg.id;

			taxCalculationMethod = edi.utils.getTaxCalculationMethod(data.fromOrg.attributes);
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						initialData = data.data;
						createModuleForm(data.data);

						const products = productValues.products.length ? productValues.products : linesData;
						if (products.length) {
							edi.methods.taxRates.showInvalidProductsWarnIfNeeded(products, availableTaxRates, {
								allowBlankRate: true
							});
						}

						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			const createModulePanel = function () {
				createModuleForm();
				seller.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			};

			if (!taxCalculationMethod) {
				taxCalculationMethod = edi.utils.getTaxCalculationMethod(userData.org.attributes);
			}
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.LEGACY_RETANN
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' === docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
