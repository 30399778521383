import { DocumentDetailsFns_upd_5_02n } from './document.details.fns_upd_5_02n';
import { DocLines503 } from './5_03N/createDocLinesGrid';
import { AdditionalInformation503 } from './5_03N/additionalInformation/createAdditionalInformationFieldSetForDetail';
// @ts-ignore
import { createFieldSetForDetails } from '@UIkit/components/panels';
// @ts-ignore
import { createLabelBlockForDetails } from '@UIkit/components/fields';

Ext.namespace('edi.modulesCfg');
const moduleName = 'document.details.fns_upd_5_03n';
edi.modulesCfg[moduleName] = {
	title: 'document.details.fns_upd_5_03n',
	modName: moduleName,
	highlightMenuId: 'documents',
	permissions: ['READ_EDI_FNS_UPD']
};

export class DocumentDetailsFns_upd_5_03n extends DocumentDetailsFns_upd_5_02n {
	version: string = '5.03-N';

	constructor() {
		super();

		const me = this;
		me.ProductGridConstructor = DocLines503;
		me.AdditionalInformationConstructor = AdditionalInformation503;
	}

	createEditAction(): ExtComponent {
		const me = this;

		return edi.document.actions.createEditActionButton(
			'document.create.fns_upd_5_03n',
			me.headerData,
			me.headerData.number
		);
	}

	createMarkingBlock(): ExtComponent {
		const me = this;

		const attributes = me.headerData?.attributes ?? {};
		const markBeer = edi.utils.getAttributeByName(attributes, 'markBeer', undefined, true);
		const markTobacco = edi.utils.getAttributeByName(attributes, 'markTobacco', undefined, true);

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('document.product.marking.product.groups.title'),
			collapsed: true,
			collapsible: true,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('document.product.bear'),
							text: edi.renderers.yesNo(markBeer)
						},
						{
							title: edi.i18n.getMessage('document.product.tobacco'),
							text: edi.renderers.yesNo(markTobacco)
						}
					]
				})
			]
		}) as ExtComponent;
	}

	createExtraBlocks() {
		const me = this;
		return [me.createMarkingBlock()];
	}
}

Ext.namespace('edi.modules');
edi.modules[moduleName] = DocumentDetailsFns_upd_5_03n;
