import { createForm, createPanel, createContainer } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createLabel } from '@UIkit/components/fields';

import './RowWithNestedRows.scss';

Ext.define('edi.components.RowWithNestedRows', {
	extend: 'Ext.panel.Panel',
	alias: 'widget.row-with-nested-rows',
	externalTitle: '',
	internalTitle: '',
	cls: 'row-with-nested-rows',
	padding: '0 0 24 0',
	isShowInternalDeleteBtn: true,
	isShowInternalDoneBtn: true,
	isShowExternalStatus: false,
	setExternalStatusFn: null,
	setWarningBackgroundFn: null,
	externalAddBtnConfig: {},
	internalAddBtnConfig: {},
	internalInitialData: [],
	showCount: true,
	isCreate: true,
	isAddFirstDefaultRow: true,
	internalFormFn() {},
	internalName: '',
	customReadOnlyInternalFormFn: null,

	initComponent: function () {
		this.setOwnConfig();
		this.callParent();
		this.afterInit();
	},

	setOwnConfig() {
		let me = this;
		if (!me.internalInitialData) {
			me.internalInitialData = [];
		}
		me.items = me.createInitialRows();
		me.mainRow = me.items[0];
	},

	afterInit() {
		let me = this;
		me.updateExternalRowButtons();
		if (me.showCount) {
			me.setExternalCount();
		}
		if (me.internalInitialData.length && !me.isCreate) {
			me.setReadOnlyForm();
		}

		me.on('afterrender', function () {
			me.getExternalRows().forEach(function (row) {
				if (typeof me.externalBlockChange === 'function') {
					me.externalBlockChange(me, row);
				}
			});
		});
	},

	createInitialRows() {
		let me = this;

		let initialRows = me.internalInitialData.map((values, i) => {
			return me.createExternalRow(values, i === 0);
		});

		edi.events.onAddExternalBtnHandler.on('change', me.setReadOnlyForm, me);
		edi.events.updateExternalStatus.on('change', me.updateExternalStatus, me);

		//если начальных данных нет, то создадим пустую строку
		return initialRows.length > 0 ? initialRows : [me.createExternalRow(null, true)];
	},

	setReadOnlyInternalRow: function (internalRow) {
		let me = this;
		let isShowWarningBackground = typeof me.setWarningBackgroundFn === 'function';
		if (isShowWarningBackground) {
			let downInternalRow = internalRow.down('[itemId="internalRow"]');
			let internalForm = internalRow.down('[itemId="internalForm"]').items.items[0];
			let formValues = edi.utils.collectFormValues(internalForm.getForm());
			if (me.setWarningBackgroundFn(formValues)) {
				internalRow.addCls('internal-row-warning');
			} else {
				internalRow.removeCls('internal-row-warning');
			}
		}

		me.onAddBtnHandler(internalRow);
	},
	setReadOnlyForm: function () {
		let me = this;
		let rows = me.getExternalRows() || [];
		Ext.Array.forEach(rows, function (externalRow) {
			let externalRowContent = externalRow.down('[itemId="externalRowContent"]');
			if (!!externalRowContent) {
				externalRowContent.items.items.forEach(function (internalRowItem) {
					me.setReadOnlyInternalRow(internalRowItem);
				});
			}
		});
	},

	updateExternalStatus: function () {
		let me = this;
		let rows = me.getExternalRows() || [];
		rows.forEach((externalRow) => {
			let externalRowContent = externalRow.down('[itemId="externalRowContent"]');
			if (!!externalRowContent) {
				externalRowContent.items.items.forEach((internalRow) =>
					internalRow.onValidateForm(internalRow.down('form'))
				);
			}
		});
	},

	onDestroy: function () {
		edi.events.onAddExternalBtnHandler.un('change', this.setReadOnlyForm);
		edi.events.updateExternalStatus.un('change', this.updateExternalStatus);
	},

	onAddBtnHandler: function (internalRow) {
		let me = this;
		let internalForm = internalRow.down('[itemId="internalForm"]').items.items[0];
		let doneBtn = internalRow.down('[itemId="done_button"]');
		let editBtn = internalRow.down('[itemId="edit_button"]');
		let removeBtn = internalRow.down('[itemId="remove_button"]');
		if (!internalForm.hasCls('read-only')) {
			me.internalDoneBtnHandler(internalForm, doneBtn, [doneBtn, editBtn, removeBtn], true);
		}
	},

	//EXTERNAL

	createExternalRow(rowData) {
		let me = this,
			externalAddBtn;

		var internalRows = [];

		let isShowExternalStatus = typeof me.setExternalStatusFn === 'function';

		if (rowData) {
			var internalRowsData = rowData[me.internalName];
			internalRowsData.forEach(function (data) {
				var internalRow = me.createInternalRow(data);
				internalRows.push(internalRow);
			});
		} else {
			internalRows.push(me.createInternalRow());
		}

		let externalRow = createPanel({
			layout: {
				type: 'vbox',
				align: 'stretch'
			},
			itemId: 'externalRow',
			cls: 'row-with-nested-row',
			items: [
				createContainer({
					cls: 'external-row',
					padding: me.externalRowPadding || 24,
					itemId: 'externalRowContainer',
					items: [
						createContainer({
							layout: 'hbox',
							items: [
								createLabel({
									cls: 'external-title',
									padding: me.externalTitle ? '0 0 24 0' : 0,
									flex: 1.5,
									text: me.externalTitle
								}),
								isShowExternalStatus
									? createContainer({
											cls: 'row-buttons',
											flex: 1,
											items: [
												createLabel({
													margin: '0 2 0 0',
													itemId: 'externalStatus',
													hidden: true,
													cls: 'edi-state-container',
													text: ''
												})
											]
									  })
									: null
							]
						}),
						createForm({
							itemId: 'externalRowContent',
							padding: 0,
							bodyPadding: 0,
							items: me.isAddFirstDefaultRow ? internalRows : []
						}),
						typeof me.createExternalBlockAction === 'function'
							? createContainer({
									layout: 'hbox',
									cls: 'external-row-actions',
									items: me.createExternalBlockAction(me, rowData)
							  })
							: null
					]
				}),
				createContainer({
					layout: 'hbox',
					items: [
						(externalAddBtn = createButton(
							Ext.apply(
								{
									itemId: 'externalAddBtn',
									cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
									padding: '24 0 0 0',
									glyph: edi.constants.ICONS.PLUS,
									name: 'item.add.button',
									disabled: true,
									text: edi.i18n.getMessage('form.btn.add.row'),
									handler: function () {
										me.externalAddBtnHandler(function (externalRow) {
											let externalRowContent = externalRow.down('[itemId="externalRowContent"]');
											if (!!externalRowContent) {
												externalRowContent.items.items.forEach(function (internalRow) {
													me.onAddBtnHandler(internalRow);
												});
											}
										});
									}
								},
								me.externalAddBtnConfig
							)
						))
					]
				})
			]
		});

		let panel = createContainer({
			layout: 'auto',
			cls: 'row-with-nested-rows-container',
			items: [externalRow]
		});

		panel.externalAddBtn = externalAddBtn;

		return panel;
	},
	setExternalCount: function () {
		let me = this;
		let rows = me.getExternalRows() || [];
		Ext.Array.forEach(rows, function (item, index) {
			const label = item.down('ui-labelfield');
			var count = rows.length > 1 ? index + 1 : 1;
			let text = me.showCount ? me.externalTitle + ' ' + count : me.externalTitle;
			label?.setText(text);
			let externalRowContent = item.down('[itemId="externalRowContent"]');

			if (me.showCount) {
				me.setInternalCount(externalRowContent);
			}
		});
	},
	externalAddBtnHandler: function (callback) {
		let me = this;
		let newItem = me.createExternalRow();

		me.add(newItem);
		me.updateExternalRowButtons(callback);
		if (me.showCount) {
			me.setExternalCount();
		}
	},

	updateExternalRowButtons(callback) {
		let me = this;
		//me.suspendLayouts();
		let rows = me.getExternalRows() || [];

		rows.forEach((row, index) => {
			if (typeof callback === 'function' && rows.length - 1 !== index) {
				callback(row);
			}
			if (typeof row?.externalAddBtn?.setVisible === 'function') {
				row.externalAddBtn.setVisible(false);
			}
			row.externalAddBtn.setDisabled(false);
			let externalRowContent = row.down('[itemId="externalRowContent"]');

			if (typeof callback === 'function' && rows.length - 1 !== index) {
				me.updateInternalRowButtons(externalRowContent, callback);
			} else {
				me.updateInternalRowButtons(externalRowContent);
			}
		});
		let lastRow = rows[rows.length - 1];
		if (typeof lastRow?.externalAddBtn?.setVisible === 'function') {
			lastRow.externalAddBtn.setVisible(lastRow.externalAddBtn.permanentlyHidden !== true);
		}
		//me.resumeLayouts();
		me.updateLayout();
	},

	getExternalRows() {
		let me = this;
		return Ext.isArray(me.items?.items) ? me.items.items : [];
	},

	getValues: function (internalName) {
		let me = this;
		let externalRows = me.getExternalRows() || [];
		let values = [];

		externalRows.forEach(function (row) {
			let externalRowContent = row.down('[itemId="externalRowContent"]');
			let externalRowContentValues = edi.utils.collectFormValues(externalRowContent);
			edi.utils.clearEmptyArrayValues(externalRowContentValues);
			edi.utils.clearEmptyValues(externalRowContentValues);

			let build = {};
			edi.methods.setObjectValuesWithConverts(externalRowContentValues, build, internalName);

			values.push(build);
		});

		return values;
	},

	//INTERNAL

	createInternalRow(rowData) {
		let me = this,
			internalAddBtn,
			doneRowBtn,
			editRowBtn,
			removeRowBtn,
			internalForm = me.internalFormFn(rowData),
			isEdit = !!rowData,
			externalRows = me.getExternalRows() || [];

		var onValidateForm = function (form) {
			externalRows = me.getExternalRows() || [];
			var isValid = (form || internalForm).isValid();
			doneRowBtn.setDisabled(!isValid);

			internalAddBtn.setDisabled(!isValid);

			externalRows.forEach(function (row, index) {
				let externalRowContent = row.down('[itemId="externalRowContent"]');
				let externalStatusLabel = row.down('[itemId="externalStatus"]');
				let externalAddBtn = row.down('[itemId="externalAddBtn"]');
				let externalRemoveBtn = row.down('[itemId="remove_button"]');

				if (externalAddBtn) {
					externalAddBtn.setDisabled(!isValid);
				}

				if (externalRemoveBtn) {
					if (!(me.items.items.length > 1)) {
						externalRemoveBtn.setDisabled(index === 0);
					}
					if (typeof me.externalRemoveBtnConfig?.setDisabledButton === 'function') {
						me.externalRemoveBtnConfig?.setDisabledButton(me, externalRemoveBtn);
					}
				}

				if (typeof me.setExternalStatusFn === 'function') {
					me.setExternalStatusFn(externalStatusLabel, externalRowContent);
				}
			});
		};

		internalForm.getForm().on('validitychange', function () {
			onValidateForm();
		});
		let label = createLabel({
			cls: 'internal-title',
			flex: 3,
			html: rowData && rowData.customInternalTitle ? rowData.customInternalTitle : me.internalTitle
		});

		doneRowBtn = createButton({
			margin: '0 0 0 16',
			cls: [BUTTON_CLS.icon, BUTTON_CLS.light],
			itemId: 'done_button',
			disabled: !internalForm.isValid() || isEdit,
			tooltip: edi.i18n.getMessage('form.btn.save'),
			glyph: edi.constants.ICONS.DONE,
			handler(btn) {
				me.internalDoneBtnHandler(internalForm, btn, [btn, editRowBtn, removeRowBtn]);
			}
		});

		editRowBtn = createButton({
			margin: '0 0 0 16',
			cls: [BUTTON_CLS.icon, BUTTON_CLS.light],
			itemId: 'edit_button',
			hidden: true,
			tooltip: edi.i18n.getMessage('action.edit'),
			glyph: edi.constants.ICONS.EDIT,
			handler(btn) {
				me.internalEditBtnHandler(internalForm, btn, [btn, doneRowBtn, removeRowBtn]);
			}
		});

		removeRowBtn = createButton({
			margin: '0 0 0 16',
			cls: [BUTTON_CLS.icon, BUTTON_CLS.light],
			itemId: 'remove_button',
			glyph: edi.constants.ICONS.CLOSE,
			tooltip: edi.constants.USE_TOOLTIPS ? edi.i18n.getMessage('form.btn.delete') : null,
			handler(btn) {
				btn.buttonData = rowData;
				me.internalRemoveBtnHandler(btn, panel);
			}
		});

		let rowButtons = [];
		if (me.isShowInternalDoneBtn) {
			rowButtons.push(doneRowBtn);
			rowButtons.push(editRowBtn);
		}
		if (me.isShowInternalDeleteBtn) {
			rowButtons.push(removeRowBtn);
		}

		let internalRow = createPanel({
			layout: {
				type: 'vbox',
				align: 'stretch'
			},
			cls: 'internal-row-panel',
			padding: me.internalRowPadding || '0 0 16 0',
			items: [
				createContainer({
					cls: 'internal-row',
					itemId: 'internalRow',
					rowData,
					items: [
						createContainer({
							cls: 'row-actions',
							layout: 'hbox',
							items: [
								label,
								createContainer({
									cls: 'row-buttons',
									layout: 'hbox',
									items: rowButtons
								})
							]
						}),
						createPanel({
							bodyPadding: 24,
							itemId: 'internalForm',
							items: [internalForm]
						})
					]
				}),
				createContainer({
					layout: 'hbox',
					items: [
						(internalAddBtn = createButton(
							Ext.apply(
								{
									glyph: edi.constants.ICONS.PLUS,
									name: 'item.add.button',
									padding: '16 0 0 0',
									itemId: 'internalAddBtn',
									cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
									text: edi.i18n.getMessage('form.btn.add.row'),
									handler: function (comp) {
										if (typeof me.outerInternalAddBtnHandler === 'function') {
											me.outerInternalAddBtnHandler(function () {
												me.onClickInternalAddBtn(comp);
											});
										} else {
											me.onClickInternalAddBtn(comp);
										}
									}
								},
								me.internalAddBtnConfig
							)
						))
					]
				})
			]
		});

		let panel = createContainer({
			layout: 'auto',
			cls: 'internal-rows-panel',
			// padding: '8 0 0 0',
			items: [internalRow]
		});

		panel.internalAddBtn = internalAddBtn;
		panel.onValidateForm = onValidateForm;

		return panel;
	},

	setInternalCount: function (externalRow) {
		let me = this;
		let rows = me.getInternalRows(externalRow) || [];
		Ext.Array.forEach(rows, function (item, index) {
			const label = item.down('ui-labelfield');
			var count = index + 1;
			label?.setText(me.internalTitle + ' ' + count);
		});
	},

	setInternalCustomTitles: function (titles = []) {
		let me = this;
		let rows = me.getExternalRows() || [];
		rows.forEach((externalRow) => {
			let externalRowContent = externalRow.down('[itemId="externalRowContent"]');
			if (!!externalRowContent) {
				externalRowContent.items.items.forEach((internalRow, index) => {
					const label = internalRow.down('ui-labelfield');
					label?.setHtml(titles[index]);
				});
			}
		});
	},
	onClickInternalAddBtn: function (comp) {
		let me = this;
		me.internalAddBtnHandler(comp, function (internalRow) {
			me.onAddBtnHandler(internalRow);
		});
	},

	internalAddBtnHandler: function (btn, callback) {
		let me = this;
		let externalBlock = btn.up('[itemId="externalRow"]');
		let externalRow = externalBlock.down('[itemId="externalRowContent"]');
		let newItem = me.createInternalRow(btn.buttonData);

		externalRow.add(newItem);

		if (typeof me.externalBlockChange === 'function') {
			me.externalBlockChange(me, externalBlock);
		}

		me.updateInternalRowButtons(externalRow, callback);
		if (me.showCount) {
			me.setInternalCount(externalRow);
		}
	},

	internalRemoveBtnHandler: function (btn, rowWhereClickWas) {
		let me = this;
		let externalBlock = btn.up('[itemId="externalRow"]');
		let externalRow = btn.up('[itemId="externalRowContent"]');
		let externalRowPanel = externalBlock?.ownerCt;
		let externalStatusLabel = externalRowPanel.down('[itemId="externalStatus"]');

		externalRow.remove(rowWhereClickWas);

		if (typeof me.setExternalStatusFn === 'function') {
			me.setExternalStatusFn(externalStatusLabel, externalRow);
		}

		if (externalRow.items.items.length === 0) {
			me.remove(externalRowPanel);
		}
		if (typeof me.externalBlockChange === 'function') {
			me.externalBlockChange(me, externalBlock);
		}

		me.updateExternalRowButtons();
		me.updateInternalRowButtons(externalRow);
		if (me.showCount) {
			me.setInternalCount(externalRow);
		}
	},

	internalDoneBtnHandler: function (form, btn, buttonsArray, isAddBtnHandler) {
		let internalRow = btn.up('[itemId="internalRow"]');
		let me = this;

		internalRow.addCls('internal-real-only');

		if (this.customReadOnlyInternalFormFn) {
			let formPanel = internalRow.down('[itemId="internalForm"]');
			let inForm = formPanel.items.items[0];
			let fieldsValues = edi.utils.collectFormValues(inForm.getForm());
			let readOnlyContainer = this.customReadOnlyInternalFormFn(fieldsValues);
			formPanel.remove(inForm);
			formPanel.add(readOnlyContainer);
			formPanel.updateLayout();
			if (!isAddBtnHandler) {
				me.setReadOnlyInternalRow(internalRow);
			}

			Ext.Array.forEach(buttonsArray, function (button) {
				if (button) {
					button.setVisible(button.itemId === 'edit_button' || button.itemId === 'remove_button');
				}
			});
		} else {
			let fields = form.getForm().getFields().items;
			Ext.Array.forEach(fields, function (field) {
				field.setReadOnly(true);
			});

			Ext.Array.forEach(buttonsArray, function (button) {
				if (button) {
					button.setVisible(button.itemId === 'edit_button' || button.itemId === 'remove_button');
				}
			});
		}

		let externalRow = internalRow.up('[itemId="externalRowContent"]');
		let externalRowPanel = externalRow.up('[itemId="externalRow"]')?.ownerCt;
		let externalStatusLabel = externalRowPanel.down('[itemId="externalStatus"]');

		if (typeof this.setExternalStatusFn === 'function') {
			this.setExternalStatusFn(externalStatusLabel, externalRow);
		}
	},

	internalEditBtnHandler: function (form, btn, buttonsArray) {
		let me = this;
		let internalRow = btn.up('[itemId="internalRow"]');
		internalRow.removeCls('internal-real-only');
		if (this.customReadOnlyInternalFormFn) {
			let formPanel = internalRow.down('[itemId="internalForm"]');
			let inForm = formPanel.items.items[0];
			let formValues = edi.utils.collectFormValues(inForm);
			let val = {};
			if (formValues?.hasOwnProperty(me.internalName)) {
				val = formValues[me.internalName];
			} else {
				val[me.internalName] = formValues;
			}
			let internalForm = this.internalFormFn(val);

			formPanel.remove(inForm);
			formPanel.add(internalForm);
			formPanel.updateLayout();

			Ext.Array.forEach(buttonsArray, function (button) {
				button.setVisible(button.itemId !== 'edit_button' || button.itemId === 'remove_button');
				if (button.itemId === 'done_button') {
					let fields = internalForm.getForm().getFields().items;
					fields.forEach(function (field) {
						field.on('change', function () {
							button.setDisabled(!internalForm.getForm().isValid());
						});
					});

					button.setDisabled(false);
				}
			});
		} else {
			let fields = form.getForm().getFields().items;
			Ext.Array.forEach(fields, function (field) {
				field.setReadOnly(false);
			});

			Ext.Array.forEach(buttonsArray, function (button) {
				button.setVisible(button.itemId !== 'edit_button' || button.itemId === 'remove_button');
			});
		}
	},

	updateInternalRowButtons(externalRow, callback) {
		let me = this;

		//me.suspendLayouts();

		let rows = me.getInternalRows(externalRow) || [];

		rows.forEach((row, index) => {
			if (typeof callback === 'function' && rows.length - 1 !== index) {
				callback(row);
			}

			let removeInternalRowBtn = row.down('[itemId="remove_button"]');
			if (removeInternalRowBtn) {
				removeInternalRowBtn.setDisabled(false);
			}
			if (typeof row?.internalAddBtn?.setVisible === 'function') {
				row.internalAddBtn.setVisible(false);
			}
			if (me.internalRemoveBtnConfig?.permanentlyDisabledInFirstRow === true && index === 0) {
				removeInternalRowBtn.setDisabled(true);
			}
		});
		let lastRow = rows[rows.length - 1];
		if (typeof lastRow?.internalAddBtn?.setVisible === 'function') {
			lastRow.internalAddBtn.setVisible(lastRow.internalAddBtn.permanentlyHidden !== true);
		}
		if (lastRow) {
			let lastRowRemoveInternalRowBtn = lastRow.down('[itemId="remove_button"]');
			lastRowRemoveInternalRowBtn?.setDisabled(me.items.items.length === 1 && rows.length === 1);
		}

		//me.resumeLayouts();
		me.updateLayout();
	},

	getInternalRows(rows) {
		return Ext.isArray(rows.items?.items) ? rows.items.items : [];
	}
});

const createRowWithNestedRows = function (cfg) {
	return Ext.create('edi.components.RowWithNestedRows', cfg);
};

export { createRowWithNestedRows };
