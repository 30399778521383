import { moduleMethods } from '@Edi/modules/power_of_attorney/POA_002/methods';

function clearSnils(snils) {
	return (snils || '').replace(/[\s-]/g, '');
}
function isPrincipal(docData) {
	const userSnils = clearSnils(edi.core.getUserData().personalId || '');
	if (!userSnils) {
		return false;
	}
	const principalSnilsArr = (docData?.record?.data?.principals || []).map((p) => clearSnils(p.snils));
	principalSnilsArr.push(
		clearSnils(edi.utils.getAttributeByName(docData?.record?.data?.attributes, 'principalSnils'))
	);
	return principalSnilsArr.some((snils) => snils === userSnils);
}
function isConfidant(docData) {
	const userSnils = clearSnils(edi.core.getUserData().personalId || '');
	if (!userSnils) {
		return false;
	}
	const confidantsSnilsArr = (docData?.record?.data?.confidants || []).map((p) => clearSnils(p.snils));
	return confidantsSnilsArr.some((snils) => snils === userSnils);
}

//Для передоверенных МЧД создание/редактирование/копирование на юай пока не поддерживаются
//определяем что это передоверенная по наличию parentPoaNumber
function isRetrust(docData) {
	return !!docData?.record?.data?.parentPoaNumber;
}

const actionRulesForPoaModule = {
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		conditions: [
			{
				allow: function (documentData) {
					return isPrincipal(documentData) || isConfidant(documentData);
				}
			}
		]
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		conditions: [
			{
				allow: function (documentData) {
					return isPrincipal(documentData) || isConfidant(documentData);
				}
			}
		]
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		conditions: [
			{
				allow: function (documentData) {
					return isPrincipal(documentData) || isConfidant(documentData);
				}
			}
		]
	},
	SIGN: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.FOR_SIGNING]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.FOR_SIGNING]
		},
		conditions: [
			{
				allow: function (documentData) {
					let finishDate = new Date(documentData.record?.data?.validTo);
					const version = documentData.record?.get('versionFormat');
					let today = new Date();
					today.setHours(
						finishDate.getHours(),
						finishDate.getMinutes(),
						finishDate.getSeconds(),
						finishDate.getMilliseconds()
					);
					let fnsNumber = documentData.record?.data?.poaNumber;
					const checkSide =
						isPrincipal(documentData) &&
						(documentData.businessState === edi.constants.STATE.DRAFT ||
							documentData.businessState === edi.constants.STATE.FOR_SIGNING);
					return (
						version !== '002' &&
						(fnsNumber ? fnsNumber !== moduleMethods.DEFAULT_FNS_NUMBER : false) &&
						finishDate >= today &&
						checkSide
					);
				}
			}
		]
	},
	//отправить на подпись доверителю
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		conditions: [
			{
				allow: function (documentData) {
					let finishDate = new Date(documentData.record?.data?.validTo);
					const version = documentData.record?.get('versionFormat');
					let today = new Date();
					today.setHours(
						finishDate.getHours(),
						finishDate.getMinutes(),
						finishDate.getSeconds(),
						finishDate.getMilliseconds()
					);
					let fnsNumber = documentData.record?.data?.poaNumber;
					return (
						version !== '002' &&
						(fnsNumber ? fnsNumber !== moduleMethods.DEFAULT_FNS_NUMBER : false) &&
						finishDate >= today &&
						isConfidant(documentData)
					);
				}
			}
		]
	},
	//отозвать
	REVOKE: {
		OUTGOING: {
			STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACTIVE]
		},
		LOOP: {
			STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACTIVE]
		},
		conditions: [
			{
				allow: function (documentData) {
					return isPrincipal(documentData);
				}
			}
		],
		permissionChanging: [{ change: () => 'CREATE_REVOKE_POWER_OF_ATTORNEY' }]
	},
	//отклонить
	REJECT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.FOR_SIGNING]
		},
		LOOP: {
			STATE: [edi.constants.STATE.FOR_SIGNING]
		},
		conditions: [
			{
				allow: function (documentData) {
					return isPrincipal(documentData);
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		conditions: [
			{
				allow: function (documentData) {
					return isPrincipal(documentData);
				}
			}
		]
	},
	EDIT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.FOR_SIGNING]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.FOR_SIGNING]
		},
		conditions: [
			{
				states: [edi.constants.STATE.FOR_SIGNING],
				allow: function (documentData) {
					const isSeveralPrincipals = documentData.record.get('principals')?.length > 1;
					const isSigned = documentData.record.get('countSignatures') > 0;
					//Если доверителей нескольких кнопку редактирования отображать только до первого подписания.
					return isPrincipal(documentData) && !(isSeveralPrincipals && isSigned);
				}
			},
			{
				allow: function (documentData) {
					return documentData.record?.get('versionFormat') === '002' || !isRetrust(documentData);
				}
			}
		]
	},
	COPY: {
		OUTGOING: {},
		LOOP: {},
		conditions: [
			{
				allow: function (documentData) {
					let state = documentData?.record?.data?.state;
					const version = documentData.record?.get('versionFormat');
					return (
						version !== '002' &&
						!isRetrust(documentData) &&
						isPrincipal(documentData) &&
						state !== edi.constants.STATE.DRAFT
					);
				}
			}
		]
	}
};

edi.action.rulesByDocType[edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY] = actionRulesForPoaModule;

export { actionRulesForPoaModule, isPrincipal, isConfidant };
