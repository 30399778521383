// @ts-ignore
import { createCombo, createTextField } from '@UIkit/components/fields';
// @ts-ignore
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { moduleMethods_003 } from '../methods_003';
import { createEmailField } from '@Components/fields';

export interface FieldProps {
	fieldCfg?: AnyObject;
	fieldBlockCfg?: AnyObject;
	order?: number;
}

export interface CreateFieldResult {
	fieldBlock: ExtComponent;
	field: ExtComponent;
	order: number;
}

export interface ComponentCfg {
	cmp: ExtComponent;
	order: number;
}

export interface PrincipalCompanyPartProps {
	docPath: string;
	isCompany?: boolean;
	partData: AnyObject;
	defaults?: AnyObject;

	defaultFieldBlockCfg?: AnyObject;
	defaultFieldCfg?: AnyObject;
}

export class PoACompanyPart {
	props: PrincipalCompanyPartProps;

	fields: {
		[key: string]: ExtComponent;
	} = {};

	defaultFieldBlockCfg: AnyObject = {
		cls: FIELD_BLOCK_CLS.small
	};

	defaultFieldCfg: AnyObject = {};

	constructor(props: PrincipalCompanyPartProps) {
		const me = this;
		me.modifyConfig(props);
	}

	modifyConfig(props: PrincipalCompanyPartProps) {
		const me = this;
		me.fields = {};
		me.props = props;

		me.defaultFieldBlockCfg ||= me.props.defaultFieldBlockCfg ?? {};
		me.defaultFieldCfg ||= me.props.defaultFieldCfg ?? {};
	}

	getSortedComponents(componentCfg: ComponentCfg[]) {
		return componentCfg
			.sort((a: ComponentCfg, b: ComponentCfg) => a.order - b.order)
			.map((cmpCfg: ComponentCfg) => cmpCfg.cmp);
	}

	createCompanyNameField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 10 } = props;

		const me = this;

		const field = (me.fields.orgName = createTextField(
			Object.assign(
				{
					allowBlank: me.props.isCompany,
					readOnly: me.props.isCompany,
					value:
						moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'naimOrg') ||
						me.props.defaults?.naimOrg,
					name: me.props.docPath + 'naimOrg',
					maxLength: 1000
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('company.name'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createOrgOgrnField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 20 } = props;

		const me = this;

		const field = (me.fields.orgOgrn = createTextField(
			Object.assign(
				{
					allowBlank: false,
					value:
						moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'ogrn') ||
						me.props.defaults?.ogrn,
					name: me.props.docPath + 'ogrn',
					regex: edi.constants.VALIDATORS.OGRN
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('column.register.number'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createOrgInnField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 30 } = props;

		const me = this;

		const field = (me.fields.orgInn = createTextField(
			Object.assign(
				{
					allowBlank: me.props.isCompany,
					readOnly: me.props.isCompany,
					value:
						moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'innyul') ||
						me.props.defaults?.innyul,
					name: me.props.docPath + 'innyul',
					regex: edi.constants.VALIDATORS.INN
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('company.inn.short'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createOrgKppField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 40 } = props;

		const me = this;

		const field = (me.fields.CompanyName = createTextField(
			Object.assign(
				{
					allowBlank: me.props.isCompany,
					readOnly: me.props.isCompany,
					value:
						moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'kpp') ||
						me.props.defaults?.kpp,
					name: me.props.docPath + 'kpp',
					regex: edi.constants.VALIDATORS.KPP
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('column.org.kpp'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createRegNomerField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 50 } = props;

		const me = this;

		const field = (me.fields.regNomer = createTextField(
			Object.assign(
				{
					value: moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'regNomer'),
					name: me.props.docPath + 'regNomer',
					maxLength: 80
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('company.filiation.regnumber'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createNaimUchrDokField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 60 } = props;

		const me = this;

		const field = (me.fields.naimUchrDok = createTextField(
			Object.assign(
				{
					value: moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'naimUchrDok'),
					name: me.props.docPath + 'naimUchrDok',
					maxLength: 1000
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('power.of.attorney.establish.doc.name'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createPhoneField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 70 } = props;

		const me = this;

		const field = (me.fields.phone = createTextField(
			Object.assign(
				{
					value:
						moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'kontaktTlf') ||
						me.props.defaults?.kontaktTlf,
					name: me.props.docPath + 'kontaktTlf',
					maxLength: 50
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: !me.props.isCompany
						? edi.i18n.getMessage('power.of.attorney.company.contact.phone')
						: edi.i18n.getMessage('company.contact.phone'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createEmailField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 80 } = props;

		const me = this;

		const field = (me.fields.email = createEmailField(
			Object.assign(
				{
					value:
						moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'adrElPocht') ||
						me.props.defaults?.adrElPocht,
					name: me.props.docPath + 'adrElPocht',
					minLength: 3,
					maxLength: 129
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		) as ExtComponent);

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('user.profile.email'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createRegionField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 90 } = props;

		const me = this;

		const field = (me.fields.region = createCombo(
			Object.assign(
				{
					name: me.props.docPath + 'adrReg.region',
					allowBlank: false,
					store: edi.stores.initRegionsStore(),
					valueField: 'id',
					displayField: 'display_name',
					value:
						moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'adrReg.region') ||
						me.props.defaults?.adrReg?.region
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('power.of.attorney.rus.region'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	createAdrRFField(props: FieldProps = {}): CreateFieldResult {
		const { fieldCfg = {}, fieldBlockCfg = {}, order = 100 } = props;

		const me = this;

		const field = (me.fields.adrRF = createTextField(
			Object.assign(
				{
					name: me.props.docPath + 'adrReg.adrRF',
					allowBlank: false,
					value:
						moduleMethods_003.getFieldValue(me.props.partData, me.props.docPath, 'adrReg.adrRF') ||
						me.props.defaults?.adrReg?.adrRF,
					maxLength: 1000
				},
				me.defaultFieldCfg,
				fieldCfg
			)
		));

		const fieldBlock = createFieldBlock(
			Object.assign(
				{
					title: edi.i18n.getMessage('company.address'),
					items: field
				},
				me.defaultFieldBlockCfg,
				fieldBlockCfg
			)
		);

		return { field, fieldBlock, order };
	}

	getSortedFieldList(): ExtComponent[] {
		const me = this;
		return me.getSortedComponents(me.getFieldList());
	}

	getFieldList(): ComponentCfg[] {
		const me = this;

		const { fieldBlock: orgName, order: orgNameOrder } = me.createCompanyNameField();
		const { fieldBlock: orgOgrn, order: rorgOgrnOrder } = me.createOrgOgrnField();
		const { fieldBlock: orgInn, order: orgInnOrder } = me.createOrgInnField();
		const { fieldBlock: orgKpp, order: orgKppOrder } = me.createOrgKppField();
		const { fieldBlock: regNomer, order: regNomerOrder } = me.createRegNomerField();
		const { fieldBlock: naimUchrDok, order: naimUchrDokOrder } = me.createNaimUchrDokField();
		const { fieldBlock: phone, order: phoneOrder } = me.createPhoneField();
		const { fieldBlock: email, order: emailOrder } = me.createEmailField();
		const { fieldBlock: region, order: regionOrder } = me.createRegionField();
		const { fieldBlock: adrRF, order: adrRFOrder } = me.createAdrRFField();

		return [
			{
				cmp: orgName,
				order: orgNameOrder
			},
			{
				cmp: orgOgrn,
				order: rorgOgrnOrder
			},
			{
				cmp: orgInn,
				order: orgInnOrder
			},
			{
				cmp: orgKpp,
				order: orgKppOrder
			},
			{
				cmp: regNomer,
				order: regNomerOrder
			},
			{
				cmp: naimUchrDok,
				order: naimUchrDokOrder
			},
			{
				cmp: phone,
				order: phoneOrder
			},
			{
				cmp: email,
				order: emailOrder
			},
			{
				cmp: region,
				order: regionOrder
			},
			{
				cmp: adrRF,
				order: adrRFOrder
			}
		];
	}
}
